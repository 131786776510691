import { Component, OnDestroy, SimpleChanges, OnChanges, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { interval, Subscription, Subject } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { OrdersService } from '../../services/orders.service';
import { CustomerService } from '../../services/customer.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { PrintService } from '../../services/print.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as FileSaver from 'file-saver';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';


@Component({
	selector: 'app-customer-trans',
	templateUrl: './customer-trans.component.html',
	styleUrls: ['./customer-trans.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(-179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('250ms ease-in')),
			transition('active => inactive', animate('250ms ease-out')),
		]),
		trigger('openClose', [
			state(':enter', style({ height: '*' })),
			state(':leave', style({ height: '0px' })),
			transition('false <=> true', animate(500))
		]),
		trigger('filterAnimation', [
			transition(':enter, * => 0, * => -1', []),
			transition(':increment', [
				query(':enter', [
					style({ opacity: 0, width: 0 }),
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 1, width: '*' })),
					]),
				], { optional: true })
			]),
			transition(':decrement', [
				query(':leave', [
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 0, width: 0 })),
					]),
				])
			]),
		]),
		trigger('grow', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: '0', width: '0' }),
				animate(200, style({ height: '*', width: '*' })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate(100, style({ height: 0, width: 0, overflow: 'hidden' })),
				animate('100ms', style({ opacity: 0 }))
			])
		]),
	]
})

export class CustomerTransComponent {
	@Input() customerdata;
	@Input() transactions: any = [];
	@Input() reloadData: any = [];
	@Input() pos: any = false;


	@Input() resetTrans: Subject < boolean > = new Subject < boolean > ();
	alltransactions: any = false;
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('emailData') emailRef: ElementRef;
	@ViewChild('posRec') posRecRef: ElementRef;

	@ViewChild('allocations') allocationsRef: ElementRef;
	@ViewChild('reversal') reversalRef: ElementRef;
	@ViewChild('details') detailsRef: ElementRef;
	@ViewChild('print_table') printtable: ElementRef;
	@ViewChild('kewordsRef') kewordsRefElement: ElementRef;
	@ViewChild('print_basic') tablecontentRef: ElementRef;
	@ViewChild('transactioMove') transactioMoveRef: ElementRef;

	printSocket: WebSocket = null;

	printing = false;
	viewedtransactions: any = [];

	flip = 'inactive';
	sending = false;
	headercolumns: string[] = [
		'transno',
		'typename',
		'trandate',
		'duedate',
		//'ponumber',
		'invtext',
		'enteredby',
		'order_',
		'settled',
		'totalamount',
		'allocated',
		'balance',
		//'statusname',
		//'settled',
		//'suppname',
		//'address1',
		//'telephone'
	];
	customertrans: any = [];
	sendemail: any = '';
	emailtrans: any = [];
	reversetran: any = '';
	transid: any = '';
	transidtype: any = '';
	keywords = new UntypedFormControl('')
	transno = new UntypedFormControl('')
	opentrans = new UntypedFormControl(true)
	fromdateCtrl = new UntypedFormControl('')
	todateCtrl = new UntypedFormControl('')
	displaydetails: any = [];
	searching: any = false;
	searchingsubscription: any = false;

	todate = new Date();
	fromdate = new Date();
	displaymattable = false;
	type_filters = [
		{ value: 10, name: 'Invoice' },
		{ value: 11, name: 'Credit' },
		{ value: 12, name: 'Payment' },
	];
	age = '';
	type_selected: any = '';
	aged: any = false;

	today = new Date();
	cdetLink: any = '';
	receiptLink: any = '';
	config: any = false;
	editsallowed: any = {
		discounts: true,
		editar: true,
		editbilling: true,
		editshipping: true,
		editcontact: true,
		editprofile: true,
		editnotes: true,
		takepayment: true,
	}
	user: any = false;
	raPdfLink = '';
	raXlsLink = '';
	rechtml: any = false;
	transsubscribtion: any = false;

	constructor(private printService: PrintService, private route: ActivatedRoute, public ordersService: OrdersService, public globalSearchService: GlobalSearchService, public router: Router, public customerService: CustomerService, private modalService: NgbModal, private fb: UntypedFormBuilder, private globalsService: GlobalsService) {
		pdfDefaultOptions.assetsFolder = 'assets';

		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
			this.globalSearchService.user.subscribe(u => {
				this.user = u
				if (u.user.issalesman) {
					this.editsallowed = r.salesmanAllowed
				}
			});

			this.cdetLink = r.apiServer.baseUrl + r.apiServer.cdetLink
			this.receiptLink = r.apiServer.baseUrl + r.apiServer.receiptLink
			this.raPdfLink = r.apiServer.baseUrl + r.apiServer.raLinkPdf
			this.raXlsLink = r.apiServer.baseUrl + r.apiServer.raLinkXls
		})
	}

	ngOnInit(): void {

		this.type_selected = [10, 11, 12]

		const today = new Date();
		const priorDate = new Date(new Date().setDate(today.getDate() - 365));
		this.fromdate = priorDate;

		this.fromdateCtrl.setValue(this.fromdate)
		this.todateCtrl.setValue(this.todate)
		this.opentrans.valueChanges.subscribe(newValue => {

			if (newValue) {
				if (!this.alltransactions) {
					this.alltransactions = this.transactions;
				}
				this.transactions = this.alltransactions.filter((r: any) => {
					return r.settled == 'No';
				});
			} else {
				this.updateTransactions();
				this.transactions = this.alltransactions;

			}
		});
	}


	//set to number but on database side it will pull bucket 1 2 3
	filterAgeOne() {
		this.age = 'one';
		this.updateTransactions()
	}

	filterAgeTwo() {
		this.age = 'two';
		this.updateTransactions()
	}

	filterAgeThree() {
		this.age = 'three';
		this.updateTransactions()
	}

	autoAllocateMatching() {
		const proceed = confirm('Allocate all open documents?')

		if (proceed) {

		}
	}

	updateTransactions() {
		let daterange = {}
		if (this.keywords.value == '') {
			daterange = {
				fromdate: this.fromdateCtrl.value,
				todate: this.todateCtrl.value,
				debtorno: this.customerdata.debtorno,
				opentrans: this.opentrans.value,
				types: this.type_selected,
				transno: this.transno.value,
				keywords: this.keywords.value,
				age: this.age
			}
		} else {
			daterange = {
				debtorno: this.customerdata.debtorno,
				keywords: this.keywords.value,
				transno: this.transno.value,
			}
		}
		this.sending = true;
		if (!this.transsubscribtion) {
			this.transsubscribtion = this.customerService.getDebtorTransactionsRange(daterange).subscribe((results: any) => {

				if (results.transactions.length > 100) {
					//this.displaymattable = true;
				}

				this.transactions = results.transactions
				this.aged = results.aged;
				this.alltransactions = results.transactions;
				if (this.displaymattable) {
					this.customertrans = new MatTableDataSource(this.transactions);
					this.customertrans.sort = this.sort;
					this.customertrans.paginator = this.paginator;
				}
				this.sending = false;
				this.transsubscribtion = false;
			});
		}
	}

	loadAllocationTo(transid: any, type: any) {
		this.flip = 'active';
		this.transid = transid;
		this.transidtype = type;
	}

	loadAllocations(transid: any, type: any) {
		this.flip = 'active';
		this.transid = transid;
		this.transidtype = type;
	}

	moveTrans(tran) {
		this.modalService.dismissAll();
		this.modalService.open(this.transactioMoveRef, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {}, (reason) => {

		});
	}

	paymentReversal(tran) {
		this.reversetran = tran.id;

		this.modalService.dismissAll();
		this.modalService.open(this.reversalRef, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {}, (reason) => {

		});
	}

	ngAfterViewInit() {

	}

	creditInvoice(trans: any) {
		this.router.navigate(['/receivables/credit-invoice/' + trans.id]);
	}

	announceSortChange(event: any) {

	}

	getTransactionTotals() {
		let total = 0
		if (this.transactions) {
			total = this.transactions.reduce(function(accumulator, c) {
				return accumulator + parseFloat(c.totalamount);
			}, 0);
		}
		return total;
	}

	getAllocTotals() {
		let total = 0
		if (this.transactions) {
			total = this.transactions.reduce(function(accumulator, c) {
				return accumulator + parseFloat(c.allocated);
			}, 0);
		}
		return total;
	}

	getBalanceTotals() {
		let total = 0
		if (this.transactions) {
			total = this.transactions.reduce(function(accumulator, c) {
				return accumulator + parseFloat(c.balance);
			}, 0);
		}
		return total;
	}

	searchItemTransactions(value) {
		if (value != '') {

			if (this.searchingsubscription) {
				this.searchingsubscription.unsubscribe();
			}
			this.searching = true;
			this.searchingsubscription = this.customerService.searchItemTransactions(this.customerdata.debtorno, value).subscribe(async (results: any) => {
				//this.globalSearchService.blurInputElement(this.kewordsRefElement);
				this.searching = false;
				this.transactions = results;
				this.customertrans = new MatTableDataSource(results);
				this.customertrans.sort = this.sort;
				this.customertrans.paginator = this.paginator;
			});
		} else {
			this.updateTransactions();
		}
	}

	searchTransaction(value) {
		if (value != '') {

			if (this.searchingsubscription) {
				this.searchingsubscription.unsubscribe();
			}

			this.searching = true;
			this.searchingsubscription = this.customerService.searchTransactions(this.customerdata.debtorno, value).subscribe(async (results: any) => {
				this.searching = false;
				this.transactions = results;
				this.customertrans = new MatTableDataSource(results);
				this.customertrans.sort = this.sort;
				this.customertrans.paginator = this.paginator;
			});
		} else {
			this.updateTransactions();
		}
	}

	openGl(transaction: any, content: any) {
		this.globalsService.getTransactionGl(transaction.transno, transaction.type).subscribe((result: any) => {
			this.displaydetails = result;
			this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
		})
	}

	viewAllocations(transaction: any, content: any) {
		this.globalsService.getAllocations(transaction.id).subscribe((result: any) => {
			this.displaydetails = result;
			this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});

		})
	}

	viewPayments(transaction: any, content: any) {

		this.viewedtransactions.push(transaction);
		this.globalsService.getHowPaid(transaction.id, transaction.type).subscribe((result: any) => {

			this.displaydetails = result;
			this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});

		})
	}

	openDocument(transaction: any, content: any, display: string) {
		this.viewedtransactions.push(transaction);
		switch (transaction.type) {
			case '11':
				this.globalsService.getCreditNote(transaction.id, display).subscribe((result: any) => {
					this.displaydetails = result;
					this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
				})
				break;
			case '10':
				this.globalsService.getInvoice(transaction.id, display).subscribe((result: any) => {
					this.displaydetails = result;
					this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
				})

				break;
		}
	}

	printReceiptPdf(transaction: any, content: any) {
		this.globalsService.getPOSReceipt(transaction.transno, 'L10').subscribe((result: any) => {

		});
	}

	printReceipt(transaction: any, content: any) {
		//if(this.printSocket){
		//this.printSocket.close();
		//}
		//this.printSocket = new WebSocket("ws://localhost:9990");
		//this.printSocket.send('^XA\n^LH0,0\n^FWN,0\n^PON\n^CF0\n^MMT,N\r\n^PW400\n^LL400\n^FT7,0^A0N,227^FH\\^FDA & A BEAUTY SUPPLY, INC^FS\n^FT11,0^A0N,227^FH\\^FD5879 DAVISON ROAD^FS\n^FT11,0^A0N,227^FH\\^FDLOCKPORT, NY 14094^FS\n^FT13,0^A0N,227^FH\\^FD(716) 478-0300^FS\n^FT0,0^A0N,227^FH\\^FDCUSTOMERSERVICE@AABEAUTYSUPPLIES.COM^FS\n^FT11,0^A0N,227^FH\\^FD^FS\n^FT11,0^A0N,227^FH\\^FDLOCKPORT, NY 14094^FS\n^XZ\n');

		this.globalsService.getPOSReceipt(transaction.transno, 'L10').subscribe((result: any) => {
			//this.socket.send(result.display);
			this.rechtml = result.display
			this.modalService.open(this.posRecRef, { ariaLabelledBy: 'modal-title', size: 'md' }).result.then((result) => {}, (reason) => {
				this.rechtml = false;
			});

		});

	}

	sendEmail(transaction: any) {
		this.sendemail = '';
		this.emailtrans = transaction;
		if (this.sendemail == '') {
			for (let x = 0; x < this.customerdata.branches.length; x++) {
				if (this.customerdata.branches[x].branchcode == transaction.branchcode) {
					this.sendemail = this.customerdata.customer.invoiceemailaddress;
				}
			}
		}

		this.modalService.dismissAll();

		this.modalService.open(this.emailRef, { ariaLabelledBy: 'modal-title', size: 'md' }).result.then((result) => {}, (reason) => {

		});
	}

	pushEmail() {
		// if (!this.isEmail(this.sendemail)) {
		// 	this.sendemail = '';
		// 	alert('Please Enter a Valid Email Address');
		// } else {

		switch (this.emailtrans.type) {
			case '12':
				this.globalsService.emailReceipt(this.emailtrans.ccid, 'email', this.sendemail).subscribe((result: any) => {})
				this.modalService.dismissAll();
				break;
			case '11':
				this.globalsService.emailCreditNote(this.emailtrans.id, 'email', this.sendemail).subscribe((result: any) => {})
				this.modalService.dismissAll();
				break;
			case '10':
				this.globalsService.emailInvoice(this.emailtrans.id, 'email', this.sendemail).subscribe((result: any) => {})
				this.modalService.dismissAll();
				break;
		}
		//}
	}

	reversalUpdate(event: any) {
		this.updateTransactions();
	}

	isEmail(search: string): boolean {
		let serchfind: boolean;
		const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		serchfind = regexp.test(search);
		return serchfind
	}

	runFunction(event: any) {

	}

	ngOnChanges(changes: any) {

		if (changes.customerdata.firstChange) {
			this.transactions = [];
			this.viewedtransactions = [];
			this.updateTransactions();
		} else {
			//new customer not jsut new data
			if (changes.customerdata.currentValue.customer_id != changes.customerdata.previousValue.customer_id) {
				this.sending = true;
				this.transactions = [];
				this.viewedtransactions = [];
			}
		}

		this.updateTransactions();
		if (changes.pos) {
			if (changes.pos.currentValue) {
				this.opentrans.setValue(false);
				this.updateTransactions();
			}
		}
	}


	cdet() {
		this.sending = true;
		this.printing = true;
	}

	pdf() {
		this.sending = true;
		this.printing = true;
		this.exportPdf();
	}
	//work around for table button
	exportPdf() {

		const encoded: string = this.globalSearchService.base64encode(this.tablecontentRef.nativeElement.innerHTML);
		const today = new Date();
		const opentext = (this.opentrans) ? 'Show Open Only Yes' : 'Show Open Only No';
		const data = {
			content: encoded,
			landscape: true,
			filename: this.customerdata.debtorno + '-Transactions.pdf',
			//title: this.customerdata.debtorno+'.'+this.customerdata.branchcode+' ' +this.customerdata.customer.brname+' Account Receivable',
			//subtitle: opentext+' Types:'+this.type_selected.join(',')+' '+this.fromdateCtrl.value.toLocaleDateString()+' - '+this.todateCtrl.value.toLocaleDateString()+' created '+today.toLocaleString()
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.sending = false;
			this.printing = false;
		});

	}

	raDetailsPdf() {
		const data = {
			landscape: true,
			debtorno: this.customerdata.debtorno,
			filename: this.customerdata.debtorno + '-AR-Transactions.pdf',
			//title: this.customerdata.debtorno+'.'+this.customerdata.branchcode+' ' +this.customerdata.customer.brname+' Account Receivable',
			//subtitle: opentext+' Types:'+this.type_selected.join(',')+' '+this.fromdateCtrl.value.toLocaleDateString()+' - '+this.todateCtrl.value.toLocaleDateString()+' created '+today.toLocaleString()
		}
		this.sending = true;
		this.customerService.getRa(data).subscribe((result: any) => {
			this.sending = false;
			this.printing = false;
		});
	}

	raDetailsXls() {
		const data = {
			landscape: true,
			debtorno: this.customerdata.debtorno,
			filename: this.customerdata.debtorno + '-AR-Transactions.pdf',
			//title: this.customerdata.debtorno+'.'+this.customerdata.branchcode+' ' +this.customerdata.customer.brname+' Account Receivable',
			//subtitle: opentext+' Types:'+this.type_selected.join(',')+' '+this.fromdateCtrl.value.toLocaleDateString()+' - '+this.todateCtrl.value.toLocaleDateString()+' created '+today.toLocaleString()
		}
		this.sending = true;
		this.customerService.getRaXls(data).subscribe((result: any) => {
			this.sending = false;
			this.printing = false;
		});
	}

	excel() {
		const encoded: string = this.globalSearchService.base64encode(this.tablecontentRef.nativeElement.innerHTML);
		const today = new Date();
		const opentext = (this.opentrans) ? 'Show Open Only Yes' : 'Show Open Only No';
		const data = {
			content: encoded,
			filename: this.customerdata.debtorno + '-Transactions.xlsx',
			// title: this.customerdata.debtorno+'.'+this.customerdata.branchcode+' ' +this.customerdata.customer.brname+' Account Receivable',
			// subtitle: opentext+' Types:'+this.type_selected.join(',')+' '+this.fromdateCtrl.value.toLocaleDateString()+' - '+this.todateCtrl.value.toLocaleDateString()+' created '+today.toLocaleString()
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.sending = false;
			this.printing = false;
		});
	}

}
