<div class="container-fluid mt-4" *ngIf="statements">
	<div class="text-center mb-4">
		<a [href]="statementLink" target="_blank" class="btn btn-outline-primary btn-lg">
			<i class="fa fa-file-pdf-o mr-2"></i> View Current Statement (PDF)
		</a>
	</div>
	<ng-container *ngIf="!statements.success">
		<h4 class="text-center text-danger">No Statements Found</h4>
	</ng-container>
	<ng-container *ngIf="statements.success">
		<div class="d-flex justify-content-center mb-4">
			<div class="input-group w-50">
				<mat-form-field appearance="outline" class="flex-grow-1">
					<mat-label>Email Address</mat-label>
					<input tabindex="0" matInput [formControl]="email">
				</mat-form-field>

				<mat-form-field appearance="outline" class="ml-2">
					<mat-label>Aging Date</mat-label>
					<input matInput [matDatepicker]="picker" [formControl]="todate">
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-datepicker #picker></mat-datepicker>
				</mat-form-field>

				<div class="input-group-append">
					<button mat-icon-button color="accent" (click)="emailStatement()" *ngIf="!sending" class="mt-3 ml-2">
						<mat-icon>send</mat-icon>
					</button>
				</div>
				<div class="text-center" *ngIf="loading">
					<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
						<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
					</svg>
				</div>
				<div class="main-container" *ngIf="!loading">
					<div class="editor-container editor-container_classic-editor" #editorContainerElement>
						<div class="editor-container__editor">
							<div #editorElement>
								<ckeditor [formControl]="emailMessage" [editor]="Editor" [config]="ckconfig" *ngIf="isLayoutReady" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="bordered p-4 ml-auto mr-auto smallericonsplease" [innerHTML]="statements.content" *ngIf="!loading"></div>
	</ng-container>
</div>