<div class="main-content">
	<div class="container-fluid">
		<div class="col-md-12 ">
			<div class="card">
				<div class="card-header card-header-{{color}}-6">
					<h4 class="card-title pointer"><i class="material-icons" (click)="back()">arrow_left</i>
						<span class="bold">Price Level Pricing</span>
					</h4>
				</div>
				<div class="card-body  no-overflow">

					<div class="row mb-3">
						<div class="col-md-6">
							<label for="categoryFilter" class="form-label">Category &nbsp;</label>
							<select id="categoryFilter" class="form-select" [(ngModel)]="categoryFilter" (change)="applyFilters()">
								<option value="">All</option>
								<option *ngFor="let category of categories" [value]="category.line_field">{{ category.line_field }}: {{ category.line_description }} </option>
							</select>
						</div>
						<div class="col-md-6">
							<label for="discountFilter" class="form-label">Discount Level &nbsp;</label>
							<select id="discountFilter" class="form-select" [(ngModel)]="discountFilter" (change)="applyFilters()">
								<option value="">All</option>
								<option *ngFor="let level of discountLevels" [value]="level.price_level_id">{{ level.name }}</option>
							</select>
						</div>
					</div>

					<table class="table table-striped table-hover">
						<thead class="thead-dark">
							<tr>
								<th scope="col">Price Name</th>
								<th scope="col">Price Type</th>
								<th scope="col">Category ID</th>
								<th scope="col">Discount Percent</th>
								<!-- <th scope="col">Discount Dollar</th> -->
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of filteredResults">
								<td>{{ item.price_name }}</td>
								<td>{{ item.price_type }}</td>
								<td>{{ item.category_id }}</td>
								<td>
									<input type="number" class="form-control form-control-sm" [(ngModel)]="item.discountpercent" (blur)="savePriceLevelPricing(item)" />
								</td>
								<!-- <td>${{ item.discountdollar }}</td> -->
							</tr>
						</tbody>
					</table>


				</div>
			</div>
		</div>
	</div>
</div>
