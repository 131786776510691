<div class="row m-0 p-0">
	<!-- <ul *ngIf="archived_dates">
		<li *ngFor="let d of archived_dates">
			<div class="pointers" (click)="loadDate(d.dateadded)"></div>
		</li>
	</ul> -->
	<div class="col-md-4  mt-3">
		<ng-container *ngIf="archived_dates">
			<mat-form-field appearance="fill">
				<mat-label>Dates Available</mat-label>
					<mat-select (selectionChange)="loadDate($event.value)">
					<mat-option *ngFor="let d of archived_dates" [value]="d.dateadded">
					{{ d.dateadded | date}}
					</mat-option>
					</mat-select>
			</mat-form-field>
		</ng-container>
	</div>
</div>
<div class="row m-0 p-0" *ngIf="archived_data">
	<div class="col-12">
		<h3>{{ date_selected | date}}</h3>
		<a class="btn btn-danger" (click)="exportPdf();"><i class="fa fa-file-pdf-o"></i></a>
		<a class="btn btn-success" (click)="exportXls();"><i class="fa fa-file-excel-o"></i></a>

		<div class="table w-100" #print_table>
			<ng-container *ngIf="archived_data">
				<div #print_table class="container-fluid p-3">
					<div id="results" class="w-100" >
						<!-- <div class="table">
							<h5>Totals</h5>
							<table calss="table table-sm">
								<tr>
									<th class="text-center">Total Units</th>
									<th class="text-right">Total Value</th>
								</tr>
								<tr>
									<td class="text-center">{{ report.totals.count }}</td>
									<td class="text-right">{{ report.totals.total |currency }}</td>
								</tr>
							</table>
						</div> -->
						<div class="table">
							<h5>Inventory Value Summary</h5>
							<table calss="table table-sm">
								<tr>
									<th>ID#</th>
									<th>Desc</th>
									<th class="text-center">Qty</th>
									<th class="text-right">Total</th>
								</tr>
								<tr *ngFor="let det of archived_data.summary">
									<td>{{ det.categoryid }}</td>
									<td>{{ det.description }}</td>
									<td class="text-center">{{ int(det.inventory_count) }}</td>
									<td class="text-right">{{ financial(det.inventory_value) |currency }}</td>
								</tr>
							</table>
						</div>
						<div class="table" >
							<h5>Inventory Value Details</h5>
							<table calss="table table-sm">
								<tr>
									<th>Item#</th>
									<th></th>
									<th>Desc</th>
									<th class="text-center">Qty</th>
									<th>units</th>
									<th class="text-right">Cost</th>
									<th class="text-right">Total</th>
								</tr>
								<ng-container *ngFor="let det of archived_data.details">
									<ng-container *ngIf="det.category_break == '1'">
										<tr>
											<th>{{ det.categoryid }}</th>
											<th>{{ det.categorydescription }}</th>
											<th></th>
											<th></th>
											<th></th>
											<th class="text-right">UNITS: {{ getCategoryTotalUnits(det.categoryid) }}</th>
											<th class="text-right">TOTAL: {{ getCategoryTotal(det.categoryid) | currency }}</th>
										</tr>
									</ng-container>
									<tr>
										<td>{{ det.stockid }}</td>
										<td><span *ngIf="det.debtorno">{{ det?.debtorno }}.{{ det?.branchcode }}:</span> {{ det.brname }}</td>
										<td>{{ det.description }}</td>
										<td class="text-center">{{ det.qtyonhand }}</td>
										<td>{{ det.units }}</td>
										<td class="text-right">{{ det.unitcost | currency }}</td>
										<td class="text-right">{{ det.itemtotal | currency}}</td>
									</tr>
								</ng-container>
							</table>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>
