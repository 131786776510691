<ng-template #consignEdit let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Consign from Stock</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-header text-center">
					<h4 class="card-title text-center"></h4>
				</div>
				<div class="card-body text-center">
					<div class="row">
						<div class="col-12">

							<div classs="col-4">
								<mat-form-field appearance="outline" class="">
									<mat-label>Branch</mat-label>
									<mat-select (selectionChange)="setItemLocation($event)" [value]="customer.branchcode">
										<mat-option *ngFor="let k of customerdata.branches" [value]="k.branchcode">
											{{ k.branchcode }} {{ k.brname }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div classs="col-8">
							<mat-form-field appearance="outline">
								<mat-label>Part Number</mat-label>
								<input (keyup)="autocompleteFilter()" type="text" [(ngModel)]="addstockid" class="text-left " matInput [matAutocomplete]="stocksuggest" />
								<mat-autocomplete autoActiveFirstOption #stocksuggest="matAutocomplete">
									<mat-option *ngFor="let item of filteredOptions" [value]="item.stockid" (onSelectionChange)="getItemBins(item.stockid)">
										<b>{{ item.stockid }}</b>: {{ item.description }} ({{ item.qty }})
									</mat-option>
								</mat-autocomplete>
							</mat-form-field>
							</div>
							<table class="table" *ngIf="itembins.length">
								<tr>
									<th>Bin Number</th>
									<th>Qty Available</th>
									<th>Qty</th>
								</tr>
								<tr *ngFor="let item of itembins">
									<td>{{item.bin}}</td>
									<td>{{item.quantity}}</td>
									<td>
										<mat-form-field appearance="outline">
											<input matInput [(ngModel)]="item.quantityreq">
										</mat-form-field>
									</td>
								</tr>
							</table>
							<button mat-stroked-button color="success" aria-label="" (click)="moveConsignmentItem()" class="mt-2">
								<mat-icon>save</mat-icon> Move Items
							</button>


						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #returnEdit let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Return to Stock</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-header text-center">
					<h4 class="card-title text-center"></h4>
				</div>
				<div class="card-body text-center">
					<div class="row">
						<div class="col-6">

							<mat-form-field appearance="outline">
								<mat-label>Part Number</mat-label>
								<input type="text" [(ngModel)]="addstockid" class="text-left " matInput disabled="disabled" />

							</mat-form-field>
						</div>
						<div class="col-6">
							Remaining: {{max_qty}}
						</div>
					</div>
					<div class="row">
						<div class="col-4">
							<mat-form-field appearance="outline">
								<mat-label>Scan to Bin</mat-label>
								<input [(ngModel)]="putawaybin" type="text" class="text-left " matInput />

							</mat-form-field>
						</div>
						<div class="col-4">
							<mat-form-field appearance="outline">
								<mat-label>Qty</mat-label>
								<input [(ngModel)]="putawayqty" type="number" class="text-right " matInput />

							</mat-form-field>
						</div>
						<div class="col-4">
							<button mat-stroked-button color="success" aria-label="" (click)="assignConsignmentItem()" class="mt-2">
								<mat-icon>save</mat-icon> Assign
							</button>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<table *ngIf="putaway.length">
								<tr>
									<th>Bin</th>
									<th>Qty</th>
								</tr>
								<tr *ngFor="let bin of putaway">
									<td> {{bin.bin}}</td>
									<td> {{bin.qty}}</td>
								</tr>
								<tr>
									<td> Total: </td>
									<td> {{total_qty}} </td>
								</tr>
							</table>
							<button mat-stroked-button color="success" aria-label="" (click)="removeConsignmentItem()" class="mt-2">
								<mat-icon>save</mat-icon> Move Items
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="row m-0 p-0 mt-3">
	<button class="btn btn-sm ml-auto mr-0" mat-stroked-button (click)="addConsignmentItem()"> Add Item </button>
	<div class="table table-sm ">
		<table mat-table class="table table-sm" [dataSource]="consignitems">
			<ng-container matColumnDef="stockid">
				<th mat-header-cell *matHeaderCellDef>Item Number</th>
				<td mat-cell *matCellDef="let item">
					{{ item.stockid }}
				</td>
			</ng-container>
			<ng-container matColumnDef="brname">
				<th mat-header-cell *matHeaderCellDef>ShipTo</th>
				<td mat-cell *matCellDef="let item">
					{{ item.brname }}
				</td>
			</ng-container>
			<ng-container matColumnDef="description">
				<th mat-header-cell *matHeaderCellDef>Description</th>
				<td mat-cell *matCellDef="let item">
					{{ item.description }}
				</td>
			</ng-container>
			<ng-container matColumnDef="date_stocked">
				<th mat-header-cell *matHeaderCellDef>Date Last Consigned</th>
				<td mat-cell *matCellDef="let item">
					{{ item.date_stocked | date: 'MM/dd/yyyy' }}
				</td>
			</ng-container>
			<ng-container matColumnDef="quantity">
				<th mat-header-cell *matHeaderCellDef>Consigned Quantity</th>
				<td mat-cell *matCellDef="let item">
					{{item.qty | number : '1.0-0' }}
				</td>
			</ng-container>
			<ng-container matColumnDef="actions">
				<th mat-header-cell *matHeaderCellDef></th>
				<td mat-cell *matCellDef="let item">
					<button mat-stroked-button (click)="removeConsignItem(item.stockid,item.qty)"> Remove </button>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="headercolumns"></tr>
			<tr mat-row *matRowDef="let row; columns: headercolumns"></tr>
		</table>
	</div>
</div>
