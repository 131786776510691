import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
	selector: '[appSelectOnFocus]'
})
export class SelectOnFocusDirective {
	constructor(private el: ElementRef) {}

	@HostListener('focus', ['$event'])
	@HostListener('click', ['$event'])
	onFocus() {
		const el = this.el.nativeElement;
		const len = el.value.length;
		if (len) {
			el.setSelectionRange(0, len)
		}
	}
	onClick() {
		const el = this.el.nativeElement;
		el.focus();
	}
}
