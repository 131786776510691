import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service'

const routes: Routes = [
	{
		path: '',
		redirectTo: 'dashboard',
		pathMatch: 'full',
		//canActivate: [AuthGuard]
	},
	{
		path: 'auth/login',
		component: LoginComponent,
	},
	{
		path: 'auth/logout',
		component: LogoutComponent,
	},
	{
		path: '',
		component: AdminLayoutComponent,
		canActivate: [AuthGuard],
		children: [{
			path: '',
			loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
		}],
	}
];


@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		RouterModule.forRoot(routes, {
			useHash: true,
			onSameUrlNavigation: 'reload',
			//scrollPositionRestoration: 'enabled',
		})
	],
	exports: [],
})
export class AppRoutingModule {}
