import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Location } from '@angular/common'

import { merge, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

declare let $: any;

@Component({
	selector: 'app-account-groups',
	templateUrl: './account-groups.component.html',
	styleUrls: ['./account-groups.component.scss']
})
export class AccountGroupsComponent implements OnInit {
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('locationsearch') customersearch: ElementRef;

	loccode: any = '';
	locselected = false;
	ld: any = [];
	term: any = '';

	taxgroup: any = '';
	taxes: any = [];
	currencies: any = [];
	bankaccounts: any = [];
	types: any = [];
	zones: any = [];
	groupForm: any = false;
	sections: any = [];
	groups: any = [];
	pandl: any = [];
	groupsTable: any = [];
	results: any = [];
	headercolumns: string[] = [
		'actions',
		'groupname',
		'sectioninaccounts',
		'pandl',
		'sequenceintb',
		'parentgroupname',
	];

	color: any = '';
	config: any = [];
	user: any = [];



	constructor(private route: ActivatedRoute, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, private fb: UntypedFormBuilder, public globalsService: GlobalsService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});

		this.color = this.globalSearchService.getColor();
		this.globalSearchService.user.subscribe((results: any) => {
			if (results) {
				this.user = results.user;
			}
		});

	}


	ngOnInit(): void {

		this.globalsService.getAccountSections().subscribe(details => {
			this.sections = details;
		});
		this.globalsService.getAccountGroups().subscribe(details => {
			this.groups = details;
		});

		this.route.params.subscribe(params => {
			const loccode = params['id'];
			if (loccode) {

				this.globalsService.getAccountGroup(loccode).subscribe((results: any) => {
					this.ld = results;
					//this.defaults.region
					this.groupForm = this.fb.group({
						startingname: [results.groupname, []],
						groupname: [results.groupname, [Validators.required]],
						sectioninaccounts: [results.sectioninaccounts, [Validators.required]],
						pandl: [results.pandl, []],
						sequenceintb: [results.sequenceintb, [Validators.required]],
						parentgroupname: [results.parentgroupname, []]
					});

				});
			} else {
				this.globalsService.getAccountGroups().subscribe((results: any) => {
					this.groupsTable = new MatTableDataSource(results);
					this.results = results
					this.groupsTable.sort = this.sort;
					this.groupsTable.paginator = this.paginator;

				});
			}

		});



	}
	updateGroup() {
		if (this.groupForm.valid) {
			this.globalsService.updateGroup(this.groupForm.value).subscribe(details => {

				this.router.navigate(['system/gl-accounts/account-groups']);
			});
		}

	}
	deleteGroup(row: any) {

		this.globalsService.deleteGroup(row).subscribe(details => {

			if (details.error) {
				this.showNotification(details.error, 'danger', 'top', 'center');
			} else {
				this.showNotification('Group Deleted', 'success', 'top', 'center');
				this.globalsService.getAccountGroups().subscribe((results: any) => {
					this.groupsTable = new MatTableDataSource(results);
					this.results = results
					this.groupsTable.sort = this.sort;
					this.groupsTable.paginator = this.paginator;
				});
			}
		});
	}
	selectGroup(row: any) {
		this.router.navigate(['./system/gl-accounts/account-groups/' + row.groupname]);
	}
	ngAfterViewInit() {
		this.globalsService.getAccountGroups().subscribe((results: any) => {
			this.groupsTable = new MatTableDataSource(results);
			this.results = results
			this.groupsTable.sort = this.sort;
			this.groupsTable.paginator = this.paginator;
		});
	}
	announceSortChange(sortState: Sort) {}
	back(): void {
		this.location.back()
	}
	showNotification(message, type, from, align) {
		//  const type = ['','info','success','warning','danger'];
		// const color = Math.floor((Math.random() * 4) + 1);

		$.notify({
			icon: 'notifications',
			message: message

		}, {
			type: type,
			timer: 1000,
			placement: {
				from: from,
				align: align
			},
			template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
				'<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
				'<i class="material-icons" data-notify="icon">notifications</i> ' +
				'<span data-notify="title">{1}</span> ' +
				'<span data-notify="message">{2}</span>' +
				'<div class="progress" data-notify="progressbar">' +
				'<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
				'</div>' +
				'</div>'
		});
	}
}