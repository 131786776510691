import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { VendorService } from '../../../services/vendor.service';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup,FormControl, ValidationErrors } from '@angular/forms';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';

@Component({
	selector: 'app-vendor-auto-complete',
	templateUrl: './vendor-auto-complete.component.html',
	styleUrls: ['./vendor-auto-complete.component.scss']
})
export class VendorAutoCompleteComponent implements OnInit {
	vendorSearch = new FormControl('');
	searching = false
	searchsubscription: any = false;
	allResults: any = false;
	filteredItems: any = false;
	keys = 'supplierid,suppname';

	@Output() vendor_selected = new EventEmitter < any > ();

	constructor(private globalSearchService: GlobalSearchService,public vendorService: VendorService) {}

	ngOnInit(): void {

		this.vendorService.getVendors().subscribe((results:any) => {
			this.allResults = results
			this.filteredItems = results;
		});

		this.vendorSearch.valueChanges.subscribe( newValue => {
			this.search();
		})
	}

	search() {

		if(this.searchsubscription) {
			this.searchsubscription.unsubscribe();
		}

		const searchvalue = this.vendorSearch.value;
		if(this.vendorSearch.value == '') {
			this.filteredItems = this.allResults;
		} else {
			this.filteredItems = this.globalSearchService.filterItem(this.allResults,this.vendorSearch.value, this.keys)
		}
	}

	selectVendor(row: any) {
		this.vendor_selected.emit(this.vendorSearch.value)
	}

	displayFn(vendor: any) {

		if(!vendor) {
			return '';
		}

		return vendor.supplierid + '-' + vendor.suppname
	}

}
