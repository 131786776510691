<div class="table" >
	<div class="row">
		<div class="col-md-2">
			<mat-form-field appearance="outline" class="" *ngIf="taxes && !user.user.issalesman">
				<mat-label>Tax Auth</mat-label>
				<mat-select [formControl]="taxfilter" (selectionChange)="filterCustomers()" multiple>
					<mat-option [value]="false">All</mat-option>
					<mat-option *ngFor="let t of taxes" [value]="t.taxgroupid">
						{{ t.taxgroupdescription }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-2">
			<mat-form-field appearance="outline" class="" *ngIf="salespeople && !user.user.issalesman">
				<mat-label>Salesperson</mat-label>
				<mat-select [formControl]="salesfilter"  (selectionChange)="filterCustomers()" multiple>
					<mat-option [value]="false">All</mat-option>
					<mat-option *ngFor="let t of salespeople" [value]="t.salesmancode">
						{{ t.salesmanname }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-3">
			<mat-form-field appearance="outline" >
				<mat-label>Search Filters</mat-label>
				<mat-select [formControl]="filteredBy" multiple #filters>
					<div class="select-all" (click)="selectAllToggle()">
						<button mat-icon-button>
							<span ml="1">Select All/None</span>
						</button>
					</div>
					<mat-option *ngFor="let filter of filterList" [value]="filter['value']">{{filter['view']}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-2">
			<mat-form-field class="full-width text-right" appearance="outline" color="accent">
				<mat-label>Search</mat-label>
				<input matInput placeholder="Search" class="text-right" [formControl]="search" autocomplete="off" (input)="filterCustomers()">
			</mat-form-field>
		</div>
		<div class="ml-auto mr-0 col-md-3">
			<ng-container *ngIf="!sending">
				<button class="btn btn-sm btn-success" (click)="exportXls();" *ngIf="data"><i class="fa fa-file-excel-o"></i></button>
				&nbsp;&nbsp;
				<button class="btn btn-sm btn-danger" (click)="exportPdf();" *ngIf="data"><i class="fa fa-file-pdf-o"></i></button>
				&nbsp;&nbsp;
			</ng-container>
			<ng-container *ngIf="sending">
				<svg class="spinner" width="30px" height="30px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
					<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
				</svg>
			</ng-container>
			<button mat-raised-button color="accent" class="mt-2" (click)="filterCustomers()" *ngIf="!sending">
				<mat-icon>refresh</mat-icon>
			</button>
		</div>
	</div>

	<div class="table table-responsive table-fluid table-fixed w-100"  >
		<mat-paginator [ngClass]="tabledata ? '': 'd-none'" [pageSizeOptions]="[50, 100, 500]" showFirstLastButtons aria-label="select page">
		</mat-paginator>
		<table mat-table class="table table-responsive table-fluid table-fixed table-hover w-100" [dataSource]="tabledata" matSort>
			<ng-container matColumnDef="debtorno">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Customer#</th>
				<td mat-cell *matCellDef="let d">
					<a [routerLink]="'/customers/view/'+d.debtorno">{{ d.debtorno }}</a>
				</td>
			</ng-container>
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Name#</th>
				<td mat-cell *matCellDef="let d">
					{{ d.name }}
				</td>
			</ng-container>
			<ng-container matColumnDef="address1">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Address1#</th>
				<td mat-cell *matCellDef="let d">
					{{ d.address1 }}
				</td>
			</ng-container>
			<ng-container matColumnDef="address2">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Address2#</th>
				<td mat-cell *matCellDef="let d">
					{{ d.address2 }}
				</td>
			</ng-container>
			<ng-container matColumnDef="address3">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> City</th>
				<td mat-cell *matCellDef="let d">
					{{ d.address3 }}
				</td>
			</ng-container>
			<ng-container matColumnDef="address4">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Region</th>
				<td mat-cell *matCellDef="let d">
					{{ d.address4 }}
				</td>
			</ng-container>
			<ng-container matColumnDef="address5">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Postal</th>
				<td mat-cell *matCellDef="let d">
					{{ d.address5 }}
				</td>
			</ng-container>
			<ng-container matColumnDef="phoneno">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Phone</th>
				<td mat-cell *matCellDef="let d">
					{{ d.phoneno | phoneFormat }}
				</td>
			</ng-container>
			<ng-container matColumnDef="email">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
				<td mat-cell *matCellDef="let d">
					{{ d.email }}
				</td>
			</ng-container>
			<ng-container matColumnDef="salesman">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> Salesman#</th>
				<td mat-cell *matCellDef="let d">
					{{ d.salesman }}
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="headercolumns;sticky: true;"></tr>
			<tr mat-row *matRowDef="let row; columns: headercolumns"></tr>
		</table>
	</div>
</div>
