<ng-template #addbinref let-modal>
	<div class="modal-header">
		<h4 class="modal-title">
			Adding to Verified Bin List
		</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="row col-10 m-auto p-0">
			<div class="col-6">
				<mat-form-field appearance="outline">
					<mat-label>Bin Name</mat-label>
					<input matInput placeholder="Enter name for bin" [formControl]="binid">
				</mat-form-field>
			</div>
			<div class="col-4">
				<mat-form-field>
					<mat-label>Location</mat-label>
					<mat-select appearance="outline" [formControl]="loc">
						<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
							{{loc.locationname}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<div class="col-12 text-center">
			<button mat-raised-button color="primary" [disabled]="alreadyExists || loc.value == '' || binid.value == ''" class="m-auto" (click)="dismissModal()">Add Bin</button>
			<p *ngIf="alreadyExists" class="text-danger">Verified bin exists</p>
			<p *ngIf="loc.value == ''" class="text-danger">Location not selected</p>
		</div>
	</div>
</ng-template>

<div class="row col-10 m-auto p-2">
	<div class="col-8">
		<mat-form-field class="example-full-width">
			<mat-label>Bin Search</mat-label>
			<input matInput placeholder="Search for a bin" (keyup)="filter($event)">
		</mat-form-field>
	</div>
	<div class="col-2">
		<button mat-raised-button (click)="addNewBin()" color="primary">Add New Verified Bin</button>
	</div>
</div>

<div class="col-10 p-2 m-auto">
	<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg" *ngIf="loading">
		<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
	</svg>
	<table mat-table [dataSource]="dataSource" class="mat-elevation-z1" *ngIf="!loading">
		<ng-container *ngFor='let col of displayedColumns; let i = index'>
			<ng-container matColumnDef="{{col}}">

				<th mat-header-cell *matHeaderCellDef [ngClass]="[
                    col == 'bin' ? 'text-left' : '',
                    col == 'loccode' ? 'text-center' : '',
                    col == 'action' ? 'text-right' : ''
                ]">{{columnHeaders[i]}}</th>

				<td mat-cell *matCellDef="let element" [ngClass]="[
                    col == 'bin' ? 'text-left':'',
                    col == 'loccode' ? 'text-center' : '',
                    col == 'action' ? 'text-right' : ''
                ]">
					<ng-container *ngIf="col != 'action'"> {{element[col]}} </ng-container>
					<ng-container *ngIf="col == 'action'">
						<button mat-icon-button (click)="removeBin(element)" class="text-danger"><mat-icon>delete</mat-icon></button>
					</ng-container>
				</td>

			</ng-container>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
	</table>
	<mat-paginator [pageSizeOptions]="[50, 100, 250]" showFirstLastButtons>
	</mat-paginator>
</div>