import { Component, EventEmitter, Output, OnInit, ViewChild, ElementRef, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Location } from '@angular/common'
import { InventoryService } from '../../services/inventory.service';
import { OrdersService } from '../../services/orders.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge, fromEvent , Subscription} from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay , switchMap} from 'rxjs/operators';


@Component({
	selector: 'app-inventory-searcher',
	templateUrl: './inventory-searcher.component.html',
	styleUrls: ['./inventory-searcher.component.scss']
})
export class InventorySearcherComponent implements OnInit {
	@Input() rule: any = false;
	@Input() ispromo: any = false;
	@Input() forceupdate: any = false;
	@Output() saveresults = new EventEmitter < any > ();

	@ViewChild('searchItems') searchItemsref: ElementRef;
	searchSubscription: Subscription | null = null;
	results: any = [];
	categories: any = false;
	productlines: any = false;
	brands: any = [];
	user: any = false;
	brand = new UntypedFormControl();
	productLine = new UntypedFormControl();
	rangestart = new UntypedFormControl();
	rangeend = new UntypedFormControl();
	category = new UntypedFormControl();
	keywords = new UntypedFormControl();
	products: any = false;
	inventorysearching: any = false;
	allitems: any = [];
	selectedproducts: any = [];
	keywordresults: any = false;
	exclusions: any = [];

	constructor(private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, public router: Router, private location: Location, public inventoryService: InventoryService, public orderService: OrdersService, private globalSearchService: GlobalSearchService) {

	}

	ngOnInit(): void {

		this.inventoryService.getItemCategories().subscribe(c => {
			this.categories = c
		});

		this.inventoryService.getItemProductlines().subscribe(p => {
			this.productlines = p
			this.brand.valueChanges.subscribe(r => {
				this.updateProductLines();
			});
		});

		this.inventoryService.getMfgs().subscribe(r => {
			this.brands = r
		});

		this.globalSearchService.user.subscribe(r => {
			this.user = r;
		});

		this.keywords.valueChanges.subscribe(newValue => {
			this.searchKeyWords();
		});
	}

	//keyword results separate from normal exclusion. whole program is really a rule builder.
	searchKeyWords() {
	  if (this.keywords?.value) {
		if (this.searchSubscription) {
		  this.searchSubscription.unsubscribe();
		}

		const input = {
		  stockid: this.keywords.value
		};

		this.searchSubscription = this.keywords.valueChanges.pipe(
		  debounceTime(500), // Debounce time of 300ms
		  switchMap(() => this.inventoryService.lookup(input))
		).subscribe(r => {
		  this.keywordresults = r;
		});
	  }
	}
	addMMItem(item:any) {

		const exists = this.selectedproducts.filter(r => {
			return r.stockid == item.stockid;
		})[0];

		if(!exists) {
			const newprod = { stockid: item.stockid, description: item.description }
			this.selectedproducts.push(newprod);
			this.keywords.setValue('');
			this.search();
		}
	}

	search() {

		const filters = {
			manufacturers: this.brand.value,
			productids: this.products,
			productlines: this.productLine.value,
			categories: this.category.value,
			range_start: this.rangestart.value,
			range_end: this.rangeend.value,
			exclusions: this.exclusions
		}

		let procced = false;

		for (const [key, value] of Object.entries(filters)) {
			if(value && value !== null && value != '') {
				procced = true;
			}
		}


		if(this.inventorysearching) {
			this.inventorysearching.unsubscribe();
		}

		if(procced) {
			//firing too many times
			this.inventorysearching = this.inventoryService.getMixItems(filters).subscribe((items: any) => {
				this.results = items;

				this.globalSearchService.blurInputElement(this.searchItemsref)
			});
		}
	}

	emitMM(){
		const filters = {
			manufacturers: this.brand.value,
			products: this.selectedproducts,
			productlines: this.productLine.value,
			categories: this.category.value,
			range_start: this.rangestart.value,
			range_end: this.rangeend.value,
			exclusions: this.exclusions
		}
		this.saveresults.emit(filters);
	}

	updateProductLines() {

		const data = {
			brands: this.brand.value
		}

		this.inventoryService.getBrandProductlines(data).subscribe( (r:any) => {
			this.productlines = r;
			this.search();
		});
	}


	removeItem(item:any) {
		const index = this.selectedproducts.indexOf(item);
		this.selectedproducts.splice(index,1);
		this.search();
	}

	exludeItem( item:any) {
		const index = this.results.indexOf(item);
		if(index >= 0) {
			this.results.splice(index, 1);
			this.exclusions.push(item);
		}
		this.search();
	}

	removeExclusion(item:any) {

		const ob = this.exclusions.filter( (i:any) => {
			return i.stockid == item.stockid;
		})[0];

		const index = this.exclusions.indexOf(ob);
		this.exclusions.splice(index,1);
		this.search();
	}



	ngOnChanges(change: any) {
		//this is never gonna let
		if(change.rule) {
			const rule = change.rule.currentValue;

			this.brands = rule.manufactures;
			this.categories = rule.categories;
			this.productlines = rule.product_lines;

			console.log(rule);


			if(rule.selectedmanufacturers) {
				const brandvalues = [];
				rule.selectedmanufacturers.forEach( r => {

					const va = this.brands.filter(p => {
						return p.name = r
					})[0];

					if(va) {
						brandvalues.push(va.name)
					}
				});
				this.brand.setValue(brandvalues);
			}

			if(rule.selectedcategories) {
				const catvalues = [];
				rule.selectedcategories.forEach(p => {
					catvalues.push(p)
				});

				this.category.setValue(catvalues);
			}

			if(rule.selectedlines) {
				const pvalues = [];
				rule.selectedlines.forEach(p => {
					pvalues.push(p)
				});
				// console.log(pvalues, rule.selectedlines);
				this.productLine.setValue(pvalues);
			}

			if(rule.range_start) {
				this.rangestart.setValue(rule.range_start)
			}

			if(rule.range_end) {
				this.rangeend.setValue(rule.range_end)
			}

			if(rule.exlusions) {
				this.exclusions = rule.exlusions.map(ent=>{
					return ent.stockid
				})
			}

			if(rule.productssel) {
				this.selectedproducts = rule.productssel
			}

			//run rule search.
			this.search();

		}

	}
}
