<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card tp-box">
					<div class="card-header card-header-{{ color }}-6">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Purchase Order</h4>
						<p class="card-category">Purchasing</p>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-6 text-left">
								<h4 [innerHTML]="purchaseorder.address">
								</h4>
							</div>
							<div class="col-6 text-right">
								<h4> {{ purchaseorder.poheader.orddate }}</h4>
								<h4 [innerHTML]="purchaseorder.toaddress">
								</h4>
							</div>
						</div>

						<div class="row">
							<div class="col-6 text-left">
								<h5 [innerHTML]="purchaseorder.poheader.stat_comment">
								</h5>
							</div>
							<div class="col-6 text-right">
								<h5 [innerHTML]="purchaseorder.poheader.polinenotes">
								</h5>
							</div>
						</div>

						<div class="row">
							<div class="table">
								<table class="table table-sm">
									<tr>
										<th>Item#</th>
										<th>Description</th>
										<th>Invoiced</th>
										<th>Received</th>
										<th>Ordered</th>
										<th>Total</th>
									</tr>
									<tr *ngFor="let line of purchaseorder.polines">
										<td>{{ line.itemcode }} </td>
										<td>{{ line.itemdescription }} </td>
										<td>{{ line.qtyinvoiced }} </td>
										<td>{{ line.quantityrecd }} </td>
										<td>{{ line.quantityord }} </td>
										<td>{{ line.unitprice * line.quantityord | currency }} </td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
