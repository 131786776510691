import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { PurchasingService } from '../../../services/purchasing.service';
import { InventoryService } from '../../../services/inventory.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


@Component({
	selector: 'app-purchasing-reordering-minmax-two',
	templateUrl: './purchasing-reordering-minmax-two.component.html',
	styleUrls: ['./purchasing-reordering-minmax-two.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(180deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotateY(180deg)'
			})),
			transition('active => inactive', animate('500ms ease-out')),
			transition('inactive => active', animate('500ms ease-in')),
			transition('inactive => orderlookup', animate('500ms ease-out')),
			transition('orderlookup => inactive', animate('500ms ease-in')),
		])
	]
})
export class PurchasingReorderingMinmaxTwoComponent implements OnInit {
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	@ViewChild('purchasedetails') purchasedetails: ElementRef;
	@ViewChild('itemhistoryele') itemhistoryele: ElementRef;
	flip = 'inactive';
	locations: any = [];
	reportForm: UntypedFormGroup;
	minControl = new UntypedFormControl('')
	brandsFilter = new UntypedFormControl()
	today = new Date();
	results: any = false;
	loading = false;

	suppliers: any = [];
	purchase_item: any = [];
	foundvendors: any = [];
	itemhistory: any = false;
	item: any = false;
	purchitem: any = false;
	months: any = false;
	futuremonths: any = false;
	loading_remote: any = false;
	noresults: any = false;
	filters: any = [];
	selected_filters: any = [];
	selected_checks: any = [];
	purchase_items: any = [];
	po_vendors: any = [];
	remoteqtys: any = [];
	allresults: any = [];
	vendors: any = [];
	watch_items: any = [];
	fully_available = new UntypedFormControl(false)
	fillMode = new UntypedFormControl(true);
	allitems: any = [];
	allpurchaseitems: any = [];
	po_vendors_all: any = [];
	//
	po_vendors_found: any = false;
	//starts at 1 - preferred vendor
	indexnow = 1
	wathchlist: any = [];
	datasets: any = [];
	holditem: any = [];
	sending = false;
	currentstep = 0;
	reviewlabel = 'Review Primary Recommendations'
	retriving: any = false;
	brands: any = [];
	headercolumns: any = [
		'vendor',
		'stockid',
		'description',
		//'brand_data',
		'qoh',
		//'qop',
		'qos',
		'qot',
		'price',
		'qty_purchase',
		//'minimum',
		//'maximum',
		//'purchdata',
		//'qos',
		//'qop',
		//'qoh',
		//'minimum',
		//'maximum',
		//	'total_available',
		//'price',
		//	'target_purchase',
	];

	winter_values: any = [{ 'label': 'Non Winter Tires', 'value': '0' }, { 'label': 'Winter Tires', 'value': '1' }, { 'label': '3-Peak All-Weather', 'value': '2' }];

	config: any = [];
	user: any = false;
	updating: boolean = false;



	constructor(private cdr: ChangeDetectorRef,private inventoryService: InventoryService, private purchasingService: PurchasingService, private globalSearchService: GlobalSearchService, private location: Location, private route: ActivatedRoute, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal) {
		this.config = this.globalsService.getApiConfig();
		this.globalSearchService.user.subscribe(results => {
			this.user = results
		});
	}

	ngOnInit(): void {
		this.purchasingService.getFiltersTwo().subscribe(async (result: any) => {
			this.filters = result;
		});

		this.purchasingService.getSuppliers('').subscribe(async (results: any) => {
			this.suppliers = results;
			this.reportForm = this.fb.group({
				startdate: [this.today, Validators.required],
				minimum_sales: [0, Validators.required],
				item_type: [1, Validators.required],
				numberofmonths: [2, Validators.required],
				numberofweeks: [8, Validators.required],
				loccode: [this.user.user.defaultlocation.loccode, Validators.required],
				filters: [],
				brands: [],
				season: [],
				sku: [''],
				supplier: ['', Validators.required],
			});

			this.inventoryService.getBrands().subscribe((r: any) => {
				this.brands = r;

				const selected = [];
				this.brands.forEach(a => {
					selected.push(a.name)
				})
				this.reportForm.get('brands').setValue(selected)
			})

			this.reportForm.get('sku').valueChanges.subscribe(newValue => {
				if (newValue !== '') {
					this.setFieldsNotRequired();
				} else {
					this.setFieldsRequired();
				}
			});

			this.fillMode.valueChanges.subscribe(newValue => {
				if (this.po_vendors_found) {
					this.updating = true;
					this.cdr.detectChanges();
					setTimeout(() => {
						this.processVendors(newValue);
					}, 500);
				}
			});
		})
	}

	getTotalPurchase(): number {
		let total = 0;
		if(this.po_vendors_found) {
			for (let vendor of this.po_vendors_found) {
				if (this.results[vendor]) {
					for (let line of this.results[vendor]) {
						if (line.qty_purchase > 0 && line.selected_option) {
							total += parseFloat(line.qty_purchase);
						}
					}
				}
			}
		}

		return Math.round(total * 100) / 100;
	}

	getTotalCost(): number {
		let totalCost = 0;

		for (let vendor of this.po_vendors_found) {
			if (this.results[vendor]) {
				for (let line of this.results[vendor]) {
					if (line.qty_purchase > 0 || line.altered === true) {
						totalCost += line.qty_purchase * line.selected_option.price;
					}
				}
			}
		}

		return totalCost;
	}

	processVendors(newValue: boolean) {
		this.updating = true;
		this.cdr.detectChanges();

		const vendors = this.po_vendors_found.slice(); // Copy the array

		const processChunk = () => {
			const vendor = vendors.shift();
			if (!vendor) {
				this.updating = false;
				this.cdr.detectChanges();
				return;
			}

			for (let item of this.results[vendor]) {
				if (newValue) {
					if (item.original_qty_purchase !== undefined) {
						item.qty_purchase = item.original_qty_purchase;
					}
					if (item.original_target_purchase !== undefined) {
						item.target_purchase = item.original_target_purchase;
					}
				} else {
					if (item.original_qty_purchase === undefined) {
						item.original_qty_purchase = item.qty_purchase;
					}
					if (item.original_target_purchase === undefined) {
						item.original_target_purchase = item.target_purchase;
					}

					item.qty_purchase = item.qty_purchase_min;
					item.target_purchase = item.target_qty_display_min;
				}
			}

			requestAnimationFrame(processChunk);
		};

		requestAnimationFrame(processChunk);
	}


	onToggleChange(event: any) {

	}

	setFieldsNotRequired() {
		this.reportForm.get('startdate').clearValidators();
		this.reportForm.get('minimum_sales').clearValidators();
		this.reportForm.get('item_type').clearValidators();
		this.reportForm.get('numberofmonths').clearValidators();
		this.reportForm.get('numberofweeks').clearValidators();
		this.reportForm.get('loccode').clearValidators();
		this.reportForm.get('supplier').clearValidators();

		this.reportForm.get('startdate').updateValueAndValidity();
		this.reportForm.get('minimum_sales').updateValueAndValidity();
		this.reportForm.get('item_type').updateValueAndValidity();
		this.reportForm.get('numberofmonths').updateValueAndValidity();
		this.reportForm.get('numberofweeks').updateValueAndValidity();
		this.reportForm.get('loccode').updateValueAndValidity();
		this.reportForm.get('supplier').updateValueAndValidity();
	}

	setFieldsRequired() {
		this.reportForm.get('startdate').setValidators(Validators.required);
		this.reportForm.get('minimum_sales').setValidators(Validators.required);
		this.reportForm.get('item_type').setValidators(Validators.required);
		this.reportForm.get('numberofmonths').setValidators(Validators.required);
		this.reportForm.get('numberofweeks').setValidators(Validators.required);
		this.reportForm.get('loccode').setValidators(Validators.required);
		this.reportForm.get('supplier').setValidators(Validators.required);

		this.reportForm.get('startdate').updateValueAndValidity();
		this.reportForm.get('minimum_sales').updateValueAndValidity();
		this.reportForm.get('item_type').updateValueAndValidity();
		this.reportForm.get('numberofmonths').updateValueAndValidity();
		this.reportForm.get('numberofweeks').updateValueAndValidity();
		this.reportForm.get('loccode').updateValueAndValidity();
		this.reportForm.get('supplier').updateValueAndValidity();
	}

	updateBrands(event: any) {
		this.reportForm.get('supplier').setValue(event.supplierid)
		const supplierid = event.supplierid;
		const defaultBrands = this.brands.filter(r => {
			return supplierid == r.a2
		})
		const selected = [];
		defaultBrands.forEach(a => {
			selected.push(a.name)
		})

		if (!selected.length) {
			// this.brands.forEach( a=> {
			// 	selected.push(a.name)
			// })
		}

		this.reportForm.get('brands').setValue(selected)
	}

	resetItem() {
		this.purchitem = this.holditem;
	}

	getItemHistory(pitem: any) {
		this.purchitem = pitem;

		this.inventoryService.getItem({ stockid: pitem.stockid }).subscribe((item: any) => {
			this.item = item;
			this.inventoryService.getHistory(pitem.stockid).subscribe((results: any) => {
				this.itemhistory = results;
				this.modalService.open(this.itemhistoryele, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

				}, (reason) => {
					this.itemhistory = {};
				});

			})
		})
	}

	updateChecks(event: any, filter_id: any) {

		if (!this.selected_checks[filter_id]) {
			this.selected_checks[filter_id] = [];
		}

		const data = { value: event.source.value };

		if (event.checked) {
			this.selected_checks[filter_id].push(data)
		} else {

			const index = this.selected_checks[filter_id].indexOf(data);

			this.selected_checks[filter_id] = this.selected_checks[filter_id].filter((selected, index) => {
				return selected.value != event.source.value
			});
		}
		//prevent empty send
		if (this.selected_checks[filter_id].length) {
			this.selected_filters[filter_id] = { filter_id: filter_id, value: this.selected_checks[filter_id] };
		} else {
			this.selected_filters[filter_id] = {};
		}
	}

	createBatchBo() {
		//this.flip = (this.flip == 'inactive') ? 'active': 'inactive';
	}

	creteMultiplePos() {

	}

	filterResults(event: any) {

		this.fully_available.setValue(event.checked)
		if (event.checked) {
			this.results = this.allitems.filter((r: any) => {
				return r.total_available >= r.qty_purchase
			})

			this.purchase_items = this.purchase_items.filter((r: any) => {
				return r.canfill;
			})


			this.po_vendors = []
			this.po_vendors_all.forEach((vendor: any, index) => {

				const lines = vendor.lines.filter((l: any) => {
					return l.canfill
				})
				vendor.lines = lines;
				this.po_vendors[index] = vendor;
			})

		} else {
			this.results = this.allitems;
			this.purchase_items = this.allpurchaseitems;
			this.po_vendors = this.po_vendors_all
		}

	}

	updateSearchSelect(event: any, filter_id: any) {
		this.selected_filters[filter_id] = { filter_id: filter_id, value: event.value };
	}

	updateSearchSelectMulti(event: any, filter_id: any) {
		this.selected_filters[filter_id] = { filter_id: filter_id, value: event.value };
	}

	editBin(item: any) {
		item.editing = (item.editing) ? false : true;
	}

	updateQuantity(event: any, item: any) {
		item.altered = true;
		if(event && event !== '') {
			item.original_qty = item.qty_purchase;
		}

		item.qty_purchase = event;

	}

	updatePrice(event: any, item: any) {
		item.selected_option.price = event
		//	this.reduceItems();
	}


	updateBinItem(event: any, item: any) {
		const index = this.results.indexOf(item);
		item.bin = event;
		item.editing = false;
	}

	setPurchOrderOptions(item: any, pdata: any) {



		const currentItem = this.results[item.vendor_index].indexOf(item);
		const index = pdata.supplierid + ': ' + pdata.suppname;

		const thisalreadythere = this.po_vendors_found.indexOf(index);

		if (thisalreadythere < 0) {
			this.po_vendors_found.push(index)
		}

		item.vendor = index
		item.selected_option = pdata
		item.total_available = pdata.total_available
		item.qty_purchase = (pdata.total_available >= item.target_purchase) ? item.target_purchase : pdata.total_available;

		if (!this.results[index]) {
			this.results[index] = [];
		}
		this.results[index].push(item)

		if (currentItem >= 0) {
			this.results[item.vendor_index].splice(currentItem, 1);
			item.vendor_index = index;
		}

		this.modalService.dismissAll();
	}


	nonStockItem(purchase_item: any) {


		if (purchase_item.qoh <= 0) {

			const proceed = confirm(' Zero Min/Max and update bin?');
			if (proceed) {
				purchase_item.bin = 'NONSTOCK';
				purchase_item.minimum = 0;
				purchase_item.maximum = 0;
			}
		} else {
			alert('Item still has QOH. Canceled')
		}
	}

	setMax(event: any, purchase_item: any) {

		//if(parseFloat(event) >= parseFloat(purchase_item.minumim)) {
		purchase_item.maximum = parseFloat(event);
		//} else {
		//	purchase_item.maximum = parseFloat(purchase_item.minumim);
		//}
	}

	setBin(event: any, purchase_item: any) {
		purchase_item.bin = event;
	}

	setMin(event: any, purchase_item: any) {
		purchase_item.minumim = parseFloat(event);
	}

	updateItem(purchase_item: any) {

		const data = {
			stockid: purchase_item.stockid,
			minimum: purchase_item.minimum,
			maximum: purchase_item.maximum,
			bin: purchase_item.bin,
			vendor: purchase_item.vendor,
			qoh: purchase_item.qoh,
			loccode: this.reportForm.get('loccode').value
		};

		this.purchasingService.updateItem(data).subscribe((result: any) => {
			this.modalService.dismissAll();
		})
	}

	viewPurchData(item: any) {
		this.purchase_item = item;
		//copy
		this.holditem = (JSON.parse(JSON.stringify(item)));
		this.holditem = item;

		this.modalService.open(this.purchasedetails, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

			if (reason && reason == 'cancel') {
				item.minimum = this.holditem.minimum;
				item.maximum = this.holditem.maximum;
				item.bin = this.holditem.bin;
			}
		});
	}

	exportXls() {
		const alldata = [];

		// Define a mapping for the headers you provided
		const fieldMapping = {
			'vendor': 'Supplier',
			'stockid': 'Product ID',
			'description': 'Product Description',
			'brand_data': 'Brand',
			'vendor_index': 'Vendor Index',
			'qoh': 'Quantity On Hand',
			'qop': 'Quantity On Order',
			'qos': 'Quantity Commited',
			'qot': 'Quantity On Transfer',
			'backorder': 'Backorder',
			'bin': 'Bin',
			'price': 'Price',
			'qty_purchase': 'Quantity Purchased',
			'original_qty': 'Original Quantity',
			'target_purchase': 'Target Purchase',
			//'qty_purchase_min': 'Minimum Purchase Quantity',
			//'target_qty_display_min': 'Target Quantity Display Minimum',
			//'cases': 'Cases',
			//'history': 'History',
			//'pansize': 'Pansize',
			'minimum': 'Minimum',
			'maximum': 'Maximum',
			//'minimum_order': 'Minimum Order',
			//'maximum_order': 'Maximum Order',
			//'purchdata': 'Purchase Data',
			//'selected_option': 'Selected Option',
			//'export_template': 'Export Template',
			'total_available': 'Total Available',
			//'loccode': 'Location Code',
			//'watchlist': 'Watchlist',
			'total_remote_stock': 'Total Remote Stock',
			//'itemhistory': 'Item History',
			//'original_qty_purchase': 'Original Quantity Purchased',
			//'original_target_purchase': 'Original Target Purchase'
		};

		this.po_vendors_found.forEach(p => {
			this.results[p].forEach(i => {
				// Create a deep copy of the item
				const addon = JSON.parse(JSON.stringify(i));

				// Modify the copy based on the field mapping
				const formattedItem = {};

				// Loop through the field mapping and apply the transformations
				Object.keys(fieldMapping).forEach(key => {
					if (addon[key] !== undefined) {
						formattedItem[fieldMapping[key]] = addon[key];
					}
				});

				// Add the modified copy to the alldata array
				alldata.push(formattedItem);
			});
		});

		// Export the formatted data
		this.globalSearchService.exportJsonToExcel(alldata, 'Recomendations');
	}



	exportTemplate() {
		const outputdata = [];
		this.po_vendors_found.forEach(p => {
			this.results[p].forEach(i => {
				//i.port
				outputdata.push({ ShipTo: i.selected_option.port, ProductID: i.selected_option.suppliers_partno, Quantity: i.target_purchase, PO: '90190' });
			})

		});

		this.globalSearchService.exportCSV('REORDER-TREADNET.csv', outputdata)

		const items = outputdata
		const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
		const header = Object.keys(items[0])
		const csv = [
			header.join(','), // header row first
			...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
		].join('\r\n')


	}


	removeItem(item: any, vendor: any) {
		const index = this.results[vendor].indexOf(item);
		this.results[vendor].splice(index, 1);
	}

	getRecomendations() {

		if (this.reportForm.valid) {
			this.indexnow = 1;

			this.reviewlabel = 'Review Primary Recommendations'
			this.reportForm.get('filters').setValue(this.selected_filters);
			this.loading = true;

			if (this.retriving) {
				this.retriving.unsubscribe();
			}

			this.retriving = this.purchasingService.geMinMaxRecomendationsTwo(this.reportForm.value).subscribe((results: any) => {

				this.loading = false;
				this.allresults = results;
				this.results = results.data
				this.allitems = results.data
				this.po_vendors_found = results.vendors;
				this.watch_items = results.watch_items;
				this.retriving = false;
				this.fillMode.setValue(false);

			});
		}
	}

	removingItem(item: any, po: any) {

	}


	printResults(po: any) {

		const vendor = this.suppliers.filter((s: any) => {
			const findindex = s.supplierid + ': ' + s.suppname;
			return po == findindex
		})[0];

		vendor.loccode = this.reportForm.get('loccode').value;
		vendor.lines = this.results[po];

		const data = vendor

		this.purchasingService.printPreviewPo(data).subscribe((results: any) => {
			this.goToLink(results.url);
		});
	}

	goToLink(url: string) {
		window.open(url, '_blank');
	}

	vendorHasFillable(po: any) {
		const openlines = this.results[po].filter((line: any) => {
			return line.qty_purchase > 0
		})
		return (openlines.length) ? true : false;
	}

	discontinueItems() {


		const proceed = confirm('Discontinue ' + this.watch_items.length + ' Items?')
		if (proceed) {
			this.purchasingService.discontinueItems(this.watch_items).subscribe((result: any) => {
				this.watch_items = this.watch_items.filter((r: any) => {
					return (!r.watchlist);
				});
			});
		}

	}

	submitPo(po: any) {

		const proceed = confirm('You are about to create a purchase order. Continue?');

		if (proceed) {

			this.sending = true;
			const vendor = this.suppliers.filter((s: any) => {
				const findindex = s.supplierid + ': ' + s.suppname;
				return po == findindex
			})[0];

			const remove_vendor = po;

			vendor.loccode = this.reportForm.get('loccode').value;
			vendor.lines = this.results[po].filter((i: any) => { return i.qty_purchase > 0 });

			if (vendor.lines.length) {
				const data = [
					{ vendor: vendor }
				]

				this.purchasingService.createPurchaseOrdersFromRecomend(data).subscribe((result: any) => {
					this.sending = false;
					if (result.success) {

						this.globalSearchService.showNotification(result.message, 'success', 'bottom', 'right');

						this.indexnow++;

						switch (this.indexnow) {
							case 2:
								this.reviewlabel = 'Review Secondary Recommendations'
								break;
							case 3:
								this.reviewlabel = 'Review Tertiary Recommendations'
								break;
						}
						const newresults = this.results[po].filter((ro: any) => {
							return ((ro.qty_purchase <= ro.target_purchase) && ro.purchdata.length);
						});

						const watchlist = this.results[po].filter((ro: any) => {
							return ((ro.qty_purchase > ro.target_purchase) && ro.purchdata.length);
						});

						const next_set = [];
						const suppindex = '';
						this.results = [];


						newresults.forEach((i: any, index) => {
							//secondary supplier?
							const newpdata = i.purchdata.filter((p: any) => {
								return parseInt(p.preferred_sort) == this.indexnow;
							})[0]

							if (newpdata) {
								const suppindex = newpdata.supplierno + ': ' + newpdata.suppname;
								const thisalreadythere = this.po_vendors_found.indexOf(suppindex);

								if (thisalreadythere < 0) {
									this.po_vendors_found.push(suppindex)
								}

								i.vendor = suppindex
								i.selected_option = newpdata
								i.qty_purchase = i.target_purchase - i.qty_purchase;
								i.total_available = newpdata.total_available

								if (newpdata.total_available < i.qty_purchase) {
									i.qty_purchase = newpdata.total_available;
								}

								if (!this.results[suppindex]) {
									this.results[suppindex] = [];
								}

								this.results[suppindex].push(i)

							} else {

							}
						});

						watchlist.forEach((i: any, index) => {
							this.wathchlist.push(i)
						});


						const remove = this.po_vendors_found.indexOf(po)
						this.po_vendors_found.splice(remove, 1)

						if (this.po_vendors_found && this.po_vendors_found.length) {
							this.currentstep = 0;
						}
						this.sending = false;
					} else {
						alert(result.message)
						this.sending = false;
					}


				});
			} else {
				this.sending = false;
			}
		}
	}
	updatePurchaseData(event: any, item: any) {
		item.purchdata = event.purchdata
	}

	ngAfterViewInit() {

		this.globalSearchService.locations.subscribe(async (results: any) => {
			this.locations = results;
		});
	}

}
