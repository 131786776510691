import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { PrintService } from '../../services/print.service';
import { ReportsService } from '../../services/reports.service';
import { interval, Observable } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatSortModule, MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormControl, FormGroup, UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

@Component({
	selector: 'app-jpms-report',
	templateUrl: './jpms-report.component.html',
	styleUrls: ['./jpms-report.component.scss']
})
export class JpmsReportComponent {
	color = '';
	@ViewChild('print_table') printtable: ElementRef;
	form: FormGroup;
	reportData: any = [];
	excelLink: string;
	pdfLink: string;
	sending: boolean = false;
	title: string = 'JPMS';
	filename: string = 'JPMS';
	todate = new Date();
	fromdate = new Date();
	today = new Date();
	fromdateCtrl = new UntypedFormControl('');
	todateCtrl = new UntypedFormControl('');
	private sort: MatSort;
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.setDataSourceAttributes();
	}

	@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
		this.paginator = mp;
		this.setDataSourceAttributes();
	}

	setDataSourceAttributes() {
		this.dataSource.paginator = this.paginator;
	}
	
	dataSource: MatTableDataSource < any > ;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataObs: Observable < any > ;
	items_per_page = [20 , 100, 250, 500, 1000];
	pagesizedefault = 100;

	displayedColumns: string[] = [
		'customer', 'perkprogram', 'customername', 'customeraddress', 'city', 'state', 'zipcode',
		'awapuhi', 'brx', 'cxg', 'cvx', 'jcb', 'mit', 'mvr', 'color', 'colorstuff', 'demi', 'pmitchell',
		'expresstools', 'neurowetline', 'pntline', 'pspline', 'xgcolor', 'ttc', 'tthmp', 'total'
	];


	perks = [

	];

	constructor(private printService: PrintService, private reportsService: ReportsService, private _changeDetectorRef: ChangeDetectorRef, private fb: FormBuilder, private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, private location: Location, private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {

		this.form = this.fb.group({
			perks: [
				[]
			],
			allPerks: [false]
		});

		this.reportsService.getJpmsPerks({}).subscribe((data: any) => {
			this.perks = data.perks;
			this.perks.forEach(perk => {
				this.form.addControl('perk_' + perk.clubcode, this.fb.control(false));
			});
			this.form.patchValue({ FormID: data.formID });
		});

		const today = new Date();
		//var priorDate = new Date(new Date().setDate(today.getDate() - 365));
		const priorDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
		this.fromdate = priorDate;
		this.fromdateCtrl.setValue(this.fromdate);
		this.todateCtrl.setValue(this.todate);
	}

	onSubmit(): void {
		this.sending = true;


		const payload = this.form.value;

		payload.fromDate = this.fromdateCtrl.value;
		payload.toDate = this.todateCtrl.value;

		this.reportsService.getJpms(payload).subscribe((data: any) => {
			this.reportData = data.reportData;
			this.setPagination(this.reportData);
			this.sending = false;
		});
	}

	calculateLineTotal(element: any): number {
		return this.parseFinancial(element.awapuhi) +
			this.parseFinancial(element.brx) +
			this.parseFinancial(element.cxg) +
			this.parseFinancial(element.cvx) +
			this.parseFinancial(element.jcb) +
			this.parseFinancial(element.mit) +
			this.parseFinancial(element.mvr) +
			this.parseFinancial(element.color) +
			this.parseFinancial(element.colorstuff) +
			this.parseFinancial(element.demi) +
			this.parseFinancial(element.pmitchell) +
			this.parseFinancial(element.expresstools) +
			this.parseFinancial(element.neurowetline) +
			this.parseFinancial(element.pntline) +
			this.parseFinancial(element.pspline) +
			this.parseFinancial(element.xgcolor) +
			this.parseFinancial(element.ttc) +
			this.parseFinancial(element.tthmp);
	}

	parseFinancial(value: string): number {
		return parseFloat(value) || 0;
	}

	back(): void {
		this.location.back()
	}

    setPagination(tableData) {
		this.dataSource = new MatTableDataSource <any> (tableData);
		this._changeDetectorRef.detectChanges();
		this.dataSource.paginator = this.paginator;
		this.dataObs = this.dataSource.connect();
	}
	
	exportXls() {
		const today = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.buildHtmlForExport());
		const data = {
			content: encoded,
			filename: this.filename + today.getMonth() + today.getDate() + today.getFullYear(),
			title: this.title,
			subtitle: ' created ' + today.toLocaleString()
		}
		this.sending = true;
		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.sending = false;
		});
	}

	exportPdf() {

		const encoded: string = this.globalSearchService.base64encode(this.buildHtmlForExport());
		const today = new Date();
		const data = {
			content: encoded,
			filename: this.filename + today.getMonth() + today.getDate() + today.getFullYear(),
			title: this.title,
			subtitle: ' created ' + today.toLocaleString()
		}
		this.sending = true;
		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.sending = false;
		});
	}
	buildHtmlForExport() {
		let html = '';
		if(this.reportData) {
		
			html = '<table class="table table-bordered table-striped table-hover"  *ngIf="reportData && reportData.length">';
			html += '<thead class="thead-dark">';
			html += '<tr>';
			html += '<th class="text-center">Customer</th>';
			html += '<th class="text-center">Perks Program</th>';
			html += '<th class="text-center">Customer Name</th>';
			html += '<th class="text-center">Customer Address</th>';
			html += '<th class="text-center">City</th>';
			html += '<th class="text-center">State</th>';
			html += '<th class="text-center">Zip Code</th>';
			html += '<th class="text-right">AWA</th>';
			html += '<th class="text-right">BRX</th>';
			html += '<th class="text-right">CXG</th>';
			html += '<th class="text-right">CVX</th>';
			html += '<th class="text-right">JCB</th>';
			html += '<th class="text-right">MIT</th>';
			html += '<th class="text-right">MVR</th>';
			html += '<th class="text-right">PC</th>';
			html += '<th class="text-right">PCM</th>';
			html += '<th class="text-right">PDM</th>';
			html += '<th class="text-right">PMM</th>';
			html += '<th class="text-right">PMT</th>';
			html += '<th class="text-right">PNW</th>';
			html += '<th class="text-right">PNT</th>';
			html += '<th class="text-right">PSP</th>';
			html += '<th class="text-right">XGC</th>';
			html += '<th class="text-right">TTC</th>';
			html += '<th class="text-right">TTHMP</th>';
			html += '<th class="text-right">Total</th>';
			html += '</tr>';
			html += '</thead>';
			html += '<tbody>';

			this.reportData.forEach( (element:any) => {
			html+='<tr>';
			html+='<td class="text-center">'+ element.customer +'</td>';
			html+='<td class="text-center">'+ element.perkprogram +'</td>';
			html+='<td class="text-center">'+ element.customername +'</td>';
			html+='<td class="text-center">'+ element.customeraddress +'</td>';
			html+='<td class="text-center">'+ element.city +'</td>';
			html+='<td class="text-center">'+ element.state +'</td>';
			html+='<td class="text-center">'+ element.zipcode +'</td>';
			html+='<td class="text-right">'+ this.parseFinancial(element.awapuhi) +'</td>';
			html+='<td class="text-right">'+ this.parseFinancial(element.brx) +'</td>';
			html+='<td class="text-right">'+ this.parseFinancial(element.crema) +'</td>';
			html+='<td class="text-right">'+ this.parseFinancial(element.cvx) +'</td>';
			html+='<td class="text-right">'+ this.parseFinancial(element.jcb) +'</td>';
			html+='<td class="text-right">'+ this.parseFinancial(element.mitch) +'</td>';
			html+='<td class="text-right">'+ this.parseFinancial(element.mvr) +'</td>';
			html+='<td class="text-right">'+ this.parseFinancial(element.color) +'</td>';
			html+='<td class="text-right">'+ this.parseFinancial(element.colorstuff) +'</td>';
			html+='<td class="text-right">'+ this.parseFinancial(element.demi) +'</td>';
			html+='<td class="text-right">'+ this.parseFinancial(element.pmitchell) +'</td>';
			html+='<td class="text-right">'+ this.parseFinancial(element.expresstools) +'</td>';
			html+='<td class="text-right">'+ this.parseFinancial(element.neurowetline) +'</td>';
			html+='<td class="text-right">'+ this.parseFinancial(element.pntline) +'</td>';
			html+='<td class="text-right">'+ this.parseFinancial(element.pspline) +'</td>';
			html+='<td class="text-right">'+ this.parseFinancial(element.xgcolor) +'</td>';
			html+='<td class="text-right">'+ this.parseFinancial(element.ttc) +'</td>';
			html+='<td class="text-right">'+ this.parseFinancial(element.tthmp) +'</td>';
			html+='<td class="text-right">'+ this.calculateLineTotal(element) +'</td>';
			html+='</tr>';
								
			});

			html += '</tbody>'
			html += '</table>'
		}
		return html;
	}

}
