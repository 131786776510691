<div class="main-content">
	<div class="container-fluid">
		<div class="row m-0 p-0">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> New Purchase Order </h4>
						<p class="card-category text-right"><span *ngIf="supplierDetails"> <b>{{ supplierDetails.suppname }}</b></span></p>
					</div>
					<div class="card-body">
						<app-vendor-lookup (vendor_selected)="vendorSelected($event)"></app-vendor-lookup>
						<app-shared-purchase-order-entry [vendor]="supplierDetails" [flip]="flipstate" (reloadVendor)="loadVendor($event.header.supplierno)" *ngIf="supplierDetails" class="w-100 mt- 3 no-overflow"></app-shared-purchase-order-entry>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
