<ng-template #editAddAccount let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Account Details</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<form [formGroup]="accountForm" class="text-dark" (submit)="updateAccount()">
			<div class="row">
				<div class="col-md-6">
					<mat-form-field class="full-width" appearance="outline">
						<mat-label>Account Code</mat-label>
						<input matInput required formControlName="accountcode" placeholder="Account Code" [value]="ld?.accountcode">
					</mat-form-field>
				</div>
				<div class="col-md-6">
					<mat-form-field class="full-width" appearance="outline">
						<mat-label>Bank Code</mat-label>
						<input matInput required formControlName="bankaccountcode" placeholder="Bank Code" [value]="ld?.bankaccountcode">
					</mat-form-field>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<mat-form-field class="full-width" appearance="outline">
						<mat-label>Account Name</mat-label>
						<input matInput formControlName="bankaccountname" placeholder="Account Name" [value]="ld?.bankaccountname">
					</mat-form-field>
				</div>
			</div>
			<div class="row">
				<div class="col-md-4">
					<mat-form-field appearance="outline">
						<mat-label>Currency</mat-label>
						<mat-select formControlName="currcode">
							<mat-option *ngFor="let currency of currencies" [value]="currency?.currabrev">
								{{currency.currency}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-4">
					<mat-form-field class="full-width" appearance="outline">
						<mat-label>Account Number</mat-label>
						<input matInput required formControlName="bankaccountnumber" placeholder="Account Number" [value]="ld?.bankaccountnumber">
					</mat-form-field>
				</div>
				<div class="col-md-4">
					<mat-form-field class="full-width" appearance="outline">
						<mat-label>Next Check Number</mat-label>
						<input matInput required formControlName="nextchecknum" placeholder="Next check" [value]="ld?.nextchecknum">
					</mat-form-field>
				</div>
			</div>
			<div class="row">
				<div class="col-6 ml-0 mb-6">
					<button mat-raised-button matInput color="accent">Save</button>
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content" *ngIf="accounts">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{ color }}-1">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Bank Accounts</h4>
						<p class="card-category text-right">Bank Accounts</p>
					</div>
					<div class="card-body">
						<div class="table table-sm ">
							<div class="table table-responsive text-center" *ngIf="!accounts">
								<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
							</div>
							<div class="row">
								<div class="col-4">
									<a (click)="newAccount()" mat-stroked-button color="danger">New Account</a>
								</div>
							</div>
							<mat-table [dataSource]="accounts" matSort (matSortChange)="announceSortChange($event)">
								<ng-container matColumnDef="actions">
									<mat-header-cell class="d-md-inline" *matHeaderCellDef></mat-header-cell>
									<mat-cell class="d-none d-md-inline" *matCellDef="let account">
										<a (click)="selectAccount(account.accountcode)" mat-raised-button class="mt-1" color="accent">Edit</a>
										<button mat-raised-button (click)="deleteAccount(account.accountcode)"><i class="fa fa-remove"></i></button>
									</mat-cell>
								</ng-container>
								<ng-container matColumnDef="accountcode">
									<mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Code">
										Account Code
									</mat-header-cell>
									<mat-cell *matCellDef="let account"> {{account.accountcode}} </mat-cell>
								</ng-container>
								<ng-container matColumnDef="currcode">
									<mat-header-cell *matHeaderCellDef mat-sort-header> Currency</mat-header-cell>
									<mat-cell *matCellDef="let account"> {{ account.currcode}} </mat-cell>
								</ng-container>
								<ng-container matColumnDef="bankaccountcode">
									<mat-header-cell *matHeaderCellDef mat-sort-header> Account Code</mat-header-cell>
									<mat-cell *matCellDef="let account"> {{ account.bankaccountcode}} </mat-cell>
								</ng-container>
								<ng-container matColumnDef="bankaccountname">
									<mat-header-cell *matHeaderCellDef mat-sort-header> Account Name</mat-header-cell>
									<mat-cell *matCellDef="let account"> {{ account.bankaccountname}} </mat-cell>
								</ng-container>
								<mat-header-row *matHeaderRowDef="headercolumns"></mat-header-row>
								<mat-row *matRowDef="let row; columns: headercolumns;"></mat-row>
							</mat-table>
							<mat-paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons aria-label="select page">
							</mat-paginator>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
