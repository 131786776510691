<div class="main-content" *ngIf="!sectionForm">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-5">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Account Sections</h4>
						<p class="card-category">General Ledger Accounts</p>
					</div>
					<div class="card-body">
						<div class="table table-sm ">
							<div class="table table-responsive text-center" *ngIf="!sections">
								<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
							</div>
							<div class="row">
								<div class="col-4">
									<a [routerLink]="'edit'" mat-stroked-button color="danger">New Section</a>
								</div>
							</div>
							<mat-table [dataSource]="sections" matSort (matSortChange)="announceSortChange($event)">
								<ng-container matColumnDef="actions">
									<mat-header-cell class="d-none d-md-inline" *matHeaderCellDef></mat-header-cell>
									<mat-cell class="d-none d-md-inline" *matCellDef="let section">
										<a [routerLink]="[section.sectionid]" mat-stroked-button class="mt-1">Edit</a>
										<button class="btn btn-sm btn-danger " *ngIf="section.sectionid > 2" (click)="deleteSection(section.sectionid)"><i class="fa fa-remove"></i></button>
									</mat-cell>
								</ng-container>

								<ng-container matColumnDef="sectionid">
									<mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Code">
										Section ID
									</mat-header-cell>
									<mat-cell *matCellDef="let section"> {{section.sectionid}} </mat-cell>
								</ng-container>
								<ng-container matColumnDef="sectionname">
									<mat-header-cell *matHeaderCellDef mat-sort-header> Section Name</mat-header-cell>
									<mat-cell *matCellDef="let section"> {{ section.sectionname}} </mat-cell>
								</ng-container>
								<mat-header-row *matHeaderRowDef="headercolumns"></mat-header-row>
								<mat-row *matRowDef="let row; columns: headercolumns;"></mat-row>
							</mat-table>
							<mat-paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons aria-label="select page">
							</mat-paginator>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<div class="main-content" *ngIf="sectionForm">
	<form [formGroup]="sectionForm" class="text-dark" (submit)="updateSection()">
		<div class="container-fluid" *ngIf="ld">
			<div class="row">
				<div class="col-md-12">
					<div class="card">
						<div class="card-header card-header-{{color}}-5">
							<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i>Account Sections</h4>
							<p class="card-category"></p>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-md-12">
									<mat-form-field class="full-width" appearance="outline">
										<mat-label>Section ID</mat-label>
										<input matInput required formControlName="sectionid" placeholder="Section ID" [value]="ld?.sectionid">
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<mat-form-field class="full-width" appearance="outline">
										<mat-label>Section Name</mat-label>
										<input matInput formControlName="sectionname" placeholder="Section Name" [value]="ld?.sectionname">
									</mat-form-field>
								</div>
							</div>


						</div>
						<div class="row">
							<div class="col-12 ml-auto mr-auto mb-6">
								<button mat-raised-button type="submit" class="btn btn-danger pull-right">Update Account Section</button>
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>