<div class="main-content">
	<div class="container-fluid">
		<div class="col-md-12">
			<div class="card">
				<div class="card-header card-header-danger">
					<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Order Status</h4>
					<p class="card-category"></p>
						<div class="messages">
						<div *ngFor="let user of messages" class="d-none">
							{{user.name}}: {{user.message | json}}
						</div>
					</div>
				</div>
				<div class="card-body">
					<div class="scrolling-wrapper">

							<div class="scrolling-wrapper">
							  <div class="card ml-0 mr-0">
								  <div class="drag-container">
									  <div class="section-heading">Open Orders <span *ngIf="carddata.todo">({{ carddata.todo.length }})</span></div>
									  <div cdkDropList #pendingList="cdkDropList" [cdkDropListData]="carddata.todo" [cdkDropListConnectedTo]="[doneList,reviewList]"
										class="item-list" (cdkDropListDropped)="drop($event, 'todo')">
										<div class="item-box" *ngFor="let item of carddata.todo" cdkDrag [innerHTML]='item.content'> </div>
									  </div>
								  </div>
							  </div>

							  <div class="card ml-0 mr-0">
								  <div class="drag-container">
									  <div class="section-heading">Fillable <span *ngIf="carddata.review">({{ carddata.review.length }})</span></div>

									  <div cdkDropList #reviewList="cdkDropList" [cdkDropListData]="carddata.review" [cdkDropListConnectedTo]="[doneList,pendingList]"
										class="item-list" (cdkDropListDropped)="drop($event, 'review')">
										<div class="item-box" *ngFor="let item of carddata.review" cdkDrag [innerHTML]='item.content'></div>
									  </div>
									</div>
							  </div>
							  <div class="card ml-0 mr-0">
								  <div class="drag-container">
									  <div class="section-heading">Loading <span *ngIf="carddata.done">({{ carddata.done.length }})</span></div>

									  <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="carddata.done" [cdkDropListConnectedTo]="[pendingList,reviewList]"
										class="item-list" (cdkDropListDropped)="drop($event, 'done')">
										<div class="item-box" *ngFor="let item of carddata.done" cdkDrag [innerHTML]='item.content'></div>
									  </div>
									</div>
							  </div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>