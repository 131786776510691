<!--
<barcode-scanner-livestream type="code_128" (valueChanges)="onValueChanges($event)" (started)="(onStarted)"></barcode-scanner-livestream>
		<div [hidden]="!barcodeValue">
			{{ barcodeValue }}
		</div>
-->

<form [formGroup]="itemForm" (ngSubmit)="lookup()" (focus_search)="ee($event)"novalidate="">
	<div class="main-content">
		<div class="container-fluid">
			<div class="card">
				<div  [ngClass]="{
					'card-header': true,
					'blue': selectedIndex == 0,
					'yellow': selectedIndex == 1,
					'red': selectedIndex == 2}">
					<h4 class="card-title" [ngClass]="{'text-white': selectedIndex == 0 || selectedIndex == 2 }"><i class="material-icons" (click)="back()">arrow_left</i> Inventory Receiving </h4>
				</div>
				<div class="card-body">
					<ng-container>
						<div class="row">
							<div class="col-12 col-md-6">
								<mat-form-field appearance="outline" class="ml-2 mr-2">
									<mat-label>Vendor</mat-label>
									<mat-select tabindex="-1" [formControl]="vendor" autocomplete="off" (selectionChange)="updatePos($event.value)">
										<mat-option *ngFor="let v of vendors" [value]="v">
												{{v.supplierid}} - {{v.suppname}}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col-12 col-md-6">
								<mat-form-field appearance="outline">
									<input matInput [formControl]="recitem" #itemrec id="itemrec" value="" class="text-right" placeholder="* Item Search  " (keyup.enter)="$event.stopPropagation();$event.preventDefault();lookupVersionEight();$event.target.blur();">
									<div matSuffix class="text-right">
										<button mat-icon-button type="button" color="white" (click)="$event.stopPropagation();$event.preventDefault();lookupVersionEight()">
											<mat-icon>search</mat-icon>
										</button>
									</div>
								</mat-form-field>
							</div>
						</div>
						<div class="table table-responsive text-center" *ngIf="searching">
							<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg>
						</div>
						<div class="table table-responsive" *ngIf="itemlist && itemlist.length">
							<table class="table table-sm">
								<thead>
									<tr>
										<th></th>
										<th class="text-left">ITEM</th>
										<th class="text-left">DESC</th>
									</tr>
								</thead>
								<tbody>
									<tr (click)="loadItem(item)" *ngFor="let item of itemlist ">
										<td> <a mat-raised-button (click)="loadItem(item)">Select</a></td>
										<td class="text-left">
											{{ item.stockid }}
										</td>
										<td class="text-left">{{ item.description }}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<ng-container *ngIf="itemlist && !itemlist.length">
							<div class="row">
								<div class="col-md-12 text-center">
									<h5 class="bold">No Results, would you like to add the barcode?
									</h5>
								</div>
								<div class="col-md-12 text-center">
									<button mat-raised-button color="accent" (click)="dspBarcodeAdd($event)" *ngIf="!addingbarcode">
										<mat-icon>add</mat-icon> Add Barcode
									</button>
									<app-qantel-item-barcode-add *ngIf="addingbarcode" [searchedfor]="lastsearch" (barcode_added)="addedBarcode($event)">
									</app-qantel-item-barcode-add>
								</div>
							</div>
						</ng-container>
					</ng-container>

					<mat-tab-group mat-stretch-tabs mat-align-tabs="end" (selectedIndexChange)="selectedIndex = $event;">
						<!-- <mat-tab label="Item IO" >
							<ng-template matTabContent>
								<app-warehouse-scaninout [itemdetails]="itemio" [vendorpos]="vendor_openpos" [vendor_selected]="vendor.value.supplierid" *ngIf="itemio"></app-warehouse-scaninout>
								<ng-container *ngIf="!viewingitem">
									<h4 class="text-center mt-3">No Item Selected</h4>
								</ng-container>
							</ng-template>
						</mat-tab>
						<mat-tab label="Finalize">
							<ng-template matTabContent>
								<app-warehouse-finalizerec></app-warehouse-finalizerec>
							</ng-template>
						</mat-tab> -->
						<mat-tab label="Item Receiving" >
							<ng-template matTabContent>
								<app-warehouse-receiving [itemdetails]="item" [vendorpos]="vendor_openpos" [vendor_selected]="vendor.value.supplierid" *ngIf="viewingitem"></app-warehouse-receiving>
								<ng-container *ngIf="!viewingitem">
									<h4 class="text-center mt-3">No Item Selected</h4>
								</ng-container>
							</ng-template>
						</mat-tab>
						<mat-tab label="Item Lookup">
							<app-warehouse-view-item [itemdetails]="item" *ngIf="viewingitem"></app-warehouse-view-item>
							<ng-container *ngIf="!viewingitem">
								<h4 class="text-center mt-3">No Item Selected</h4>
							</ng-container>
						</mat-tab>
						<mat-tab label="Item Sending">
							<app-warehouse-sending [itemdetails]="item" [vendorpos]="vendor_openpos" [vendor_selected]="vendor.value.supplierid" *ngIf="viewingitem"></app-warehouse-sending>
							<ng-container *ngIf="!viewingitem">
								<h4 class="text-center mt-3">No Item Selected</h4>
							</ng-container>
						</mat-tab>
					</mat-tab-group>
					<ng-container *ngIf="viewingitem">
						<h4 class="text-center mt-3" *ngIf="vendor_openpos" style="display: none">
								{{ vendor.value.suppname }} PENDING: ({{ vendor_openpos.total_pending}})
						</h4>
						<table class="table table-sm table-fixed fixTableHead" style="display: none">
							<thead>
							<tr>
								<th></th>
								<th>ITEM</th>
								<th>DESC</th>
								<th>PO#</th>
								<th class="text-center">ORDERED</th>
								<th class="text-center">RECIEVED</th>
								<th class="text-center">PENDING</th>
							</tr>
							</thead>
							<tbody>
							<tr *ngFor="let d of vendor_openpos.details">
								<td><button mat-raised-button (click)="loadItemByStockID(d.itemcode)">select</button></td>
								<td>{{ d.itemcode }}</td>
								<td>{{ d.itemdescription }}</td>
								<td>{{ d.orderno }}</td>
								<td class="text-center">{{ d.quantityord }}</td>
								<td class="text-center">{{ d.quantityrecd }}</td>
								<td class="text-center">{{ d.remaining }}</td>
							</tr>
							</tbody>
						</table>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</form>
