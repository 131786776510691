import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {
	ClassicEditor,
	AccessibilityHelp,
	Autoformat,
	Autosave,
	BlockQuote,
	Bold,
	Code,
	Essentials,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	Heading,
	Highlight,
	Indent,
	IndentBlock,
	Italic,
	Link,
	List,
	Alignment,
	AutoLink,
	ListProperties,
	Paragraph,
	PasteFromOffice,
	SelectAll,
	Strikethrough,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	TextTransformation,
	TodoList,
	Underline,
	Undo,
	type EditorConfig
} from 'ckeditor5';

@Component({
	selector: 'app-wysiwyg-editor',
	templateUrl: './wysiwyg-editor.component.html',
	styleUrls: ['./wysiwyg-editor.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class WysiwygEditorComponent implements OnInit, AfterViewInit {

	@Input() input: any = false;
	@Input() config: any = false;
	@Output() data = new EventEmitter<any>();
	formControlRef = new UntypedFormControl('');

	constructor(private cdr: ChangeDetectorRef) { }

	ngOnInit(): void {
		this.formControlRef.valueChanges.subscribe((newV) => {
			this.data.emit(newV);
		})
	}

	public isLayoutReady = false;
	public Editor = ClassicEditor;
	public ckconfig: EditorConfig = {};

	ngAfterViewInit(): void {
		this.getConfig(this.config)
		this.formControlRef.setValue(this.input.value);
		this.formControlRef.updateValueAndValidity();
		this.isLayoutReady = true;
		this.cdr.detectChanges();
	}

	getConfig(type) {
		switch (type) {
			case 'limited':
				this.ckconfig = {
					toolbar: {
						items: [
							'undo',
							'redo',
							'|',
							'heading',
							'|',
							'FontSize',
							'|',
							'bold',
							'italic',
							'underline',
							'|',
							'link',
							'blockQuote',
							'|',
							'alignment',
							'|',
							'bulletedList',
							'numberedList',
							'outdent',
							'indent'
						],
						shouldNotGroupWhenFull: false
					},
					plugins: [
						AccessibilityHelp,
						Alignment,
						AutoLink,
						Autosave,
						BlockQuote,
						Bold,
						Essentials,
						Heading,
						Indent,
						IndentBlock,
						Italic,
						Link,
						List,
						ListProperties,
						Paragraph,
						PasteFromOffice,
						SelectAll,
						Underline,
						Undo
					],
					heading: {
						options: [
							{
								model: 'paragraph',
								title: 'Paragraph',
								class: 'ck-heading_paragraph'
							},
							{
								model: 'heading1',
								view: 'h1',
								title: 'Heading 1',
								class: 'ck-heading_heading1'
							},
							{
								model: 'heading2',
								view: 'h2',
								title: 'Heading 2',
								class: 'ck-heading_heading2'
							},
							{
								model: 'heading3',
								view: 'h3',
								title: 'Heading 3',
								class: 'ck-heading_heading3'
							},
							{
								model: 'heading4',
								view: 'h4',
								title: 'Heading 4',
								class: 'ck-heading_heading4'
							},
							{
								model: 'heading5',
								view: 'h5',
								title: 'Heading 5',
								class: 'ck-heading_heading5'
							},
							{
								model: 'heading6',
								view: 'h6',
								title: 'Heading 6',
								class: 'ck-heading_heading6'
							}
						]
					},
					initialData: '',
					link: {
						addTargetToExternalLinks: true,
						defaultProtocol: 'https://',
						decorators: {
							toggleDownloadable: {
								mode: 'manual',
								label: 'Downloadable',
								attributes: {
									download: 'file'
								}
							}
						}
					},
					list: {
						properties: {
							styles: true,
							startIndex: true,
							reversed: true
						}
					},
					placeholder: ''
				};
				break;
			default:
				this.ckconfig = {
					toolbar: {
						items: [
							'undo',
							'redo',
							'|',
							'heading',
							'|',
							'fontSize',
							'fontFamily',
							'fontColor',
							'fontBackgroundColor',
							'|',
							'bold',
							'italic',
							'underline',
							'strikethrough',
							'code',
							'|',
							'link',
							'insertTable',
							'highlight',
							'blockQuote',
							'|',
							'bulletedList',
							'numberedList',
							'todoList',
							'outdent',
							'indent'
						],
						shouldNotGroupWhenFull: true
					},
					plugins: [
						AccessibilityHelp,
						Autoformat,
						Autosave,
						BlockQuote,
						Bold,
						Code,
						Essentials,
						FontBackgroundColor,
						FontColor,
						FontFamily,
						FontSize,
						Heading,
						Highlight,
						Indent,
						IndentBlock,
						Italic,
						Link,
						List,
						ListProperties,
						Paragraph,
						PasteFromOffice,
						SelectAll,
						Strikethrough,
						Table,
						TableCaption,
						TableCellProperties,
						TableColumnResize,
						TableProperties,
						TableToolbar,
						TextTransformation,
						TodoList,
						Underline,
						Undo
					],
					fontFamily: {
						supportAllValues: true
					},
					fontSize: {
						options: [10, 12, 14, 'default', 18, 20, 22],
						supportAllValues: true
					},
					heading: {
						options: [
							{
								model: 'paragraph',
								title: 'Paragraph',
								class: 'ck-heading_paragraph'
							},
							{
								model: 'heading1',
								view: 'h1',
								title: 'Heading 1',
								class: 'ck-heading_heading1'
							},
							{
								model: 'heading2',
								view: 'h2',
								title: 'Heading 2',
								class: 'ck-heading_heading2'
							},
							{
								model: 'heading3',
								view: 'h3',
								title: 'Heading 3',
								class: 'ck-heading_heading3'
							},
							{
								model: 'heading4',
								view: 'h4',
								title: 'Heading 4',
								class: 'ck-heading_heading4'
							},
							{
								model: 'heading5',
								view: 'h5',
								title: 'Heading 5',
								class: 'ck-heading_heading5'
							},
							{
								model: 'heading6',
								view: 'h6',
								title: 'Heading 6',
								class: 'ck-heading_heading6'
							}
						]
					},
					initialData: '',
					link: {
						addTargetToExternalLinks: true,
						defaultProtocol: 'https://',
						decorators: {
							toggleDownloadable: {
								mode: 'manual',
								label: 'Downloadable',
								attributes: {
									download: 'file'
								}
							}
						}
					},
					list: {
						properties: {
							styles: true,
							startIndex: true,
							reversed: true
						}
					},
					placeholder: '',
					table: {
						contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
					}
				};
				break;
		}
	}





}
