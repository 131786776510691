import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-purchasing-receiptjournal',
	templateUrl: './purchasing-receiptjournal.component.html',
	styleUrls: ['./purchasing-receiptjournal.component.scss']
})
export class PurchasingReceiptjournalComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {
	}

}
