import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { CustomerService } from '../../services/customer.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { PrintService } from '../../services/print.service'


@Component({
	selector: 'app-new-customers',
	templateUrl: './new-customers.component.html',
	styleUrls: ['./new-customers.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotate3d(0, 1, 0, -179deg)',
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotateY(-179deg)'
			})),
			transition('active => inactive', animate('500ms ease-out')),
			transition('inactive => active', animate('500ms ease-in')),
			transition('inactive => orderlookup', animate('500ms ease-out')),
			transition('orderlookup => inactive', animate('500ms ease-in')),
		]),
		trigger('openClose', [
			state(':enter', style({ height: '*' })),
			state(':leave', style({ height: '0px' })),
			transition('false <=> true', animate(500))
		]),
		trigger('filterAnimation', [
			transition(':enter, * => 0, * => -1', []),
			transition(':increment', [
				query(':enter', [
					style({ opacity: 0, width: 0 }),
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 1, width: '*' })),
					]),
				], { optional: true })
			]),
			transition(':decrement', [
				query(':leave', [
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 0, width: 0 })),
					]),
				])
			]),
		]),
		trigger('grow', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: '0', width: '0' }),
				animate(200, style({ height: '*', width: '*' })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate(100, style({ height: 0, width: 0 })),
				animate('100ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class NewCustomersComponent implements OnInit {
	@Input() flip = 'inactive';
	@Output() viewData = new EventEmitter < any > ();
	@ViewChild('monthDetails') monthDetailsRef: ElementRef;
	@ViewChild('print_table') printtable: ElementRef;
	viewing: any = false;
	hideshowunits = new UntypedFormControl(true);
	hideshowsales = new UntypedFormControl(true);
	hideshowcost = new UntypedFormControl(false);
	todate= new UntypedFormControl(new Date());
	defaultlocation = new UntypedFormControl('');
	data: any = false;
	sending: any = false;
	days: any = 60;
	daysmax: any = 365;
	title = 'New Customers ';
	total: any = 0;
	brand_details: any = false;
	datasub: any = false;

	constructor(private printService: PrintService, public globalSearchService: GlobalSearchService, private customerService: CustomerService, private modalService: NgbModal) {}
	user: any = false;
	ref_location: any = false;
	filename: string = 'New Customers ' + this.todate.value.toLocaleDateString();

	ngOnInit(): void {

		const now = new Date();
		const firstDayPrevMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);

		this.todate.setValue(firstDayPrevMonth);
		this.title = 'New Customers Since ' + this.todate.value.toLocaleDateString();
		this.loadData();

		this.globalSearchService.user.subscribe(results => {
			//only run if user is definied
			if (this.user) {
				if (results.user.defaultlocation.loccode != this.ref_location) {
					this.defaultlocation.setValue(this.user.user.defaultlocation.loccode);
					this.ref_location = false;
				}
			}

			this.user = results
			if (!this.ref_location) {
				this.ref_location = this.user.user.defaultlocation.loccode;
			}
		});
	}


	loadData() {
		const data = { fromdate: this.todate.value }
		this.sending = true;
		this.datasub = this.customerService.getNewCustomers(data).subscribe(async (r: any) => {
			this.total = r.length
			this.data = r;
			this.sending = false;
		});
	}

	toggleFlip(data: any, title: string) {

		if (!data) {
			this.flip = 'inactive';
		} else {
			this.flip = (this.flip == 'active') ? 'inactive' : 'active';
		}

		if (this.flip == 'active') {
			this.viewing = data;
			this.title = title
			const dashboard_data = { data: data, title: this.title }
			this.viewData.emit(dashboard_data);
		} else {
			this.viewData.emit(false);
		}
	}

	exportPdf() {

		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename,
			landscape: true,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	exportXls() {
		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

	ngOnDestroy() {

		if (this.datasub) {
			this.datasub.unsubscribe();
		}
	}
}
