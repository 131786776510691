import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter ,AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl,FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { UsersService } from '../../services/users.service'
import { PrintService } from '../../services/print.service'
import { ReportsService } from '../../services/reports.service'

import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { Location } from '@angular/common'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as FileSaver from 'file-saver';

@Component({
	selector: 'app-rsi',
	templateUrl: './rsi.component.html',
	styleUrls: ['./rsi.component.scss']
})
export class RsiComponent implements OnInit {
	rsidata: any = {};
	@ViewChild('rsi_details') rsi_detailsRef: ElementRef;
	color: any = 'blue';
	startdate: any = '';
	enddate: any = '';
	branches: any = [];
	user: any = {};
	config: any = [];
	sending: any = false;
	isPosUser = false;
	location: any = new UntypedFormControl('');
	fromdateCtrl = new UntypedFormControl('');
	todateCtrl = new UntypedFormControl('');
	constructor(public reportsService: ReportsService, public printService: PrintService, private usersService: UsersService, private route: ActivatedRoute,public globalSearchService: GlobalSearchService,private modalService: NgbModal, private globalsService: GlobalsService) {


	this.usersService.isPosUser().subscribe((isPOS) => {
			if (isPOS){
				this.isPosUser = true;
			}
		})
	}

	ngOnInit(): void {
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});
		this.startdate = new Date();
		this.enddate = new Date();
		this.fromdateCtrl.setValue(this.startdate);
		this.todateCtrl.setValue(this.enddate);
		this.globalSearchService.user.subscribe( result => {
			this.user = result;
			this.location.value = this.user.user.defaultlocation.loccode;
			this.getRSIData();
		});
		this.reportsService.getBranches().subscribe((results: any) => {
			this.branches = results;
		});
  
	}

	getRSIData(){
		this.sending = true;
		this.startdate = this.fromdateCtrl.value;
		this.enddate = this.todateCtrl.value;
	
		const data = { startdate: this.fromdateCtrl.value.toLocaleString().split(', ')[0], enddate: this.todateCtrl.value.toLocaleString().split(', ')[0], branch: this.location.value };
		this.globalsService.getRSI(data).subscribe(async (results:any) => {

			this.rsidata = results;
			this.sending = false;
		});

	}
	fieldReset(){

		this.rsidata = { placed: 0,
			payments: [],
			shipped: 0,
			discounts: 0,
			fet: 0,
			tax: 0,
			edf: 0,
			tadj: 0,
			freight: 0,
			tirefee: 0,
			deposits: 0,
			gpdol: 0,
			gppct: 0,
			cashsales: 0,
			onacct: 0,
			totalsale: 0 };
	}
	pdfJournal() {
		this.sending = true;

		const encoded: string = this.globalSearchService.base64encode(this.rsi_detailsRef.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: 'rsi',
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.sending = false;
		});
	}

	xlsJournal() {
		this.sending = true;

		const encoded: string = this.globalSearchService.base64encode(this.rsi_detailsRef.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: 'rsi',
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.sending = false;
		});
	}
}
