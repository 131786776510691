<!-- Start of Coupon ** TODO NEED CONFIGURATION FOR COUPONS ALLOWED -->

<!-- <ng-template #couponModal let-modal>
	<app-coupons (addCoupon)="applyCoupon($event)" [customer]="customer"></app-coupons>
</ng-template> -->


<!-- End of Coupon -->
<ng-template #taxdetails let-modal>
	<div class="modal-header">
		<h4> Tax Details</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<ng-container *ngFor="let t of itemdetails.taxdetails">
			<div class="col-md-4 ml-auto mr-auto">
				<mat-form-field appearance="standard" class="smaller-font-form-tax">
					<mat-label>{{ t.description }} {{ getTaxDisplay(t.taxrate) }} %</mat-label>
					<input matInput autocomplete="off" class="text-center mt-0 mb-0 pt-0 pb-0 " value="{{ getTaxDisplay(t.taxrate) }}" placeholder="{{ t.description }} {{ getTaxDisplay(t.taxrate) }}" (keydown.enter)="$event.preventDefault();updateTaxRate($event.target.value,t)" (blur)="updateTaxRate($event.target.value,t)" appSelectOnFocus>
					<span matSuffix>%</span>
				</mat-form-field>
			</div>
		</ng-container>

		<div class="col-md-12 text-right ml-auto mr-0">
			<button mat-button (click)="updateTotals()">
				<mat-icon>refresh</mat-icon>
			</button>
			<div class="row">
				<div class="col-12 p-0 m-0 text-right" *ngFor="let total of cart_totals">
					<span *ngIf="(total.text !== '0.00' && total.title == 'Discount Total') || !['Discount Total'].includes(total.title)">
						<b>{{total.title }}</b>: {{ total.text | currency }}
					</span>
				</div>
			</div>
		</div>

	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #purchasedetails let-modal (click)="stopProp($event)">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Purchase Information</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row" *ngFor="let item of purchase_items">
			<div class="col-12">
				<app-purchase-data [item]="item" (newitemdata)="updateItem($event, item)"></app-purchase-data>
			</div>
			<div [ngClass]="item.purchdata.length == 1 ? 'col-md-12': 'col-md-6'" *ngFor="let pdata of item.purchdata">
				<div class="card highlight" (click)="setPurchOrderOptions(item.cart_id, pdata)">
					<span matBadge="{{ pdata.preferred_sort }}" matBadgeSize="large" matBadgeColor="accent"></span>
					<div class="card-header text-center">
						<h4 class="card-title text-center">{{ pdata.supplierid }} - {{ pdata.suppname }} {{ pdata.price | currency }}</h4>
					</div>
					<div class="card-body text-center">
						<div class="col-12">
							<h5>{{ pdata.stockid }} {{ pdata.supplierdescription }}</h5>
						</div>
						<div class="col-12 p-0 m-0">
							<ul class="entry-list p-0 m-0" *ngFor="let remote of pdata.remote">
								<li>
									<div class="spec-label">{{ remote.feedlabel }}:</div>
									<div class="spec-value">{{ remote.qty }}</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-container *ngIf="customerForm">

	<!-- TBD *ngIf="config.env.features == coupons'
	<button class="ml-2 mr-2" aria-label="Coupons" color="accent" (click)="showCoupons()"  >
		Coupons
	</button>
	-->
	<ng-container *ngIf="!issalesman">
		<div class="flex-row coupon-form" *ngIf="config.coupons && config.env.package == 'beauty'">
			<mat-form-field class="coupon-input">
				<mat-label>Coupon</mat-label>
				<input matInput placeholder="" value="" [formControl]="couponFormControl">
			</mat-form-field>
			<button mat-raised-button class="btn coupon-apply" (click)="applyCoupon()" [disabled]="this.couponFormControl.value == ''">Apply</button>
		</div>
	</ng-container>

	<form [formGroup]="customerForm" *ngIf="customer">
		<div class="row mb-2 m-0 p-0" [ngClass]="issalesman ? 'd-none': ''">
			<div class="col-md-4">
				<mat-form-field appearance="standard">
					<mat-label>Warehouse</mat-label>
					<mat-select formControlName="defaultlocation">
						<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
							{{ loc.locationname }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-4">
				<mat-form-field class="full-width" appearance="standard">
					<mat-label>Entered By</mat-label>
					<input matInput formControlName="enteredby" matInput placeholder="Entered By">
				</mat-form-field>
			</div>
			<div class="col-md-4">
				<mat-form-field class="full-width" appearance="standard">
					<mat-label>Taken By</mat-label>
					<input matInput formControlName="takenby" matInput placeholder="Taken By">
				</mat-form-field>
			</div>
		</div>
		<div class="row mb-2 mt-md-4">
			<!-- <div class="card-deck w-100 m-0 p-0">
					<div class="row m-0 p-0">-->
			<div class="card col-12 col-md-6 col-lg-4 m-lg-0 p-lg-0 ">
				<div class="card-header card-header-rose card-header-icon">
					<div class="card-icon pointer" (click)="toggleEditBilling()">
						<i class="material-icons">credit_card</i>
					</div>
					<h5 class="card-title bold">Bill To:</h5>
					<ng-container *ngIf="!editbilling">
						<div class="card-body w-100 text-dark form-inputs">
							<div class="row">
								<div class="col-12 ">
									<span [innerHTML]="customer.billto"></span>
									<br>
									<b>Credit Limit: {{ customer.creditlimit | currency }}</b><br>
									<b>Price Level : {{ customerForm.get('salestype').value }}</b><br>
									<div class="row m-0 p-0">
										<div class="col-12 m-0 p-0 text-right addcard pointer">
											<i class="material-icons" (click)="toggleOneTimeCard('cc')">add</i>
											<i class="material-icons" (click)="toggleOneTimeCard('cc')">credit_card</i>
										</div>
									</div>
								</div>

								<ng-container *ngIf="(onetimecard || arpreauth) && editsallowed.takepayment">


									<mat-button-toggle-group [formControl]="chargeTypeSwitch" aria-label="Font Style">
										<mat-button-toggle value="card_onfile">Card On File</mat-button-toggle>
										<mat-button-toggle value="newcard">New Card</mat-button-toggle>
									</mat-button-toggle-group>

									<app-credit-card-profile-select [customer]="customer" [type]="'orderentry'" [arpreauth]="arpreauth" (updatePayments)="attachCard($event)" class="w-100 ml-0 p-0" *ngIf="chargeTypeSwitch.value =='card_onfile'" ></app-credit-card-profile-select>

									<app-credit-card-form [customer]="customer" [type]="'orderentry'" [arpreauth]="arpreauth" (updatePayments)="attachCard($event)" class="w-100" *ngIf="chargeTypeSwitch.value =='newcard'"></app-credit-card-form>

								</ng-container>

								<ng-container *ngIf="customerForm.get('payment_method').value != '' && customerForm.get('payment_method').value ">
									<span class="ml-4 cc_charge">
										<div class="payment-header">
											PAYMENT METHOD SELECTED:
										</div>
										<div class="payment-details">
											<span class="card-type">{{ customerForm.get('payment_method').value.card_type }}</span>
											<span class="card-number">{{ customerForm.get('payment_method').value.number }}</span>
											<button mat-icon-button class="remove-button" (click)="removePaymentMethod()">
												<mat-icon>remove</mat-icon>
											</button>
										</div>
									</span>
								</ng-container>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="editbilling">
						<div class="card-body w-100 text-dark form-inputs" @grow>
							<div class="row">
								<div class="col-md-12">
									<mat-form-field appearance="standard">
										<mat-label>Name</mat-label>
										<input matInput required value="" formControlName="name">
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<mat-form-field appearance="standard">
										<mat-label>Address 1</mat-label>
										<input matInput required value="" formControlName="address1">
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<mat-form-field appearance="standard">
										<mat-label>Address 2</mat-label>
										<input matInput value="" formControlName="address2">
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4">
									<mat-form-field appearance="standard">
										<mat-label>City</mat-label>
										<input matInput value="" formControlName="address3">
									</mat-form-field>
								</div>
								<div class="col-md-4">
									<mat-form-field appearance="standard" class="" *ngIf="zones">
										<mat-label>Region</mat-label>
										<mat-select formControlName="address4">
											<mat-option *ngFor="let zone of zones.zones" [value]="zone.code">
												{{zone.code }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-4">
									<mat-form-field appearance="standard">
										<mat-label>Postal</mat-label>
										<input matInput value="" formControlName="address5">
									</mat-form-field>
								</div>
								<div class="col-md-12">
									<mat-form-field appearance="standard" class="" *ngIf="zones">
										<mat-label>Country</mat-label>
										<mat-select formControlName="address6">
											<mat-option *ngFor="let zone of zones.countries" [value]="zone.name">
												{{ zone.name }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
			<div class="card col-12 col-md-6 col-lg-4  mt-lg-0 pt-lg-0 mb-lg-0 mt-lg-0 ">
				<div class="card-header card-header-danger card-header-icon">
					<div class="card-icon pointer" (click)="toggleEditShipping()">
						<i class="material-icons">account_circle</i>
					</div>
					<h5 class="card-title bold">Ship To: {{customerdata.branchcode}}</h5>

					<h4 class="card-title">
						{{ getRouteName(customer.route) }}
						<ng-container *ngIf="customerdata.shiptocount > 1">
							<small>
								<button mat-icon-button [matMenuTriggerFor]="branchchange" color="accent" aria-label="Change Branch">
									<mat-icon>menu</mat-icon>
								</button>
							</small>
							<mat-menu #branchchange="matMenu">
								<ng-container *ngFor="let branch of customerdata.branches">
									<button mat-menu-item (click)="changeBranch(branch.branchcode)">
										<mat-icon>sync_alt</mat-icon>
										<span>{{ branch.branchcode }}: {{branch.brname}} {{ branch.braddress1 }}</span>
									</button>
								</ng-container>
							</mat-menu>
							<small>({{customerdata.shiptocount}})</small>
						</ng-container>
					</h4>

					<ng-container *ngIf="!editshipping">
						<div class="card-body w-100 text-dark form-inputs">
							<div class="row">
								<div class="col-12 ">
									<span [innerHTML]="customer.shipto"></span>
									<br />
									<b>Contact: {{ customer.contactname }}</b><br>
									<b>Phone#: {{ this.customerForm.get('phoneno').value }}</b><br>
									<b>Email:
										<span *ngIf="customerForm.get('email').value == ''">
											N/A
										</span>
										<span *ngIf="customerForm.get('email').value != ''">
											{{ this.customerForm.get('email').value }}
										</span>
									</b>
								</div>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="editshipping">
						<div class="card-body text-dark form-inputs" @grow>
							<div class="row">
								<div class="col-md-12 ">
									<mat-form-field appearance="standard">
										<mat-label>Name</mat-label>
										<input matInput value="" formControlName="brname">
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12 ">
									<mat-form-field appearance="standard">
										<mat-label>Address 1</mat-label>
										<input matInput value="" formControlName="braddress1">
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<mat-form-field appearance="standard">
										<mat-label>Address 2</mat-label>
										<input matInput value="" formControlName="braddress2">
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4">
									<mat-form-field appearance="standard">
										<mat-label>City</mat-label>
										<input matInput value="" formControlName="braddress3">
									</mat-form-field>
								</div>
								<div class="col-md-4">
									<mat-form-field appearance="standard" class="" *ngIf="zones">
										<mat-label>Region</mat-label>
										<mat-select formControlName="braddress4">
											<mat-option *ngFor="let zone of zones.zones" [value]="zone.code">
												{{zone.code }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-4">
									<mat-form-field appearance="standard">
										<mat-label>Postal</mat-label>
										<input matInput value="" formControlName="braddress5">
									</mat-form-field>
								</div>
								<div class="col-md-12">
									<mat-form-field appearance="standard" class="" *ngIf="zones">
										<mat-label>Country</mat-label>
										<mat-select formControlName="braddress6">
											<mat-option *ngFor="let zone of zones.countries" [value]="zone.name">
												{{ zone.name }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<mat-form-field appearance="standard">
										<mat-label>Price List</mat-label>
										<mat-select formControlName="salestype" (selectionChange)="repriceCart($event)">
											<mat-option *ngFor="let tpe of salestypes" [value]="tpe?.typeabbrev">
												{{tpe.typeabbrev}} {{tpe.sales_type}}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-6">
									<mat-form-field class="full-width" appearance="standard">
										<mat-label>Phone</mat-label>
										<input matInput formControlName="phoneno" matInput placeholder="phone#">
									</mat-form-field>
								</div>
								<div class="col-md-6">
									<mat-form-field class="full-width" appearance="standard">
										<mat-label>Email</mat-label>
										<input matInput formControlName="email" matInput placeholder="email">
									</mat-form-field>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
			<div class="card col-12 col-md-12 col-lg-4  m-lg-0 p-lg-0">
				<div class="card-header card-header-success card-header-icon">
					<div class="card-icon" (click)="toggleEditSettings()">
						<i class="material-icons">settings</i>
					</div>
					<h5 class="card-title bold">Order Details:</h5>
					<ng-container>
						<div class="card-body w-100 text-dark form-inputs">
							<div class="row ">
								<div class="col-md-6">
									<mat-form-field class="full-width" appearance="standard">
										<mat-label>Customer PO#</mat-label>
										<input formControlName="reference" matInput placeholder="PO number">
									</mat-form-field>
								</div>

								<div class="col-md-6" [ngClass]="issalesman ? 'd-none':''">
									<mat-form-field appearance="standard">
										<mat-label>Sales Person</mat-label>
										<mat-select formControlName="salesman">
											<mat-option *ngFor="let person of salespeople" [value]="person.salesmancode">
												{{ person.salesmanname }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-6" [ngClass]="issalesman ? 'd-none':''">
									<mat-form-field appearance="standard">
										<mat-label>Terms</mat-label>
										<mat-select formControlName="paymentterms">
											<mat-option *ngFor="let term of terms" [value]="term.termsindicator">
												{{ term.terms }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-6">
									<mat-form-field appearance="standard">
										<mat-label>Ship Via</mat-label>
										<mat-select formControlName="defaultshipvia">
											<mat-option *ngFor="let ship of shipvia" [value]="ship.shipper_id">
												{{ ship.shippername }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-6">
									<!-- <span matPrefix (click)="customerForm.get('freightcost').setValue(0.00);updateShipping()">X</span> -->
									<mat-form-field appearance="fill" class="mb-0">
										<mat-label>Freight</mat-label>

										<span matPrefix>$</span>
										<input matInput formControlName="freightcost" class="text-right" placeholder="Freight Cost" (focus)="selectText($event)" (input)="updateShipping()">
									</mat-form-field>
									<ng-container *ngIf="config.env.package === 'beauty'">
										<mat-checkbox class="ml-auto mr-auto mt-0 mb-0" [formControl]="doNotPrint" (change)="updatePrintButton()" [ngClass]="issalesman ? 'd-none':''">DO NOT PRINT</mat-checkbox>
									</ng-container>
								</div>
								<div class="col-md-6" [ngClass]="issalesman ? 'd-none':''">
									<mat-radio-group [formControl]="noteselector" aria-label="Date Wanted" class="text-center" (change)="shippingOptionChange($event)">
										<mat-radio-button value="0" checked>SHIP ASAP&nbsp;</mat-radio-button>
										<mat-radio-button value="1">Ship When Available</mat-radio-button>
										<mat-radio-button value="2">Specific Date</mat-radio-button>
									</mat-radio-group>


								</div>
								<div [ngClass]="{'col-md-6 ml-auto mr-0 text-right': noteselector.value == 2,'d-none': noteselector.value != 2}">
									<mat-form-field appearance="standard" class="text-right">
										<mat-label>Date Wanted</mat-label>
										<input matInput formControlName="datewanted" [matDatepicker]="orderdatepicker">
										<mat-datepicker-toggle matSuffix [for]="orderdatepicker"></mat-datepicker-toggle>
										<mat-datepicker #orderdatepicker></mat-datepicker>
									</mat-form-field>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
			<!-- </div>
				</div> -->
		</div>
		<div class="row m-0 p-0">
			<div class="col-md-6 m-0 p-0">
				<mat-form-field class="mr-1 full-widthh" appearance="fill">
					<mat-label>Dispatch Note</mat-label>
					<textarea matInput formControlName="comments" placeholder="Ex. Opens at 2pm..."></textarea>
				</mat-form-field>
			</div>
			<div class="col-md-6 m-0 p-0">
				<mat-form-field class="ml-1 w-100 full-width" appearance="fill">
					<mat-label>Invoice Note</mat-label>
					<textarea matInput formControlName="notes" placeholder="Ex. Thank you for your business..."></textarea>
				</mat-form-field>
			</div>
		</div>
		<div class="row m-0 p-0 cust-note-row" *ngIf="config.arpreauth && custNotes.length">
			<mat-expansion-panel hideToggle class="cust-note-expansion w-100">
			<mat-expansion-panel-header>
				<mat-panel-title> Customer Account Notes </mat-panel-title>
				<mat-panel-description class="m-0 p-0">
					<div class="col-12 m-0 p-0">
						<div class="float-right note-count">
							<span>(&nbsp;{{custNotes.length}}&nbsp;)&nbsp;</span><mat-icon class="align-top" >description</mat-icon>
						</div>
					</div>
				</mat-panel-description>
			</mat-expansion-panel-header>
				<div class="row justify-content-center">
					<div class="col-12 text-center">
						<mat-chip-list class="note-chip-list mt-2 mb-2 ml-auto mr-auto">
							<mat-chip *ngFor="let note of custNotes" class="note-chip ml-1 mr-1">
								<div class="note-content">
									<div class="note-details">
										{{ note.note }} <br>
										<small class="note-date">{{ note.notedate | date:'mediumDate' }}</small>
										<small class="note-user">{{ note.userid }}</small>
									</div>
								</div>
							</mat-chip>
						</mat-chip-list>
					</div>
				</div>
			</mat-expansion-panel>

		</div>
	</form>

	<ng-container class="w-100">
		<div class="container-fluid text-right" id="rightcart-content" *ngIf="!cart_items.length">
			<div class="row mt-0 mb-0 pt-0 pb-0 border-top small-font">
				<div class="col-md-12 mt-0 pt-0 text-center mb-3 mt-3">
					<h4>No Current Items</h4>
				</div>
			</div>
		</div>
		<div class="table mt-2 " *ngIf="cart_items.length">
			<table class="table table-fixed w-100 no-overflow">
				<thead>
					<tr>
						<th class="text-left">Item</th>
						<!-- <th class="text-left" *ngIf="config.env.package == 'beauty'">Coupon</th> -->
						<th class="text-left" *ngIf="!user.issalesman">Note</th>
						<th class="text-center">Qty</th>
						<th class="text-right small-td">Price</th>
						<th class="table-header text-right font-weight-bold discount-width" >
							<input matInput type="text"[(ngModel)]="discountAllRef" (keyup)="massUpdateDiscount($event)" class="header-input header-input-discount text-center" >
							% Discount
						</th>
						<!--
						<th *ngIf="config.discounts && editsallowed.discounts" class="text-right small-td text-nowrap">
							<div class="input-group text-right d-ruby">
								<input [(ngModel)]="discountAllRef" (keyup)="massUpdateDiscount($event)" class="input-dark text-white massdis-input text-center dics-input">
								<span class="input-group-text text-white font-weight-bolder">%</span> Discount
							</div>
						</th> -->
						<th class="text-center" *ngIf="config.pofromorder">Availability</th>
						<th class="text-right">T</th>
						<th class="text-right">Total</th>
						<th class="text-right"></th>
					</tr>
				</thead>
				<tbody>
					<ng-container *ngFor="let item of cart_items">
						<tr [ngClass]="item.parent_id ? 'child' : ''" *ngIf="!item.switching">
							<td class="text-left">
								<button mat-icon-button aria-label="Change Vendor" color="accent" (click)="viewPurchOrderOptions(item)" *ngIf="item.porequired =='1' && config.pofromorder">
									<mat-icon class="smaller-font">edit</mat-icon>
								</button>
								<span *ngIf="config.env.package !== 'tires' && item.parent_id && item.parent_id != ''" class="badge badge-success ml-2 pointer" (click)="item.switching = !(item.switching) ? true : false;"><span class="fa fa-paperclip"></span></span>
								<ng-container *ngIf="config.env.package == 'tires'">
									<b>&nbsp;{{item.stockid}}</b>&nbsp;&nbsp;
									<span class="bold">{{item.description}}</span>
								</ng-container>
								<ng-container *ngIf="config.env.package == 'beauty' && item.stockid == 'COUPON' && couponData != false">
									<div class="lh-0">
										<mat-icon [ngClass]="couponData[item.coupon_used].data.taxable == 1 ? 'taxable-coupon' : ''">redeem</mat-icon>
										<ng-container *ngIf='couponData[item.coupon_used].data.taxable == 1'>TAXABLE</ng-container>
										<b>&nbsp;{{item.stockid}}</b>&nbsp;&nbsp;
									</div>
									<span class="bold d-inline-block">
										<span [ngClass]="item.validity == 'valid'? 'valid-coupon' : 'invalid-coupon mat-elevation-z1'">
											<span style='text-align: center; display: block; color: rgb(24, 24, 24);'> {{couponData[item.coupon_used].data.name}} :
												<span style="color: rgb(70, 70, 70); font-style: italic;">{{couponData[item.coupon_used].data.code}}</span> @
												<span style="color: rgb(0, 0, 0);"> {{isCouponFixed(item.coupon_used)}}{{couponData[item.coupon_used].data.discount | number : '1.2-2'}}{{isCouponPercent(item.coupon_used)}} </span>
											</span>
										</span>
									</span>
								</ng-container>
								<ng-container *ngIf="config.env.package == 'beauty' && item.stockid != 'COUPON'">
									<b>&nbsp;{{item.stockid}}</b>&nbsp;&nbsp;

									<span class="bold">{{item.description}}</span>
								</ng-container>
							</td>
							<td class="text-left" *ngIf="!user.issalesman">
								<mat-form-field appearance="standard" class="m-0 p-0" *ngIf="item.mbflag != 'F'">
									<mat-label>Line Notes</mat-label>
									<input matInput maxlength="64" placeholder="Line Notes." [value]="item.notes" (input)="updateNote($event,item)">
								</mat-form-field>
							</td>
							<td class="text-center w-25">
								<mat-form-field appearance="standard" class="m-0 p-0 ">
									<mat-label>Qty</mat-label>
									<span matPrefix>#</span>
									<ng-container *ngIf="config.env.package == 'beauty' ">
										<input matInput type="text" placeholder="QTY" class="text-center" [value]="item.quantity" (input)="updateQuantity($event, item, true)" (focus)="selectText($event)" appNumericField>

									</ng-container>
									<ng-container *ngIf="config.env.package == 'tires' ">
										<input matInput type="text" placeholder="QTY" class="text-center" [value]="item.quantity" (input)="updateQuantity($event, item)" (focus)="selectText($event)" appNumericField>
									</ng-container>
									<mat-hint align="end" *ngIf="item.qtyinvoiced && item.qtyinvoiced != 0">{{item.qtyinvoiced}} / {{item.quantity}} invoiced </mat-hint>
								</mat-form-field>
							</td>
							<td class="text-right w-25">
								<mat-form-field appearance="standard" class="m-0 p-0 text-right">
									<mat-label>Price</mat-label>
									<span matPrefix>$</span>
									<ng-container *ngIf="config.env.package == 'tires' ">
										<input matInput type="text" class="text-right" placeholder="Price" [value]="item.price" (input)="updatePrice($event,item)" (focus)="selectText($event)" appNumericField>
									</ng-container>
									<ng-container *ngIf="config.env.package == 'beauty' ">
										<input matInput type="text" class="text-right" placeholder="Price" [value]="item.price" (input)="updatePrice($event,item,true)" (focus)="selectText($event)" appNumericField id="beautysalesman">
									</ng-container>
									<mat-hint *ngIf="config.discounts && item.discount > 0" class="text-right text-danger bolder">
										Discount Price {{ getDiscounPrice(item) | currency }} @ {{ item.discount }}%
									</mat-hint>
								</mat-form-field>
							</td>
							<td class="text-right w-25" *ngIf="config.discounts && editsallowed.discounts">
								<mat-form-field appearance="standard" class="text-right" *ngIf="item.mbflag != 'F'">
									<mat-label>Discount</mat-label>
									<input matInput type="text" class="text-right" placeholder="Discount" [value]="item.discount" (focus)="selectText($event)" (input)="updateDiscount($event,item)" appNumericField>
									<span matSuffix>%</span>
								</mat-form-field>
							</td>
							<td class="text-center" *ngIf="config.pofromorder">
								<div [ngClass]="{ 'bg-warning': item.porequired == '1', 'bg-success': item.porequired == '0' }" *ngIf="!item.parent_id">
									<p class="text-center text-white mt-3 bolder" *ngIf="item.porequired == '0'"> Available</p>
									<p class="text-center text-dark mt-3 bolder" *ngIf="item.porequired == '1'"> PO Required</p>
								</div>
							</td>
							<td class="text-right" (click)="updateTax(item)">
								<span *ngIf="item.taxtotal != 0">T</span>
								<span *ngIf="item.taxtotal == 0">N</span>
							</td>
							<td class="text-right">
								<b>{{ getDiscountTotal(item) | currency }}</b>
							</td>
							<td class="text-right">
								<button mat-icon-button color="warn" aria-label="remove" (click)="removeFromOrder(item.cart_id)">
									<mat-icon>remove</mat-icon>
								</button>
							</td>
						</tr>
						<ng-container *ngIf="item.switching">
							<tr [ngClass]="{'child': item.parent_id}">
								<td colspan="9">
									<div class="row">
										<div class="col-4">
											<mat-card class="item-card">
												<mat-card-header>
													<mat-card-title class="text-dark m-0 p-0">{{item.stockid}}</mat-card-title>
													<mat-card-subtitle class="text-dark m-0 p-0">{{item.description}}</mat-card-subtitle>
												</mat-card-header>
												<mat-card-content>
													<mat-form-field appearance="outline" class="full-width">
														<mat-label>Enter new item</mat-label>
														<input matInput [(ngModel)]="item.inputValue" autofocus placeholder="Enter value" (keyup)="itemSearch(item)">
														<button mat-icon-button matSuffix (click)="itemSearch(item)">
															<mat-icon>search</mat-icon>
														</button>
													</mat-form-field>
												</mat-card-content>
												<mat-card-actions>
													<div class="row">
														<div class="col-12">
															<button class="btn btn-danger full-width-button" (click)="item.switching = false;">Cancel</button>
														</div>
													</div>
												</mat-card-actions>
											</mat-card>
										</div>
										<div class="col-8">
											<div *ngIf="swapitems.length">
												<div class="item-details" *ngFor="let sitem of swapitems">
													<div class="row bg-white border-top align-items-center p-2">
														<div class="col-2">
															<img [src]="sitem.thumb" alt="Item Image" class="img-thumbnail">
														</div>
														<div class="col-8 text-left">
															<span><strong>{{sitem.brand}} : {{sitem.stockid}}</strong> - {{sitem.price | currency }} - Stock: <b>{{sitem.locqoh}}</b> - Unit: {{sitem.units}}</span>
														</div>
														<div class="col-2 text-right">
															<button mat-button (click)="item.switching =false; selectSwapItem(sitem, item)" class="btn btn-primary">Select</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</td>
							</tr>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="config.orderadjustments">
						<tr [ngClass]="issalesman ? 'd-none':''">
							<td>
								<b>Order Adjustment</b>
							</td>
							<td class="text-left">
								<mat-form-field appearance="standard" class="m-0 p-0">
									<mat-label>Adjustment Reason</mat-label>
									<input matInput maxlength="64" placeholder="Bounced check, refund, etc" [formControl]="adjustTitleRef" (input)="updateAdj()">
								</mat-form-field>
							</td>
							<td *ngIf="config.discounts && editsallowed.discounts">
							</td>
							<td class="text-right w-25">
								<mat-form-field appearance="standard" class="m-0 p-0 text-right">
									<mat-label>Amount</mat-label>
									<span matPrefix>$</span>
									<input matInput type="text" class="text-right" placeholder="0.00" [formControl]="adjustRef" (input)="updateAdj()" appSelectOnFocus appNumericField>
								</mat-form-field>
							</td>
						</tr>
					</ng-container>
				</tbody>
			</table>
		</div>
		<div *ngIf="cart_items.length" class="order-totals">
			<div class="col-12 p-0 m-0 text-right" *ngFor="let total of cart_totals">
				<div class="order-total" *ngIf="(total.text !== '0.00' && total.title == 'Discount Total') || !['Discount Total'].includes(total.title)">
					<span class="total-title">{{ total.title }}</span>:
					<span class="total-value">{{ total.text | currency }}</span>
				</div>
			</div>
			<div class="col-12 p-0 m-0 text-right" *ngIf="config.orderadjustments && additionalcharge">
				<div class="order-total">
					<span class="total-title">Adjustment</span>:
					<span class="total-value">{{ additionalcharge | currency }}</span>
				</div>
				<div class="order-total">
					<span class="total-title">Adjusted Total</span>:
					<span class="total-value">{{ adjustedTotal | currency }}</span>
				</div>
			</div>
		</div>
	</ng-container>
</ng-container>