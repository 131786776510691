import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router } from '@angular/router';

import { CustomerService } from '../../../services/customer.service';
import { GlobalsService } from '../../../services/globals.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { Customer } from '../../../classes/customer';
import { Location } from '@angular/common';


@Component({
	selector: 'app-customer-edit',
	templateUrl: './customer-edit.component.html',
	styleUrls: ['./customer-edit.component.scss'],
	animations: [
		trigger('flipState', [
		  state('active', style({
			  transform: 'rotateY(-179deg)'
		  })),
		  state('inactive', style({
			  transform: 'rotateY(0)'
		  })),
		  transition('inactive => active', animate('250ms ease-in')),
		  transition('active => inactive', animate('250ms ease-out')),
		]),
	   trigger('openClose', [
			state('true', style({ height: '*' })),
			state('false', style({ height: '0px' })),
			transition('false <=> true', animate(500))
	  ]),
	  trigger('filterAnimation', [
			transition(':enter, * => 0, * => -1', []),
			transition(':increment', [
		  query(':enter', [
					style({ opacity: 0, width: 0 }),
					stagger(50, [
			  animate('300ms ease-out', style({ opacity: 1, width: '*' })),
					]),
		  ], { optional: true })
			]),
			transition(':decrement', [
		  query(':leave', [
					stagger(50, [
			  animate('300ms ease-out', style({ opacity: 0, width: 0 })),
					]),
		  ])
			]),
	  ]),
	  trigger('grow', [ // Note the trigger name
			transition(':enter', [
		  // :enter is alias to 'void => *'
		  style({ height: '0',width: '0' }),
		  animate(200, style({ height: '*' , width: '*' })),
		  animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
		  // :leave is alias to '* => void'
		  animate(300, style({ height: 0, width: 0  })),
		  animate('300ms', style({ opacity: 0 }))
			])
	  ]),
	]
})
export class CustomerEditComponent implements OnInit {
	@Input() customerdata;
	@Output() editing = new EventEmitter<boolean>();
	@Output() newcustomerdata = new EventEmitter<boolean>();

	customerForm: any = false;
	customertypes: any = [];
	//customerdata: any = [];
	holdreasons: any = [];
	zones: any = [];
	terms: any = [];
	currencies: any = [];
	salestypes: any = [];
	//allowed edit fields - need to add to branch as well
	formEditAllowed = [
		'debtorno',
		'name',
		'address1',
		'address2',
		'address3',
		'address4',
		'address5',
		'address6',
		'cosm_license',
		'licExpDate',
		'signed_mor',
		//'contactname'
	]
	user: any = [];
	config: any = false;

	constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder,private customerService: CustomerService, public router: Router, private location: Location, private globalsService: GlobalsService, private globalSearchService: GlobalSearchService) {
		this.globalSearchService.user.subscribe(u => {
			this.user = u
		})
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});
	}

	removeCustomer() {
		if(this.customerdata) {

			if(this.user.user.customeradmin === '1') {
				this.customerService.removeDebtor(this.customerdata).subscribe(async (results: any) => {
					if(results.success) {
						this.router.navigate(['./customers']);
					} else {
						alert('Can Not Remove Customer: \n'+results.reasons);
					}
				});
			}
		}
	}

	ngOnInit(): void {
	
		this.globalSearchService.zones.subscribe(async (results: any) => {
			this.zones = results;
		});
	
		const details = this.customerdata.customer;
		const controls = [];
		const formGroup: UntypedFormGroup = new UntypedFormGroup({});
	
		Object.keys(details).forEach(key => {
			if (this.formEditAllowed.includes(key)) {
				let value = details[key] ? details[key] : '';
	
				if (key === 'signed_mor') {
					value = (value === 'Y');
				}
	
				if (key === 'licExpDate') {
					if(this.config.env.package === 'beauty') {
						value = value ? new Date(value) : null;
						controls.push({
							name: key,
							control: new UntypedFormControl(value, [this.dateOrNullValidator])
						});
					}
				} else {
					// Other controls
					controls.push({ name: key, control: new UntypedFormControl(value, []) });
				}
			}
		});
	
		controls.forEach(f => {
			formGroup.addControl(f.name, f.control);
		});
	
		this.customerForm = formGroup;
	
		// Mark all controls as touched to trigger validation errors
		Object.keys(this.customerForm.controls).forEach(controlName => {
			this.customerForm.get(controlName)?.markAsTouched();
		});
	}
	
	dateOrNullValidator(control: UntypedFormControl) {
		const value = control.value;
		if (value === null || value === '' || value === ' ' || value instanceof Date && !isNaN(value.getTime())) {
			return null;
		}
		return { invalidDate: true };
	}

	onSave($event){
	}

	onSubmit() {
		this.customerService.updateDebtor(this.customerForm.value).subscribe( (results) => {
			this.newcustomerdata.emit(results);
			this.editing.emit(false);
		})
	}

	back(): void {
		this.editing.emit(false);
	}

	ngAfterViewInit() {



	}
}
