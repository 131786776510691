import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup,FormControl, ValidationErrors } from '@angular/forms';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { OrdersService } from '../../services/orders.service';
import { ReportsService } from '../../services/reports.service';
import { PrintService } from '../../services/print.service';
import { CardLists, Card } from '../../classes/orders';
import { Router } from '@angular/router';
import { Ng2CompleterModule } from 'ng2-completer';
import { Location } from '@angular/common'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

declare let $: any;

@Component({
	selector: 'app-order-item-inquiry',
	templateUrl: './order-item-inquiry.component.html',
	styleUrls: ['./order-item-inquiry.component.scss']
})
export class OrderItemInquiryComponent implements OnInit {
@ViewChild('orderView') orderStatusViewRef: ElementRef;
@ViewChild('print_table') printtable: ElementRef;
itemSearchRef = new FormControl('');
orderSearchRef = new FormControl('');
branchRef = new UntypedFormControl('');
filterresults: any = [];
filteredItems: any = [];
orderData: CardLists;
filename: any = 'OpenOrderItems';
customerData: any = [];
openOrderData: any = [];
orders: any = [];
orderSearch: any = [];
orderResults: any = false;
ordersFound: any = [];
sending: any = false;
currdate: any = '';

	@Input() name: string;
	color: any = 'blue';
	config: any = [];
	pickinglink: any = '';
	invoicelink: any = '';
	orderdetails: any = false;
	branches: any = [];

	ngOnInit(): void {}
	constructor(private globalSearchService: GlobalSearchService, public router: Router, private printService: PrintService, private reportsService: ReportsService, private ordersService: OrdersService,private location: Location, private modalService: NgbModal) {

		this.color = this.globalSearchService.getColor();
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});
		this.reportsService.getBranches().subscribe((results: any) => {
			this.branches = results;
		});
		this.globalSearchService.mesagesallowed.next(false);

		this.pickinglink = this.config.apiServer.baseUrl + this.config.apiServer.pickingLink;
		this.invoicelink = this.config.apiServer.baseUrl + this.config.apiServer.pickingInvoice;
	}
	public searchOrders() {
		if(this.branchRef.value == ''){
			this.showNotification('Please Select a Location','danger','top','center');
		} else {
			this.sending = true;
			const data = { stockid: this.orderSearchRef.value,
				loccode: this.branchRef.value,
			}

			this.ordersService.getOII(data).subscribe(r => {
				this.sending = false;
				this.orderResults = r;
				this.currdate = new Date();
			})
		}

	}
	back(): void {
		this.location.back()
	}
	exportPdf() {
		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);

		const data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	exportXls() {
		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}
	showNotification(message, type, from, align) {
		//  const type = ['','info','success','warning','danger'];
		// const color = Math.floor((Math.random() * 4) + 1);

		$.notify({
			icon: 'notifications',
			message: message

		}, {
			type: type,
			timer: 1000,
			placement: {
				from: from,
				align: align
			},
			template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
				'<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
				'<i class="material-icons" data-notify="icon">notifications</i> ' +
				'<span data-notify="title">{1}</span> ' +
				'<span data-notify="message">{2}</span>' +
				'<div class="progress" data-notify="progressbar">' +
				'<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
				'</div>' +
				'</div>'
		});
	}

}
