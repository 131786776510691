<div class="main-content" id="top">
    <div class="container-fluid">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header card-header-{{color}}-5">
                    <h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Barcode Assignment</h4>
                    <p class="card-category"></p>
                    <div class="messages">
                    </div>
                </div>
                <div class="card-body p-0 pt-2">
                    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">

                        <!-- lookup prod from barcode -->
                        <div class="col-12 row">
                            <div class="col-8">
                                <mat-form-field appearence="outline">
                                    <mat-label>Scan a barcode</mat-label>
                                    <input matInput value="" type="text" formControlName="barcode" type="input" (keyup.enter)="search();" id="barcodesearch">
                                </mat-form-field>
                                <button type="submit" class="d-none"></button>
                            </div>
                            <div class="col-4 text-center align-middle">
                                <button mat-raised-button color="accent" (click)="search()"><mat-icon class="align-middle">search</mat-icon></button>
                            </div>
                        </div>

                        <div class="col-12">
                            <!-- multiple prod assigned to barcode select & edit -->
                            <ng-container *ngIf="multProd">
                                <ul class="list-unstyled entry-list">
                                    <li *ngFor="let sku of multProd" [ngClass]="activeProduct.stockid == sku.stockid ? 'bg-highlight' : ''">
                                        <div class="spec-label">{{sku.stockid}}</div>
                                        <div class="spec-value text-center">
                                            <button class="mtb-10 " mat-raised-button (click)="activeProd(sku)">Select</button>
                                        </div>
                                    </li>
                                </ul>
                            </ng-container>
                        </div>

                        <!-- item container -->
                        <div class="col-12 p-1">
                            <ng-container *ngIf="singProd">
                                <div class="row m-0 p-0 align-middle">
                                    <div class="col-12 p-0 text-center overflow-hidden">
                                        <img src="{{singProd.details.thumb}}">
                                    </div>
                                    <div class="col-12 text-center">
                                        <ul class="list-unstyled entry-list">
                                            <li>
                                                <div class="spec-label">STOCKID</div>
                                                <div class="spec-value">{{singProd.details.stockid}}</div>
                                            </li>
                                            <li>
                                                <div class="spec-label">MFG</div>
                                                <div class="spec-value">{{singProd.details.manufacturer.name}}</div>
                                            </li>
                                            <li>
                                                <div class="spec-label">LINE</div>
                                                <div class="spec-value">{{singProd.details.lineid}}</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-12 p-0" *ngIf="!showStockidSearch">
                                    <h3 class="bg-gains">Descriptions</h3>
                                    <ng-container *ngIf="singProd.details.description">
                                        <p>{{singProd.details.description}}</p>
                                    </ng-container>
                                    <ng-container *ngIf="!singProd.details.description">
                                        <p>V8 has no description</p>
                                    </ng-container>
                                    <ng-container *ngIf="singProd.details.descriptions.description">
                                        <h5 class="bg-gains">Web</h5>
                                        <p [innerHtml]="singProd.details.descriptions.description" class="mh-200 overflow-auto"></p>
                                    </ng-container>
                                </div>
                                <div class="col-12 p-0 row justify-content-between m-auto" *ngIf="!showStockidSearch">
                                    <button mat-raised-button class="bg-danger text-white" (click)="assign(singProd, 'remove')">Remove</button>
                                    <button mat-raised-button color="primary" class="text-white" (click)="change('replace')">Change</button>
                                    <!-- <button mat-raised-button class="bg-warning" (click)="change('add')">Add</button> -->
                                    <button mat-raised-button class="bg-success text-white" (click)="resetAll()">OK</button>
                                </div>
                            </ng-container>


                            <ng-container *ngIf="!singProd && !multProd"></ng-container>
                        </div>

                        <!-- assign -->
                        <div class="col-12 p-0 row text-center justify-content-center mt-1" *ngIf="showStockidSearch">
                            <div class="w-100 text-center" *ngIf="!singProd && !multProd">No results for '{{searchForm.get('barcode').value}}'</div>
                            <div class="col-8">
                                <mat-form-field appearence="outline" >
                                    <mat-label>Search for product</mat-label>
                                    <input matInput value="" type="text" formControlName="prod" (keyup.enter)="searchSku();" id='prodsearch'>
                                </mat-form-field>
                            </div>
                            <div clas="col-4">
                                <button mat-raised-button (click)="searchSku()">Search</button>
                            </div>
                        </div>
                        <div class="col-12" *ngIf="allitems">
                            <ul class="list-unstyled">
                                <li *ngFor="let prod of allitems">
                                    <div class="col-12 li-item">
                                        <div class="row">
                                            <div class="col-8 align-middle">
                                                {{prod.stockid}}
                                                <hr>
                                                {{prod.description}}
                                            </div>
                                            <div class="col-4 align-middle-flex">
                                                <button *ngIf="type == 'add'" class="mb-1" mat-raised-button color="primary" (click)="assign(prod, 'assign')">Add</button>
                                                <button *ngIf="type == 'replace'" mat-raised-button color="primary" (click)="assign(prod, 'replace', singProd)">Change</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class=""></div>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>