<div class="row">
	<div class="col-md-4 mt-4">
		<h5 class="bold">Select Bin(s) To Merge</h5>
		<mat-form-field class="col-md-12 text-right" appearance="outline" *ngIf="bins">
			  <mat-label>Search Available Bins</mat-label>
			  <input type="text"
					 aria-label="Item"
					 matInput
					 [(ngModel)]="search_available"
					 (input)="searchBin($event)">
			<button *ngIf="search_available != ''" matSuffix mat-icon-button aria-label="Clear" (click)="search_available='';searchBin($event)">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
		<mat-divider></mat-divider>
		<h6 class="mt-2 mb-2">Available Bins ({{filteredbins.length}})</h6>
		<mat-action-list class="borderd bin-box" dense >
		  <button mat-list-item (click)="moveTo(bin)" class="mat-list-item-bordered" *ngFor="let bin of filteredbins">

			  <span *ngIf="bin.location == ''"><b>NO BIN</b> ({{ bin.count }}) <b>w/QOH</b> ({{ bin.active_items }})</span>
			  <span *ngIf="bin.location != ''"><b>{{ bin.location }}</b>  <b>#</b> ({{ bin.count }}) <b>w/QOH</b> ({{ bin.active_items }})</span>
			  <span class="ml-auto mr-0"><small> <mat-icon color="warning" class="small">add</mat-icon></small></span>
		  </button>
		</mat-action-list>
	</div>
	<div class="col-md-4 mt-4">
		<h5 class="bold">Selected Bins</h5>
		<mat-form-field class="col-md-12 text-right" appearance="outline" *ngIf="bins">
			  <mat-label>Search Selected Bins</mat-label>
			  <input type="text"
					 aria-label="Item"
					 matInput
					 [(ngModel)]="search_current"
					 (input)="searchSelectedBin($event)">
					 <button *ngIf="search_current" matSuffix mat-raised-button  mat-icon-button aria-label="Clear" (click)="search_current='';searchSelectedBin($event)">
						 <mat-icon>close</mat-icon>
					 </button>
		</mat-form-field>
		<mat-divider></mat-divider>
		<h6 class="mt-2 mb-2">Selected Bins ({{ tobins.length }})</h6>
		<mat-action-list class="borderd bin-box" dense *ngIf="tobins.length">
		  <button mat-list-item (click)="moveFrom(bin)" class="mat-list-item-bordered" *ngFor="let bin of filteredtobins">

			  <span *ngIf="bin.location == ''"><b>NO BIN</b> ({{ bin.count }}) <b>w/QOH</b> ({{ bin.active_items }})</span>
				<span *ngIf="bin.location != ''"><b>{{ bin.location }}</b>  <b>#</b> ({{ bin.count }}) <b>w/QOH</b> ({{ bin.active_items }})</span>

			  <span class="ml-auto mr-0"><small> <mat-icon color="accent" class="small">remove</mat-icon></small></span>

		  </button>
		</mat-action-list>
		<mat-action-list class="borderd bin-box" dense *ngIf="!tobins.length">
			<mat-list-item class="text-warning">Select Bins to Merge</mat-list-item>
		</mat-action-list>
	</div>
	<div class="col-md-4 mt-4">

		<h5 class="bold" [ngClass]="transferBin.valid ? '' : 'text-danger'">Transfer Bin {{ neworcurrent }}</h5>
		<mat-divider></mat-divider>
		<mat-form-field appearance="outline" class="text-right">
			<mat-label>Select Transfer Bin or Create New Bin</mat-label>
			<input type="text"
				placeholder="Transfer To Bin"
				aria-label="Transfer To Bin"
				matInput
				required
				[maxLength]="8"
				[formControl]="transferBin"
				[matAutocomplete]="transferbin"
				getOrderDetails>
				<button *ngIf="transferBin.value.length" matSuffix mat-icon-button aria-label="Clear" (click)="transferBin.setValue('')">
					<mat-icon>close</mat-icon>
				</button>
			<mat-hint>{{ transferBin.value.length }} / 8</mat-hint>
			<mat-autocomplete autoActiveFirstOption #transferbin="matAutocomplete">
				<mat-option *ngFor="let bin of filteredOptions" [value]="bin.location">
					{{bin.location }}
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>

		<mat-divider></mat-divider>

		<div class="col-12 notification text-right">
			<h6 class="mt-2 mb-2" *ngIf="!tobins.length || transferBin.value ==''">*Issues</h6>
			<mat-list dense class="bin-box" *ngIf="!tobins.length || transferBin.value ==''">
				<mat-list-item *ngIf="!tobins.length" class="mat-list-item-bordered text-danger bold"><mat-icon>error</mat-icon> Select Bins To Merge</mat-list-item>
				<mat-list-item *ngIf="transferBin.value ==''"  class="mat-list-item-bordered text-danger bold"><mat-icon>error</mat-icon> Select/Create a Transfer Bin</mat-list-item>
			</mat-list>

			<!-- <h6 class="mt-2 mb-2">{{neworcurrent}}</h6> -->
			<div *ngIf="!sending">
			<button *ngIf="tobins.length && transferBin.value !='' && config.env.package == 'tires'" mat-raised-button color="warn" class="mt-3 mb-3"(click)="moveItems()" [disabled]="newbin">Merge Bins</button>
			<button *ngIf="tobins.length && transferBin.value !='' && config.env.package != 'tires'" mat-raised-button color="warn" class="mt-3 mb-3"(click)="moveItems()">Merge Bins</button>
			</div>
			<div *ngIf="sending">
				<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
				</svg>
			</div>
			<div *ngIf="affected">
				<h6 class="mt-2 mb-2">Affected Items</h6>
				<div class="row" *ngFor="let item of affected">
					<div class="col-12"> {{ item.stockid }} </div>
				</div>
			</div>
		</div>
	</div>
</div>
