<div class="main-content">
  <div class="container-fluid">
	  <div class="row">
		  <div class="col-md-12">
			  <div class="card">
				  <div class="card-header card-header-danger">
					  <h4 class="card-title">Upload A File</h4>
					  <p class="card-category">Mapping will occur after</p>
				  </div>
				  <div class="card-body">

					  <app-vendor-lookup *ngIf="selectedsupplier ==''"></app-vendor-lookup>
					  <ngx-dropzone [multiple]="false" (change)="onSelect($event)" *ngIf="!uploadresults && selectedsupplier !=''">
						<ngx-dropzone-label>DROP FILE OR CLICK TO UPLOAD!</ngx-dropzone-label>
						<ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)"
						(error)="onUploadError($event)"
						(success)="onUploadSuccess($event)"
						>
							<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
						</ngx-dropzone-preview>
					</ngx-dropzone>
					<div class="table" *ngIf="uploadresults">
						<h3>PO For: {{ supplierDetails.VENDOR_NAME }}</h3>
						<h4>{{ uploadresults.found }} Items </h4>
						<button class="btn btn-sm btn-success" (click)="createPO()">Create Purchase Order</button>
						<table class="table">
							<thead>
								<th>Inv#</th>
								<th>Their Part#</th>
								<th>Our Part#</th>
								<th>Their Price</th>
								<th>Our Price</th>
								<th>Their Desc</th>
								<th>Our Desc</th>
								<th>Qty Shipped</th>
								<th>Qty B/O.</th>
								<th>Qty Ordered.</th>
								<th>PO#</th>
							</thead>
							<tbody>
							<tr *ngFor="let line of uploadresults.lines">
								<td>{{ line.invoiceno  }}</td>
								<td>{{ line.internal }}</td>
								<td>{{ line.stockid  }}</td>
								<td>{{ line.cprice }}</td>
								<td>{{ line.price }}</td>
								<td>{{ line.cdescription }}</td>
								<td>{{ line.description }}</td>
								<td>{{ line.quantity - line.backorder }}</td>
								<td>{{ line.backorder }}</td>
								<td>{{ line.quantity }}</td>
								<td>{{ line.ponumber }}</td>
							</tr>
							</tbody>
						</table>
					</div>
				  </div>
			  </div>
		  </div>
	  </div>
  </div>
</div>
