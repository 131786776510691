<ng-template #cashModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title">Ending Cash Details ({{ dreg.endcash }})</h4>
		<button mat-icon-button type="button" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<div class="modal-body">
		<div class="cash-modal-container">
			<div class="cash-drawer" *ngFor="let cd of dreg.cashdrawers; let i = index">
				<h5 class="drawer-title">Cash Drawer # {{ i + 1 }} ({{ cd.total | currency }})</h5>
				<table class="cash-drawer-table">
					<ng-container *ngFor="let bill of cashBills">
						<tr>
							<th>{{ bill.label }}</th>
							<td>
								<mat-form-field appearance="fill">
									<mat-label>{{ bill.placeholder }}</mat-label>
									<input matInput type="text" [(ngModel)]="cd[bill.model]" (ngModelChange)="cd[bill.model] = cd[bill.model] || 0; updateCash()" (focus)="$event.target.select()" class="text-right" [placeholder]="0" />
								</mat-form-field>
							</td>
						</tr>
					</ng-container>
				</table>
			</div>
		</div>
	</div>
</ng-template>

<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i> Daily Sales Register
						</h4>
						<p class="card-category text-right">DREG</p>
					</div>
					<div class="card-body">
						<button *ngIf="!sending && sent" class="text-right alert alert-success" (click)="unlockDREG();">DREG Finalized</button>
						<button *ngIf="!sending && !sent" style="float:right" class="btn btn-sm btn-secondary" color="primary" (click)="emailDREG();">Finalize DREG</button>
						<span *ngIf="sending" class="ml-2">
								<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
						</span>
						<div class="row">
							<div class="col-md-6">
								<mat-form-field appearance="fill">
									<mat-label>Choose a date</mat-label>
									<input matInput [matDatepicker]="picker" [(ngModel)]="dreg.dregdate" (ngModelChange)="dregdatechange($event)">
									<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
									<mat-datepicker #picker></mat-datepicker>
								</mat-form-field>
							</div>
							<div class="col-md-6">
								<mat-form-field appearance="fill">
									<mat-label>Location</mat-label>
									<mat-select [formControl]="location" (selectionChange)="changeLocation($event)">
										<mat-option *ngFor="let v of branches" [value]="v.loccode">
											{{v.locationname}}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
						</div>
						<table>
							<tr>
								<th>Payments Taken</th>
								<th>Cash Drawer</th>
								<th>Expected Deposit</th>
								<th> Expenses <button class="btn btn-sm btn-primary" (click)="addexp($event)" [disabled]="canedit === false"> Add </button></th>
							</tr>
							<tr>
								<td class="align-top">
									<table>
										<tr *ngFor="let payment of dreg.payments; let i = index">
											<mat-form-field class="full-width" appearance="fill">
												<mat-label>{{ payment.name }}</mat-label>
												<td><input type="number" matInput [(ngModel)]="payment.total" placeholder="0.00" disabled="disabled"></td>
											</mat-form-field>
										</tr>
									</table>
								</td>
								<td class="align-top">
									<table>
										<tr>
											<td>
												<mat-form-field class="full-width" appearance="fill">
													<mat-label>Starting Cash</mat-label>
													<input type="number" matInput [(ngModel)]="dreg.startcash" placeholder="0.00" (ngModelChange)="recalc()" [disabled]="canedit === false">
												</mat-form-field>
											</td>
										</tr>
										<tr>
											<td>

													<button style="width:100%" class="btn btn-md btn-primary" (click)="cashedit()" [disabled]="canedit === false"> Ending Cash: ${{ dreg.endcash }} </button>

											</td>
										</tr>
									</table>
								</td>
								<td class="align-top">
									<table>
										<tr>
											<td>
												<mat-form-field class="full-width" appearance="fill">
													<mat-label>Sales Total</mat-label>
													<input type="number" matInput [value]="dreg.salestotal" placeholder="0.00" disabled="disabled">
												</mat-form-field>
											</td>
										</tr>
										<tr>
											<td>
												<mat-form-field class="full-width" appearance="fill">
													<mat-label>Drawer Cash Change</mat-label>
													<input type="number" matInput [value]="dreg.startcash - dreg.endcash" placeholder="0.00" disabled="disabled">
												</mat-form-field>
											</td>
										</tr>
										<ng-container *ngFor="let payment of dreg.payments; let i = index">
											<tr *ngIf="dreg.payments[i].cash==0 && dreg.payments[i].total != 0">
												<mat-form-field class="full-width" appearance="fill">
													<mat-label>Less {{ payment.name }}</mat-label>
													<td><input type="number" matInput [(ngModel)]="dreg.payments[i].total" placeholder="0.00" disabled="disabled"></td>
												</mat-form-field>
											</tr>
										</ng-container>
										<tr>
											<mat-form-field class="full-width" appearance="fill">
												<mat-label>Pre-expense Subtotal</mat-label>
												<td><input type="number" matInput [ngModel]="dreg.calctotal" placeholder="0.00" disabled="disabled"></td>
											</mat-form-field>
										</tr>
									</table>
								</td>
								<td class="align-top">
									<table>
										<tr *ngFor="let expense of dreg.expenses; let i = index">
											<td>
												<mat-form-field class="half-width" appearance="fill">
													<mat-label>Expense Amount {{ i + 1}}</mat-label>
													<input type="number" matInput [(ngModel)]="dreg.expenses[i].total" placeholder="0.00" (ngModelChange)="recalc()" [disabled]="canedit === false">
												</mat-form-field>
											</td>
											<td>
												<mat-form-field class="half-width" appearance="fill">
													<mat-label>Expense Label {{ i + 1 }}</mat-label>
													<input matInput [(ngModel)]="dreg.expenses[i].name" placeholder="" [disabled]="canedit === false">

												</mat-form-field>

											</td>
											<td class="align-middle"><button class="btn btn-primary btn-flat" color="primary" (click)="removeexpense(i)" [disabled]="canedit === false"> X </button></td>
										</tr>
										<tr>
											<mat-form-field class="full-width" appearance="fill">
												<mat-label>Expected Deposit</mat-label>
												<td><input type="number" matInput [ngModel]="dreg.calcdeposit" placeholder="0.00" disabled="disabled"></td>
											</mat-form-field>
										</tr>
										<tr>
											<mat-form-field class="full-width" appearance="fill">
												<mat-label>Actual Deposit</mat-label>
												<td><input type="number" matInput [(ngModel)]="dreg.deposit" placeholder="0.00" (ngModelChange)="recalc()" [disabled]="canedit === false"></td>
											</mat-form-field>
										</tr>
										<tr>
											<mat-form-field class="full-width" appearance="fill">
												<mat-label>Over / Short</mat-label>
												<td><input type="number" matInput [(ngModel)]="dreg.overshort" placeholder="0.00" (ngModelChange)="recalc2()" [disabled]="canedit === false"></td>
											</mat-form-field>
										</tr>
									</table>
								</td>
							</tr>

						</table>
						<div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div style="display:none;" #print_table>
		<table>
			<tr>
				<th colspan="2">Daily Sales Register</th>
			</tr>
			<tr>
				<th colspan="2"> {{ dreg.dregdate | date:'MM/d/Y' }}</th>
			</tr>
			<tr>
				<th colspan="2"> Branch {{ dreg.loccode }}</th>
			</tr>
			<tr>
				<th colspan="2"> Payments Taken </th>
			</tr>
			<tr *ngFor="let payment of dreg.payments; let i = index">
				<td>{{ payment.name }}</td>
				<td>{{ dreg.payments[i].total}}</td>
			</tr>
			<tr>
				<th colspan="2"> Cash Drawer </th>
			</tr>
			<tr>
				<td>Starting Cash</td>
				<td>{{ dreg.startcash}}</td>
			</tr>
			<tr>
				<td>Ending Cash</td>
				<td> {{dreg.endcash}}</td>
			</tr>
			<tr>
				<th colspan="2">Expected Deposit</th>
			</tr>
			<tr>
				<td>Sales Gross</td>
				<td> {{dreg.salestotal - dreg.taxtotal}}</td>
			</tr>
			<tr>
				<td>Sales Tax</td>
				<td> {{dreg.taxtotal}}</td>
			</tr>
			<tr>
				<td>Sales Net</td>
				<td> {{dreg.salestotal}}</td>
			</tr>
			<tr>
				<td>Drawer Cash Change</td>
				<td>{{dreg.startcash - dreg.endcash}}</td>
			</tr>
			<ng-container *ngFor="let payment of dreg.payments; let i = index">
				<tr *ngIf="dreg.payments[i].cash==0 && dreg.payments[i].total != 0">
					<td>Less {{ payment.name }}</td>
					<td>{{dreg.payments[i].total}}</td>
				</tr>
			</ng-container>
			<tr>
				<td>Pre-expense Subtotal</td>
				<td>{{dreg.calctotal}}</td>
			</tr>
			<tr>
				<th colspan="2">Expenses</th>
			</tr>
			<tr *ngFor="let expense of dreg.expenses; let i = index">
				<td>{{dreg.expenses[i].name}}</td>
				<td>
					{{dreg.expenses[i].total}}
				</td>
			</tr>
			<tr>
				<td>Expected Deposit</td>
				<td>{{dreg.calcdeposit}}</td>
			</tr>
			<tr>
				<td>Actual Deposit</td>
				<td>{{dreg.deposit}}</td>
			</tr>
			<tr>
				<td>Over / Short</td>
				<td>{{dreg.overshort}}</td>
			</tr>
			<tr>
				<th colspan="2"> Cash Drawer Breakdown </th>
			</tr>
			<tr *ngFor="let cd of dreg.cashdrawers; let i = index">
				<td colspan="2">
					<table>
						<tr>
							<th colspan="2">Cash Drawer # {{i + 1}} ({{cd.total}})</th>
						</tr>
						<tr>
							<td> $ 100.00</td>
							<td>
								{{ cd.hun }}
							</td>
						</tr>
						<tr>
							<td> $ 50.00</td>
							<td>
								{{ cd.fif }}
							</td>
						</tr>
						<tr>
							<td> $ 20.00</td>
							<td>
								{{ cd.twen }}
							</td>
						</tr>
						<tr>
							<td> $ 10.00</td>
							<td>
								{{ cd.ten }}
							</td>
						</tr>
						<tr>
							<td> $ 5.00</td>
							<td>
								{{ cd.five }}
							</td>
						</tr>
						<tr>
							<td> $ 1.00</td>
							<td>
								{{ cd.one }}
							</td>
						</tr>
						<tr>
							<td> $ 0.25</td>
							<td>
								{{ cd.q }}
							</td>
						</tr>
						<tr>
							<td> $ 0.10</td>
							<td>
								{{ cd.d }}
							</td>
						</tr>
						<tr>
							<td> $ 0.05</td>
							<td>
								{{ cd.n }}
							</td>
						</tr>
						<tr>
							<td> $ 0.01</td>
							<td>
								{{ cd.p }}
							</td>
						</tr>
					</table>
				</td>
			</tr>
		</table>
	</div>
</div>