import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { GlobalsService } from '../../services/globals.service';
import { PaymentsService } from '../../services/payments.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
	selector: 'app-allocations',
	templateUrl: './allocations.component.html',
	styleUrls: ['./allocations.component.scss']
})
export class AllocationsComponent implements OnInit {
	@Input() transid: any = [];
	@Input() type: any = [];

	@ViewChild('paymentSearch') paymentSearchRef: ElementRef;

	trandata: any = [];
	displaydetails: any = [];
	allavail: any = false;
	allalloc: any = false;
	transactions: any = false;
	account: any = false;
	searchByCurrentPayments = new UntypedFormControl('');
	searchByCurrentPaymentsDate = new UntypedFormControl('');
	searchByCurrentInvoices = new UntypedFormControl('');
	searchByCurrentInvoicesDate = new UntypedFormControl('');

	openrec = new UntypedFormControl(true);


	constructor(public paymentsService: PaymentsService, public globalSearchService: GlobalSearchService, private modalService: NgbModal, private globalsService: GlobalsService) {}

	ngOnInit(): void {
		const data = { transid: this.transid, type: this.type };

		this.globalsService.getAllocationData(data).subscribe((results: any) => {
			this.trandata = results;
			this.trandata.balance = Math.abs(results.balance);
			this.allavail = results.available;
			this.allalloc = results.allocto;
			const element = document.getElementById('allocdiv')
			element.scrollIntoView({ behavior: 'smooth', block: 'start' });

		});


		this.openrec.valueChanges.subscribe(newValue => {
			if(!newValue) {
				this.getAllDocuments();
			} else {
				this.getOpenDocuments();
			}
		})


	}

	addAllocation(tran: any, id: any) {

		const amt = (document.getElementById('amt_' + tran.id) as HTMLInputElement).value;
		tran.amt = amt;

		if (tran.amt < 0) {
			alert('Cannot allocate negative amount');
			return false;
		}
		if (tran.amt == 0) {
			tran.amt = Math.abs(tran.balance);
		}
		if (amt == '') {
			tran.amt = Math.abs(tran.balance);
		}

		if (tran.amt > Math.abs(id.balance)) {
			tran.amt = Math.abs(id.balance);
		}

		const data = { transno: id.transno, type: id.type, allocid: tran.id, amt: tran.amt };
		if(this.type < 20) {
			this.paymentsService.addAllocation(data).subscribe((results: any) => {
				this.trandata = results;
				this.trandata.balance = Math.abs(results.balance);
			});
		} else {
			this.globalsService.addAllocation(data).subscribe((results: any) => {
				this.trandata = results;
				this.trandata.balance = Math.abs(results.balance);
			});
		}
	}

	removeAllocation(tran: any, id: any) {
		const data = { transno: id.transno, fromtransno: tran.transno, type: id.type, allocid: tran.id };
		this.globalsService.removeAllocation(data).subscribe((results: any) => {
			this.trandata = results;
			this.trandata.balance = Math.abs(results.balance);
		});
	}

	allocate(transaction: any) {

		this.transid = transaction.id;
		const data = { transno: transaction.transno, type: transaction.type };


		this.globalsService.getAllocationData(data).subscribe((results: any) => {
			this.trandata = results;
			this.trandata.balance = Math.abs(this.trandata.balance);
		});

	}

	currentInvoiceSearchDate(event: any) {
		if (event != '') {
			this.trandata.available = this.globalSearchService.filterItem(this.allavail, event, 'trandate');
		} else {
			this.trandata.available = this.allavail
		}
	}

	currentInvoiceSearch(event: any) {
		if (event != '') {
			this.trandata.available = this.globalSearchService.filterItem(this.allavail, event, 'trandate,total,transno');
		} else {
			this.trandata.available = this.allavail
		}
	}

	currentPaymentsSearchDate(event: any) {
		if (event != '') {
			this.trandata.allocto = this.globalSearchService.filterItem(this.allalloc, event, 'trandate');
		} else {
			this.trandata.allocto = this.allalloc
		}
	}

	currentPaymentsSearch(event: any) {
		if (event != '') {
			this.trandata.allocto = this.globalSearchService.filterItem(this.allalloc, event, 'trandate,total,transno');
		} else {
			this.trandata.allocto = this.allalloc
		}
	}

	openDocument(transaction: any, content: any, display: string) {

		switch (transaction.type) {
		case '11':
			this.globalsService.getCreditNote(transaction.id, display).subscribe((result: any) => {
				this.displaydetails = result;
				this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
			})
			break;
		case '10':
			this.globalsService.getInvoice(transaction.id, display).subscribe((result: any) => {
				this.displaydetails = result;
				this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
			})
			break;
		}
	}

	dealllocate() {
		const proceed = confirm('Remove All Allocations?');
		if(proceed) {
			const data = { id : this.transid }
			if(this.type < 20) {
				this.paymentsService.dealllocateDocument(data).subscribe((results: any) => {
					this.ngOnInit();
				});
			} else {
				this.globalsService.dealllocateDocument(data).subscribe((results: any) => {
					this.ngOnInit();
				});
			}
		}
	}

	autoAlloc() {

		const proceed = confirm('Auto Allocate?');
		if(proceed) {
			const data = { id : this.transid }
			this.paymentsService.autoAllocDocument(data).subscribe((results: any) => {
				this.ngOnInit();
			});
		}
	}

	changeTrans(id: any) {
		this.transid = id;
		//this.modalService.dismissAll();
		this.ngOnInit();
	}

	getOpenDocuments() {
		if(this.type < 20) {
			this.paymentsService.getCustomerAccountReceivables(this.trandata.debtorno, this.trandata.branchcode).subscribe((results: any) => {
				this.account = results
				this.transactions = results.payments;
			});
		} else {
			this.paymentsService.getVendorAccountPayable(this.trandata.supplierno).subscribe((results: any) => {
				this.account = results
				this.transactions = results.payments;
			});
		}
	}

	getAllDocuments() {
		if(this.type < 20) {
			this.paymentsService.getCustomerAllPaymentCredits(this.trandata.debtorno, this.trandata.branchcode).subscribe((results: any) => {
				this.account = results
				this.transactions = results.payments;
			});
		} else {
		}
	}

	changeDocumentModal() {
		if(this.openrec.value) {
			this.getOpenDocuments();
		} else {
			this.getAllDocuments();
		}

		this.modalService.open(this.paymentSearchRef, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {}, (reason) => {

		});

	}

}
