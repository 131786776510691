import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { JsonPipe, ViewportScroller } from '@angular/common';

import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';

import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { OmsService } from '../../services/oms.service';
import { DispatchService } from '../../services/dispatch.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { parseJSON } from 'date-fns';
declare const google: any;

@Component({
	selector: 'app-dispatches-load-sheet-only',
	templateUrl: './dispatches-load-sheet-only.component.html',
	styleUrls: ['./dispatches-load-sheet-only.component.scss']
})
export class DispatchesLoadSheetOnlyComponent implements OnInit {
	@ViewChild('orderdetails') orderdetailsref: ElementRef;

	@Input() selectedId: any = false;
	transfertruck = new UntypedFormControl('')
	selectedorder: any = false;
	loadingrun: any = false;
	loadingdata: any = false;
	active_dispatches: any = false;
	active_allpoints: any = false;
	filtereditems: any = [];
	listData: any = [];

	dispatches: any = [];
	allpoints: any = [];

	total_capacity = 0;
	total_order_volume = 0;
	total_items = 0;
	trucks: any = [];
	alltrucks: any = [];
	user: any = [];
	constructor(private fb: UntypedFormBuilder, private dispatchService: DispatchService, public omsService: OmsService, public location: Location, public ordersService: OrdersService, private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, public formBuilder: UntypedFormBuilder, public inventoryService: InventoryService, private modalService: NgbModal) {

		this.globalSearchService.user.subscribe((result) => {
			this.user = result;
			this.omsService.setupSocketConnection(this.user.user.userid);
		});
	}

	ngOnInit(): void {



		this.dispatchService.getTrucks().subscribe((result: any) => {

			if (result.length == 1) {
				this.selectedId = result[0];
				this.total_capacity = result[0].capacity
			} else {
				this.total_capacity = result[0].capacity
			}

			this.trucks = result;
			this.alltrucks = result
			this.loadData();
		});


		this.omsService.subscribeToCards((err, data) => {

			switch (data.type) {
			case 'pick':
				this.loadData();
				break;
			case 'buildingroute':
				this.loadData();
				break;
			case 'dispatch':
				this.loadData();
				break;
			}
		})
	}


	cancel(dispatch: any) {
		this.dispatchService.cancelDispatch(dispatch).subscribe(results => {
			this.loadData();
			this.modalService.dismissAll();
		})
	}

	deliverRun(truck: any) {
		const proceed = confirm('Mark All As Delivered?');
		if (proceed) {
			this.dispatchService.deliverRun(truck).subscribe(results => {
				this.loadData();
				this.modalService.dismissAll();
			})
		}
	}

	clearRun(truck: any) {
		const proceed = confirm('Clear This Run?');
		if (proceed) {
			this.dispatchService.clearRun(truck).subscribe(results => {
				this.loadData();
				this.modalService.dismissAll();
			})
		}
	}

	dropAtHome(dispatch: any) {
		this.dispatchService.returnDispatch(dispatch).subscribe(results => {
			this.loadData();
			this.modalService.dismissAll();
		})
	}

	delivered(dispatch: any) {
		this.dispatchService.deliverDispatch(dispatch).subscribe(results => {
			this.loadData();
			this.modalService.dismissAll();
		})
	}

	altLocation() {
		if (this.transfertruck.value) {
			this.dispatchService.transferDispatch(this.selectedorder.did, this.transfertruck.value).subscribe(results => {
				this.loadData();
				this.modalService.dismissAll();
			})
		}
	}

	viewOrder(order: any) {
		this.selectedorder = order
		this.modalService.open(this.orderdetailsref, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false }).result.then((result) => {}, (reason) => {
			this.selectedorder = false;
		});
	}

	loadData() {
		this.getTruckData();
		if (this.loadingdata) {
			this.loadingdata.unsubscribe();
		}

		this.loadingdata = this.dispatchService.getActivePicked(this.selectedId).subscribe((results: any) => {
			this.dispatches = results;
			this.allpoints = results;
			if (results) {
				this.total_order_volume = results.reduce(function(accumulator, i) {
					return accumulator + i.ordercapacity;
				}, 0);

				this.total_items = results.reduce(function(accumulator, i) {
					return accumulator + i.item_count;
				}, 0);
			}
		});

		if (this.loadingrun) {
			this.loadingrun.unsubscribe();
		}

		this.loadingrun = this.dispatchService.getActiveRun(this.selectedId).subscribe((results: any) => {
			this.active_dispatches = results.dispatched;
			this.active_allpoints = results.allpoints;
		});
	}

	loadRun(event: any) {


		let loadvar = '';


		let truckname = 'All';
		if (event.id) {
			loadvar = event.id;
			truckname = event.name
		} else{
			loadvar = this.selectedId;
		}

		if (this.loadingdata) {
			this.loadingdata.unsubscribe();
		}


		this.loadingdata = this.dispatchService.getActivePicked(loadvar,'').subscribe((results: any) => {
			this.dispatches = results;
			this.allpoints = results;

			if (results) {
				this.total_order_volume = results.reduce(function(accumulator, i) {
					return accumulator + i.ordercapacity;
				}, 0);


				this.total_items = results.reduce(function(accumulator, i) {
					return accumulator + i.item_count;
				}, 0);
			}
		});

		if (this.loadingrun) {
			this.loadingrun.unsubscribe();
		}

		this.loadingrun = this.dispatchService.getActiveRun(loadvar).subscribe((results: any) => {
			this.active_dispatches = results.dispatched;
			this.active_allpoints = results.allpoints;
		});

	}

	getTruckData() {
		this.dispatchService.getTrucks().subscribe((result: any) => {
			if (result.length == 1) {
				const truck = result.filter(t => {
					return t.id == result[0].id
				})[0];
				this.total_capacity = truck.capacity
				this.loadRun(truck);
			} else {
				this.total_capacity = result[0].capacity
				this.loadRun('')
			}

			this.trucks = result;
			this.alltrucks = result
		});
	}


	identify(index, item) {
		return item.did;
	}
}
