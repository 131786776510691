<div class="main-content">
<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			<div class="card tp-box">
				<div class="card-header card-header-{{config.color}}-5">
					<div class="row">
						<div class="col-10">
							<h4 class="card-title">
								<i class="material-icons" (click)="back()">arrow_left</i> Credit Entry
								<span *ngIf="customer_data">
								{{ customer_data.debtorno }}.{{ customer_data.branchcode }} {{ customer_data.name }}
								</span>
							</h4>
						</div>
					</div>
				</div>
				<div class="card-body" *ngIf="customer_data">

					<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent" [(selectedIndex)]="selectedindex">

						<mat-tab label="RMA">
							<ng-template matTabContent>
							<app-shared-credit-entry
									(reloadCustomer)="updateCustomer($event)"
									[original_order]="original_order"
									[customer]="customer_data"  [cart_items]="cart_items" [flip]="'inactive'" [type]="'11'"></app-shared-credit-entry>
							</ng-template>
						</mat-tab>

						<mat-tab label="RMA an Invoice">
							<ng-template matTabContent>
								<app-credit-customer-orders [(customerdata)]="customer_data" (cart_updated)="reloadOrder($event)"> </app-credit-customer-orders>
							</ng-template>
						</mat-tab>
					</mat-tab-group>
				</div>
			</div>
		</div>
	</div>
</div>
</div>
