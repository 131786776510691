<div class="table">
	<table class="table">
		<tr>
			<th>Vendor</th>
			<th>balance</th>
		</tr>
		<tr *ngFor="let v of data">
			<td>{{ v.supplierid }}</td>
			<td>{{ v.balance | currency }}</td>
		</tr>
	</table>
</div>
