
<form class="">
	<button class="btn btn-primary btn-sm d-none d-lg-inline" (click)="exportExcel(results)">XLS</button>
	<div class="input-group no-border">
		<input #searching id="searching" type="text" (input)="onInput($event)" class="form-control text-right" placeholder="Order Search..." />
		<button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
			<i class="material-icons">search</i>
			<div class="ripple-container"></div>
		</button>
	</div>
		<div class="results mt-2 mb-2" *ngIf="allresults.length">
		<div class="listitem row border-bottom pl-1 pr-1 ml-1 mr-1" *ngFor="let result of results; let i = index">
			<div class="col-6 text-left">
				<small><button class="btn btn-sm btn-rose" (click)="navTo(result.ORDER_NUMBER, type)"><i class="material-icons">visibility</i></button></small>
			</div>
			<div class="text-muted text-right col-6" *ngIf="result.snoozeactive">
				<small>
					<i class="material-icons ">notifications_active</i>
					<br>
					<span class="text-warning">
						SNOOZED UNTIL: {{ result.SNOOZEHIST[0].date_end  | date:'fullDate' | uppercase }}<br>
						({{ result.SNOOZEHIST[0].comments  | uppercase }})
					</span>
				</small>
			</div>

			<div class="col-12 col-md-8 pr-0">

				<b>ORDERED:</b>&nbsp;{{ result.DATE_ORDERED | dateAgo | uppercase }}<br>
				<b>LOCATION:</b>&nbsp;<span class="text-primary"> <b>{{ result.LOCATION }}</b></span><br>
				<b>ITEM:</b>&nbsp;<span class="text-primary"><b>{{ result.ITEM_NUMBER }}</b></span><br>
				<b>DESC:</b>&nbsp;{{ result.DESCRIPTION }}<br>
				<b>SHIP:</b>&nbsp;{{ result.SHIP_VIA }}
			</div>
			<div class="col-12 col-md-4 ">


				<b>WANTED:</b>&nbsp;{{ result.DATE_WANTED | dateAgo | uppercase }}<br>
				<b>ORDER# :</b>&nbsp;<span class="text-primary">{{ result.ORDER_NUMBER }}</span><br>
				<b>ORDERED/QOH:</b>&nbsp;
				<span [ngClass]="+result.PICK <= +result.QOH ? 'text-success': 'text-danger'"> <b> {{ result.PICK }}</b></span> / <b>{{ result.QOH }}</b><br>
				<b>CUST:</b>&nbsp;({{ result.CUST_NUMBER }}) {{ result.CUST_NAME }}
				<br>
				<span *ngIf="result.CUSTOMERINFO && result.CUSTOMERINFO.PHONE">
					TEL: <a href="tel://{{ result.CUSTOMERINFO.PHONE }}">{{ result.CUSTOMERINFO.PHONE }} </a>
				</span>
			</div>
		</div>
	</div>
	<div *ngIf="allresults.length <= 0 ">
		<span class="alert alert-warning">No Results</span>
	</div>
</form>

<!-- data tables not reloading moving to self implementation
<div class="table d-none ">
	<div class="table table-responsive" >
		<table *ngIf="dtRendered" datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-no-bordered table-hover dataTable dtr-inline " id="excel-table" >
			<tr *ngIf="!dtRendered">
				<td class="text-center">
					<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
</svg>
				</td>
			</tr>
			<tr *ngIf="dtRendered && isempty">
				<td class="text-center">
					No Results
				</td>
			</tr>
		</table>
	</div>
</div>
-->
<div id="excel-table" class="table d-none">
	<table class="table tables-sm d-none">
		<tr>
			<th *ngFor="let head of header">{{ head.title}}</th>
		</tr>
		<tr *ngFor="let result of results">
			<td *ngFor="let head of header">{{ result[head.title] }}</td>
		</tr>
	</table>
</div>
