import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { GlobalSearchService } from '../services/globalsearchservice.service';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';

@Injectable({
	providedIn: 'root'
})

export class DeelsheetsService {
	key = 'UET6DFMPAXW7BKCB';
	config: any;
	user: any = [];
	package: any = '';

	constructor(private http: HttpClient, private _configSvc: RuntimeConfigLoaderService, public globalSearchService: GlobalSearchService ) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})

		this.globalSearchService.user.subscribe((result) => {
			this.user = result;
		});

	}

	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};

	public getPdfContent(slug: any) {
		var method = 'product/deal/pdfcontent&pdf=' + slug;
		return this.http.get(this.setEndPoint(method));
	}

	public removeFile(formdata: any) {
		var method = 'product/deal/removeslug';
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}

	public getSheets() {
		var method = 'product/deal/dealsheets';
		return this.http.get(this.setEndPoint(method));

		//return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
		//  catchError(this.handleError(method, data))
		//);
	}

	public saveSheets() {
		var method = 'dealsheet/brand/getdeels';
		return this.http.get(this.setEndPoint(method));
	}

	public uploadFile(formdata: any) {
		var method = 'product/deal/filedrop';
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}

	public uploadUrl(formdata: any) {
		var method = 'product/deal/urlsave';
		return this.http.post(this.setEndPoint(method), formdata, this.httpOptions).pipe(
			catchError(this.handleError(method, formdata))
		);
	}



	private setEndPoint(method: any) {
		return this.getApi() + method + this.getApiKey()
	}

	private getApi() {
		return this.config.apiServer.website;
	}

	private getApiKey() {
		return '&key=' + this.config.apiCredentials.clientId + '&userid=' + this.user.user.userid + this.getPackage();
	}

	private getPackage() {
		const p = this.globalSearchService.getSessionItem('package');
		if(p) {
			return '&package='+p;
		} else {
			return '';
		}
	}

	public getKey() {
		return '&apikey=' + this.key;
	}

	private handleError<T>(operation = 'operation', result?: T) {

		return (error: any): Observable<T> => {

			if (error instanceof HttpErrorResponse && error.status === 401) {
				window.location.href = '/#/auth/logout';
			}

			return error;
		};
	}

}
