import { Directive, Renderer2, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Subscription } from 'rxjs';

@Directive({
	selector: '[appSelectAll]',
})
export class MultiselectDirective implements OnDestroy {
	private openedChangeSubscription: Subscription;

	constructor(private el: ElementRef, private select: MatSelect, private renderer: Renderer2) {
		this.openedChangeSubscription = this.select.openedChange.subscribe(isOpen => {
			if (isOpen) {
				const div = this.renderer.createElement('div');
				this.renderer.addClass(div, 'select-all-container');

				const button = this.renderer.createElement('button');
				this.renderer.addClass(button, 'select-all-button');
				const buttonText = this.renderer.createText('Select All/None');

				this.renderer.appendChild(button, buttonText);
				this.renderer.appendChild(div, button);

				const panelParentNode = this.select.panel.nativeElement.parentNode;
				if (panelParentNode) {
					this.renderer.appendChild(panelParentNode, div);
				} else {
					console.error('Panel parent node not found. Unable to append the select-all button.');
				}

				// Add click event listener
				this.renderer.listen(button, 'click', () => this.selectAllToggle());
			}
		});
	}

	selectAllToggle(): void {
		const currentValue = this.select.value;
		const allValues = this.select.options.map(option => option.value);
		this.select.value = currentValue.length === allValues.length ? [] : allValues;
	}

	ngOnDestroy(): void {
		if (this.openedChangeSubscription) {
			this.openedChangeSubscription.unsubscribe();
		}
	}

	ngAfterViewInit() {
		this.el.nativeElement.parentElement.removeAttribute('aria-owns');
		this.el.nativeElement.parentElement.removeAttribute('aria-labelledby');
	}
}
