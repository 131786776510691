<ng-template #editTruckEle let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Edit Truck</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-header text-center">
					<h4 class="card-title text-center"></h4>
				</div>
				<div class="card-body text-center">
					<form [formGroup]="truckForm">
						<div class="row">
							<div class="col-6">
								<mat-form-field appearance="fill">
									<mat-label>Name</mat-label>
									<input matInput formControlName="truck_name" class="text-right ">
								</mat-form-field>
							</div>
							<div class="col-6">
								<mat-form-field appearance="fill">
									<mat-label>Capacity</mat-label>
									<input matInput formControlName="capacity" class="text-right ">
								</mat-form-field>
							</div>
						</div>
						<div class="row">
							<div class="col-6 text-left">
								<button *ngIf="truck.truck_id" mat-raised-button class="mt-2 mb-2" color="warn" (click)="removeTruck()">Remove</button>
							</div>
							<div class="col-6 text-right">
								<button mat-stroked-button color="success" aria-label="" (click)="saveTruck()" class="mt-2">
									<mat-icon>save</mat-icon> Save
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="container-fluid">
<ng-container *ngIf="trucks">
	<div class="row">
		<div clkass="col-12 text-right">
			<button mat-raised-button (click)="addTruck()" class="mt-2 mb-2">Add Truck</button>
		</div>
	</div>
	<div class="table table-sm">
		<table class="table table-sm table-fixed">
			<tr>
				<th> ID </th>
				<th> Name </th>
				<th> Capacity </th>
				<th></th>
			</tr>
			<tr *ngFor="let t of trucks">
				<td>{{ t.truck_id }}</td>
				<td>{{ t.truck_name }}</td>
				<td>{{ t.capacity }}</td>
				<td><button mat-icon-button class="accent" (click)="editTruck(t)">
						<mat-icon>edit</mat-icon>
					</button>
			</tr>
		</table>
	</div>
</ng-container>
</div>
