import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';


import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { PurchasingService } from '../../services/purchasing.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { OmsService } from '../../services/oms.service';
import { GlobalsService } from '../../services/globals.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { ItemList } from '../../classes/items';
import { OrderTypes, OrderHeader } from '../../classes/orders';

import { Location } from '@angular/common'

@Component({
	selector: 'app-order-openlist',
	templateUrl: './order-openlist.component.html',
	styleUrls: ['./order-openlist.component.scss']
})

export class OrderOpenlistComponent implements OnInit {

	@ViewChild('stockidsearch') stockidSearchField: ElementRef;
	@ViewChild('purchaseDetails') purchaseDetailsRef: ElementRef;

	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	orderForm: UntypedFormGroup;
	CHAT_ROOM = 'OrderBoard';
	allorders: OrderHeader;
	picking: OrderTypes;
	forcesearch = true;
	filteredItems: any = [];
	openorders: any;
	headercolumns: string[] = [
		'transno',
		'status',
		'orderno',
		'debtorno',
		//'deliverto',
		//'transno',
		'haspo',
		'deliverydate',

	];
	orderupdates: any = [];
	openOrderData: any = [];
	config: any = [];
	orderdetails: any = [];
	searchInput = new UntypedFormControl('');
	searchInputItems = new UntypedFormControl('');
	//pdf: todo - make this part of config
	baselink = '';
	color: any = '';
	defaultlocation = new UntypedFormControl('');
	locations: any = [];
	itemsearch = false;
	itemsearchresults: any = false
	podetails: any = [];

	allcustomer = {
		customer: {
			debtorno: '*',
		}
	}
	user: any = false;
	constructor(private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, private ordersService: OrdersService, public router: Router, private location: Location, private inventoryService: InventoryService, private omsService: OmsService, private globalSearchService: GlobalSearchService, private globalsService: GlobalsService, private modalService: NgbModal, public purchasingService: PurchasingService) {
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.user.subscribe(r => {
			this.user = r;
		});
	}

	ngOnInit(): void {

		this.config = this.globalsService.getApiConfig()
		this.baselink = this.config.apiServer.pickingLink;


		this.omsService.subscribeToOpenOrders((err, data) => {
			this.orderupdates = data;
			this.openorders = new MatTableDataSource(data);
			this.openorders.sort = this.sort;
			this.openorders.paginator = this.paginator;
			this.globalSearchService.showNotification('New Order Added ', 'info', 'top', 'right');
		});


		this.globalSearchService.pickingOrderData.subscribe((results: OrderTypes) => {
			this.picking = results;
		});

		this.globalSearchService.locations.subscribe((results: any) => {
			this.locations = results;
			if (results && results[0]) {
				this.defaultlocation.setValue(results[0].loccode)
			}
		});


		this.route.params.subscribe(params => {
			const orderno = params['id'];
			if (orderno) {
				this.forcesearch = false;
			}
			// if (!orderno) {
			// 	this.forcesearch = true;
			// 	this.ordersService.getOrders().subscribe((orders: OrderHeader) => {
			// 		this.allorders = orders
			// 	});
			// }
		});
	}

	selectItemLookup(event: any) {

		this.router.navigate(['./orders/entry/' + event.header.debtorno + '/' + event.header.branchcode]);
	}

	back(): void {
		this.location.back()
	}

	loadPreOrder(event: any) {
		this.router.navigate(['./orders/entry/' + event.header.debtorno + '/' + event.header.branchcode]);
	}

	invoiceOrder(event: any) {
		this.router.navigate(['orders/invoice/' + event + '/dispatch']);
	}

	exportExcel() {
		this.globalSearchService.exportJsonToExcel(this.openOrderData, 'OpenOrders');
	}

}
