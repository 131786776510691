<div class="row text-center w-100 ml-auto mr-auto">
	<ngx-dropzone [multiple]="false" (change)="onSelect($event)">
		<ngx-dropzone-label>
			<div class="col-12">
				DROP FILE HERE OR CLICK TO UPLOAD
			</div>
		</ngx-dropzone-label>
		<ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)" (error)="onUploadError($event)" (success)="onUploadSuccess($event)">
			<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
		</ngx-dropzone-preview>
	</ngx-dropzone>
</div>
