<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-danger">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Customer Invoices</h4>
						<p class="card-category">Invoices for</p>
					</div>
					<div class="card-body">
						<div class="table table-striped table-no-bordered table-hover dataTable dtr-inline ">
							<table datatable [dtOptions]="dtOptions" class="table table-striped table-no-bordered table-hover dataTable dtr-inline " *ngIf="invoices">
								<thead>
									<tr>
										<th>Order #</th>
										<th>Principal</th>
										<th>Inv Date</th>
										<th>T.Inv Date</th>
									</tr>
								</thead>
								<tbody>
									<tr  *ngFor="let invoice of invoices; ">
										<td>{{ invoice.ORDER_NUMBER }}</td>
										<td>{{ invoice.CUST_NAME }}</td>
										<td><span [innerHTML]="invoice.DATE_ORDERED"></span></td>
										<td><span [innerHTML]="invoice.DATE_WANTED"></span></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>