import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';

import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { PrintService } from '../../services/print.service'
import { CustomerService } from '../../services/customer.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { PaymentsService } from '../../services/payments.service';

@Component({
	selector: 'app-receivables-byshipvia-report',
	templateUrl: './receivables-byshipvia-report.component.html',
	styleUrls: ['./receivables-byshipvia-report.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotate3d(0, 1, 0, 179deg)',
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotateY(179deg)'
			})),
			transition('active => inactive', animate('500ms ease-out')),
			transition('inactive => active', animate('500ms ease-in')),
			transition('inactive => orderlookup', animate('500ms ease-out')),
			transition('orderlookup => inactive', animate('500ms ease-in')),
		]),
		trigger('openClose', [
			state(':enter', style({ height: '*' })),
			state(':leave', style({ height: '0px' })),
			transition('false <=> true', animate(500))
		]),
		trigger('filterAnimation', [
			transition(':enter, * => 0, * => -1', []),
			transition(':increment', [
				query(':enter', [
					style({ opacity: 0, width: 0 }),
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 1, width: '*' })),
					]),
				], { optional: true })
			]),
			transition(':decrement', [
				query(':leave', [
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 0, width: 0 })),
					]),
				])
			]),
		]),
		trigger('grow', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: '0', width: '0' }),
				animate(200, style({ height: '*', width: '*' })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate(100, style({ height: 0, width: 0 })),
				animate('100ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class ReceivablesByshipviaReportComponent {
	@Input() flip = 'inactive';
	@Output() viewData = new EventEmitter < any > ();
	viewing: any = false;
	@ViewChild('print_table') printtable: ElementRef;
	data: any = false;
	title = 'Open Invoices By Term';
	jounral_sub: any = false;
	filename = 'Open_Invoices_By_Term';
	config: any = false;
	color = 'blue';
	terms: any = [];
	termscodes = new UntypedFormControl('COD');
	sortDirection = 1; // 1 for ascending, -1 for descending
	currentSortColumn = '';
	isLoading = true;
	transactions: any = [];
	sending: any = false;

	constructor(private printService:PrintService,private paymentsService: PaymentsService, private globalsService: GlobalsService, private globalSearchService: GlobalSearchService, private customerService: CustomerService) {}

	ngOnInit(): void {
		this.color = this.globalSearchService.getColor();

		this.globalSearchService.configsubscription.subscribe(s => {
			this.config = s;
		});

		this.globalsService.getTerms().subscribe(details => {
			this.terms = details;
		});
		this.loadData();
	}

	sortData(property: string) {
		if (this.currentSortColumn === property) {
			this.sortDirection = -this.sortDirection; // Toggle direction if sorting the same column
		} else {
			this.currentSortColumn = property;
			this.sortDirection = 1; // Reset to ascending for a new column
		}

		this.data.sort((a, b) => {
			let valueA = a[property];
			let valueB = b[property];

			if (typeof valueA === 'string') {
				valueA = valueA.toLowerCase();
				valueB = valueB.toLowerCase();
			}

			if (valueA < valueB) {
				return -1 * this.sortDirection;
			} else if (valueA > valueB) {
				return 1 * this.sortDirection;
			} else {
				return 0;
			}
		});
	}
	changeTerm() {
		this.loadData();
	}
	loadData() {
		this.isLoading = true; // Start loading
		const data = this.termscodes.value;
		this.paymentsService.getCustomerAccountReceivablesByTerm(data).subscribe(
			(results: any) => {
				this.data = results.transactions;
				this.isLoading = false;
			},
			(error) => {
				this.isLoading = false;
			}
		);
	}

	exportPdf() {
		this.sending = true;
		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const today = new Date();
		const data = {
			content: encoded,
			filename: this.filename ,
			title: this.title + ' ' + this.termscodes.value,
			subtitle:' created '+today.toLocaleString()
		}

		this.printService.pdf(data).subscribe( (result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.sending = false;

		});
	}

	exportXls() {
		this.sending = true;
		const today = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename,
			title: this.title  + ' ' + this.termscodes.value,
			subtitle:' created '+ today.toLocaleString()
		}

		this.printService.xls(data).subscribe( (result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.sending = false;
		});
	}

	toggleFlip(data: any, title: string) {

		if (!data) {
			this.flip = 'inactive';
		} else {
			this.flip = (this.flip == 'active') ? 'inactive' : 'active';
		}

		if (this.flip == 'active') {
			this.viewing = data;
			this.title = title
			const dashboard_data = { data: data, title: this.title }
			this.viewData.emit(dashboard_data);
		} else {
			this.viewData.emit(false);
		}
	}
}