
<ng-template #transactioMove let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Move Transaction</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
		<div class="modal-body">
			<div class="mb-3">
				<app-transaction-move>
				</app-transaction-move>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #details let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">{{ displaydetails.title }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="displaydetails">
			<h4></h4>
			<ng-container *ngIf="displaydetails.type == 'html'">
				<div [innerHTML]="displaydetails.display">
				</div>
			</ng-container>
			<ng-container *ngIf="displaydetails.type == 'pdf'">
				<ngx-extended-pdf-viewer [base64Src]="displaydetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true" [filenameForDownload]="displaydetails.title"></ngx-extended-pdf-viewer>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #posRec let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Receipt </h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body" *ngIf="rechtml">
		<div class="row justify-content-end">
		  <div class="col-auto">
			<button mat-raised-button color="primary" (click)="sendPrint()">
			  <mat-icon>print</mat-icon> PRINT
			</button>
		  </div>
		</div>
		<div [innerHTML]="rechtml">
		</div>
	</div>
</ng-template>
<ng-template #emailData let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Confirm Email Address</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3">
			<mat-form-field appearance="outline">
				<mat-label>Email Address</mat-label>
				<input matInput [(ngModel)]="sendemail" />
			</mat-form-field>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-raised-button color="primary" (click)="pushEmail()">
			<mat-icon>email</mat-icon> Send
		</button>
	</div>
</ng-template>
<ng-template #reversal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title"></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<app-payment-reversal [input]="reversetran" (output)="reversalUpdate($event)"></app-payment-reversal>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #adjustment let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Adjusting
			<ng-container *ngIf="adjustmentTransaction.type == 12">{{adjustmentTransaction.id}}</ng-container>
			<ng-container *ngIf="adjustmentTransaction.type == 10">{{adjustmentTransaction.transno}}</ng-container>
		</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body" *ngIf="adjustmentTransaction">
		<app-adjustment [transaction]="adjustmentTransaction" (adjusted)="dismissAdjustment()"></app-adjustment>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>


<app-customer-aging *ngIf="config.env.client != 'aaprod' || (config.env.client == 'aaprod' && !user.user.issalesman)"
[aged]="aged" class="w-100" (columnzero)="updateTransactions()"  (columnone)="filterAgeOne()"  (columntwo)="filterAgeOne()" (columnthree)="filterAgeTwo()" (columnfour)="filterAgeThree()"> </app-customer-aging>

<div class="container-fluid tp-box mwidth" [@flipState]="flip">

	<div class="tp-box__side tp-box__back" [ngClass]="flip == 'inactive' ? '' : ''">
		<ng-container *ngIf="transid">
			<div class="text-left mt-3">
				<button mat-raised-button color="primary" (click)="flip = 'inactive';transid=false;updateTransactions()">Back</button>
			</div>
			<app-allocations [transid]="transid" [type]="transidtype"></app-allocations>
		</ng-container>
	</div>
	<div id="frontside" class="row tp-box__side tp-box__front" [ngClass]="flip == 'active' ? '' : ''">

		<nav class="navbar navbar-expand-lg navbar-light bg-light w-100 no-shadow" @filterAnimation>
			<div class="navbar-nav w-100 ml-auto mr-auto">
				<div class="nav-item nav-link mr-md-auto ml-md-0">
					<mat-slide-toggle class="pt-0 mt-0" [formControl]="opentrans">
						<mat-label>Show open only</mat-label>
					</mat-slide-toggle>
				</div>

				<div class="nav-item ml-md-auto mr-md-0">
					<mat-form-field appearance="outline" *ngIf="type_filters">
						<mat-label>Type</mat-label>
						<mat-select [(ngModel)]="type_selected" multiple>
							<mat-option *ngFor="let v of type_filters" [value]="v.value">{{v.name}}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="navbar-nav ml-md-auto">
					<ng-container *ngIf="config.env.client !== 'aaprod' || (config.env.client === 'aaprod' && !user.user.issalesman)">
						<div class="nav-item">
							<mat-form-field appearance="outline" class="w-100">
								<mat-label class="text-right">Search By Items/Order</mat-label>
								<input #kewordsRef tabindex="0" matInput value="" class="w-100" [formControl]="keywords" class="text-right" (input)="searchItemTransactions($event.target.value)" (keyup.enter)="searchItemTransactions($event.target.value)">
								<mat-progress-bar mode="indeterminate" color="accent" *ngIf="searching"></mat-progress-bar>
								<span matPrefix *ngIf="searching" class="ml-auto mr-auto">
									<svg class="spinner" width="15px" height="15px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
								<mat-hint>Search Item/Invoice</mat-hint>
							</mat-form-field>
						</div>

						<div class="nav-item ml-1">
							<mat-form-field appearance="outline" class="w-100">
								<mat-label class="text-right">Search By Invoice#/Document#/$</mat-label>
								<input tabindex="0" matInput value="" class="w-100" [formControl]="transno" class="text-right" (input)="searchTransaction($event.target.value)" (keydown.enter)="searchTransaction($event.target.value)">
								<mat-progress-bar mode="indeterminate" color="accent" *ngIf="searching"></mat-progress-bar>
								<span matPrefix *ngIf="searching" class="ml-auto mr-auto">
									<svg class="spinner" width="15px" height="15px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
								<mat-hint>Search Invoice#/Document#/$</mat-hint>
							</mat-form-field>
						</div>
					</ng-container>

					<ng-container *ngIf="config.env.client === 'aaprod' && user.user.issalesman">
						<mat-form-field appearance="outline" class="w-100">
							<mat-label class="text-right">Search By Invoice#/Document#/$</mat-label>
							<input tabindex="0" matInput value="" class="w-100" [formControl]="transno" class="text-right" (input)="filterTransaction($event.target.value)" (keydown.enter)="filterTransaction($event.target.value)">
							<span matPrefix *ngIf="searching" class="ml-auto mr-auto">
								<svg class="spinner" width="15px" height="15px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
							</span>
							<mat-hint>Search Invoice#/Document#/$</mat-hint>
						</mat-form-field>
					</ng-container>

					<div class="nav-item ml-1">
						<form class="form-inline my-2 my-lg-0">
							<mat-form-field appearance="outline">
								<mat-label>Transaction Dates</mat-label>
								<mat-date-range-input [rangePicker]="picker" (keyup.enter)="updateTransactions();">
									<input matStartDate [formControl]="fromdateCtrl" placeholder="Start date">
									<input matEndDate [formControl]="todateCtrl" placeholder="End date">
								</mat-date-range-input>
								<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
								<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
								<mat-date-range-picker #picker></mat-date-range-picker>
							</mat-form-field>
						</form>
					</div>
				</div>
				<div class="nav-item nav-link mt-0 pt-0 ml-auto mr-0">
					<button mat-raised-button color="accent" class="ml-2" (click)="age = '';updateTransactions()">
						<mat-icon *ngIf="!sending">refresh</mat-icon>
						<span *ngIf="sending">
							<svg class="spinner" width="25px" height="25px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg>
						</span>
					</button>
				</div>
			</div>
		</nav>
		<div class="row w-100 m-0 p-0 text-right">
			<div class="col-4 text-left mt-3">
				Results: {{ transactions.length }}
			</div>
			<div class="col-8 text-right">
				<button class="btn btn-sm btn-danger" (click)="pdf()" [disabled]="!transactions.length"><i class="fa fa-file-pdf-o"></i></button>
				<button class="btn btn-sm btn-success" (click)="excel()" [disabled]="!transactions.length"><i class="fa fa-file-excel-o"></i></button>

				<a class="btn btn-sm btn-warn" href="{{ raPdfLink }}&debtorno={{customerdata.customer.debtorno}}" *ngIf="transactions && transactions.length && editsallowed.editar"><i class="fa fa-file-pdf-o"></i> RA PDF</a>
				<a class="btn btn-sm btn-success" href="{{ raXlsLink }}&debtorno={{customerdata.customer.debtorno}}" *ngIf="transactions && transactions.length && editsallowed.editar" ><i class="fa fa-file-excel-o"></i> RA XLS</a>

			</div>
		</div>
		<div class="table table-sm mt-2" #print_table >
			<div class="table mt-3"  *ngIf="displaymattable && customertrans" >
				<mat-paginator [pageSizeOptions]="[50, 100, 500]" showFirstLastButtons aria-label="select page">
				</mat-paginator>
				<table mat-table  class="table table-sm table-hover" [dataSource]="customertrans" matSort (matSortChange)="announceSortChange($event)">
					<ng-container matColumnDef="transno">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Document#</th>
						<td mat-cell *matCellDef="let tran">

							<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More" class="donotprint">
								<mat-icon >more_vert</mat-icon>
							</button>
							<mat-menu #menu="matMenu">
								<button mat-menu-item *ngIf="tran.type == '10' || tran.type == '11'" (click)="openDocument(tran,details,'html')">
									<mat-icon>visibility</mat-icon>
									<span>View HTML</span>
								</button>
								<button mat-menu-item *ngIf="tran.type == '10' || tran.type == '11'" (click)="openDocument(tran,details,'pdf')">
									<mat-icon>visibility</mat-icon>
									<span>View PDF</span>
								</button>
								<button mat-menu-item *ngIf="tran.type == '12'" (click)="sendEmail(tran)">
									<mat-icon>email</mat-icon>
									<span>Email Receipt</span>
								</button>
								<button mat-menu-item *ngIf="tran.type == '10' || tran.type == '11'" (click)="printReceipt(tran,details)">
									<mat-icon>visibility</mat-icon>
									<span>Print Receipt</span>
								</button>
								<button mat-menu-item *ngIf="tran.type == '10' || tran.type == '11'" (click)="sendEmail(tran)">
									<mat-icon>email</mat-icon>
									<span>Email</span>
								</button>
								<mat-divider></mat-divider>
								<button mat-menu-item (click)="loadAllocations(tran.id,tran.type)" *ngIf="(tran.type == '12' || tran.type == '11' && editsallowed.editar && !pos) ">
									<mat-icon>view_list</mat-icon>
									<span>Allocate</span>
								</button>
								<button mat-menu-item (click)="creditInvoice(tran)" *ngIf="tran.type == '10' && editsallowed.editar">
									<mat-icon>history</mat-icon>
									<span>RMA/Credit</span>
								</button>
								<button mat-menu-item (click)="viewPayments(tran,details)" *ngIf="tran.type == '10' ">
									<mat-icon>credit_card</mat-icon>
									<span>Payments</span>
								</button>
								<mat-divider></mat-divider>
								<button mat-menu-item (click)="openGl(tran, details)" *ngIf="editsallowed.editar && !pos">
									<mat-icon>view_list</mat-icon>
									<span>GL's</span>
								</button>
								<button mat-menu-item (click)="viewAllocations(tran, details)" *ngIf="(tran.type == '12' || tran.type == '11') ">
									<mat-icon>view_list</mat-icon>
									<span>Allocations</span>
								</button>
								<button mat-menu-item (click)="reversal(tran)" *ngIf="(tran.type == '12' && tran.totalamount < 0) && editsallowed.editar && !pos">
									<mat-icon>view_list</mat-icon>
									<span>Reversal</span>
								</button>
								<button mat-menu-item (click)="adjustModal(tran)" >
									<mat-icon>view_list</mat-icon>
									<span>Adjust</span>
								</button>

								<!-- <button mat-menu-item (click)="moveTrans(tran)">
									<mat-icon>clock</mat-icon>
									<span>Move Transaction</span>
								</button> -->

							</mat-menu>
							{{ tran.order_ }}
						</td>
					</ng-container>
					<ng-container matColumnDef="typename">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
						<td mat-cell *matCellDef="let tran">
							{{ tran.typename }}
						</td>
					</ng-container>
					<ng-container matColumnDef="trandate">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
						<td mat-cell *matCellDef="let tran">
							{{ tran.trandate }}
						</td>
					</ng-container>
					<ng-container matColumnDef="duedate">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Due</th>
						<td mat-cell *matCellDef="let tran">
							{{ tran.duedate }}
						</td>
					</ng-container>
					<ng-container matColumnDef="invtext">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Text</th>
						<td mat-cell *matCellDef="let tran">
							<div [innerHTML]="tran.invtext"></div> {{tran.customerref}}
						</td>
					</ng-container>
					<ng-container matColumnDef="enteredby">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>EnteredBy#</th>
						<td mat-cell *matCellDef="let tran">
							{{ tran.enteredby | uppercase }}
						</td>
					</ng-container>
					<ng-container matColumnDef="order_">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Document#</th>
						<td mat-cell *matCellDef="let tran">
							{{ tran.transno }}
						</td>
					</ng-container>
					<ng-container matColumnDef="totalamount">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Total</th>
						<td mat-cell *matCellDef="let tran"  >
							{{ tran.totalamount }}
						</td>
					</ng-container>
					<ng-container matColumnDef="allocated">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Allocated</th>
						<td mat-cell *matCellDef="let tran" >
							{{ tran.allocated }}
						</td>
					</ng-container>
					<ng-container matColumnDef="balance">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Balance</th>
						<td mat-cell *matCellDef="let tran"  >
							{{ tran.balance }}
						</td>
					</ng-container>
					<tr mat-header-row *matHeaderRowDef="headercolumns"></tr>
					<tr mat-row *matRowDef="let row; columns: headercolumns"></tr>
				</table>
				<mat-paginator [pageSizeOptions]="[50, 100, 500]" showFirstLastButtons aria-label="select page">
				</mat-paginator>
			</div>

			<div class="table mt-3" #print_basic *ngIf="!displaymattable && transactions && customerdata.customer">

				<div class="donotshow">
					<h4 class="">{{ customerdata.customer.debtorno }}.{{ customerdata.customer.branchcode }} {{ customerdata.customer.brname }}</h4>
					<h5> {{ today | date: 'short' }}</h5>

					<app-customer-aging [aged]="aged" class="w-100" (columnzero)="updateTransactions()"  (columnone)="filterAgeOne()"  (columntwo)="filterAgeOne()" (columnthree)="filterAgeTwo()" (columnfour)="filterAgeThree()" class="table-bordered"> </app-customer-aging>

				</div>

				<table #tablecontent class="table table-fixed table-hover" *ngIf="transactions.length">
					<thead>
					<tr>
						<th>Document#</th>
						<th>Type</th>
						<th>Date</th>
						<th>Due</th>
						<th>Terms</th>
						<th class="td-max">Reference</th>
						<th class="td-max">Check#</th>
						<th class="text-left">EnteredBy#</th>
						<th class="text-left">PickedBy#</th>
						<th class="text-left">PackedBy#</th>
						<th class="text-center">Invoice#</th>
						<th class="text-center">Settled</th>
						<th class="text-right ">Total</th>
						<th class="text-right ">Allocated</th>
						<th class="text-right ">Balance</th>
					</tr>
					</thead>
					<tbody>
					<tr *ngFor="let tran of transactions">
						<td>
							<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More" class="donotprint">
								<mat-icon >more_vert</mat-icon>
							</button>
							<mat-menu #menu="matMenu" >
								<button mat-menu-item *ngIf="tran.type == '10' || tran.type == '11'" (click)="openDocument(tran,details,'html')">
									<mat-icon>visibility</mat-icon>
									<span>View HTML</span>
								</button>
								<button mat-menu-item *ngIf="tran.type == '10' || tran.type == '11'" (click)="openDocument(tran,details,'pdf')">
									<mat-icon>visibility</mat-icon>
									<span>View PDF</span>
								</button>

								<ng-container *ngIf="tran.ccid != 'false' && tran.ccid">
									<button mat-menu-item *ngIf="tran.type == '12'" (click)="sendEmail(tran)">
										<mat-icon>email</mat-icon>
										<span>Email Receipt</span>
									</button>
									<a mat-menu-item *ngIf="(tran.type == '12')" href="{{receiptLink}}{{tran.ccid}}">
										<mat-icon>visibility</mat-icon>
										<span>Print Receipt</span>
									</a>
								</ng-container>
								<button mat-menu-item *ngIf="(tran.type == '10' || tran.type == '11') && config.env.package == 'beauty'" (click)="printReceipt(tran,details)">
									<mat-icon>visibility</mat-icon>
									<span>Print Receipt</span>
								</button>
								<button mat-menu-item *ngIf="tran.type == '10' || tran.type == '11'" (click)="sendEmail(tran)">
									<mat-icon>email</mat-icon>
									<span>Email</span>
								</button>
								<mat-divider></mat-divider>
								<button mat-menu-item (click)="loadAllocations(tran.id, tran.type)" *ngIf="(tran.type == '12' || tran.type == '11') && editsallowed.editar && !pos">
									<mat-icon>view_list</mat-icon>
									<span>Allocate</span>
								</button>
								<button mat-menu-item (click)="creditInvoice(tran)" *ngIf="tran.type == '10' && !pos && editsallowed.editar">
									<mat-icon>history</mat-icon>
									<span>RMA/Credit</span>
								</button>
								<button mat-menu-item (click)="viewPayments(tran,details)" *ngIf="tran.type == '10' ">
									<mat-icon>credit_card</mat-icon>
									<span>Payments</span>
								</button>
								<mat-divider></mat-divider>
								<button mat-menu-item (click)="openGl(tran, details)" *ngIf="editsallowed.editar && !pos">
									<mat-icon>view_list</mat-icon>
									<span>GL's</span>
								</button>
								<button mat-menu-item (click)="viewAllocations(tran, details)" *ngIf="(tran.type == '12' || tran.type == '11') ">
									<mat-icon>view_list</mat-icon>
									<span>Allocations</span>
								</button>
								<button mat-menu-item (click)="paymentReversal(tran)" *ngIf="(tran.type == '12' && tran.totalamount < 0) && editsallowed.editar && !pos">
									<mat-icon>view_list</mat-icon>
									<span>Reversal</span>
								</button>
								<button mat-menu-item (click)="adjustModal(tran)" >
									<mat-icon>view_list</mat-icon>
									<span>Adjust</span>
								</button>
								<!-- <button mat-menu-item (click)="moveTrans(tran)">
									<mat-icon>clock</mat-icon>
									<span>Move Transaction</span>
								</button> -->

							</mat-menu>
							{{tran.transno }}
						</td>
						<td>{{tran.typename }}</td>
						<td>{{tran.trandate }}</td>
						<td>{{tran.duedate }}</td>
						<td>{{tran.paymentterms }}</td>
						<td class="td-max">{{tran.invtext }} <span>{{tran.customerref}}</span> </td>
						<td class="td-max">{{tran.checknum }}</td>
						<td class="text-left">{{tran.enteredby | uppercase }}</td>
						<td class="text-left">{{tran.pickedby | uppercase }}</td>
						<td class="text-left">{{tran.packedby | uppercase }}</td>
						<td class="text-center">{{tran.order_ }}</td>
						<td class="text-center " [ngClass]="(tran.settled == 'Yes') ? 'text-success bolder': 'text-danger bolder'">{{ tran.settled }}</td>
						<td class="text-right nobreak minwidth">{{tran.totalamount | currency }}</td>
						<td class="text-right nobreak minwidth">{{tran.allocated | currency }}</td>
						<td class="text-right nobreak minwidth">{{tran.balance | currency }}</td>
					</tr>
					<tr class="">
						<td>TOTAL</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td class="text-right nobreak minwidth">{{ getTransactionTotals() | currency }}</td>
						<td class="text-right nobreak minwidth">{{ getAllocTotals() | currency }}</td>
						<td class="text-right nobreak minwidth bold">{{ getBalanceTotals() | currency }}</td>
					</tr>
					</tbody>
				</table>

				<div class="row nomargins">
					<div class="col-12 text-center" *ngIf="transactions && !transactions.length">
						<mat-divider></mat-divider>
						<h3>No Transactions</h3>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
