<ng-container *ngIf="locationForm">
	<div class="row">
		<div class="col-6 text-left">
			<button mat-raised-button (click)="cancel()" >Cancel</button>
		</div>
		<div class="col-6 text-right">
			<button mat-raised-button (click)="updateLocation()" color="accent">Save</button>
		</div>
	</div>
	<form [formGroup]="locationForm" class="text-dark">
		<div class="row">
			<div class="col-md-8">
				<div class="card card-profile">
					<div class="card-body">
						<h6 class="card-category text-gray">Location Details</h6>
						<div class="row">
							<div class="col-md-12">
								<mat-form-field class="full-width" appearance="outline">
									<input matInput required formControlName="loccode" placeholder="Loc Code" [value]="ld?.loccode">
								</mat-form-field>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<mat-form-field class="full-width" appearance="outline">
									<mat-label>Location Name</mat-label>
									<input matInput formControlName="locationname" placeholder="Location Name" [value]="ld?.locationname">
								</mat-form-field>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<mat-form-field class="full-width" appearance="outline">
									<input matInput placeholder="Address 1" formControlName="deladd1" [value]="ld?.deladd1">
								</mat-form-field>
							</div>
							<div class="col-md-6">
								<mat-form-field class="full-width" appearance="outline">
									<mat-label>Address 2</mat-label>
									<input matInput placeholder="Address 2" formControlName="deladd2" [value]="ld?.deladd2">
								</mat-form-field>
							</div>
						</div>
						<div class="row">
							<div class="col-md-4">
								<mat-form-field class="full-width" appearance="outline">
									<mat-label>City</mat-label>
									<input matInput placeholder="City" formControlName="deladd3" [value]="ld?.deladd3">
								</mat-form-field>
							</div>
							<div class="col-md-4">
								<mat-form-field appearance="outline">
									<mat-label>State/Province</mat-label>
									<mat-select formControlName="deladd4">
										<mat-option *ngFor="let zone of zones.zones" [value]="zone.code">
											{{zone.code}} {{zone.name}}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col-md-4">
								<mat-form-field class="full-width" appearance="outline">
									<input matInput placeholder="Postal" formControlName="deladd5" [value]="ld?.deladd5">
								</mat-form-field>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<mat-form-field appearance="outline" class="">
									<mat-label>Country</mat-label>
									<mat-select formControlName="deladd6" (selectionChange)="updateZones($event)">
										<mat-option *ngFor="let zone of zones.countries" [value]="zone.name">
											{{ zone.name }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>

							<div class="col-md-6">
								<mat-form-field appearance="outline" *ngIf="taxes">
									<mat-label>Tax Authority With Cert</mat-label>
									<mat-select formControlName="taxgroupid_cert">
										<mat-option *ngFor="let tax of taxes" [value]="tax.taxgroupid">
											{{tax.taxgroupdescription}}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>

							<div class="col-md-6">
								<mat-form-field appearance="outline" *ngIf="taxes">
									<mat-label>Tax Authority Without Cert</mat-label>
									<mat-select formControlName="taxgroupid">
										<mat-option *ngFor="let tax of taxes" [value]="tax.taxgroupid">
											{{tax.taxgroupdescription}}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>

						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card card-profile">
					<div class="card-body">
						<h6 class="card-category text-gray">Location Contact</h6>
						<div class="row">
							<div class="col-md-12">
								<mat-form-field class="full-width" appearance="outline">
									<input formControlName="contact" matInput placeholder="Contact Name" [value]="ld?.contact">
								</mat-form-field>
							</div>
							<div class="col-md-12">
								<mat-form-field class="full-width" appearance="outline">
									<input formControlName="email" matInput placeholder="Location Email" [value]="ld?.email">
								</mat-form-field>
							</div>
							<div class="col-md-12">
								<mat-form-field class="full-width" appearance="outline">
									<input formControlName="tel" matInput placeholder="Location Phone" [value]="ld?.tel">
								</mat-form-field>
							</div>
							<div class="col-md-12">
								<mat-form-field class="full-width" appearance="outline">
									<input formControlName="fax" matInput placeholder="Location Fax" [value]="ld?.fax">
								</mat-form-field>


								<angular-editor formControlName="receipt_message" [config]="editorConfig"></angular-editor>


							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</ng-container>
