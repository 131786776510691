<div class="main-content">
    <div class="card card-spacing">
        <div class="card-header card-header-{{color}}-6">
            TRUCK UNLOADING
        </div>
        <div class="card-body">

            <div class="col-12" [formGroup]="truckForm" *ngIf="formReady">
                <mat-form-field appearance="outline">
                    <mat-label>Truck to unload</mat-label>
                    <mat-select formControlName="truckref">
                        <mat-option [value]="">-</mat-option>
                        <ng-container *ngFor="let truck of returnedTrucks">
                            <mat-option [value]="truck.details">
                                {{truck.truck.name}} &nbsp; {{truck.truck.driver}}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-12 p-0 m-0">
                <ul *ngIf="truckForm.get('truckref').value" class="w-100 m-1 p-1">
                    <div *ngFor="let da of truckForm.get('truckref').value">

                        <li *ngFor="let load of da.loads" class="list-unstyled p-0 m-0">
                            <div class="card">
                                <div class="card-header text-white" [ngClass]="da.disp.status_id == 7 ? 'bg-danger' : 'credit-bg'">
                                    <div class="float-left">#{{load.orderno}} {{load.status_id}}</div>
                                    <div class="float-right">{{da.cust.debtorno}}.{{da.cust.branchcode}}&nbsp; &nbsp; &nbsp;{{da.cust.brname}}</div>
                                </div>

                                <div class="card-body">
                                    <div class="w-100">
                                        <div class="col-12 row">
                                            <div class="lrg-font">{{load.stockid}}</div>
                                            <div class="med-font align-middle">
                                                <ng-container *ngIf="da.disp.type != 11">
                                                    &nbsp;&nbsp;&nbsp;{{load.loaded}}&nbsp;/&nbsp;{{load.ordered}}&nbsp;<mat-icon class="align-middle">local_shipping</mat-icon>
                                                </ng-container>
                                                <ng-container *ngIf="da.disp.type == 11">
                                                    &nbsp;&nbsp;&nbsp;{{load.loaded}}&nbsp;/&nbsp;{{load.quantity}}&nbsp;<mat-icon class="align-middle">local_shipping</mat-icon>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <br>
                                        <div *ngIf="!da.unloading && da.disp.type != 11" class="med-font">{{da.description}}</div>
                                        <div *ngIf="!da.unloading && da.disp.type == 11" class="med-font">{{load.description}}</div>
                                        <mat-form-field *ngIf="load.unloading && !load.error" [formGroup]="barcodeForm">
                                            <mat-label>Scan to Unload</mat-label>
                                            <input matInput placeholder="UPC#" value="" formControlName="barcoderef" (keyup.enter)="removePick(da,load, $event)">
                                        </mat-form-field>
                                        <div *ngIf="da.error" class="text-center error-msg">{{da.error}}</div>
                                    </div>
                                    <hr>
                                    <div class="w-100">
                                        <div class="float-left align-middle med-font" *ngIf="da.disp.type != 11">{{da.disp.name}}</div>
                                        <div class="float-left align-middle med-font" *ngIf="da.disp.type == 11">CREDIT</div>
                                        <button *ngIf="!load.unloading" class="float-right mt-1" mat-stroked-button (click)="load.unloading = true">Unload</button>
                                        <button *ngIf="load.unloading" class="float-right mt-1" mat-stroked-button (click)="load.unloading = false">Back</button>
                                    </div>

                                </div>
                            </div>
                        </li>
                    </div>
                </ul>
            </div>


        </div>
    </div>
</div>