import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../../services/users.service';
import { Location } from '@angular/common'
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
	selector: 'app-user-groups',
	templateUrl: './user-groups.component.html',
	styleUrls: ['./user-groups.component.scss']
})
export class UserGroupsComponent implements OnInit {
	usergroups: any = [];
	allUsergroups: any = []
	color = '';
	@ViewChild('addGroupInput') addGroupRef: ElementRef;
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	addGroupForm: UntypedFormGroup;
	usergroupSearch = new FormControl('')
	isSearching = false
	headercolumns: string[] = [
		'actions',
		'user_group_id',
		'name',
		'sales_channel'
	]
	placedfrom = [{
		name: 'BackOffice',
		value: 1
	},
	{
		name: 'Point Of Sale',
		value: 2
	},
	{
		name: 'Street',
		value: 3
	},
	{
		name: 'Ecom',
		value: 4
	},
	{
		name: 'Retail',
		value: 5
	}]

	allUsers: any = [];

	constructor(private fb: UntypedFormBuilder,private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, private userService: UsersService, private location: Location,private modalService: NgbModal) {

		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {


		this.addGroupForm = this.fb.group({
			user_group_id: [''],
			name: ['', Validators.required],
			permission: [''],
			access: [''],
		});


		this.userService.getUserGroups().subscribe((groups: any) => {
			this.allUsergroups = groups
			this.setTableData(groups)
		});
	}
	getUsers() {
		this.userService.getUsers().subscribe((users: any) => {
			this.allUsers = users
		})
	}
	openModal() {
		this.modalService.open(this.addGroupRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}

	getNameByValue(value: any): string {
		const item = this.placedfrom.find(p => p.value === parseInt(value));
		return item ? item.name : 'Not Found';
	  }

	removeGroup(group: any) {

		this.userService.removeGroup(group).subscribe( (result:any) => {
			this.allUsergroups = result
			this.setTableData(result)
		});

	}
	addGroup() {
		if(this.addGroupForm.valid) {
			this.userService.addGroup(this.addGroupForm.value).subscribe( (result:any) => {
				this.allUsergroups = result
				this.setTableData(result)
				this.modalService.dismissAll()
			});
		}
	}
	back(): void {
		this.location.back()
	}

	confirmDeletion(usergroup: any) {
		if(confirm('Would you like to delete this User Group?')) {
			this.removeGroup(usergroup)
		}
	}

	setTableData(usergroups: any[]) {
		this.usergroups = new MatTableDataSource(usergroups)
		this.usergroups.sort = this.sort
		this.usergroups.paginator = this.paginator

		//Set sorting to work with lower or upper case
		this.usergroups.sortingDataAccessor = this.sortingDataAccessor
	}

	sortingDataAccessor(data: any, sortHeaderID: string) : string {
		return typeof data[sortHeaderID] === 'string'
			? data[sortHeaderID].toLowerCase()
			: data[sortHeaderID]
	}

	announceSortChange(event) {

	}

	searchUserGroups() {
		this.onInput()
	}

	onInput() {
		const search = this.usergroupSearch.value
		this.isSearching = true
		this.setTableData(this.globalSearchService.filterItem(this.allUsergroups, search, 'user_group_id,name'))
		this.isSearching = false
	}

}
