<div class="main-content">
<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-header card-header-{{color}}-6">
					<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> SalonCentric POS Sales Report</h4>
					<p class="card-category text-right"></p>
				</div>
				<div class="card-body">
	<div class="m-auto main-width">
	<form [formGroup]="searchForm">
	<div class="row m-0 p-0">
		<div class="col-md-2 mt-3">
			<mat-form-field appearance="outline">
				<mat-label>Transaction Dates</mat-label>
				<mat-date-range-input [rangePicker]="picker">
					<input matStartDate formControlName="startdate" placeholder="Start date">
					<input matEndDate formControlName="enddate" placeholder="End date">
				</mat-date-range-input>
				<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-date-range-picker #picker></mat-date-range-picker>
			</mat-form-field>
		</div>
		<div class="col-md-2 mt-3">
			<mat-form-field appearance="outline" >
				<mat-label>PPD Vendors</mat-label>
				<mat-select formControlName="ppvend" multiple appSelectAll>
					<mat-option *ngFor="let k of ppdvend" [value]="k.supplierid">{{k.suppliername}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-2 mt-3">
			<mat-form-field appearance="outline" >
				<mat-label>Partner Vendors</mat-label>
				<mat-select formControlName="pvend" multiple appSelectAll>
					<mat-option *ngFor="let k of partvend" [value]="k.supplierid">{{k.suppliername}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-2 mt-3">
			<mat-form-field appearance="outline" >
				<mat-label>Independent Vendors</mat-label>
				<mat-select formControlName="ivend" multiple appSelectAll>
					<mat-option *ngFor="let k of indvend" [value]="k.supplierid">{{k.suppliername}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-2 mt-3">
			<mat-form-field appearance="outline" >
				<mat-label>Misc Vendors</mat-label>
				<mat-select formControlName="mvend" multiple appSelectAll>
					<mat-option *ngFor="let k of miscvend" [value]="k.supplierid">{{k.suppliername}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-2 mt-3">
			<mat-form-field appearance="outline" >
				<mat-label>Branches</mat-label>
				<mat-select formControlName="branch" multiple appSelectAll>
					<mat-option *ngFor="let k of branches" [value]="k.loccode">{{k.locationname}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-3 mt-3">
			<span *ngIf="sending">
				<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
					<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
				</svg>
			</span>
			<button mat-raised-button class="btn-success btn-height" (click)="$event.preventDefault();exportXls()" *ngIf="orderResults">
				<i class="fa fa-file-excel-o"></i> XLS
			</button>
			&nbsp;
			<button mat-raised-button class="btn-danger btn-height" (click)="$event.preventDefault();exportPdf()" *ngIf="orderResults">
				<i class="fa fa-file-pdf-o"></i> PDF
			</button>
			&nbsp;
			<button mat-raised-button color="accent" class="mt-2" (click)="getCustExport()" *ngIf="!sending">
				<mat-icon>refresh</mat-icon>
			</button>
		</div>
	</div>
	</form>
	<ng-container *ngIf="orderResults">
		<hr>
		<h3> SalonCentric POS Sales Report {{searchForm.get('startdate').value | date:'MM/d/yy'}} - {{searchForm.get('enddate').value | date: 'MM/d/yy'}}</h3>
			<h4>
				{{orderResults.title}}
			</h4>
		<div>
			<mat-paginator [pageSizeOptions]="items_per_page" [pageSize]="pagesizedefault" *ngIf="orderResults"></mat-paginator>
			<div class="table" *ngIf="orderResults">
				<table class="table table-sm table-hover">
					<thead>
						<tr>
							<th>Year</th>
							<th>Invoice Date</th>
							<th>Store Code</th>
							<th>Store Location</th>
							<th>DSC</th>
							<th>Brand</th>
							<th>Material Division</th>
							<th>SKU</th>
							<th>Vendor Part</th>
							<th>UPC</th>
							<th>Description</th>
							<th>Category</th>
							<th>Customer Number</th>
							<th>Sales $</th>
							<th>Qty Sold</th>
					</thead>
					<tbody>
						<ng-container *ngFor="let o of dataObs | async;let i = index">
							<tr>
								<td>
									{{o.year}}
								</td>
								<td>
									{{o.idate}}
								</td>
								<td>{{o.scode}}</td>
								<td>{{o.sloc}}</td>
								<td>{{o.DSC}}</td>
								<td>{{o.Brand}}</td>
								<td>{{o.line}}</td>
								<td>{{o.SKU}}</td>
								<td>{{o.vpart}}</td>
								<td>{{o.UPC}}</td>
								<td>{{o.Description}}</td>
								<td>{{o.Category}}</td>
								<td>{{o.CUSTOMER_NUMBER }}</td>
								<td>{{ o.salestotal | currency }}</td>
								<td>{{ o.salesqty }}</td>
							</tr>
						</ng-container>						
					</tbody>
				</table>
			</div>
		</div>
	</ng-container>
	</div>
</div>
			</div>
		</div>
	</div>
</div>
</div>