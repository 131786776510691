import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';

@Component({
	selector: 'app-productlinecusttable',
	templateUrl: './productlinecusttable.component.html',
	styleUrls: ['./productlinecusttable.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
			state('*', style({ height: '*', visibility: 'visible' })),
			transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
})
export class ProductlinecusttableComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @Input() input: any = [];
  @Input() locs: any = '';
  isExpansionDetailRow = (index, row) => row.hasOwnProperty('detailRow');
  subdisplayedColumns: string[] = [];
  datasource: any;
  config: any;
  constructor(private globalSearchService: GlobalSearchService) {
  	this.globalSearchService.configsubscription.subscribe(conf => {
  		this.config = conf;
  	});
  }

  ngOnInit(): void {
  	if (this.locs.length > 1) {
  		this.subdisplayedColumns = ['debtorno', 'name', ...this.locs, 'units','sales'];
  	}else{
  		this.subdisplayedColumns = ['debtorno', 'name', 'typename', 'units','sales'];
  	}

  	this.datasource = new MatTableDataSource(this.input);
  }

  ngAfterViewInit(): void{
  	this.datasource.sort = this.sort;
  }

  printCustOrders(){ //add excell download for cust orders table here
  	// alert("printing customer orders");
  }

}
