import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatBadgeModule } from '@angular/material/badge';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRippleModule } from '@angular/material/core';
import { SharedModule } from '../shared/shared.module';
import { DashboardComponentsModule } from '../dashboard-components/dashboard-components.module';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { CustomersModule } from '../customers/customers.module';
import { CustomerEditComponent } from './customer-edit/customer-edit.component';
import { CounterPosComponent } from './counter-pos/counter-pos.component';
import { CounterPosCustomerComponent } from './counter-pos-customer/counter-pos-customer.component';
import { CounterPosTransactionsComponent } from './counter-pos-transactions/counter-pos-transactions.component';
import { PosReceiptComponent } from './pos-receipt/pos-receipt.component';
import { ItemQueryComponent } from './item-query/item-query.component';
import { CounterPosDashboardComponent } from './counter-pos-dashboard/counter-pos-dashboard.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { CounterPosInvoiceComponent } from './counter-pos-invoice/counter-pos-invoice.component';


@NgModule({
	declarations: [
		CounterPosComponent,
		CounterPosCustomerComponent,
		CounterPosTransactionsComponent,
		CustomerEditComponent,
		PosReceiptComponent,
		ItemQueryComponent,
		CounterPosDashboardComponent,
		CounterPosInvoiceComponent,
	],
	imports: [
		CommonModule,
		SharedModule,
		DashboardComponentsModule,
		CustomersModule,
		RouterModule,
		MatToolbarModule,
		MatButtonModule,
		MatDialogModule,
		MatDividerModule,
		MatInputModule,
		MatTableModule,
		MatStepperModule,
		MatSortModule,
		MatPaginatorModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		FormsModule,
		MatAutocompleteModule,
		MatSlideToggleModule,
		MatRippleModule,
		MatTabsModule,
		MatListModule,
		MatRadioModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatCardModule,
		MatExpansionModule,
		MatMenuModule,
		MatIconModule,
		MatSelectModule,
		MatBadgeModule,
		MatChipsModule,
		MatTooltipModule,
		NgxExtendedPdfViewerModule,
		NgxMaskModule.forRoot(),
		NgxPaginationModule
	]
})
export class CounterPosModule { }
