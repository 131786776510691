import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { GlobalsService } from '../../../services/globals.service';
import { InventoryService } from '../../../services/inventory.service';

@Component({
	selector: 'app-inventory-edit-images',
	templateUrl: './inventory-edit-images.component.html',
	styleUrls: ['./inventory-edit-images.component.scss'],
	animations: [
		trigger('grow', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: '0',width: '0', overflow: 'hidden' }),
				animate(200, style({ height: '*' , width: '*' })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate(300, style({ height: 0, width: 0 , overflow: 'hidden' })),
				animate('300ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class InventoryEditImagesComponent implements OnInit {
	@Input() item;
	@Output() editing = new EventEmitter<boolean>();
	@Output() newitemdata = new EventEmitter<boolean>();
	files: File[] = [];
	itemForm: any = false;
	uploadresults: any = [];
	properties: any = [];
	formEditAllowed = [
		'stockid',
		'image',
	]

	constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder,private inventoryService: InventoryService, public router: Router, private location: Location, private globalsService: GlobalsService) {}

	onSubmit() {

		if(this.itemForm.valid) {

			this.inventoryService.updateItem(this.itemForm.value).subscribe( (result: any) => {
				this.newitemdata.emit(result);
			})
		}
	}

	ngOnInit(): void {

		const details = this.item
		const controls = [];
		const formGroup: UntypedFormGroup = new UntypedFormGroup({});
		Object.keys(details).forEach(key => {
			if(this.formEditAllowed.includes(key)) {
				const value = (details[key]) ? details[key] : '';
				controls.push({ name: key, control: new UntypedFormControl(value, []) })
			}
		});
		controls.forEach(f => {
				 formGroup.addControl(f.name, f.control, {})
		});
		this.itemForm = formGroup;
	}

	onSelect(event: any) {

		this.files.push(...event.addedFiles);
		for (let i = 0; i < this.files.length; i++) {
			this.readFile(this.files[i]).then(fileContents => {
				//stuff
				const upload = {
					content: fileContents,
					name: event.addedFiles[0].name,
					mime: event.addedFiles[0].type,
					item: this.item.stockid,
				}

				this.inventoryService.uploadFile(upload, this.item.stockid).subscribe((result: any) => {
					 this.newitemdata.emit(result);
				 });

			});
		}
	}

	onRemove(event: any) {
		this.files.splice(this.files.indexOf(event), 1);
	}

	private async readFile(file: File): Promise<string | ArrayBuffer> {
		return new Promise<string | ArrayBuffer>((resolve, reject) => {
		  const reader = new FileReader();

			reader.onload = e => {
				return resolve((e.target as FileReader).result);
			};

			reader.onerror = e => {
				console.error(`FileReader failed on file ${file.name}.`);
				return reject(null);
			};

			if (!file) {
				return reject(null);
			}
			reader.readAsDataURL(file);
		});

		this.files = [];
	}

	ngAfterViewInit(): void {

	}

}
