<ng-container *ngIf="daysales.length">
<ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div class="cal-cell-top">
	&nbsp;&nbsp;UNITS:

	<span class="cal-day-badge" *ngIf="getDateSales(day.date)">
		 {{ getDateSales(day.date) }}
		<!-- {{ day.badgeTotal }} -->
	</span>
	<span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
  </div>
  <div class="cell-totals">
	<span
	  *ngFor="let group of day.eventGroups"
	  class="badge badge-{{ group[0] }}">
	  {{ group[1].length }}
	</span>
  </div>
</ng-template>
<div class="row text-center">
	<div class="col-md-4">
		<div class="btn-group">
			<div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
				Previous
			</div>
			<div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
				Today
			</div>
			<div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
				Next
			</div>
		</div>
	</div>
	<div class="col-md-4">
		<h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
	</div>
	<div class="col-md-4">
		<div class="btn-group">
			<div class="btn btn-primary" (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
				Month
			</div>
			<div class="btn btn-primary" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
				Week
			</div>
			<div class="btn btn-primary" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
				Day
			</div>
		</div>
	</div>
</div>
<br />
<div [ngSwitch]="view">
	<mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" [columnHeaders]="'12345'" (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)" [cellTemplate]="customCellTemplate">
	</mwl-calendar-month-view>
	<mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
	</mwl-calendar-week-view>
	<mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
	</mwl-calendar-day-view>
</div>

<!-- Everything you see below is just for the demo, you don't need to include it in your app -->



<!--
	<br /><br /><br />
<h3>
	Edit events
	<button class="btn btn-primary float-end" (click)="addEvent()">
		Add new
	</button>
	<div class="clearfix"></div>
</h3>

<div class="table-responsive">
	<table class="table table-bordered">
		<thead>
			<tr>
				<th>Title</th>
				<th>Primary color</th>
				<th>Secondary + text color</th>
				<th>Starts at</th>
				<th>Ends at</th>
				<th>Remove</th>
			</tr>
		</thead>

		<tbody>
			<tr *ngFor="let event of events">
				<td>
					<input type="text" class="form-control" [(ngModel)]="event.title" (keyup)="refresh.next()" />
				</td>
				<td>
					<input type="color" [(ngModel)]="event.color.primary" (change)="refresh.next()" />
				</td>
				<td>
					<input type="color" [(ngModel)]="event.color.secondary" (change)="refresh.next()" />
					<input type="color" [ngModel]="event.color.secondaryText ?? '#1e90ff'" (ngModelChange)="event.color.secondaryText = $event" (change)="refresh.next()" />
				</td>
				<td>
					<input class="form-control" type="text" mwlFlatpickr [(ngModel)]="event.start" (ngModelChange)="refresh.next()" [altInput]="true" [convertModelValue]="true" [enableTime]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i" placeholder="Not set" />
				</td>
				<td>
					<input class="form-control" type="text" mwlFlatpickr [(ngModel)]="event.end" (ngModelChange)="refresh.next()" [altInput]="true" [convertModelValue]="true" [enableTime]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i" placeholder="Not set" />
				</td>
				<td>
					<button class="btn btn-danger" (click)="deleteEvent(event)">
						Delete
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</div>

<ng-template #modalContent let-close="close">
	<div class="modal-header">
		<h5 class="modal-title">Event action occurred</h5>
		<button type="button" class="close" (click)="close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div>
			Action:
			<pre>{{ modalData?.action }}</pre>
		</div>
		<div>
			Event:
			<pre>{{ modalData?.event | json }}</pre>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-secondary" (click)="close()">
			OK
		</button>
	</div>
</ng-template> -->

</ng-container>
