<ng-template #nonstock let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Non Stock Item</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<i class="fa fa-close"></i>
		</button>
	</div>
	<div class="modal-body text-dark">
		<form [formGroup]="nonStockForm" *ngIf="nonStockForm">
			<div class="row">
				<div class="col-md-12 mb-3" *ngIf="config.env.package === 'tires'">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Library Search</mat-label>
						<input matInput [formControl]="librarysearch">
					</mat-form-field>
					<mat-progress-bar mode="indeterminate" color="accent" *ngIf="librarySearching"></mat-progress-bar>
				</div>

				<ng-container *ngIf="librarySearchResults && config.env.package === 'tires'">
					<div class="container">
						<ul class="list-group">
							<li class="list-group-item cpdata" *ngFor="let option of librarySearchResults" (click)="selectLibraryItem(option)">
								<div class="row align-items-center">
									<div class="col-2" *ngIf="option.model">
										<img [src]="option.model.make.imageUrl" class="img-fluid nonstocklogo" alt="Make Image">
									</div>
									<div class="col-2" *ngIf="option.model">
										<img [src]="option.model.imageUrl" class="img-fluid nonstockimage" *ngIf="option.model.imageUrl" alt="Model Image">
									</div>
									<div class="col-8">
										{{option.model.make.name}} {{option.model.name}} {{ option.displayName }}
									</div>
									<ng-container *ngIf="loadingItem">
										<svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
											<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
										</svg>
									</ng-container>
								</div>
							</li>
						</ul>
					</div>
				</ng-container>

				<div class="col-md-4 mb-3">
					<h4 *ngIf="nonStockForm.get('brand').value != ''">
						{{ (nonStockForm.get('brand').value.mfg_code  != '') ? nonStockForm.get('brand').value.mfg_code+':'  : 'N/A:' }} {{ nonStockForm.get('brand').value.name }}
						<mat-icon class="mt-2" (click)="nonStockForm.get('brand').setValue('')">cancel</mat-icon>
					</h4>
					<mat-form-field appearance="outline" *ngIf="nonStockForm.get('brand').value == ''">
						<mat-label>Brand</mat-label>
						<mat-select formControlName="brand">
							<mat-option *ngFor="let v of brands" [value]="v">
								{{v.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="col-md-4 mb-3">
					<h4 *ngIf="selectedsupplier">
						{{ selectedsupplier.supplierid }} - {{ selectedsupplier.suppname }}
						<mat-icon class="mt-2" (click)="selectedsupplier = false;">cancel</mat-icon>
					</h4>
					<ng-container *ngIf="!selectedsupplier">
						<app-vendor-lookup (vendor_selected)="selectNonStockVendor($event)" class="w-100"></app-vendor-lookup>
					</ng-container>
				</div>

				<div class="col-md-4 mb-3">
					<mat-form-field appearance="outline">
						<mat-label>Barcode</mat-label>
						<input matInput formControlName="barcode">
					</mat-form-field>
				</div>

				<div class="col-md-4 mb-3">
					<mat-form-field appearance="outline">
						<mat-label>Description</mat-label>
						<input matInput required formControlName="description">
					</mat-form-field>
				</div>

				<div class="col-md-4 mb-3">
					<mat-form-field appearance="outline">
						<mat-label>Part#</mat-label>
						<input matInput formControlName="partno">
					</mat-form-field>
				</div>

				<div class="col-md-4 mb-3" [ngClass]="{'d-none': config.env.package == 'beauty' && !user.user.viewcost}">
					<mat-form-field appearance="outline" class="text-right">
						<mat-label>Cost</mat-label>
						<span matPrefix>$</span>
						<input matInput formControlName="cost">
					</mat-form-field>
				</div>

				<div class="col-md-4 mb-3">
					<mat-form-field appearance="outline">
						<mat-label>Qty</mat-label>
						<span matPrefix>#</span>
						<input matInput required formControlName="qty" class="text-center">
					</mat-form-field>
				</div>

				<div class="col-md-4 mb-3">
					<mat-form-field appearance="outline" class="text-right">
						<mat-label>Price</mat-label>
						<span matPrefix>$</span>
						<input matInput required formControlName="price">
						<mat-hint *ngIf="estprice" class="text-danger">Suggested Markup: {{ suggestedMarkup | currency }}</mat-hint>
					</mat-form-field>
				</div>

				<div class="col-md-4 mb-3" *ngIf="config.env.package == 'tires'">
					<mat-form-field appearance="outline" class="text-right">
						<mat-label>FET</mat-label>
						<span matPrefix>$</span>
						<input matInput formControlName="fet">
					</mat-form-field>
				</div>

				<div class="col-md-4 mb-3" *ngIf="config.env.package == 'beauty'">
					<mat-slide-toggle formControlName="taxable">
						Always Taxable
					</mat-slide-toggle>
				</div>

				<div class="col-md-12 text-right">
					<button mat-stroked-button aria-label="Add To Order" color="accent" (click)="addNonStock()" [ngClass]="{'btn btn-danger': !nonStockForm.valid, 'btn btn-success': nonStockForm.valid}">
						<small>
							<mat-icon>add</mat-icon>
						</small> Add To Order
					</button>
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<button mat-stroked-button color="white" (click)="nonStockModal()" *ngIf="config.features.nonstock">Add Non Stock</button>
