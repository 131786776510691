<div class=" row border-bottom pl-1 pr-1 ml-1 mr-1">
	<ng-container *ngIf="!sending">
		<button class="btn btn-success" (click)="exportXls();"><i class="fa fa-file-excel-o"></i></button>
		&nbsp;&nbsp;
		<button class="btn btn-danger" (click)="exportPdf();"><i class="fa fa-file-pdf-o"></i></button>
	</ng-container>
	<ng-container *ngIf="sending">
		<svg class="spinner" width="30px" height="30px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
			<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
		</svg>
	</ng-container>

	<div class="table" #print_table>
		<table class="table table-sm">
			<tr>
				<th>Customer#</th>
				<th>Name</th>
				<th>Address1</th>
				<th>Address2</th>
				<th>Address3</th>
				<th>Address4</th>
				<th>Address5</th>
				<th>Tax Ref</th>
			</tr>
			<tr *ngFor="let d of data">
				<td>
					<a [routerLink]="'/customers/view/'+d.debtorno">{{ d.debtorno}}</a></td>
				<td>{{ d.name}}</td>
				<td>{{ d.address2}}</td>
				<td>{{ d.address3}}</td>
				<td>{{ d.address4}}</td>
				<td>{{ d.address5}}</td>
				<td>{{ d.taxref}}</td>
			</tr>
		</table>
	</div>
</div>
