<div class="main-content">
	<div class="container-fluid">
		<div class="row tp-wrapper m-0 p-0">
			<div class="col-md-12 ">
				<div class="card ">
					<div class="card-header card-header-{{color}}">
						<h4 class="card-title">
							<i class="material-icons">arrow_left</i> Statements
						</h4>
					</div>

					<div class="card-body">
						<!--left-->

						<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent">
							<mat-tab label="Statement Messages" class="no-overflow">
								<ng-template matTabContent>
									<div class="row mt-3 m-0 p-0">
										<div class="col-md-3" *ngFor="let r of statement_text">
											<h5>{{ r.confname }}</h5>
											<angular-editor [init]="editorConfig" (onSelectionChange)="updateValue($event, r)" [config]="editorConfig" [initialValue]="r.confvalue"></angular-editor>
										</div>
									</div>
									<div class="col-12 text-right">
										<button mat-raised-button color="accent" (click)="saveMessages()">Save Message Text</button>
									</div>
								</ng-template>
							</mat-tab>

							<mat-tab label="Print Statements" class="no-overflow">
								<ng-template matTabContent>
									<form [formGroup]="statementForm" class="no-overflow" *ngIf="statementForm">
										<!-- Customer Range Section -->
										<mat-card class="form-card">
											<mat-card-title>Customer Range</mat-card-title>
											<mat-card-content>
												<mat-divider></mat-divider>
												<div class="row mt-3">
													<div class="col-md-3">
														<mat-form-field appearance="outline" class="full-width">
															<mat-label>Starting Customer (Customer code)</mat-label>
															<input type="text" placeholder="Reference" formControlName="FromCust" aria-label="Reference" matInput />
														</mat-form-field>
													</div>
													<div class="col-md-3">
														<mat-form-field appearance="outline" class="full-width">
															<mat-label>Ending Customer (Customer code)</mat-label>
															<input type="text" placeholder="Reference" formControlName="ToCust" aria-label="Reference" matInput />
														</mat-form-field>
													</div>
												</div>
											</mat-card-content>
										</mat-card>

										<!-- Date Selection Section -->
										<mat-card class="form-card mt-3">
											<mat-card-title>Date Selection</mat-card-title>
											<mat-card-content>
												<mat-divider></mat-divider>
												<div class="row mt-3">
													<div class="col-md-3">
														<mat-form-field appearance="outline" class="full-width">
															<mat-label>Cut off Date</mat-label>
															<input matInput [value]="today" formControlName="dateto" [matDatepicker]="paydatepicker">
															<mat-datepicker-toggle matSuffix [for]="paydatepicker"></mat-datepicker-toggle>
															<mat-datepicker #paydatepicker></mat-datepicker>
														</mat-form-field>
													</div>
													<div class="col-md-3">
														<mat-form-field appearance="outline" class="full-width">
															<mat-label>Statement Due Date</mat-label>
															<input matInput [value]="today" formControlName="datedue" [matDatepicker]="paydatepickerdatedue">
															<mat-datepicker-toggle matSuffix [for]="paydatepickerdatedue"></mat-datepicker-toggle>
															<mat-datepicker #paydatepickerdatedue></mat-datepicker>
														</mat-form-field>
													</div>
												</div>
											</mat-card-content>
										</mat-card>

										<!-- Additional Selections Section -->
										<mat-card class="form-card mt-3">
											<mat-card-title>Additional Selections</mat-card-title>
											<mat-card-content>
												<mat-divider></mat-divider>
												<div class="row mt-3">
													<div class="col-md-3">
														<mat-form-field appearance="outline" class="full-width">
															<mat-label>Terms</mat-label>
															<mat-select formControlName="paymentterms" multiple appSelectAll>
																<mat-option *ngFor="let term of terms" [value]="term.termsindicator">
																	{{ term.terms }}
																</mat-option>
															</mat-select>
														</mat-form-field>
													</div>
													<div class="col-md-3">
														<mat-form-field appearance="outline" class="full-width">
															<mat-label>Statement Types</mat-label>
															<mat-select formControlName="type" multiple>
																<mat-option value="credit">Credit Balance</mat-option>
																<mat-option value="debit">Debit Balance</mat-option>
															</mat-select>
														</mat-form-field>
													</div>
													<div class="col-md-6 mt-3">
														Customers with Print Statement Flag Only
														<mat-slide-toggle formControlName="printonly"></mat-slide-toggle>
													</div>
												</div>
											</mat-card-content>
										</mat-card>

										<!-- Loading Spinner -->
										<div class="table-responsive text-center mt-3" *ngIf="loading">
											Loading Please Wait...
											<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
												<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
											</svg>
										</div>

										<!-- Print Statements Button -->
										<div class="col-md-12 text-center mt-3" *ngIf="!loading">
											<button mat-raised-button color="primary" (click)="printStatements()">Print Statements</button>
										</div>
									</form>

								</ng-template>
							</mat-tab>
							<mat-tab label="Email Statements" class="no-overflow" *ngIf="statementEmailForm">
								<div class="table-responsive scheduled-emails mt-3" *ngIf="jobs && jobs.length === 0">
									<mat-card class="form-card ml-0 mr-0">
										<mat-card-title>Scheduled Emails</mat-card-title>
										<mat-card-content>
											<mat-divider></mat-divider>
											<p>No Jobs Pending</p>
										</mat-card-content>
									</mat-card>
								</div>
								<div class="table-responsive scheduled-emails mt-3" *ngIf="jobs && jobs.length > 0">
									<mat-card class="form-card ml-0 mr-0">
										<mat-card-title>Scheduled Emails</mat-card-title>
										<mat-card-content>
											<mat-divider></mat-divider>
											<table class="table table-striped">
												<thead class="thead-dark">
													<tr>
														<th scope="col">Scheduled</th>
														<th scope="col">Customer Range</th>
														<th scope="col">Cut Off Date</th>
														<th scope="col">Records Processed</th>
														<th scope="col">Last Record | Email</th>
														<th scope="col" class="text-center">Status</th>
														<th scope="col" class="text-center">Action</th>
													</tr>
												</thead>
												<tbody>
													<tr *ngFor="let job of jobs">
														<td>{{ job.scheduled | date:'short' }}</td>
														<td>{{ job.customer_range }}</td>
														<td>{{ job.cut_off_date | date:'short' }}</td>
														<td>{{ job.records_processed }}</td>
														<td>{{ job.last_record_email }}</td>
														<td class="text-center">{{ job.status }}</td>
														<td class="text-center">
															<button mat-icon-button color="warn" aria-label="Remove" (click)="removeJob(job.job_id)">
																<mat-icon>remove</mat-icon>
															</button>
														</td>
													</tr>
												</tbody>
											</table>
										</mat-card-content>
									</mat-card>
								</div>




								<ng-container *ngIf="statementEmailForm">
									<form [formGroup]="statementEmailForm" (ngSubmit)="scheduleEmail()">
										<!-- Hidden inputs for scheduling and script -->
										<input type="hidden" formControlName="schedule" value="true">
										<input type="hidden" formControlName="script" value="autosender.php">

										<mat-card class="form-card">
											<mat-card-title>Email Scheduler</mat-card-title>
											<mat-card-content>
												<!-- Section: Date Selection -->
												<mat-divider></mat-divider>
												<div class="row mt-3">
													<div class="col-6">
														<mat-form-field appearance="outline" class="full-width">
															<mat-label>Date To</mat-label>
															<input matInput [matDatepicker]="cutoffDatePicker" formControlName="dateto" maxlength="10" placeholder="YYYY-MM-DD">
															<mat-datepicker-toggle matSuffix [for]="cutoffDatePicker"></mat-datepicker-toggle>
															<mat-datepicker #cutoffDatePicker></mat-datepicker>
														</mat-form-field>
													</div>
													<div class="col-6">
														<mat-form-field appearance="outline" class="full-width">
															<mat-label>Date Due</mat-label>
															<input matInput [matDatepicker]="schedulerDatePicker" formControlName="datedue" maxlength="10" placeholder="YYYY-MM-DD">
															<mat-datepicker-toggle matSuffix [for]="schedulerDatePicker"></mat-datepicker-toggle>
															<mat-datepicker #schedulerDatePicker></mat-datepicker>
														</mat-form-field>
													</div>
												</div>

												<!-- Section: Customer Selection -->
												<mat-divider></mat-divider>
												<h3 class="section-title">Customer Selection</h3>
												<div class="row mt-3">
													<div class="col-6">
														<mat-form-field appearance="outline" class="full-width">
															<mat-label>Starting Customer (Customer code)</mat-label>
															<input matInput type="text" maxlength="10" formControlName="FromCust">
														</mat-form-field>
													</div>
													<div class="col-6">
														<mat-form-field appearance="outline" class="full-width">
															<mat-label>Ending Customer (Customer code)</mat-label>
															<input matInput type="text" maxlength="10" formControlName="ToCust">
														</mat-form-field>
													</div>
												</div>

												<!-- Section: Schedule Time -->
												<mat-divider></mat-divider>
												<h3 class="section-title">Schedule Time</h3>
												<div class="row mt-3">
													<div class="col-6">
														<mat-form-field appearance="outline" class="full-width">
															<mat-label>Job Schedule Time</mat-label>
															<mat-select formControlName="job_schedule_time">
																<mat-option *ngFor="let hour of hours" [value]="hour.value">{{hour.label}}</mat-option>
															</mat-select>
														</mat-form-field>
													</div>
													<div class="col-6">
														<mat-form-field appearance="outline" class="full-width">
															<mat-label>Job Schedule Time (Minutes)</mat-label>
															<mat-select formControlName="job_schedule_time_min">
																<mat-option *ngFor="let min of minutes" [value]="min.value">{{min.label}}</mat-option>
															</mat-select>
														</mat-form-field>
													</div>
												</div>

												<!-- Section: Payment Terms -->
												<mat-divider></mat-divider>
												<h3 class="section-title">Payment Terms</h3>
												<div class="row mt-3">
													<div class="col-12">
														<mat-form-field appearance="outline" class="full-width">
															<mat-label>Terms</mat-label>
															<mat-select formControlName="paymentterms" multiple appSelectAll>
																<mat-option *ngFor="let term of terms" [value]="term.termsindicator">
																	{{ term.terms }}
																</mat-option>
															</mat-select>
														</mat-form-field>
													</div>
												</div>
											</mat-card-content>

											<!-- Card Actions: Submit button -->
											<mat-card-actions class="card-actions-right">
												<button mat-raised-button color="primary" type="submit">Schedule Email</button>
											</mat-card-actions>
										</mat-card>
									</form>
								</ng-container>

							</mat-tab>

						</mat-tab-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>