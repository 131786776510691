import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { TaxesService } from '../../services/taxes.service';

@Component({
	selector: 'app-taxes',
	templateUrl: './taxes.component.html',
	styleUrls: ['./taxes.component.scss']
})
export class TaxesComponent implements OnInit {

	taxauthorities: any = [];
	taxgroups: any = [];
	taxcategories: any = [];
	color = 'blue';

	constructor(private location: Location,private globalsService: GlobalsService, private globalSearchService: GlobalSearchService,private taxesService: TaxesService) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
	}

	back(): void {
		this.location.back()
	}


}
