import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, Directive, HostListener, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VendorService } from '../../services/vendor.service';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { Location, DatePipe } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import * as FileSaver from 'file-saver';
import { GlobalsService } from '../../services/globals.service';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

@Component({
	selector: 'app-api-settings',
	templateUrl: './api-settings.component.html',
	styleUrls: ['./api-settings.component.scss']
})
export class ApiSettingsComponent implements OnInit {

	@ViewChild('remotes') remotes: ElementRef;
	@ViewChild('rmappings') rmappings: ElementRef;
	@ViewChild('rexclude') rexclude: ElementRef;
	remoteapi: any = [];
	apimappings: any = [];
	exclusions: any = [];
	suppliers: any = [];
	brands: any = [];
	cats: any = [];
	rapi: any = [];
	rmap: any = [];
	rex: any = [];
	color = 'blue';


	constructor(private globalSearchService: GlobalSearchService, private route: ActivatedRoute, private vendorService: VendorService, private inventoryService: InventoryService, private location: Location, public router: Router, private globalsService: GlobalsService, private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();

	}

	ngOnInit(): void {

		this.vendorService.getVendors().subscribe((vs: any) => {

			if (vs) {
				this.suppliers = vs;
			}
		});
		this.inventoryService.getMfgs().subscribe((vs: any) => {

			if (vs) {
				this.brands = vs;
			}
		});
		this.globalsService.getRemoteAPISettings().subscribe((apidata: any) => {

			if (apidata) {
				this.remoteapi = apidata.remoteapi;
				this.apimappings = apidata.apimappings;
				this.exclusions = apidata.exclusions;
			}
		});
		this.globalsService.getItemSetup().subscribe((apidata: any) => {

			if (apidata) {
				this.cats = apidata.categories;
			}
		});
	}
	editRapi(rapi: any) {
		const data = { api_id: rapi }

		this.globalsService.getRemoteAPI(data).subscribe((results: any) => {

			this.rapi = results;
			this.modalService.open(this.remotes, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {

			}, (reason) => {
				this.rapi = [];
			});
		});
	}
	add(data: any) {

		if (data == 'rapi') {
			this.rapi = { api_id: 'NEW', apiname: '', apilogin: '', apipassword: '', apikey: '' }
			this.modalService.open(this.remotes, { ariaLabelledBy: 'modal-title', size: 'lg' });
		} else if (data == 'rmap') {
			this.rmap = { map_id: 'NEW', api_id: '', warehouse: '', feedcolumn: '', feedlabel: '', sort_value: '', binlabel: '' };
			this.modalService.open(this.rmappings, { ariaLabelledBy: 'modal-title', size: 'lg' })
		} else if (data == 'rex') {
			this.rex = { ex_id: 'NEW', api_id: '', manufacturer_id: '', categoryid: '' };
			this.modalService.open(this.rexclude, { ariaLabelledBy: 'modal-title', size: 'lg' })
		}
	}
	save(data: any) {
		this.globalsService.saveRemote(data).subscribe((results: any) => {
			if (results.error) {
				alert(results.error);
			} else {
				this.modalService.dismissAll();
			}
			this.globalsService.getRemoteAPISettings().subscribe((apidata: any) => {

				if (apidata) {
					this.remoteapi = apidata.remoteapi;
					this.apimappings = apidata.apimappings;
					this.exclusions = apidata.exclusions;
				}
			});
		});
	}
	delete(data: any) {
		if (confirm('Are you sure you want to delete this item?')) {
			this.globalsService.delRemote(data).subscribe((results: any) => {
				if (results.message != '') {
					alert(results.message);
				}
				this.modalService.dismissAll();
				this.globalsService.getRemoteAPISettings().subscribe((apidata: any) => {

					if (apidata) {
						this.remoteapi = apidata.remoteapi;
						this.apimappings = apidata.apimappings;
						this.exclusions = apidata.exclusions;
					}
				});
			});
		}
	}
	editRmap(rmap: any) {
		const data = { api_id: rmap }

		this.globalsService.getRemoteMap(data).subscribe((results: any) => {

			this.rmap = results;
			this.modalService.open(this.rmappings, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {

			}, (reason) => {
				this.rmap = [];
			});
		});
	}
	editRex(rex: any) {
		const data = { api_id: rex }

		this.globalsService.getRemoteEx(data).subscribe((results: any) => {

			this.rex = results;
			this.modalService.open(this.rexclude, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {

			}, (reason) => {
				this.rex = [];
			});
		});
	}
	back(): void {
		this.location.back()
	}

}