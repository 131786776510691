<ng-template #orderdetails id="orderdetails" let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Order Details {{ selectedorder.invoice }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="col-12">
				<div class="row">
					<div class="col-9">
						<h5 [innerHTML]="selectedorder.address"></h5>
					</div>
					<div class="col-3 text-right">
						<ul class="entry-list m-0 p-0 bordered">
							<li>
								<div class="spec-label">PO</div>
								<div class="spec-value">{{ selectedorder.po }}</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="row text-center">
					<div class="ml-auto mr-auto col-12 mt-2 mb-2">
						<button mat-raised-button color="warn" (click)="seleccted_alt = true;">Dropped At Alt. Location</button>
						<div *ngIf="seleccted_alt">
							<mat-form-field appearance="outline" class="ml-2 mr-2">
								<mat-label>Transfer To:</mat-label>
								<mat-select [formControl]="transfertruck">
									<mat-option *ngFor="let truck of trucks" [value]="truck.id">
										{{truck.name }}
									</mat-option>
								</mat-select>
							</mat-form-field>
							<button mat-raised-button color="danger" (click)="altLocation()">Submit</button>
						</div>

					</div>
					<div class="ml-auto mr-auto col-12 mt-2 mb-2">
						<button mat-raised-button color="accent" (click)="cancel(selectedorder.did)">Refused Delivery</button>
					</div>
					<div class="ml-auto mr-auto col-12 mt-2 mb-2">
						<div class="row" *ngIf="selectedorder.ordertype=='11'">

							<ng-container *ngFor="let item of selectedorder.details">
								<div class="col-md-12">
									{{ item.stkcode }} {{ item.itemdesc }}
									<mat-form-field appearance="outline" class="ml-2 mr-2">
										<mat-label>Qty Pick Up</mat-label>
										<input matInput class="text-right" placeholder="Qty Pick Up" [(value)]="item.quantity">
									</mat-form-field>
								</div>
							</ng-container>
						</div>

						<button mat-raised-button color="primary" (click)="delivered(selectedorder.did)">
							<span *ngIf="selectedorder.ordertype=='11'">Picked Up</span>
							<span *ngIf="selectedorder.ordertype!='11'">Delivered</span>
						</button>
					</div>
					<div class="ml-auto mr-auto col-12 mt-2 mb-2" *ngIf="selectedorder.ordertype=='11' && selectedorder.status_id=='31'">

						<button mat-raised-button color="primary" (click)="dropAtHome(selectedorder.did)">
							<span>Return To Warehouse</span>
						</button>
					</div>
				</div>
				<table class="table table-sm">
					<tr>
						<th>Item#</th>
						<th>Description</th>
						<th>Ordered</th>
						<th>Price </th>
					</tr>
					<tr *ngFor="let item of selectedorder.details">
						<td>{{ item.stkcode }}</td>
						<td>{{ item.description }}</td>
						<td>{{ item.ordered }}</td>
						<td>{{ item.unitprice | currency}}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="list" cdkDropList [cdkDropListData]="listData" (cdkDropListDropped)="reorgDispatch($event);" cdkDropList>
	<ng-container>
		<div *ngFor="let da of listData;trackBy: identify;" cdkDrag>
			<div class="row m-0 p-0 small-font border-bottom item-list " *ngIf="da.ispoint=='0'">
				<li class="dd-item dd3-item item-box">
					<div class="dd-handle dd3-handle status-{{da.status_id}}" [ngClass]="{credit: da.ordertype=='11'}">&nbsp;</div>
					<mat-card class="dd3-content p-1 ">
						<mat-card-content>
							<div class="row">
								<ng-container *ngIf="isdispatch">
									<button mat-icon-button class="ml-3" color="accent" aria-label="Remove" (click)="removeFromDispatch(da)">
										<mat-icon>remove</mat-icon>
									</button>
								</ng-container>
								<div class="col-md-5 ml-4 ml-md-2 mr-0 pr-0" [innerHTML]="da.address">
								</div>
								<div class="col-12 col-md-4 ml-2 mr-0 pr-0 pl-0">
									<ul class="entry-list m-0 p-0">
										<li>
											<div class="spec-label">PHONE#</div>
											<div class="spec-value"><a href="tel://{{ da.phone }}">{{ da.phone }}</a></div>
										</li>
										<li>
											<div class="spec-label">ORDER#</div>
											<div class="spec-value">{{ da.invoice }}</div>
										</li>
										<li>
											<div class="spec-label">PO</div>
											<div class="spec-value">{{ da.po }}</div>
										</li>
										<li>
											<div class="spec-label">Items</div>
											<div class="spec-value">{{ da.qty }}</div>
										</li>
										<li *ngIf="da.payterms == 'COD'">
											<div class="spec-label">Terms</div>
											<div class="spec-value">{{da.payterms}}</div>
										</li>
										<li>
											<div class="spec-label">Status</div>
											<div class="spec-value">{{ da.status }}</div>
										</li>
									</ul>
								</div>
								<div class="col-4 col-md-2 ml-0 mr-0 text-center mt-4 mb-3 ">
									<button mat-mini-fab color="accent" aria-label="View / Update Status" (mousedown)="$event.stopPropagation()" (click)="viewOrder(da)">
										<mat-icon>sync_alt</mat-icon>
									</button>
								</div>
							</div>
						</mat-card-content>
					</mat-card>
				</li>
			</div>
		</div>
	</ng-container>
</div>
