import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { InventoryService } from '../../../services/inventory.service';

@Component({
	selector: 'app-price-break',
	templateUrl: './price-break.component.html',
	styleUrls: ['./price-break.component.scss'] // Updated to .scss
})
export class PriceBreakComponent implements OnInit {

	@Input() priceBreakData: any[] = []; // Input to receive priceBreaks data
	@Input() stockId: any[] = [];
	@Output() priceBreaksUpdated = new EventEmitter < any[] > (); // Event emitter to notify parent of updates
	priceBreakForm: FormGroup;
	salestypes: any = []; // Sales types fetched from the service
	currencies: any[] = ['USD', 'CAD']; // Default currency options

	constructor(private inventoryService: InventoryService, private fb: FormBuilder, private globalSearchService: GlobalSearchService) {
		// Fetch sales types from the service
		this.globalSearchService.all_system_types.subscribe(u => {
			this.salestypes = u.salestypes;
			console.log(this.salestypes);
		});
	}

	ngOnInit(): void {
		this.priceBreakForm = this.fb.group({
			priceBreaks: this.fb.array([])
		});
		this.populatePriceBreaks();
	}

	// Getter to access the priceBreaks form array
	get priceBreaks(): FormArray {
		return this.priceBreakForm.get('priceBreaks') as FormArray;
	}

	// Function to populate the form array with the input data
	populatePriceBreaks(): void {
		if (this.priceBreakData && this.priceBreakData.length > 0) {
			this.priceBreakData.forEach(breakData => {
				this.priceBreaks.push(this.fb.group({
					salestype: [breakData.salestype, Validators.required],
					stockid: [breakData.stockid], // Hidden field to hold stockid
					quantitybreak: [breakData.quantitybreak, Validators.required],
					price: [breakData.price, Validators.required],
					currabrev: [breakData.currabrev || 'USD', Validators.required], // Default to USD if currabrev is missing
					startdate: [breakData.startdate, Validators.required],
					enddate: [breakData.enddate, Validators.required]
				}));
			});
		}
	}

	// Function to add a new price break with default values
	addPriceBreak(): void {
		const today = new Date();

		// Get the first day of the current month
		const firstDayOfThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);

		// Get the last day of the next month
		const lastDayOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0);

		const newPriceBreak = this.fb.group({
			salestype: ['', Validators.required], // User will select a sales type from the dropdown
			stockid: [this.stockId, Validators.required], // Hidden field, can set default if needed
			quantitybreak: ['', Validators.required],
			price: ['', Validators.required],
			currabrev: ['USD', Validators.required], // Default to USD
			startdate: [firstDayOfThisMonth.toISOString().slice(0, 10), Validators.required], // Default to first day of this month
			enddate: [lastDayOfNextMonth.toISOString().slice(0, 10), Validators.required] // Default to last day of next month
		});
		this.priceBreaks.push(newPriceBreak);
	}

	// Function to remove a specific price break by index
	removePriceBreak(index: number): void {
		this.priceBreaks.removeAt(index);
	}

	cancelPriceBreak() {
		const updatedPriceBreaks = this.priceBreakForm.value.priceBreaks;
		this.priceBreaksUpdated.emit(updatedPriceBreaks);
	}
	// Function to save the price breaks (e.g., send them to an API)
	savePriceBreaks(): void {
		const updatedPriceBreaks = this.priceBreakForm.value.priceBreaks;
		this.inventoryService.updatePriceMatrix(updatedPriceBreaks).subscribe(r => {
			if (r.success) {
				// Emit the updated price breaks to the parent component
				this.priceBreaksUpdated.emit(updatedPriceBreaks);

				// Show notification
				this.globalSearchService.showNotification("Price breaks updated", 'success', 'bottom', 'right');
			}
		});
	}
}