import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { InventoryService } from '../../../services/inventory.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup, FormControl, ValidationErrors } from '@angular/forms';


@Component({
	selector: 'app-location-autocomplete',
	templateUrl: './location-autocomplete.component.html',
	styleUrls: ['./location-autocomplete.component.scss']
})
export class LocationAutocompleteComponent implements OnInit {
	@Output() value_selected = new EventEmitter < any > ();
	searchInput = new FormControl('');
	searching = false
	searchsubscription: any = false;
	allResults: any = false;
	filteredItems: any = false;
	field_label = 'Location'
	keys = 'loccode,locationname';
	defaultlocation: any = false;
	constructor(private globalSearchService: GlobalSearchService, private inventoryService: InventoryService) {}

	ngOnInit(): void {

		this.searchInput.valueChanges.subscribe(newValue => {
			this.search()
		});

		this.globalSearchService.locations.subscribe(r => {
			this.allResults = r;
			this.filteredItems = r;

			this.globalSearchService.user.subscribe((u: any) => {
				const defaultloc = this.allResults.filter((ra: any) => {
					return ra.loccode == u.user.defaultlocation.loccode
				})[0];

				this.searchInput.setValue(defaultloc)
				this.value_selected.emit(defaultloc)
			});
		});
	}

	selectValue(value: any) {
		this.value_selected.emit(this.searchInput.value)
	}

	search() {

		if (this.searchsubscription) {
			this.searchsubscription.unsubscribe();
		}

		const searchvalue = this.searchInput.value;
		if (this.searchInput.value == '') {
			this.filteredItems = this.allResults;
		} else {
			this.filteredItems = this.globalSearchService.filterItem(this.allResults, this.searchInput.value, this.keys)
		}
	}

	displayFn(value: any) {

		if (!value) {
			return '';
		}

		return value.loccode + '-' + value.locationname
	}

}
