import { Component } from '@angular/core';
import { from,fromEvent, Observable,Subscription } from 'rxjs';
import { environment } from '../environments/environment';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {
	offlineEvent: Observable < Event > ;
	onlineEvent: Observable < Event > ;
	subscriptions: Subscription[] = [];

	ngOnInit(): void {
		this.handleAppConnectivityChanges();


	}

	private handleAppConnectivityChanges(): void {
		this.onlineEvent = fromEvent(window, 'online');
		this.offlineEvent = fromEvent(window, 'offline');

		this.subscriptions.push(this.onlineEvent.subscribe(e => {
			// handle online mode

		}));

		this.subscriptions.push(this.offlineEvent.subscribe(e => {
			// handle offline mode

		}));
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

}

if (typeof Worker !== 'undefined') {
  // Create a new
  const worker = new Worker(new URL('./app.worker', import.meta.url));
  worker.onmessage = ({ data }) => {
    console.log(`page got message: ${data}`);
  };
  worker.postMessage('hello');
} else {
  // Web Workers are not supported in this environment.
  // You should add a fallback so that your program still executes correctly.
}