<div class="main-content">

    <mat-tab-group>

        <mat-tab label="Gl Viewing">
            <div class="mb-3" *ngIf="displaydetails">
                <h4></h4>
                <ng-container *ngIf="displaydetails.type == 'html'">
                    <div [innerHTML]="displaydetails.display">
                    </div>
                </ng-container>
                <ng-container *ngIf="displaydetails.type == 'pdf'">
                    <ngx-extended-pdf-viewer [base64Src]="displaydetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true" [filenameForDownload]="displaydetails.title"></ngx-extended-pdf-viewer>
                </ng-container>
            </div>
        </mat-tab>

        <mat-tab label="Reverse" [formGroup]="reverse">
            <ng-container *ngIf="adjustData && adjustData.trandata">
                <!-- header -->
                <div class="row justify-content-center m-0 p-1 mt-2">
                    <h2>{{adjustData.typename}}<b> #{{adjustData.transno}}</b></h2>
                </div>
                <div class="row justify-content-center m-0 p-0">
                    <p class="font-weight-lighter">Creates an opposite document and applies to the selected transaction</p>
                </div>

                <!-- adjust stock? -->
                <div class="row justify-content-center m-0 p-1">
                    <mat-checkbox class="example-margin" formControlName="stock">{{adjustData['adjust_stock']}}</mat-checkbox>
                </div>

                <!-- tran details -->
                <div class="row justify-content-around m-0 p-1">
                    <div class="col-2"></div>
                    <div class="col-3">
                        <ul class="list-unstyled tran_details_ul">
                            <li>
                                <div class='float-left'>Reference: </div>
                                <div class='float-right'>{{adjustData.trandata.reference}}</div>
                            </li>
                            <li>
                                <div class='float-left'>Allocated: </div>
                                <div class='float-right'>{{adjustData.allocated | currency}}</div>
                            </li>
                            <li>
                                <div class='float-left'>Discounts: </div>
                                <div class='float-right'>{{adjustData.trandata.ovdiscount | currency}}</div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-2"></div>
                    <div class="col-3">
                        <ul class="list-unstyled tran_details_ul">
                            <li>
                                <div class='float-left'>SubTotal: </div>
                                <div class='float-right'>{{adjustData.trandata.ovamount | currency}}</div>
                            </li>
                            <li>
                                <div class='float-left'>Tax: </div>
                                <div class='float-right'>{{adjustData.trandata.ovgst | currency}}</div>
                            </li>
                            <li>
                                <div class='float-left'>Shipping: </div>
                                <div class='float-right'>{{adjustData.trandata.ovfreight | currency}}</div>
                            </li>
                            <li>
                                <div class='float-left'>Total: </div>
                                <div class='float-right'>{{adjustData.totalamount | currency}}</div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-2"></div>
                </div>

                <!-- posting date & reason row -->
                <div class="row justify-content-center m-0 p-1" >
                    <div class="col-1"></div>
                    <div class="col-4">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Date</mat-label>
                            <input matInput [matDatepicker]="picker" formControlName="date">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="picker" ></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-2"></div>
                    <div class="col-4 align-middle">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Reason</mat-label>
                            <textarea matInput placeholder="Reason For Reversal" formControlName="reason"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-1"></div>
                </div>

                <!-- button row -->
                <div class="row justify-content-center m-0 p-0">
                    <span *ngIf="adjustProcessing">
                        <svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                        </svg>
                    </span>
                    <button mat-raised-button class="bg-danger text-white" *ngIf="!adjustProcessing" (click)="adjust('reverse')">Adjust</button>
                </div>
            </ng-container>
        </mat-tab>

        <mat-tab label="AR Adjustment">
            <ng-container *ngIf="arAdjustData && arAdjustData.trandata">
                <!-- header -->
                <div class="row justify-content-center m-0 p-1 mt-2">
                    <h2>{{arAdjustData.typename}}<b> #{{arAdjustData.transno}}</b></h2>
                </div>

                <!-- tran details -->
                <div class="row justify-content-around m-0 p-1">
                    <div class="col-2"></div>
                    <div class="col-3">
                        <ul class="list-unstyled tran_details_ul">
                            <li>
                                <div class='float-left'>Reference: </div>
                                <div class='float-right'>{{arAdjustData.trandata.reference}}</div>
                            </li>
                            <li>
                                <div class='float-left'>Allocated: </div>
                                <div class='float-right'>{{arAdjustData.allocated | currency}}</div>
                            </li>
                            <li>
                                <div class='float-left'>Discounts: </div>
                                <div class='float-right'>{{arAdjustData.trandata.ovdiscount | currency}}</div>
                            </li>
                            <li>
                                <div class='float-left'>{{arAdjustData.typename}} Total: </div>
                                <div class='float-right'>{{arAdjustData.totalamount | currency}}</div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-2"></div>
                    <div class="col-3">
                        <ul class="list-unstyled tran_details_ul">
                            <li>
                                <div class='float-left'>SubTotal: </div>
                                <div class='float-right'>{{arAdjustData.trandata.ovamount | currency}}</div>
                            </li>
                            <li>
                                <div class='float-left'>Tax: </div>
                                <div class='float-right'>{{arAdjustData.trandata.ovgst | currency}}</div>
                            </li>
                            <li>
                                <div class='float-left'>Shipping: </div>
                                <div class='float-right'>{{arAdjustData.trandata.ovfreight | currency}}</div>
                            </li>
                            <li>
                                <div class='float-left'>New Total: </div>
                                <div class='float-right'>{{newTotal | currency}}</div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-2"></div>
                </div>

                <!-- posting date & reason row -->
                <div class="row justify-content-center m-0 p-1" >
                    <mat-form-field appearance="outline" class="w-75 inline m-auto" >
                        <mat-label>Posting Date</mat-label>
                        <input matInput [matDatepicker]="picker" [formControl]="ardate" class="text-center" (dateChange)="updatePostingDate()">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker" ></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="row justify-content-center m-0 p-0">
                    <p>OR</p>
                </div>
                <div class="row m-0 p-0 justify-content-center">
                    <p class="font-weight-lighter font-italic">**Payments & Credits are negative, Invoices are positives</p>
                </div>
                <div class="row justify-content-center m-0 p-0">
                    <div class="col-1"></div>
                    <div class="col-5">
                        <mat-form-field appearance="outline">
                            <mat-label>{{arAdjustData.typename}} Balance</mat-label>
                            <mat-hint align="start">**This will force the balance to be a specific value</mat-hint>
                            <input matInput [formControl]="balance" (keyup)="updateNewTotal('force', $event)">
                        </mat-form-field>
                    </div>
                    <div class="col-5">
                        <mat-form-field appearance="outline">
                            <mat-label>Adjustment Amount</mat-label>
                            <mat-hint align="end">**Amount to adjust up or down</mat-hint>
                            <input matInput [formControl]="amount" (keyup)="updateNewTotal('adjust', $event)">
                        </mat-form-field>
                    </div>
                    <div class="col-1"></div>
                </div>

                <!-- button row -->
                <div class="row justify-content-center m-0 p-0">
                    <span *ngIf="adjustProcessing">
                        <svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                        </svg>
                    </span>
                    <button mat-raised-button class="bg-danger text-white" *ngIf="!adjustProcessing" (click)="adjust('ar')">Adjust</button>
                </div>
            </ng-container>
        </mat-tab>
    </mat-tab-group>

</div>