import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { OrdersService } from '../../services/orders.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';


@Component({
	selector: 'app-order-reprint-pick',
	templateUrl: './order-reprint-pick.component.html',
	styleUrls: ['./order-reprint-pick.component.scss']
})
export class OrderReprintPickComponent implements OnInit {

	searchForm: UntypedFormGroup;
	user: any = [];
	searchingSubscribtion: any = false;
	orders: any = [];
	config: any = [];
	endpoint ='/?route=salesman/documents/picking&key=9NPZ5AJ4FNNT9C7Y&id=';
	filetype: '.pdf';
	color: any ='';
	baselink: any = '';
	constructor(private route: ActivatedRoute, private orderService: OrdersService, private location: Location,public router: Router,public cdr: ChangeDetectorRef,private globalSearchService: GlobalSearchService, private globalsService: GlobalsService,private fb: UntypedFormBuilder,) {

		this.color = this.globalSearchService.getColor();

	}

	ngOnInit(): void {

		this.config = this.globalsService.getApiConfig()
		this.baselink = this.config.apiServer.baseUrl+this.config.apiServer.pickingLink;
		this.globalSearchService.user.subscribe((results: any) => {
			if(results) {
				this.user = results.user;
			}
		});

		this.searchForm = this.fb.group({
			keywords: ['', Validators.required],
		});

		this.loadData('');
		this.searchForm.get('keywords').valueChanges.subscribe(newValue => {
			this.loadData(newValue)
		})
	}

	loadData(keywords) {
		if(this.searchingSubscribtion) {
			this.searchingSubscribtion.unsubscribe()
		}

		this.searchingSubscribtion = this.orderService.getOrderSearch(keywords).subscribe( (results:any) => {
			this.orders = results;
		})
	}

	print(order: any) {
		window.location.assign(this.config.baseUrl + this.endpoint + order.orderno);
	}

	back(): void {
		this.location.back()
	}
}
