import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform,AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { merge, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';

import { MatSnackBar,MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { PurchasingService } from '../../services/purchasing.service';
import { OrdersService } from '../../services/orders.service';
import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { OmsService } from '../../services/oms.service';
import { trigger, state, style, transition, animate, animation } from '@angular/animations';

@Component({
	selector: 'app-purchase-order-entry',
	templateUrl: './purchase-order-entry.component.html',
	styleUrls: ['./purchase-order-entry.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(-179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('500ms ease-in')),
			transition('active => inactive', animate('500ms ease-out')),
		]),
		trigger('cinfo', [
			transition(':enter', [
				animate('50ms', style({ opacity: 1, height: 100 })),
			]),
			transition(':leave', [
				animate('100ms', style({ opacity: 1, height: 0 }))
			]),
		]),
		trigger('itemSearch', [
			transition(':enter', animation([style({ transform: 'translate(-800px,0)', }),
				animate('0.2s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(1200px,0)', })
				),
			])),
		]),
		trigger('addFilters', [
			transition(':enter', animation([style({ transform: 'translate(200px,0)', }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(-200px,0)', })
				),
			])),
		]),
	],
})
export class PurchaseOrderEntryComponent implements OnInit {

	user: any;
	config: any;

	total_qty = 0;
	totals: any = [];
	CHAT_ROOM = 'OrderBoard';
	taxgroups: any = [];
	salespeople: any = [];
	shipvia: any = [];
	terms: any = [];
	zones: any = [];
	locations: any = [];
	holdreasons: any = [];

	itemSearchForm: UntypedFormGroup;
	vendorFormGroup: UntypedFormGroup;

	vendor: any = [];

	limits = [
		{ name: '25', value: '25' },
		{ name: '50', value: '50' },
		{ name: '100', value: '100' },
		{ name: '150', value: '150' },
		{ name: '250', value: '200' },
		{ name: '500', value: '500' },
		{ name: 'No Limit', value: false },
	];

	vendorinfo = true;
	searching = false;
	dspcost = true;
	complete = false;
	editing_order = false;

	inventorysearching: any = false;

	search_items: any= [];
	filtered_items: any= [];
	cart_items: any = [];
	total_cart_qty = 0;
	cart_totals: any = [];
	summary: any = [];
	color: any = 'blue'
	baselink: any = [];
	ponumber: any =  false;
	recdoc: any = false;
	constructor(private omsService: OmsService, private router: Router,private fb: UntypedFormBuilder,private location: Location,private route: ActivatedRoute,private purchasingService:PurchasingService,private globalSearchService:GlobalSearchService, private ordersService: OrdersService ,private globalsService: GlobalsService ) {
		this.config = this.globalsService.getApiConfig()
		this.color = this.globalSearchService.getColor();
		this.baselink = this.config.apiServer.baseUrl + this.config.apiServer.poLink;
		this.recdoc = this.config.apiServer.baseUrl + this.config.apiServer.recLink;
	}

	ngOnInit(): void {

		this.itemSearchForm = this.fb.group({
			keywords: ['', Validators.required],
			limit: [this.limits[0].value, Validators.required],
		});

		this.itemSearchForm.get('keywords').valueChanges.subscribe(newValue => {
			this.itemSearch(newValue)
		})

		this.route.params.subscribe(params => {
			if(params['id']) {
				const supplierid = params['id'];

				this.purchasingService.getSuppliers(supplierid).subscribe((result:any) => {
					this.vendor = result;
					this.init();

					if(params['order']) {
						this.editing_order = true;
						this.ponumber = params['order'];
						this.purchasingService.loadPurchaseOrder(params['order']).subscribe((results: any) => {
							//this.complete = true;
							this.summary = results;
						});
					}

					this.purchasingService.getCart(supplierid).subscribe((items: any[]) => {
						this.cart_items = items
						this.total_cart_qty = items.reduce(function (accumulator, items) {
							return accumulator + parseFloat(items.quantity);
						}, 0);
						this.purchasingService.getTotals(supplierid).subscribe((results: any[]) => {
							this.totals = results;
							 });
					 });
				});
			}
		});
	}

	toggleVendorInfo() {
		this.vendorinfo = (this.vendorinfo) ? false : true;
	}

	itemSearch(keywords: any) {
		this.searching = true;
		this.vendorinfo = false;
		if(this.inventorysearching) {
			this.inventorysearching.unsubscribe()
		}

		const data = { keywords: keywords, limit: this.itemSearchForm.get('limit').value, vendor: this.vendorFormGroup.value };

		this.inventorysearching = this.purchasingService.getItemSearch(data).subscribe((items: any) => {

			this.search_items = items
			this.filtered_items = items
			this.searching = false;
		});
	}

	stopProp(event: any): void {
		event.stopPropagation();
	}

	decrement(item: any) {
		const index = this.filtered_items.indexOf(item);
		if(this.filtered_items[index].quantity > 0){
			this.filtered_items[index].quantity -=1;
		}
	}

	increment(item: any) {
		const index = this.filtered_items.indexOf(item);
		this.filtered_items[index].quantity +=1;
	}

	//todo - move these to order service
	updateSearchPrice(event:any, item:any ) {
		const index = this.filtered_items.indexOf(item) ;
		this.filtered_items[index].price = event.target.value;
	}

	updateSearchQuantity(event:any, item:any ) {
		const index = this.filtered_items.indexOf(item) ;
		this.filtered_items[index].quantity = event.target.value;
	}

	updatePrice(event:any, item:any ) {
		const index = this.cart_items.indexOf(item) ;
		this.cart_items[index].price = event.target.value;
		this.globalSearchService.showNotification(item.description +' x '+item.quantity+' Updated','warning','bottom','right');
		this.updateCartItem(this.cart_items[index]);
	}

	updateQuantity(event:any, item:any ) {
		const index = this.cart_items.indexOf(item) ;
		this.cart_items[index].quantity = event.target.value;
		this.globalSearchService.showNotification(item.description +' x '+item.quantity+' Updated','warning','bottom','right');
		this.updateCartItem(this.cart_items[index]);
	}

	updateDiscount(event:any, item:any ) {
		const index = this.cart_items.indexOf(item) ;
		this.cart_items[index].discount = event.target.value;
		this.updateCartItem(this.cart_items[index]);
	}

	updateNote(event:any, item:any ) {
		const index = this.cart_items.indexOf(item) ;
		this.cart_items[index].line_notes = event.target.value;
		this.updateCartItem(this.cart_items[index]);
	}

	updateCartItem(item:any) {

		const index = this.cart_items.indexOf(item);

		const data = {
			supplierid: this.vendor.supplierid,
			cartid: item.cart_id,
			description: item.description,
			price: item.price,
			quantity: item.quantity,
			discount: item.discount,
			line_notes: item.line_notes,
		};

		this.purchasingService.updateOrder(data).subscribe((results: any ) => {

			this.total_cart_qty = this.cart_items.reduce(function (accumulator: number, items: any) {
				return accumulator + parseFloat(items.quantity);
			}, 0);

			this.purchasingService.getTotals(this.vendor.supplierid).subscribe((results: any) => {
				 this.totals = results;
			});
		});
	}

	removeFromOrder(cart_id: any) {
		this.purchasingService.removeFromOrder(cart_id, this.vendor.supplierid).subscribe((results: any) => {
			this.cart_items = results;
			this.total_cart_qty = results.reduce(function (accumulator, items) {
				return accumulator + parseFloat(items.quantity);
			}, 0);

		});
	}

	addToOrder(item: any) {

		const this_index = this.filtered_items.indexOf(item)
		if(!this.filtered_items[this_index].quantity) {
			this.filtered_items[this_index].quantity = 1;
		}

		const add_items = [];

		//all qtys to add
		this.filtered_items.forEach((item, index) => {
			//allow negatives? hrmm
			if(parseFloat(item.quantity) != 0) {
				add_items.push(item);
			}
		});

		if(add_items.length) {
			this.purchasingService.addMultipleToOrder(add_items, this.vendor.supplierid).subscribe((results: any) => {

				this.cart_items = results;
				this.updateTotals();

				add_items.forEach((item, index) => {
					//reset qtys and show notification
					if(parseFloat(item.quantity) != 0) {
						this.globalSearchService.showNotification(item.description +' x '+item.quantity+' Added','success','bottom','right');
						const index = this.filtered_items.indexOf(item);
						this.filtered_items[index].quantity = 0;
					}
				});
			});
		}
	}

	updateTotals() {
		this.purchasingService.getTotals(this.vendor.supplierid).subscribe((results: any) => {

			this.cart_totals = results.totals;
			this.total_cart_qty = this.cart_items.reduce(function (accumulator: number, items: any) {
				return accumulator + parseFloat(items.quantity);
			}, 0);
		});
	}

	init() {

		//forcing cascade - not loaded properly during testing.
		this.globalSearchService.user.subscribe((user: any) => {
			this.user = user;
			if(user) {
				this.globalSearchService.zones.subscribe( (zones: any) => {
					this.zones = zones;
					if(zones) {
						this.globalSearchService.locations.subscribe( (locations: any) => {
							this.locations = locations;
							if(user.user) {
								if(this.vendor) {
									const future = new Date();
									future.setDate(future.getDate() + 3);

									this.vendorFormGroup = this.fb.group({
										supplierid: [this.vendor.supplierid, [Validators.required]],
										suppname: [this.vendor.suppname, [Validators.required]],
										address1: [this.vendor.address1, [Validators.required]],
										address2: [this.vendor.address2, []],
										address3: [this.vendor.address3, [Validators.required]],
										address4: [this.vendor.address4, [Validators.required]],
										address5: [this.vendor.address5, [Validators.required]],
										address6: [this.vendor.address6, [Validators.required]],
										deladd1: [user.user.defaultlocation.deladd1],
										deladd2: [user.user.defaultlocation.deladd2],
										deladd3: [user.user.defaultlocation.deladd3],
										deladd4: [user.user.defaultlocation.deladd4],
										deladd5: [user.user.defaultlocation.deladd5],
										deladd6: [user.user.defaultlocation.deladd6],
										warehouse: [user.user.defaultlocation.loccode,[Validators.required]],
										deliverydate: [future.toISOString(),[Validators.required]],
										initiator: [this.user.user.userid,[Validators.required]],
										refrenceno: [''],
									});
								}
							}
						});
					}
				});
			}
		});
	}

	createOrder() {
		this.purchasingService.createPurchaseOrder(this.vendor, this.vendorFormGroup.value, this.cart_items, this.user).subscribe((results:any) => {
			if(results.success) {
				this.purchasingService.clearOrder(this.vendor.supplierid).subscribe((clear: any[]) => {
					this.complete = true;
					this.summary = results;
					const data = { results, user: this.user };
					this.omsService.sendPutUpdate({ data, roomName: this.CHAT_ROOM }, cb => {
					});
					this.router.navigate(['/purchasing-success/'+results.header.supplierno+'/'+results.header.orderno])
				});
			} else {
				alert(results.message);
			}
		});
	}

	clearOrder() {
		this.purchasingService.clearOrder(this.vendor.supplierid).subscribe((results: any[]) => {
			 this.cart_items = [];
			 this.search_items = [];
			 this.filtered_items = [];
			 this.searching = false;
			 this.total_cart_qty = 0;
		});
	}

	back(): void {
		this.location.back()
	}

	ngAfterViewInit(): void {

		this.globalSearchService.user.subscribe(async user => this.user = user);
		this.globalSearchService.locations.subscribe(async user => this.user = user);

		this.globalSearchService.taxgroups.subscribe( async (results: any) => {
			this.taxgroups = results;
		});

		this.globalSearchService.salespeople.subscribe(async (results: any) => {
			this.salespeople = results;
		});

		this.globalSearchService.shipvia.subscribe(async (results: any) => {
			this.shipvia = results;
		});

		this.globalSearchService.terms.subscribe( async (results: any) => {
			this.terms = results;
		});

		this.globalSearchService.holdreasons.subscribe(async (results: any) => {
			this.holdreasons = results;
		});
	}

}
