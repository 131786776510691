<ng-template #userdetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">User Details</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<app-user-edit [user]="selecteduser" (updated)="updateUser($event)"></app-user-edit>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Users</h4>
						<p class="card-category text-right">User Information</p>
					</div>
					<div class="card-body">
						<div class="row align-items-center mb-3">
							<div class="col-md-6 text-left">
								<a mat-raised-button color="accent" [routerLink]="'/users/create'">New User</a>
							</div>
							<div class="col-md-2 text-left">
								<mat-slide-toggle class="text-center ml-auto mr-auto" [formControl]="filterdisabled" (click)="searchUsers()">
									Include Disabled
								</mat-slide-toggle>
							</div>
							<div class="col-md-4">
								<mat-form-field appearance="outline" class="w-100">
									<mat-label>Search</mat-label>
									<input matInput (input)="onInput()" [formControl]="userSearch" (keydown.enter)="$event.preventDefault(); onInput()" autofocus>
									<button mat-icon-button matSuffix (click)="searchUsers()">
										<mat-icon>search</mat-icon>
									</button>
									<mat-progress-bar mode="indeterminate" color="accent" *ngIf="isSearching"></mat-progress-bar>
								</mat-form-field>
							</div>
						</div>
						<div class="table-container">
							<div class="table">
								<table mat-table [dataSource]="users" matSort (matSortChange)="announceSortChange($event)">
									<!-- Actions Column -->
									<ng-container matColumnDef="actions">
										<th mat-header-cell *matHeaderCellDef></th>
										<td mat-cell *matCellDef="let user">
											<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
												<mat-icon>more_vert</mat-icon>
											</button>
											<mat-menu #menu="matMenu">
												<button mat-menu-item (click)="openUser(user)">
													<mat-icon>edit</mat-icon>
													<span>Edit User</span>
												</button>
											</mat-menu>
										</td>
									</ng-container>

									<!-- User ID Column -->
									<ng-container matColumnDef="userid">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>User ID</th>
										<td mat-cell *matCellDef="let user">{{ user.userid }}</td>
									</ng-container>

									<ng-container matColumnDef="empcode" *ngIf="config.env.package == 'beauty'">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>Emp #</th>
										<td mat-cell *matCellDef="let user">{{ user.empcode }}</td>
									</ng-container>

									<!-- Name Column -->
									<ng-container matColumnDef="realname">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
										<td mat-cell *matCellDef="let user">{{ user.realname }}</td>
									</ng-container>

									<!-- Role Column -->
									<ng-container matColumnDef="groupname">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
										<td mat-cell *matCellDef="let user">{{ user.groupname }}</td>
									</ng-container>

									<!-- Disabled Column -->
									<ng-container matColumnDef="blocked">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>Disabled</th>
										<td mat-cell *matCellDef="let user">
											<span>{{ user.blocked === '1' ? 'Yes' : 'No' }}</span>
										</td>
									</ng-container>

									<!-- View Cost Column -->
									<ng-container matColumnDef="viewcost">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>View Cost</th>
										<td mat-cell *matCellDef="let user">
											<span>{{ user.viewcost === '1' ? 'Yes' : 'No' }}</span>
										</td>
									</ng-container>

									<!-- Header and Row Definitions -->
									<tr mat-header-row *matHeaderRowDef="headercolumns; sticky: true;"></tr>
									<tr mat-row *matRowDef="let row; columns: headercolumns;"></tr>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
