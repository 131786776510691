<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title" id="headof"><i class="material-icons" (click)="back()">arrow_left</i> Inventory List</h4>
						<p class="card-category"><b></b></p>
					</div>
					<div class="card-body">
						<app-inventory-search></app-inventory-search>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
