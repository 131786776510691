<form class="navbar-form">
	<div class="input-group no-border  w-100">
		<input type="text" (input)="onInput($event)" class="form-control text-right"  placeholder="Order Search..." />
		<button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
		<i class="material-icons">search</i>
		<div class="ripple-container"></div>
		</button>
	</div>
	<cdk-virtual-scroll-viewport class="gkz-viewport" itemSize="20">
		<div *cdkVirtualFor="let item of filteredItems; let i = index" class="item">

			<div class="row border-bottom">
				<div class="col-6 col-md-4" (click)="navTo(item.link)">
					<b>{{ item.content }}</b><br>
					ORDER: <b>{{ item.details.ORDER_NUMBER}}</b><br>
					DATE: {{ item.details.DATE_ORDERED}}
				</div>
				<div class="col-6 col-md-4">
					(<b>{{ item.details.CUST_NUMBER }}</b>) {{ item.details.CUST_NAME }}<br>
					PO: {{ item.details.CUST_ORDER_NO_ }}
				</div>
				<div class="d-none d-md-inline col-md-4">
					WANTED: <b>{{ item.details.DATE_WANTED}}</b><br>
					{{ item.details.ADDRESS_1}}, {{ item.details.CITY}}
					<span *ngIf="item.details.ADDRESS_2 != ''"><br>{{ item.details.ADDRESS_2 }}</span>
				</div>
			</div>
		</div>
	</cdk-virtual-scroll-viewport>
</form>