<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">

					<div class="card-header card-header-{{ color }}-5" >
						<h4 class="card-title pointer"><i class="material-icons" (click)="back()">arrow_left</i> {{ title }}</h4>
					</div>
					<div class="card-body">
						<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent">
							<mat-tab label="Customer Age">
								<app-archived-customers-ar class="w-100">
								</app-archived-customers-ar>
							</mat-tab>
							<mat-tab label="Inventory">
								<app-archive-inventory class="w-100">
								</app-archive-inventory>
							</mat-tab>
						</mat-tab-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
