<div class="table w-100">
	<table class="table table-sm smaller-font" *ngIf="aged" >
		<tr>
			<th class="text-center w-20">BAL</th>
			<th class="text-center w-20">DUE</th>
			<th class="text-center w-20">30</th>
			<th class="text-center w-20">60</th>
			<th class="text-center w-20">90</th>
		</tr>
		<tr>
			<td class="text-center w-20 pointer" (click)="emitZero()">{{ aged.balance | currency}}</td>
			<td class="text-center w-20 pointer" (click)="emitOne()">{{ aged.due | currency}}</td>
			<td class="text-center w-20 pointer" [ngClass]="aged.current > 0 ? 'bg-danger text-white bolder': '' " (click)="emitTwo()">{{ aged.current | currency}}</td>
			<td class="text-center w-20 pointer" [ngClass]="aged.overdue1 > 0 ? 'bg-danger text-white bolder': '' " (click)="emitThree()">{{ aged.overdue1 | currency}}</td>
			<td class="text-center w-20 pointer" [ngClass]="aged.overdue2 > 0 ? 'bg-danger text-white bolder': '' " (click)="emitFour()">{{ aged.overdue2 | currency}}</td>
		</tr>
	</table>
</div>
