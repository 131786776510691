<ng-container *ngIf="!editing && !newcustomer">
<div class="table">
	<table mat-table  [dataSource]="branches" matSort class="table table-sm w-100">
		<ng-container matColumnDef="debtorno">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Edit</th>
			<td mat-cell *matCellDef="let tran">
				<button class="btn btn-sm" (click)="editBranch(tran.branchcode)"><i class="fa fa-pencil"></i></button>
			</td>
		</ng-container>
		<ng-container matColumnDef="branchccode">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Branch#</th>
			<td mat-cell *matCellDef="let tran" [innerHTML]= "tran.branchcode"></td>
		</ng-container>
		<ng-container matColumnDef="brname">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
			<td mat-cell *matCellDef="let tran" [innerHTML]= "tran.brname"></td>
		</ng-container>
		<ng-container matColumnDef="braddress1">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Address</th>
			<td mat-cell *matCellDef="let tran" [innerHTML]= "tran.braddress1"></td>
		</ng-container>
		<ng-container matColumnDef="braddress3">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> City</th>
			<td mat-cell *matCellDef="let tran" [innerHTML]= "tran.braddress3"></td>
		</ng-container>
		<ng-container matColumnDef="braddress4">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Region</th>
			<td mat-cell *matCellDef="let tran" [innerHTML]= "tran.braddress4"></td>
		</ng-container>
		<ng-container matColumnDef="braddress5">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Postal</th>
			<td mat-cell *matCellDef="let tran" >
				<span class="text-left">
				{{ tran.braddress5 }} &nbsp;
				</span>
				<span class="text-right">
					<button mat-icon-button color="warn" (click)="removeBranch(tran.branchcode)"><mat-icon>remove</mat-icon></button>
				</span>

			</td>
		</ng-container>

		<tr mat-header-row  *matHeaderRowDef="headercolumns"></tr>
		<tr mat-row *matRowDef="let row; columns: headercolumns;"></tr>
	</table>
</div>
</ng-container>
<ng-container *ngIf="newcustomer">
	<app-customer-shipto-add [customerdata]="customerdata" (editing)="updateEditing($value)" (newcustomerdata)="updateCustomer($event)"></app-customer-shipto-add>
</ng-container>
<ng-container *ngIf="editing && !newcustomer">
	<app-customer-shipto-edit [customerdata]="currentbranch" (editing)="updateEditing($value)" (newcustomerdata)="updateCustomer($event)"></app-customer-shipto-edit>
</ng-container>
