<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-3">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Automated Delivery Receipt System</h4>
						<p class="card-category"></p>
					</div>
					<div class="card-body">
						<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent">
							<mat-tab label="ADRS Orders">
								<div>
									<div class="table table-sm ">
										<div class="table table-responsive text-center" *ngIf="!pending">
											<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
												<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
											</svg>
										</div>
										<div class="row">
											<div class="col-md-2">
												<mat-form-field appearance="outline" class="ml-2 mr-2">
													<mat-label>Manufacturer:</mat-label>
													<mat-select [formControl]="filterMfg" (selectionChange)="filterMultiple($event)" multiple appSelectAll >
														<mat-option *ngFor="let m of filterMfgs" [value]="m">
															{{ m }}
														</mat-option>
													</mat-select>
												</mat-form-field>

											</div>
											<div class="col-md-10">
												<mat-form-field appearance="outline">
												<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
												</mat-form-field>
											</div>
										</div>

										<mat-table [dataSource]="pending" matSort #sorter1="matSort" (matSortChange)="announceSortChange($event)">
											<ng-container matColumnDef="selected">
												<mat-header-cell *matHeaderCellDef> <button mat-raised-button color="primary" (click)="sendADRS()"> Send </button> &nbsp; <button mat-raised-button color="warn" (click)="removeADRS()"> Remove </button> &nbsp; </mat-header-cell>
												<mat-cell *matCellDef="let pending">
													<mat-checkbox [(ngModel)]="pending.selected"></mat-checkbox>
												</mat-cell>
											</ng-container>

											<ng-container matColumnDef="orderno">
												<mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Order"> Order No</mat-header-cell>
												<mat-cell *matCellDef="let pending">
													{{pending.orderno}}
												</mat-cell>
											</ng-container>

											<ng-container matColumnDef="orderdate">
												<mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Code">
													Date Ordered
												</mat-header-cell>
												<mat-cell *matCellDef="let pending"> {{pending.orderdate}} </mat-cell>
											</ng-container>
											<ng-container matColumnDef="doctype">
												<mat-header-cell *matHeaderCellDef>
													Document Type
												</mat-header-cell>
												<mat-cell *matCellDef="let pending"> {{pending.doctype}} </mat-cell>
											</ng-container>
											<ng-container matColumnDef="debtorno">
												<mat-header-cell *matHeaderCellDef mat-sort-header> Customer</mat-header-cell>
												<mat-cell *matCellDef="let pending"> {{ pending.debtorno}} </mat-cell>
											</ng-container>
											<ng-container matColumnDef="branchcode">
												<mat-header-cell *matHeaderCellDef mat-sort-header> Branch</mat-header-cell>
												<mat-cell *matCellDef="let pending"> {{ pending.branchcode}} </mat-cell>
											</ng-container>
											<ng-container matColumnDef="brname">
												<mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
												<mat-cell *matCellDef="let pending"> {{ pending.brname}} </mat-cell>
											</ng-container>
											<ng-container matColumnDef="manufacturer">
												<mat-header-cell *matHeaderCellDef mat-sort-header> Manufacturer</mat-header-cell>
												<mat-cell *matCellDef="let pending"> {{ pending.mfg}} </mat-cell>
											</ng-container>
											<ng-container matColumnDef="totalines">
												<mat-header-cell *matHeaderCellDef mat-sort-header> Total Lines to Send</mat-header-cell>
												<mat-cell *matCellDef="let pending"> {{ pending.totalines}} </mat-cell>
											</ng-container>
											<mat-header-row *matHeaderRowDef="headercolumns"></mat-header-row>
											<mat-row *matRowDef="let row; columns: headercolumns;"></mat-row>
										</mat-table>
										<mat-paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons aria-label="select page">
										</mat-paginator>
									</div>
								</div>
							</mat-tab>
							<mat-tab label="Rejections">
								<div>
									<div class="table table-sm ">
										<div class="table table-responsive text-center" *ngIf="!reject">
											<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
												<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
											</svg>
										</div>
										<mat-table [dataSource]="reject" matSort #sorter2="matSort" (matSortChange)="announceSortChange($event)">
											<ng-container matColumnDef="selected">
												<mat-header-cell *matHeaderCellDef> </mat-header-cell>
												<mat-cell *matCellDef="let reject">
													<button mat-raised-button color="primary" (click)="resendADRS(reject.orderno)"> Send </button>
													<button mat-raised-button color="secondary" (click)="clearADRS(reject.orderno)"> Remove </button>
												</mat-cell>
											</ng-container>

											<ng-container matColumnDef="orderno">
												<mat-header-cell *matHeaderCellDef> Order No</mat-header-cell>
												<mat-cell *matCellDef="let reject">
													{{reject.orderno}}
												</mat-cell>
											</ng-container>

											<ng-container matColumnDef="orderdate">
												<mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Code">
													Date Ordered
												</mat-header-cell>
												<mat-cell *matCellDef="let reject"> {{reject.orderdate}} </mat-cell>
											</ng-container>
											<ng-container matColumnDef="doctype">
												<mat-header-cell *matHeaderCellDef mat-sort-header>
													Document Type
												</mat-header-cell>
												<mat-cell *matCellDef="let reject"> {{reject.doctype}} </mat-cell>
											</ng-container>
											<ng-container matColumnDef="debtorno">
												<mat-header-cell *matHeaderCellDef mat-sort-header> Customer</mat-header-cell>
												<mat-cell *matCellDef="let reject"> {{ reject.debtorno}} </mat-cell>
											</ng-container>
											<ng-container matColumnDef="branchcode">
												<mat-header-cell *matHeaderCellDef mat-sort-header> Branch</mat-header-cell>
												<mat-cell *matCellDef="let reject"> {{ reject.branchcode}} </mat-cell>
											</ng-container>
											<ng-container matColumnDef="brname">
												<mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
												<mat-cell *matCellDef="let reject"> {{ reject.brname}} </mat-cell>
											</ng-container>
											<ng-container matColumnDef="reason">
												<mat-header-cell *matHeaderCellDef mat-sort-header> Reason</mat-header-cell>
												<mat-cell *matCellDef="let reject"> <span title="{{reject.reason}}">{{ reject.code}}</span> </mat-cell>
											</ng-container>
											<mat-header-row *matHeaderRowDef="rheadercolumns"></mat-header-row>
											<mat-row *matRowDef="let row; columns: rheadercolumns;"></mat-row>


										</mat-table>
										<mat-paginator [pageSizeOptions]="[25, 50, 100]" #page2="matPaginator" showFirstLastButtons aria-label="select page">
										</mat-paginator>


									</div>
								</div>
							</mat-tab>
							<mat-tab label="Past ADRS Orders">
								<div>
									<div class="table table-sm ">
										<div class="table table-responsive text-center" *ngIf="!sent">
											<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
												<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
											</svg>
										</div>
										<mat-table [dataSource]="sent" matSort #sorter3="matSort" (matSortChange)="announceSortChange($event)">
											<ng-container matColumnDef="selected">
												<mat-header-cell *matHeaderCellDef></mat-header-cell>
												<mat-cell *matCellDef="let sent">
												<button mat-raised-button color="secondary" (click)="clearADRS(sent.orderno)"> Remove </button>
												</mat-cell>
											</ng-container>
											<ng-container matColumnDef="orderno">
												<mat-header-cell *matHeaderCellDef> Order No</mat-header-cell>
												<mat-cell *matCellDef="let sent">
													{{sent.orderno}}
												</mat-cell>
											</ng-container>

											<ng-container matColumnDef="orderdate">
												<mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Code">
													Date Ordered
												</mat-header-cell>
												<mat-cell *matCellDef="let sent"> {{sent.orderdate}} </mat-cell>
											</ng-container>
											<ng-container matColumnDef="doctype">
												<mat-header-cell *matHeaderCellDef mat-sort-header>
													Document Type
												</mat-header-cell>
												<mat-cell *matCellDef="let reject"> {{reject.doctype}} </mat-cell>
											</ng-container>
											<ng-container matColumnDef="debtorno">
												<mat-header-cell *matHeaderCellDef mat-sort-header> Customer</mat-header-cell>
												<mat-cell *matCellDef="let sent"> {{ sent.debtorno}} </mat-cell>
											</ng-container>
											<ng-container matColumnDef="branchcode">
												<mat-header-cell *matHeaderCellDef mat-sort-header> Branch</mat-header-cell>
												<mat-cell *matCellDef="let sent"> {{ sent.branchcode}} </mat-cell>
											</ng-container>
											<ng-container matColumnDef="brname">
												<mat-header-cell *matHeaderCellDef mat-sort-header> Name</mat-header-cell>
												<mat-cell *matCellDef="let sent"> {{ sent.brname}} </mat-cell>
											</ng-container>
											<ng-container matColumnDef="manufacturer">
												<mat-header-cell *matHeaderCellDef mat-sort-header> Manufacturer</mat-header-cell>
												<mat-cell *matCellDef="let sent"> {{ sent.mfg}} </mat-cell>
											</ng-container>
											<ng-container matColumnDef="totalines">
												<mat-header-cell *matHeaderCellDef mat-sort-header> Total Lines to Send</mat-header-cell>
												<mat-cell *matCellDef="let sent"> {{ sent.totalines}} </mat-cell>
											</ng-container>
											<mat-header-row *matHeaderRowDef="headercolumns"></mat-header-row>
											<mat-row *matRowDef="let row; columns: headercolumns;"></mat-row>
										</mat-table>
										<mat-paginator [pageSizeOptions]="[25, 50, 100]" #page3="matPaginator" showFirstLastButtons aria-label="select page">
										</mat-paginator>
									</div>
								</div>
							</mat-tab>
						</mat-tab-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
