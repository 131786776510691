import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Location } from '@angular/common'
import { InventoryService } from '../../services/inventory.service';
import { QantelService } from '../../services/qantel.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { BarcodeScannerLivestreamComponent } from 'ngx-barcode-scanner';
import { OrdersService } from '../../services/orders.service';
import { VendorService } from '../../services/vendor.service';
import { PurchasingService } from '../../services/purchasing.service';
import { reduce } from 'rxjs/operators';

@Component({
	selector: 'app-warehouse-item-scan',
	templateUrl: './warehouse-item-scan.component.html',
	styleUrls: ['./warehouse-item-scan.component.scss']
})
export class WarehouseItemScanComponent implements OnInit {

	@ViewChild('stockid') stockidField: ElementRef;
	@ViewChild('itemrec') itemrecField: ElementRef;

	// @Output() selectedIndexChange: EventEmitter<number>



	itemForm: UntypedFormGroup;
	itemlist: any = false;
	recitem = new UntypedFormControl('');
	vendor: UntypedFormControl = new UntypedFormControl('');
	item: any = false;
	ordertitle = '';
	orderdetails: any = [];
	searching: boolean;
	showlist: boolean;
	historyloading: boolean;
	showorder: boolean;
	starting: boolean;
	lastsearch = '';
	inbound = 0;
	noresults = false;
	addingbarcode = false;
	searchsub: any = false;
	barcodeScanner: BarcodeScannerLivestreamComponent;
	barcodeValue;
	color: any = '';
	viewingitem: any = false;
	itemio: any = false;
	vendors: any = [];
	vendor_openpos: any = false;
	scanorrec = 'rec';
	selectedIndex: any = 0;

	constructor(private purchasingService: PurchasingService, private globalSearchService: GlobalSearchService, private route: ActivatedRoute, private formBuilder: UntypedFormBuilder, public router: Router, private location: Location, public inventoryService: InventoryService, public orderService: OrdersService, private qantelService: QantelService, private vendorService : VendorService) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
		this.showlist = false;
		this.showorder = false;
		this.starting = true;
		this.searching = false;
		this.viewingitem = false;
		this.historyloading = false;

		this.itemForm = this.formBuilder.group({
			stockid: ['', Validators.required],
		});

		this.vendorService.getVendors().subscribe( result => {
			this.vendors = result;
		})

		this.route.params.subscribe(params => {

			if (params['item']) {
				this.itemForm = this.formBuilder.group({
					stockid: ['', Validators.required],
				});

				this.itemForm.markAllAsTouched();

				this.inventoryService.loadItem(params['item']).subscribe((result: any) => {

					this.viewingitem = true;
					this.item = result
					this.itemlist = false;
					this.itemio = result;

					const search = {
						stockid: params['item'],
						vendor: this.vendor.value.supplierid
					}

					this.purchasingService.getPurchasesForItemByVendor(search).subscribe(results => {
						this.vendor_openpos = results;
					})

					if(this.scanorrec == 'rec') {
						if (result.reserved_orders.length) {
							this.itemForm.get('stockid').setValue('')
							this.audioPlay();
						} else {
							if(result.openpos) {
								this.itemForm.get('stockid').setValue('')
								this.audioPlayBad();
								this.vibrate()
							} else {
								this.itemForm.get('stockid').setValue('')
								this.vibrateLong();
								this.audioPlayFree();
							}
						}
					}

					this.focusInput();
				});
			}
		});

	}

	tabChange(number: any){
		// alert(number);
	}

	ee(event: any){
		alert(JSON.stringify(event));
	}

	searchOrReceiveToggle(event: any) {
		switch(event) {
		case 1:
			this.scanorrec = 'scan';
			break;
		default:
			this.scanorrec = 'rec';
			break;
		}

	}
	updatePos(event:any) {
		//this.viewingitem = false;
		const search = {
			stockid: (this.item) ? this.item.item.stockid : '',
			vendor: this.vendor.value.supplierid
		}

		this.purchasingService.getPurchasesForItemByVendor(search).subscribe( (results:any) => {
			this.vendor_openpos = results;
		})
	}

	focusInput() {
		//this.stockidField.nativeElement.focus();
	}

	ngAfterViewInit() {

	}


	colorSwap(event: any){
		this.selectedIndex = event;
	}



	back(): void {
		this.location.back()
	}


	lookupVersionEight() {
		this.searching = true;
		this.lastsearch = this.recitem.value;


		if (this.searchsub) {
			this.searchsub.unsubscribe();
		}

		if (this.recitem.value != '') {
			const data = { stockid: this.recitem.value , vendor: this.vendor.value.supplierid }
			this.searchsub = this.inventoryService.itemSearch(data).subscribe((results: any) => {
				this.itemlist = results
				if (results.length == 1) {
					this.itemio = results;

					this.itemForm.get('stockid').setValue('')
					this.loadErpItem(results[0]);
					this.viewingitem = true;
				} else {
					this.viewingitem = false;
				}

				this.searching = false;
				this.recitem.setValue('');
				this.focusInput();
			});
		} else {
			this.focusInput();
			this.lastsearch = '';
			this.searching = false;
			this.showlist = false;
			this.noresults = false;
			this.itemlist = null;
		}
	}

	lookup() {

		this.searching = true;
		this.lastsearch = this.itemForm.value.stockid;

		if (this.searchsub) {
			this.searchsub.unsubscribe();
		}

		if (this.itemForm.value.stockid != '') {

			this.searchsub = this.qantelService.itemSearch(this.itemForm.value).subscribe((results: any) => {
				this.itemlist = results
				if (results.length == 1) {
					this.loadItem(results[0]);
				} else {
					this.viewingitem = false;
				}

				this.searching = false;
			});
		} else {
			this.focusInput();
			this.lastsearch = '';
			this.searching = false;
			this.showlist = false;
			this.noresults = false;
		}
	}

	loadErpItem(item) {
		this.itemio = item;
		this.router.navigate(['/warehouse/item-scan/' + item.stockid]);
	}

	loadItemByStockID(item) {
		this.router.navigate(['/warehouse/item-scan/' + item]);
		this.focusInput();
	}

	loadItem(item) {

		if(this.item) {
			if(this.item.item.stockid!= item.stockid) {
				this.recitem.setValue('');
				this.router.navigate(['/warehouse/item-scan/' + item.stockid]);
				this.focusInput();
			} else {
				this.viewingitem = true;
				this.searching = false;
				this.showlist = false;
				this.noresults = false;
				this.itemlist = false;
				this.recitem.setValue('');
				this.focusInput();
			}
		} else {

			this.recitem.setValue('');

			this.router.navigate(['/warehouse/item-scan/' + item.stockid]);
			this.focusInput();
		}
	}

	dspBarcodeAdd(event: any) {
		event.preventDefault();
		this.addingbarcode = true;
	}

	addedBarcode(event: any) {
		this.addingbarcode = false;
		this.itemForm.setValue({ stockid: event.stockid });
		// this.lookup();
		this.lookupVersionEight();
	}

	getHistory(item) {
		this.inventoryService.getHistory(item).subscribe((history: any[]) => {
			this.item.history = history;
			this.historyloading = false;
		});
	}

	loadOrder(order) {
		this.starting = false;
		this.noresults = false;
		this.orderService.getOrder(order).subscribe((results: any) => {
			this.showorder = true;
			this.orderdetails = results;
			this.ordertitle = 'Order: ' + order + '';
		});

	}

	billingAddressFormatted(obj: any) {

		const address = obj.CUST_NAME + ' (' + obj.CUST_NUMBER + ')<br>' + obj.ADDRESS_1 + ' ' + obj.ADDRESS_2 + '<br>' + obj.CITY + ', ' + obj.STATE + ' ' + obj.ZIP_CODE;

		return address;
	}

	shippingAddressFormatted(obj: any) {
		const address = obj.SHIP_NAME + '<br>' + obj.SHIP_ADDR_1 + ' ' + obj.SHIP_ADDR_2 + '<br>' + obj.SHIP_CITY + ', ' + obj.SHIP_STATE + ' ' + obj.SHIP_ZIP;
		return address;
	}

	onValueChanges(result) {
		this.barcodeValue = result.codeResult.code;
	}

	startCamera() {
		this.barcodeScanner.start();
	}

	onStarted(started) {

	}

	audioPlay() {
		const audio = new Audio('/assets/pristine-609.mp3');
		const play = audio.play();
		if (play !== undefined) {
			play.catch(error => {
				// Auto-play was prevented
				// Show a UI element to let the user manually start playback
			}).then(() => {
				// Auto-play started
			});
		}
	}

	audioPlayBad() {
		const audio = new Audio('/assets/glitch.mp3');
		const play = audio.play();
		if (play !== undefined) {
			play.catch(error => {
				// Auto-play was prevented
				// Show a UI element to let the user manually start playback
			}).then(() => {
				// Auto-play started
			});
		}
	}

	audioPlayFree() {
		const audio = new Audio('/assets/money.mp3');
		const play = audio.play();
		if (play !== undefined) {
			play.catch(error => {
				// Auto-play was prevented
				// Show a UI element to let the user manually start playback
			}).then(() => {
				// Auto-play started
			});
		}
	}

	vibrate() {
		if (window.navigator && window.navigator.vibrate) {
			navigator.vibrate(2000);
		} else {
			// Not supported
		}
	}

	vibrateLong() {
		if (window.navigator && window.navigator.vibrate) {
			navigator.vibrate(5000);
		} else {
			// Not supported
		}
	}

}
