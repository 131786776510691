import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, AfterViewInit , Directive, HostListener } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { Location } from '@angular/common'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as FileSaver from 'file-saver';

@Component({
	selector: 'app-vendor-allocations',
	templateUrl: './vendor-allocations.component.html',
	styleUrls: ['./vendor-allocations.component.scss'],
	animations: [
		trigger('detailExpand', [
		  state('collapsed', style({ height: '0px', minHeight: '0' })),
		  state('expanded', style({ height: '*' })),
		  transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
})
export class VendorAllocationsComponent implements OnInit {

	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	headercolumns: string[] = [
		'transno',
		'trandate',
		'typename',
		'supplierno',
		'suppname',
		//'typename',
		'invtext',
		'totalamount',
		'allocated',
		'balance',
	];
	expandedElement: any = false;
	prevRow: any = false;
	transactions: any = [];
	color: any = 'blue';
	displaymattable: any = true;
	loading: any = true;
	displaydetails: any = [];
	transid: any = '';
	trandata: any = [];
	loccode: any = '';
	searching = false;
	vendorSearch =  new FormControl('');

	alltransactions: any = false;
	transactionsraw: any = false;

	constructor(public location: Location,private route: ActivatedRoute, public globalSearchService: GlobalSearchService, private modalService: NgbModal, private globalsService: GlobalsService) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
		this.loadData();
		this.route.params.subscribe(params => {
			this.transid = params['transid'];
			const data = { transid: this.transid, type: '20' };

			this.globalsService.getAllocationData(data).subscribe((results: any) => {
				this.trandata = results;
				this.trandata.balance = Math.abs(this.trandata.balance);
			});
		});
	}

	onInput() {
		if(this.vendorSearch.value != '' ) {

			const newrsults = this.globalSearchService.filterItem(this.alltransactions,this.vendorSearch.value, 'transno,trandate,supplierno,suppname');

			this.transactions = new MatTableDataSource(newrsults);
			this.transactions.sort = this.sort;
			this.transactions.paginator = this.paginator;

		} else {
			this.transactions = new MatTableDataSource(this.alltransactions);
			this.transactions.sort = this.sort;
			this.transactions.paginator = this.paginator;
		}
	}

	back(): void {
		this.location.back()
	}

	loadData() {
		this.loading = true;
		this.globalsService.getAllocationTrans(this.loccode).subscribe(async (results: any) => {
			this.alltransactions = results.ap.recs;
			this.transactionsraw = results.ap.recs;

			this.transactions = new MatTableDataSource(results.ap.recs);

			this.transactions.sort = this.sort;
			this.transactions.paginator = this.paginator;
			if(this.prevRow) {
				this.transactions.data[this.prevRow].selected = true;
			}
			this.loading = false;
		});
	}
	searchTransaction(value) {

	}
	addAllocation(tran: any, id: any) {
		const amt = (document.getElementById('amt_' + tran.id) as HTMLInputElement).value;
		tran.amt = amt;

		if (tran.amt < 0) {
			alert('Cannot allocate negative amount');
		}
		if (tran.amt == 0) {
			tran.amt = Math.abs(tran.balance);
		}
		if (tran.amt > Math.abs(id.balance)) {
			tran.amt = Math.abs(id.balance);
		}

		const data = { transno: id.transno, type: id.type, allocid: tran.id, amt: tran.amt };
		this.globalsService.addAllocation(data).subscribe((results: any) => {

			this.trandata = results;
			this.trandata.balance = Math.abs(this.trandata.balance);

		});
	}
	removeAllocation(tran: any, id: any) {
		const data = { transno: id.transno, fromtransno: tran.transno, type: id.type, allocid: tran.id };
		this.globalsService.removeAllocation(data).subscribe((results: any) => {

			this.trandata = results;
			this.trandata.balance = Math.abs(this.trandata.balance);

		});
	}
	allocate(transaction: any) {

		this.transid = transaction.id;
		const data = { transno: transaction.transno, type: transaction.type };
		this.globalsService.getAllocationData(data).subscribe((results: any) => {

			this.trandata = results;
			this.trandata.balance = Math.abs(this.trandata.balance);

		});
	}
	openDocument(transaction: any, content: any, display: string) {

		switch (transaction.type) {
		case '11':
			this.globalsService.getCreditNote(transaction.id, display).subscribe((r: any) => {
				this.displaydetails = r;
				this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
			})
			break;
		case '10':
			this.globalsService.getInvoice(transaction.id, display).subscribe((r: any) => {
				this.displaydetails = r;
				this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
			})

			break;
		}
	}

	expandRow(row: any, index: any ) {

		if(this.prevRow && this.prevRow >= 0) {
			this.transactions.data[this.prevRow].selected = false;
		}

		if(index >= 0) {
			const previousindex = index - 1;
			if(previousindex >= 0) {
				this.prevRow = previousindex;
				this.transactions.data[previousindex].selected = true;
			}
		}

		this.expandedElement = (this.expandedElement == row) ? false : row;

		if(!this.expandedElement) {
			this.loadData();
			row.selected = false;
		}
	}

	identify(index, item) {
		return item.id;
	}
	announceSortChange(event: any) {

	}
}
