import { Component, EventEmitter, OnInit, Input, Output, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomerService } from '../../../services/customer.service';
import { UsersService } from '../../../services/users.service';
import { OmsService } from '../../../services/oms.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
	selector: 'app-customer-lookup',
	templateUrl: './customer-lookup.component.html',
	styleUrls: ['./customer-lookup.component.scss']
})
export class CustomerLookupComponent implements OnInit, AfterViewInit {

	@ViewChild(MatSort) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('customerinput') customerinput: ElementRef;
	@Output() customer_selected = new EventEmitter < any > ();
	@Input() creating = false;
	@Input() lookupOnly: any = false;

	title = 'customers';
	outlined = 'outline';
	customers: any[] = [];
	all_customers: any[] = [];
	headercolumns: string[] = ['actions', 'name', 'billingaddress','contact','address3'];
	customersearchservice: any;
	timeout: any = null;
	searching = false;
	customersearching = false;
	customersearchsubscription: any;
	noresults = false;
	ready = true;
	sending = false;
	color: any = 'blue';
	customertypes: any = false;
	customertype = new UntypedFormControl('');
	customersearch = new UntypedFormControl('');
	include_disabled = new UntypedFormControl(false);
	search_by_order = new UntypedFormControl(false);
	phoneonly = new UntypedFormControl(false);
	config: any = false;
	user: any = false;
	dataSource: MatTableDataSource < any > = new MatTableDataSource();
	isPosUser: any = false;

	constructor(
		private usersService: UsersService,
		private fb: UntypedFormBuilder,
		private globalSearchService: GlobalSearchService,
		public customerService: CustomerService,
		public router: Router,
		public omsService: OmsService
	) {
		this.omsService.setPrintedOrder();
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});

		this.globalSearchService.userData.subscribe(r => {
			this.user = r;
		});
	}

	ngOnInit(): void {
		this.ready = false;
		this.noresults = true;
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.mesagesallowed.next(true);
		if (this.creating) {
			this.outlined = 'outline';
		}
		this.globalSearchService.customertypes.subscribe(r => {
			this.customertypes = r;
		});
		this.usersService.isPosUser().subscribe(isPOS => {
			if (isPOS) {
				this.isPosUser = true;
			}
			this.customerinput.nativeElement.focus();
		});


	}

	ngAfterViewInit() {
		this.dataSource.sort = this.sort;
		this.dataSource.paginator = this.paginator;
//
// 		fromEvent(this.customerinput.nativeElement, 'keyup')
// 			.pipe(
// 				debounceTime(150),
// 				distinctUntilChanged(),
// 			)
// 			.subscribe(() => {
// 				this.filterCustomers();
// 			});
	}

	filterCustomers() {
		this.ready = false;

		if (this.customersearchsubscription) {
			this.customersearchsubscription.unsubscribe();
		}

		if(this.customersearch.value === '') {
			this.clearForm();
			return false;
		}

		const search = {
			customertype: this.customertype.value,
			keywords: this.customersearch.value,
			include_disabled: this.include_disabled.value,
			search_by_order: this.search_by_order.value,
			phoneonly: this.phoneonly.value
		};
		this.sending = true;
		this.customersearching = true;
		this.customersearchsubscription = this.customerService.getCustomerSearch(search).subscribe((results: any) => {
			this.sending = false;
			this.all_customers = results;

			if (!this.include_disabled.value) {
				results = results.filter((customer) => {
					return customer.disabletrans === this.include_disabled.value;
				});
			}
			this.customersearching = false;
			this.ready = true;
			this.noresults = (results.length) ? false : true;
			if (results.length === 1) {
				this.customer_selected.emit(results[0]);
				this.clearForm();
			} else {
				if (this.user && this.user.user && this.user.user.issalesman) {
					this.globalSearchService.blurInputElement(this.customerinput);
				}
			}

			this.customers = results;
			this.dataSource.data = results;
		});
	}

	clearForm() {
		this.noresults = true;
		this.customersearching = false;
		this.customers = [];
		this.all_customers = [];
		this.customersearch.setValue('');
		this.dataSource.data = [];
		this.ready = false;
	}

	filterDisabled() {
		if (this.include_disabled.value) {
			this.customers = this.all_customers;
		} else {
			this.customers = this.all_customers.filter((customer) => {
				return customer.disabletrans === this.include_disabled.value;
			});
		}
		this.dataSource.data = this.customers;
	}

	selectCustomer(row: any) {
		this.clearForm();
		this.omsService.setPrintedOrder();
		this.customer_selected.emit(row);
		this.ready = false;
	}

	getCustomerInfo(customer: any): string {
		const stripSlashes = (str: string): string => str ? str.replace(/^\/+|\/+$/g, '') : '';

		let customerInfo = `
	  <strong>${stripSlashes(customer.debtorno)}.${stripSlashes(customer.branchcode)}</strong>
	`;

		if (customer.name) {
			customerInfo += `<br />${stripSlashes(customer.name)}`;
		}

		if (customer.phoneno) {
			customerInfo += `<br />${stripSlashes(customer.phoneno)}`;
		}

		if (this.config.showsalesman && customer.salesmanname) {
			customerInfo += `<br /><span class="mat-caption">SLSM: <b>${stripSlashes(customer.salesmanname)}</b></span>`;
		}

		return customerInfo;
	}
}
