<div class="row">
	<div class="ml-auto mr-0">
	<form [formGroup]="searchForm">
	<div class="row m-0 p-0">
		<div class="col-md-3 mt-3">
			<mat-form-field appearance="outline">
				<mat-label>Added Between</mat-label>
				<mat-date-range-input [rangePicker]="picker">
					<input matStartDate formControlName="fromDateCtrl" placeholder="Start date">
					<input matEndDate formControlName="toDateCtrl" placeholder="End date">
				</mat-date-range-input>
				<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-date-range-picker #picker></mat-date-range-picker>
			</mat-form-field>
		</div>	
		<div class="col-md-2 mt-3">
			<mat-form-field appearance="outline" >
				<mat-label>Brand</mat-label>
				<mat-select formControlName="brand" multiple appSelectAll>
					<mat-option *ngFor="let k of brands" [value]="k.manufacturer_id">{{k.name}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-2 mt-3">
			<mat-form-field appearance="outline" >
				<mat-label>Category</mat-label>
				<mat-select formControlName="category" multiple appSelectAll>
					<mat-option *ngFor="let k of categories" [value]="k.categoryid">{{k.categorydescription}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-2 mt-3">
			<mat-form-field appearance="outline" >
				<mat-label>Product Line</mat-label>
				<mat-select formControlName="plines" multiple appSelectAll>
					<mat-option *ngFor="let k of plines" [value]="k.line_field">{{k.line_description}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>	
		<div class="col-md-2 mt-3">
			<mat-form-field appearance="outline">
				<mat-label>Quantity Status</mat-label>
				<mat-select formControlName="quantity">
					<mat-option value="0"> All Items </mat-option>
					<mat-option value="1"> Only Items w/ QOH </mat-option>
					<mat-option  value="2"> Only Out of Stock </mat-option>
					<mat-option  value="3"> Negative Stock </mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-2 mt-3">
			<mat-form-field appearance="outline" >
				<mat-label>Item Status</mat-label>
				<mat-select formControlName="status">
					<mat-option value="0"> All Items </mat-option>
					<mat-option value="1"> Active </mat-option>
					<mat-option value="2"> Active but Web Disabled </mat-option>
					<mat-option  value="3"> DWO </mat-option>
					<mat-option  value="4"> Discontinued </mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-2 mt-3">
			<mat-form-field appearance="outline">
				<mat-label>Include Images?</mat-label>
				<mat-select formControlName="images">
					<mat-option value="0"> No </mat-option>
					<mat-option  value="1"> Yes </mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-2 mt-3">
			<mat-form-field appearance="outline" >
				<mat-label>Include Prices?</mat-label>
				<mat-select formControlName="showprice">
					<mat-option value="0"> No </mat-option>
					<mat-option  value="1"> Yes </mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-3 mt-3">
			<span *ngIf="sending">
				<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
					<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
				</svg>
			</span>
			<button mat-raised-button class="btn-success btn-height" (click)="$event.preventDefault();exportXls()" *ngIf="data">
				<i class="fa fa-file-excel-o"></i> XLS
			</button>
			&nbsp;
			<button mat-raised-button class="btn-danger btn-height" (click)="$event.preventDefault();exportPdf()" *ngIf="data">
				<i class="fa fa-file-pdf-o"></i> PDF
			</button>
			&nbsp;
			<button mat-raised-button color="accent" class="mt-2" (click)="getItemExport()" *ngIf="!sending">
				<mat-icon>refresh</mat-icon>
			</button>
		</div>
	</div>
	</form>
	</div>
</div>
<div class="table table-responsive table-fixed ">
	<mat-paginator [pageSizeOptions]="items_per_page" [pageSize]="pagesizedefault" *ngIf="data" showFirstLastButtons aria-label="select page"></mat-paginator>
	<div class="table" *ngIf="data">
				<table class="table table-sm table-hover">
					<thead>
						<tr>
							<th>Item#</th>
							<th>Category</th>
							<th>ProductLine</th>
							<th>Description</th>
							<th>MaterialCost</th>
							<th>Discontinued</th>
							<th>Barcode</th>
							<th *ngIf="showimage">Image</th>
							<th>Enabled</th>
							<th>DWO</th>
							<th>Sort</th>
							<th>Sort 2</th>
							<th>Created</th>
							<th *ngFor="let price of pricenames;">{{price.sales_type}}</th>
							
					</thead>
					<tbody>
						<ng-container *ngFor="let o of dataObs | async;let i = index">
							<tr>
								<td>
									<a [routerLink]="'/inventory/view/'+o.stockid">{{ o.stockid }}</a>
								</td>
								<td>
									{{o.categoryid}}
								</td>
								<td>{{o.lineid}}</td>
								<td>{{o.description}}</td>	
								<td>{{o.materialcost}}</td>
								<td>{{ o.discontinued }}</td>
								<td>{{ o.barcode }}</td>
								<td *ngIf="showimage">{{ o.image}}</td>
								<td>{{ o.enabled }}</td>
								<td>{{ o.dwo }}</td>
								<td>{{ o.sort}}</td>
								<td>{{ o.sort2 }}</td>
								<td>{{ o.date_created | date:'MM/d/yy' }}</td>
								<td *ngFor="let price of pricenames;">{{ (o.prices[price.sales_type] ? o.prices[price.sales_type] : 0) | currency}}</td>
							</tr>
						</ng-container>						
					</tbody>
				</table>
			</div>
</div>
