<div class="main-content">
	<div class="col-md-12">
		<div class="container-fluid tp-box" [@flipState]="flip">
			<div class="row tp-box__side tp-box__front" id="frontside" [ngClass]="flip == 'inactive' ? '' : 'd-none'">

				<div [ngClass]="{'col-lg-4 col-md-4 col-sm-6': viewing_title!='All Customers', 'col-lg-12 col-md-12 col-sm-12': viewing_title=='All Customers'}">
					<app-all-customers (viewData)="toggleFlipTest($event)"></app-all-customers>
				</div>
				<div [ngClass]="{'col-lg-4 col-md-4 col-sm-6': viewing_title!='Customers Online', 'col-lg-12 col-md-12 col-sm-12': viewing_title=='Customers Online'}">
					<app-customers-online (viewData)="toggleFlipTest($event)"></app-customers-online>
				</div>

				<div class="" [ngClass]="{'col-lg-4 col-md-4 col-sm-6': viewing_title!='Customer Carts', 'col-lg-12 col-md-12 col-sm-12': viewing_title=='Customer Carts'}" @grow>
					<app-customer-carts (viewData)="toggleFlipTest($event)"></app-customer-carts>
				</div>
				<div class="" [ngClass]="{'col-lg-4 col-md-4 col-sm-6': viewing_title!='agedc', 'col-lg-12 col-md-12 col-sm-12': viewing_title=='agedc'}" @grow>
					<app-over-due-customers (viewData)="toggleFlipTest($event)"></app-over-due-customers>
				</div>
				<div class="" [ngClass]="{'col-lg-4 col-md-4 col-sm-6': viewing_title!='Inactive Customers', 'col-lg-12 col-md-12 col-sm-12': viewing_title=='Inactive Customers'}" @grow>
					<app-customer-inactive (viewData)="toggleFlipTest($event)"></app-customer-inactive>
				</div>
				<div class="" [ngClass]="{'col-lg-4 col-md-4 col-sm-6': viewing_title!='Web Disabled Customers', 'col-lg-12 col-md-12 col-sm-12': viewing_title=='Web Disabled Customers'}" @grow>
					<app-disabled-customers (viewData)="toggleFlipTest($event)"></app-disabled-customers>
				</div>
				<div class="" [ngClass]="{'col-lg-4 col-md-4 col-sm-6': viewing_title!='OOB Customers', 'col-lg-12 col-md-12 col-sm-12': viewing_title=='OOB Customers'}" @grow>
					<app-oob-customers (viewData)="toggleFlipTest($event)"></app-oob-customers>
				</div>

				<div class="" [ngClass]="{'col-lg-4 col-md-4 col-sm-6': viewing_title!='Open Quotes', 'col-lg-12 col-md-12 col-sm-12': viewing_title=='Open Quotes'}" @grow *ngIf="config.env.package == 'beauty' && !user.user.issalesman || config.env.package == 'tires'">
					<app-customer-open-quotes (viewData)="toggleFlipTest($event)"></app-customer-open-quotes>
				</div>
				<div class="" [ngClass]="{'col-lg-4 col-md-4 col-sm-6': viewing_title!='Matching Docs', 'col-lg-12 col-md-12 col-sm-12': viewing_title=='Matching Docs'}" @grow *ngIf="editsallowed.editar && !user.user.issalesman">
					<app-matching-docs (viewData)="toggleFlipTest($event)"></app-matching-docs>
				</div>
				<ng-container *ngIf="config.env.package == 'tires'">
					<div class="" [ngClass]="{'col-lg-4 col-md-4 col-sm-6': viewing_title!='Matching National Account Receivables', 'col-lg-12 col-md-12 col-sm-12': viewing_title=='Matching National Account Receivabless'}" @grow>
						<app-matching-doc-customer-type (viewData)="toggleFlipTest($event)"></app-matching-doc-customer-type>
					</div>
				</ng-container>


				<div class="row tp-box__side tp-box__back">
					<div class="card">
						<div class="card-header card-header-{{ color }}-3">
							<h4 class="card-title "> <i class="material-icons pointer" (click)="toggleFlip('')">arrow_left</i> {{ viewing }} </h4>
							<p class="card-category"></p>
						</div>
						<div class="card-body">
							<div class="results mt-2 mb-2">
								<ng-container [ngSwitch]="viewing">
									<app-customer-carts *ngSwitchCase="'Customer Carts'" [data]="currentcarts"></app-customer-carts>
									<app-customer-carts *ngSwitchCase="'Customer Carts One'" [data]="cartagebuckets[2]"></app-customer-carts>
									<app-customer-carts *ngSwitchCase="'Customer Carts Two'" [data]="cartagebuckets[3]"></app-customer-carts>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
