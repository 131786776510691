import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { environment } from '../../../environments/environment';
import { AppConfig } from '../../app.config';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';


@Component({
	selector: 'app-config-switcher',
	templateUrl: './config-switcher.component.html',
	styleUrls: ['./config-switcher.component.scss']
})
export class ConfigSwitcherComponent implements OnInit, OnDestroy {
	availableConfigs: any = [];
	currentConfig: any = '';
	selectedConfig: any = ''
	user: any = [];
	@Output() reload = new EventEmitter < boolean > ();
	private subscriptions: Subscription = new Subscription();

	constructor(
		private globalsService: GlobalsService,
		private globalSearchService: GlobalSearchService,
		private appConfig: AppConfig,
		private configService: ConfigService
	) {
		this.globalSearchService.user.subscribe(r => {
			this.user = r;
		});

	}

	ngOnInit(): void {
		this.globalSearchService.user.subscribe(r => {
			this.user = r;
		});

		this.globalSearchService.configsubscription.subscribe(r => {

			this.currentConfig = r?.env?.client ?? environment.name;
			const configSubscription = this.configService.loadAvailableConfigs().subscribe(
				(configs) => {
					// Filter configs based on user's access level
					this.availableConfigs = configs.filter((config: any) =>
						parseInt(this.user.user.fullaccess) >= config.allowedAccessLevel
					);
				},
				error => {
					console.error('Error loading available configs', error);
				}
			);

			this.subscriptions.add(configSubscription);

		});
	}


	onChangeConfig(newConfig: string): void {
		// Update currentConfig with the selected config key
		this.currentConfig = newConfig;

		const loadConfigSubscription = this.configService.loadConfig(newConfig).subscribe(
			r => {
				this.globalSearchService.setConfig(r);
				this.globalsService.updateConfigFile(r);
				this.globalSearchService.reload();
				this.reload.emit(true);
			},
			error => {
				console.error('Error loading configuration', error);
				// Optionally handle fallback logic here
			}
		);

		this.subscriptions.add(loadConfigSubscription);
	}
	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}