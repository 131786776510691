import { Injectable } from '@angular/core';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { SocketService } from './socket.service';
import { GlobalsService } from './globals.service';
import * as FileSaver from 'file-saver';
import { ValidationErrors } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { jsPDF } from 'jspdf';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { environment } from '../../environments/environment';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

declare let $: any;

@Injectable({
	providedIn: 'root'
})
//using as globals
export class GlobalSearchService {
	config: any = [];
	bound_user: any = [];
	filteredItems: any = [];
	color = 'blue';
	collapseMini: any = false;

	public searchTerm: BehaviorSubject < string > = new BehaviorSubject < string > (null);

	public dispatchNote: BehaviorSubject < string > = new BehaviorSubject < string > (null);
	public invoiceNote: BehaviorSubject < string > = new BehaviorSubject < string > (null);
	public poEntered: BehaviorSubject < string > = new BehaviorSubject < string > (null);

	public lastCustomer: BehaviorSubject < any > = new BehaviorSubject < any > (null);

	public empCode: BehaviorSubject < any > = new BehaviorSubject < any > (null);


	public lastCustomerTrans: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public lastItem: BehaviorSubject < any > = new BehaviorSubject < any > (null);

	public selectedlocation: BehaviorSubject < any > = new BehaviorSubject < any > (null);

	public datasets: BehaviorSubject < any > = new BehaviorSubject < any > (null);

	public discountsAllowed: BehaviorSubject < string > = new BehaviorSubject < string > (null);

	public user: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public mesagesallowed: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public userData: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public locationData: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public orderData: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public searchResults: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public customerData: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public vendorData: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public openOrderData: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public messages: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public pickingOrderData: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public allItems: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public socket: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public currentOrderList: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public orderSearch: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public itemSearch: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public orderResults: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public package: BehaviorSubject < any > = new BehaviorSubject < any > (null);

	//globals
	public taxgroups: BehaviorSubject < any > = new BehaviorSubject < any > (null);

	public reloadorder: BehaviorSubject < any > = new BehaviorSubject < any > (null);

	public salespeople: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public shipvia: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public terms: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public zones: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public holdreasons: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public currencies: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public salestypes: BehaviorSubject < any > = new BehaviorSubject < any > (null);

	public all_system_types: BehaviorSubject < any > = new BehaviorSubject < any > (null);

	public locations: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public areas: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public customertypes: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public payment_methods: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public bankaccounts: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public company: BehaviorSubject < any > = new BehaviorSubject < any > (null);
	public configsubscription: BehaviorSubject < any > = new BehaviorSubject < any > (null);

	public currentsetuser = false;
	public localStorageKey: string = 'v8ConfigOverride';
	private preOrder: any = false;
	
	constructor(private _configSvc: RuntimeConfigLoaderService, private socketService: SocketService, private globalsService: GlobalsService) {

		this.config = this.loadConfigFromLocalStorage();
		this.configsubscription.next(this.config);
		this.reloadorder.next(false);
		//only functioning while logged in user value.
		if (this.config) {
			this.discountsAllowed.next(this.config.discounts);
		}
		//disabling for now. looks like its causing extra socket calls
		// this.socketService.subscribeToGroupUpdate((err, data) => {
		// 	//use a switch to update sections?
		// 	if (data) {
		// 		this.socketService.getUserUpdate(this.user.value).subscribe(
		// 			async (result: any) => {
		// 				if (result.pass) {
		// 					//force update user settings
		// 					this.userData.next(result.user);
		// 					this.user.next(result);
		// 					this.currentsetuser = result;
		// 					localStorage.setItem('user', JSON.stringify(result))
		// 				}
		// 			});
		// 	}
		// });

		// Handle user changes and location loading
		this.user.subscribe(changed => {
			if (changed && changed.pass && this.currentsetuser !== changed) {
				this.currentsetuser = changed;
				this.globalsService.getUserLocations().subscribe((results: any) => {});
			}
		});
		//set initial globals
		forkJoin({
			taxes: this.globalsService.getTaxes(),
			salespeople: this.globalsService.getSalesman(),
			shipVia: this.globalsService.getShipVia(),
			terms: this.globalsService.getTerms(),
			holdReasons: this.globalsService.getHoldReasons(),
			defaultZones: this.globalsService.getDefaultZones(),
			userLocations: this.globalsService.getUserLocations(),
			currencies: this.globalsService.getCurrencies(),
			salesTypes: this.globalsService.getSalesTypes(),
			areas: this.globalsService.getAreas(),
			customerTypes: this.globalsService.getCustomerTypes(),
			systemTypes: this.globalsService.getTypes(),
			paymentMethods: this.globalsService.getPaymentMethods(),
			bankAccounts: this.globalsService.getBankAccounts(),
			company: this.globalsService.getCompany()
		}).subscribe((results) => {
			// Process results when all observables are complete
			this.taxgroups.next(results.taxes);
			this.salespeople.next(results.salespeople);
			this.shipvia.next(results.shipVia);
			this.terms.next(results.terms);
			this.holdreasons.next(results.holdReasons);
			this.zones.next(results.defaultZones);
			this.locations.next(results.userLocations);
			this.currencies.next(results.currencies);
			this.salestypes.next(results.salesTypes);
			this.areas.next(results.areas);
			this.customertypes.next(results.customerTypes);
			this.all_system_types.next(results.systemTypes);
			this.payment_methods.next(results.paymentMethods);
			this.bankaccounts.next(results.bankAccounts);
			this.company.next(results.company);
		});
	}

	setPreOrder(data: any) {
		this.preOrder = data;
	}

	getPreOrder() {
		return this.preOrder;
	}
 
	private loadConfigFromLocalStorage(): any {
		const localConfig = localStorage.getItem(this.localStorageKey);

		if (localConfig) {
			try {
				const config = JSON.parse(localConfig);
				if (this.isValidConfig(config)) {
					//console.log('Using local storage config:', config);
					return config;
				} else {
					//console.warn('Local storage config is invalid. Falling back to default config.');
					return this.getDefaultConfig();
				}
			} catch (error) {
				//console.error('Failed to parse local storage config. Falling back to default config:', error);
				return this.getDefaultConfig();
			}
		} else {
			//console.warn('No local storage config found. Using default config.');
			return this.getDefaultConfig();
		}
	}

	private isValidConfig(config: any): boolean {
		if (!config) {
			//'Configuration is null or undefined.');
			return false;
		}
		if (typeof config.apiCredentials.clientId !== 'string') {
			//'Invalid or missing "clientId":', config.apiCredentials.clientId);
			return false;
		}
		if (!config.env || typeof config.env.package !== 'string') {
			//'Invalid or missing "env.package":', config.env);
			return false;
		}
		return true;
	}

	private getDefaultConfig(): any {
		this.config = this._configSvc.getConfig();
		return this.config;
	}

	updatePaymentMethods() {
		this.globalsService.getPaymentMethods().subscribe(async (results: any) => {
			this.payment_methods.next(results);
		});
	}

	getConfig() {
		return this.config;
	}

	reload() {

		this.discountsAllowed.next(this.config.discounts);
		this.socketService.subscribeToGroupUpdate((err, data) => {
			//use a switch to update sections?
			if (data) {
				this.socketService.getUserUpdate(this.user.value).subscribe(
					async (result: any) => {
						if (result.pass) {
							//force update user settings
							this.userData.next(result.user);
							this.user.next(result);
							localStorage.setItem('user', JSON.stringify(result))
						}
					});
			}
		});

		//set initial globals
		this.globalsService.getTaxes().subscribe(async (results: any) => {
			this.taxgroups.next(results);
		});

		this.globalsService.getSalesman().subscribe(async (results: any) => {
			this.salespeople.next(results)
		});

		this.globalsService.getShipVia().subscribe(async (results: any) => {
			this.shipvia.next(results)
		});

		this.globalsService.getTerms().subscribe(async (results: any) => {
			this.terms.next(results)
		});

		this.globalsService.getHoldReasons().subscribe(async (results: any) => {
			this.holdreasons.next(results)
		});

		//no async
		this.globalsService.getDefaultZones().subscribe((results: any) => {
			this.zones.next(results)
		});

		//no async
		this.globalsService.getUserLocations().subscribe((results: any) => {
			this.locations.next(results)
		});

		this.globalsService.getCurrencies().subscribe(async (results: any) => {
			this.currencies.next(results);
		});

		this.globalsService.getSalesTypes().subscribe(async (results: any) => {
			this.salestypes.next(results);
		});

		this.globalsService.getAreas().subscribe(async (results: any) => {
			this.areas.next(results);
		});

		this.globalsService.getCustomerTypes().subscribe(async (results: any) => {
			this.customertypes.next(results);
		});

		this.globalsService.getTypes().subscribe(async (results: any) => {
			this.all_system_types.next(results);
		});

		this.globalsService.getPaymentMethods().subscribe(async (results: any) => {
			this.payment_methods.next(results);
		});

		this.globalsService.getBankAccounts().subscribe(async (results: any) => {
			this.bankaccounts.next(results);
		});

		this.globalsService.getCompany().subscribe(async (results: any) => {
			this.company.next(results);
		});

	}

	reloadLocations() {
		this.globalsService.getUserLocations().subscribe((results: any) => {
			this.locations.next(results)
		});
	}

	reloadingOrder(yesno: boolean) {
		this.reloadorder.next(yesno);
	}

	getDataSets() {
		this.globalsService.getDataSets().subscribe(async (results: any) => {
			this.datasets.next(results);
		});
	}

	hideSideBar() {
		const body = document.getElementsByTagName('body')[0];
		body.classList.remove('sidebar-mini');

		if (!body.classList.contains('sidebar-mini')) {
			this.collapseMini = true;
			body.classList.add('sidebar-mini');
			this.setNavState(this.collapseMini);
		}
	}

	getNavState() {
		return JSON.parse(localStorage.getItem('navstate'))
	}
	setNavState(inbound) {
		return localStorage.setItem('navstate', inbound);
	}


	public showNotification(message, type, from, align) {
		//  const type = ['','info','success','warning','danger'];
		// const color = Math.floor((Math.random() * 4) + 1);

		$.notify({
			icon: 'notifications',
			message: message

		}, {
			type: type,
			delay: 1000,
			z_index: 10009,
			timer: 1500,
			placement: {
				from: from,
				align: align
			},
			template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
				'<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
				'<i class="material-icons" data-notify="icon">notifications</i> ' +
				'<span data-notify="title">{1}</span> ' +
				'<span data-notify="message">{2}</span>' +
				'<div class="progress" data-notify="progressbar">' +
				'<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
				'</div>' +
				'<a href="{3}" target="{4}" data-notify="url"></a>' +
				'</div>'
		});
	}

	public filterItem(input: any[], term: any, keys: string): any[] {
		if (!input || !term || !keys) {
			return input; // Return original if nothing to search
		}

		const regex = new RegExp(term, 'gi'); // Compile regex once
		const keyArray = keys.split(','); // Pre-split keys

		return input.filter(item =>
			keyArray.some(key =>
				item[key] && regex.test(item[key]) // Check for matching terms
			)
		);
	}



	public exportJsonToExcel(data: any, fileName: any) {
		import('xlsx').then(xlsx => {
			const worksheet = xlsx.utils.json_to_sheet(data);
			const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
			const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
			this.saveAsExcelFile(excelBuffer, fileName);
		});
	}

	public setConfig(config: any) {
		this.config = config;
		this.configsubscription.next(this.config);
	}

	public setColor(value: any): string {
		localStorage.setItem('color', JSON.stringify(value))
		this.color = value;
		return value;
	}

	public getColor(): string {
		let color = JSON.parse(localStorage.getItem('color'));
		if (color === null) {
			color = 'blue';
			localStorage.setItem('color', JSON.stringify('blue'))
		}
		this.color = color;
		return color;
	}
	//session miss behaing switch to storage
	public getSessionItem(name: string, ) {
		name = 'app'
		//JSON.parse(
		const check = localStorage.getItem(name);
		return check;
	}

	public setPackage(name: string, value: any) {
		const check = localStorage.getItem(name);
		if (check === null) {
			localStorage.setItem(name, value)
		}
		return value;
	}

	public setLocalItem(name: string, value: any) {
		const check = JSON.parse(localStorage.getItem(name));
		if (check === null) {
			localStorage.setItem(name, JSON.stringify(value))
		}
		return value;
	}

	public getLocalItem(name: string, ) {
		const check = JSON.parse(localStorage.getItem(name));
		return check;
	}

	exportPDF(element: any) {

		const html = htmlToPdfmake(document.getElementById(element).innerHTML, {});

		const docDefinition = {
			pageSize: 'Letter',
			defaultStyle: {
				fontSize: 8,
			},
			content: html,
			styles: {
				table: {
					fontSize: 12,
				},
				anotherStyle: {
					italics: true,
					alignment: 'right'
				}
			},
			pageMargins: [40, 60, 40, 60],
		};

		//const documentDefinition = { content: html };
		pdfMake.createPdf(docDefinition).download();
	}

	saveAsExcelFile(buffer: any, fileName: string): void {
		const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		const EXCEL_EXTENSION = '.xlsx';
		const data: Blob = new Blob([buffer], {
			type: EXCEL_TYPE
		});
		FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
	}


	exportCSV(filename: string, rows: object[], headers ? : string[]): void {
		if (!rows || !rows.length) {
			return;
		}
		const separator = ',';

		const keys: string[] = Object.keys(rows[0]);

		let columHearders: string[];

		if (headers) {
			columHearders = headers;
		} else {
			columHearders = keys;
		}

		const csvContent =
			columHearders.join(separator) +
			'\n' +
			rows.map(row => {
				return keys.map(k => {
					let cell = row[k] === null || row[k] === undefined ? '' : row[k];

					cell = cell instanceof Date ?
						cell.toLocaleString() :
						cell.toString().replace(/"/g, '""');

					if (cell.search(/("|,|\n)/g) >= 0) {
						cell = `"${cell}"`;
					}
					return cell;
				}).join(separator);
			}).join('\n');

		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		if (navigator.msSaveBlob) { // In case of IE 10+
			navigator.msSaveBlob(blob, filename);
		} else {
			const link = document.createElement('a');
			if (link.download !== undefined) {
				// Browsers that support HTML5 download attribute
				const url = URL.createObjectURL(blob);
				link.setAttribute('href', url);
				link.setAttribute('download', filename);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	}


	base64encode(str) {
		const encode = encodeURIComponent(str).replace(/%([a-f0-9]{2})/gi, (m, $1) => String.fromCharCode(parseInt($1, 16)))
		return btoa(encode)
	}

	base64decode(str) {
		const decode = atob(str).replace(/[\x80-\uffff]/g, (m) => `%${m.charCodeAt(0).toString(16).padStart(2, '0')}`)
		return decodeURIComponent(decode)
	}

	downloadXls(base64String, fileName) {
		const source = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;
		const link = document.createElement('a');
		link.href = source;
		link.download = `${fileName}`
		link.click();
	}


	downloadPdf(base64String, fileName) {
		const source = `data:application/pdf;base64,${base64String}`;
		const link = document.createElement('a');
		link.href = source;
		link.download = `${fileName}`
		link.click();
	}

	isEmail(search: string): boolean {
		let serchfind: boolean;
		const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		serchfind = regexp.test(search);
		return serchfind
	}

	loadData() {

	}

	randomString(length: any, chars: any) {
		let result = '';
		for (let i = length; i > 0; --i) { result += chars[Math.floor(Math.random() * chars.length)]; }
		return result;
	}

	blurInputElement(inputElement: any) {
		if (inputElement && inputElement.nativeElement) {
			// For ElementRef
			inputElement.nativeElement.blur();
		} else if (inputElement && inputElement._elementRef && inputElement._elementRef.nativeElement) {
			// For MatFormField
			inputElement._elementRef.nativeElement.blur();
		} else if (inputElement) {
			// For other types of input elements
			inputElement.blur();
		}
	}

	getFormValidationErrors(form: any) {
		Object.keys(form.controls).forEach((key, index) => {
			const controlErrors: ValidationErrors = form.get(key).errors;
			if (controlErrors != null) {
				Object.keys(controlErrors).forEach(keyError => {
					//this.customerForm.controls[index].markAsTouched()
					form.markAllAsTouched();
					form.markAsUntouched()

					if (key == 'reference') {
						key = 'PO Number';
					}

					if (key == 'discountAmount') {
						key = 'Invalid Discount';
						keyError = '';
					}

					if (key == 'edireference') {
						key = 'Print Statement';
					}

					this.showNotification(key + ' ' + keyError, 'danger', 'bottom', 'right')
				});
			}
		});
	}

	updateLocations() {
		this.globalsService.getAllLocations().subscribe((results: any) => {
			this.locations.next(results)
		});
	}
}