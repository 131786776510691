<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card tp-box">
					<div class="card-header card-header-{{ color }}-6">
						<div class="row m-0 p-0">
							<div class="col-8">
								<h4 class="card-title bold">
									<mat-icon (click)="back()">arrow_left</mat-icon>
									Purchase Order Created
								</h4>
							</div>
						</div>
					</div>
					<div class="card-body" >

						<ng-container>
							<div class="card-body">
								<div class="row">
									<div class="col-12 text-center" *ngIf="summary.poheader">

										<h4 class="bold">#{{ summary.poheader.orderno }} Created</h4>

										<div class="row">
											<div class="col-md-3 mt-2 mb-2 text-center">
												<a mat-raised-button color="warn" href="{{baselink}}{{summary.poheader.orderno}}">
													Print Purchase Order</a>
											</div>
											<div class="col-md-3 mt-2 mb-2 text-center">
												<a mat-raised-button color="warn" href="{{recdoc}}{{summary.poheader.orderno}}">
													Print Receiving Document</a>
											</div>
											<div class="col-md-3 mt-2 mb-2 text-center">
												<a [routerLink]="'/purchasing/receive/'+summary.poheader.orderno" mat-raised-button color="accent" class="ml-2 mr-2">Receive</a>
											</div>
											<div class="col-md-3 mt-2 mb-2 text-center">
												<button mat-raised-button color="success" [routerLink]="'/purchasing'">New Order</button>
											</div>
										</div>

									</div>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
