import { Injectable } from '@angular/core';
import { catchError , retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpHeaders,HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from '../services/globalsearchservice.service';
@Injectable({
	providedIn: 'root'
})


export class FiltersService {
	key = 'UET6DFMPAXW7BKCB';
	config: any;
	user : any = [];
	package: any = '';

	constructor(private http: HttpClient,private _configSvc: RuntimeConfigLoaderService, public globalSearchService: GlobalSearchService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})
		this.globalSearchService.user.subscribe( (result) => {
			this.user = result;
		});

	}

	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type':	'application/json',
		})
	};

	//AR

	public getFilters() {
		const method = 'filters/get';
		return this.http.get(this.setEndPoint(method));
	}

	public removeBranch(debtorno: any, branch: any) {
		const method = 'customers/removebranch';
		return this.http.post(this.setEndPoint(method), { debtorno: debtorno, branchcode: branch }, this.httpOptions).pipe(catchError(this.handleError(method, method))
		);
	}

	private setEndPoint(method: any) {
			 return this.getApi()+method+this.getApiKey()
	}

	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getApiKey() {
			 return '&key='+this.config.apiCredentials.clientId+'&userid='+this.user.user.userid;
	}

	public getKey() {
		return '&apikey='+this.key;
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			return error;
		};
	}

}
