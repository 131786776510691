import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { CustomerService } from '../../services/customer.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { PrintService } from '../../services/print.service';
import { MatPaginator , PageEvent} from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { interval, Subscription, Observable } from 'rxjs';

@Component({
	selector: 'app-customer-no-purchase',
	templateUrl: './customer-no-purchase.component.html',
	styleUrls: ['./customer-no-purchase.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotate3d(0, 1, 0, -179deg)',
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotateY(-179deg)'
			})),
			transition('active => inactive', animate('500ms ease-out')),
			transition('inactive => active', animate('500ms ease-in')),
			transition('inactive => orderlookup', animate('500ms ease-out')),
			transition('orderlookup => inactive', animate('500ms ease-in')),
		]),
		trigger('openClose', [
			state(':enter', style({ height: '*' })),
			state(':leave', style({ height: '0px' })),
			transition('false <=> true', animate(500))
		]),
		trigger('filterAnimation', [
			transition(':enter, * => 0, * => -1', []),
			transition(':increment', [
				query(':enter', [
					style({ opacity: 0, width: 0 }),
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 1, width: '*' })),
					]),
				], { optional: true })
			]),
			transition(':decrement', [
				query(':leave', [
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 0, width: 0 })),
					]),
				])
			]),
		]),
		trigger('grow', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: '0', width: '0' }),
				animate(200, style({ height: '*', width: '*' })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate(100, style({ height: 0, width: 0 })),
				animate('100ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class CustomerNoPurchaseComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() flip = 'inactive';
	@Output() viewData = new EventEmitter < any > ();
	@ViewChild('monthDetails') monthDetailsRef: ElementRef;
	@ViewChild('print_table') printtable: ElementRef;

	private sort: MatSort;
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.setDataSourceAttributes();
	}

	@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
		this.paginator = mp;
		this.setDataSourceAttributes();
	}

	setDataSourceAttributes() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	dataObs: Observable < any > ;
	items_per_page = [25, 50, 100, 500];
	pagesizedefault = 100;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatPaginator) paginatorNop: MatPaginator;
	dataSource: MatTableDataSource < any > ;

	items_per_pageNop = [25, 50, 100, 500];
	pagesizedefaultNop = 50;

	viewing: any = false;
	hideshowunits = new UntypedFormControl(true);
	hideshowsales = new UntypedFormControl(true);
	hideshowcost = new UntypedFormControl(false);
	defaultlocation = new UntypedFormControl('');
	data: any = false;
	sending: any = false;
	days: any = 60;
	daysmax: any = 365;
	title: string = 'No Purchases ' + this.days + '/' + this.daysmax + ' Days';
	total: number = 0;
	brand_details: any = false;
	datasub: any = false;
	pageindex = 0;


	constructor(private _changeDetectorRef: ChangeDetectorRef, private printService: PrintService, public globalSearchService: GlobalSearchService, private customerService: CustomerService, private modalService: NgbModal) {}
	user: any = false;
	ref_location: any = false;
	filename: string = 'No Purchases ' + this.days + '/' + this.daysmax + ' Days';
	showFirstLastButtons = true;
	showPageSizeOptions = true;
	hidePageSize = false;

	ngOnInit(): void {
		this.setPagination([]);
		//this.setPaginationNop([]);
		this.loadData();

		this.globalSearchService.user.subscribe(results => {
			//only run if user is definied
			if (this.user) {
				if (results.user.defaultlocation.loccode != this.ref_location) {
					this.defaultlocation.setValue(this.user.user.defaultlocation.loccode);
					this.ref_location = false;
				}
			}

			this.user = results;
			if (!this.ref_location) {
				this.ref_location = this.user.user.defaultlocation.loccode;
			}
		});
	}

	ngAfterViewInit() {

	}

	loadDataOnly(data) {
		this.customerService.getNoPurchaseCustomers(data).subscribe(async (results: any) => {
			this.sending = false;
			this.data = results;
			this.setPagination(results);
		//	this.setPaginationNop(results);
		});
	}

	loadData() {
		const data = {
			numberofdays: this.days,
			daysmax: this.daysmax,
		 	pageindex: this.pageindex,
		 	pagesize: this.pagesizedefaultNop,
		 };
		this.sending = true;
		this.datasub = this.customerService.getNoPurchaseCustomersCount(data).subscribe(async (r: any) => {
			if (r) {
				if(!this.total) {
					this.total = parseFloat(r.total);
				}

				this.loadDataOnly(data);

			}

		});
	}

	getBrandSales(debtorno: any, brand: any) {
		const today = new Date();
		const priorDate = new Date(new Date().setDate(today.getDate() - this.daysmax));
		const data = { debtorno: debtorno, brands: [brand], from: 'all' };

		this.customerService.searchCustomerHistory(data).subscribe(async (results: any) => {
			this.sending = false;
			this.brand_details = results;
			this.modalService.open(this.monthDetailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
		});
	}

	getCostTotalYear(year: any) {
		const value = year.cost.reduce(function(accumulator: number, items: any) {
			const addon = parseFloat(items.value);
			return accumulator + addon;
		}, 0);
		return value;
	}

	getSaleTotalYear(year: any) {
		const value = year.sales.reduce(function(accumulator: number, items: any) {
			const addon = parseFloat(items.value);
			return accumulator + addon;
		}, 0);
		return value;
	}

	getQtyTotalYear(year: any) {
		const value = year.qty.reduce(function(accumulator: number, items: any) {
			const addon = parseFloat(items.value);
			return accumulator + addon;
		}, 0);
		return value;
	}

	updateDays(event: any) {
		this.days = event.target.value;
	}

	updateDaysMax(event: any) {
		this.daysmax = event.target.value;
	}

	toggleFlip(data: any, title: string) {
		if (!data) {
			this.flip = 'inactive';
		} else {
			this.flip = (this.flip == 'active') ? 'inactive' : 'active';
		}

		if (this.flip == 'active') {
			this.viewing = data;
			this.title = title;
			const dashboard_data = { data: data, title: this.title };
			this.viewData.emit(dashboard_data);
		} else {
			this.viewData.emit(false);
		}
	}

	exportPdf() {
		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = { content: encoded, filename: this.filename, landscape: true };

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	exportXls() {
		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = { content: encoded, filename: this.filename };

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

	ngOnDestroy() {
		if (this.datasub) {
			this.datasub.unsubscribe();
		}
	}

	setPagination(tableData: any[]) {
		this.dataSource = new MatTableDataSource < any > (tableData);
		this._changeDetectorRef.detectChanges();
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
		this.dataObs = this.dataSource.connect();
	}

	onPageChange(event: PageEvent) {
		this.pageindex = event.pageIndex;

		const data = {
			numberofdays: this.days,
			daysmax: this.daysmax,
			pageindex: event.pageIndex,
			pagesize: this.pagesizedefaultNop,
		 };

		this.loadDataOnly(data);
	}
}
