import { Component, OnInit, OnChanges } from '@angular/core';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
	selector: 'app-price-level-pricing',
	templateUrl: './price-level-pricing.component.html',
	styleUrls: ['./price-level-pricing.component.scss']
})
export class PriceLevelPricingComponent implements OnInit, OnChanges {
	priceLevelForm: FormGroup;
	priceLevels: any[] = [];
	categories: any[] = [];
	allResults: any[] = []; // Store all results initially fetched
	results: any[] = []; // Store the filtered results
	showNewPriceLevelInput = false;

	newPriceLevelName = '';
	newPriceLevelId = ''; // New property for price level ID

	constructor(
		private inventoryService: InventoryService,
		private globalSearchService: GlobalSearchService,
		private fb: FormBuilder
	) {
		this.priceLevelForm = this.fb.group({
			pricelevels: [
				[]
			],
			categories: [
				[]
			]
		});
	}

	ngOnInit(): void {
		this.getInitialData();
		this.onChanges();
	}

	getInitialData(): void {
		// Initialize price levels, categories, and results from the service
		this.inventoryService.getPriceLevels().subscribe((data) => {
			this.priceLevels = data.pricelevel;
			this.categories = data.categories;
			this.allResults = data.results; // Assuming results are fetched initially
			this.results = this.allResults; // Initially, no filter is applied
		});
	}

	ngOnChanges(): void {
		this.onChanges();
	}

	onChanges(): void {
		this.priceLevelForm.get('pricelevels')?.valueChanges.subscribe(() => {
			this.filterResults();
		});
		this.priceLevelForm.get('categories')?.valueChanges.subscribe(() => {
			this.filterResults();
		});
	}

	filterResults(): void {
		const selectedPriceLevels = this.priceLevelForm.get('pricelevels')?.value || [];
		const selectedCategories = this.priceLevelForm.get('categories')?.value || [];

		this.results = this.allResults.filter(result => {
			const matchesPriceLevel = selectedPriceLevels.length === 0 || selectedPriceLevels.includes(result.price_type);
			const matchesCategory = selectedCategories.length === 0 || selectedCategories.includes(result.category_id);
			return matchesPriceLevel && matchesCategory;
		});
	}

	toggleNewPriceLevel(): void {
		this.showNewPriceLevelInput = !this.showNewPriceLevelInput;
	}

	createNewPriceLevel(): void {
		if (this.newPriceLevelName.trim() === '' || this.newPriceLevelId.trim() === '') {
			this.globalSearchService.showNotification('Price level name and ID cannot be empty', 'error', 'bottom', 'right');
			return;
		}

		const payload = {
			name: this.newPriceLevelName,
			pricelevel: this.newPriceLevelId
		};

		this.inventoryService.createPriceLevel(payload).subscribe(() => {
			this.globalSearchService.showNotification('New price level created', 'success', 'bottom', 'right');
			this.getInitialData(); // Refresh the data to include the new price level
			this.showNewPriceLevelInput = false; // Hide the input after saving
			this.newPriceLevelName = ''; // Reset the input
			this.newPriceLevelId = ''; // Reset the input
		});
	}

	savePriceLevel(level: string, category: string, discount: string): void {

		const payload = {
			price_type: level,
			category_id: category,
			discountpercent: discount
		}

		this.inventoryService.savePriceLevel(payload)
			.subscribe(() => {
				// Update the UI to reflect the saved state
				this.showSuccessMessage(level, category, discount);
			});
	}

	showSuccessMessage(level: string, category: string, discount: string): void {
		this.globalSearchService.showNotification('Updated', 'success', 'bottom', 'right')
	}

	onPriceBlur(level: string, category: string, discount: string): void {
		this.savePriceLevel(level, category, discount);
	}
}