import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';

import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import { OrdersService } from '../../services/orders.service';
import { CustomerService } from '../../services/customer.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import * as FileSaver from 'file-saver';
import { GlobalsService } from 'app/services/globals.service';
import { PrintService } from 'app/services/print.service';

@Component({
	selector: 'app-customer-quotes',
	templateUrl: './customer-quotes.component.html',
	styleUrls: ['./customer-quotes.component.scss']
})
export class CustomerQuotesComponent implements OnInit {

	openquotes: any = [];
	openorders: any = [];
	customertrans: any = [];
	closeResult: any = [];
	selectedquote: any = false;
	selectedQuoteDetails: any = false;
	headercolumns: string[] = [
		'orderno',
		'deliverydate',
		'ponumber',
		'deliverto',
		'items',
		'phoneno',
		//	"debtorno",
		'totals',
		//'enteredby',
	];
	quotedata: any = [];
	sendemail = '';
	emailtrans: any = [];
	sending = false;
	config: any = [];

	@Input() customerdata;
	@Output() quote_loaded = new EventEmitter < boolean > ();
	@Output() quotesUpdated = new EventEmitter < boolean > ();
	@ViewChild('quoteDetails') detailsRef: ElementRef;
	@ViewChild('emailData') emailRef: ElementRef;

	@ViewChild(MatPaginator, { static: true, read: MatPaginator }) paginator: MatPaginator;
	private sort: MatSort;
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.setDataSourceAttributes();
	}

	setDataSourceAttributes() {
		this.openorders.paginator = this.paginator;
	}


	constructor(private modalService: NgbModal, public ordersService: OrdersService, public globalSearchService: GlobalSearchService, public globalsService: GlobalsService, public router: Router, public customerService: CustomerService, public printService: PrintService) {}

	ngOnInit(): void {

		this.config = this.globalsService.getApiConfig();

		this.ordersService.getQuotes(this.customerdata.customer.debtorno,
			this.customerdata.customer.branchcode).subscribe((results: any) => {
			this.openquotes = results;
			this.openorders = new MatTableDataSource(results);
			this.openorders.sort = this.sort;
			this.openorders.paginator = this.paginator;
		});
	}

	announceSortChange(event: any) {

	}

	getTotal(totals: any) {
		return totals.filter( r => {return r.code == 'total'})[0]?.text;
	}

	loadQuoteToCart(quote: any) {
		const service = this.ordersService;
		quote.details.forEach(function(item: any) {
			service.addToOrder(item, this.customerdata.debtorno, this.customerdata.branchcode).subscribe(async (results: any) => {
				this.ctl = true;
			});
		});
	}

	loadQuote(quote: any) {
		this.quote_loaded.emit(quote)
	}

	cancelQuote(orderno: any) {
		this.ordersService.cancelQuote(orderno).subscribe((results) => {
			this.customerService.getDebtorWithBranch(this.customerdata.debtorno, this.customerdata.branchcode).subscribe((results: any) => {
				this.quotesUpdated.emit(results)
			});

			this.ordersService.getQuotes(this.customerdata.customer.debtorno,
				this.customerdata.customer.branchcode).subscribe((results: any) => {
				this.openquotes = results;
				this.openorders = new MatTableDataSource(results);
				this.openorders.sort = this.sort;
				this.openorders.paginator = this.paginator;
			});

		});
	}

	viewQuote(quote: any) {

		this.ordersService.getQuote(quote.orderno).subscribe(r => {
			this.selectedQuoteDetails = r;
			this.modalService.open(this.detailsRef, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			});
		});


	}

	printQuote(quote: any){
		this.selectedQuoteDetails = quote

		const format: Object = { ariaLabelledBy: 'modal-title', size: 'xl' };

		//output PDF
		this.globalsService.getQuoteInvoice(quote.orderno, 'pdf').subscribe(result => {
			this.selectedQuoteDetails = result;
			this.modalService.open(this.detailsRef, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			});
		});
	}

	PDF() {
		this.sending = true;

		this.exportPDF();
	}

	exportPDF() {

		const encoded: string = this.globalSearchService.base64encode(this.detailsRef)

		const data = {
			content: encoded,
			filename: this.customerdata.debtorno + '-Quote.pdf'
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.sending = false;
		})
	}

	sendEmail(transaction: any) {
		this.sendemail = '';
		this.emailtrans = transaction;

		if(this.sendemail == ''){
			for(let x = 0; x < this.customerdata.branches.length; x++){
				if(this.customerdata.branches[x].branchcode == transaction.branchcode){
					this.sendemail = this.customerdata.branches[x].email;
				}
			}
		}

		this.modalService.dismissAll();

		this.modalService.open(this.emailRef, { ariaLabelledBy: 'modal-title', size: 'md' }).result.then(result => {}, reason => {}) // <-- email
	}

	pushEmail(){
		if(!this.globalSearchService.isEmail(this.sendemail)){
			this.sendemail = '';
			alert('Please Enter a Valid Email Address');
		} else {
			this.globalsService.emailQuoteInvoice(this.emailtrans.orderno, this.sendemail, 'email').subscribe(result => {
				this.selectedQuoteDetails = result;
				this.modalService.dismissAll();

			});


		}
	}

	openModal(content, item: any) {
		this.selectedquote = item
		this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	ngAfterViewInit() {


	}

}
