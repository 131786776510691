import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GlobalSearchService } from '../services/globalsearchservice.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InventoryComponent } from './inventory.component';
import { InventoryListComponent } from './inventory-list/inventory-list.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { MatChipsModule } from '@angular/material/chips';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { InventoryDashboardComponent } from './inventory-dashboard/inventory-dashboard.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { InventoryCreateComponent } from './inventory-create/inventory-create.component';
import { InventoryUpdateComponent } from './inventory-update/inventory-update.component';
import { InventoryViewComponent } from './inventory-view/inventory-view.component';
import { FocusMeDirective } from './directive/focus-me.directive';
import { HighlightDirective } from './directive/highlight.directive';
import { StockMasterComponent } from './inventory-create/stock-master/stock-master.component';
import { DatePipe } from '@angular/common';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { PurchdataComponent } from './inventory-create/purchdata/purchdata.component';
import { PricesComponent } from './inventory-create/prices/prices.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { InventorySearchComponent } from './inventory-search/inventory-search.component';
import { InventoryEditComponent } from './inventory-edit/inventory-edit.component';
import { InventoryEditSetupComponent } from './inventory-edit/inventory-edit-setup/inventory-edit-setup.component';
import { InventoryEditPricesComponent } from './inventory-edit/inventory-edit-prices/inventory-edit-prices.component';
import { InventoryEditStockComponent } from './inventory-edit/inventory-edit-stock/inventory-edit-stock.component';
import { InventoryEditExtraComponent } from './inventory-edit/inventory-edit-extra/inventory-edit-extra.component';
import { InventoryEditImagesComponent } from './inventory-edit/inventory-edit-images/inventory-edit-images.component';

import { InventoryRemoteNewItemsComponent } from './inventory-remote-new-items/inventory-remote-new-items.component';
import { SpecialPricingComponent } from './special-pricing/special-pricing.component';
import { InventoryValueComponent } from './inventory-value/inventory-value.component';

import { InventoryMovementsComponent } from './inventory-movements/inventory-movements.component';
import { InventoryAdjustmentComponent } from './inventory-adjustment/inventory-adjustment.component';

import { SharedModule } from '../shared/shared.module';
import { InventoryAttributesComponent } from './inventory-create/inventory-attributes/inventory-attributes.component';

// wysiwyg
import { EditorModule,  TINYMCE_SCRIPT_SRC  } from '@tinymce/tinymce-angular';
import { BrowserModule } from '@angular/platform-browser';



import { InventoryTransfersComponent } from './inventory-transfers/inventory-transfers.component';
import { TransfersCreateComponent } from './inventory-transfers/transfers-create/transfers-create.component';
import { TransfersReceiveComponent } from './inventory-transfers/transfers-receive/transfers-receive.component';
import { TransfersReleaseComponent } from './inventory-transfers/transfers-release/transfers-release.component';
import { BranchReorderComponent } from './branch-reorder/branch-reorder.component';

import { InventoryBarcodesComponent } from './inventory-barcodes/inventory-barcodes.component';
import { TransfersEditComponent } from './inventory-transfers/transfers-edit/transfers-edit.component';
import { InventoryPromosComponent } from './inventory-promos/inventory-promos.component';
import { InventoryExportComponent } from './inventory-export/inventory-export.component';
import { InventoryGlclassComponent } from './inventory-glclass/inventory-glclass.component';
import { WeeklyReorderComponent } from './weekly-reorder/weekly-reorder.component';
import { InventoryProductgroupsComponent } from './inventory-productgroups/inventory-productgroups.component';

import { AccordionModule } from 'primeng/accordion';
import { BadgeModule } from 'primeng/badge';

import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { InventoryUploadComponent } from './inventory-upload/inventory-upload.component';
import { PriceBreakComponent } from './inventory-view/price-break/price-break.component';



@NgModule({
	imports: [
		BrowserModule,
		CKEditorModule,
		EditorModule,
		CommonModule,
		RouterModule,
		ReactiveFormsModule,
		FormsModule,
		NgxDropzoneModule,
		ScrollingModule,
		MatToolbarModule,
		MatButtonModule,
		MatDialogModule,
		MatDividerModule,
		MatInputModule,
		MatTableModule,
		MatCheckboxModule,
		MatSortModule,
		MatPaginatorModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		NgxExtendedPdfViewerModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatExpansionModule,
		MatSelectModule,
		MatMenuModule,
		MatIconModule,
		MatSlideToggleModule,
		MatStepperModule,
		MatChipsModule,
		MatListModule,
		MatTabsModule,
		MatCardModule,
		MatRadioModule,
		AccordionModule,
		ConfirmPopupModule,
		BadgeModule,
		ClipboardModule,
		MatProgressSpinnerModule,
		CalendarModule.forRoot({
	  provide: DateAdapter,
	  useFactory: adapterFactory,
		}),
		SharedModule,
	],
	declarations: [
		InventoryComponent,
		InventoryListComponent,
		InventoryDashboardComponent,
		InventoryCreateComponent,
		InventoryUpdateComponent,
		InventoryViewComponent,
		FocusMeDirective,
		HighlightDirective,
		StockMasterComponent,
		PurchdataComponent,
		PricesComponent,
		InventorySearchComponent,
		InventoryEditComponent,
		InventoryEditSetupComponent,
		InventoryEditPricesComponent,
		InventoryEditStockComponent,
		InventoryEditExtraComponent,
		InventoryEditImagesComponent,
		InventoryRemoteNewItemsComponent,
		SpecialPricingComponent,
		InventoryValueComponent,
		InventoryMovementsComponent,
		InventoryAdjustmentComponent,
		InventoryAttributesComponent,
		InventoryTransfersComponent,
		TransfersCreateComponent,
		TransfersReceiveComponent,
		TransfersReleaseComponent,
		BranchReorderComponent,
		InventoryBarcodesComponent,
		TransfersEditComponent,
		InventoryPromosComponent,
		InventoryExportComponent,
		InventoryGlclassComponent,
		WeeklyReorderComponent,
		InventoryProductgroupsComponent,
		InventoryUploadComponent,
  PriceBreakComponent,
	],
	exports: [
		InventoryComponent
	],
	providers: [
		GlobalSearchService,
		ConfirmationService,
		MessageService,
		CurrencyPipe,
		DatePipe,
		{ provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
	],
})

export class InventoryModule { }
