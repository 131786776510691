<div class="container-fluid tp-box" [@flipState]="flip" >
	<div class="row tp-box__side tp-box__back" *ngIf="flip == 'active'">
		<div class="card" >
			<div class="card-header card-header-primary card-header-icon">
				<div class="card-icon pointer" (click)="toggleFlip(false, '')">
					<i class="material-icons">login</i>
				</div>
				<h3 class="card-title">{{ title }} </h3>
			</div>
			<div class="card-body"  *ngIf="viewing">
				<div class="table">
				<table class="table table-sm">
					<tr>
						<th>IP</th> 
						<th>Customer#</th>
						<th>Branch#</th>
						<th>Name</th>
						<th>Address</th>
						<th>City</th>
						<th>State</th>
						<th>Postal#</th>
						<th>Phone#</th>
						<th>Cart#</th>
						<th>Qty</th>
						<th>Date</th>
					</tr>
					<tr *ngFor="let d of data">
						<td>{{d.ip}}</td>
						<td>{{d.custID}}</td>

						<td *ngIf="!d.branchcode" class="na-text"><sub>N/A</sub></td>
						<td *ngIf="d.branchcode">{{d.branchcode}}</td>

						<td *ngIf="!d.brname" class="na-text"><sub>N/A</sub></td>
						<td *ngIf="d.brname">{{d.brname}}</td>

						<td *ngIf="d.braddress1">{{d.braddress1}}</td>
						<td *ngIf="!d.braddress1" class="na-text"><sub>N/A</sub></td>

						<td *ngIf="!d.braddress3" class="na-text"><sub>N/A</sub></td>
						<td *ngIf="d.braddress3">{{d.braddress3}}</td>

						<td *ngIf="!d.braddress4" class="na-text"><sub>N/A</sub></td>
						<td *ngIf="d.braddress4">{{d.braddress4}}</td>

						<td *ngIf="!d.braddress5" class="na-text"><sub>N/A</sub></td>
						<td *ngIf="d.braddress5">{{d.braddress5}}</td>

						<td *ngIf="!d.phoneno" class="na-text"><sub>N/A</sub></td>
						<td *ngIf="d.phoneno">{{d.phoneno}}</td>
						
						<td *ngIf="!d.cart_id" class="na-text"><sub>N/A</sub></td>
						<td *ngIf="d.cart_id">{{d.cart_id}}</td>
						
						<td *ngIf="!d.quantity" class="na-text"><sub>N/A</sub></td>
						<td *ngIf="d.quantity">{{d.quantity}}</td>
												
						<td>{{d.custDate}}</td>
					</tr>
				</table>
				</div>
			</div>
		</div>
	</div>
	<div class="row tp-box__side tp-box__front" id="frontside" [ngClass]="flip == 'inactive' ? '' : 'd-none'">
		<div class="card card-stats">
			<div class="card-header card-header-primary card-header-icon">
				<div class="card-icon pointer" (click)="toggleFlip(data, title)">
					<i class="material-icons">login</i>
				</div>
				<p class="card-category">{{ title }}</p>
				<h3 class="card-title">
					<span *ngIf="data">
						{{ data.length }}
					</span>
					<span *ngIf="!data">
						<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</span>
				</h3>
				<small>
					<p class="card-category text-info text-justify mt-3 text-right">
						&nbsp;
					</p>
				</small>
			</div>
			<div class="card-footer">
				<div class="stats pointer">
					<a (click)="toggleFlip(data, title)">
						<i class="material-icons">content_copy</i>
						View Now</a>
				</div>
				<br />
			</div>
		</div>
	</div>
</div>
