import { Component, OnDestroy, OnInit, ViewChild, OnChanges, ChangeDetectorRef, ElementRef, Pipe, PipeTransform, ViewEncapsulation, Directive, HostListener } from '@angular/core';
import { ViewportScroller } from '@angular/common';

import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { trigger, state, style, transition, animate } from '@angular/animations';
import * as Chartist from 'chartist';

import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DataService } from '../../data.service';
import { OmsService } from '../../services/oms.service';

import { OrdersService } from '../../services/orders.service';
import { PurchasingService } from '../../services/purchasing.service';

import { InventoryService } from '../../services/inventory.service';

import { VendorService } from '../../services/vendor.service';
import { DailySales } from '../../classes/reports';
import { OrderTypes, Cards, ItemPick } from '../../classes/orders';
import { Pick, ItemList } from '../../classes/items';

import { Order, PickItem, PickItems } from '../../classes/orders';
import { Message } from '../../classes/message';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import * as XLSX from 'xlsx';
import { MatPaginator } from '@angular/material/paginator';
import { merge, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

declare let $: any;

@Component({
	selector: 'app-purchase-order-receive',
	templateUrl: './purchase-order-receive.component.html',
	styleUrls: ['./purchase-order-receive.component.scss']
})
export class PurchaseOrderReceiveComponent implements OnInit {

	@ViewChild('keywordstwo') keywordsField: ElementRef;
	@ViewChild('itemCodeRef') itemCodeRef: ElementRef;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


	itemScanForm: UntypedFormGroup;
	itemSearchForm: UntypedFormGroup;
	SENDER: any;
	messages = [];
	message: any;
	CHAT_ROOM = 'OrderBoard';

	status: any;
	pickable: any;
	carddata: any;
	sortcolumns: any;
	selectedColumn: any;
	picks: ItemPick[] = [];
	poSearch: UntypedFormControl = new UntypedFormControl('');
	itemCode: UntypedFormControl = new UntypedFormControl('');
	vendor: UntypedFormControl = new UntypedFormControl('');
	filteredResults: any = []
	filteredItems: any = [];

	completed_items: any = [];
	mustcomplete: any = [];

	linecomplete = {};
	itemcount = {};
	errors = [];
	error: any;
	address: any;
	linedetails = {};
	forcesearch = true;
	column: any;
	openOrderData: any;
	ordersFound: any;
	purchaseorders: any = [];
	selectedorder: any = [];
	purchoderdetails: any = [];
	searchsubscription: any = false;
	mustpick: any = [];
	journals: any = [];
	user: any = [];
	selectedpo = '';
	color = 'blue';

	item_bin = {
		stockid: '',
		bin: '',
		location: '',
		qty: 0,
	}

	scanResults: any = [];
	vendors: any = [];
	receviginorder: any = false;
	pageindex = 0;

	dataSource: string[] = []; // for displaying paginated data
	loadingPO: boolean = false;
	loadingdetails: boolean;
	items_per_page = [25, 50, 100, 200, 500];
	pageSize = 25;
	pagesizedefault = 25;
	p: any;


	constructor(private vendorService: VendorService, public omsService: OmsService, public location: Location, public ordersService: OrdersService, private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, public formBuilder: UntypedFormBuilder, public inventoryService: InventoryService, private purchasingService: PurchasingService) {

		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {

		const token = this.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
		if (token) {
			// 			this.omsService.setupSocketConnection(token);
			// 			this.omsService.subscribeToCards((err, data) => {
			//
			// 				if(data.message !='auto') {
			// 				}
			// 				//could interfere with each other. maybe only on a new purchase order?
			// 				if(data.name == 'updatepick') {
			// 					this.loadPos();
			// 				}
			//
			// 				if(!this.column) {
			// 				} else {
			// 				}
			//
			// 			});
		}

		this.vendorService.getVendors().subscribe(results => this.vendors = results);

		const all = {
			supplierid: '*',
			suppname: 'ALl',
		}

		this.vendors.unshift(all)

		this.globalSearchService.user.subscribe((result) => {
			this.user = result;
		});

		this.itemScanForm = this.formBuilder.group({
			keywords: ['', Validators.required],
			journal: ['', Validators.required],

		});

		//init show all

		this.route.params.subscribe(params => {
			if (params['id']) {
				this.receviginorder = params['id'];
				this.pageindex = 1;
				this.getOrderDetails(params['id'])
				//this.itemScanForm.get('poSearch').setValue(params['id'])
			}
		});
		if (!this.receviginorder) {
			this.loadPos();
		}
		this.getInboundOrders();




	}

	updatePageSize(event: any) {

		this.pageSize = event.value;
	}

	closeOpenNewJournal() {
		this.purchasingService.closeJournal(this.itemScanForm.value).subscribe((result: any) => {
			this.journals = result;
			this.itemScanForm.get('journal').setValue(result[0]);
		});
	}

	getInboundOrders() {

		this.purchasingService.getOrders().subscribe((results: any) => {
			this.purchaseorders = results.pos;
			this.journals = results.journals;
			this.itemScanForm.get('journal').setValue(results.journals[0]);
		});
	}

	itemScan() {

		if (this.searchsubscription) {
			this.searchsubscription.unsubscribe();
		}

		const data = { scan: this.itemCode.value, vendor: this.vendor.value }
		this.searchsubscription = this.purchasingService.getItemScan(data).subscribe((result: any) => {
			const thisitem = this.mustpick.filter((items: any) => {
				return items.stockid == result.stockid
			})

			const item = thisitem[0];
			const index = this.mustpick.indexOf(item);

			if (index < 0) {

			} else {
				const floatvalue = parseFloat(this.mustpick[index].quantityrecd) + 1;
				this.mustpick[index].current_movement = 1;
				this.mustpick[index].quantityrecd = floatvalue;

				if (floatvalue > parseFloat(this.mustpick[index].quantityord)) {
					if (confirm('You are trying to receive more than ordered, Proceed?')) {

					} else {
						const floatvalue = parseFloat(this.mustpick[index].quantityrecd);
						this.mustpick[index].quantityrecd = floatvalue;
						this.mustpick[index].current_movement = 0;
					}
				}

				this.itemCode.setValue('');
				//this.updatePick(this.mustpick[index]);
				if (this.mustpick[index].quantityrecd == this.mustpick[index].quantityord) {
					this.mustpick.splice(index, 1);
				}
				this.mustpick[index].bin.qty = Number(this.mustpick[index].bin.qty) + Number(this.mustpick[index].current_movement);
			}
		})
	}

	filterPoByVendor(event: any) {

		const search = {
			vendor: event.supplierid
		};
		//this.vendor = event.supplierid
		this.mustpick = this.purchoderdetails.filter(r => {
			return r.supplierno == this.vendor.value
		})

	}

	filterItems() {
		if (this.vendor.value != '*') {
			this.mustpick = this.purchoderdetails.filter(r => {
				return r.supplierno == this.vendor.value
			})
		} else {
			this.mustpick = this.purchoderdetails;
		}
		//this.linkListToPaginator();
		this.itemCodeRef.nativeElement.focus();
	}

	itemSearch() {

		if (this.receviginorder != '') {

			const result = this.purchoderdetails.filter(r => {
				return r.stockid.includes(this.itemScanForm.get('keywords').value);
			});
			this.mustpick = result;
			let process = true;
			if (result.length == 1) {
				const item = result[0];
				const index = this.purchoderdetails.indexOf(item);
				let floatvalue = parseFloat(this.purchoderdetails[index].quantityrecd) + 1;
				this.purchoderdetails[index].current_movement = 1;

				if (floatvalue > parseFloat(this.purchoderdetails[index].quantityord)) {
					if (confirm('You are trying to receive more than ordered, Proceed?')) {

					} else {
						floatvalue = parseFloat(this.purchoderdetails[index].quantityrecd);
						this.purchoderdetails[index].quantityrecd = floatvalue;
						this.purchoderdetails[index].current_movement = 0;

						process = false;
					}
				}

				this.purchoderdetails[index].quantityrecd = floatvalue;
				if (this.purchoderdetails[index].current_bin == null) {
					this.purchoderdetails[index].current_bin = this.purchoderdetails[index].bins[0];
				}
				this.purchoderdetails[index].current_bin.qty = floatvalue;

				this.mustpick = this.purchoderdetails;
				this.itemScanForm.controls['keywords'].setValue('');

			}
		} else {
			if (this.searchsubscription) {
				this.searchsubscription.unsubscribe();
			}

			this.searchsubscription = this.purchasingService.getPutAwaySearch(this.itemScanForm.value).subscribe((result: any) => {

				this.purchoderdetails = result;
				this.mustpick = result;
				let process = true;
				if (result.length == 1) {
					const item = result[0];
					const index = this.mustpick.indexOf(item);
					const floatvalue = parseFloat(this.mustpick[index].quantityrecd) + 1;
					this.mustpick[index].current_movement = 1;

					if (floatvalue > parseFloat(this.mustpick[index].quantityord)) {
						if (confirm('You are trying to receive more than ordered, Proceed?')) {

						} else {
							const floatvalue = parseFloat(this.mustpick[index].quantityrecd);
							this.mustpick[index].quantityrecd = floatvalue;
							this.mustpick[index].current_movement = 0;
							process = false;
						}
					}

					this.mustpick[index].quantityrecd = floatvalue;
					this.itemScanForm.controls['keywords'].setValue('');
					if (process) {
						//this.updatePick(this.mustpick[index]);
					}
				}
			});
		}
	}

	loadPos() {
		var search = '*';
		if (this.receviginorder) {
			search = this.receviginorder;
		}
		this.poSearch.setValue('');
		this.loadingdetails = true;
		this.purchasingService.getPurchaseOrderDetails(search).subscribe((results: any) => {
			this.purchoderdetails = results;
			this.mustpick = results;
			this.loadingdetails = false;
			if (this.receviginorder) {
				this.mustpick = this.purchoderdetails.filter(r => {
					return r.orderno == this.receviginorder
				})
			}
			//this.linkListToPaginator();
		});
	}

	setBin(value: any, bin: any) {
		bin.bin = value.toUpperCase();
	}

	addBin(item) {

		const item_bin = {
			stockid: item.stockid,
			bin: '',
			location: item.intostocklocation,
			qty: 0,
		}

		item.bins.push(item_bin)
	}

	removeBin(bin, item) {

		let proceed = true;
		if (bin.qty > 0) {
			proceed = confirm('This Bin has qty, Proceed? Bin will be reduced by' + bin.qty);
		}

		if (proceed) {
			if (bin.qty > 0) {
				item.current_bin = bin;
				item.current_movement = -1 * bin.qty;
				const floatvalue = parseFloat(item.quantityrecd) + item.current_movement;
				bin.qty = bin.qty + item.current_movement;
				item.quantityrecd = floatvalue;
				////this.updatePick(item);
			}

			if (item.bins.length > 1) {
				const index = item.bins.indexOf(bin);;
				item.bins.splice(index, 1);
			}
		}
	}
	loadSelectedOrder(orderno: any) {
		this.router.navigate(['/purchasing/receive/' + orderno])
	}

	defaultState() {
		this.router.navigate(['/purchasing/receive/']);
	}

	getOrderDetails(orderno: any) {
		this.selectedorder = this.purchaseorders.filter((order: any) => {
			return order.orderno = orderno;
		})[0];

		this.loadingPO = true;

		this.purchasingService.getPurchaseOrderDetails(orderno).subscribe((results: any) => {
			this.purchoderdetails = results;
			this.mustpick = results;
			//this.linkListToPaginator();
			this.loadingPO = false
		});

		this.purchasingService.getOrders().subscribe((results: any) => {
			this.purchaseorders = results.pos;
		});
	}

	setCheckValue(event, item) {

		let floatvalue = 0;
		if (event.checked) {
			floatvalue = parseFloat(item.quantityord);
			item.bins[0].qty = floatvalue;
			item.current_movement = floatvalue;
		} else {
			item.current_movement = -1 * parseFloat(item.quantityord);
		}

		item.bins.splice(1, item.bins.length)

		item.bins[0].qty = floatvalue;
		item.quantityrecd = floatvalue;
		//	//this.updatePick(item);
	}

	//todo move these methods to order pick much cleaner


	addPick(bin: any, item: any) {

		item.current_movement = 1;
		item.current_bin = bin;
		const floatvalue = parseFloat(item.quantityrecd) + parseFloat(item.current_movement);
		if (floatvalue > parseFloat(item.quantityord)) {
			const proceed = confirm('You are trying to receive more than ordered, Proceed?');

			if (proceed) {
				bin.qty = bin.qty + item.current_movement;
				item.quantityrecd = floatvalue;
				//this.updatePick(item);
			} else {
				item.current_movement = 0;
			}

		} else {
			bin.qty = parseFloat(bin.qty) + parseFloat(item.current_movement);
			item.quantityrecd = floatvalue;
			//this.updatePick(item);
		}
	}

	removePick(bin: any, item: any) {
		item.current_movement = -1
		item.current_bin = bin;

		let floatvalue = parseFloat(bin.qty) + parseFloat(item.current_movement);
		if (floatvalue < 0) {
			//no update
			floatvalue = 0;
			item.current_movement = 0;
			bin.qty = 0;
			item.quantityrecd = 0;
		} else {
			item.current_movement = -1;
			item.quantityrecd = floatvalue;
			bin.qty = parseFloat(bin.qty) + parseFloat(item.current_movement);
		}
	}

	completeReceiving() {

		const data = {
			items: this.mustpick,
			user: this.user,
			journal_no: true
		}

		this.purchasingService.completeVendorPurchaseOrder(data).subscribe(r => {
			if (r.success) {
				//issue with partials
				this.purchasingService.getPurchaseOrderDetails(this.receviginorder).subscribe((results: any) => {
					this.purchoderdetails = results;
					this.mustpick = results;
				});

				this.globalSearchService.showNotification(this.receviginorder + ' Received', 'success', 'bottom', 'left');
				this.defaultState();
			}

		})
	}


	showError: boolean = false;
	errorMessage: string = '';
	setQty(event: any, item: any, bin: any) {
		// Get the current input value and calculate the current movement
		const inputQty = parseFloat(event.target.value);
		if (isNaN(inputQty)) {
			this.showError = true;
			this.errorMessage = 'Please enter a valid number';
			event.target.value = bin.qty;
			return;
		}

		this.showError = false; // Hide error if input is valid

		const currentQty = parseFloat(bin.qty);
		const quantityRecd = parseFloat(item.quantityrecd);
		const quantityOrd = parseFloat(item.quantityord);

		const movement = inputQty - currentQty;
		const newQuantityRecd = quantityRecd + movement;

		// Handle the text input
		if (newQuantityRecd > quantityOrd) {
			if (confirm('You are trying to receive more than ordered. Proceed?')) {
				bin.qty = inputQty.toString();
				item.quantityrecd = newQuantityRecd.toString();
				// Uncomment this line if updatePick method is required
				// this.updatePick(item);
			} else {
				event.target.value = bin.qty;
			}
		} else {
			bin.qty = inputQty.toString();
			item.quantityrecd = newQuantityRecd.toString();
			// Uncomment this line if updatePick method is required
			// this.updatePick(item);
		}
	}

	selectText(event: any) {
		event.target.select();
	}


	updatePick(item: any) {

		const data = { item, user: this.user, form: this.itemScanForm.value };
		if (data) {
			//send to socket
			if (item.quantityord == item.quantityrecd && item.current_movement != 0) {
				//this.globalSearchService.showNotification(item.description+'Received','success','bottom', 'left');
			}

			// this.omsService.sendPutUpdate({data, roomName: this.CHAT_ROOM}, cb => {
			// 	//doesnt update auto complete?
			// 	if(item.current_movement != 0) {
			// 		this.purchasingService.getOrders().subscribe( (results: any) => {
			// 			this.purchaseorders = results.pos;
			// 			if(this.receviginorder) {
			// 				var stillopen = results.pos.filter( p => {
			// 					return p.orderno == this.receviginorder
			// 				})
			// 			}
			// 		});
			// 	}
			// });
		}
	}

	resetData(e) {
		this.dataSource = [];
		if (e.index == 0) {
			this.defaultState()
		}
	}

	back(): void {
		this.location.back()
	}

	finished() {
		return false;
	}

	randomString(length: any, chars: any) {
		let result = '';
		for (let i = length; i > 0; --i) { result += chars[Math.floor(Math.random() * chars.length)]; }
		return result;
	}


	linkListToPaginator() {
		if (this.paginator.pageIndex > 0) { this.paginator.pageIndex = 0 }
		let holder = this.mustpick;
		merge(this.paginator.page).pipe(
				startWith({}),
				switchMap(() => {
					const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
					const endIndex = startIndex + this.paginator.pageSize;
					return of(holder.slice(startIndex, endIndex));
				}))
			.subscribe(data => this.dataSource = data);
	}


}