<form name="reportrun" [formGroup]="reportForm" (ngSubmit)="onSubmit()" id="variable_form">
	<div class="main-content">
		<div class="container-fluid">
			<div class="row">
				<div class="col-md-12">
					<div class="card">
						<!--
						<div class="card-header card-header-danger" *ngIf="reportoptions">

							<input type="hidden" name="report" id="reportname" value="{{ reportoptions.report }}">
							<h4 class="card-title "> <i class="material-icons" (click)="back()">arrow_left</i> {{ reportoptions.options.Name }}</h4>
							<p class="card-category" *ngIf="reportoptions.Description">{{ reportoptions.options.Description }}</p>
							<p class="card-category" *ngIf="reportoptions.Description">This report needs more information before running.</p>
							<div class="card-body" *ngIf="reportoptions.options" formArrayName="reportArray" id="variables-fieldset">
								<div class="form-group" *ngFor="let option of reportoptions.vars; let i = index">
									<div class="row">
										<label for='var_{{ option.key }}' class='col-sm-3 white'>{{ option.display }}</label>

										<div class='col-sm-8 controls' *ngIf="option.is_select && !option.is_multiselect">
											<select name="macros[{{ option.key }}]" id="var_{{ option.key }}" class="variable" formControlName="option.key">
												<option *ngFor="let opt of option.options" [selected]="opt.selected" [value]="opt.value"> {{ opt.display }} </option>
											</select>
										</div>
										<div class='col-sm-8 controls' *ngIf="option.is_select && option.is_multiselect">
											<ng-select name="macros[{{ option.key }}]" id="var_{{ option.key }}" class="variable" formControlName="{{ option.key }}" [items]="option.options" bindLabel="display" bindValue="" placeholder="{{ option.display }}" appendTo="body" [multiple]="true" [(ngModel)]="multiselect" (remove)="deleteSingle($event, option.key)" (change)="onSelectCompany($event, option.key)">
											</ng-select>

										</div>
										<div class='col-sm-8 controls' *ngIf="option.type == 'daterange'">
											<input type="text" class="variable" data-end="var_{{option.key}}_end" data-start="var_{{option.key}}_start" name="macros[{{ option.key }}]" ngxDaterangepickerMd [(ngModel)]="selected" [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [linkedCalendars]="false" [showClearButton]="true" [locale]="{displayFormat: 'MM/DD/YYYY',format: 'YYYY-MM-DD'}" (datesUpdated)="datesUpdated($event)" placeholder="DATE RANGE" />


											<input type='hidden' name="macros[{{option.key}}][start]" id="range_start" value="{{ range_start }}" />
											<input type='hidden' name="macros[{{option.key}}][end]" id="range_end" value="{{ range_end }}" />
										</div>
										<div class='col-sm-8 controls' *ngIf="option.type == 'text'">
											<input type='text' class="variable" name="macros[{{ option.key }}]" id="var_{{ option.key }}" value='{{ option.value }}' />
										</div>
									</div>
								</div>
								<div class="form-group">
									<div class="col-sm-offset-2 col-sm-10">
										<button type="submit" class="btn btn-primary">Run Report</button>
									</div>
								</div>

							</div>
						</div>
						<div class="card-body">



							<button mat-raised-button color="primary" (click)="exportExcel()" [ngClass]="tablesrc.length ? '': 'd-none'">XLS</button>
							<button mat-raised-button color="accent" (click)="exportPDF()" [ngClass]="tablesrc.length ? '': 'd-none'">PDF</button>
							<div class="table table-responsive" #htmlData id="htmlData">
								<table mat-table [dataSource]="tabledata" matSort (matSortChange)="announceSortChange($event)">
									<ng-container [matColumnDef]="column" *ngFor="let column of headercolumns">
										<th mat-header-cell *matHeaderCellDef mat-sort-header> {{ column }} </th>
										<td mat-cell *matCellDef="let order">
											<span *ngIf="column != 'Actions'">
												{{ order[column] }}
											</span>
											<span *ngIf="column == 'Actions'">
												<button mat-stroked-button color="primary" class="ml-2 mr-2">Edit</button>
												<button mat-stroked-button color="warn" class="ml-2 mr-2">Invoice</button>
												<button mat-stroked-button color="accent" class="ml-2 mr-2">Print</button>
											</span>

										</td>
									</ng-container>
									<tr mat-header-row *matHeaderRowDef="headercolumns"></tr>

									<tr mat-row *matRowDef="let row; columns: headercolumns"></tr>
								</table>
							</div>
						</div>
						-->
							<div class="iframe-container embed-responsive h-90" >
								<!--
								<seb-ng-magic-iframe [source]="urlSafe" *ngIf="urlSafe">
								  <div class="skeleton-loader"></div>
								</seb-ng-magic-iframe>
								-->
							  <iframe allowfullscreen class="embed-responsive-item" [src]="urlSafe" ></iframe>

							</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</form>

<script>
	$(function() {

		$('.daterangepicker_holder', $('#variable_form')).each(function() {
			moment.lang(window.navigator.language);

			var range_holder = $(this);
			range_holder.daterangepicker({
					ranges: {
						'Today': [moment(), moment()],
						'Yesterday': [moment().subtract('days', 1), moment().subtract('days', 1)],
						'Last 7 Days': [moment().subtract('days', 6), moment()],
						'Last 30 Days': [moment().subtract('days', 29), moment()],
						'This Month': [moment().startOf('month'), moment().endOf('month')],
						'Last Month': [moment().subtract('month', 1).startOf('month'), moment().subtract('month', 1).endOf('month')]
					},
					showWeekNumbers: true,
					showDropdowns: true,
					locale: {
						firstDay: moment.langData()._week.dow
					}
				},
				function(start, end) {
					$('#var_' + range_holder.data('var') + '_start').val(start.format('YYYY-MM-DD') + " 00:00:00");
					$('#var_' + range_holder.data('var') + '_end').val(end.format('YYYY-MM-DD') + " 23:59:59");

					$('span', range_holder).html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
				}
			);
		});
		$('.datepicker_holder', $('#variable_form')).each(function() {
			$(this).datepicker();
		});

		$('#variables-fieldset').on('hide.bs.collapse', function() {
			var t = new Array();
			$('#variables-fieldset .variable').each(function() {
				var inputs = $(this).find('input,select,textarea');
				var label = $(this).find('label.control-label').text();
				var values = [];
				inputs.each(function() {
					if (($(this).attr('name') || '').match(/^macros/)) {
						// For dropdowns, use the display name instead of the value
						if ($('option:selected', $(this)).length) {
							var t = [];
							$('option:selected', $(this)).each(function() {
								t.push($(this).text());
							});
							values.push(t.join(' | '));
						}
						// Otherwise, show the value
						else {
							values.push($(this).val());
						}
					}
				});
				if (values.length) {
					t.push('<strong>' + label + '</strong>: <em>' + values.join(' - ') + '</em>');
				}
			});
			var text = ' : ' + t.join(', ');
			$('#variables-holder').html(text);
			$('#variables-header span.glyphicon').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-right');
		}).on('show.bs.collapse', function() {
			$('#variables-selected').collapse('hide');
			$('#variables-holder').text('');
			$('#variables-header span.glyphicon').removeClass('glyphicon-chevron-right').addClass('glyphicon-chevron-down');
		})


	});
</script>
