import { Component, OnInit, Input, ViewChild, ElementRef , OnChanges, AfterViewInit } from '@angular/core';
import { GlobalSearchService } from '../../../services/globalsearchservice.service'
import { PrintService } from '../../../services/print.service'
import { CustomerService } from '../../../services/customer.service'
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors, FormControl, FormGroup, FormArray } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-customer-total',
	templateUrl: './customer-total.component.html',
	styleUrls: ['./customer-total.component.scss']
})
export class CustomerTotalComponent implements OnInit, AfterViewInit {
	@Input() data: any = false;
	filename = 'customers';
	sending = false;
	salespeople: any = false;
	taxfilter = new UntypedFormControl(false);
	salesfilter = new UntypedFormControl(false);
	search = new UntypedFormControl('');
	taxes: any = false;
	tabledata: any = [];


	headercolumns: string[] = [
		'debtorno',
		'name',
		'address1',
		'address2',
		'address3',
		'address4',
		'address5',
		// 'paymentterms',
		// 'creditlimit',
		'phoneno',
		'email',
		'salesman'
	];

	filteredBy = new FormControl('');
	filterList: any = [
		{
			view: 'Customer #',
			value: 'custbranch.debtorno'
		},
		{
			view: 'Customer Name',
			value: 'name'
		},
		{
			view: 'Address 1',
			value: 'address1'
		},
		{
			view: 'Address 2',
			value: 'address2'
		},
		{
			view: 'City',
			value: 'address3'
		},
		{
			view: 'Region',
			value: 'address4'
		},
		{
			view: 'Postal Code',
			value: 'Address5'
		},
		{
			view: 'Phone #',
			value: 'phoneno'
		},
		{
			view: 'Email',
			value: 'email'
		}];

	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('print_table') printtable: ElementRef;
	@ViewChild('filters') filters: MatSelect;
	config: any = [];
	user: any = false;
	editsallowed: any = {
		discounts: true,
		editar: true,
		editbilling: true,
		editshipping: true,
		editcontact: true,
		editprofile: true,
		editnotes: true,
		takepayment: true,
	}

	obsValue: Observable<any>;
	searchsub: any = false;
	exporting: any = false;

	constructor(private customerService: CustomerService, private globalSearchService: GlobalSearchService, private printService: PrintService){
		this.globalSearchService.taxgroups.subscribe(r => {
			this.taxes = r;
		})

		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
			this.globalSearchService.user.subscribe(u => {
				this.user = u
				if(u.user.issalesman) {
					this.editsallowed = r.salesmanAllowed
				}
			})
		});


		this.globalSearchService.salespeople.subscribe(r => {
			this.salespeople = r.filter((person) => person.group_id == 1 && person.current == 1).sort((a,b) => a.salesmanname > b.salesmanname ? 1 : 0);
		})
	}

	ngAfterViewInit(): void {
		//setting of default search filters -> runs off filters 'value' val
		//current defaults : email, phone, city
		this.filters.options.forEach((item: MatOption) => ['email','phoneno','address3'].includes(item.value) ? item.select() : '');
	}

	ngOnInit(): void {
		this.tabledata = new MatTableDataSource([]);
		this.tabledata.sort = this.sort;
		this.tabledata.paginator = this.paginator;
	}

	setData(data: any) {
		if(data) {
			this.tabledata = new MatTableDataSource(data);
			this.tabledata.sort = this.sort;
			this.tabledata.paginator = this.paginator;
		}
	}

	//rendering in browser is too heavy. slows down the whole page load. Attempt to build the html outside teh dom
	buildHtmlForExport() {
		let html = '';
		if(this.data) {
			html = '<table class="table table-fixed table-sm" #print_table>';
			html += '<thead>';
			html += '<tr>';
			html += '<th>Customer#</th>';
			html += '<th>Name</th>';
			html += '<th>Address1</th>';
			html += '<th>Address2</th>';
			html += '<th>Address3</th>';
			html += '<th>Address4</th>';
			html += '<th>Address5</th>';
			html += '<th>Phone</th>';
			html += '<th>Email</th>';
			html += '<th>Salesman#</th>';
			html += '</tr>';
			html += '</thead>';
			html += '<tbody>';

			this.data.forEach( (d:any) => {
				html += '<tr *ngFor="let d of data" class="donotshow">'
				html += '<td>'
				html += d.debtorno
				html += '</td>'
				html += '<td>' +d.name+'</td>'
				html += '<td>' +d.address1+'</td>'
				html += '<td>' +d.address2+'</td>'
				html += '<td>' +d.address3+'</td>'
				html += '<td>' +d.address4+'</td>'
				html += '<td>' +d.address5+'</td>'
				html += '<td>' +d.phoneno+'</td>'
				html += '<td>' +d.email+'</td>'
				html += '<td>' +d.salesman+'</td>'
				html += '</tr>'
			});

			html += '</tbody>'
			html += '</table>'
		}
		return html;
	}

	filterCustomers() {
		const filters = {
			salesman: this.salesfilter.value,
			tax: this.taxfilter.value,
			search: this.search.value,
			wild: this.filteredBy.value
		}

		if(this.searchsub) {
			this.searchsub.unsubscribe();
		}
		this.sending = true;
		this.searchsub = this.customerService.getAllCustomers(filters).subscribe(async(results: any) => {
			if(results) {

				this.data = results;
				this.tabledata = new MatTableDataSource(results)
				this.tabledata.sort = this.sort;
				this.tabledata.paginator = this.paginator;
			}
			this.sending = false;
		});
	}

	exportPdf() {
		this.sending = true;
		const encoded: string = this.globalSearchService.base64encode(this.buildHtmlForExport());
		const today = new Date();

		const filter = ( this.salesfilter.value.length > 1) ? this.salesfilter.value.join(', ') : '';

		const data = {
			content: encoded,
			filename: this.filename,
			title: 'All Customers',
			subtitle: 'Sales People:' + filter +' created '+today.toLocaleString(),
		}
		this.exporting = true;
		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.exporting = false;
			this.sending = false;
		});
	}

	exportXls() {
		this.sending = true;
		const encoded: string = this.globalSearchService.base64encode(this.buildHtmlForExport());
		const today = new Date();
		const filter = ( this.salesfilter.value.length > 0) ? 'Sales People: '+this.salesfilter.value.join(', ') : '';

		const data = {
			content: encoded,
			filename: this.filename,
			title: 'All Customers',
			subtitle: filter +' created '+today.toLocaleString(),
		}
		this.exporting = true;
		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.sending = false;
			this.exporting = false;
		});
	}
	ngOnChanges(changes: any) {

		if(changes.data) {
			this.filterCustomers()
		}
		// changes.prop contains the old and the new value...
	}

	ngOnDestroy() {
		this.searchsub.unsubscribe();
	}

	selectAllToggle() {
		const selected = this.filters.options.find(opt => opt.selected);
		if (selected) {
			this.filters.options.forEach((item: MatOption) => item.deselect());
		} else {
			this.filters.options.forEach((item: MatOption) => item.select());
		}
	}

	trackByItemId(index: number, item: any): number {
		return item.debtorno;
	}
}
