import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { InventoryService } from '../../services/inventory.service';
import { PurchasingService } from '../../services/purchasing.service';
import { OrdersService } from '../../services/orders.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';


@Component({
	selector: 'app-warehouse-view-item',
	templateUrl: './warehouse-view-item.component.html',
	styleUrls: ['./warehouse-view-item.component.scss']
})
export class WarehouseViewItemComponent implements OnInit {
	@Input() itemdetails: any = false;
	historyloading = false;
	showorder = false;
	orderdetails: any = [];
	history: any = false;
	color: any = 'blue';
	item: any = false;
	user: any = [];
	location :any ='';
	location_data: any = [];
	recitems: any = []

	constructor(private globalSearchService: GlobalSearchService, private inventoryService: InventoryService, public ordersService: OrdersService, public purchasingService: PurchasingService) {
		this.color = this.globalSearchService.getColor();

		this.globalSearchService.user.subscribe( (u:any) => {
			this.user = u.user;
			this.location = u.user.defaultlocation.loccode
		});

	}

	ngOnInit(): void {

		this.item = this.itemdetails.item
		this.historyloading = true;
		this.location_data = this.itemdetails.itemstock.filter( l => {
			return l.loccode == this.location;
		})[0];

		this.purchasingService.getOpenByItem(this.item.stockid).subscribe((recitems:any) => {
			this.recitems= recitems;
		});
		this.inventoryService.getPurchaseHistory(this.item.stockid).subscribe(async (result: any) => {
			this.historyloading = false;
			this.history = result
		})
	}

	audioPlay() {
		const audio = new Audio('/assets/pristine-609.mp3');
		audio.play();
	}

	audioPlayBad() {
		const audio = new Audio('/assets/glitch.mp3');
		audio.play();
	}

	audioPlayFree() {
		const audio = new Audio('/assets/money.mp3');
		audio.play();
	}

	vibrate() {
		if (window.navigator && window.navigator.vibrate) {
			navigator.vibrate(2000);
		} else {
			// Not supported
		}
	}

	loadOrder(order) {
		this.ordersService.getOrder(order).subscribe((results: any) => {
			this.showorder = true;
			this.orderdetails = results;
		});
	}

	ngAfterViewInit(): void {

	}

}
