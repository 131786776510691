import { Component, OnInit, ElementRef, ViewChild  } from '@angular/core';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { InventoryService } from 'app/services/inventory.service';
import { interval, Subscription } from 'rxjs';

@Component({
	selector: 'app-warehouse-reports',
	templateUrl: './warehouse-reports.component.html',
	styleUrls: ['./warehouse-reports.component.scss']
})
export class WarehouseReportsComponent implements OnInit {



	navSubscription: Subscription
	timerSubscription: Subscription
	reportdata: any = false;
	datasource: any;
	displayedColumns: any;
	matIndex: any = 0;
	prgoress: any = 0;
	reportrunning = false;

	constructor(private inventoryService: InventoryService, private globalSearchService: GlobalSearchService) { }

	ngOnInit(): void {
		this.run_report({ tab: { textLabel: 'NSRB' } });
		this.autonav();
	}

	NSRB() {
		this.inventoryService.nsrbReport({}).subscribe((results: any) => {
			this.datasource = results;
			this.displayedColumns = ['Item', 'Bins', 'QOH', 'Age', 'Since'];
			this.reportdata = true;
		});
	}
	NEEDS() {
		this.inventoryService.needsReport({}).subscribe((results: any) => {
			this.datasource = results;
			this.displayedColumns = ['stockid', 'description', 'quantity', 'bin'];
			this.reportdata = true;
		});
	}
	RECDOC() {
		this.inventoryService.recdocReport({}).subscribe((results: any) => {
			this.datasource = results;
			this.displayedColumns = ['Item', 'Bins', 'QOH', 'Age', 'Since'];
			this.reportdata = true;
		});
	}

	CREDITS() {
		this.inventoryService.creditItemsReport({}).subscribe((results: any) => {
			this.datasource = results;
			this.displayedColumns = ['Item', 'Bins', 'QOH', 'MIN', 'MAX', 'TARGET', 'LASTVENDOR', 'Age', 'Since'];
			this.reportdata = true;
		});
	}

	PICKABLE_ORD() {
		// this.reportrunning = true;
		this.inventoryService.pickableReport({}).subscribe((results: any) => {
			this.datasource = results;
			this.displayedColumns = ['orderno', 'stkcode', 'ordered', 'qoh'];
			this.reportdata = true;
			this.restartTimer();
			// this.reportrunning = false;
		});
	}

	run_report(e: any) {
		this.restartTimer();
		const report = e.tab.textLabel
		this.reportdata = false;
		switch (report) {
		case 'RECDOC':
			this.RECDOC();
			break;
		case 'NEEDS':
			this.NEEDS();
			break;
		case 'NSRB':
			this.NSRB();
			break;
		case 'PICKABLE ORDERS':
			this.PICKABLE_ORD();
			break;
		case 'CREDIT ITEMS':
			this.CREDITS();
			break;
		}
	}

	autonav() {
		this.startTimer();
		this.navSubscription = interval(45000).subscribe((x => {
			this.rotateNav();
		}));
	}

	startTimer() {
		this.timerSubscription = interval(225).subscribe((x => {
			this.updateTimer();
		}));
	}

	updateTimer(){
		this.prgoress += .5;
	}

	restartTimer(){
		this.prgoress = 0;
	}

	rotateNav() {
		if (this.matIndex == 2) {
			this.matIndex = 0;
		} else {
			this.matIndex++;
		}
	}

}
