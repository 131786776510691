<div class="container w-100 m-0 p-0">

<div *ngIf="sending">
	<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
		<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
		</circle>
	</svg>
</div>
<div *ngIf="!sending">
	<form [formGroup]="paymentForm" *ngIf="paymentForm" (ngSubmit)="addPaymentMethod()" autocomplete="off">
		<mat-form-field class="full-width text-right" appearance="outline" color="accent">
			<mat-label>Card Name</mat-label>
			<input matInput placeholder="Card Name" type="text" formControlName="name">
		</mat-form-field>
		<mat-form-field class="full-width text-right" appearance="outline" color="accent">
			<mat-label>Card Number</mat-label>
			<input matInput placeholder="Card Number" type="tel" ccNumber #ccNumber="ccNumber" formControlName="ccnumber">
			<span matSuffix class="scheme">
				<i class="fa fa-cc-{{ccNumber.resolvedScheme$ | async}}"></i>
			</span>
		</mat-form-field>
		<mat-form-field class="full-width text-right" appearance="outline" color="accent">
			<mat-label>Expiration</mat-label>
			<input matInput id="cc-exp-date" type="tel" autocomplete="cc-exp" formControlName="expiry" ccExp>
		</mat-form-field>
		<mat-form-field class="full-width text-right" appearance="outline" color="accent">
			<mat-label>CVV</mat-label>
			<input matInput id="cc-cvc" type="tel" autocomplete="off" formControlName="cvv" ccCVC>
		</mat-form-field>
		<button mat-flat-button color="accent">Add Card</button>
	</form>
</div>
</div>
