<div class="card-body mt-4 pb-0 pt-0 text-left">
	<div class="row border-bottom" *ngFor="let item of order.details">
		<div class="col-md-12">
			<b>{{ item.stockid}}</b> {{ item.description}}
		</div>
		<div class="col-md-3 text-left">
			<ul class="entry-list">
				<li>
					<div class="spec-label"> Price</div>
					<div class="spec-value">{{ item.unitprice | currency }}</div>
				</li>
				<li>
					<div class="spec-label"> Ordered</div>
					<div class="spec-value"> {{ item.ordered }} {{ item.units}} </div>
				</li>
				<li>
					<div class="spec-label"> QOH</div>
					<div class="spec-value">{{ item.qoh }} {{ item.units}}</div>
				</li>
			</ul>
		</div>
		<div class="col-md-3 text-left bold ml-0 ">
			<!-- keeping centered -->
			<div class="row m-0 p-0">
				<div class="col-md-12  m-0 p-0">
					<mat-form-field class="full-width text-right" appearance="outline">
						<mat-label>Dispatch Quantity</mat-label>
						<input matInput (input)="updateDispatchQty($event,item)" type="number" placeholder="Dispatch" [(value)]="item.dispatch">
						<mat-error>
						</mat-error>
					</mat-form-field>
				</div>
			</div>
		</div>
		<div class="col-md-3">
			<div class="row mt-0 mb-0 pt-0 pb-0">
				<div class="col-md-12 mt-0 mb-0 pt-0 pb-0" *ngFor="let tax of item.taxes; let i = index ">
					<mat-form-field>
						<small>{{ item.taxes[i].description }}</small>
						<mat-label class="text-right"> <b>Total:</b> {{ item.taxes[i].total | currency }}</mat-label>
						<input matInput type="text" class="text-left" placeholder="Dispatch" [value]="item.taxes[i].taxrate" (input)="setTax($event, item, i)">
					</mat-form-field>
				</div>
			</div>
		</div>
		<div class="col-md-3 mt-0 text-right">
			<ul class="list-group list-group-flush">
				<li class="list-group-item pt-0 pb-0">Sub Total: {{ item.linesubtotal | currency }}</li>
				<li class="list-group-item pt-0 pb-0">Tax Total: {{ item.taxtotal | currency }}</li>
				<li class="list-group-item pt-0 pb-0">Total {{ item.linetotal | currency  }}</li>
			</ul>
		</div>
	</div>
</div>
<div class="card-body mt-4 pb-0 pt-0 text-left">
	<ng-container>
		<div class="row">
			<div class="card col-md-4">
				<div class="card-header card-header-warning card-header-icon">
					<div class="card-icon">
						<i class="material-icons">local_shipping</i>
					</div>
					<ng-container *ngIf="order.header">
						<h5 class="card-title bold">
							Shipping {{ this.totals.freight | currency }} (Tax {{ freight_tax_total | currency }})
						</h5>
					</ng-container>
				</div>
				<div class="card-body mt-4 pb-0 pt-0 text-left" *ngIf="order.header">
					<h5>Shipping</h5>
					<div class="row">
						<div class="col-md-3">
							<mat-form-field>
								<mat-label class="text-right">Shipping Charge</mat-label>
								<input matInput type="text" class="text-left" [(value)]="order.header.freightcost" (input)="setFreightCharge($event)" placeholder="Shipping Charge">
							</mat-form-field>
						</div>
						<div class="col-md-5" *ngFor="let ftax of order.freight_tax; let i = index ">
							<mat-form-field>
								<mat-label class="text-right">
									{{ ftax.description}} {{ ftax.taxrate | percent}}
								</mat-label>
								<input matInput type="text" class="text-left" (input)="setFreightTax($event,ftax)" placeholder="" [(value)]="ftax.taxrate">
								<mat-hint *ngIf="order.header.freightcost">
									{{ ftax.taxrate * order.header.freightcost | currency}}
								</mat-hint>
							</mat-form-field>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-8 text-right mr-0 ml-auto">
				<ul class="list-group list-group-flush">
					<li class="list-group-item pt-0 pb-0">
						<h6>Invoice Totals</h6>
						<mat-divider></mat-divider>
					</li>
					<li class="list-group-item pt-0 pb-0"><b>SubTotal:</b> {{ totals.subtotal| currency }}</li>
					<li class="list-group-item pt-0 pb-0"><b>Shipping:</b> {{ totals.freight | currency }}</li>
					<li class="list-group-item pt-0 pb-0"><b>Tax:</b> {{ totals.tax | currency }}</li>
					<li class="list-group-item pt-0 pb-0"><b>Total:</b> {{ totals.total | currency }}</li>
				</ul>
			</div>
		</div>
		<div class="col-md-12 text-right">
			<button mat-raised-button color="warn" class="mt-3 col-2 mb-3" (click)="updateInvoice()">Update Invoice</button>
		</div>
	</ng-container>
</div>
