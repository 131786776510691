<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i> Customer Sales By Branch</h4>
						<p class="card-category text-right">CRPT</p>
					</div>
					<div class="card-body">
						<form class="navbar-form">
							<div class="row">
								<!-- Transaction Dates -->
								<div class="col-md-4">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Transaction Dates</mat-label>
										<mat-date-range-input [rangePicker]="picker">
											<input matStartDate [formControl]="fromdateCtrl" placeholder="Start date">
											<input matEndDate [formControl]="todateCtrl" placeholder="End date">
										</mat-date-range-input>
										<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
										<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
										<mat-date-range-picker #picker></mat-date-range-picker>
									</mat-form-field>
								</div>
								<!-- Branches -->
								<div class="col-md-4">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Branches</mat-label>
										<mat-select [formControl]="branchRef" multiple #branchFilter appSelectAll>
											<mat-option *ngFor="let k of branches" [value]="k.loccode">{{ k.locationname }}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<!-- Salespeople -->
								<div class="col-md-4">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Salespeople</mat-label>
										<mat-select [formControl]="salespersonRef" multiple #salesFilter appSelectAll>
											<mat-option *ngFor="let k of salespeople" [value]="k.salesmancode">{{ k.salesmanname }}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<!-- Consolidated or Branch -->
								<div class="col-md-4" *ngIf="!isPosUser">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Consolidated or Branch?</mat-label>
										<mat-select [formControl]="conBrRef">
											<mat-option *ngFor="let v of conbr" [value]="v.value">{{ v.label }}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<!-- Web Orders -->
								<div class="col-md-4" *ngIf="!isPosUser">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Web Orders?</mat-label>
										<mat-select [formControl]="placedFromRef">
											<mat-option *ngFor="let v of placedfrom" [value]="v.value">{{ v.label }}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<!-- Buttons -->
								<div class="col-md-4">
									<button mat-raised-button color="accent" class="mt-2" (click)="getCRPT()">
										<mat-icon class="p-0">search</mat-icon> Search
									</button>
									<span *ngIf="sending" class="ml-2">
										<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
											<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
										</svg>
									</span>
									<ng-container *ngIf="ot">
										&nbsp;&nbsp;
										<button class="btn btn-success btn-sm" (click)="$event.preventDefault(); exportXls()">
											<i class="fa fa-file-excel-o"></i> XLS
										</button>
										&nbsp;&nbsp;
										<button class="btn btn-danger btn-sm" (click)="$event.preventDefault(); exportPdf()">
											<i class="fa fa-file-pdf-o"></i> PDF
										</button>
									</ng-container>
								</div>
							</div>
						</form>
						<div class="table" *ngIf="orderResults">
							<ng-container *ngIf="ot">
								<h3>Customer Sales by Branch {{reportCriteria.fromdate | date:'MM/d/yy'}} - {{reportCriteria.todate | date: 'MM/d/yy'}}</h3>
								<h4>{{getReportSubtitle()}}</h4>
								<h5>Count: {{ ot.totalrecords }}</h5>
								<h5>Avg: {{ ot.avg | currency }}</h5>
								<h5>Total: {{ ot.total | currency}}</h5>
							</ng-container>
							<ng-container *ngFor="let o of orderResults; trackBy: trackByBranchName">
								<div class="card">
									<div class="card-body">
										<h5 class="card-title">{{ o.branchname }}: {{ getBranchNameByLoccode(o.branchname) }}</h5>
										<h5 class="card-title">Total: {{ o.total | currency }}</h5>
										<table class="table table-striped table-bordered">
											<thead class="thead-dark">
												<tr>
													<th scope="col" (click)="sortTable(o, 'br')">BR <mat-icon>{{ getSortIcon(o, 'br') }}</mat-icon></th>
													<th scope="col" (click)="sortTable(o, 'customer')">Customer <mat-icon>{{ getSortIcon(o, 'customer') }}</mat-icon></th>
													<th scope="col" (click)="sortTable(o, 'name')">Name <mat-icon>{{ getSortIcon(o, 'name') }}</mat-icon></th>
													<th scope="col" (click)="sortTable(o, 'salesman')">Salesman <mat-icon>{{ getSortIcon(o, 'salesman') }}</mat-icon></th>
													<th scope="col" (click)="sortTable(o, 'salesman_name')">Salesman Name <mat-icon>{{ getSortIcon(o, 'salesman_name') }}</mat-icon></th>
													<th scope="col" (click)="sortTable(o, 'sales')">Sales <mat-icon>{{ getSortIcon(o, 'sales') }}</mat-icon></th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let line of o.details">
													<td>{{ line.br }}</td>
													<td>
														<a class="link" (click)="viewCustomer(line.customer, line.br)">{{ line.customer }}</a>
													</td>
													<td>{{ line.name }}</td>
													<td>{{ line.salesman }}</td>
													<td>{{ line.salesman_name }}</td>
													<td>{{ line.sales | currency }}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</ng-container>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>