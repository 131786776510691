import { Component, OnDestroy, SimpleChanges, OnChanges, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, Pipe, PipeTransform, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


import { trigger, state, style, transition, animate, animation } from '@angular/animations';
import { CustomerService } from '../../services/customer.service';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { UsersService } from '../../services/users.service';
import { PurchasingService } from '../../services/purchasing.service';

import { OmsService } from '../../services/oms.service';


@Component({
	selector: 'app-order-entry-po',
	templateUrl: './order-entry-po.component.html',
	styleUrls: ['./order-entry-po.component.scss']
})
export class OrderEntryPoComponent implements OnInit {
	@Input() orderno: any = false;
	@ViewChild('purchasedetails') purchasedetails: ElementRef;
	@ViewChild('orderDetails') orderDetailsEle: ElementRef;
	po_vendors:any = [];
	order: any = false;
	created_pos: any = false;
	extra_pos: any = false;
	order_lines: any = false;
	loading_remote = false;
	remoteqtys: any = [];
	sending = false;
	purchase_item:any = false;
	purchordercreated_details:any = false;
	purchordercreated:any = false;
	modifed:any = false;
	user: any = [];
	config: any = [];
	podetails: any = false;
	remote_toggle = new FormControl(true, []);
	toggled: any = true;
	ready: boolean;

	constructor(private purchasingService: PurchasingService, private inventoryService: InventoryService, private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, public omsService: OmsService, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal, public usersService:UsersService) {

		this.config = this.globalsService.getApiConfig();
		this.globalSearchService.user.subscribe((results: any) => {
			if (results) {
				this.user = results.user;
			}
		});
	}

	ngOnInit(): void {
		this.remote_toggle.valueChanges.subscribe(newV => {
			this.toggled = newV;
			if(this.po_vendors) {
				this.po_vendors.forEach( po => {
					po.remote_order = newV;
				})
			}
		});
	}

	//PO AFTER ORDER

	addPoItem(item: any,po) {
		const newline = JSON.parse(JSON.stringify(item));
		newline.addon = true;
		newline.bin = 'NEED'
		po.lines.push(newline);
	}

	updateOrderBin(event:any, line: any) {
		line.bin = event.value;
		line.selected_option.bin = event.value;
	}

	removeAddon(item: any, po: any) {
		const index = po.lines.indexOf(item);
		po.lines.splice(index,1)
	}

	addPoItems(supplier: any) {

		const item = [{
			stockid: '',
			description: '',
			line_notes: '',
			qty: '',
			price: '',
		}];

		this.purchasingService.addToOrder(item,supplier).subscribe( (result) => {

		})
	}

	setPurchOrderOptions(line: any, selected: any) {
		const data = {
			orderno: this.orderno,
			orderlineno: line.orderlineno,
			option: selected
		}

		this.orderService.setSalesOrderPoOptions(data).subscribe( (results: any) => {
			this.getOrder();
			this.modalService.dismissAll();
			this.purchase_item = {};
			//.close(this.purchasedetails);
		});
	}


	createPurchaseOrders() {
		this.sending = true;

		this.purchasingService.createPurchaseOrders(this.po_vendors).subscribe( (results: any) => {
			this.getOrder();
			this.modalService.dismissAll();
			this.purchase_item = {};
			this.po_vendors = false;
			this.sending = false;
			this.purchordercreated = results.success;
			this.purchordercreated_details = results.message
			this.order.porequired = false;
			if(results.warn) {
				alert(results.message)

			}
			//.close(this.purchasedetails);
		});
	}

	viewPurchOrderOptions(line: any) {

		this.purchase_item = line;

		this.orderService.getPurchDataFromLine(line).subscribe( (results: any) => {
			this.purchase_item = results;
			this.modalService.open(this.purchasedetails, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {

			}, (reason) => {
				this.purchase_item = {};
			});
		});
	}


	updatePoVendors(details: any) {
		this.ready = false;
		this.order = details
		this.order_lines = details.details;
		this.created_pos = details.created_pos
		this.extra_pos = details.extra_pos

		const poitems = [];
		details.podetails.forEach((item: any) => {
			let already_ordered = 0;
			if(item.haspo) {
				already_ordered = item.haspo.quantiy_order;
			}

			const qty = (item.haspo) ? item.poqty - already_ordered : item.poqty;

			if(qty > 0 ) {
				if(item.po_options) {
				} else {
					// po option not selected
					item.po_options = item.purchdata[0];
				}

				const data = {
					vendor: item.po_options.supplierno,
					vendor_name: item.po_options.suppname,
					stockid: item.stockid ,
					orderqty: qty,
					orderlineno: item.orderlineno,
					description: item.description,
					price: parseFloat(item.po_options.price),
					options_selected: item.po_options,
					purchdata: item.purchdata,
					haspo: item.haspo
				}
				const result = (item.porequired) ? data : false;
				if(result) {
					poitems.push(result);
				}

			}
		});

		const all_vendors =  poitems.map( a => a.vendor);
		const unique_vendors = all_vendors.filter((x, i, a) => a.indexOf(x) == i)

		if(poitems.length) {

			//map data down: broken down by vendor
			const data = { poitems: poitems }
			this.loading_remote = true;
			this.purchasingService.getRemoteQty(data).subscribe( (results: any) => {
				this.loading_remote = false;
				this.remoteqtys = results;

				this.po_vendors = poitems.map( (item: any) => {

					const lines = poitems.filter( (items:any) => {
						return items.options_selected.supplierno == item.options_selected.supplierno;
					});

					lines.forEach((line:any, index) => {
						//should be empty if not there
						lines[index].availability = this.remoteqtys.filter( (avail_line: any) => {
							return avail_line.orderlineno == line.orderlineno;
						})[0].inventory;
					})

					const data  = {
						salesorder: this.orderno ,
						loccode: this.order.header.fromstkloc,
						vendor: item.options_selected.supplierno,
						vendordetails: item.options_selected,
						name: item.options_selected.suppname,
						lines: lines,
						remoteorder: this.remote_toggle.value
					}

					return data
				});

				const final_vendors = [];
				const final_vendor_pos = [];
				//todo rework this whole thing. lost the reductions in above.
				this.po_vendors.forEach( (vendor: any) => {
					const index = final_vendors.indexOf(vendor.vendor)
					if(index < 0){
						final_vendors.push(vendor.vendor)
						final_vendor_pos.push(vendor)
					}
				});

				//reduce down to just single vendors
				this.po_vendors  = final_vendor_pos
			});
			this.ready = true;
		}
	}

	updateItem(data: any, purchase_item: any) {
		///purchase_item.purchdata = data;

		// let itemdata = this.purchase_item.filter( (item:any) => {
		// 	return data.item.stockid == item.stockid
		// })[0]
		this.purchase_item.purchdata = data.purchdata;

		//let index = this.filtered_items.indexOf(itemdata);
		//this.filtered_items[index] = itemdata;

	}

	getOrder() {
		this.orderService.getOrderInvoice(this.orderno).subscribe((details: any) => {
			this.order = details
			this.order_lines = details.details;
			this.created_pos = details.created_pos
			this.extra_pos = details.extra_pos

			this.updatePoVendors(details);
		})
	}

	viewPo(orderno:any) {
		this.purchasingService.getPurchaseOrder(orderno).subscribe((order: any) => {
			this.podetails = order
			this.modalService.open(this.orderDetailsEle, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
		});
	}

	updateOrderQty(value: any, line:any) {
		line.orderqty = parseFloat(value);
	}

	editOrder(orderno: any) {
		this.orderService.loadSalesOrder(orderno).subscribe((result:any) => {
			const link = result.header.debtorno+'/'+result.header.branchcode;
			this.router.navigate(['/orders/entry/'+link]);
		})
	}

	ngOnChanges(changes: any) {
		this.getOrder();
	}


}
