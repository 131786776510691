import { Component, OnDestroy,SimpleChanges, OnChanges, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryService } from '../../../services/inventory.service';
import { VendorService } from '../../../services/vendor.service';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { TableModule } from 'primeng/table';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';


@Component({
	selector: 'app-inventory-attributes',
	templateUrl: './inventory-attributes.component.html',
	styleUrls: ['./inventory-attributes.component.scss'],
})
export class InventoryAttributesComponent implements OnInit {
	@Input() itemdetails: any
	@Input() inputdata: any
	@Input() clonestockid: any
	@Output() itemAttributesUpdate = new EventEmitter < any > ();
	@Output() updateing = new EventEmitter<any>();
	itemForm: UntypedFormGroup;
	files: any = [];
	brands: any = [];
	defaultbrandto: any = false;

	config: any = []
	user: any = []
	constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private inventoryService: InventoryService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, private globalsService: GlobalsService, private vendorService: VendorService) {

		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
			this.globalSearchService.user.subscribe(u => {
				this.user = u
			})
		});
	}


	ngOnInit(): void {

		this.inventoryService.getMfgs().subscribe(r => {
			this.brands = r
		});

		this.itemForm = this.fb.group({
			stockid: [this.itemdetails.stockid, [Validators.required]],
			language_id: ['1'],
			name: [this.itemdetails.description],
			description: [this.itemdetails.description],
			tag: [''],
			meta_title: [this.itemdetails.description],
			meta_description: [this.itemdetails.description],
			meta_keyword: [''],
			image: [''],
			brand: ['', [Validators.required]],
			downloadimage: [''],
			benefits: [this.inputdata?.model?.benefits],
			features: [this.inputdata?.model?.features],
			ingredients: [this.inputdata?.model?.ingredients],
		});

		this.inventoryService.getMfgsFromProductLine(this.itemdetails.lineid).subscribe( r => {
			if(r && r[0]) {
				this.itemForm.get('brand').setValue(r[0].manufacturer_id);
			}
		});

		if (this.clonestockid) {
			this.inventoryService.getItem({ stockid: this.clonestockid }).subscribe((results: any) => {

				this.itemForm.controls['name'].setValue(results.descriptions.name);
				this.itemForm.controls['brand'].setValue(results.item.manufacturer_id);
				this.itemForm.controls['description'].setValue(results.descriptions.description);
				this.itemForm.controls['meta_title'].setValue(results.descriptions.meta_title);
				this.itemForm.controls['meta_description'].setValue(results.descriptions.meta_description);
				this.itemForm.controls['meta_keyword'].setValue(results.descriptions.meta_keyword);
				//brand: []
			});
		}

		this.itemForm.valueChanges.subscribe(newValue => {
			this.updateing.emit(this.itemForm);
		})
	}
	updateItem() {
		if (this.itemForm.valid) {
			this.itemAttributesUpdate.emit(this.itemForm);

		}
	}

	onSelect(event) {
		this.files.push(...event.addedFiles);
		for (let i = 0; i < this.files.length; i++) {
			this.readFile(this.files[i]).then(fileContents => {
				//stuff
				const upload = {
					content: fileContents,
					name: event.addedFiles[0].name,
					mime: event.addedFiles[0].type,
					item: this.itemForm.get('stockid').value,
				}

				this.inventoryService.uploadFile(upload, this.itemForm.get('stockid').value).subscribe((result: any) => {
					this.itemForm.get('image').setValue(result.message)
				 });
			});
		}
	}

	onRemove(event) {
		this.files.splice(this.files.indexOf(event), 1);
	}

	private async readFile(file: File): Promise<string | ArrayBuffer> {
		return new Promise<string | ArrayBuffer>((resolve, reject) => {
		  const reader = new FileReader();

			reader.onload = e => {
				return resolve((e.target as FileReader).result);
			};

			reader.onerror = e => {
				console.error(`FileReader failed on file ${file.name}.`);
				return reject(null);
			};

			if (!file) {
				return reject(null);
			}
			reader.readAsDataURL(file);
		});

		this.files = [];
	}

	ngOnChanges(changes: any) {
		if(changes.inputdata) {
			if(changes.inputdata.currentValue) {
				const brand = this.brands.filter( (b:any) => {
					return b.name.toUpperCase() === this.inputdata.mfg.toUpperCase();
				})

				if(brand[0]) {
					this.itemForm.controls['brand'].setValue(brand[0].manufacturer_id);
				}
				if(this.inputdata.image) {
					this.itemForm.controls['downloadimage'].setValue(this.inputdata.image);
				}

				let name = '';

				// if(this.inputdata.mfg) {
				// 	name += this.inputdata?.mfg
				// }

				if(this.inputdata.name) {
					name += this.inputdata?.name
				}

				if(this.inputdata.size) {
					name += ' '+this.inputdata?.size
				}

				if(this.inputdata.size) {
					name += ' '+this.inputdata?.size
				}

				if(this.inputdata.loadindex) {
					name += ' '+this.inputdata?.loadindex
				}

				if(this.inputdata.speedrating) {
					name += ' '+this.inputdata?.speedrating
				}

				let barcode = '';
				if(this.inputdata.upc && this.inputdata.upc != '') {
					barcode = this.inputdata?.upc
				} else {
					barcode = this.inputdata?.itemcode
				}
				if(this.itemForm.controls['name']) {
					this.itemForm.controls['name'].setValue(name);
				}
				if(this.inputdata?.model?.benefits && this.itemForm.controls['benefits']) {
					this.itemForm.controls['benefits'].setValue(this.inputdata?.model?.benefits);
				}
				if(this.inputdata?.model?.features && this.itemForm.controls['features']) {
					this.itemForm.controls['features'].setValue(this.inputdata?.model?.features);
				}

				if(this.inputdata?.model?.ingredients && this.itemForm.controls['ingredients']) {
					this.itemForm.controls['ingredients'].setValue(this.inputdata?.model?.ingredients);
				}
			}
		}
	}


	updateForm(control, event){
		control.setValue(event);
	}
}
