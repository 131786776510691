import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit ,  SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { InventoryService } from '../../services/inventory.service';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { TableModule } from 'primeng/table';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

import { GlobalsService } from '../../services/globals.service';

import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
	selector: 'app-inventory-create',
	templateUrl: './inventory-create.component.html',
	styleUrls: ['./inventory-create.component.scss']
})
export class InventoryCreateComponent implements OnInit {
	user: any = [];
	itemForm: UntypedFormGroup;
	clonestockid: any = false;
	properties: any = false;
	stockmaster: any = [];
	purchdata: any = [];
	attributes: any = [];
	pricedata: any = [];
	selectedStep = 0;
	animation_length = 0;
	color = 'blue';
	stockid_exists = false;
	lookupitem: any = false;

	librarysearch = new UntypedFormControl('');
	librarySearchResults: any = [];
	librarySearchSelected: any = false;
	librarySearching: any = false;
	config: any = false;
	constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private inventoryService: InventoryService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, private globalsService: GlobalsService) {
		this.color = this.globalSearchService.getColor();

		this.globalSearchService.configsubscription.subscribe((results: any) => {
			this.config = results
		});

		this.globalsService.getItemSetup().subscribe(async (results: any) => {
			this.properties = results
		});
	}

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			if (params.id && params.id != '') {
				this.clonestockid = params.id
			}
		})
	}

	pushStockMasterChanges(event: any) {
		this.stockmaster = event.value
	}

	pushAttributeChanges(event: any) {
		this.attributes = event.value;
	}
	pushPurchData(event: any) {
		this.purchdata = event.value
	}

	puchPriceData(event:any) {
		this.pricedata = event
	}

 	addStockMaster(event: any) {
		this.stockmaster = event.value
		this.selectedStep = 1;
	}

	addAttributes(event: any) {
		this.attributes = event.value;
		this.selectedStep = 2;
	}

	addPurchData(event: any) {
		this.purchdata = event.value
		this.selectedStep = 3;
	}

	addPriceData(event: any) {
		this.pricedata = event
		this.selectedStep = 4;

		const data = {
			stockmaster: this.stockmaster,
			purchdata: this.purchdata,
			prices: this.pricedata,
		}
	}

	existingItem(event: any) {
		this.stockid_exists = event;

	}

	createItem() {
		const data = {
			stockmaster: this.stockmaster,
			purchdata: this.purchdata,
			prices: this.pricedata,
			attributes: this.attributes
		}

		if (!this.stockid_exists) {
			this.inventoryService.addItem(data).subscribe((result: any) => {
				if(this.config.env.package == 'tires'){
					this.selectedStep = 5;
				}else{
					this.router.navigate(['/inventory/view/' + result.stockmaster.stockid])
				}
			});
		}
	}

	searchTireLibrary() {
		this.inventoryService.librarySearch(this.librarysearch.value).subscribe((newValue: any) => {
			this.librarySearch(newValue);
		})
	}

	selectLibraryItem(item: any) {
		const data = { item: item }
		this.inventoryService.librarySelect(data).subscribe((lib: any) => {
			this.lookupitem = lib
			this.librarySearchResults = false;
			// let brandexists = this.brands.filter(r => {
			// 	return r.name == lib.mfg
			// });
			//
			// 			if (!brandexists.length) {
			// 				let newbrand = {
			// 					manufacturer_id: "-1",
			// 					mfg_code: lib.mfg,
			// 					name: lib.mfg,
			// 				}
			// 				this.brands.push(newbrand)
			// 				this.nonStockForm.get('brand').setValue(newbrand);
			// 			} else {
			// 				this.nonStockForm.get('brand').setValue(brandexists[0]);
			// 			}
			//
			// 			this.librarySearchSelected = lib
			// 			this.librarySearchResults = false;
			// 			this.nonStockForm.get('description').setValue(lib.name);
			//
			// 			this.nonStockForm.get('partno').setValue(lib.itemcode);
			// 			if (lib.upc) {
			// 				this.nonStockForm.get('barcode').setValue(lib.upc);
			// 			} else {
			// 				this.nonStockForm.get('barcode').setValue(lib.itemcode);
			// 			}
		});
	}

	librarySearch(search: any) {

		if (this.librarySearching) {
			this.librarySearching.unsubscribe()
		}

		const data = { keywords: search, details: this.librarysearch.value }

		this.librarySearching = this.inventoryService.librarySearch(data).subscribe((lib: any) => {
			this.librarySearchResults = lib
			this.librarySearching = false;
		});

	}

	back(): void {
		this.location.back()
	}
}
