<h4>Editing PLM for Product Line {{line_field}}</h4>
<div class="plm-container">
	<div *ngFor="let plm of plmdata" class="plm-item">
		<ng-container *ngIf="plm.editing === 1; then editplm else showplm"></ng-container>

		<ng-template #editplm>
			<div class="row mb-3">
				<div class="col-md-3 col-sm-6 col-12">
					<mat-form-field appearance="outline" color="accent" class="w-100">
						<mat-label>Price Level</mat-label>
						<mat-select [(value)]="plm.typeabbrev">
							<mat-option *ngFor="let plv of plvs" [value]="plv.typeabbrev">
								{{plv.typeabbrev}} - {{plv.sales_type}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-3 col-sm-6 col-12">
					<mat-form-field appearance="outline" color="accent" class="w-100">
						<mat-label>Price Basis</mat-label>
						<mat-select [(value)]="plm.pricebasis">
							<mat-option value="Cost">Cost</mat-option>
							<mat-option *ngFor="let plv of plvs" [value]="plv.typeabbrev">
								{{plv.typeabbrev}} - {{plv.sales_type}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-2 col-sm-6 col-12">
					<mat-form-field appearance="outline" color="accent" class="w-100">
						<mat-label>Operator</mat-label>
						<mat-select [(value)]="plm.operator">
							<mat-option value="1">+</mat-option>
							<mat-option value="2">x</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-2 col-sm-6 col-12">
					<mat-form-field appearance="outline" color="accent" class="w-100">
						<mat-label>Amount</mat-label>
						<input matInput [(ngModel)]="plm.amount" placeholder="Amount">
					</mat-form-field>
				</div>
				<div class="col-md-2 col-12 d-flex align-items-end justify-content-end">
					<button mat-raised-button class="mr-2 btn-success" (click)="savePLM(plm)">
						<i class="material-icons">save</i>
					</button>
					<button mat-raised-button color="accent" (click)="cancelPLM()">
						<i class="material-icons">cancel</i>
					</button>
				</div>
			</div>
		</ng-template>

		<ng-template #showplm>
			<div class="row mb-3">
				<div class="col-md-3 col-sm-6 col-12">
					<div class="text-left">
						<strong>Price Level:</strong> {{plm.typeabbrev}} - {{plm.sales_type}}
					</div>
				</div>
				<div class="col-md-3 col-sm-6 col-12">
					<div class="text-left">
						<strong>Price Basis:</strong> {{plm.pricebasis}}
					</div>
				</div>
				<div class="col-md-2 col-sm-6 col-12">
					<div class="text-center">
						<strong>Operator:</strong> {{plm.operator_label}}
					</div>
				</div>
				<div class="col-md-2 col-sm-6 col-12">
					<div class="text-right">
						<strong>Amount:</strong> {{plm.amount}}
					</div>
				</div>
				<div class="col-md-2 col-12 text-right">
					<button mat-raised-button color="primary" (click)="plm.editing=1">
						<i class="material-icons">edit</i>
					</button>
					<button mat-raised-button (click)="deletePLM(plm)">
						<i class="material-icons">delete</i>
					</button>
				</div>
			</div>
		</ng-template>
	</div>
</div>
