import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryService } from '../../services/inventory.service';
import { DashboardService } from '../../services/dashboard.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { PrintService } from 'app/services/print.service';

@Component({
	selector: 'app-need-bin-location',
	templateUrl: './need-bin-location.component.html',
	styleUrls: ['./need-bin-location.component.scss']
})
export class NeedBinLocationComponent {
	negativeItems: any = [];
	color: string = 'blue';
	subscritionsactive: any = false;
	filename = 'needs_bin';
	@ViewChild('print_table') printtable: ElementRef;

	constructor( private dashboardService: DashboardService, private globalSearchService: GlobalSearchService, private router: Router, private location: Location, private route: ActivatedRoute, private printService: PrintService ) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
		this.loadData();
	}

	loadData(): void {
		if (this.subscritionsactive) {
			this.subscritionsactive.unsubscribe();
		}
		this.subscritionsactive = this.dashboardService.getNeedLocation().subscribe(async (results: any) => {
			this.negativeItems = (results.data) ? results.data : [];
		});
	}

	viewItem(item: any): void {
		this.router.navigate(['/inventory/view/' + item.stockid]);
	}

	back(): void {
		this.location.back();
	}

	ngOnDestroy(): void {
		if (this.subscritionsactive) {
			this.subscritionsactive.unsubscribe();
		}
	}

	exportPdf() {

		const encoded: string = btoa(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}
	exportXls() {
		const encoded: string = btoa(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}
}
