<ng-container>
	<form [formGroup]="invoiceForm" *ngIf="invoiceForm" (keydown.enter)="$event.preventDefault()" class="no-overflow mt-3">
		<ng-template matStepLabel>Invoice Information</ng-template>
		<div class="row">
			<div class="col-md-4">
				<mat-form-field appearance="outline">
					<mat-label>Reference</mat-label>
					<input matInput required value="" formControlName="reference">
				</mat-form-field>
			</div>
			<div class="col-md-4">
				<mat-form-field appearance="outline">
					<mat-label>Delivery Date</mat-label>
					<input matInput [matDatepicker]="invoiceDate" formControlName="invoiceDate">
					<mat-datepicker-toggle matSuffix [for]="invoiceDate"></mat-datepicker-toggle>
					<mat-datepicker #invoiceDate></mat-datepicker>
				</mat-form-field>
			</div>

			<div class="col-md-4">
				<mat-form-field appearance="outline" class="text-right">
					<span matSuffix>%</span>
					<mat-label>Exchange Rate</mat-label>
					<input matInput required value="" formControlName="rate">
				</mat-form-field>
			</div>

			<div class="col-md-4">
				<mat-form-field appearance="outline">
					<mat-label>Due Date</mat-label>
					<input matInput [matDatepicker]="dueDate" formControlName="dueDate">
					<mat-datepicker-toggle matSuffix [for]="dueDate"></mat-datepicker-toggle>
					<mat-datepicker #dueDate></mat-datepicker>
				</mat-form-field>
			</div>
			<div class="col-md-4">
				<mat-form-field appearance="outline">
					<mat-label>Discount Date</mat-label>
					<input matInput [matDatepicker]="discountDate" formControlName="discountDate">
					<mat-datepicker-toggle matSuffix [for]="discountDate"></mat-datepicker-toggle>
					<mat-datepicker #discountDate></mat-datepicker>
				</mat-form-field>
			</div>
			<div class="col-md-4">
				<mat-form-field appearance="outline" class="text-right">
					<mat-label>Discount Amount $</mat-label>
					<span matPrefix>$</span>
					<input matInput formControlName="discountAmount" (input)="setDiscount($event)">
					<mat-error *ngIf="invoiceForm.get('discountAmount').invalid">Invalid Discount</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<mat-form-field class="full-width" appearance="outline">
					<mat-label>GRN's</mat-label>
					<mat-chip-list #grnList aria-label="Selected Pos">
						<mat-chip *ngFor="let grn of selected_grnbatches" (removed)="remove(grn)">
							GRN#: {{ grn.grnbatch }} PO#:{{grn.orderno}}
							<button matChipRemove>
								<mat-icon>cancel</mat-icon>
							</button>
						</mat-chip>
						<input type="text" matInput #grnInput placeholder="Select Purchase Order" [matChipInputFor]="grnList"  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)" formControlName="grns" [matAutocomplete]="auto">
					</mat-chip-list>
					<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
						<mat-option *ngFor="let grn of grn_batches" [value]="grn">
							GRN#: {{ grn.grnbatch }} PO#:{{grn.orderno}}
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>

			</div>
			<div class="col-md-6" [ngClass]="selected_grns.length >= 1 ? 'mt-3' : '' ">
				<app-gl-account-lookup (glselected)="addGl($event)"></app-gl-account-lookup>
			</div>
			<div class="col-md-12">
				<div class="table">
					<table class="table table-sm table-fixed" *ngIf="selected_grns.length || glcharges.length">
						<thead>
						<tr>
							<th>Item</th>
							<th>Desc</th>
							<th>Received</th>
							<th class="text-right">PO Price</th>
							<th class="text-center">Qty</th>
							<th class="text-right">Invoice Price</th>
							<th class="text-right">Line Total</th>
							<th class="text-right"></th>
						</tr>
						</thead>
						<tbody>
						<tr *ngFor="let grn of selected_grns">
							<td class="pt-4">{{ grn.itemcode }}</td>
							<td class="pt-4">{{ grn.itemdescription }}</td>
							<td class="pt-4" [ngClass]="grn.qtyrecd != grn.quantityord ? 'text-danger bold': 'text-success bold'">{{ grn.qtyrecd }} / {{grn.quantityord}}</td>
							<td class="pt-4 text-right">{{ grn.unitprice | currency}}</td>
							<td class="text-center">
								<mat-form-field appearance="outline" class="text-center">
									<mat-label>Invoice Qty.</mat-label>
									<input matInput required [(value)]="grn.toinvoice" (input)="setQty($event, grn)" appSelectOnFocus>
								</mat-form-field>
							</td>
							<td class="text-right">
								<mat-form-field appearance="outline" class="text-right ">
									<span matPrefix>$</span>
									<mat-label>Invoice Price</mat-label>
									<input matInput required [(value)]="grn.unitprice" (input)="setPrice($event, grn)" moneyPipe appSelectOnFocus>
								</mat-form-field>
							</td>
							<td class="pt-4 text-right">
								<b> {{ grn.unitprice * grn.toinvoice | currency}}</b>
							</td>
							<td class="pt-4 text-right">
								<button mat-flat-button (click)="removeLine(grn)">
									<i class="material-icons">remove</i>
								</button>
							</td>
						</tr>
						<tr *ngFor="let gl of glcharges">
							<td class="pt-4">{{ gl.account }}</td>
							<td class="pt-4">{{ gl.name }}</td>
							<td></td>
							<td></td>
							<td></td>
							<td class="text-right">
								<mat-form-field appearance="outline" class="text-right">
									<span matPrefix>$</span>
									<mat-label>Price</mat-label>
									<input matInput required [(value)]="gl.value" (input)="setGlPrice($event, gl)" moneyPipe appSelectOnFocus>
								</mat-form-field>
							</td>
							<td class="pt-4 text-right">
								<b> {{ gl.value * gl.quantity | currency}}</b>
							</td>
							<td class="pt-4 text-right">
								<button mat-flat-button (click)="removeGl(gl)">
									<i class="material-icons">remove</i>
								</button>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
				<div class="row">
					<div class="col-12 text-right" *ngIf="selected_grns.length || glcharges.length">
						<h5 class="mr-4">Sub-Total: {{ invoiceTotal | currency }}</h5>
						<ng-container>
							<div class="ml-auto text-right" *ngFor="let tax of vendor_data.taxes">
								<mat-form-field appearance="fill" class="ml-auto col-2 text-right">
									<span matPrefix>$</span>
									<mat-label>{{ tax.description }}</mat-label>
									<input matInput required value="{{tax.taxamount | number:'1.2-2'}}" (input)="setTaxValue($event, tax)" appSelectOnFocus>
								</mat-form-field>
							</div>
						</ng-container>
					</div>
					<div class="col-12 text-right">

								<h5 class="mr-4">Tax: {{ taxTotal | currency }}</h5>
								<h5 class="mr-4">Discount: {{ discount | currency }}</h5>
								<h5 class="mr-4">Total: {{ totalTotal | currency }}</h5>
								<button mat-raised-button [color]="invoiceForm.valid ? 'primary' : 'accent'" (click)="onSubmit()">Create Invoice</button>

					</div>
				</div>
			</div>
		</div>
	</form>
	<div class="text-center bold" *ngIf="!selected_grns.length  && !glcharges.length">
		Add Purchase Orders And/Or GL Charges
	</div>
</ng-container>
