<ng-template #orderDetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Purchase Order</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="podetails">
			<h4>PO # {{ podetails.poheader.orderno }}</h4>
			<div class="table">
				<table class="table table-sm table-bordered">
					<tr>
						<td>
							{{podetails.poheader.suppliercontact}}<br>
							{{podetails.poheader.suppdeladdress1}}<br>
							{{podetails.poheader.suppdeladdress2}}<br>
							{{podetails.poheader.suppdeladdress3}}, {{podetails.poheader.suppdeladdress4}} {{podetails.poheader.suppdeladdress5}}
						</td>
						<td>
							{{podetails.poheader.deladd1}}<br>
							{{podetails.poheader.deladd2}}<br>
							{{podetails.poheader.deladd3}}, {{podetails.poheader.deladd4}} {{podetails.poheader.deladd5}}
						</td>
					</tr>
				</table>
				<table class="table table-sm table-fixed">
					<tr>
						<th>ITEM</th>
						<th>DESC</th>
						<th class="text-center">QTY</th>
						<th class="text-center">RCVD</th>
						<th class="text-right">PRICE</th>
						<th class="text-right">TOTAL</th>
					</tr>
					<tr *ngFor="let item of podetails.polines">
						<td>{{item.itemcode}}</td>
						<td>{{item.itemdescription}}</td>
						<td class="text-center">{{item.quantityord}}</td>
						<td class="text-center">{{item.quantityrecd}}</td>
						<td class="text-right">{{item.unitprice | currency}}</td>
						<td class="text-right">{{item.unitprice * item.quantityord | currency}}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #purchasedetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Purchase Information</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row" *ngIf="purchase_item">
			<div [ngClass]="purchase_item.purchdata.length == 1 ? 'col-md-12': 'col-md-6'" *ngFor="let pdata of purchase_item.purchdata">
				<div class="card highlight" (click)="setPurchOrderOptions(purchase_item, pdata)" [ngClass]="(pdata.supplierid == purchase_item.options_selected.supplierno) ? 'highlighted' : ''">
					<div class="card-header text-center">
						<span matBadge="{{ pdata.preferred_sort }}" matBadgeSize="large" matBadgeColor="accent"></span>
						<h4 class="card-title text-center">{{ pdata.supplierid }} - {{ pdata.suppname }} <b>{{ pdata.price | currency }}</b></h4>
					</div>
					<div class="card-body text-center">

						<div class="col-12">
							<h5>{{ pdata.stockid }} {{ pdata.supplierdescription }}</h5>
						</div>
						<div class="col-12 p-0 m-0">
							<ul class="entry-list p-0 m-0" *ngFor="let remote of pdata.remote">
								<li>
									<div class="spec-label">{{ remote.feedlabel }}:</div>
									<div class="spec-value">{{ remote.qty }}</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<mat-divider></mat-divider>
			<app-purchase-data [item]="purchase_item" (newitemdata)="updateItem($event,purchase_item)"></app-purchase-data>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="col-12 ">

	<mat-divider></mat-divider>
	<ng-container *ngIf="created_pos">
		<h4 class="mt-3" *ngIf="created_pos.length"><b>Purchased Items</b></h4>
		<ng-container *ngFor="let c of created_pos">
			<div class="table border-bottom">
				<table class="table table-sm">
					<tr>
						<th></th>
						<th>Purchase Orders#</th>
						<th>Remote Order#</th>
						<th class="text-left">Description</th>
						<th class="text-left">Price</th>
						<th class="text-center">Qty</th>
						<th class="text-center">Total</th>
					</tr>
					<tr>
						<td>
							<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #menu="matMenu">
								<button mat-menu-item (click)="viewPo(c.purchase_orderno)">
									<mat-icon>visibility</mat-icon>
									<span>View</span>
								</button>
								<a href="{{ config.apiServer.baseUrl}}{{config.apiServer.poLink}}{{c.purchase_orderno}}" mat-menu-item>
									<mat-icon>print</mat-icon>
									<span>PDF</span>
								</a>
								<mat-divider></mat-divider>
								<a [routerLink]="'/purchasing/receive/'+c.purchase_orderno" mat-menu-item>
									<mat-icon>call_received</mat-icon>
									<span>Receive</span>
								</a>
							</mat-menu>

						</td>
						<td>{{ c.purchase_orderno }}</td>
						<td>
							{{ c.remoteordernumber }}
							<div *ngIf="c.remoteordernumber == ''" class="bg-danger pl-2 text-white ">
								REMOTE ORDER NOT PLACED <br>
								CONTACT VENDOR {{ c.supptel }}
							</div>
						</td>
						<td class="text-left">{{c.description}}</td>
						<td class="text-left">{{c.unitprice | currency}}</td>
						<td class="text-center">{{c.quantiy_order}}</td>
						<td class="text-left">{{c.unitprice * c.quantiy_order | currency}}</td>
					</tr>
				</table>
			</div>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="extra_pos">
		<ng-container *ngFor="let c of extra_pos">
			<div class="row border-bottom" (click)="viewPo(c.purchase_orderno)">
				<mat-divder></mat-divder>
				<div class="col-md-2 text-left"><b>Purchase Orders#:</b><br>{{ c.purchase_orderno }}</div>
				<div class="col-md-2 text-left">Remote Order#:<br>{{ c.remoteordernumber}} </div>
				<div class="col-md-5 text-left">Description: <br>{{c.description}}</div>
				<div class="col-md-3 text-center">Quantity<br>{{c.quantiy_order}}</div>
				<mat-divder></mat-divder>
			</div>
		</ng-container>
	</ng-container>

	<div class="col-12 mt-4 text-center mb-3" *ngIf="order.porequired">

		<div class="row" *ngIf="purchordercreated">
			<div class="col-12 mt-4 text-center">
				<h4>{{ purchordercreated_details }}</h4>
			</div>
		</div>


		<span><mat-slide-toggle class="toggle_button" [formControl]="remote_toggle">Remote PO</mat-slide-toggle></span>

		<ng-container *ngIf="!ready">
			<h4><b>Purchase Order<span *ngIf="po_vendors.length == 1 ? 's' : ''"></span> Required ({{po_vendors.length}})</b></h4>
			<span>
				<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
					<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
				</svg>
			</span>
		</ng-container>


		<ng-container *ngIf="po_vendors.length">
			<h4><b>Purchase Order<span *ngIf="po_vendors.length == 1 ? 's' : ''"></span> Required ({{po_vendors.length}})</b></h4>

			<div class="card mt-2" [ngClass]="po_vendors.length == 1 ? 'ml-auto mr-auto col-md-12': 'col-md-12'" *ngFor="let po of po_vendors">
				<h4 class="card-title text-left mt-3 ml-2 mb-0"><b>{{ po.vendor }}</b> {{ po.name }}</h4>

				<div class="card-body">
					<div class="row">
						<div class="col-12">
							<textarea matInput class="form-control filled" (input)="updatePoNote($event.target.value, po)" placeholder="Purchase Order Notes"></textarea>
						</div>
					</div>

					<div class="row " *ngFor="let line of po.lines;let i = index">
						<!--<ng-container *ngIf="line.availability">
						<div class="col-md-4 p-0" *ngFor="let remote of line.availability">
							<ul class="entry-list p-0 m-0 bold">
								<li>
									<div class="spec-label">{{ remote.feedlabel }}:</div>
									<div class="spec-value">{{ remote.qty }}</div>
								</li>
							</ul>
						</div>
						</ng-container>
						-->
						<div class="col-md-12">
							<h4 *ngIf="line.haspo">
								PO#{{ line.haspo.purchase_orderno  }}  x {{ line.haspo.quantiy_order }} placed for this order, Order Qty Reduced.
							</h4>
						</div>

						<div class="col-md-12 text-left">
							<div class="table table-full">
								<table class="table table-striped table-fixed table-sm">
									<tr>
										<th>
											<button mat-icon-button *ngIf="line.addon" (click)="removeAddon(line, po)">
												<mat-icon>remove</mat-icon>
											</button>
										</th>
										<th class="text-left">ITEM</th>
										<th class="w-150">DESC</th>
										<th class="w-50px text-left ">BIN</th>
										<ng-container *ngIf="line.availability">
											<th class="text-center" *ngFor="let remote of line.availability">
												{{ remote.feedlabel | uppercase }}
											</th>
										</ng-container>
										<th class="text-center">ORDER</th>
										<th class="text-right">PRICE</th>
										<th class="text-right">TOTAL</th>
										<th></th>
									</tr>
									<tr [ngClass]="line.haspo ? 'bg-danger' : ''">
										<td>
											<button class="mt-2" mat-icon-button aria-label="Change Vendor" color="accent" (click)="viewPurchOrderOptions(line)">
												<small>
													<mat-icon>edit</mat-icon>
												</small>
											</button>
										</td>
										<td class="text-left">{{ line.stockid }}</td>
										<td class="w-200 text-left">{{ line.description }}</td>
										<td class="w-50px text-left ">
											<ng-container *ngIf="!line.addon">
												{{ orderno }}
											</ng-container>
											<ng-container *ngIf="line.addon">
												<mat-radio-group aria-label="NEED/NONSTOCK" class="radio-group" (change)="updateOrderBin($event, line)" [(value)]="line.bin">
													<mat-radio-button value="NEED" class="radio-button">NEED</mat-radio-button>
													&nbsp;&nbsp;
													<mat-radio-button value="NONSTOCK" class="radio-button">NONSTOCK</mat-radio-button>
												</mat-radio-group>

											</ng-container>
										</td>
										<ng-container *ngIf="line.availability">
											<td class="text-center" *ngFor="let remote of line.availability">
												{{ remote.qty }}
											</td>
										</ng-container>
										<td class="text-center">
											<input matInput required [(value)]="line.orderqty" class="text-center form-control" (input)="updateOrderQty($event.target.value, line)">
										</td>
										<td class="text-right">{{ line.price | currency}}</td>
										<td class="text-right">{{ line.price * line.orderqty | currency }}</td>
										<td class="text-center">
											<button mat-mini-fab color="primary" aria-label="Add PO Item" (click)="addPoItem(line, po)">
												<mat-icon>call_split</mat-icon>
											</button>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 text-center mb-2">
				<div class="col-md-12 ml-auto mr-auto mt-4 " *ngIf="sending">
					<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
						<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
					</svg>
				</div>
				<button mat-raised-button color="accent" (click)="createPurchaseOrders()" *ngIf="!sending">Create Purchase Order<span *ngIf="po_vendors.length == 1 ? 's' : ''"></span>
				</button>
			</div>
			<mat-divider></mat-divider>
		</ng-container>
	</div>
	<div class="row mt-2">
		<mat-divider></mat-divider>
		<br />
		<!--
		<div class="col-md-2 ml-auto mr-auto mt-2 mb-2">
			<a mat-stroked-button color="accent" (click)="editOrder(orderno)">Edit Order</a>
		</div>
		<div class="col-md-2 ml-auto mr-auto mt-2 mb-2">
			<a href="{{ reprintlink }}" mat-stroked-button color="accent">Re-print Pick</a>
		</div>
		<div class="col-md-3 ml-auto mr-auto mt-2 mb-2">
			<a href="{{ pickingInvoice }}" mat-stroked-button color="accent">Re-print Pick Invoice</a>
		</div>

		<div class="col-md-2 ml-auto mr-auto mt-2 mb-2">
			<a [routerLink]="'dispatch'" mat-stroked-button color="warn" class="ml-2 mr-2">Invoice</a>
		</div>

		<div class="col-md-2 ml-auto mr-auto mt-2 mb-2">
			<a [routerLink]="'/orders/pick/'+orderno" mat-stroked-button color="primary">Pick</a>
		</div>

		<div class="col-md-2 ml-auto mr-auto mt-2 mb-2">
			<button mat-raised-button color="primary" [routerLink]="'/orders/entry'">New Order</button>
		</div>
	-->
	</div>
	<mat-divider></mat-divider>
</div>
