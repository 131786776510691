import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { OrdersService } from '../services/orders.service';
import { Order, OrderHeader } from '../classes/orders';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { TableModule } from 'primeng/table';
import * as FileSaver from 'file-saver';

import { trigger, state, style, transition, animate } from '@angular/animations';


@Component({
	selector: 'app-dispatches',
	templateUrl: './dispatches.component.html',
	styleUrls: ['./dispatches.component.scss']
})
export class DispatchesComponent implements OnInit {
	dispatches;
	trucks;
	ready: boolean;
	constructor(private orderService: OrdersService, private location: Location, public cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.ready = false;
		this.orderService.getDispatches().subscribe((results: any) => {
			this.dispatches = results.dispatches
			this.trucks = results.othertrucks
			this.ready = true;
		});

	}


	back(): void {
		this.location.back()
	}

}
