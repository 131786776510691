<ng-template #glc let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">GL Class</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="table w-100">
			<table class="table table-sm w-100">
				<tr>
					<th>ITEM</th>
					<th>DESC</th>
					<th class="text-center w-10">QOH</th>
				</tr>
				<tr *ngFor="let i of viewingglc.details">
					<td>{{i.stockid}}</td>
					<td>{{i.description}}</td>
					<td class="text-center w-10">{{i.qoh}}</td>
				</tr>
			</table>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #glEdit let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Edit</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-header text-center">
					<h4 class="card-title text-center"></h4>
				</div>
				<div class="card-body text-center">
					<div class="row">
						<div class="col-12">
							<form [formGroup]="glClassForm">

								<mat-form-field appearance="outline">
									<mat-label>Code</mat-label>
									<input type="text" class="text-left " matInput formControlName="code" />
								</mat-form-field>

								<mat-form-field appearance="outline">
									<mat-label>Name</mat-label>
									<input type="text" class="text-left " matInput formControlName="name" />
								</mat-form-field>

								<button mat-stroked-button color="success" aria-label="" (click)="saveGLClass()" class="mt-2">
									<mat-icon>save</mat-icon> Save
								</button>

							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<button mat-stroked-button color="success" aria-label="" (click)="addGLClass()" class="mt-2 mb-2">
	<mat-icon>add</mat-icon> Add GL Class
</button>

<div class="container-fluid">
	<mat-form-field appearance="fill">
		<mat-label>Search</mat-label>
		<input type="text" class="text-left" matInput [formControl]="glclasssearch" />
	</mat-form-field>

	<div class="table ov">
		<div class="text-right"> Results ({{searchingglclass.length}})</div>
		<table class="table table-sm table-striped table-hover table-bordered table-fixed ov">
			<thead class="header">
				<tr>
					<th class="header" scope="col">GL Class Code</th>
					<th class="header" scope="col">Description</th>
					<th class="header text-center" scope="col">
						#Items</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let p of searchingglclass">

					<td>
						<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More">
							<mat-icon>more_vert</mat-icon>
						</button>
						<mat-menu #menu="matMenu">
							<button mat-menu-item (click)="viewProducts(p)">
								<mat-icon>view_list</mat-icon> View Items
							</button>
							<button mat-menu-item color="danger" aria-label="" (click)="removeGLC(p)">
								<mat-icon>remove</mat-icon> Remove
							</button>
							<button mat-menu-item color="success" aria-label="" (click)="editGLC(p)">
								<mat-icon>edit</mat-icon> Edit
							</button>

						</mat-menu>
						{{ p.code }}
					</td>
					<td> {{ p.name }}</td>
					<td class="text-center">{{ p.count }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
