<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent">
	<mat-tab label="Single Bin / New Bin">
		<div class="card">
			<div class="card-header">
				<h5 class="card-title"><i class="fa fa-exclamation-triangle"></i> Print Single Bin / New Bin Label</h5>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-6">
						<!-- -->
						<mat-form-field appearance="outline">
							<mat-label>Label Text</mat-label>
							<input type="text" [maxLength]="8" [(ngModel)]="bintext" (ngModelChange)="bintext = $event.toUpperCase()" requred placeholder="Label Text" aria-label="Label Text" matInput
								[formControl]="singleLabel"
								[matAutocomplete]="singleSearch"
								>
							<mat-autocomplete autoActiveFirstOption #singleSearch="matAutocomplete">
								<mat-option *ngFor="let bin of filteredOptions" [value]="bin.location">
									{{bin.location }}
								</mat-option>
							</mat-autocomplete>

						</mat-form-field>
					</div>
					<div class="col-md-6">
						<h5 [innerHTML]="neworcurrent"></h5>
						<button mat-raised-button color="accent" (click)="printSingleLabel()">
							<mat-icon>print</mat-icon> Print
						</button>
					</div>
				</div>
			</div>
		</div>
	</mat-tab>
	<mat-tab label="Print a range of Bins">
		<div class="card">
			<div class="card-header">
				<h5 class="card-title">
					<i class="fa fa-exclamation-triangle"></i> Print a range Of Bin Labels
				</h5>
			</div>
			<div class="card-body">
				<form [formGroup]="fromToBin" (ngSubmit)="printBinRange()">
					<div class="row">
						<div class="col-md-4">
							<mat-form-field appearance="outline">
								<mat-label>Starting Bin</mat-label>
								<mat-select requred formControlName="bin_from">
									<mat-option *ngFor="let bin of bins" [value]="bin.location">{{bin.location}}</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="outline">
								<mat-label>Ending Bin</mat-label>
								<mat-select requred formControlName="bin_to">
									<mat-option *ngFor="let bin of bins" [value]="bin.location">{{bin.location}}</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<h5 *ngIf="fromToBin.valid">{{fromToBin.get('bin_from').value}} - {{fromToBin.get('bin_to').value }}</h5>

							<button mat-raised-button color="accent">
								<mat-icon>print</mat-icon> Print
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</mat-tab>
	<mat-tab label="Storage Label">
		<div class="card">
			<div class="card-header">
				<h5 class="card-title"><i class="fa fa-exclamation-triangle"></i> Print Storage Label</h5>
			</div>
			<div class="card-body" *ngIf="storageLabel">
				<form [formGroup]="storageLabel" (ngSubmit)="printStorageLabel()">

					<h5><b>Next Tag ID</b>: <b>{{ storageLabel.get('storage_id').value }}</b></h5>
					<div class="row">
						<div class="col-sm-6">
							<mat-form-field appearance="outline">
								<mat-label>Barcode</mat-label>
								<input type="text" requred formControlName="barcode" placeholder="barcode" aria-label="barcode" matInput>
							</mat-form-field>
						</div>
						<div class="col-sm-6">
							<mat-form-field appearance="outline">
								<mat-label>BIN</mat-label>
								<input type="text" requred formControlName="bin" placeholder="bin" aria-label="bin" matInput
									[matAutocomplete]="binSearch"
									[(ngModel)]="storagebin"
									(ngModelChange)="storagebin = $event.toUpperCase()"
									>
								<mat-autocomplete autoActiveFirstOption #binSearch="matAutocomplete">
									<mat-option *ngFor="let bin of filteredOptions" [value]="bin.location">
										{{bin.location }}
									</mat-option>
								</mat-autocomplete>

							</mat-form-field>
						</div>
						<div class="col-sm-6">
							<mat-form-field appearance="outline">
								<mat-label>Date</mat-label>
								<input matInput formControlName="daterec" [matDatepicker]="daterec">
								<mat-datepicker-toggle matSuffix [for]="daterec"></mat-datepicker-toggle>
								<mat-datepicker #daterec></mat-datepicker>
							</mat-form-field>
						</div>
						<div class="col-sm-6">
							<mat-form-field appearance="outline">
								<mat-label>Customer#</mat-label>
								<input type="text" requred
									formControlName="customer"
									placeholder="Customer"
									aria-label="Customer" matInput>
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-6">
							<mat-form-field appearance="outline">
								<mat-label>Description</mat-label>
								<input type="text" requred formControlName="description" placeholder="description" aria-label="description" matInput>
							</mat-form-field>
						</div>
						<div class="col-sm-6">
							<mat-form-field appearance="outline">
								<mat-label>Quantity</mat-label>
								<input type="number" requred formControlName="parcels" placeholder="parcels" aria-label="parcels" matInput>
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-6">
							<button mat-raised-button color="accent">
								<mat-icon>print</mat-icon> Print
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</mat-tab>
	<mat-tab label="Tire Label">
		<div class="card">
			<div class="card-header">
				<h5 class="card-title"><i class="fa fa-exclamation-triangle"></i> Print Tire Label</h5>
			</div>
			<div class="card-body" *ngIf="tireLabel">
				<form [formGroup]="tireLabel" (ngSubmit)="printTireLabel()">

					<div class="row">
						<div class="col-sm-6">
							<mat-form-field appearance="outline">
								<mat-label>Part Number</mat-label>
								<input type="text" requred formControlName="partno" placeholder="partno" aria-label="partno" matInput (input)="itemSearch()">
							</mat-form-field>
							<ng-container *ngIf="sending">
								<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
							</ng-container>
							<ng-container *ngIf="searchResults">
								<div class="row mt-1 mb-1 border-bottom" *ngFor="let i of searchResults">
									<div (click)="setValue(i)">
										<b>{{ i.stockid }}</b> {{ i.description }}
									</div>
								</div>
							</ng-container>

						</div>
						<div class="col-sm-6">
							<mat-form-field appearance="outline">
								<mat-label>Description</mat-label>
								<input type="text" requred formControlName="description" placeholder="description" aria-label="description" matInput>
							</mat-form-field>
						</div>
						<div class="col-sm-6">
							<mat-form-field appearance="outline">
								<mat-label>Tire Size</mat-label>
								<input matInput formControlName="size">
							</mat-form-field>
						</div>
						<div class="col-sm-6">
						</div>
					</div>
					<div class="row">
						<div class="col-sm-6">
							<button mat-raised-button color="accent">
								<mat-icon>print</mat-icon> Print
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</mat-tab>
</mat-tab-group>
