<div class="table" #print_table>
	<button class="btn btn-success" (click)="exportXls();"><i class="fa fa-file-excel-o"></i></button>
	&nbsp;&nbsp;
	<button class="btn btn-danger" (click)="exportPdf();"><i class="fa fa-file-pdf-o"></i></button>
	<table class="table table-sm ">
		<thead>
		<tr>
			<th>Item#</th>
			<th>Description</th>
			<th>Bin</th>
			<th>Qty</th>
		</tr>
		</thead>
		<tbody>
		<tr *ngFor="let d of data">
			<td><a [routerLink]="'/inventory/view/'+d.stockid">{{ d.stockid}}</a></td>
			<td>{{ d.description}}</td>
			<td>{{ d.bin}}</td>
			<td>{{ d.quantity}}</td>
		</tr>
		</tbody>
	</table>
</div>
