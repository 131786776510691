import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';

import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { PurchasingService } from '../../services/purchasing.service';
import { InventoryService } from '../../services/inventory.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
	selector: 'app-purchasing-brand-priority',
	templateUrl: './purchasing-brand-priority.component.html',
	styleUrls: ['./purchasing-brand-priority.component.scss']
})
export class PurchasingBrandPriorityComponent implements OnInit {
	color = 'blue';
	brands: any = [];
	vendors: any = [];
	priorities: any = [];
	vendor = new UntypedFormControl('');
	constructor(private inventoryService: InventoryService, private purchasingService: PurchasingService, private globalSearchService: GlobalSearchService, private location: Location, private route: ActivatedRoute, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
		this.inventoryService.getBrands().subscribe(results => {
			this.brands = results;
		})

		this.purchasingService.getSuppliers('').subscribe((results: any) => {
			this.vendors = results;
		});
	}

	loadPriorities(id: any) {
		this.inventoryService.getBrandPriorities(id).subscribe(results => {
			this.priorities = results;
		})
	}

	removeVendor(vendor: any, brand: any) {

		const index = brand.priorities.indexOf(vendor)
		brand.priorities.splice(index, 1)
		if(!brand.priorities.length) {
			brand.priorities = false
		}

		const data = { brand : brand, remove: vendor }
		this.inventoryService.removeBrandPriorities(data).subscribe(result => {

		});

	}

	addVendor(brand: any) {
		let proceed = true;
		if(this.vendor.valid) {
			if (!brand.priorities) {
				brand.priorities = [];
			} else {

				const iva = brand.priorities.filter( (b:any) =>  {
					return b.supplierid == this.vendor.value.supplierid
				})

				if(iva.length) {
					proceed = false;
				}
			}
			if(proceed) {
				brand.priorities.push(this.vendor.value)
				const data = { brand : brand, priorities: brand.priorities }
				this.inventoryService.updateBrandPriorities(data).subscribe(result => {
				});
			}
		}

	}

	drop(event: CdkDragDrop < string[] > , brand: any) {

		moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		const data = { brand : brand, priorities: event.container.data }
		this.inventoryService.updateBrandPriorities(data).subscribe(result => {

		});

	}

	back(): void {
		this.location.back()
	}

}
