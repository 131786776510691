import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, Validators, ValidationErrors, UntypedFormControl, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { SystemService } from '../../../services/system.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
	selector: 'app-information',
	templateUrl: './information.component.html',
	styleUrls: ['./information.component.scss']
})
export class InformationComponent {

	editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: '300',
		minHeight: '300',
		maxHeight: '300',
		width: 'auto',
		minWidth: '0',
		translate: 'yes',
		enableToolbar: true,
		showToolbar: true,
		defaultParagraphSeparator: '',
		defaultFontName: '',
		defaultFontSize: '',
		fonts: [
			{ class: 'arial', name: 'Arial' },
			{ class: 'times-new-roman', name: 'Times New Roman' },
			{ class: 'calibri', name: 'Calibri' },
			//{ class: 'comic-sans-ms', name: 'Comic Sans MS' }
		],
		customClasses: [{
			name: 'quote',
			class: 'quote',
		},
		// {
		// 	name: 'redText',
		// 	class: 'redText'
		// },
		{
			name: 'Title',
			class: 'titleText',
			tag: 'h2',
		},
		],
		sanitize: true,
		toolbarPosition: 'top',
		toolbarHiddenButtons: [
			[],
			['fontSize']
		]
	};

	descriptionHtmlContent: JSON;
	color: any = '';
	config: any = [];
	user: any = [];
	editing: any = false;
	editFormGroup: UntypedFormGroup;
	editinfo: any = [];
	information: any = [];
	storeOptions: any = [];
	layouts: any = [];

	constructor(private modalService: NgbModal, private fb: UntypedFormBuilder, private globalSearchService: GlobalSearchService, private systemService: SystemService) {

		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
			this.globalSearchService.user.subscribe(u => {
				this.user = u
			})
		});

		this.systemService.getStores().subscribe(v => {

			this.storeOptions = v;
		});

		this.systemService.getLayouts().subscribe(v => {

			this.layouts = v;
		});

		this.refreshInfo();
		this.editFormGroup = this.fb.group({
			information_id: ['', []],
			title: ['', [Validators.required]],
			description: ['', [Validators.required]],
			meta_title: ['', []],
			meta_description: ['', []],
			meta_keyword: ['', []],
			status: ['1', []],
			bottom: ['0', []],
			sort_order: ['0', []],
			stores: [
				['0'],
				[]
			],
			layout: ['', []],
		});
	}
	refreshInfo() {
		this.systemService.getInfo().subscribe(v => {

			this.information = v;
			this.editing = false;
		});
	}

	editInfo(e: any) {
		this.editFormGroup = this.fb.group({
			information_id: [e.information_id, []],
			title: [e.title, [Validators.required]],
			description: [e.description, [Validators.required]],
			meta_title: [e.meta_title, []],
			meta_description: [e.meta_description, []],
			meta_keyword: [e.meta_keyword, []],
			status: [e.status, []],
			bottom: [e.bottom, []],
			sort_order: [e.sort_order, []],
			stores: [e.stores, []],
			layout: [e.layout, []],
		});
		this.editinfo = e;
		this.editing = true;
	}
	newinfo() {
		this.editFormGroup = this.fb.group({
			information_id: ['', []],
			title: ['', [Validators.required]],
			description: ['', [Validators.required]],
			meta_title: ['', []],
			meta_description: ['', []],
			meta_keyword: ['', []],
			status: ['1', []],
			bottom: ['0', []],
			sort_order: ['0', []],
			stores: [
				['0'],
				[]
			],
			layout: ['', []],
		});
		this.editinfo = [];
		this.editing = true;
	}
	cancelEdit() {
		this.editinfo = [];
		this.editing = false;
	}
	saveEdit() {
		this.systemService.saveInfo(this.editFormGroup.value).subscribe((data) => {
			this.editing = false;
			this.refreshInfo();
		});

	}

	deleteInfo(e) {
		if (confirm('Delete Information Page: ' + e.title + '?')) {
			this.systemService.deleteInfo({ information_id: e.information_id }).subscribe((r) => {
				this.refreshInfo();
			});
		}
	}
	back() {

	}
}
