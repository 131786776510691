<ng-template #emailData let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Confirm Email Address</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3">
			<mat-form-field appearance="outline">
				<mat-label>Email Address</mat-label>
				<input matInput [(ngModel)]="sendemail" />
			</mat-form-field>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-raised-button color="primary" (click)="pushEmail()">
			<mat-icon>email</mat-icon> Send
		</button>
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-5">
						<h4 class="card-title" id="headof"><i class="material-icons">arrow_left</i> Account Credited</h4>
						<p class="card-category">
							<b></b>
						</p>
					</div>
					<div class="card-body">
						<div class="col-12 text-center">
							<h1 class="text-success">SUCCESS</h1>
							<h4 class="bold">DOCUMENT#: {{ invoice.transno }}</h4>
							<h4 class="bold">INVOICE#: {{ invoice.order_ }}</h4>
							<h4 class="bold">CUSTOMER#: {{ invoice.debtorno }}.{{ invoice.branchcode }}</h4>
`						</div>
						<div class="col-12 text-center">
							<div class="circle-loader load-complete">
								<div class="checkmark-complete draw checkmark"></div>
							</div>
						</div>
						<div class="col-12 text-center">
							<a href="{{ reprintlink }}" mat-raised-button color="warn"><i class="fa fa-file-pdf-o"> </i>&nbsp;Print Credit</a>
							&nbsp;&nbsp;<a mat-raised-button class="btn btn-warning " [routerLink]="'/customers/view/'+invoice.debtorno"> View Customer </a>


							&nbsp;&nbsp;<button (click)="sendEmail(invoice)" mat-raised-button color="warn">Email Credit Note</button>

						</div>
						<ng-container *ngIf="customerdata">
							<h4>{{ customerdata.aged.aged }}</h4>
							<app-payment [customer]="customerdata" ></app-payment>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
