<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-5">
						<h4 class="card-title ">Reports</h4>
						<p class="card-category text-right"> Available reports</p>
					</div>
					<div class="card-body">
						<div class="table-responsive">
							<mat-form-field appearance="fill">
								<mat-label>Search</mat-label>
								<input type="text" class="text-right mt-0 pt-0" placeholder="Search" [formControl]="filterTerm" aria-label="Search" matInput />
							</mat-form-field>
							<div class="text-right">
								<h5>Results {{reports.length}}</h5>
							</div>
							<div class="table" *ngIf="reports">
								<div class="row m-0 p-0" >
									<ng-container *ngFor="let g of groups">
										<ng-container *ngIf="g !='-' && getGroupReports(g).length">
											<div class="card col-3 mt-0" >
												<div class="card-header card-header-icon card-header-rose">
													<div class="card-icon">
														<i class="material-icons {{g}}"></i>
													</div>
													<h4 class="card-title"><b>{{ g | uppercase }}</b></h4>
												</div>
												<div class="card-body">
													<div class="row border-bottom" *ngFor="let report of getGroupReports(g)">
														<a [routerLink]="'view/'+report.type+'/'+report.file"> {{ report.title }} </a>
													</div>
												</div>
											</div>
										</ng-container>
									</ng-container>
									<ng-container *ngFor="let g of groups">
										<ng-container *ngIf="g =='-' && getGroupReports(g).length">
											<div class="card col-12 ml-auto mr-auto mt-0" >
												<div class="card-header card-header-icon card-header-rose">
													<div class="card-icon">
														<i class="material-icons">filter_list</i>
													</div>
													<h4 class="card-title"><b>MISC</b></h4>
												</div>
												<div class="card-body">
													<div class="row border-bottom" *ngFor="let report of getGroupReports(g)">
														<a [routerLink]="'view/'+report.type+'/'+report.file"> {{ report.title }} </a>
													</div>
												</div>
											</div>
										</ng-container>
									</ng-container>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
