<div class="main-content">
	<div class="col-md-12">
		<div class="container-fluid tp-box" [@flipState]="flip">
			<div class="backside tp-box__side tp-box__back" [ngClass]="flip != 'inactive' ? '' : 'd-none'">
				<div class="card">
					<div class="card-header card-header-{{color}}-5" *ngIf="flip != 'inactive'">
						<h4 class="card-title pointer"> <i class="material-icons" (click)="toggleFlip('na', '')">arrow_left</i> {{ fliptitle }} </h4>
						<p class="card-category"></p>
					</div>
					<div class="card-body" *ngIf="data">
						<app-open-orders (orderloaded)="selectItemLookup($event)" (preorderloaded)="selectItemLookup($event)" (orderinvoice)="invoiceOrder($event)" [flipfilter]="flipdata"></app-open-orders>
					</div>
				</div>
			</div>
			<div class="row tp-box__side tp-box__front" id="frontside" *ngIf="picking">
				<div class="col-lg-4 col-md-4 col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-warning card-header-icon">
							<div class="card-icon">
								<i class="material-icons">border_outer</i>
							</div>
							<p class="card-category">Open Orders</p>
							<h3 class="card-title">
								<span *ngIf="picking">{{ picking.totalcount }}</span>
								<span *ngIf="!picking">
									<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
								<span *ngIf="notready != 'notready'"></span>
								<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg></span>
							</h3>

							<small *ngIf="picking">
								<p class="card-category text-info text-justify mt-3 text-right">
									<span class=" ml-1 mr-1 pointer" (click)="toggleFlip('allone','', true, allone)"> ( {{ allone.length }} ) 3-5 Days </span>
									<span class="ml-1 mr-1 pointer" (click)="toggleFlip('alltwo','', true, alltwo)"> ( {{ alltwo.length }} ) 5-7 Days </span>
									<span class="ml-1 mr-1 pointer" (click)="toggleFlip('allthree','', true, allthree)"> ( {{ allthree.length }} ) > 7 Days </span>
								</p>
							</small>
						</div>
						<div class="card-footer">
							<div class="stats" *ngIf="picking.totalcount">
								<a (click)="toggleFlip('openorders','')" class="pointer"><i class="material-icons">visibility</i>
									View Now</a>
							</div>
							<div class="stats" *ngIf="!picking.totalcount">
								<br />
							</div>
						</div>
					</div>
				</div>


				<div class="" [ngClass]="{'col-lg-4 col-md-4 col-sm-6': viewing_title!='Open Quotes', 'col-lg-12 col-md-12 col-sm-12': viewing_title=='Open Quotes'}" >
					<app-customer-open-quotes (viewData)="toggleFlipTest($event)" ></app-customer-open-quotes>
				</div>

				<!-- <div class="col-lg-4 col-md-4 col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-rose card-header-icon">
							<div class="card-icon">
								<i class="material-icons">notifications_paused</i>
							</div>
							<p class="card-category">Snoozed</p>

							<h3 class="card-title">

								<span *ngIf="picking">{{ picking.snoozedcount }}</span>
								<span *ngIf="!picking">
									<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
								<span *ngIf="notready != 'notready'"></span>
								<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg></span>
							</h3>
						</div>
						<p class="card-category text-info text-justify mt-3 text-right">
							&nbsp;
						</p>

						<div class="card-footer">
							<div class="stats" *ngIf="picking.snoozedcount">
								<a (click)="toggleFlip('snoozed','')" class="pointer"><i class="material-icons">visibility</i>
									View Now</a>
							</div>
							<div class="stats" *ngIf="!picking.snoozedcount">
								<br />
							</div>
						</div>
					</div>
				</div> -->

				<div class="col-lg-4 col-md-4 col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-danger card-header-icon">
							<div class="card-icon">
								<i class="material-icons">menu_open</i>
							</div>
							<p class="card-category">Open RMA's</p>
							<h3 class="card-title">
								<span *ngIf="credits.length">{{ credits.length }}</span>
								<span *ngIf="!credits">
									<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
								<span *ngIf="notready != 'notready'"></span>
								<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg></span>
							</h3>
							<p class="card-category text-info text-justify mt-3 text-right">
								&nbsp;
							</p>
						</div>
						<div class="card-footer">
							<div class="stats" *ngIf="credits.length">
								<a [routerLink]="'/credits/open-credits'" class="pointer"><i class="material-icons">visibility</i>
									View Now</a>
							</div>
							<div class="stats" *ngIf="!credits.length">
								<br />
							</div>
						</div>
					</div>
				</div>

				<div class="col-lg-4 col-md-4 col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-success card-header-icon">
							<div class="card-icon">
								<i class="material-icons">done</i>
							</div>

							<p class="card-category">Fillable</p>
							<h3 class="card-title">
								<span *ngIf="picking">{{ picking.fillablecount }}</span>
								<span *ngIf="!picking">
									<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>

								<span *ngIf="notready != 'notready'"></span>
								<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg></span>
							</h3>
							<small *ngIf="picking">
								<p class="card-category text-info text-justify mt-3 text-right">
									<span class=" ml-1 mr-1 pointer" (click)="toggleFlip('lateone','', true, lateone)"> ( {{ picklateone.length }} ) 3-5 Days </span>
									<span class="ml-1 mr-1 pointer" (click)="toggleFlip('latetwo','' , true, latetwo)"> ( {{ picklatetwo.length }} ) 5-7 Days </span>
									<span class="ml-1 mr-1 pointer" (click)="toggleFlip('late','' , true, late)"> ( {{ picklatethree.length }} ) > 7 Days </span>
								</p>
							</small>

							<small *ngIf="picking.partialpickedcount">
								<p class="card-category text-info pointer" (click)="toggleFlip('partialpicked','')"> ( {{ picking.partialpicked.length }} ) Partial Picks</p>
							</small>

						</div>
						<div class="card-footer">
							<div class="stats" *ngIf="picking.fillablecount">
								<a (click)="toggleFlip('fillable','')" class="pointer"><i class="material-icons">visibility</i>
									View Now</a>
							</div>
							<div class="stats" *ngIf="!picking.fillablecount">
								<br />
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-primary card-header-icon">
							<div class="card-icon">
								<i class="material-icons">hail</i>
							</div>
							<p class="card-category">Partially Fillable</p>
							<h3 class="card-title">
								<span *ngIf="picking">{{ picking.paritalcount }}</span>
								<span *ngIf="!picking">
									<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
								<span *ngIf="notready != 'notready'"></span>
								<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg></span>
							</h3>
						</div>
						<div class="card-footer">
							<div class="stats" *ngIf="picking.paritalcount">
								<a (click)="toggleFlip('parital','')" class="pointer"><i class="material-icons">visibility</i>
									View Now</a>
							</div>
							<div class="stats" *ngIf="!picking.paritalcount">
								<br />
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4  col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-danger card-header-icon">
							<div class="card-icon">
								<i class="material-icons">hourglass_disabled</i>
							</div>
							<p class="card-category">Un-fillable Orders</p>
							<h3 class="card-title">

								<span *ngIf="picking">{{ picking.notfillablecount }}</span>
								<span *ngIf="!picking">
									<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>


								<span *ngIf="notready != 'notready'"></span>
								<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg></span>
							</h3>

							<small *ngIf="picking">

								<p class="card-category text-info text-justify mt-3 text-right">
									<span class="ml-1 mr-1 pointer" (click)="toggleFlip('notfillableone','',true, notfillableone)"> ( {{ notfillableone.length }} ) 3-5 Days </span>
									<span class="ml-1 mr-1 pointer" (click)="toggleFlip('notfillabletwo','',true, notfillabletwo)"> ( {{ notfillabletwo.length }} ) 5-7 Days </span>
									<span class="ml-1 mr-1 pointer" (click)="toggleFlip('notfillablethree','',true, notfillablethree)"> ( {{ notfillablethree.length }} ) > 7 Days </span>
								</p>

							</small>

						</div>
						<div class="card-footer">
							<div class="stats" *ngIf="picking.notfillablecount">
								<a (click)="toggleFlip('notfillablecount','')" class="pointer"><i class="material-icons">visibility</i>
									View Now</a>
							</div>
							<div class="stats" *ngIf="!picking.notfillablecount">
								<br />
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4  col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-primary card-header-icon">
							<div class="card-icon">
								<i class="material-icons">taxi_alert</i>
							</div>
							<p class="card-category">Overdue Orders</p>
							<h3 class="card-title">

								<span *ngIf="overdues">{{ overdues.length }}</span>
								<span *ngIf="!overdues">
									<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>


								<span *ngIf="notready != 'notready'"></span>
								<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg></span>
							</h3>

							<small *ngIf="overdues">

								<p class="card-category text-info text-justify mt-3 text-right">
									<span class="ml-1 mr-1 pointer" (click)="toggleFlip('overdue1','',true, oveerdue1)"> ( {{ overdue1_length }} ) 1-5 Days </span>
									<span class="ml-1 mr-1 pointer" (click)="toggleFlip('overdue2','',true, oveerdue2)"> ( {{ overdue2_length }} ) 5-10 Days </span>
									<span class="ml-1 mr-1 pointer" (click)="toggleFlip('overdue3','',true, oveerdue3)"> ( {{ overdue3_length }} ) > 10 Days </span>
								</p>

							</small>

						</div>
						<div class="card-footer">
							<div class="stats" *ngIf="overdues">
								<a (click)="toggleFlip('overdues','', true)" class="pointer"><i class="material-icons">visibility</i>
									View Now</a>
							</div>
							<div class="stats" *ngIf="!overdus">
								<br />
							</div>
						</div>
					</div>
				</div>
				<!--
				<div class="col-lg-4 col-md-4 col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-danger card-header-icon">
							<div class="card-icon">
								<i class="material-icons">inventory</i>
							</div>
							<p class="card-category">Return To Bin</p>

							<h3 class="card-title">

								<span *ngIf="picking">{{ returns.length }}</span>
								<span *ngIf="!picking">
									<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
								<span *ngIf="notready != 'notready'"></span>
								<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg></span>
							</h3>
						</div>
						<div class="card-footer">
							<div class="stats" *ngIf="returns.length">
								<a (click)="toggleFlip('returns','',returns)" class="pointer"><i class="material-icons">visibility</i>
									View Now</a>
							</div>
							<div class="stats" *ngIf="!returns.length">
								<br />
							</div>

						</div>
					</div>
				</div>
				-->

				<div class="col-lg-4 col-md-4 col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-warning card-header-icon">
							<div class="card-icon">
								<i class="material-icons">inventory_2</i>
							</div>
							<p class="card-category">In Picking</p>

							<h3 class="card-title">

								<span *ngIf="picking">{{ picking.inpickcount }}</span>
								<span *ngIf="!picking">
									<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
								<span *ngIf="notready != 'notready'"></span>
								<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg></span>
							</h3>
						</div>
						<div class="card-footer">
							<div class="stats" *ngIf="picking.inpickcount">
								<a (click)="toggleFlip('inpick','')" class="pointer"><i class="material-icons">visibility</i>
									View Now</a>
							</div>
							<div class="stats" *ngIf="!picking.inpick">
								<br />
							</div>

						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-success card-header-icon">
							<div class="card-icon">
								<i class="material-icons">input</i>
							</div>
							<p class="card-category">Picked</p>

							<h3 class="card-title">

								<span *ngIf="picking">{{ picking.pickedcount }}</span>
								<span *ngIf="!picking">
									<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
								<span *ngIf="notready != 'notready'"></span>
								<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg></span>
							</h3>
						</div>
						<div class="card-footer">
							<div class="stats" *ngIf="picking.pickedcount">
								<a (click)="toggleFlip('picked','')" class="pointer"><i class="material-icons">visibility</i>
									View Now</a>
							</div>
							<div class="stats" *ngIf="!picking.pickedcount">
								<br />
							</div>

						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-info card-header-icon">
							<div class="card-icon">
								<i class="material-icons">local_shipping</i>
							</div>
							<p class="card-category">Loaded</p>
							<h3 class="card-title">
								<span *ngIf="picking">{{ picking.dispatchedcount }}</span>
								<span *ngIf="!picking">
									<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
								<span *ngIf="notready != 'notready'"></span>
								<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg></span>
							</h3>
						</div>
						<div class="card-footer">
							<div class="stats" *ngIf="picking.dispatchedcount">
								<a (click)="toggleFlip('dispatched','')" class="pointer"><i class="material-icons">visibility</i>
									View Now</a>
							</div>
							<div class="stats" *ngIf="!picking.dispatchedcount">
								<br />
							</div>
						</div>
					</div>
				</div>


				<div class="col-lg-4 col-md-4 col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-info card-header-icon">
							<div class="card-icon">
								<i class="material-icons">local_shipping</i>
							</div>
							<p class="card-category">Dispatched</p>
							<h3 class="card-title">
								<span *ngIf="picking">{{ picking.dispatchedcount }}</span>
								<span *ngIf="!picking">
									<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
								<span *ngIf="notready != 'notready'"></span>
								<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg></span>
							</h3>
						</div>
						<div class="card-footer">
							<div class="stats" *ngIf="picking.dispatchedcount">
								<a (click)="toggleFlip('dispatched','')" class="pointer"><i class="material-icons">visibility</i>
									View Now</a>
							</div>
							<div class="stats" *ngIf="!picking.dispatchedcount">
								<br />
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-info card-header-icon">
							<div class="card-icon">
								<i class="material-icons">receipt</i>
							</div>
							<p class="card-category">Need Invoiced</p>
							<h3 class="card-title">
								<span *ngIf="picking">{{ picking.billablecount }}</span>
								<span *ngIf="!picking">
									<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
								<span *ngIf="notready != 'notready'"></span>
								<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg></span>
							</h3>
						</div>
						<div class="card-footer">
							<div class="stats" *ngIf="picking.billablecount">
								<a (click)="toggleFlip('billable','')" class="pointer"><i class="material-icons">visibility</i>
									View Now</a>
							</div>
							<div class="stats" *ngIf="!picking.billablecount">
								<br />
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-danger card-header-icon">
							<div class="card-icon">
								<i class="material-icons">remove</i>
							</div>
							<p class="card-category">Refused Delivery</p>

							<h3 class="card-title" >

								<span *ngIf="refused">{{ refused.length }}</span>
								<span *ngIf="!refused">
								<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
								</span>
								<span *ngIf="notready != 'notready'"></span>
								<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg></span>
							</h3>
						</div>
						<div class="card-footer">
							<div class="stats" *ngIf="refused.length">
								<a (click)="toggleFlip('refused','')" class="pointer"><i class="material-icons">visibility</i>
								 View</a>
							</div>
							<div class="stats" *ngIf="!refused.length">
								<br />
							</div>
						</div>
					</div>
				</div>

				<div class="col-lg-4 col-md-4 col-sm-6" *ngIf="config.features.adrs">
					<div class="card card-stats">
						<div class="card-header card-header-info card-header-icon">
							<div class="card-icon">
								<i class="material-icons">toys</i>
							</div>
							<p class="card-category">ADRS Orders</p>

							<h3 class="card-title" >

								<span *ngIf="adrs">{{ adrs.adrscount }}</span>
								<span *ngIf="!adrs">
								<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
								</span>
								<span *ngIf="notready != 'notready'"></span>
								<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg></span>
							</h3>
						</div>
						<div class="card-footer">
							<div class="stats" *ngIf="adrs.adrscount">
								<a (click)="toggleFlip('adrs','')" class="pointer"><i class="material-icons">visibility</i>
							 	View</a>
							</div>
							<div class="stats" *ngIf="!adrs.adrscount">
								<br />
							</div>
						</div>
					</div>
				</div>


				<!--
					<div class="col-12">
					<div class="row">
						<div class="col-md-6">
							<app-order-search></app-order-search>
						</div>
						<div class="col-md-6">
							<app-item-search></app-item-search>
						</div>
					</div>
					</div>
				-->
			</div>

			<div class="row tp-box__side tp-box__front text-center" *ngIf="!picking">
				<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
					<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
				</svg>
			</div>
		</div>

	</div>
</div>
