import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { InventoryService } from '../../services/inventory.service';
import { CustomerService } from '../../services/customer.service';
import { Location } from '@angular/common'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
	selector: 'app-inventory-adjustment',
	templateUrl: './inventory-adjustment.component.html',
	styleUrls: ['./inventory-adjustment.component.scss']
})
export class InventoryAdjustmentComponent implements OnInit {
	@Input() stockid;
	@Input() cost;
	@Output() closeadj: any = new EventEmitter < boolean > ();
	@Output() newitemdata: any = new EventEmitter < boolean > ();

	itembins: any = [];
	adjustment: any = [];
	selectedbin: any = '';
	movementqty = 0;
	movementreason: any = '';
	movements: any = [];
	adjustmentForm: UntypedFormGroup;
	user: any = [];
	locations: any = [];
	bins: any = [];
	total_qoh: any = false;
	config: any = false;
	sending: boolean = false;

	constructor(private route: ActivatedRoute, public inventoryService: InventoryService, private globalsService: GlobalsService, private fb: UntypedFormBuilder,
		public globalSearchService: GlobalSearchService) {
		this.globalSearchService.user.subscribe((user: any) => {
			this.user = user.user
		});
	}

	ngOnInit(): void {
		this.globalSearchService.locations.subscribe((locs: any) => {
			this.locations = locs;

			const payload = {
				stockid: this.stockid,
				loccode: this.user.defaultlocation.loccode
			}

			this.inventoryService.getItemBins(payload).subscribe((results: any) => {
				this.itembins = results;
				this.setForm();
			});
		});

		this.inventoryService.getBinSearch('').subscribe((bins: any) => {
			this.bins = bins;

		});

		this.globalSearchService.configsubscription.subscribe(c => {
			this.config = c;
		});
	}

	totalQoh(): number {
		const movements = this.movements;
		let addon = 0;
		let totalqoh = 0;
		if (movements.length) {
			movements.forEach(m => {
				addon += parseFloat(m.qty)
			});
		};

		if (this.itembins) {
			totalqoh = this.itembins.reduce(function(accumulator: number, items: any) {
				return accumulator + parseFloat(items.quantity);
			}, 0);
		}

		return totalqoh + addon;
	}

	selBin(bin) {
		this.adjustmentForm.get('bin').setValue(bin);
		$('#qty').focus();
	}

	setForm() {

		let binvalue = '';
		if (this.itembins.length == 1) {
			binvalue = this.itembins[0].bin;
		}
		let location_selected = (this.adjustmentForm && this.adjustmentForm.get('loccode').value) ? this.adjustmentForm.get('loccode').value : this.user.defaultlocation.loccode;

		if (this.config.quickadjustment) {
			this.adjustmentForm = this.fb.group({
				stockid: [this.stockid, Validators.required],
				loccode: [location_selected, Validators.required],
				bin: [binvalue],
				qty: [0, Validators.required],
				reason: ['', Validators.required],
				cost: [this.cost]
			});

		} else {
			this.adjustmentForm = this.fb.group({
				stockid: [this.stockid, Validators.required],
				loccode: [location_selected, Validators.required],
				bin: [binvalue, Validators.required],
				qty: [0, Validators.required],
				reason: ['', Validators.required],
				cost: [this.cost]
			});
		}

		if (this.config && this.config.env.package == 'beauty') {
			this.adjustmentForm.get('bin').setValidators([])
			this.adjustmentForm.get('reason').setValidators([])
			this.adjustmentForm.updateValueAndValidity();

		}


		this.adjustmentForm.markAllAsTouched();

		this.adjustmentForm.get('loccode').valueChanges.subscribe(newValue => {
			this.inventoryService.getItemBins({ stockid: this.stockid, loccode: newValue }).subscribe((results: any) => {
				this.itembins = results;
				this.setForm();
			});
		});

		this.adjustmentForm.get('bin').valueChanges.subscribe(newValue => {
			if (newValue != '' && newValue != ' ') {
				newValue = newValue.toUpperCase()
				const search = {
					loccode: this.adjustmentForm.get('loccode').value,
					bin: newValue,
				}
				this.inventoryService.getBinSearch(search).subscribe((bins: any) => {
					this.bins = bins;
				});
			}
		});


		this.showAddBinInput = false;
		this.newBinValue = '';
		this.editingBin = '';
		this.editingBinValue = '';

		if (this.config.quickadjustment) {
			// setTimeout(() => {
			// 	$('#qty').focus();
			// }, 500);
		}
	}

	addMovement() {

		if (this.adjustmentForm.valid && this.adjustmentForm.get('qty').value != 0) {
			const move = this.adjustmentForm.value;
			this.movements.push(move);
			this.setForm();
			if (this.config.quickadjustment) {
				this.makeAdjustment();
			}
		} else {
			this.globalSearchService.showNotification('Invalid Adjustment', 'danger', 'bottom', 'right');
		}
	}

	binExists(): boolean {
		let exits = false;
		const binfilter = this.bins.filter((b: any) => {
			return b.bin == this.adjustmentForm.get('bin').value;
		})[0];

		if (binfilter) {
			exits = true;
		}

		return exits;
	}

	removeMovement(move) {
		const index = this.movements.indexOf(move);
		this.movements.splice(index, 1);
	}

	makeAdjustment() {
		if (this.movements) {
			this.sending = true;
			this.inventoryService.adjustInventory(this.movements).subscribe((results: any) => {
				this.sending = false;
				this.inventoryService.loadItem(this.stockid).subscribe((results: any) => {
					this.newitemdata.emit(results)
					this.closeadj.emit(false);
				});

			});
		}
	}

	showAddBinInput: boolean = false;
	newBinValue: string = '';
	editingBin: string = '';
	editingBinValue: string = '';
	originalBinValue: string = '';

	// Method to initiate editing
	editBin(bin: any) {
		this.editingBin = bin.bin;
		this.editingBinValue = bin.bin;
		this.originalBinValue = bin.bin; // Store the original bin value
	}

	removeBin(bin:any) {

		if(bin.quantity === 0 || bin.quantity === '0') {
			const originalBin = { ...bin, bin: this.originalBinValue, stockid: this.stockid }; // Preserve the original bin

			// Create the updated bin object with the new value
			const updatedBin = { ...bin, bin: this.editingBinValue, stockid: this.stockid };

			this.editingBin = '';
			this.originalBinValue = '';
			const payload = {
				originalBin,
				updatedBin
			};

			this.inventoryService.removeBin(payload).subscribe((results: any) => {
				const color = (results.success) ? 'success' : 'danger';
				this.globalSearchService.showNotification(results.message, color, 'bottom', 'right');

				this.inventoryService.getItemBins({ stockid: this.stockid, loccode: this.user.defaultlocation.loccode }).subscribe((results: any) => {
					this.itembins = results;
					this.setForm();
				});
			});
		} else {
			this.globalSearchService.showNotification('Bin Is Not Empty', 'danger', 'bottom','right');
		}
	}

	saveBin(bin: any) {
		const originalBin = { ...bin, bin: this.originalBinValue, stockid: this.stockid }; // Preserve the original bin

		// Create the updated bin object with the new value
		const updatedBin = { ...bin, bin: this.editingBinValue, stockid: this.stockid };

		this.editingBin = '';
		this.originalBinValue = '';
		const payload = {
			originalBin,
			updatedBin
		};

		this.inventoryService.changeBinCode(payload).subscribe((results: any) => {
			const color = (results.success) ? 'success' : 'danger';
			this.globalSearchService.showNotification(results.message, color, 'bottom', 'right');

			this.inventoryService.getItemBins({ stockid: this.stockid, loccode: this.user.defaultlocation.loccode }).subscribe((results: any) => {
				this.itembins = results;
				this.setForm();
			});
		});
	}

	cancelEdit() {
		this.editingBin = '';
		this.originalBinValue = '';
	}


	createBin() {
		if (this.newBinValue) {
			const newBin = {
				loccode: this.user.defaultlocation.loccode,
				bin: this.newBinValue,
				stockid: this.stockid,
				quantity: 0,
				reorderlevel: 0,
				managed: 0,
				canupd: 1,
				quantityreq: 0
			};

			this.inventoryService.addBinSimple(newBin).subscribe((result: any) => {
				if (result.success) {
					this.itembins.push(newBin);
					this.showAddBinInput = false;
					this.newBinValue = '';
					this.globalSearchService.showNotification(result.message, 'success', 'bottom', 'right');
				} else {
					this.globalSearchService.showNotification(result.message, 'danger', 'bottom', 'right');
				}
			});
		}
	}

	selectText(inputElement: HTMLInputElement) {
		inputElement.select();
	}
}