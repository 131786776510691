import { Injectable } from '@angular/core';
import { ActivatedRoute,ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';
import { PermissionsService } from '../services/permissions.service';
import { GlobalSearchService } from '../services/globalsearchservice.service';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { RouteInfo, ROUTES , VERSION } from '../classes/routes';


@Injectable()
export class AuthGuardService implements CanActivate {
	menuItems: any = [];;
	pagesubscription: any;
	user: any = [];
	version: any = false;
	timestamp = new Date().getTime(); // Get current timestamp
	url = '/assets/version.json';
	constructor(
			private http: HttpClient,
			protected auth: AuthService,
			public router: Router ,
			public currentroute: ActivatedRoute ,
			protected permissionsService: PermissionsService,
			protected globalSearchService: GlobalSearchService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
	//canActivate(route: ActivatedRouteSnapshot, router: Router): boolean {
		const logged = this.auth.isAuthenticated;
		if (!this.auth.isAuthenticated()) {
			this.router.navigate(['auth/login']);
			return false;
		}
		//state.url
		if(state.url && state.url != 'auth/permission') {
			this.globalSearchService.user.subscribe( user => {
				this.user = user;
				this.pagesubscription = user.user.access_permissions.includes(state.url);

				//need to rethink this forces every page vs every parent.
				if(!this.pagesubscription) {
					//check children
					//this.router.navigate(['auth/permission']);
				}
			});
		}
		/*
		this.globalSearchService.user.subscribe( user => {
			return this.permissionsService.checkPermission(user, this.router.url, []);
		})
		*/
		let version = JSON.parse(localStorage.getItem('v8version'));
		this.http.get(this.url+'?t='+this.timestamp).subscribe( (res:any) => {

			if(res.version) {
				if(version === null ) {
					localStorage.setItem('v8version', JSON.stringify(res))
					window.location.reload();
				} else {
					version = JSON.parse(localStorage.getItem('v8version'));
					if(version.version != res.version) {
						localStorage.setItem('v8version', JSON.stringify(res))
						window.location.reload();
					}
				}
			}
		});

		return true;
	}


}
