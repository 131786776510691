<div class="main-content">
	<div class="container-fluid">
		<div class="card">
			<div class="card-header card-header-{{color}}-1">
				<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Order Confirmation</h4>
				<p class="card-category">Order Confirmation</p>
			</div>
			<div class="card-body" >
				<div class="input-group no-border  w-100">
					<input type="text" (input)="onInput($event)" class="form-control text-right"  placeholder="Search..." />
					<button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
					<i class="material-icons">search</i>
					<div class="ripple-container"></div>
					</button>
				</div>

				<div class="table table-responsive text-center" *ngIf="!openorders">
					<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
						</circle>
					</svg>
				</div>

				<button mat-raised-button type="submit" color="accent" (click)="exportExcel()" *ngIf="openorders">
					<i class="material-icons">text_snippet</i> XLS

				</button>

				<table mat-table [dataSource]="openorders" matSort (matSortChange)="announceSortChange($event)">
					<ng-container matColumnDef="actions">
						<th mat-header-cell *matHeaderCellDef mat-sort-header></th>
						<td mat-cell *matCellDef="let tran">
							<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
							  <mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #menu="matMenu">
								<button mat-menu-item>
									<a
									(click)="loadOrder(tran.orderno)">
									<mat-icon>edit</mat-icon>
									<span>Edit</span>
								</a>
								</button>
								<a href="{{config.apiServer.baseUrl}}{{baselink}}{{tran.orderno}}" mat-menu-item >
									<mat-icon>print</mat-icon>
									<span>PDF</span>
								</a>
								<button mat-menu-item>
									<a class="text-dark" [routerLink]="'/orders/invoice/'+tran.orderno+'/dispatch'">
									<mat-icon>description</mat-icon>
									<span> Invoice </span>
									</a>
								</button>
							</mat-menu>


						</td>
					</ng-container>
					<ng-container matColumnDef="status">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Status.</th>
						<td mat-cell *matCellDef="let tran" >{{ tran.status }}</td>
					</ng-container>

					<ng-container matColumnDef="orderno">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Order#.</th>
						<td mat-cell *matCellDef="let tran" >{{ tran.orderno }}</td>
					</ng-container>

					<ng-container matColumnDef="transno">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Invoice#.</th>
						<td mat-cell *matCellDef="let tran" >{{ tran.transno }}</td>
					</ng-container>

					<ng-container matColumnDef="ponumber">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> PO number#.</th>
						<td mat-cell *matCellDef="let tran" >{{ tran.ponumber }}</td>
					</ng-container>

					<ng-container matColumnDef="debtorno">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Customer#.</th>
						<td mat-cell *matCellDef="let tran" >{{ tran.debtorno }}</td>
					</ng-container>

					<ng-container matColumnDef="deliverto">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Name<th>
						<td mat-cell *matCellDef="let tran" >{{ tran.deliverto }}</td>
					</ng-container>

					<ng-container matColumnDef="deliverydate">
						<th mat-header-cell *matHeaderCellDef mat-sort-header> Date Watned<th>
						<td mat-cell *matCellDef="let tran" >{{ tran.deliverydate }}</td>
					</ng-container>
					  <tr mat-header-row *matHeaderRowDef="headercolumns"></tr>
					  <tr mat-row *matRowDef="let row; columns: headercolumns"></tr>
				</table>
				<mat-paginator [pageSizeOptions]="[25, 50, 100]"
								 showFirstLastButtons
								 aria-label="select page">
				</mat-paginator>
			</div>
		</div>
	</div>
</div>
