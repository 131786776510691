import { Directive, EventEmitter, HostListener, Output,ElementRef } from '@angular/core';
import { debounce } from 'lodash';

@Directive({
	selector: '[appWaitType]'
})
export class DebounceBlurDirective {
	@Output() keyUpCheck = new EventEmitter();
	timeout: any = null;
	@HostListener('keyup', ['$event'])
		keyUped = function(event: any) {
			clearTimeout(this.timeout);
			const $this = this;
			this.timeout = setTimeout(function() {
				if (event.keyCode != 13) {
					$this.execute(event);
				}
			}, 1000);
		}

	constructor(
		private elementRef: ElementRef
	){}

	private execute(event: any) {
		this.keyUpCheck.emit(event);
		this.elementRef.nativeElement.focus();
	}
}
