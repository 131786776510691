import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, Directive, HostListener, ViewEncapsulation } from '@angular/core';
import { Location, DatePipe } from '@angular/common'
import { ActivatedRoute,Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, ValidatorFn, AbstractControl, UntypedFormControl } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../../services/globalsearchservice.service'
import { PrintService } from '../../../services/print.service'
import { TaxesService } from '../../../services/taxes.service'
import { GeneralLedgerService } from '../../../services/general-ledger.service'
import { InventoryService } from '../../../services/inventory.service'
import { DeelsheetsService } from '../../../services/deelsheets.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ThisReceiver } from '@angular/compiler';
import { Console } from 'console';
import { SlugifyPipe } from '../../../pipes/slugify.pipe'
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-qr-codes',
  templateUrl: './qr-codes.component.html',
  styleUrls: ['./qr-codes.component.scss']
})
export class QrCodesComponent {
	@ViewChild("pdfdisplay") pdfdisplayRef: ElementRef;
	title = 'dealsheets';
	dealsheets: any = false;
	alldealsheets: any = false;
	dealForm: any = false;
	sending: boolean = false;
	files: File[] = [];
	qrCodeDownloadLink: SafeUrl = "";
	baseurl: any = false;
	append: string = '';
	content_url: string = '';
	slug: any = [];
	pdfcontent: any = false;
	search = new UntypedFormControl('');
	fetching: boolean = false;
	durationInSeconds = 5000;
	tabIndex = 0;
	urlPattern = "(^(http|https):\/\/)[a-zA-Z0-9.;,\/?:@&=+$-_.!~*\'()#]*";


	color = 'blue';
	categories: any = [];
	productlines: any = [];
	filteredproductlines: any = [];
	rebates: any = [];
	hazards: any = [];
	glaccounts: any = [];
	manufacturers: any = [];
	taxcats: any = [];
	cattypes: any = [];
	selected_linefield: any = '';
	productLineForm: UntypedFormGroup;
	plinesearch = new FormControl('');
	categoryForm: UntypedFormGroup;
	manufacturerForm: UntypedFormGroup;
	productLineMergeForm: UntypedFormGroup;
	vieingpline: any = false;
	salestypes: any = false;
	merging: any = false;
	searchingproductlines: any = false;
	selectedto: any = '';
	searchsub: any = false;
	searchResults: any;
	to = new FormControl('', Validators.required);
	from = new FormControl('', Validators.required);
	partialmerge = new FormControl(false);
	partialMergeItemsControls: FormControl < any > [] = [];
	// partialMergeItemsControls: UntypedFormGroup;
	searchbar = new FormControl('');
	viewSearch = false;
	validateSub: any;
	stockidChanged: any = { status: false, data: {} };
	toproductline: any = false;
	allowmmerge: any = [];
	controlsReady = false;
	sanitized_ID: any = false;
	batchType: string;
	batchComplete: boolean;
	config: any;

	dataSource: MatTableDataSource < any > ;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataObs: Observable < any > ;
	items_per_page = [25, 50, 100, 500];
	pagesizedefault = 25;

	slugifyPipe = new SlugifyPipe();

	constructor(private route: ActivatedRoute, public router: Router,public deelsheetsService: DeelsheetsService,private printService: PrintService, private _changeDetectorRef: ChangeDetectorRef, private fb: UntypedFormBuilder, private taxesService: TaxesService, private inventoryService: InventoryService, private generalLedgerService: GeneralLedgerService, private globalSearchService: GlobalSearchService, private location: Location, public cdr: ChangeDetectorRef, private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.salestypes.subscribe(v => { this.salestypes = v; });
		this.globalSearchService.configsubscription.subscribe((r) => {
			this.config = r
			this.baseurl = r.apiServer.qrwebsite
		});
	}

	ngOnInit(): void {
		this.dealForm = this.fb.group({
			seo_url: ['', [Validators.required]],
			input_url: ['']
		});

		this.deelsheetsService.getSheets().subscribe((deels: any) => {
			this.dealsheets = deels
			this.alldealsheets= deels
		})

		this.route.params.subscribe( (params: any) => {
			const deelsheet = params['id'];
		});

	}


	back(): void {
		this.location.back()
	}

	openSnackBar(message: string, action: string) {
		// this._snackBar.open(message, action, { duration: 1000 });
	}

	searchDeals() {

		if(!this.alldealsheets) {
			this.alldealsheets = this.dealsheets;
		}
		if(this.search.value != '') {
			this.dealsheets = this.filterItem(this.alldealsheets,this.search.value,'seo_url,pdf');
		} else {
			this.dealsheets = this.alldealsheets;
		}

	}

	updateAppend() {
		this.append = this.slugifyPipe.transform(this.dealForm.get('seo_url').value)
	}

	updateUrl() {
		this.content_url = this.dealForm.get('input_url').value;
	}

	setSlug(d: any) {

		if(this.slug == d) {
			this.slug = false;
			this.setFormDefaults();
		} else {

			this.slug = d;
			this.append = d.seo_url;
			this.content_url = d.alturl;
			this.fetching = true;
			this.pdfcontent = false;


			//select the correct mat-tab by regex output of the content_url (empty and false returns set the tab to 'file' )
			if(this.content_url == ""){
				this.tabIndex = 0;
			} if(!new RegExp(this.urlPattern).test(this.content_url)) {
				this.tabIndex = 0;
			} else {
				this.tabIndex = 1;
			}

			this.deelsheetsService.getPdfContent(d.seo_url).subscribe(async (result:any) => {
				this.fetching = false;
				this.pdfcontent = result.content;
			});

			this.dealForm = this.fb.group({
				seo_url: [d.seo_url.toLowerCase(), [Validators.required]],
				input_url:[this.content_url]
			});
		}
	}

	onChangeURL(url: SafeUrl) {
		this.qrCodeDownloadLink = url;
	}

	onRemove(event: any) {
		this.files.splice(this.files.indexOf(event), 1);
	}

	removeSelectedSlug(slug:any) {
		let upload = {
			formdata: slug,
		}

		this.deelsheetsService.removeFile(upload).subscribe((results: any) => {
			this.files = [];
			this.dealsheets = results;
			this.alldealsheets= results;
			this.sending = false;
			this.setFormDefaults();
		});
	}

	removeSlug() {

		if(this.dealForm.valid) {
			let upload = {
				formdata: this.dealForm.value,
			}

			this.deelsheetsService.removeFile(upload).subscribe((results: any) => {
				this.files = [];
				this.dealsheets = results;
				this.alldealsheets= results;
				this.sending = false;
				this.setFormDefaults();
			});
		}
	}
	saveWithoutFile() {
		if(this.dealForm.valid) {
			this.dealForm.get('seo_url').setValue(this.slugifyPipe.transform(this.dealForm.get('seo_url').value))

			let upload = {
				formdata: this.dealForm.value,
			}

			this.deelsheetsService.uploadFile(upload).subscribe((results: any) => {
				//this.openSnackBar('Saved', '');
				this.files = [];
				this.dealsheets = results;
				this.sending = false;
				//this.dealForm = this.fb.group({
				//	seo_url: ['', [Validators.required]],
				//});
			});
		}
	}

	saveUrl() {
		if(this.dealForm.valid) {

			this.dealForm.get('seo_url').setValue(this.slugifyPipe.transform(this.dealForm.get('seo_url').value))

			let upload = {
				formdata: this.dealForm.value,
			}

			this.deelsheetsService.uploadUrl(upload).subscribe((results: any) => {
				//this.openSnackBar('Saved', '');
				this.files = [];
				this.dealsheets = results;
				this.sending = false;
				//this.dealForm = this.fb.group({
				//	seo_url: ['', [Validators.required]],
				//});
			});
		}
	}

	viewPdf(d: any) {
		this.modalService.open(this.pdfdisplayRef, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {
		});
	}
	onSelect(event: any) {

		if(this.dealForm.valid) {

			this.sending = true;
			this.files.push(...event.addedFiles);

			for (var i = 0; i < this.files.length; i++) {

				this.readFile(this.files[i]).then(fileContents => {
					//stuff
					let upload = {
						content: fileContents,
						name: event.addedFiles[0].name,
						mime: event.addedFiles[0].type,
						formdata: this.dealForm.value,
					}

					this.deelsheetsService.uploadFile(upload).subscribe((results: any) => {
						this.files = [];
						this.dealsheets = results;
						this.alldealsheets= results;
						this.sending = false;
						//this.dealForm = this.fb.group({
						//	seo_url: ['', [Validators.required]],
						//});

						//this.openSnackBar('Saved', '');
					});
				});
			}
		}
	}

	private async readFile(file: File): Promise < string | ArrayBuffer > {
		return new Promise < any > ((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = (e:any) => {
				return resolve((e.target as FileReader).result);
			};

			reader.onerror = e => {
				console.error(`FileReader failed on file ${file.name}.`);
				return reject(null);
			};

			if (!file) {
				return reject(null);
			}
			reader.readAsDataURL(file);
		});

		this.files = [];
	}

	onUploadError(event: any) {

	}

	onUploadSuccess(event: any) {

	}

	public filterItem(input: any, term: any, keys: any) {
		var result = Object.assign([], input).filter((item:any) => keys.split(',').some((key:any) => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));
		return result
	}

	setFormDefaults(){
		this.dealForm = this.fb.group({
			seo_url: ['', [Validators.required]],
			input_url: ['']
		});
	}
}
