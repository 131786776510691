import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { interval, Subscription, Observable } from 'rxjs';
import { debounceTime, startWith, map, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';

import { GlobalsService } from '../../../services/globals.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { InventoryService } from '../../../services/inventory.service';

@Component({
	selector: 'app-inventory-edit-setup',
	templateUrl: './inventory-edit-setup.component.html',
	styleUrls: ['./inventory-edit-setup.component.scss'],
	animations: [
		trigger('grow', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: '0', width: '0', overflow: 'hidden' }),
				animate(200, style({ height: '*', width: '*' })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate(300, style({ height: 0, width: 0, overflow: 'hidden' })),
				animate('300ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class InventoryEditSetupComponent implements OnInit {
	@Input() item;
	@Output() editing = new EventEmitter < boolean > ();
	@Output() newitemdata = new EventEmitter < any > ();
	itemForm: any = false;
	properties: any = [];
	formEditAllowed = [
		'stockid',
		'barcode',
		'categoryid',
		'taxcatid',
		'lineid',
		'description',
		'longdescription',
		'controlled',
		'decimalplaces',
		'discontinued',
		'mbflag',
		'glclass',
		'units',
		'dwo',
		'enabled',
		'manufacturer_id',
		'winter',
		'designation'
	]
	config: any = false;
	availableCategories: any = [];
	categoryCtrl = new UntypedFormControl();
	assignedCategories: any = [];
	filteredCategories: Observable < string[] > ;

	constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder, private inventoryService: InventoryService, public router: Router, private location: Location, private globalsService: GlobalsService, private globalSearchService: GlobalSearchService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});
	}

	onSubmit() {
		if (this.itemForm.valid) {
			const itemData = this.itemForm.value;
			const category_paylaod = {
				stockid: this.item.stockid,
				categories: this.assignedCategories
			}
			this.inventoryService.updateItem(itemData).pipe(
				switchMap((result: any) =>

					this.inventoryService.assignCategories(category_paylaod).pipe(
						map(() => result)
					)
				)
			).subscribe({
				next: (result) => this.newitemdata.emit(result),
				error: (err) => console.error('Error updating item or assigning categories', err)
			});
		}
	}


	deleteItem() {
		this.inventoryService.deleteItem(this.itemForm.value).subscribe((result: any) => {
			if (result.success) {
				this.newitemdata.emit(result);
				this.router.navigate(['inventory/view/' + result.nextandprev.next]);
			} else {
				this.globalSearchService.showNotification(
					result.message, 'danger', 'bottom', 'right'
				);
			}

		})
	}

	private _filter(value: any): any[] {

		if (typeof value === 'string' && value.trim() !== '') {
			const filterValue = value.toLowerCase();
			return this.availableCategories.filter(category => category.label.toLowerCase().includes(filterValue));
		}

		return this.availableCategories;
	}


	addCategory(category: any) {
		if (!this.assignedCategories.find(c => c.category_id === category.value)) {

			this.assignedCategories.push({
				stockid: this.item.stockid,
				category_id: category.value,
				name: category.name,
				label: category.label
			});
		}
		this.categoryCtrl.setValue(''); // Clear the input
	}

	removeCategory(category_id: string) {
		this.assignedCategories = this.assignedCategories.filter(c => c.category_id !== category_id);
	}

	ngOnInit(): void {

		this.globalsService.getItemSetup().subscribe({
			next: (results: any) => {
				this.properties = results;
				this.availableCategories = results.webcategories;
				const formGroup: UntypedFormGroup = Object.keys(this.item).reduce((group, key) => {
					if (this.formEditAllowed.includes(key)) {
						const value = this.item[key] || '';
						group.addControl(key, new UntypedFormControl(value, []));
					}
					return group;
				}, new UntypedFormGroup({}));

				this.itemForm = formGroup;
				this.assignedCategories = this.item.webcategories;
				this.filteredCategories = this.categoryCtrl.valueChanges.pipe(
					startWith(''),
					map(value => this._filter(value || ''))
				);

			},
			error: (err) => {
				this.globalSearchService.showNotification('Failed to fetch item setup', 'danger', 'bottom', 'right');
			}
		});
	}
}