import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup,ReactiveFormsModule } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { TaxesService } from '../../../services/taxes.service';


@Component({
	selector: 'app-tax-categories',
	templateUrl: './tax-categories.component.html',
	styleUrls: ['./tax-categories.component.scss']
})
export class TaxCategoriesComponent implements OnInit {
	data: any = []
	constructor(private taxesService: TaxesService) {}

	ngOnInit(): void {
		this.taxesService.getTaxCategories().subscribe((results: any) => {
			this.data = results;
		})
	}

}
