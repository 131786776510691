<div class="main-content" id="printcontent" #printcontent>
	<div class="container-fluid"  >
		<div class="row">
			<!-- <app-day-sales></app-day-sales> -->
			<div class="col-md-12" >
				<div class="card" >
					<div class="card-header card-header-{{color}}-3">
						<h4 class="card-title "> Day End Checkout</h4>
						<button mat-raised-button color="accent" (click)="postData()" *ngIf="!posted">Post Day End</button>
						<span *ngIf="posted">
							&nbsp;&nbsp;&nbsp;&nbsp;Posted&nbsp;&nbsp;&nbsp;&nbsp; {{ results.userid }}
						</span>
						&nbsp;&nbsp;
						<!-- <a mat-raised-button (click)="getExcel();">Excel Export</a>
						&nbsp;&nbsp; -->
						<button mat-raised-button (click)="$event.preventDefault();exportPdf()">
							<i class="fa fa-file-pdf-o"></i> PDF
						</button>
						<p class="card-category"></p>
					</div>
					<div class="card-body" >
						<mat-form-field appearance="outline" class="col-2">
							<mat-label>Date</mat-label>
							<input matInput [formControl]="datefrom" [matDatepicker]="payfrom">
							<mat-datepicker-toggle matSuffix [for]="payfrom"></mat-datepicker-toggle>
							<mat-datepicker #payfrom></mat-datepicker>
							<span matSuffix><button mat-icon-button (click)="loadData()" color="accent"><mat-icon>refresh</mat-icon></button></span>
						</mat-form-field>


						<div class="table" #print_table >
							<h5 class="ml-2">Date: {{ datefrom.value | date }} &nbsp;Units Sold: {{results.salesqty}}</h5>
							<h5 class="ml-2">Total Received: {{totals.totalrec | currency}}</h5>
							<h5 class="ml-2">Total Sold/Collected: {{totals.totalcollect | currency}}</h5>
							<h5 class="ml-2">Total Over/Short: {{ totals.overshort | currency}} </h5>

							<ng-container *ngIf="!config.features.editdayend">
								<table class="table mt-4 table-sm">

									<tr>
										<td  class="w-50">
											<ul class="list-group list-group-flush">
												<li class="list-group-item list-group-item-action flex-column align-items-start active">CREDIT CARDS</li>
												<li *ngFor="let sale of results.creditcards" class="list-group-item">
													{{ sale.label }}: {{ sale.value | currency }}
												</li>
												<li class="list-group-item list-group-item-action flex-column align-items-start ">
													<b>TTL: CREDIT CARDS {{totals.creditcards | currency}}</b>
												</li>
											</ul>
											<!-- <ul class="list-group list-group-flush">
												<li class="list-group-item list-group-item-action flex-column align-items-start active">PAID OUTS</li>
												<li *ngFor="let sale of results.paidouts" class="list-group-item">
													{{ sale.label }}: {{ sale.value | currency }}
												</li>
												<li class="list-group-item list-group-item-action flex-column align-items-start">
													<b>TTL: PAID OUTS (paid out (+) / paid in (-) {{totals.paidouts | currency}}</b>
												</li>
											</ul> -->
											<ul class="list-group list-group-flush">
												<li class="list-group-item list-group-item-action flex-column align-items-start active"> HOUSE A/R CHARGES</li>
												<li *ngFor="let sale of results.housear" class="list-group-item">
													{{ sale.label }}: {{ sale.value | currency }}
												</li>
												<li class="list-group-item list-group-item-action flex-column align-items-start ">
													<b>TTL: HOUSE A/R CHARGES {{totals.housear | currency}}</b>
												</li>
											</ul>
											<ul class="list-group list-group-flush">
												<li class="list-group-item list-group-item-action flex-column align-items-start active">BANK DEPOSITS</li>
												<li *ngFor="let sale of results.bankdep" class="list-group-item">
													{{ sale.label }}: {{ sale.value | currency }}
												</li>
												<li class="list-group-item list-group-item-action flex-column align-items-start ">
													<b>TTL: BANK DEPOSITS: {{totals.bankdeposit | currency}}</b>
												</li>
											</ul>
											<ul class="list-group list-group-flush">
												<li class="list-group-item list-group-item-action flex-column align-items-start active">OTHER</li>

												<li *ngFor="let sale of results.otherar" class="list-group-item">
													{{ sale.label }}: {{ sale.value | currency }}
												</li>
												<li class="list-group-item list-group-item-action flex-column align-items-start ">
													<b>TTL: OTHER: {{totals.otherar | currency}}</b>
												</li>
											</ul>
										</td>
										<td  class="w-50">
												<ul class="list-group list-group-flush">
													<li class="list-group-item list-group-item-action flex-column align-items-start active">SALES</li>
													<li *ngFor="let sale of results.salesdata" class="list-group-item">
														{{ sale.label }}: {{ sale.value | currency }}
													</li>
													<li class="list-group-item list-group-item-action flex-column align-items-start ">
														<b>TTL: SALES {{totals.sales | currency}}</b>
													</li>
												</ul>
												<ul class="list-group list-group-flush">
													<li class="list-group-item list-group-item-action flex-column align-items-start active">TAXES & FEES</li>
													<li *ngFor="let sale of results.taxfees" class="list-group-item">
														{{ sale.label }}: {{ sale.value | currency }}
													</li>
													<li class="list-group-item list-group-item-action flex-column align-items-start ">
														<b>TTL: TAXES & FEES: {{totals.taxfees | currency}}</b>
													</li>
												</ul>
												<ul class="list-group list-group-flush">
													<li class="list-group-item list-group-item-action flex-column align-items-start active">HOUSE A/R COLLECTIONS</li>
													<li *ngFor="let sale of results.housearcollect" class="list-group-item">
														{{ sale.label }}: {{ sale.value | currency }}
													</li>
													<li class="list-group-item list-group-item-action flex-column align-items-start ">
														<b>TTL: HOUSE A/R COLLECTIONS: {{totals.housearcollect | currency}}</b>
													</li>
												</ul>
												<ul class="list-group list-group-flush">
													<li class="list-group-item list-group-item-action flex-column align-items-start active">OTHER</li>

													<li *ngFor="let sale of results.othersold" class="list-group-item">
														{{ sale.label }}: {{ sale.value | currency }}
													</li>
													<li class="list-group-item list-group-item-action flex-column align-items-start ">
														<b>TTL: OTHER: {{totals.otherrec | currency}}</b>
													</li>
												</ul>

										</td>
									</tr>
								</table>
								<table class="table table-sm">
									<tr>
										<td>									<li class="list-group-item list-group-item-action flex-column align-items-start ">
											<b>TTL:RECEIVED {{totals.totalrec | currency}}</b>
											</li></td>
										<td><li class="list-group-item list-group-item-action flex-column align-items-start ">
											<b>TTL: SOLD & COLLECTED {{totals.totalcollect | currency}}</b>
											</li></td>
										<td><li class="list-group-item list-group-item-action flex-column align-items-start ">
											<b>OVER/SHORT {{totals.overshort | currency}}</b>
											</li></td>
									</tr>
								</table>
							</ng-container>
							<ng-container *ngIf="config.features.editdayend">
								<table class="table">
									<tr>
										<th colspan="3">Dollars Received</th>
										<th colspan="3">Dollars Sold / Collected</th>
									</tr>
									<tr>
										<th colspan="3" width="50%">
											<table>
												<tr *ngFor="let sale of results.paidouts">
													<td width="33%"><input [disabled]="sale.removable == 0" mat-input [(ngModel)]="sale.label"></td>
													<td width="33%"><input mat-input [(ngModel)]="sale.value" (change)="recalcTotals()"></td>
													<td width="33%"><button *ngIf="sale.removable" class="noprint" mat-icon-button (click)="removeEntry(sale,results.paidouts)">
															<mat-icon>remove</mat-icon>
														</button></td>
												</tr>
											</table>
										</th>
										<th colspan="3" width="50%">
											<table>
												<tr *ngFor="let sale of results.salesdata">
													<td width="33%"><input [disabled]="sale.removable == 0" mat-input [(ngModel)]="sale.label"></td>
													<td width="33%"><input mat-input [(ngModel)]="sale.value" (change)="recalcTotals()"></td>
													<td width="33%"><button *ngIf="sale.removable" class="noprint" mat-icon-button (click)="removeEntry(sale,results.salesdata)">
															<mat-icon>remove</mat-icon>
														</button></td>
												</tr>
											</table>
										</th>
									</tr>
									<!-- <tr>
										<th colspan="2">
											<button mat-icon-button (click)="addEntry(results.paidouts)">
												<mat-icon>add</mat-icon>
											</button>
											TTL: PAID OUTS (paid out (+) / paid in (-)
										</th>
										<th>{{totals.paidouts | currency}}</th>
										<th colspan="2">TTL: SALES</th>
										<th>{{totals.sales | currency}}</th>
									</tr> -->
									<tr>
										<th colspan="3">
											<table>
												<tr *ngFor="let sale of results.creditcards">
													<td width="33%"><input [disabled]="sale.removable == 0" mat-input [(ngModel)]="sale.label"></td>
													<td width="33%"><input mat-input [(ngModel)]="sale.value" (change)="recalcTotals()"></td>
													<td width="33%"><button *ngIf="sale.removable" class="noprint" mat-icon-button (click)="removeEntry(sale,results.creditcards)">
															<mat-icon>remove</mat-icon>
														</button></td>
												</tr>
											</table>
										</th>
										<th colspan="3">
											<table>
												<tr *ngFor="let sale of results.taxfees">
													<td width="33%"><input [disabled]="sale.removable == 0" mat-input [(ngModel)]="sale.label"></td>
													<td width="33%"><input mat-input [(ngModel)]="sale.value" (change)="recalcTotals()"></td>
													<td width="33%">

														<button *ngIf="sale.removable" mat-icon-button class="noprint" (click)="removeEntry(sale,results.taxfees)">
															<mat-icon>remove</mat-icon>
														</button>
													</td>
												</tr>
											</table>
										</th>
									</tr>
									<tr>
										<th colspan="2"><button mat-icon-button (click)="addEntry(results.creditcards)">
												<mat-icon>add</mat-icon>
											</button>TTL: CREDIT CARDS</th>
										<th>{{totals.creditcards}}</th>
										<th colspan="2">TTL: TAXES & FEES</th>
										<th>{{totals.taxfees | currency}}</th>
									</tr>
									<tr>
										<th colspan="3">
											<table>
												<tr *ngFor="let sale of results.housear">
													<td width="33%"><input [disabled]="sale.removable == 0" mat-input [(ngModel)]="sale.label"></td>
													<td width="33%"><input mat-input [(ngModel)]="sale.value" (change)="recalcTotals()"></td>
													<td width="33%"><button *ngIf="sale.removable" class="noprint" mat-icon-button (click)="removeEntry(sale,results.housear)">
															<mat-icon>remove</mat-icon>
														</button></td>
												</tr>
											</table>
										</th>
										<th colspan="3">
											<table>
												<tr *ngFor="let sale of results.housearcollect">
													<td width="33%"><input [disabled]="sale.removable == 0" mat-input [(ngModel)]="sale.label"></td>
													<td width="33%"><input mat-input [(ngModel)]="sale.value" (change)="recalcTotals()"></td>
													<td width="33%"><button *ngIf="sale.removable" class="noprint" mat-icon-button (click)="removeEntry(sale,results.housearcollect)">
															<mat-icon>remove</mat-icon>
														</button></td>
												</tr>
											</table>
										</th>
									</tr>
									<tr>
										<th colspan="2"><button mat-icon-button (click)="addEntry(results.housear)">
												<mat-icon>add</mat-icon>
											</button>TTL: HOUSE A/R CHARGES</th>
										<th>{{totals.housear | currency}}</th>
										<th colspan="2"><button mat-icon-button (click)="addEntry(results.housearcollect)">
												<mat-icon>add</mat-icon>
											</button>TTL: HOUSE A/R COLLECTIONS</th>
										<th>{{totals.housearcollect | currency}}</th>
									</tr>
									<tr>
										<th colspan="3">
											<table>
												<tr *ngFor="let sale of results.bankdep">
													<td width="33%"><input [disabled]="sale.removable == 0" mat-input [(ngModel)]="sale.label"></td>
													<td width="33%"><input mat-input [(ngModel)]="sale.value" (change)="recalcTotals()"></td>
													<td width="33%"><button *ngIf="sale.removable" class="noprint" mat-icon-button>
															<mat-icon>remove</mat-icon>
														</button></td>
												</tr>
											</table>
										</th>
										<th colspan="3"></th>
									</tr>
									<tr>
										<th colspan="2"><button mat-icon-button (click)="addEntry(results.bankdep)">
												<mat-icon>add</mat-icon>
											</button>TTL: BANK DEPOSITS</th>
										<th>{{totals.bankdeposit | currency }}</th>
										<th colspan="2"></th>
										<th></th>
									</tr>
									<tr>
										<th colspan="3">
											<table>
												<tr *ngFor="let sale of results.otherar">
													<td width="33%"><input [disabled]="sale.removable == 0" mat-input [(ngModel)]="sale.label"></td>
													<td width="33%"><input mat-input [(ngModel)]="sale.value" (change)="recalcTotals()"></td>
													<td width="33%"><button *ngIf="sale.removable" class="noprint" mat-icon-button (click)="removeEntry(sale,results.otherar)">
															<mat-icon>remove</mat-icon>
														</button></td>
												</tr>
											</table>
										</th>
										<th colspan="3">
											<table>
												<tr *ngFor="let sale of results.othersold">
													<td width="33%"><input [disabled]="sale.removable == 0" mat-input [(ngModel)]="sale.label"></td>
													<td width="33%"><input mat-input [(ngModel)]="sale.value" (change)="recalcTotals()"></td>
													<td width="33%"><button *ngIf="sale.removable" class="noprint" mat-icon-button (click)="removeEntry(sale,results.othersold)">
															<mat-icon>remove</mat-icon>
														</button></td>
												</tr>
											</table>
										</th>
									</tr>
									<tr>
										<th colspan="2"><button mat-icon-button (click)="addEntry(results.otherar)">
												<mat-icon>add</mat-icon>
											</button>TTL: OTHER</th>
										<th>{{totals.otherrec | currency}}</th>
										<th colspan="2"><button mat-icon-button (click)="addEntry(results.othersold)">
												<mat-icon>add</mat-icon>
											</button>TTL: OTHER</th>
										<th>{{totals.othersold | currency}}</th>
									</tr>
									<tr>
										<th colspan="2">TTL:RECEIVED</th>
										<th>{{totals.totalrec | currency}}</th>
										<th colspan="2">TTL: SOLD & COLLECTED</th>
										<th>{{totals.totalcollect | currency}}</th>
									</tr>
									<tr>
										<th colspan="5">OVER / SHORT </th>
										<th> {{totals.overshort | currency}}</th>
									</tr>
								</table>
							</ng-container>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</div>
