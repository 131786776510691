import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { OmsService } from '../../services/oms.service';
import { GlobalsService } from '../../services/globals.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


import { ItemList } from '../../classes/items';
import { OrderTypes, OrderHeader } from '../../classes/orders';

import { Location } from '@angular/common'

@Component({
	selector: 'app-order-confirmation',
	templateUrl: './order-confirmation.component.html',
	styleUrls: ['./order-confirmation.component.scss']
})

export class OrderConfirmationComponent implements OnInit, AfterViewInit {

	@ViewChild('stockidsearch') stockidSearchField: ElementRef;
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	orderForm: UntypedFormGroup;
	CHAT_ROOM = 'OrderBoard';
	allorders: OrderHeader;
	picking: OrderTypes;
	forcesearch = true;
	filteredItems: any = [];
	openorders: any;
	headercolumns: string[] = [
		'actions',
		'status',
		'orderno',
		'transno',
		'ponumber',
		'debtorno',
		'deliverto',
		'deliverydate',
	];
	orderupdates: any = [];
	openOrderData: any = [];
	config: any = [];

	//pdf: todo - make this part of config
	baselink = '';
	color: any ='';

	constructor(private route: ActivatedRoute, private formBuilder: UntypedFormBuilder,private ordersService: OrdersService, public router: Router, private location: Location, private inventoryService: InventoryService, private omsService: OmsService, private globalSearchService: GlobalSearchService, private globalsService: GlobalsService) {

		this.color = this.globalSearchService.getColor();

	}

	ngOnInit(): void {

		this.config = this.globalsService.getApiConfig()
		this.baselink= this.config.apiServer.pickingLink;
		const token = this.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
		if (token) {
			this.omsService.setupSocketConnection(token);
			this.omsService.subscribeToOpenOrders((err, data) => {
				this.orderupdates = data;
				this.openorders = new MatTableDataSource(data);
				this.openorders.sort = this.sort;
				this.openorders.paginator = this.paginator;
				this.globalSearchService.showNotification('New Order Added ','info','top','right');
			});
		}

		this.globalSearchService.pickingOrderData.subscribe((results: OrderTypes) => {
			this.picking = results;
		});

		this.ordersService.getOrders().subscribe((results: any) => {
			this.openOrderData = results;
			this.openorders = new MatTableDataSource(results);
			this.openorders.sort = this.sort;
			this.openorders.paginator = this.paginator;
			//MatTableDataSource
		});

		this.route.params.subscribe(params => {
			const orderno = params['id'];
			if(orderno) {
				this.forcesearch = false;
			}
			if(!orderno) {
				this.forcesearch = true;
				this.ordersService.getOrders().subscribe((orders: OrderHeader) => {
					this.allorders = orders
				});
			}
		});
	}

	announceSortChange(sortState: Sort) {

	}

	back(): void {
		this.location.back()
	}

	ngAfterViewInit() {

	}

	loadOrder(orderno: any) {
		this.ordersService.loadSalesOrder(orderno).subscribe((result:any) => {
			const link = result.header.debtorno+'/'+result.header.branchcode;
			this.router.navigate(['/orders/entry/'+link]);
		})
	}

	randomString(length, chars) {
		let result = '';
		for (let i = length; i > 0; --i) {result += chars[Math.floor(Math.random() * chars.length)];}
		return result;
	}

	onInput(event: any, keys = 'orderno'){
		//tood make this ajax call to search for items on orders as well

		this.openorders = this.filterItem(this.openOrderData, event.target.value, 'orderno,transno,ponumber,debtorno,deliverto,deliverydate');
	}

	filterOrders(orders) {

		const allresults = [];

		if(this.openOrderData) {
			const openorders = this.filterItem(this.openOrderData, '-', 'orderno,transno,ponumber,debtorno,deliverto,deliverydate');
			if(openorders) {
				openorders.forEach(function (value) {
				});
			}
		}

		this.setFiltered(allresults)

	}

	setFilteredLists(term) {

		const allresults = [];
		const ordersFound = [];

		const openorders = this.filterItem(this.openOrderData, term, 'orderno,transno,ponumber,debtorno,deliverto,deliverydate');
		if(openorders) {
			openorders.forEach(function (value) {
				allresults.push({ content: 'Open Order', details: value, link:'/orders/lookup/'+value.ORDER_NUMBER })
				ordersFound.push(value.ORDER_NUMBER)
			});
		}

		this.setFiltered(allresults)

	}

	exportExcel() {
		this.globalSearchService.exportJsonToExcel(this.openOrderData, 'OpenOrders');
	}

	getFiltered() {
		return this.filteredItems;
	}

	setFiltered(results) {
		this.filteredItems = results;
	}

	assignCopy(input){
		this.filteredItems = Object.assign([], input);
	}

	filterItem(input, term , keys ){
		if(!term){
			this.assignCopy(input);
		}
		const result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) &&	new RegExp(term, 'gi').test(item[key])));
		return result
	}

}
