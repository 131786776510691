<div class="main-content" >
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{ color }}-1">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Merge Customers </h4>
						<p class="card-category text-right">Merge Customers</p>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-md-6">
								<mat-form-field appearance="outline">
									<mat-label>FROM</mat-label>
									<input type="text" class="form-control text-right" matInput [formControl]="fromcustomer" (input)="customerSearch($event.target.value, 'from')" [matAutocomplete]="autoFrom" />
									<mat-autocomplete #autoFrom="matAutocomplete" [displayWith]="displayFn" (optionSelected)="setFrom($event.option.value)">
									  <mat-option *ngFor="let option of fromresults" [value]="option" >
										{{option.debtorno}} {{option.name}}
									  </mat-option>
									</mat-autocomplete>
								</mat-form-field>
							</div>
							<div class="col-md-6">
								<mat-form-field appearance="outline">
									<mat-label>TO</mat-label>
									<input type="text" class="form-control text-right" matInput [formControl]="tocustomer" (input)="customerSearch($event.target.value, 'to')" [matAutocomplete]="autoTo" />
									<mat-autocomplete #autoTo="matAutocomplete" [displayWith]="displayFn" (optionSelected)="setTo($event.option.value)" >
									  <mat-option *ngFor="let option of toresults" [value]="option" >
										<b>{{option.debtorno}}:</b> {{option.name}}
									  </mat-option>
									</mat-autocomplete>
								</mat-form-field>
							</div>

							<div class="col-12 text-right" *ngIf="from && to">
								<button mat-raised-button (click)="mergeCustomers()"><i class="fa fa-send"></i> MERGE</button>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
