<div class="main-content">
	<div class="container-fluid">
		<div class="card">
			<div class="card-header card-header-{{ color }}-5">
				<h4 class="card-title">Form Builder</h4>
				<p class="card-category"></p>
			</div>
			<div class="card-body">


				<div>
					<h2>Form Builder</h2>
					<div class="form-builder">
						<div class="available-fields" cdkDropList [cdkDropListData]="fields" [cdkDropListConnectedTo]="['formDesignList']">
							<h3>Available Fields</h3>
							<div *ngFor="let field of fields" cdkDrag [cdkDragData]="field" class="form-field">
								{{ field.label }}
								<input *ngIf="field.type === 'text'" type="text" disabled class="form-control mt-2">
								<input *ngIf="field.type === 'number'" type="number" disabled class="form-control mt-2">
								<textarea *ngIf="field.type === 'textarea'" disabled class="form-control mt-2"></textarea>
								<input *ngIf="field.type === 'checkbox'" type="checkbox" disabled class="form-check-input mt-2">
								<input *ngIf="field.type === 'radio'" type="radio" disabled class="form-check-input mt-2">
								<select *ngIf="field.type === 'select'" disabled class="form-control mt-2">
									<option>Option</option>
								</select>
								<p *ngIf="field.type === 'paragraph'" class="form-control-plaintext mt-2">Paragraph text</p>
								<img *ngIf="field.type === 'image'" src="https://via.placeholder.com/100" class="img-thumbnail mt-2" alt="Image placeholder">
							</div>
						</div>
						<div class="drop-area" cdkDropList id="formDesignList" (cdkDropListDropped)="onDrop($event)">
							<h3>Form Design</h3>
							<div class="form-group-placeholder" *ngIf="formControls.length === 0">Drag fields here</div>
							<div *ngFor="let control of formControls; let i = index" class="form-group" [formGroup]="formGroup">
								<label [for]="control.name">{{ control.label }}</label>
								<div class="input-group">
									<input *ngIf="control.type === 'text'" type="text" formControlName="{{control.name}}" class="form-control mt-2">
									<input *ngIf="control.type === 'number'" type="number" formControlName="{{control.name}}" class="form-control mt-2">
									<textarea *ngIf="control.type === 'textarea'" formControlName="{{control.name}}" class="form-control mt-2"></textarea>
									<input *ngIf="control.type === 'checkbox'" type="checkbox" formControlName="{{control.name}}" class="form-check-input mt-2">
									<input *ngIf="control.type === 'radio'" type="radio" formControlName="{{control.name}}" class="form-check-input mt-2">
									<select *ngIf="control.type === 'select'" formControlName="{{control.name}}" class="form-control mt-2">
										<option *ngFor="let option of control.options" [value]="option">{{ option }}</option>
									</select>
									<p *ngIf="control.type === 'paragraph'" class="form-control-plaintext mt-2">Paragraph text</p>
									<img *ngIf="control.type === 'image'" src="https://via.placeholder.com/100" class="img-thumbnail mt-2" alt="Image placeholder">
									<div class="input-group-append">
										<button type="button" class="btn btn-secondary mt-2" (click)="openCustomizer(control, fieldCustomizer)">
											<mat-icon>settings</mat-icon>
										</button>
										<button type="button" class="btn btn-danger mt-2" (click)="removeField(i)">
											<mat-icon>delete</mat-icon>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
						<button type="submit" class="btn btn-primary">Create Form</button>
					</form>

					<!-- Field Customizer Modal -->
					<ng-template #fieldCustomizer let-modal>
						<div class="modal-header">
							<h4 class="modal-title">Field Customization</h4>
							<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form [formGroup]="customizationForm">
								<div class="form-group">
									<label for="label">Label</label>
									<input type="text" id="label" formControlName="label" class="form-control">
								</div>
								<div class="form-group">
									<label for="placeholder">Placeholder</label>
									<input type="text" id="placeholder" formControlName="placeholder" class="form-control">
								</div>
								<div class="form-group">
									<label for="required">Required</label>
									<input type="checkbox" id="required" formControlName="required" class="form-check-input">
								</div>
							</form>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-primary" (click)="saveCustomization(modal)">Save</button>
						</div>
					</ng-template>
				</div>








			</div>
		</div>
	</div>
</div>
