import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
	test : Date = new Date();
    url = '/assets/version.json';
	version: any = '';
	constructor() { }

	ngOnInit() {
		let version = JSON.parse(localStorage.getItem('v8version'));
		this.version = version?.version;
	}

}
