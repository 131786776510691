<ng-template #details let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">{{ displaydetails.title }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<!--
		<mat-chip-set role="list">
			<mat-chip role="listitem" *ngFor="let l of viewedtransactions">
				<button mat-menu-item (click)="viewPayments(l,details)">
					<mat-icon>credit_card</mat-icon>
					<span>Payments</span>
				</button>
			</mat-chip>
		</mat-chip-set>
		-->

		<div class="mb-3" *ngIf="displaydetails">
			<h4></h4>
			<ng-container *ngIf="displaydetails.type == 'html'">
				<div [innerHTML]="displaydetails.display">
				</div>
			</ng-container>
			<ng-container *ngIf="displaydetails.type == 'pdf'">
				<ngx-extended-pdf-viewer [base64Src]="displaydetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true" [filenameForDownload]="displaydetails.title"></ngx-extended-pdf-viewer>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #orderView let-modal>
	<div class="modal-header">
		<div class="modal-title row m-0 p-0" id="modal-title">
			<h4>Orders</h4>
		</div>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="orderdetails">
			<div class="table">
				<table class="table table-sm">
					<tr>
						<th>Order #</th>
						<th>Customer</th>
						<th>Type</th>
						<th class="text-center">Date</th>
						<th class="text-center">Salesman Code</th>
						<th class="text-center">Order Total</th>
					</tr>
					<tr *ngFor="let item of orderdetails">

						<td><button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More" class="donotprint">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #menu="matMenu">
								<button mat-menu-item (click)="openDocument(item,details,'html')">
									<mat-icon>visibility</mat-icon>
									<span>View HTML</span>
								</button>
								<button mat-menu-item (click)="openDocument(item,details,'pdf')">
									<mat-icon>visibility</mat-icon>
									<span>View PDF</span>
								</button>

							</mat-menu>{{item.transno}}
						</td>
						<td>{{item.Customer}} - {{item.Name}}</td>
						<td>{{item.DocType}}</td>
						<td class="text-center">{{item.Date}}</td>
						<td class="text-center">{{item.Salesman}}</td>
						<td class="text-center">{{item.Sales | currency}}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i> Salesman Sales by Branch
						</h4>
						<p class="card-category text-right">SRPT</p>
					</div>
					<div class="card-body">
						<form class="navbar-form">
							<div class="row">

								<div class="col-4">
									<mat-form-field appearance="outline">
										<mat-label>Transaction Dates</mat-label>
										<mat-date-range-input [rangePicker]="picker">
											<input matStartDate [formControl]="fromdateCtrl" placeholder="Start date">
											<input matEndDate [formControl]="todateCtrl" placeholder="End date">
										</mat-date-range-input>
										<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
										<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
										<mat-date-range-picker #picker></mat-date-range-picker>
									</mat-form-field>
								</div>

								<div class="col-4">
									<mat-form-field appearance="outline">
										<mat-label>Branches</mat-label>
										<mat-select [formControl]="branchRef" multiple #branchFilter>
											<div class="select-all" (click)="selectAllToggle('branch')">
												<button mat-icon-button>
													<div ml="1">Select All/None</div>
												</button>
											</div>
											<mat-option *ngFor="let k of branches" [value]="k.loccode">{{k.locationname}}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

								<div class="col-4">
									<mat-form-field appearance="outline">
										<mat-label>Salespeople</mat-label>
										<mat-select [formControl]="salespersonRef" multiple #salesFilter>
											<div class="select-all" (click)="selectAllToggle('salesman')">
												<button mat-icon-button>
													<div ml="1">Select All/None</div>
												</button>
											</div>
											<mat-option *ngFor="let k of salespeople" [value]="k.salesmancode">{{k.salesmanname}}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

								<div class="col-4">
									<mat-form-field appearance="outline">
										<mat-label>Consolidated or Branch?</mat-label>
										<mat-select [formControl]="conBrRef">
											<mat-option *ngFor="let v of conbr" [value]="v.value">
												{{v.label}}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

								<div class="col-4">
									<mat-form-field appearance="outline">
										<mat-label>Web Orders?</mat-label>
										<mat-select [formControl]="placedFromRef">
											<mat-option *ngFor="let v of placedfrom" [value]="v.value">
												{{v.label}}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

								<div class="col-4">
									<button mat-raised-button color="white" type="submit" (click)="getCRPT()">
										<mat-icon class="p-0">search</mat-icon> Search
									</button>
									<span *ngIf="sending">
										<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
											<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
										</svg>
									</span>
									&nbsp;&nbsp;
									<button class="btn btn-success btn-sm" (click)="$event.preventDefault();exportXls()" *ngIf="orderResults"> <i class="fa fa-file-excel-o"></i> XLS </button>
									&nbsp;&nbsp;
									<button class="btn btn-danger btn-sm" (click)="$event.preventDefault();exportPdf()" *ngIf="orderResults"> <i class="fa fa-file-pdf-o"></i> PDF </button>
								</div>
							</div>

						</form>

						<ng-container *ngIf="orderResults">
							<h3>Salesman Sales by Branch {{reportCriteria.fromdate | date:'MM/d/yy'}} - {{reportCriteria.todate | date: 'MM/d/yy'}}</h3>
							<h4>{{getReportSubtitle()}}</h4>
							<table class="table" *ngIf="orderResults">

								<tr>
									<th>BR</th>
									<th>Sales ID</th>
									<th>Sales Name</th>
									<th>Sales</th>
								</tr>
								<ng-container *ngFor="let o of orderResults.lines | keyvalue">
									<tr>
										<th colspan="4">{{getBranchName(o.key)}}</th>
									</tr>
									<ng-container *ngFor="let line of o.value.l">
										<tr>
											<td>
												{{ line.BR }}
											</td>
											<td><a class="link" (click)="viewCustomer(line.Salesman,line.BR)">{{ line.Salesman }}</a></td>
											<td>{{ line.Salesmanname }}</td>
											<td>{{ line.Sales | currency }}</td>
										</tr>
									</ng-container>
									<tr>
										<th></th>
										<th></th>
										<th></th>
										<th>{{o.key }} Total: {{ o.value.total | currency}}</th>
									</tr>
								</ng-container>
								<tr>
									<th></th>
									<th>Count : {{ orderResults.totalrecords }}</th>
									<th>Avg: {{ orderResults.avg | currency }}</th>
									<th>Total: {{ orderResults.total | currency}}</th>
								</tr>
							</table>
						</ng-container>

						<div #print_tablepdf style="display: none;">
							<table class="table" *ngIf="orderResults" style="width: 45%; margin: auto;">
								<tr>
									<th>BR</th>
									<th>Sales ID</th>
									<th style="text-align: left;">Sales Name</th>
									<th style="text-align: right;">Sales</th>
								</tr>
								<ng-container *ngFor="let o of orderResults.lines | keyvalue">
									<tr>
										<th colspan="4" style="text-align: left;"> {{getBranchName(o.key)}} </th>
									</tr>
									<ng-container *ngFor="let line of o.value.l">
										<tr>
											<td>
												{{ line.BR }}
											</td>
											<td style="text-align: center;">{{ line.Salesman }}</td>
											<td style="text-align: left;">{{ line.Salesmanname }}</td>
											<td style="text-align: right;">{{ line.Sales | currency }}</td>
										</tr>
									</ng-container>
									<tr>
										<th></th>
										<th></th>
										<th></th>
										<th style="text-align: right;">{{o.key }} Total: {{ o.value.total | currency}}</th>
									</tr>
								</ng-container>
								<tr>
									<th></th>
									<th>Count : {{ orderResults.totalrecords }}</th>
									<th style="text-align: left;">Avg: {{ orderResults.avg | currency }}</th>
									<th style="text-align: right;">Total: {{ orderResults.total | currency}}</th>
								</tr>
							</table>
						</div>

						<div #print_tablexlsx style="display: none;">
							<table class="table" *ngIf="orderResults">
								<tr>
									<th>BR</th>
									<th>Sales ID</th>
									<th>Sales Name</th>
									<th>Sales</th>
								</tr>
								<ng-container *ngFor="let o of orderResults.lines | keyvalue">
									<tr>
										<th colspan="4"> {{getBranchName(o.key)}} </th>
									</tr>
									<ng-container *ngFor="let line of o.value.l">
										<tr>
											<td>
												{{ line.BR }}
											</td>
											<td><b>{{ line.Salesman }}</b></td>
											<td>{{ line.Salesmanname }}</td>
											<td>{{ line.Sales | currency }}</td>
										</tr>
									</ng-container>
									<tr>
										<th colspan="3"></th>

										<th>{{o.key }} Total: {{ o.value.total | currency}}</th>
									</tr>
								</ng-container>
								<tr>
									<th></th>
									<th>Count : {{ orderResults.totalrecords }}</th>
									<th>Avg: {{ orderResults.avg | currency }}</th>
									<th>Total: {{ orderResults.total | currency}}</th>
								</tr>
							</table>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</div>