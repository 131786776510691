<ng-template #orderDetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Purchase Order</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="podetails">
			<h4>PO # {{ podetails.poheader.orderno }}</h4>
			<div class="table">
				<table class="table table-sm">
					<tr>
						<td>
							{{podetails.poheader.suppliercontact}}<br>
							{{podetails.poheader.suppdeladdress1}}<br>
							{{podetails.poheader.suppdeladdress2}}<br>
							{{podetails.poheader.suppdeladdress3}}, {{podetails.poheader.suppdeladdress4}} {{podetails.poheader.suppdeladdress5}}
						</td>
						<td>
							{{podetails.poheader.deladd1}}<br>
							<span *ngIf="podetails.poheader.deladd2 != ''">{{podetails.poheader.deladd2}}<br></span>
							{{podetails.poheader.deladd3}}, {{podetails.poheader.deladd4}} {{podetails.poheader.deladd5}}
						</td>
					</tr>
				</table>
				<div class="card">
					<div class="card-body" [innerHTML]="podetails.poheader.stat_comment"></div>
				</div>

				<table class="table table-sm">
					<tr>
						<th>ITEM</th>
						<th>DESC</th>
						<th class="text-center">QTY</th>
						<th class="text-center">RCVD</th>
						<th class="text-right">PRICE</th>
						<th class="text-right">TOTAL</th>
					</tr>
					<tr *ngFor="let item of podetails.polines">
						<td>{{item.itemcode}}</td>
						<td>{{item.itemdescription}}</td>
						<td  class="text-center">{{item.quantityord}}</td>
						<td  class="text-center">{{item.quantityrecd}}</td>
						<td class="text-right">{{item.unitprice | currency}}</td>
						<td class="text-right">{{item.unitprice * item.quantityord | currency}}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="table">
	<table mat-table [dataSource]="purchaseorders" matSort (matSortChange)="announceSortChange($event)">
		<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef mat-sort-header></th>
			<td mat-cell *matCellDef="let tran">
				<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
					<mat-icon>more_vert</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button mat-menu-item (click)="viewOrder(tran.orderno, orderDetails)">
						<mat-icon>visibility</mat-icon>
						<span>View</span>
					</button>

					<a href="{{config.apiServer.baseUrl}}{{baselink}}{{tran.orderno}}" mat-menu-item>
						<mat-icon>print</mat-icon>
						<span>PDF</span>
					</a>
					<a href="{{config.apiServer.baseUrl}}{{reclink}}{{tran.orderno}}" mat-menu-item>
						<mat-icon>print</mat-icon>
						<span>Rcv Doc</span>
					</a>
					<mat-divider></mat-divider>

					<button mat-menu-item [routerLink]="'/purchasing/'+tran.supplierno+'/'+tran.orderno">
						<mat-icon>edit</mat-icon>
						<span>Edit</span>
					</button>

				</mat-menu>
			</td>
		</ng-container>
		<ng-container matColumnDef="suppname">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
			<td mat-cell *matCellDef="let tran">
				{{ tran.suppname }}
			</td>
		</ng-container>
		<ng-container matColumnDef="orddate">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Date Ordered
			<th>
			<td mat-cell *matCellDef="let tran">{{ tran.orddate }}</td>
		</ng-container>
		<ng-container matColumnDef="status_id">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Status.</th>
			<td mat-cell *matCellDef="let tran">
				<ng-container *ngIf="!tran.editing">
					{{ tran.statusname }}
					<button mat-icon-button aria-label="Change Status" (click)="toggleStatusChange(tran)">
						<mat-icon>edit</mat-icon>
					</button>
				</ng-container>
				<mat-form-field appearance="fill" *ngIf="tran.editing">
					<mat-label>Status</mat-label>
					<mat-select [(value)]="tran.status_id" (selectionChange)="updateStatus($event, tran)">
						<mat-option *ngFor="let status of statuses" [(value)]="status.status_id">{{ status.name }}</mat-option>
					</mat-select>
					<span matSuffix *ngIf="tran.editing">
						<mat-icon (click)="toggleStatusChange(tran)">clear</mat-icon>
					</span>
				</mat-form-field>
			</td>
		</ng-container>

		<ng-container matColumnDef="orderno">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Order#.</th>
			<td mat-cell *matCellDef="let tran">{{ tran.orderno }}</td>
		</ng-container>

		<ng-container matColumnDef="requisitionno">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> PO number#.</th>
			<td mat-cell *matCellDef="let tran">{{ tran.requisitionno }}</td>
		</ng-container>

		<ng-container matColumnDef="ordervalue">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Amount</th>
			<td mat-cell *matCellDef="let tran">{{ tran.ordervalue | currency }}</td>
		</ng-container>
		<ng-container matColumnDef="deliverydate">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Date Wanted
			<th>
			<td mat-cell *matCellDef="let tran">{{ tran.deliverydate }}</td>
		</ng-container>
		<ng-container matColumnDef="initiator">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Initiator
			<th>
			<td mat-cell *matCellDef="let tran">
				{{ tran.initiator }}
			</td>
		</ng-container>
		<ng-container matColumnDef="ponumber">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>
			<th>
			<td mat-cell *matCellDef="let tran">


			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="headercolumns"></tr>
		<tr mat-row *matRowDef="let row; columns: headercolumns"></tr>
	</table>
	<mat-paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons aria-label="select page">
	</mat-paginator>
</div>
