<ng-template #details let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">{{ displaydetails.title }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<!--
		<mat-chip-set role="list">
			<mat-chip role="listitem" *ngFor="let l of viewedtransactions">
				<button mat-menu-item (click)="viewPayments(l,details)">
					<mat-icon>credit_card</mat-icon>
					<span>Payments</span>
				</button>
			</mat-chip>
		</mat-chip-set>
		-->

		<div class="mb-3" *ngIf="displaydetails">
			<h4></h4>
			<ng-container *ngIf="displaydetails.type == 'html'">
				<div [innerHTML]="displaydetails.display">
				</div>
			</ng-container>
			<ng-container *ngIf="displaydetails.type == 'pdf'">
				<ngx-extended-pdf-viewer [base64Src]="displaydetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true" [filenameForDownload]="displaydetails.title"></ngx-extended-pdf-viewer>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-5">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Inventory
							Transfers</h4>
						<p class="card-category"></p>
					</div>
					<div class="card-body">
						<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent">
							<mat-tab label="Open Transfers">
								<ng-template matTabContent>
									<div class="container-fluid tp-box" [@flipState]="flip">
										<div class="row backside tp-box__side tp-box__back" [ngClass]="flip != 'inactive' ? '' : 'd-none'">
											<div class="col-md-12">
												<app-transfers-create *ngIf="!editingtransfer" (transferComplete)="tComp($event)" (resetTransfer)="reset()"></app-transfers-create>
												<app-transfers-edit [transno]="{editingtransfer}" *ngIf="editingtransfer" (transferComplete)="tComp($event)" (resetTransfer)="reset()"></app-transfers-edit>
											</div>
										</div>
										<div class="row tp-box__side tp-box__front" id="frontside" [ngClass]="flip != 'active' ? '' : 'd-none'">
											<button mat-stroked-button color="success" aria-label="" (click)="addTransfer()" class="mt-2 mb-2">
												<mat-icon>add</mat-icon> Add New Transfer
											</button>

											<div class="container-fluid">
												<form [formGroup]="searchForm">
													<div class="row">
														<div class="col-md-2">
															<mat-form-field appearance="fill">
																<mat-label>Start Date</mat-label>
																<input matInput [matDatepicker]="picker1" formControlName="startdate" (dateChange)="getTransfers()" />
																<mat-datepicker-toggle matSuffix [for]="picker1">
																</mat-datepicker-toggle>
																<mat-datepicker #picker1></mat-datepicker>
															</mat-form-field>
														</div>
														<div class="col-md-2">
															<mat-form-field appearance="fill">
																<mat-label>End Date</mat-label>
																<input matInput [matDatepicker]="picker2" formControlName="enddate" (dateChange)="getTransfers()" />
																<mat-datepicker-toggle matSuffix [for]="picker2">
																</mat-datepicker-toggle>
																<mat-datepicker #picker2></mat-datepicker>
															</mat-form-field>
														</div>
														<div class="col-md-3">
															<mat-form-field appearance="fill">
																<mat-label>From Location</mat-label>
																<mat-select formControlName="fromloc" (selectionChange)="getTransfers()">
																	<mat-option value="">
																		---- Any ----
																	</mat-option>
																	<mat-option *ngFor="let loc of tranlocations" [value]="loc.loccode">
																		{{loc.locationname }}
																	</mat-option>
																</mat-select>
															</mat-form-field>
														</div>
														<div class="col-md-3">
															<mat-form-field appearance="fill">
																<mat-label>To Location</mat-label>
																<mat-select formControlName="toloc" (selectionChange)="getTransfers()">
																	<mat-option value="">
																		---- Any ----
																	</mat-option>
																	<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
																		{{loc.locationname }}
																	</mat-option>
																</mat-select>
															</mat-form-field>
														</div>
														<div class="col-md-2">
															<mat-form-field appearance="fill">
																<mat-label>Status</mat-label>
																<mat-select formControlName="status" (selectionChange)="getTransfers()">
																	<mat-option value="">
																		---- Any ----
																	</mat-option>
																	<mat-option *ngFor="let status of statuses" [value]="status.code">
																		{{status.description }}
																	</mat-option>
																</mat-select>
															</mat-form-field>
														</div>
													</div>
												</form>
												<div class="table ov">
													<div class="text-right"> Results ({{transferlist.length}})</div>
													<table class="table table-sm">
														<tr>
															<th></th>
															<th>Document #</th>
															<th>Input Date</th>
															<th>From Location</th>
															<th>To Location</th>
															<th>Notes</th>
															<th>Status</th>
															<th>Total</th>
														</tr>
														<tr *ngFor="let t of transferlist">
															<td>
																<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More" class="donotprint">
																	<mat-icon>more_vert</mat-icon>
																</button>
																<mat-menu #menu="matMenu">
																	<button mat-menu-item *ngIf="t.status == 0" (click)="editTransfer(t.transno)">
																		<mat-icon>edit</mat-icon>
																		<span>Edit</span>
																	</button>
																	<button mat-menu-item (click)="openDocument(t,details,'pdf')">
																		<mat-icon>visibility</mat-icon>
																		<span>PDF</span>
																	</button>
																	<button mat-menu-item (click)="cancelTransfer(t)">
																		<mat-icon>cancel</mat-icon>
																		<span>Cancel</span>
																	</button>
																</mat-menu>
															</td>
															<td>{{ t.transno }}</td>
															<td>{{ t.inputdate | date }}</td>
															<td>{{ t.fromstkloc }}</td>
															<td>{{ t.tostkloc }}</td>
															<td class="mw-300">{{ t.notes }}</td>
															<td>
																<div [ngClass]="statuses[t.status].code == 0 ? 'entered' : statuses[t.status].code == 1 ? 'released' : statuses[t.status].code == 2 ? 'received' : statuses[t.status].code == 3 ? 'cancelld' : ''">{{ statuses[t.status].description }}</div>
															</td>
															<td>{{t.ovasaletotal | currency}}</td>
														</tr>
													</table>
													<ng-container *ngIf="!transferlist || !transferlist.length ">
														<h4 class="text-lg-center"> No Open Transfers</h4>
													</ng-container>
												</div>
											</div>
										</div>
									</div>
								</ng-template>
							</mat-tab>
							<mat-tab label="Transfer Release" (click)="reload()">
								<ng-template matTabContent>
									<app-transfers-release (refreshTransfers)="reload()"></app-transfers-release>
								</ng-template>
							</mat-tab>
							<mat-tab label="Transfer Receiving" (click)="reload()">
								<ng-template matTabContent>
									<app-transfers-receive (refreshTransfers)="reload()"></app-transfers-receive>
								</ng-template>
							</mat-tab>
						</mat-tab-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
