import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router } from '@angular/router';

import { InventoryService } from '../../../services/inventory.service';
import { GlobalsService } from '../../../services/globals.service';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { Customer } from '../../../classes/customer';
import { Location } from '@angular/common'
import { GlobalSearchService } from 'app/services/globalsearchservice.service';


@Component({
	selector: 'app-bin-items',
	templateUrl: './bin-items.component.html',
	styleUrls: ['./bin-items.component.scss']
})
export class BinItemsComponent implements OnInit {

	@Input() bins;
	@Output() binupdate;

	inventorysearching: any = false;
	searching = false;
	allitems: any = [];
	allbins: any = [];
	filteredItems: any = [];
	itemSearchForm = new UntypedFormControl('');
	selectedItemSearch = new UntypedFormControl('');
	transferBin = new UntypedFormControl('');
	selected_items: any = [];
	filtered_items: any = [];
	tobins: any = [];
	alltobins: any = [];
	filteredOptions: any = [];
	affected: any = false;
	neworcurrent = '';
	sending = false;
	onhandonly = true;
	newbin: boolean = false;
	config: any = false;
	verifiedbins: any = [];

	constructor(private inventoryService: InventoryService, private globalSearchService: GlobalSearchService) { }

	ngOnInit(): void {

		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})

		this.inventoryService.getVerifiedBinListing().subscribe((res: any)=>{
			this.verifiedbins = res;
		})

		this.allbins = this.bins;
		this.filteredOptions = this.bins;

		this.itemSearchForm.valueChanges.subscribe(newValue => {
			this.itemSearch(newValue)
		})

		this.transferBin.valueChanges.subscribe(newValue => {
			if(!this.filteredOptions) {
				this.filteredOptions = this.bins
			}
			this.newOrCurrentBin(newValue.toUpperCase())
			this.filteredOptions = this.filterItem(this.bins, newValue.toUpperCase(), 'location');

		});

		this.selectedItemSearch.valueChanges.subscribe(newValue => {

			this.tobins = this.filterItem(this.alltobins, newValue, 'stockid,description');
		})

	}

	toggleOnHandOnly() {
		this.onhandonly = (!this.onhandonly) ? true : false;
	}

	addSelectedItem(item: any) {
		this.affected = false;
		const index =this.filtered_items.indexOf(item)
		this.filtered_items.splice(index, 1);
		this.tobins.push(item)
		this.alltobins.push(item)
		this.tobins.sort((a, b) => (a.stockid > b.stockid) ? 1 : (a.stockid === b.stockid) ? ((a.description > b.description) ? 1 : -1) : -1 )

	}

	removeSelectedItem(item: any) {
		this.affected = false;
		let index =this.tobins.indexOf(item)
		this.tobins.splice(index, 1);

		index =this.alltobins.indexOf(item)
		this.alltobins.splice(index, 1);

		this.filtered_items.push(item)
		this.filtered_items.sort((a, b) => (a.stockid > b.stockid) ? 1 : (a.stockid === b.stockid) ? ((a.description > b.description) ? 1 : -1) : -1 )
	}

	newOrCurrentBin(binin) {
		var exists;
		if(this.config.env.package == 'tires'){
			exists = this.verifiedbins.filter(bin => {
				return (bin.bin).toUpperCase() == (binin).toUpperCase()
			})
		}else{
			exists = this.bins.filter(bin => {
				return (bin.location).toUpperCase() == (binin).toUpperCase()
			})
		}


		if(!exists.length){
			this.newbin = true;
		}else{
			this.newbin = false
		}

		if(this.transferBin.value =='') {
			this.neworcurrent = '';
		} else {
			if(this.config.env.package == 'tires'){
				this.neworcurrent = (exists.length) ? 'Merge to ' +this.transferBin.value :'"' + binin.toUpperCase() + '"' + " not on file, please see back office staff."
			}else{
				this.neworcurrent = (exists.length) ? 'Merge to ' +this.transferBin.value : 'Creating New Bin: '+this.transferBin.value
			}
		}
	}


	itemSearch(event: any) {
		this.affected = false;
		this.searching = true;
		if(this.inventorysearching) {
			this.inventorysearching.unsubscribe()
		}

		this.inventorysearching = this.inventoryService.itemCleanSearch(event).subscribe((items: any) => {
			// do not include already selected items
			const exists = this.tobins.map((binitem: any) => {
				return binitem.stockid
			});




			items = items.filter((item:any) => {
				return !exists.includes(item.stockid)
			});

			this.allitems = items
			this.filtered_items = items

			if(this.onhandonly) {
				this.filtered_items = items.filter((item:any) => {
					return parseFloat(item.qoh) > 0
				});
			}
			this.searching = false;
		});

	}

	updateItems(qohonly: any) {

		this.filtered_items = this.allitems;
		if(qohonly.checked) {
			this.onhandonly = true;
			this.filtered_items = this.allitems.filter((item:any) => {
				return parseFloat(item.qoh) > 0
			});
		} else {
			this.onhandonly = false;
		}
	}

	moveItems() {
		//tobin is container of items being moved
		this.sending = true;
		this.inventoryService.moveItemsToBin(this.tobins, this.transferBin.value.toUpperCase()).subscribe((results: any) => {

			this.sending = false;
			if(results.success) {

				const allbins = this.tobins.map( bin => {
					return bin.location
				});

				const message = 'Bin(s): '+allbins.join(',')+' Merged To '+this.transferBin.value.toUpperCase();

				alert(message);

				this.affected = results.affected
				this.allbins = results.bins
				this.filteredOptions = results.bins;
				this.selected_items = [];
				this.filtered_items = [];

				this.tobins = [];
				this.alltobins = [];
				this.filtered_items = [];
				this.transferBin.setValue('');
				this.itemSearchForm.setValue('');
			} else {

				const allbins = this.tobins.map( bin => {
					return bin.location
				});
				const message = 'MERGE FAILED! Bin(s): '+allbins.join(',')+' To '+this.transferBin.value;
				alert(message);
			}

		});
	}

	filterValues(search: string) {
		return this.allbins.filter((value) => {
			return value.location.toLowerCase().indexOf(search.toLowerCase()) === 0
		});
	}

	filterItem(input: any, term: any , keys: any ){
		const result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) &&	new RegExp(term, 'gi').test(item[key])));
		return result
	}

}
