import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-open-purchases',
	templateUrl: './open-purchases.component.html',
	styleUrls: ['./open-purchases.component.scss']
})
export class OpenPurchasesComponent implements OnInit {
	@Input() dataset: any;
	@Input() title: any = '';
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	vendor_data: any = false;

	headercolumns: string[] = [
		'actions',
		'suppname',
		'orddate',
		'status_id',
		'orderno',
		'realorderno',
		'ponumber',
		'ordervalue',
		'deliverydate',
		'initiator',
	];
	statuses: any = [];
	constructor(public router: Router,private route: ActivatedRoute,) { }

	selectItemLookup(event: any) {
		this.router.navigate(['/purchasing/'+event.header.supplierno]);
	}

	ngOnInit(): void {
		this.statuses = this.dataset.statuses
	}

	announceSortChange(event: any) {

	}

	updatePoData(event: any) {

	}

	getStatus(id: any) {
		return this.statuses.filter((st) => {
			return st.status_id = id
		})[0];
	}

}
