<ng-container>
<form @grow [formGroup]="arForm"  (ngSubmit)="onSubmit()" class="needs-validation product-add"  *ngIf="arForm">
	<div class="card-header">
		<div class="col-12 text-right">
			<button class="ml-auto mr-0" mat-button
			mat-raised-button [color]="arForm.valid ? 'warn': 'accent' "> <i class="material-icons">save</i> Save</button>
		</div>
	</div>
	<div class="row">
		<div class="col-md-6">
			<mat-form-field appearance="outline">
				<mat-label>Payment Terms</mat-label>
				<mat-select formControlName="paymentterms">
					<mat-option *ngFor="let va of terms"
					[value]="va?.termsindicator">
					{{va.terms}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-6">
			<mat-form-field appearance="outline" >
				<mat-label>Currency</mat-label>
				<mat-select formControlName="currcode">
					<mat-option *ngFor="let currency of currencies" [value]="currency?.currabrev">
						{{currency.currency}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-6">
			<mat-form-field appearance="outline" >
				<mat-label>Bank Account</mat-label>
				<mat-select formControlName="bankact">
					<mat-option *ngFor="let account of bankaccounts"
					[value]="account.accountcode">
						{{account.accountcode}}-{{account.bankaccountname}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-md-6">
			<mat-form-field appearance="outline" class="ml-2 mr-2" *ngIf="taxes">
				<mat-label>Tax Group</mat-label>
				<mat-select formControlName="taxgroupid">
					<mat-option *ngFor="let tax of taxes" [value]="tax?.taxgroupid">{{tax.taxgroupdescription}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
</form>
</ng-container>
