<form [formGroup]="priceBreakForm">
  <!-- Buttons to Add and Save Price Breaks -->
  <div class="button-group mb-3">
	<button mat-raised-button color="accent" (click)="cancelPriceBreak()">Cancel</button>
	<button mat-raised-button color="primary" (click)="addPriceBreak()" type="button">Add Price Break</button>
	<button mat-raised-button color="accent" (click)="savePriceBreaks()">Save</button>
  </div>

  <div formArrayName="priceBreaks">
	<div class="row">
	  <div *ngFor="let break of priceBreaks.controls; let i = index" class="col-md-12 mb-3">
		<mat-card class="price-break-card">
		  <mat-card-title>
			Price Break {{ i + 1 }}
			<mat-icon class="delete-icon" (click)="removePriceBreak(i)">delete</mat-icon>
		  </mat-card-title>
		  <mat-card-content [formGroupName]="i">
			<div class="row">
			  <!-- Sales Type Dropdown -->
			  <div class="col-md-6 mb-3">
				<mat-form-field appearance="outline">
				  <mat-label>Sales Type</mat-label>
				  <mat-select formControlName="salestype">
					<mat-option *ngFor="let type of salestypes" [value]="type.typeabbrev">
					  {{ type.sales_type }}
					</mat-option>
				  </mat-select>
				</mat-form-field>
			  </div>

			  <!-- Currency (currabrev) Field -->
				<div class="col-md-6">
				  <mat-form-field appearance="outline">
					<mat-label>Currency</mat-label>
					<mat-select formControlName="currabrev">
					  <mat-option *ngFor="let currency of currencies" [value]="currency">
						{{ currency }}
					  </mat-option>
					</mat-select>
				  </mat-form-field>
				</div>


			  <!-- Hidden Stock ID -->
			  <input type="hidden" formControlName="stockid">

			  <!-- Editable Fields -->
			  <div class="col-md-6">
				<mat-form-field appearance="outline">
				  <mat-label>Quantity Break</mat-label>
				  <input matInput formControlName="quantitybreak" type="number">
				</mat-form-field>
			  </div>

			  <div class="col-md-6">
				<mat-form-field appearance="outline">
				  <mat-label>Price</mat-label>
				  <input matInput formControlName="price" type="number">
				</mat-form-field>
			  </div>


			  <!-- Angular Material Date Picker for Start Date -->
			  <div class="col-md-6">
				<mat-form-field appearance="outline">
				  <mat-label>Start Date</mat-label>
				  <input matInput [matDatepicker]="pickerStart" formControlName="startdate">
				  <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
				  <mat-datepicker #pickerStart></mat-datepicker>
				</mat-form-field>
			  </div>

			  <!-- Angular Material Date Picker for End Date -->
			  <div class="col-md-6">
				<mat-form-field appearance="outline">
				  <mat-label>End Date</mat-label>
				  <input matInput [matDatepicker]="pickerEnd" formControlName="enddate">
				  <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
				  <mat-datepicker #pickerEnd></mat-datepicker>
				</mat-form-field>
			  </div>
			</div>
		  </mat-card-content>
		</mat-card>
	  </div>
	</div>
  </div>
</form>
