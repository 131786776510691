import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import { CreditService } from '../../services/credit.service';
import { GlobalsService } from '../../services/globals.service';
import { CustomerService } from '../../services/customer.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as FileSaver from 'file-saver';
import { Location } from '@angular/common'

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-open-credits',
	templateUrl: './open-credits.component.html',
	styleUrls: ['./open-credits.component.scss']
})

export class OpenCreditsComponent implements OnInit {

	@Input() customerdata: any = [];
	@Input() item: any = false;
	@Output() cart_updated = new EventEmitter < string > ();
	@Output() orderloaded = new EventEmitter < any > ();
	@Output() cancel = new EventEmitter < boolean > ();
	@Output() credit_data = new EventEmitter < any > ();


	searchInput = new UntypedFormControl('');
	searchInputItems = new UntypedFormControl('');
	defaultlocation = new UntypedFormControl('');
	order_status_edit = new UntypedFormControl('');
	order_statuses: any = false;
	openorders: any = [];
	customertrans: any = [];
	filteredItems: any = [];
	headercolumns: string[] = [
		'orderno',
		'statusname',
		'deliverydate',
		'from_order',
		'branchcode',
		'deliverto',
		'ponumber',
		'phoneno',
		'email',
		'totals'
	];

	orderupdates: any = [];
	openOrderData: any = [];
	allopenOrderData: any = [];
	config: any = [];
	pickinglink = '';
	orderdetails: any = [];
	locations: any = [];

	itemsearchresults: any = [];
	itemsearch = false;
	user: any = false;
	ref_location: any = false;

	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	constructor(public creditService: CreditService, public globalSearchService: GlobalSearchService, public router: Router, public customerService: CustomerService, private location: Location, private globalsService: GlobalsService, private modalService: NgbModal) {
		this.config = this.globalsService.getApiConfig();

		this.pickinglink = this.config.apiServer.baseUrl + this.config.apiServer.creditPickingLink;
	}

	ngOnInit(): void {
		this.globalSearchService.locations.subscribe(async (results: any) => {
			this.locations = results;
			if (results) {
				this.defaultlocation.setValue(results[0].loccode)
			}
		});

		this.creditService.getOrderStatuses().subscribe(r => {
			this.order_statuses = r;

			this.globalSearchService.user.subscribe(results => {
				//only run if user is definied
				if (this.user) {
					if (results.user.defaultlocation.loccode != this.ref_location) {
						this.defaultlocation.setValue(this.user.user.defaultlocation.loccode);
						this.loadData();
						this.ref_location = false;
					}
				}

				this.user = results
				if (!this.ref_location) {
					this.ref_location = this.user.user.defaultlocation.loccode;
				}
			});
		})
	}

	openModal(content) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
	}

	search() {
		this.itemsearch = false;
		const value = this.searchInput.value;
		if (value && value != '') {
			this.openorders = this.globalSearchService.filterItem(this.openOrderData, value, 'orderno,transno,ponumber,debtorno,deliverto,deliverydate,stockid,description');

			this.openorders = new MatTableDataSource(this.openorders);
			this.openorders.sort = this.sort;
			this.openorders.paginator = this.paginator;

		} else {
			this.openOrderData = this.allopenOrderData
			this.openorders = new MatTableDataSource(this.allopenOrderData);
			this.openorders.sort = this.sort;
			this.openorders.paginator = this.paginator;
		}
	}

	getTotal(totals: any) {
		if(totals) {
			const total= totals.filter( r => {
				if(r.code) {
					return r.code == 'total'
				}
			})[0];
			if(total) {
				return total.text
			}
		}

		return 0.00;

	}

	searchItems() {
		const value = this.searchInputItems.value;

		if (value && value != '') {
			this.itemsearch = true;
			const searchdata = [];
			this.openOrderData.forEach((item: any) => {
				searchdata.push(item.details)
			});

			const searchresults = this.globalSearchService.filterItem(searchdata, value, 'orderno,narrative,stkcode,description,ordervalue');
			const mapped = searchresults.map(i => i.orderno);

			this.itemsearchresults = searchresults;

			this.openorders = this.openOrderData.filter(o => {
				return mapped.includes(o.orderno);
			});

			this.openorders = new MatTableDataSource(this.openorders);
			this.openorders.sort = this.sort;
			this.openorders.paginator = this.paginator;

		} else {
			this.openOrderData = this.allopenOrderData
			this.openorders = new MatTableDataSource(this.allopenOrderData);
			this.openorders.sort = this.sort;
			this.openorders.paginator = this.paginator;
		}

	}

	onInput(event: any) {
		if (this.openOrderData) {
			this.openorders = this.globalSearchService.filterItem(this.openOrderData, event, 'orderno,transno,ponumber,debtorno,deliverto,deliverydate');
		}
	}

	viewOrder(orderno: any, content: any) {

		this.creditService.getOrder(orderno).subscribe((order: any) => {
			this.orderdetails = order
			this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
		});
	}

	loadOrder(orderno: any) {

		//this.router.navigate(['/receivables/credit-invoice/' + orderno]);
		//this.router.navigate(['/receivables/credit-invoice/' + orderno]);
		this.creditService.loadRma(orderno).subscribe((r:any) => {
			this.router.navigate(['/receivables/credit-note/' + r.header.debtorno +'/'+r.header.branchcode]);
		})
	}

	loadData() {
		let search = '';

		if(this.customerdata.debtorno){
			if (this.customerdata.debtorno) {
				search = this.customerdata.debtorno;
			}

			if (this.customerdata && search == '') {
				search = this.customerdata.customer.debtorno;
			}

			this.creditService.getCustomerOpenOrders(search).subscribe(async (results: any) => {
				this.openOrderData = results;
				this.allopenOrderData = results;
				if (results) {
					this.credit_data.emit(results);
					this.openorders = new MatTableDataSource(results);
					this.openorders.sort = this.sort;
					this.openorders.paginator = this.paginator;
				}
			});
		}

		if(this.item){
			search = this.item;
			this.creditService.getCustomerOpenOrdersByItem(search).subscribe(async (results: any) => {

				this.openOrderData = results;
				this.allopenOrderData = results;
				if (results) {
					this.credit_data.emit(results);
					this.openorders = new MatTableDataSource(results);
					this.openorders.sort = this.sort;
					this.openorders.paginator = this.paginator;
				}
			});
		}

		if(!this.item && !this.customerdata ){
			this.creditService.getCustomerOpenOrders([]).subscribe(async (results: any) => {

				this.openOrderData = results;
				this.allopenOrderData = results;
				if (results) {
					this.credit_data.emit(results);
					this.openorders = new MatTableDataSource(results);
					this.openorders.sort = this.sort;
					this.openorders.paginator = this.paginator;
				}
			});
		}

	}

	cancelOrder(orderno: any) {
		this.creditService.cancelOrder(orderno).subscribe(async (results: any) => {
			this.cancel.emit(true);
			this.cart_updated.emit(results);
			this.credit_data.emit(results);
			this.loadData()
		})
	}

	setDelivered(orderno: any) {
		this.creditService.setDelivered({ orderno: orderno }).subscribe(async (results: any) => {
			this.loadData()
		})
	}

	updatePick(orderno: any) {
		//needs callback to have customer only
		this.creditService.updateOrderToPicked({ orderno: orderno }).subscribe(async (results: any) => {
			this.loadData()
		})
	}

	back(): void {
		this.location.back()
	}

	ngOnChanges(): void{
		this.loadData();
	}

	toggleEdit(tran: any) {
		if (!tran.edit) {
			tran.edit = true;
			this.order_status_edit.setValue(tran.orderstatusid);
		} else {
			tran.edit = false;
		}
	}

	saveStatusUpdate(tran: any) {

		const data = {
			trans: tran,
			type: this.order_status_edit.value,
			user: this.user,
		}

		this.creditService.updateOrderStatus(data).subscribe((r: any) => {
			if (r.success) {
				tran.edit = false;
				const status = this.order_statuses.filter(s => {
					return s.order_status_id == data.type;
				})[0];
				tran.statusname = status.name;
				tran.details.orderstatusid = data.type;
				this.order_status_edit.reset();

				this.loadData();

			}
		})
	}

	announceSortChange(sortState: Sort) {

	}

}
