<div class="main-content">
	<div class="container-fluid">
		<div class="col-md-12">
			<div class="card pr-0 pl-0">
				<div class="card-header card-header-{{color}}-3">
					<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i> Purchase Receiving</h4>
					<p class="card-category"></p>
					<div class="messages row">
					</div>
				</div>
				<div class="card-body">
					<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent" [selectedIndex]="pageindex">
						<mat-tab label="Item Scan">
							<div class="row m-0 p-0 no-overflow">
								<div class="col-3 col-md-4 ml-0 mr-0">
									<mat-form-field appearance="outline" class="ml-2 mr-2">
										<mat-label>Vendor</mat-label>
										<mat-select tabindex="-1" [formControl]="vendor" autocomplete="off" (selectionChange)="filterItems()">
											<mat-option *ngFor="let v of vendors" [value]="v.supplierid">
												<ng-container *ngIf="v.bankref !=''">
													{{ v.bankref }}
												</ng-container>
												<ng-container *ngIf="v.bankref ==''">
													{{v.supplierid}} - {{v.suppname}}
												</ng-container>
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-8 col-md-8 ml-0 mr-0">
									<mat-form-field appearance="outline" class="ml-2 mr-2">
										<mat-label>Scan An Item</mat-label>
										<input #itemCodeRef tabindex="1" matInput autocomplete="off" class="text-right" placeholder="Scan an Item" [formControl]="itemCode" (input)="itemScan()">
									</mat-form-field>
								</div>
							</div>
						</mat-tab>
						<mat-tab label="P.O. Receiving">
							<ng-container>
								<form [formGroup]="itemScanForm" class="no-overflow">
									<div class="row">
										<div class="col-12 col-md-4 ml-0 mr-0">
											<mat-form-field appearance="outline" class="ml-2 mr-2">
												<mat-label>Journal</mat-label>
												<mat-select formControlName="journal">
													<mat-option *ngFor="let j of journals" [value]="j">
														#{{j.journal_no }}: {{j.journal_date | date }} {{j.desc1 }}</mat-option>
												</mat-select>
												<span matPrefix (click)="closeOpenNewJournal()">
													<small><i class="material-icons">remove</i></small>
												</span>
											</mat-form-field>
										</div>
										<div class="col-12 col-md-4 ml-0 mr-0">
											<mat-form-field appearance="outline" class="ml-2 mr-2">
												<mat-label>Select A Purchase Order</mat-label>
												<input type="text" placeholder="Select Purchase Order" aria-label="Select Purchase Order" matInput [formControl]="poSearch" [matAutocomplete]="pos">
												<mat-autocomplete #pos="matAutocomplete">
													<mat-option (onSelectionChange)="getOrderDetails(order.orderno)" *ngFor="let order of purchaseorders" [value]="order.orderno">
														{{ order.orderno }} {{ order.statusname }} {{ order.suppname }}
													</mat-option>
												</mat-autocomplete>
												<button matSuffix mat-icon-button aria-label="Clear" (click)="loadPos()">
													<mat-icon>close</mat-icon>
												</button>
											</mat-form-field>
										</div>
										<div class="col-12 col-md-4 ml-0 mr-0">
											<mat-form-field appearance="outline" class="ml-2 mr-2">
												<mat-label>Scan An Item</mat-label>
												<input type="text" placeholder="Scan an Item" aria-label="Scan an Item" matInput formControlName="keywords" (input)="itemSearch()">
											</mat-form-field>
										</div>
									</div>
								</form>
							</ng-container>

							<div class="row mt-2 mb-2" *ngIf="!mustpick.length">
								<div class="col-12 text-center">
									<span class="alert alert-succes">No Items To Receive, Select a Purchase Order or Scan an Item</span>
								</div>
							</div>
							<div class="row border-bottom mt-2" *ngIf="mustpick.length">
								<div class="col-6 text-left mr-0 pr-0"><b>ITEM/PO</b></div>
								<div class="col-6 text-right ml-0 text-nowrap"><b>DESC/PICK/BIN</b></div>
							</div>
							<ng-container *ngFor="let item of mustpick">
								<div class="row border-bottom pickitem mt-2">
									<div class="col-12 col-md-6 mt-2 mr-auto ml-0">
										<span class="spec-label-large">ITEM</span>
										&nbsp;&nbsp;<b>{{ item.stockid }}</b>
										<br />
										<span class="spec-label-large">DESC:</span>&nbsp;&nbsp;
										<b>{{ item.description }}</b> : ({{ item.totalqoh }})
										<br />
										<ng-container *ngIf="item.commited">
											<span class="spec-label-large">COMMITED:</span>&nbsp;&nbsp;
											<b>{{ item.commited.sales_orderno }} x {{ item.commited.quantiy_order }}</b>
										</ng-container>
									</div>
									<div class="col-12 col-md-6 text-right ml-auto mr-0 ">
										<div class="row m-0 p-0 ">
											<div class="col-md-1 mt-3 mr-2">
												<button mat-icon-button aria-label="split bin" (click)="addBin(item)">
													<mat-icon>call_split</mat-icon>
												</button>
											</div>
											<div class="col-md-10">
												<ng-container *ngIf="item.bins.length > 1">
													TOTAL: <b>{{ item.quantityrecd }}</b> / <b>{{ item.quantityord }}</b>
												</ng-container>
												<div class="row m-0 p-0" *ngFor="let b of item.bins">
													<div class="col-md-5 mt-2">
														<mat-form-field class="full-width" appearance="outline">
															<mat-label>Bin</mat-label>
															<input matInput type="text" placeholder="Bin" [(value)]="b.bin" (input)="setBin($event.target.value,b)">
															<mat-error></mat-error>
															<span matSuffix *ngIf="item.bins.length > 1">
																<button mat-icon-button aria-label="remove bin" (click)="removeBin(b, item)" *ngIf="item.bins.length > 1">
																	<mat-icon>remove</mat-icon>
																</button>
															</span>
														</mat-form-field>
													</div>

													<div class="col-md-7 mt-auto mb-auto">
														<span class="" [ngClass]="item.quantityrecd < item.quantityord ? 'text-danger': 'text-success'">
															<mat-form-field class="full-width" appearance="outline">
																<span matPrefix>
																	<button class=" btn btn-just-icon btn-white btn-fab btn-round btn-sm" (click)="removePick(b, item)">
																		<small>
																			<i class="material-icons">remove</i>
																		</small>
																	</button>
																</span>
																<input matInput type="text" class="text-right" type="text" [(value)]="b.qty" [ngClass]="item.quantityrecd < item.quantityord ? 'text-danger': 'text-success'" (blur)="setQty($event, item, b)">
																<span matSuffix> / <b>{{ item.quantityord }}</b></span>
																<span matSuffix>
																	<button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-2" (click)="addPick(b, item)">
																		<small><i class="material-icons">add</i></small>
																	</button>
																</span>
																<!--<span matSuffix>
																<mat-checkbox [checked]='item.quantityrecd == item.quantityord' (change)="setCheckValue($event, item)" class="mat-checkbox mat-accent " data-item="{{ item.podetailitem }}"></mat-checkbox>
															</span>
															-->
																<mat-error></mat-error>
															</mat-form-field>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</ng-container>
						</mat-tab>
					</mat-tab-group>
				</div>
			</div>
		</div>
	</div>
</div>
