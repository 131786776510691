import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from '../services/globalsearchservice.service';
@Injectable({
	providedIn: 'root'
})

export class CouponService {
	key = 'UET6DFMPAXW7BKCB';
	config: any;
	user: any = [];
	package: any = '';

	constructor(private http: HttpClient, private _configSvc: RuntimeConfigLoaderService, public globalSearchService: GlobalSearchService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})

		this.globalSearchService.user.subscribe((result) => {
			this.user = result;
		});

	}

	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			'responseType': 'text',
			'Access-Control-Allow-Origin': '*'
		})
	};

	public httpOptions2 = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};



	public getOptions = {
		headers: new HttpHeaders({

		})
	};

	public addCoupon(coupon: Object = {}) {
		const method = 'marketing/coupon/add' + this.getApiKey();


		return this.http.post(this.setEndPoint(method), (JSON.stringify(coupon)))
			.pipe(catchError(this.handleError(method, coupon)))
	}

	public editCoupon(coupon: Object = {}) {
		const method = 'marketing/coupon/edit' + this.getApiKey();

		return this.http.post(this.setEndPoint(method), (JSON.stringify(coupon)))
			.pipe(catchError(this.handleError(method, coupon)))
	}

	public deleteCoupon(coupon: Object = {}) {
		const method = 'marketing/coupon/delete' + this.getApiKey();

		return this.http.post(this.setEndPoint(method), JSON.stringify(coupon))
			.pipe(catchError(this.handleError(method, coupon)));
	}

	public getAllCoupons() {
		const method = 'marketing/coupon/getAllCoupons' + this.getApiKey();

		return this.http.get(this.setEndPoint(method))
	}


	public applyCoupon(data:any){
		const method = 'marketing/coupon/applycoupon';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions2).pipe(
			catchError(this.handleError('coupon', data))
		);
	}

	public depleteCoupon(data:any){
		const method = 'marketing/coupon/depletecoupon';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions2).pipe(
			catchError(this.handleError('coupon', data))
		);
	}

	public checkCouponAvailability(data:any){
		const method = 'marketing/coupon/checkcouponavailability';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions2).pipe(
			catchError(this.handleError('coupon', data))
		);
	}

	public getCouponData(data:any){
		const method = 'marketing/coupon/getcoupondata';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions2).pipe(
			catchError(this.handleError('coupon', data))
		);
	}

	public removeCouponHistory(data:any){
		const method = 'marketing/coupon/removeordercouponhistory';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions2).pipe(
			catchError(this.handleError('coupon', data))
		);
	}

	public revalidateCoupon(data: any){
		const method = 'marketing/coupon/validatecoupon';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions2).pipe(
			catchError(this.handleError('coupon', data))
		);
	}

	public recalculateCoupons(data: any){
		const method = 'marketing/coupon/recalculatecoupon';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions2).pipe(
			catchError(this.handleError('coupon', data))
		);
	}

	private setEndPoint(method: any) {
		return this.getApi() + method + this.getApiKey()
	}

	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getApiKey() {
		return '&key=' + this.config.apiCredentials.clientId + '&userid=' + this.user.user.userid + this.getPackage();
	}

	private getPackage() {
		const p = this.globalSearchService.getSessionItem('package');
		if(p) {
			return '&package='+p;
		} else {
			return '';
		}
	}

	public getKey() {
		return '&apikey=' + this.key;
	}

	private handleError<T>(operation = 'operation', result?: T) {

		return (error: any): Observable<T> => {

			if (error instanceof HttpErrorResponse && error.status === 401) {
				window.location.href = '/#/auth/logout';
			}

			return error;
		};
	}
}
