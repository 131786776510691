import { Component, OnInit, Input, Output , EventEmitter,ViewEncapsulation } from '@angular/core';
import { CreditCardValidators } from 'angular-cc-library';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';

import { PaymentsService } from '../../services/payments.service';

@Component({
	selector: 'app-dispatch-add-card',
	templateUrl: './dispatch-add-card.component.html',
	styleUrls: ['./dispatch-add-card.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DispatchAddCardComponent implements OnInit {
	@Input() customer: any = [];
	@Output() newcarddata = new EventEmitter<any>();
	paymentForm: UntypedFormGroup;
	result: any = [];
	sending = false;
	addingcard = false;
	constructor(private paymentsService: PaymentsService,private fb: UntypedFormBuilder) {}

	ngOnInit(): void {

		this.paymentForm = this.fb.group({
			debtorno: [this.customer.debtorno, Validators.required],
			branchcode: [this.customer.branchcode, Validators.required],
			name: [this.customer.name, Validators.required],
			ccnumber: ['', Validators.required],
			expiry: ['', Validators.required],
			cvv: ['', Validators.required],
		});

	}

	toggleAddingCard() {
		this.addingcard = (!this.addingcard) ? true : false;
	}

	addPaymentMethod() {
		if(this.paymentForm.valid) {
			this.sending = true;
			this.paymentsService.addCreditCard(this.paymentForm.value).subscribe( (result: any) => {
				this.sending = false;
				if(!result.success) {
					alert(result.message)
				} else {
					this.result = result
					this.addingcard = false;
					const cardresult = { card: result, orderno: this.customer.orderno }
					this.newcarddata.emit(cardresult);
				}
			});
		}
	}
}
