<div class="col-md-4">
	&nbsp;&nbsp; <button mat-raised-button (click)="$event.preventDefault();exportXls()" >
		 <i class="fa fa-file-excel-o"></i> XLS
	 </button>
	 &nbsp;&nbsp;
	 <button mat-raised-button (click)="$event.preventDefault();exportPdf()">
		 <i class="fa fa-file-pdf-o"></i> PDF
	 </button>
</div>
<div class="table" #print_table id="print_table">
<table mat-table  [dataSource]="dataset" matSort (matSortChange)="announceSortChange($event)">
	<ng-container matColumnDef="actions">
		<th mat-header-cell *matHeaderCellDef mat-sort-header></th>
		<td mat-cell *matCellDef="let tran">

		</td>
	</ng-container>
	<ng-container matColumnDef="SUPPLIER">
		<th mat-header-cell *matHeaderCellDef mat-sort-header>SUPPLIER</th>
		<td mat-cell *matCellDef="let tran">
		{{ tran.SUPPLIER }}
		</td>
	</ng-container>
	<ng-container matColumnDef="DATE">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> DATE<th>
		<td mat-cell *matCellDef="let tran" >{{ tran.DATE }}</td>
	</ng-container>
	<ng-container matColumnDef="ORDERNO">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> ORDERNO</th>
		<td mat-cell *matCellDef="let tran" >{{ tran.ORDERNO }}</td>
	</ng-container>

	<ng-container matColumnDef="CUSTOMER">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> CUSTOMER#</th>
		<td mat-cell *matCellDef="let tran" >
			<a [routerLink]="'/customers/view/'+tran.CUSTOMER">
				{{ tran.CUSTOMER }}
			</a>
		</td>
	</ng-container>

	<ng-container matColumnDef="NAME">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> NAME</th>
		<td mat-cell *matCellDef="let tran" >{{ tran.NAME }}</td>
	</ng-container>

	<ng-container matColumnDef="PHONE">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> PHONE#</th>
		<td mat-cell *matCellDef="let tran" >{{ tran.PHONE }}</td>
	</ng-container>

	<ng-container matColumnDef="SALESPERSON">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> SALESPERSON</th>
		<td mat-cell *matCellDef="let tran" >{{ SALESPERSON }}</td>
	</ng-container>
	<ng-container matColumnDef="ITEMCODE">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> ITEMCODE<th>
		<td mat-cell *matCellDef="let tran" >
			<a [routerLink]="'/inventory/view/'+tran.ITEMCODE">
			{{ tran.ITEMCODE }}
			</a>
		</td>
	</ng-container>
	<ng-container matColumnDef="DESCRIPTION">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> DESCRIPTION<th>
		<td mat-cell *matCellDef="let tran" >{{ tran.DESCRIPTION }}</td>
	</ng-container>
	<ng-container matColumnDef="OUTSTANDING">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> OUTSTANDING<th>
		<td mat-cell *matCellDef="let tran" >{{ tran.OUTSTANDING }}</td>
	</ng-container>
	<ng-container matColumnDef="PRICE">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> PRICE<th>
		<td mat-cell *matCellDef="let tran" >{{ tran.PRICE | currency }}</td>
	</ng-container>
	<ng-container matColumnDef="DISCOUNT">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> DISCOUNT<th>
		<td mat-cell *matCellDef="let tran" >{{ tran.DISCOUNT }}</td>
	</ng-container>
	<ng-container matColumnDef="NETTOTAL">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> NETTOTAL<th>
		<td mat-cell *matCellDef="let tran" >{{ tran.NETTOTAL | currency }}</td>
	</ng-container>
	<ng-container matColumnDef="QOH">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> QOH<th>
		<td mat-cell *matCellDef="let tran" >{{ tran.QOH }}</td>
	</ng-container>
	  <tr mat-header-row *matHeaderRowDef="headercolumns"></tr>
	  <tr mat-row *matRowDef="let row; columns: headercolumns"></tr>
</table>
<mat-paginator [pageSizeOptions]="[25, 50, 100]"
				 showFirstLastButtons
				 aria-label="select page">
</mat-paginator>

</div>
