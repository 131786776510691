<ng-template #purchaseOrderDetails let-modal>

	<div class="modal-header">
		<h4 class="modal-title" id="modal-title" *ngIf="podetails">
			<a [routerLink]="'/vendors/'+podetails.poheader.supplierno">{{ podetails.poheader.supplierno }}</a> Purchase Order
		</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<mat-tab-group #tabPanel mat-align-tabs="start" color="accent" class="no-overflow">
			<mat-tab label="Purchase Order">
				<div class="mb-3" *ngIf="podetails">
					<h4 class="mt-3">PO #{{ podetails.poheader.orderno }}</h4>
					<div class="table">
						<table class="table table-sm">
							<tr>
								<td>
									{{podetails.poheader.contact}}
									{{podetails.poheader.address}}<br>
									{{podetails.poheader.suppdeladdress1}}<br>
									{{podetails.poheader.suppdeladdress2}}<br>
									{{podetails.poheader.suppdeladdress3}}, {{podetails.poheader.suppdeladdress4}} {{podetails.poheader.suppdeladdress5}}
								</td>
								<td>
									{{podetails.poheader.deladd1}}<br>
									<span *ngIf="podetails.poheader.deladd2 != ''">{{podetails.poheader.deladd2}}<br></span>
									{{podetails.poheader.deladd3}}, {{podetails.poheader.deladd4}} {{podetails.poheader.deladd5}}
								</td>
							</tr>
						</table>
						<div class="card">
							<div class="card-body" [innerHTML]="podetails.poheader.stat_comment"></div>
						</div>
						<table class="table table-sm ">
							<tr>
								<th>ITEM</th>
								<th>DESC</th>
								<th class="text-center">QTY</th>
								<th class="text-center">RCVD</th>
								<th class="text-center">PEND</th>
								<th class="text-right">PRICE</th>
								<th class="text-right">TOTAL</th>
							</tr>
							<tr *ngFor="let item of podetails.polines">
								<td><a [routerLink]="'/inventory/view/'+item.itemcode" (click)="modal.dismiss('route change')">{{item.itemcode}}</a></td>
								<td>{{item.itemdescription}}</td>
								<td class="text-center">{{item.quantityord}}</td>
								<td class="text-center">{{item.quantityrecd}}</td>
								<td class="text-center">{{item.quantityord - item.quantityrecd}}</td>
								<td class="text-right">{{item.unitprice | currency}}</td>
								<td class="text-right">{{item.unitprice * item.quantityord | currency}}</td>
							</tr>
						</table>
					</div>
				</div>
			</mat-tab>
			<mat-tab label="Receiving Details">
				<ng-container *ngIf="podetails.receiving.length">
					<table class="table table-fixed table-sm">
						<tr>
							<th>GRN</th>
							<th>Order#</th>
							<th>Item#</th>
							<th>Description</th>
							<th>Received</th>
							<th>Invoiced</th>
							<th>Date</th>
						</tr>
						<tr *ngFor="let r of podetails.receiving">
							<td>{{ r.grnno }}</td>
							<td>{{ r.poorderno }}</td>
							<td>{{ r.itemcode }}</td>
							<td>{{ r.itemdescription }}</td>
							<td>{{ r.qtyrecd }}</td>
							<td>{{ r.quantityinv }}</td>
							<td>{{ r.update_date }}</td>
						</tr>
					</table>
				</ng-container>
				<ng-container *ngIf="!podetails.receiving.length">
					No Receiving Details
				</ng-container>
			</mat-tab>
			<mat-tab label="Receiving Movements">
				<ng-container *ngIf="podetails.movements.length">
					<table class="table table-fixed table-sm">
						<tr>
							<th>Item#</th>
							<th>Location</th>
							<th>User</th>
							<th>Quantity</th>
							<th>QOH</th>
							<th>Date</th>
						</tr>
						<tr *ngFor="let r of podetails.movements">
							<td>{{ r.stockid }}</td>
							<td>{{ r.loccode }}</td>
							<td>{{ r.userid }}</td>
							<td>{{ r.qty }}</td>
							<td>{{ r.newqoh }}</td>
							<td>{{ r.trandate }}</td>
						</tr>
					</table>
				</ng-container>
				<ng-container *ngIf="!podetails.movements.length">
					No Movements
				</ng-container>
			</mat-tab>
		</mat-tab-group>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #orderDetails let-modal>
	<div class="modal-header">
		<div class="modal-title row m-0 p-0" id="modal-title">
			<h4>Order# {{orderdetails.header.orderno}}</h4>
		</div>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="orderdetails">
			<div class="row border-bottom">
				<div class="col-md-3 text-center">
					<h5>Ship Via: {{ orderdetails.header.shippername }}</h5>
				</div>
				<div class="col-md-3 text-center">
					<h5>Date Wanted: {{ orderdetails.header.datewanted }}</h5>
				</div>
				<div class="col-md-3 text-center">
					<h5>Entered By: {{ orderdetails.header.enteredby }}</h5>
				</div>
				<div class="col-md-3 text-center">
					<h5>Created: {{ orderdetails.header.date_created }}</h5>
				</div>
			</div>
			<div class="row mt-2 mb-2 border-bottom">
				<div class="col-md-3 ">
					<h5 class="mb-0">Ship To:</h5>
					<span [innerHTML]="orderdetails.header.shipto"></span>
				</div>
				<div class="col-md-3">
					<h5 class="mb-0">Phone:</h5>
					{{orderdetails.header.contactphone}}
					<ng-container *ngIf="orderdetails.header.contactemail && orderdetails.header.contactemail != ''">
						<h5 class="mb-0">Email:</h5>
						{{orderdetails.header.contactemail}}
					</ng-container>
				</div>
				<div class="col-md-3">
					<h5>Notes:</h5>
					{{orderdetails.header.comments}}
				</div>
				<div class="col-md-3">
					<h5>History: </h5>
					<ng-container *ngIf="orderdetails.dispatch_details.dispatch_id">
						<ul>
							<li *ngIf="orderdetails.dispatch_details.truck_name">ShipVia: {{ orderdetails.dispatch_details.truck_name }}</li>
							<li *ngIf="orderdetails.dispatch_details.datecreated !='0000-00-00 00:00:00'">Dispatched:
								{{ orderdetails.dispatch_details.datecreated | date:'shortDate' }}
								{{ orderdetails.dispatch_details.datecreated | date:'shortTime' }}

							</li>
							<li *ngIf="orderdetails.dispatch_details.delivery_date !='0000-00-00 00:00:00'">Delivered:
								{{ orderdetails.dispatch_details.delivery_date | date:'shortDate' }}
								{{ orderdetails.dispatch_details.delivery_date | date:'shortTime' }}
							</li>
						</ul>
					</ng-container>
					<ng-container *ngIf="!orderdetails.dispatch_details.dispatch_id">
						N/A
					</ng-container>
				</div>
			</div>
			<div class="table">
				<table class="table table-sm">
					<tr>
						<th>ITEM</th>
						<th>DESC</th>
						<th class="text-center">ORDERED</th>
						<th class="text-center">QOH</th>
						<th class="text-right">PRICE</th>
						<th class="text-right">DISCOUNT</th>
						<th class="text-right">TOTAL</th>
					</tr>
					<tr *ngFor="let item of orderdetails.details">
						<td><a [routerLink]="'/inventory/view/'+item.stkcode" (click)="modal.dismiss('route change')">
								{{item.stkcode}}</a>
						</td>
						<td>{{item.description}}</td>
						<td class="text-center">{{item.ordered - item.qtyinvoiced}}</td>
						<td class="text-center">{{item.qoh}}</td>
						<td class="text-right">{{item.unitprice | currency}}</td>
						<td class="text-right">{{item.discountpercent | percent }}</td>
						<td class="text-right">{{item.discountprice * item.ordered | currency}}</td>
					</tr>
					<tr *ngFor="let t of orderdetails.saved_totals">
						<td></td>
						<td></td>
						<td class="text-center"></td>
						<td class="text-center"></td>
						<td class="text-center"></td>
						<td class="text-right">{{ t.title }}:</td>
						<td class="text-right">{{t.text | currency}}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #orderDetails let-modal>
	<div class="modal-header">
		<div class="modal-title row m-0 p-0" id="modal-title">
			<h4>Order# {{orderdetails.header.orderno}}</h4>
		</div>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="orderdetails">
			<div class="row border-bottom">
				<div class="col-md-3 text-center">
					<h5>Ship Via: {{ orderdetails.header.shippername }}</h5>
				</div>
				<div class="col-md-3 text-center">
					<h5>Date Wanted: {{ orderdetails.header.datewanted }}</h5>
				</div>
				<div class="col-md-3 text-center">
					<h5>Entered By: {{ orderdetails.header.enteredby }}</h5>
				</div>
				<div class="col-md-3 text-center">
					<h5>Created: {{ orderdetails.header.date_created }}</h5>
				</div>
			</div>
			<div class="row mt-2 mb-2 border-bottom">
				<div class="col-md-3 ">
					<h5 class="mb-0">Ship To:</h5>
					<span [innerHTML]="orderdetails.header.shipto"></span>
				</div>
				<div class="col-md-3">
					<h5 class="mb-0">Phone:</h5>
					{{orderdetails.header.contactphone}}
					<ng-container *ngIf="orderdetails.header.contactemail && orderdetails.header.contactemail != ''">
						<h5 class="mb-0">Email:</h5>
						{{orderdetails.header.contactemail}}
					</ng-container>
				</div>
				<div class="col-md-3">
					<h5>Notes:</h5>
					{{ noNewLines(orderdetails.header.comments) }}
				</div>
				<div class="col-md-3">
					<h5>History: </h5>
					<ng-container *ngIf="orderdetails.dispatch_details.dispatch_id">
						<ul>
							<li *ngIf="orderdetails.dispatch_details.truck_name">ShipVia: {{ orderdetails.dispatch_details.truck_name }}</li>
							<li *ngIf="orderdetails.dispatch_details.datecreated !='0000-00-00 00:00:00'">Dispatched:
								{{ orderdetails.dispatch_details.datecreated | date:'shortDate' }}
								{{ orderdetails.dispatch_details.datecreated | date:'shortTime' }}

							</li>
							<li *ngIf="orderdetails.dispatch_details.delivery_date !='0000-00-00 00:00:00'">Delivered:
								{{ orderdetails.dispatch_details.delivery_date | date:'shortDate' }}
								{{ orderdetails.dispatch_details.delivery_date | date:'shortTime' }}
							</li>
						</ul>
					</ng-container>
					<ng-container *ngIf="!orderdetails.dispatch_details.dispatch_id">
						N/A
					</ng-container>
				</div>
			</div>
			<div class="table">
				<table class="table table-sm">
					<tr>
						<th>ITEM</th>
						<th>DESC</th>
						<th class="text-center">ORDERED</th>
						<th class="text-center">QOH</th>
						<th class="text-right">PRICE</th>
						<th class="text-right">DISCOUNT</th>
						<th class="text-right">TOTAL</th>
					</tr>
					<tr *ngFor="let item of orderdetails.details">
						<td><a [routerLink]="'/inventory/view/'+item.stkcode" (click)="modal.dismiss('route change')">
							{{item.stkcode}}</a>
							</td>
						<td>{{item.description}}</td>
						<td class="text-center">{{item.ordered - item.qtyinvoiced}}</td>
						<td class="text-center">{{item.qoh}}</td>
						<td class="text-right">{{item.unitprice | currency}}</td>
						<td class="text-right">{{item.discountpercent | percent }}</td>
						<td class="text-right">{{item.discountprice * item.ordered | currency}}</td>
					</tr>
					<tr *ngFor="let t of orderdetails.saved_totals">
						<td></td>
						<td></td>
						<td class="text-center"></td>
						<td class="text-center"></td>
						<td class="text-center"></td>
						<td class="text-right">{{ t.title }}:</td>
						<td class="text-right">{{t.text | currency}}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>



<div class="main-content">
	<div class="row m-0">
		<div class="col-md-6">
		</div>
		<div class="col-md-6">
			<div class="row m-0 p-0 legend">

				<div class="ml-auto col-auto text-center">
					<div class="ml-auto mr-auto legend-block claimed "> </div>
					Claimed
				</div>
				<div class="col-auto text-center">
					<div class="ml-auto mr-auto legend-block partialpick"> </div>
					Partial Pick
				</div>
				<div class="col-auto text-center">
					<div class="ml-auto mr-auto legend-block picked"> </div>
					Picked
				</div>
			</div>
		</div>
	</div>

	<div class="container-fluid tp-box" [@flipState]="flip">
		<!-- route building all trucks -->
		<div class="row tp-box__side tp-box__front" id="frontside" [ngClass]="flip != 'active' ? '' : 'd-none'">
			<ng-container>
				<div class="col-md-12">
					<div class="card">
						<div class="card-header card-header-{{color}}-5">
							<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Order Status</h4>
							<p class="card-category text-right" *ngIf="loading">
								<small>
								<svg class="spinner" width="5px" height="5px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
								</small>
							</p>
						</div>
						<div class="card-body" *ngIf="pickable && flip == 'inactive'">
								<div class="row">
									<!-- <div class="col-md-4">
										<mat-form-field appearance="fill">
											<mat-label>Select a Truck</mat-label>
											<mat-select [(ngModel)]="selectedColumn" (selectionChange)="updateTruckDisplay()" (multiple)="true">
												<mat-option [value]="''">ALL</mat-option>
												<mat-option *ngFor="let t of allsortcolumns" [value]="t.id">
													{{t.name }}
												</mat-option>
											</mat-select>
											<span matSuffix *ngIf="selectedColumn != ''" (click)="clearInput()">
												<mat-icon>clear</mat-icon>
											</span>
										</mat-form-field>
									</div> -->
									<div class="col-md-6">
										<mat-form-field appearance="fill">
											<mat-label>Select a Route</mat-label>
											<mat-select [formControl]="selected_route" (selectionChange)="filterOrders()">
												<mat-option [value]="''">ALL</mat-option>
												<mat-option *ngFor="let k of routes" [value]="k.route_Id">
													{{ k.route_Id }}: {{ k.route_description }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-6">
										<mat-form-field appearance="fill">
											<mat-label>Search Orders</mat-label>
											<input matInput class="text-right " #filterSearch (keyup)="onInput($event)">
										</mat-form-field>
									</div>
									<mat-divider></mat-divider>
									<div [ngClass]="config.env.package =='beauty' ? 'col-md-4' : 'col-md-6'">
										<b>ORDERS ({{ filteredItems.length }})</b>
										&nbsp;&nbsp;&nbsp;

										<span class=" ml-1 mr-1 pointer text-info text-justify mt-3 text-right" (click)="filterOrdersAge('one')">
											<b>[{{ getAgedOrders(0, 1 ).length }}]</b> < 1 hr
										</span>
										<span class=" ml-1 mr-1 pointer text-info text-justify mt-3 text-right" (click)="filterOrdersAge('two')">
											<b>[{{ getAgedOrders( 1, 4 ).length }}]</b> < 4 hr
										</span>
										<span class=" ml-1 mr-1 pointer text-info text-justify mt-3 text-right" (click)="filterOrdersAge('three')">
											<b>[{{ getAgedOrders( 4, -1 ).length }}]</b> > 4 hr
										</span>

										<span class=" ml-1 mr-1 pointer text-info text-justify mt-3 text-right" (click)="filterOrdersAge('all')">
											<b>[{{ this.filteredItems.length }}]</b> All
										</span>

										<mat-form-field appearance="fill">
											<mat-label>Open</mat-label>
											<mat-select [formControl]="pickstatus" (selectionChange)="filterOrders()" multiple>
												<mat-option *ngFor="let k of pickedFilter" [value]="k.value">
													{{ k.label }}
												</mat-option>
											</mat-select>
										</mat-form-field>
										<div class="item-list-vertical scrolling-left " *ngIf="filteredItems">
											<ng-container>
												<li class="dd-item dd3-item item-box" *ngFor="let item of filteredItems" >

													<!--<div class="card item-box mt-0 mb-1 boxhide" > -->

													<div class="dd-handle dd3-handle" (mousedown)="$event.stopPropagation()" [ngClass]="{futurepicked: (item.info.daystilldue > 0 && item.pick_complete), notfillable: item.notfillable, invoiced: item.invoiced == '1', notfillable: item.notfillable, claimed: item.claimstatus,partialpick: (item.partial_pick), picked: item.pick_complete, notdue: item.info.daystilldue > 0}" (click)="viewOrder(item.order)">&nbsp; </div>


													<mat-card class="dd3-content p-1 border-0">
														<mat-card-title (click)="viewOrder(item.order)">#{{ item.order }} <span [ngClass]="{'text-danger bold': item.info.processcc }">({{ item.info.terms }})</span>
														&nbsp;<b>{{ item.shipvia }}</b>

													</mat-card-title>
														<mat-divider inset></mat-divider>
														<mat-card-content>
															<div class="row">
																<div class="col-md-6" [innerHTML]="item.address">
																</div>
																<div class="col-md-6">
																	<span>CREATED: {{ item.info.enteredby }} {{ item.info.date_created | date: 'MM/dd/yyyy hh:mm a' }}
																		<!--{{ item.info.date_created | dateAgo | uppercase  }}-->
																	 </span><br>
																	<span>WANTED:{{ item.info.deliverydate | date: 'MM/dd/yyyy' }} </span><br>

																	<span *ngIf="item.claimed_user">
																		 CLAIMED: {{ item.claimed_user }} {{ item.claimed_date | date: 'MM/dd/yyyy hh:mm a'  }}


																	</span>

																	<span class="text-left w-100 dispatch-note" *ngIf="item.info.notes != '' && item.info.notes !='0' && item.info.notes !='--c--'"><br>{{ noNewLines(item.info.notes) }}<br></span>
																	<span>ORDER: {{ item.ordered }} PICKED: {{ item.picked }}</span>
																	<ng-container *ngIf="item.polinks">
																	<span *ngFor="let o of item.polinks" >
																		<br>
																		<button (click)="viewPurchaseOrder(o.purchase_orderno)" >{{ o.purchase_orderno}}</button>
																	</span>
																	</ng-container>
																</div>
															</div>
														</mat-card-content>
														<mat-card-content class="text-left pl-1 routecolor">
															<div class="row">
																<div class="col-md-6">
																#{{ item.route }}:{{ item.routename }} ({{ item.totalvolume }})
																</div>
																<div class="col-md-6">

																</div>
															</div>


														</mat-card-content>

														<mat-divider></mat-divider>
														<mat-card-content class="text-left w-100 dispatch-note" *ngIf="item.info.notes != '' && item.info.notes !='0' && item.info.notes !='--c--'">

														</mat-card-content>
													</mat-card>
												</li>
											</ng-container>
										</div>
									</div>
									<div [ngClass]="config.env.package =='beauty' ? 'col-md-4' : 'col-md-6'">
										<b>PICKING({{ filteredItemstwo.length }})</b>
										<mat-form-field appearance="fill">
											<mat-label>Picked</mat-label>
											<mat-select [formControl]="pickstatustwo" (selectionChange)="filterOrders()" multiple>
												<mat-option *ngFor="let k of pickedFilter" [value]="k.value">
													{{ k.label }}
												</mat-option>
											</mat-select>
										</mat-form-field>
										<div   class="item-list-vertical scrolling-left "  *ngIf="filteredItemstwo">
											<ng-container>
												<li class="dd-item dd3-item item-box" *ngFor="let item of filteredItemstwo" >

													<!--<div class="card item-box mt-0 mb-1 boxhide" > -->

													<div class="dd-handle dd3-handle" (mousedown)="$event.stopPropagation()" [ngClass]="{futurepicked: (item.info.daystilldue > 0 && item.pick_complete), notfillable: item.notfillable, invoiced: item.invoiced == '1', notfillable: item.notfillable, claimed: item.claimstatus,partialpick: (item.partial_pick), picked: item.pick_complete, notdue: item.info.daystilldue > 0}" (click)="viewOrder(item.order)">&nbsp; </div>


													<mat-card class="dd3-content p-1 border-0">
														<mat-card-title (click)="viewOrder(item.order)">#{{ item.order }} <span [ngClass]="{'text-danger bold': item.info.processcc }">({{ item.info.terms }})</span>
															&nbsp;<b>{{ item.shipvia }}</b>
														</mat-card-title>
														<mat-divider inset></mat-divider>
														<mat-card-content>
															<div class="row">
																<div class="col-md-6" [innerHTML]="item.address">
																</div>
																<div class="col-md-6">
																	<span>CREATED: {{ item.info.enteredby }} {{ item.info.date_created | date: 'MM/dd/yyyy hh:mm a' }}
																		<!--{{ item.info.date_created | dateAgo | uppercase  }}-->
																	 </span><br>
																	<span>WANTED:{{ item.info.deliverydate | date: 'MM/dd/yyyy' }} </span><br>

																	<span *ngIf="item.claimed_user">
																		 CLAIMED: {{ item.claimed_user }} {{ item.claimed_date | date: 'MM/dd/yyyy hh:mm a'  }}


																	</span>

																	<span class="text-left w-100 dispatch-note" *ngIf="item.info.notes != '' && item.info.notes !='0' && item.info.notes !='--c--'"><br>{{ noNewLines(item.info.notes) }}<br></span>
																	<span>ORDER: {{ item.ordered }} PICKED: {{ item.picked }}</span>
																	<ng-container *ngIf="item.polinks">
																	<span *ngFor="let o of item.polinks" >
																		<br>
																		<button (click)="viewPurchaseOrder(o.purchase_orderno)" >{{ o.purchase_orderno}}</button>
																	</span>
																	</ng-container>
																</div>
															</div>
														</mat-card-content>
														<mat-card-content class="text-left pl-1 routecolor">
															<div class="row">
																<div class="col-md-6">
																#{{ item.route }}:{{ item.routename }} ({{ item.totalvolume }})
																</div>
																<div class="col-md-6">

																</div>
															</div>


														</mat-card-content>

														<mat-divider></mat-divider>
														<mat-card-content class="text-left w-100 dispatch-note" *ngIf="item.info.notes != '' && item.info.notes !='0' && item.info.notes !='--c--'">

														</mat-card-content>
													</mat-card>
												</li>

											</ng-container>
										</div>
									</div>
									<div class="col-md-3" *ngIf="selectedColumn != ''">
										<!-- <div class="dd-handle dd3-handle" (mousedown)="$event.stopPropagation()" [ngClass]="{partialpick: (item.partial_pick), picked: item.pick_complete, notdue: item.info.daystilldue > 0, futurepicked: (item.info.daystilldue > 0 && item.pick_complete), notfillable: item.notfillable, invoiced: item.invoiced == '1', notfillable: item.notfillable}" (click)="viewOrder(item.order)">&nbsp;</div>

										<mat-card class="dd3-content p-1 border-0">
											<mat-card-title>#{{ item.order }} <span [ngClass]="{'text-danger bold': item.info.processcc }">({{ item.info.terms }})</span> </mat-card-title>
											<mat-divider inset></mat-divider>
											<mat-card-content [innerHTML]="item.address">
											</mat-card-content>
											<mat-card-content class="text-left pl-1 routecolor">
												#{{ item.route }}:{{ item.routename }} ({{ item.totalvolume }})
											</mat-card-content>

											<mat-divider></mat-divider>
											<mat-card-content class="text-left w-100 dispatch-note" *ngIf="item.info.notes != '' && item.info.notes !='0' && item.info.notes !='--c--'">
												{{ item.info.notes }}
											</mat-card-content>

											<mat-card-actions align="end" *ngIf="selectedColumn != ''">
												<button mat-flat-button (click)="$event.stopPropagation();moveItem(item);">ADD</button>
											</mat-card-actions>
										</mat-card> -->
									</div>

									<div class="col-md-4" *ngIf="config.env.package =='beauty' || selectedColumn !=''">

										<mat-form-field appearance="fill">
											<mat-label>{{ checkedlabel }}</mat-label>
											<mat-select [formControl]="pickstatusthree" (selectionChange)="filterOrdersThree()" multiple>
												<mat-option *ngFor="let k of pickedFilter" [value]="k.value">
													{{ k.label }}
												</mat-option>
											</mat-select>
										</mat-form-field>
										<div class="item-list-vertical scrolling-left "  *ngIf="filteredItemsthree">
											<ng-container>
												<li class="dd-item dd3-item item-box" *ngFor="let item of filteredItemsthree" >
													<!--<div class="card item-box mt-0 mb-1 boxhide" > -->

													<div class="dd-handle dd3-handle" (mousedown)="$event.stopPropagation()" [ngClass]="{partialpick: (item.partial_pick), picked: item.pick_complete, notdue: item.info.daystilldue > 0, futurepicked: (item.info.daystilldue > 0 && item.pick_complete), notfillable: item.notfillable, invoiced: item.invoiced == '1', notfillable: item.notfillable}" (click)="viewOrder(item.order)">&nbsp;</div>
													<mat-card class="dd3-content p-1 border-0">
														<mat-card-title>#{{ item.order }} <span [ngClass]="{'text-danger bold': item.info.processcc }">({{ item.info.terms }})</span>
														&nbsp;<b>{{ item.shipvia }}</b>
													</mat-card-title>
														<mat-divider inset></mat-divider>
														<mat-card-content [innerHTML]="item.address">
														</mat-card-content>
														<mat-card-content class="text-left pl-1 routecolor">
															#{{ item.route }}:{{ item.routename }} ({{ item.totalvolume }})
														</mat-card-content>

														<mat-divider></mat-divider>
														<mat-card-content class="text-left w-100 dispatch-note" *ngIf="item.info.notes != '' && item.info.notes !='0' && item.info.notes !='--c--'">
															{{ item.info.notes }}
														</mat-card-content>

														<mat-card-actions align="end" *ngIf="selectedColumn != ''">
															<button mat-flat-button (click)="$event.stopPropagation();moveItem(item);">ADD</button>
														</mat-card-actions>
													</mat-card>
												</li>
											</ng-container>
										</div>
									</div>
								</div>

						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>
