import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[appCollapseKeyboard]'
})
export class CollapseKeyboardDirective {

	constructor() { }

  @HostListener('keydown.enter', ['$event']) onKeydownEnterHandler(event: KeyboardEvent) {
	// Listen for the Enter key to collapse the keyboard
		this.collapseKeyboard();
	}

  collapseKeyboard() {
  	// Programmatically blur the focused input field
  	const activeElement = document.activeElement as HTMLElement;
  	if (activeElement) {
	  activeElement.blur();
  	}
  }
}
