import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { OrdersService } from '../../services/orders.service';
import { ReportsService } from '../../services/reports.service';
import { CardLists, Card } from '../../classes/orders';
import { Router } from '@angular/router';
import { Ng2CompleterModule } from 'ng2-completer';
import { Location } from '@angular/common'
import { PrintService } from '../../services/print.service'
import * as FileSaver from 'file-saver';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

@Component({
	selector: 'app-srpt',
	templateUrl: './srpt.component.html',
	styleUrls: ['./srpt.component.scss']
})
export class SrptComponent implements OnInit {
	@ViewChild('orderView') orderStatusViewRef: ElementRef;
	@ViewChild('details') detailsRef: ElementRef;
	@ViewChild('print_tablexlsx') printtablexlsx: ElementRef;
	@ViewChild('print_tablepdf') printtablepdf: ElementRef;
	@ViewChild('salesFilter') salesmanRef: MatSelect;
  	@ViewChild('branchFilter') branchesRef: MatSelect;
  	itemSearchRef = new FormControl('');
  	orderSearchRef = new FormControl('');
  	conBrRef = new UntypedFormControl('C');
  	placedFromRef = new UntypedFormControl('');
  	branchRef = new UntypedFormControl('');
  	salespersonRef = new UntypedFormControl('');
  	filterresults: any = [];
  	filteredItems: any = [];
  	orderData: CardLists;
  	customerData: any = [];
  	openOrderData: any = [];
  	orders: any = [];
  	orderSearch: any = [];
  	orderResults: any = false;
  	ordersFound: any = [];
  	todate = new Date();
  	fromdate = new Date();
  	reportCriteria = {
  		fromdate: '',
  		todate: '',
  		locs: [],
  		salesperson: [],
  		conbr: '',
  		placedfrom: ''
  	};

	@Input() name: string;
	color: any = 'blue';
	config: any = [];
	pickinglink: any = '';
	filename: any = 'Salesman_Sales_by_Branch_';
	invoicelink: any = '';
	displaydetails: any = false;
	orderdetails: any = false;
	fromdateCtrl = new UntypedFormControl('');
	todateCtrl = new UntypedFormControl('');
	sending: any = false;
	branches: any = [];
	salespeople: any = [];
	conbr: any = [{ label: 'Consolidated', value: 'C' }, { label: 'Branch', value: 'B' }];
	placedfrom: any = [{ label: 'Included', value: '' }, { label: 'Excluded', value: '1' }, { label: 'Only', value: '2' }];

	ngOnInit(): void { }
	constructor(private globalSearchService: GlobalSearchService, private printService: PrintService, private globalsService: GlobalsService, public router: Router, private reportsService: ReportsService, private location: Location, private modalService: NgbModal) {

		this.color = this.globalSearchService.getColor();
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});
		this.globalSearchService.mesagesallowed.next(false);
		this.reportsService.getBranches().subscribe((results: any) => {
			this.branches = results;
		});
		this.reportsService.getSalesPeople().subscribe((results: any) => {
			this.salespeople = results;
		});
		const today = new Date();
		const priorDate = new Date(today.getFullYear(), today.getMonth(), 1);
		this.fromdate = priorDate;

		this.fromdateCtrl.setValue(this.fromdate)
		this.todateCtrl.setValue(this.todate)
	}
	public getCRPT() {
		this.orderResults = false;
		this.reportCriteria = {
			fromdate: this.fromdateCtrl.value.toISOString().split('T')[0],
			todate: this.todateCtrl.value.toISOString().split('T')[0],
			locs: this.branchRef.value,
			conbr: this.conBrRef.value,
			placedfrom: this.placedFromRef.value,
			salesperson: this.salespersonRef.value
		};
		this.sending = true;
		this.reportsService.getSRPT(this.reportCriteria).subscribe(r => {
			this.orderResults = r;
			this.sending = false;
		})

	}
	viewCustomer(sp: any, loccode: any) {
		const drilldown = {
			sp: sp,
			loc: loccode,
			fromdate: this.reportCriteria.fromdate,
			todate: this.reportCriteria.todate,
			locs: this.reportCriteria.locs,
			conbr: this.reportCriteria.conbr,
			placedfrom: this.reportCriteria.placedfrom,
			salesperson: this.reportCriteria.salesperson
		};
		this.reportsService.getSRPTDrill(drilldown).subscribe((order: any) => {
			this.orderdetails = order
			this.modalService.open(this.orderStatusViewRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => { }, (reason) => { });
		});
	}
	openDocument(transaction: any, content: any, display: string) {

		switch (transaction.DocType) {
		case 'Credit':
			this.globalsService.getCreditNote(transaction.id, display).subscribe((result: any) => {
				this.displaydetails = result;
				this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => { }, (reason) => { });
			})
			break;
		case 'Invoice':
			this.globalsService.getInvoice(transaction.id, display).subscribe((result: any) => {
				this.displaydetails = result;
				this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => { }, (reason) => { });
			})

			break;
		}
	}
	exportPdf() {
		const now = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.printtablepdf.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename + now.getMonth() + now.getDate() + now.getFullYear(),
			title: `Salesman Sales by Branch ${this.reportCriteria.fromdate} - ${this.reportCriteria.todate} Created @ ${now.toLocaleString()}`,
			subtitle: this.getReportSubtitle()
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	getReportSubtitle() {
		let out = '';

		this.reportCriteria.conbr == 'C' ? out += 'Consolidated Report ' : out += 'Branch Report ';

		if (this.reportCriteria.locs.length != this.branches.length && this.reportCriteria.locs.length > 0) {
			out += 'For Location(s): ';
			this.reportCriteria.locs.length ? (this.reportCriteria.locs).map((loc) => out += (loc + ' ')) : '';
		} else {
			out += 'For All Locations ';
		}

		if (this.reportCriteria.salesperson.length != this.salespeople.length && this.reportCriteria.salesperson.length > 0) {
			out += 'and Salesperson(s): ';
			this.reportCriteria.salesperson.length ? (this.reportCriteria.salesperson).map((person) => out += (person + ' ')) : '';
		} else {
			out += 'and All Salespeople';
		}

		if (this.reportCriteria.placedfrom == ' ') {
			out += ' Including Web Orders'
		}
		if (this.reportCriteria.placedfrom == '1') {
			out += ' Excluding Web Orders'
		}
		if (this.reportCriteria.placedfrom == '2') {
			out += ' Only Web Orders'
		}
		return out;
	}

	selectAllToggle(filter_name: string) {
		let filter;
		switch (filter_name) {
		case 'salesman':
			filter = this.salesmanRef;
			break;
		case 'branch':
			filter = this.branchesRef;
			break;
		default:
			return;
		}
		const selected = filter.options.find(opt => opt.selected);
		if (selected) {
			filter.options.forEach((item: MatOption) => item.deselect());
		} else {
			filter.options.forEach((item: MatOption) => item.select());
		}
	}

	getBranchName(loc) {
		if(loc == 99){return 'ALL'}
		const branch = this.branches.filter((i) => {
			return i.loccode == loc
		})
		return branch[0].locationname;
	}

	exportXls() {
		const now = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.printtablexlsx.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename + now.getMonth() + now.getDate() + now.getFullYear(),
			title: `Salesman Sales by Branch ${this.reportCriteria.fromdate} - ${this.reportCriteria.todate} Created @ ${now.toLocaleString()}`,
			subtitle: this.getReportSubtitle()
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

	back(): void {
		this.location.back()
	}

}
