import { Directive , HostListener } from '@angular/core';

@Directive({
	selector: '[appNoEnterKey]'
})
export class NoEnterKeyDirective {

	@HostListener('keydown', ['$event'])
	onKeyDown(event: any) {
		if (event.keyCode === 13) {
			return false;
		}
	}
}
