<div class="row">
	<div class="col-md-2">
		<a [routerLink]="'/inventory/create'" class="mt-3" mat-stroked-button color="accent">New Item</a>
		&nbsp;
		<a [routerLink]="'/inventory/create/'+stockid" class="mt-3" mat-stroked-button color="accent" *ngIf="stockid">Clone</a>
	</div>
	<div class="col-md-2 mb-0 mt-4">
		<mat-slide-toggle [formControl]="include_disabled" >Include Discontinued</mat-slide-toggle>
	</div>

	<div class="col-md-2">
		<mat-form-field appearance="outline">
			<mat-label>Category</mat-label>
			<mat-select [formControl]="categoryControl" multiple="true">
				<!--<mat-option [value]="''">All</mat-option> -->
				<mat-option *ngFor="let c of categories" [value]="c.categoryid">
					{{ c.categorydescription }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="col-md-2">
		<mat-form-field appearance="outline">
			<mat-label>Product Line</mat-label>
			<mat-select [formControl]="productLineControl" multiple="true">
				<mat-option *ngFor="let p of productlines" [value]="p.line_field">
					{{ p.line_description }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="col-md-4">
		<mat-form-field appearance="outline" class="smaller-font-form notop text-right" color="accent">
			<mat-spinner matPrefix diameter="30" *ngIf="searching"></mat-spinner>
			<span matPrefix *ngIf="allitems.length && !searching">
				<button mat-icon-button class="p-0" (click)="clearForm()">
					<mat-icon class="p-0">clear</mat-icon>
				</button>
			</span>
			<input #itemSearch matInput [formControl]="itemSearchControl" placeholder="Search By PartNo/Description..." autofocus="true" class="w-100 m-0 p-0 notop text-right" (keyup.enter)="searchItems()" >
			<span matSuffix>
				<button mat-icon-button color="white" (click)="searchItems()" >
					<mat-icon class="p-0" >search</mat-icon>
				</button>
			</span>
		</mat-form-field>
	</div>
</div>
<h3 class="text-center" *ngIf="!searching && itemSearchControl.value != '' && allitems && allitems.length == 0 && !typing">NO RESULTS FOR {{ itemSearchControl.value | uppercase }}</h3>
<table mat-table [dataSource]="items" matSort (matSortChange)="announceSortChange($event)" [ngClass]="allitems.length ? '': 'd-none'">
	<ng-container matColumnDef="stockid">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> Item#</th>
		<td mat-cell *matCellDef="let item">
			<button mat-stroked-button (click)="viewItem(item)">{{item.stockid}}</button>
		</td>
	</ng-container>
	<ng-container matColumnDef="supplierno">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> Vendor</th>
		<td mat-cell *matCellDef="let item">
			{{item.supplierno}}
		</td>
	</ng-container>

	<ng-container matColumnDef="lineid">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> Product Line</th>
		<td mat-cell *matCellDef="let item">
			{{item.lineid}}
		</td>
	</ng-container>
	<ng-container matColumnDef="description">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> Description</th>
		<td mat-cell *matCellDef="let item">
			{{item.description}}
		</td>
	</ng-container>
	<ng-container matColumnDef="qoh">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> QOH</th>
		<td mat-cell *matCellDef="let item">
			{{ getTotalQoh(item.qoh) }}
		</td>
	</ng-container>
	<ng-container matColumnDef="cost" >
		<th mat-header-cell *matHeaderCellDef mat-sort-header><span [ngClass]="!user.user.viewcost ? 'd-none': ''">Cost</span></th>
		<td mat-cell *matCellDef="let item">
			<span [ngClass]="!user.user.viewcost ? 'd-none': ''">{{item.cost | currency}}</span>
		</td>
	</ng-container>
	<ng-container matColumnDef="price">
		<th mat-header-cell *matHeaderCellDef mat-sort-header>Price</th>
		<td mat-cell *matCellDef="let item">
			{{ item.price }} <span *ngIf="item.specialflag" class="crossed-out">{{ item.originalPrice }}</span>
		</td>
	</ng-container>


	<tr mat-header-row *matHeaderRowDef="headercolumns;sticky: true;" ></tr>
	<tr mat-row *matRowDef="let row; columns: headercolumns;" (click)="viewItem(row)" [ngClass]="row.specialflag ? 'special': ''"></tr>

</table>
<mat-paginator
  [pageSizeOptions]="page_size"
  [pageSize]="page_size_default"
  showFirstLastButtons
  aria-label="select page"
  [ngClass]="allitems.length ? '' : 'd-none'">
</mat-paginator>
