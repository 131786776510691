<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}">
						<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i> Order Entry
						</h4>
						<p class="card-category text-right">Select a Customer</p>
					</div>
					<div class="card-body">
						<app-customer-lookup (customer_selected)="selectCustomer($event)"></app-customer-lookup>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
