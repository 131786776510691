<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card tp-box " >
					<div class="card-header card-header-orderentry">
						<h4 class="card-title bold"><i class="material-icons" (click)="back()">arrow_left</i> Order Entry
						<span *ngIf="customerdata" class="bold">
						({{ customerdata.customer.debtorno }}-{{ customerdata.customer.branchcode }})
						{{ customerdata.customer.name }}
						</span>
						</h4>
						<p class="card-category">Order Entry <span *ngIf="customerdata">
							{{ customerdata.customer.name }}</span></p>
					</div>
					<div class="card-body tp-box__side tp-box__front" >
						<app-customer-search *ngIf="!customerselected"></app-customer-search>

						<app-customer-info *ngIf="customerselected" [customerdata]="customerdata" [userdata]="user" ></app-customer-info>

						<app-order-items *ngIf="customerselected" [customerdata]="customerdata" [dspcost]="dspcost" (addItem)="addToOrder($event)" [filteredItems]="searchitems"></app-order-items>

					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- right side cart-->
<!-- right side cart-->
<div class="fixed-plugin">
	<section class="dspcost text-center">
		<mat-checkbox class="margin mt-2" [(ngModel)]="dspcost" ></mat-checkbox>
	</section>
	<div class="dropdown show-dropdown text-center cart-fixed" *ngIf="items">
	<a href="#" data-toggle="dropdown" aria-expanded="false">
		<i class="fa fa-shopping-cart fa-2x text-white">
			{{ items.length }}
		</i>
		<span class="text-white" *ngIf="total_qty">({{total_qty}})</span>
	</a>
	<ul class="dropdown-menu cart-fixed-content " x-placement="bottom-start" id="rightcart" *ngIf="items">
		<li class="header-title">Cart</li>
		<ng-container *ngIf="items" >
			<div class="container" id="rightcart-content" (click)="stopProp($event)">
				<div class="row" >
					<div class="card m-0 p-0 border-0" *ngFor="let item of items">
						<div class="card-header ml-0 mr-0 mb-0 pl-0 pr-0  border-top">
							<div class="row">
								<div class="col-8">
									<h5 class="card-title p-0 m-0"><b>{{ item.stockid }}</b>: {{ item.description }}</h5>
								</div>
								<div class="col-4 text-right">
									<span class="mt-4">
										<b>TOTAL: {{ item.quantity * item.price - (item.price * item.discount / 100) | currency:'USD' }}</b>
									</span>
								</div>
							</div>
						</div>
						<div class="card-horizontal p-0 m-0">
							<div class="img-square-wrapper mt-2">
								<img class="cartthumbnail" src="{{ item.thumb }}" alt="" >
							</div>
							<div class="card-body pb-0 pt-0">
								<div class="row pb-0 pt-0">
									<div class="col-4  pb-0 pt-0">
										<mat-form-field>
												<input matInput type="text"
													class="text-right"
													placeholder="Price"
													[value]="item.price"
													(input)="updatePrice($event,item)">
											<!--<mat-label>Price</mat-label>			<mat-error>
											</mat-error>-->
										</mat-form-field>
									</div>
									<div class="col-4  pb-0 pt-0">
										<mat-form-field >

												<input matInput type="text" placeholder="QTY"
													class="text-center"
													[value]="item.quantity"	(input)="updateQuantity($event, item)">
											<!--<mat-label>QTY</mat-label>
											<mat-error>
											</mat-error>-->
										</mat-form-field>
									</div>

									<div class="col-4 text-right pb-0 pt-0">
										<button class="btn btn-sm btn-danger pl-0 pr-0" (click)="removeFromOrder(item.cart_id)">
											<i class="material-icons">remove_circle</i>
										</button>
									</div>
								</div>
								<div class="col-12 p-0 m-0">
									<mat-form-field>
										<input matInput maxlength="64" placeholder="Line Notes."
											[value]="item.notes"
											 (input)="updateNote($event,item)"	>
									</mat-form-field>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="items.length">
				<div class="row">
					<div class="col-6 text-left">
						<button class="btn btn-primary" (click)="clearCart()">Clear</button>
					</div>
					<div class="col-6 text-right">
					<button class="btn btn-primary" (click)="placeOrder()">Place Order</button>
					</div>
				</div>
			</div>
		</ng-container>
	</ul>
	</div>
</div>

<nav class="navbar fixed-bottom navbar-light bg-light">
	<div class="col-9 ml-auto text-right">
		<app-order-item-search *ngIf="customerselected" [customerdata]="customerdata" [dspcost]="dspcost" (itemList)="updateSearchItems($event)"></app-order-item-search>
	</div>
</nav>
