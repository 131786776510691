import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common'
import { InventoryService } from '../services/inventory.service';
import { ItemList } from '../classes/items';
import { BarcodeScannerLivestreamComponent } from 'ngx-barcode-scanner';
import { GlobalSearchService } from '../services/globalsearchservice.service';

import { OrdersService } from '../services/orders.service';
import { Order , OrderHeader } from '../classes/orders';
import * as jQuery from 'jquery';

@Component({
	selector: 'app-inventory',
	templateUrl: './inventory.component.html',
	styleUrls: ['./inventory.component.scss']
})

export class InventoryComponent implements OnInit {


	@ViewChild('stockid') stockidField: ElementRef;

	public itemForm: UntypedFormGroup;
	public itemlist;
	public item;
	ordertitle;
	orderdetails: Order;
	searching: boolean;
	showlist: boolean;
	historyloading: boolean;
	showorder: boolean;
	starting: boolean;
	lastsearch;
	inventorysearching: any;
	barcodeScanner: BarcodeScannerLivestreamComponent;
	barcodeValue: any;
	color: any ='';


	constructor(private globalSearchService: GlobalSearchService,private route: ActivatedRoute, private formBuilder: UntypedFormBuilder,public router: Router, private location: Location, public inventoryService: InventoryService, public orderService: OrdersService) {}

	ngOnInit(): void {
		this.showlist = false;
		this.showorder = false;
		this.starting = true;
		this.searching = false;
		this.historyloading = false;
		this.color = this.globalSearchService.getColor();
		this.itemForm = this.formBuilder.group({
			stockid: ['', Validators.required],
		});


		this.route.params.subscribe(params => {
			const itemnumber = params['id'];
			if(itemnumber) {
				this.itemForm.value.stockid = itemnumber;
				this.lookup();
			}
		});


	}

	focusInput() {
		this.stockidField.nativeElement.focus();
	}

	ngAfterViewInit() {

	}

	back(): void {
		this.location.back()
	}

	lookup() {

		this.starting = false;
		this.searching = true;
		this.historyloading = false;
		this.lastsearch = this.itemForm.value.stockid;
		if(this.itemForm.value.stockid != '') {

			if(this.inventorysearching) {
				this.inventorysearching.unsubscribe()
			}

			this.inventorysearching = this.inventoryService.lookup(this.itemForm.value).subscribe((item: ItemList) => {
				if(item.multiple == 'true') {
					this.itemlist = item.parts
					this.showlist = true;
				} else {
					this.item = item
					this.showlist = false;
					this.historyloading = true;
					this.getHistory(item.details.ITEM_NUMBER);
					this.itemForm = this.formBuilder.group({
						stockid: ['', Validators.required],
					});

					if(item.hasorders) {
						this.audioPlay();
					} else {
						this.audioPlayBad();
						this.vibrate();
					}

					this.focusInput();
				}
				this.searching = false;

				this.globalSearchService.blurInputElement(this.stockidField);
			});
		}
		else {
			this.focusInput();
			this.lastsearch = '';
			this.searching = false;
			this.showlist = false;
		}
	}

	loadItem(item) {

		this.starting = false;
		this.searching = true;
		this.historyloading = false;
		this.lastsearch = this.itemForm.value.stockid;
		this.item = [];

		if(this.inventorysearching) {
			this.inventorysearching.unsubscribe()
		}

		this.inventorysearching = this.inventoryService.lookup({ stockid: item, single: true }).subscribe((item: ItemList) => {
			this.item = item
			this.showlist = false;
			this.searching = false;
			this.itemForm = this.formBuilder.group({
				stockid: ['', Validators.required],
			});

			if(item.hasorders) {
				this.audioPlay();
			} else {
				this.audioPlayBad();
				this.vibrate()
			}

			this.focusInput();
		});

	}

	getHistory(item) {
		this.inventoryService.getHistory(item).subscribe((history: any[]) => {
			this.item.history = history;
			this.historyloading = false;
		});
	}

	loadOrder(order) {
		this.starting = false;
		this.orderService.getOrder(order).subscribe((results: Order) => {
			this.showorder = true;
			this.orderdetails = results;
			this.ordertitle = 'Order: '+ order+'';
		});

	}

	billingAddressFormatted(obj:OrderHeader) {

	    const address = obj.CUST_NAME + ' ('+ obj.CUST_NUMBER+ ')<br>' + obj.ADDRESS_1 + ' ' + obj.ADDRESS_2 + '<br>' + obj.CITY + ', ' + obj.STATE + ' ' +obj.ZIP_CODE;

		return address;
	}

	shippingAddressFormatted(obj:OrderHeader) {
		const address = obj.SHIP_NAME+'<br>'+obj.SHIP_ADDR_1 + ' ' + obj.SHIP_ADDR_2 + '<br>' + obj.SHIP_CITY + ', ' + obj.SHIP_STATE + ' ' +obj.SHIP_ZIP;
		return address;
	}

	onValueChanges(result) {
		this.barcodeValue = result.codeResult.code;
	}

	startCamera() {
		this.barcodeScanner.start();
	}

	onStarted(started) {

	}
	audioPlay(){
		const audio = new Audio('/assets/pristine-609.mp3');
		audio.play();
	}

	audioPlayBad(){
		const audio = new Audio('/assets/glitch.mp3');
		audio.play();
	}

	vibrate() {
		if (window.navigator && window.navigator.vibrate) {
			navigator.vibrate(2000);
		} else {
			// Not supported
		}
	}

}
