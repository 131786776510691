import { Component, OnInit, ViewChild, ChangeDetectorRef,AfterViewInit,ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportsService } from '../../services/reports.service';
import { Location } from '@angular/common'
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as FileSaver from 'file-saver';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { HttpClientModule,HttpHeaders,HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
import * as moment from 'moment';

import * as XLSX from 'xlsx';

@Component({
	selector: 'app-report-view',
	templateUrl: './report-view.component.html',
	styleUrls: ['./report-view.component.scss']
})

export class ReportViewComponent implements OnInit {

	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('htmlData', { static: false }) htmlData:ElementRef;



	selected: any;
	alwaysShowCalendars: boolean;
	reportoptions;
	hasvariables: boolean;
	tabledata: any = [];
	tablesrc: any = [];
	range_start;
	range_end;

	ready: boolean;
	reportForm: UntypedFormGroup;
	reportItems: {
		id: number;
		title: string;
	}[];

	skills = new UntypedFormArray([]);
	dtOptions: DataTables.Settings = {};
	dtRendered = true;
	isempty = false;

	dtTrigger: Subject<any> = new Subject();

	results = { data:[{}] };
	header: any; //= [{title: "",data: ""},];
	multiselect: any;
	macros = [];
	headercolumns: any = [];
	config: any = [];
	urlSafe: SafeResourceUrl;

	ranges: any = {
		'Today': [moment(), moment()],
		'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		'Last 7 Days': [moment().subtract(6, 'days'), moment()],
		'Last 30 Days': [moment().subtract(29, 'days'), moment()],
		'This Month': [moment().startOf('month'), moment().endOf('month')],
		'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 	'month').endOf('month')]
	}

	color: any = 'blue';
	html: any ='';
	constructor(private http: HttpClient,public sanitizer: DomSanitizer,public globalSearchService: GlobalSearchService, private route: ActivatedRoute,private reportsService: ReportsService,private location: Location,public router: Router, private formBuilder: UntypedFormBuilder, public cdr: ChangeDetectorRef) {
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})

		this.reportForm = this.formBuilder.group({
			report: [''],
			reportArray: this.formBuilder.array([ this.createItem('options') ])
		});

		this.alwaysShowCalendars = true;

		this.route.params.subscribe(params => {
			const file = params['id'];
			const type = params['type'];
			this.hasvariables = true;

			this.reportsService.getReport(file,type).subscribe((reportoptions:any) => {
				this.reportoptions = reportoptions;
				this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(reportoptions.full_link+'&template_color='+this.color);

				if(Object.keys(this.reportoptions.vars).length === 0) {
					this.hasvariables = false;
				}

			});
		});
	}


	public getReportArray() : UntypedFormArray {
		return this.reportForm.get('reportArray') as UntypedFormArray;
	}

	createItem(itemin): UntypedFormGroup {
		const asjson = '{'+itemin+': ""}';
		const name = JSON.stringify(asjson);
		return this.formBuilder.group({});
	}


	ngOnInit(): void {
		this.ready = true;

		if(!this.hasvariables) {
			this.onSubmit();
		}
		 //this.generateData();
	}

	datesUpdated(range) {
		this.range_start = this.transformDate(range.startDate._d)
		this.range_end = this.transformDate(range.endDate._d)
	}

	generateData() {

		this.dtOptions = {
			 data: this.results.data,
			 columns: this.header
		};
		/*
		this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
			dtInstance.clear();
			dtInstance.row.add([]).draw();
		})
		*/
	}

	reRenderDataTable(): void {
		/*
		this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
			dtInstance.destroy();
			this.dtTrigger.next();
		});
		*/
	}


	destroyDataTable(): void {
		/*
		this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
			dtInstance.destroy();
		});
		*/
	}

	updateData() {
		this.destroyDataTable();
		this.dtRendered = false
		//this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
		//	dtInstance.clear();
		//}


		this.cdr.detectChanges();
		this.dtRendered = true
		this.cdr.detectChanges();
		this.dtTrigger.next();
		/*
		this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
			//dtInstance.clear();
		});

		this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
			dtInstance.row.add([]);
		})
		*/

	}

	onSubmit() {

		const requests = [];
		const names = []
		const values = []
		this.ready = false;
		this.isempty = false;

		document.querySelectorAll('input').forEach(input => {
			requests[input.name] = input.value
			names.push(input.name)
			values.push(input.value)
		});

		document.querySelectorAll('select').forEach(input => {
			requests[input.name] = input.value
			names.push(input.name)
			values.push(input.value)
		});

		let geturl = '';

		names.forEach((value, index) => {
			if(names[index] != '') {
				geturl = geturl + '&'+names[index]+'='+values[index];
			}
		});

		this.macros.forEach((value, index) => {

			if(value.name != '') {
				geturl = geturl + '&macros['+value.name+']='+value.value;
			}
		});


		this.reportsService.runReport(geturl).subscribe((results: any[]) => {

			this.tabledata = new MatTableDataSource(results);
			this.tablesrc = results
			this.headercolumns = Object.keys(results[0]);

			this.tabledata.sort = this.sort;
			//this.tabledata.paginator = this.paginator;

			/*
			if(results.length > 0 ) {

				let newheader = [];
				(Object.keys(Object.values(results)[0])).forEach((keys : any, vals :any) => {
					if(keys) {
						newheader.push({title: keys, data:keys})
					}
				});
				let newvalues = []
				results.forEach((keys : any, vals :any) => {
					newvalues.push(keys);
				});

				if(!this.header) {
					this.isempty = true;
				}

				this.header = newheader;
				this.results.data = newvalues;
				this.updateData();
				this.ready = true;
				//$('#result-table').dataTable().fnAddData(results);
			} else {
				let newheader = [{title: 'NORESULTS', data:'NORESULTS'}];
				let newvalues = [{'NORESULTS':''}];
				this.results.data = newvalues;
				this.updateData();
				this.ready = true;
			}
			*/
		});

	}

	back(): void {
		this.location.back()
	}

	transformDate(date) {
		const datePipe = new DatePipe('en-US');
		//var datePipe = new DatePipe();
		return datePipe.transform(date, 'yyyy-MM-dd'); //whatever format you need.
	}


	varsToIds(data, namedkey) {
		const selectresult = [];
		data.forEach((keys : any, vals :any) => {
			selectresult.push({ id: keys.value, value: keys.value, display: keys.display, variable: namedkey })
		});

		return selectresult;
	}

	 addTagFn(name) {
		return { name: name, tag: true };
	}

	onSelectCompany(values, namekey) {
		//this.macros = [];
		values.forEach((keys : any, vals :any) => {
			this.macros.push({ name:namekey , value: keys.value });
		});


	}

	deleteSingle(values, namekey) {

		this.macros.forEach((value,index) => {
			if(value.name==namekey && value.value == values.value.value) {

				this.macros.splice(index,1);
			}
		});

		//this.macros = this.macros.filter(s => s != values);
	}

	ngAfterViewInit() {
		this.tabledata.sort = this.sort;
		//this.tabledata.paginator = this.paginator;
	}

	announceSortChange(event: any) {

	}

	exportExcel() {
		//much simpler
		const table_elt = document.getElementById('htmlData');
		const workbook = XLSX.utils.table_to_book(table_elt);
		const ws = workbook.Sheets['Sheet1'];
		XLSX.utils.sheet_add_aoa(ws, [[this.reportoptions.options.Name+' '+new Date().toISOString()]], { origin:-1 });
		XLSX.writeFile(workbook, this.reportoptions.options.Name+' '+new Date()+'.xlsx');
	}

	saveAsExcelFile(buffer: any, fileName: string): void {
		const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		const EXCEL_EXTENSION = '.xlsx';
		const data: Blob = new Blob([buffer], {
			type: EXCEL_TYPE
		});
		FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
	}

	exportPDF() {
		//const pdfTable = this.htmlData.nativeElement;



		const html = htmlToPdfmake(document.getElementById('htmlData').innerHTML, {


		});

		const docDefinition = {
			pageSize: 'A4',
			defaultStyle: {
				fontSize: 8,
			},
		 content: html,
		 styles: {
			 table: {
			   fontSize: 12,
			 },
			 anotherStyle: {
			   italics: true,
			   alignment: 'right'
			 }
		   },
		 /*[
		 {
		   layout: 'lightHorizontalLines', // optional
		   table: {
			 // headers are automatically repeated if the table spans over multiple pages
			 // you can declare how many rows should be treated as headers
			 headerRows: 1,
			 widths: [ '*', 'auto', 100, '*' ],
			 body: html
		   }
		 }],
		 */
			//		 tableAutoSize:true,
			//		 pageOrientation: 'landscape',
		 pageMargins: [ 40, 60, 40, 60 ],
		 };

		//const documentDefinition = { content: html };
		pdfMake.createPdf(docDefinition).download();

	  }

	/*

	exportPDF(): void {
		let DATA: any = document.getElementById('htmlData');




		html2canvas(DATA).then((canvas) => {
		  let fileWidth = 208;
		  let fileHeight = (canvas.height * fileWidth) / canvas.width;
		  const FILEURI = canvas.toDataURL('image/png');
		  let PDF = new jsPDF('p', 'mm', 'a4');
		  let position = 0;
		  PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
		  return PDF;
		}).then(PDF => {

			PDF.save('Inventory_Value' + new Date().getTime()+'.pdf');
		});
	  }
	  */




}
