<ng-template #details let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">{{ displaydetails.title }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="displaydetails">
			<h4></h4>
			<ng-container *ngIf="displaydetails.type == 'html'">
				<div [innerHTML]="displaydetails.display">
				</div>
			</ng-container>
			<ng-container *ngIf="displaydetails.type == 'pdf'">
				<ngx-extended-pdf-viewer [base64Src]="displaydetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true" [filenameForDownload]="displaydetails.title"></ngx-extended-pdf-viewer>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">
		<div class="row tp-wrapper m-0 p-0">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title" (click)="back()">
							<i class="material-icons">arrow_left</i>
							{{ title }}
						</h4>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-4">
								<mat-form-field appearance="outline">
									<mat-label>DRI Date</mat-label>
									<input matInput (dateChange)="updateDayPayMents($event.value)" [formControl]="datefrom" [matDatepicker]="payfrom">
									<mat-datepicker-toggle matSuffix [for]="payfrom"></mat-datepicker-toggle>
									<mat-datepicker #payfrom></mat-datepicker>
								</mat-form-field>
							</div>
							<div class="col-md-4">
								<mat-form-field appearance="fill">
									<mat-label>Location</mat-label>
									<mat-select [formControl]="branch">
										<mat-option *ngFor="let v of branches" [value]="v.loccode">{{v.locationname}}</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col-md-4">
								<button class="mt-3" mat-raised-button (click)="loadData()">Run</button>
								&nbsp;&nbsp;
								<button mat-raised-button (click)="$event.preventDefault();exportXls()" *ngIf="payments.summary">
									<i class="fa fa-file-excel-o"></i> XLS
								</button>
								&nbsp;&nbsp;
								<button mat-raised-button (click)="$event.preventDefault();exportPdf()" *ngIf="payments.summary">
									<i class="fa fa-file-pdf-o"></i> PDF
								</button>
							</div>
						</div>

						<div class="row">
							<span *ngIf="running">
								<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
							</span>

							<div class="col-md-12" id="payrep" *ngIf="!running">
								<ng-container *ngIf="payments.transactions">
									<div class="table" #print_table>
										<h3 class="mt-2 mb-4">{{ title }}</h3>
										<h4 class="mt-2 mb-4">{{ title }} Summary</h4>
										<div class="bg-white mt-2 rounded">
											<div id="receipts">
												<div class="row">
													<div class="col-md-6">
														<table class="summary-table table-fixed compact w-100">
															<tbody>
																<tr>
																	<td class="summary-label">Gross Amt:</td>
																	<td class="summary-value text-right">{{ totals.gross | currency }}</td>
																</tr>
																<tr>
																	<td class="summary-label">+ Tax:</td>
																	<td class="summary-value text-right">{{ totals.taxtotal | currency }}</td>
																</tr>
																<tr>
																	<td class="summary-label">- Disc:</td>
																	<td class="summary-value text-right">{{ totals.disctotal | currency }}</td>
																</tr>
																<tr>
																	<td class="summary-label">+ Misc:</td>
																	<td class="summary-value text-right">{{ totals.misctotal | currency }}</td>
																</tr>
																<tr class="summary-highlight">
																	<th class="summary-label">Net:</th>
																	<td class="summary-value text-right">{{ totals.net | currency }}</td>
																</tr>
																<tr class="summary-highlight">
																	<th class="summary-label">Total Sales:</th>
																	<th class="summary-value text-right">{{ totals.totalsales | currency }}</th>
																</tr>
																<tr>
																	<th class="summary-label">Total Invoices:</th>
																	<td class="summary-value text-right">{{ totals.invcount }}</td>
																</tr>
																<tr>
																	<th class="summary-label">Total Credits:</th>
																	<td class="summary-value text-right">{{ totals.credcount }}</td>
																</tr>
																<tr>
																	<th class="summary-label">Customers:</th>
																	<td class="summary-value text-right">{{ totals.custcount }}</td>
																</tr>
															</tbody>
														</table>
													</div>
													<div class="col-md-6">
														<table class="summary-table table-fixed compact w-100">
															<thead class="summary-thead-light">
																<tr>
																	<th class="summary-label">Type</th>
																	<th class="summary-value text-right">Amount</th>
																</tr>
															</thead>
															<tbody>
																<tr *ngFor="let p of payments.summary">
																	<td class="summary-label">{{ p.type }}</td>
																	<td class="summary-value text-right">{{ p.amount | currency }}</td>
																</tr>
																<tr class="summary-highlight">
																	<th class="summary-label">Payments:</th>
																	<th class="summary-value text-right">{{ total_pay | currency }}</th>
																</tr>
																<tr class="summary-highlight">
																	<th class="summary-label">Taxable Sales:</th>
																	<th class="summary-value text-right">{{ totals.taxablesales | currency }}</th>
																</tr>
															</tbody>
														</table>
													</div>
												</div>



												<h4>Total Trans: {{ total_trans | currency }}</h4>
												<h4 class="mt-2 mb-4">{{ title }}</h4>

												<table id="rcptTable" name="rcptTable" class="table compact table-sm table-fixed collapsed">
													<thead class="thead-dark">
														<tr>
															<th class="text-center">Date</th>
															<th class="text-center">Time</th>
															<th class="text-center">Order#</th>
															<th class="text-center">Document#</th>
															<th class="text-center">Type#</th>
															<th class="text-center">Terms#</th>
															<th class="text-right">Customer#</th>
															<th class="text-left">Name</th>
															<th class="text-right">Amount</th>
															<th *ngIf="config.env.package == 'tires'" class="text-right">Freight</th>
															<th class="text-right">Discount</th>
															<th class="text-right">Tax</th>
															<th class="text-right"><b>Total</b></th>
															<th class="text-right"><b>Payment</b></th>
															<th class="text-center">Settled</th>
															<th class="text-center">Reference</th>
															<th class="text-center">Payments</th>
														</tr>
													</thead>
													<tbody>
														<ng-container *ngFor="let pay of payments.transactions">
															<tr>
																<td class="text-center">{{ pay.trandate }}</td>
																<td class="text-center">{{ pay.inputdate }}</td>
																<td class="text-center">
																	<a href="#" (click)="$event.preventDefault();openDocument(pay,'html')"> {{ pay.order_ }}</a>
																</td>
																<td class="text-center">{{ pay.transno }}</td>
																<td class="text-center">{{ pay.typename }}</td>
																<td class="text-center">{{ pay.payterms }}</td>
																<td class="text-right text-nowrap">
																	<b>{{ pay.debtorno }}.{{ pay.branchcode }}</b>
																</td>
																<td class="text-left text-nowrap">{{ pay.name }}</td>
																<td class="text-right">{{ pay.ovamount | currency }}</td>
																<td *ngIf="config.env.package == 'tires'" class="text-right">{{ pay.ovfreight | currency }}</td>
																<td class="text-right">{{ pay.ovdiscount | currency }}</td>
																<td class="text-right">{{ pay.ovgst | currency }}</td>
																<td class="text-right"><b>{{ pay.total | currency }}</b></td>
																<td class="text-right" [ngClass]="{'text-success': pay.settled == 'Yes', 'text-danger': pay.settled == 'No'}">
																	<b>{{ pay.alloc | currency }}</b>
																</td>
																<td class="text-center">{{ pay.settled }}</td>
																<td class="text-center">{{ pay.reference }}</td>
																<td class="text-center">{{ pay.paytext }}</td>
															</tr>
														</ng-container>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</ng-container>

								<ng-container *ngIf="!payments.transactions">
									<h4>No Transactions Found</h4>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>