<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card tp-box">
					<div class="card-header card-header-{{color}}-5">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i>
							Credit Items & Taxes
						</h4>
						<div class="card-category" *ngIf="order.header">
							<div class="row m-0 p-0">
								<div class="col-4">
									Credit
								</div>
								<div class="col-8 text-right">
									{{ order.header.name }}: # <b>{{ orderno }}</b>
								</div>
							</div>
						</div>
					</div>
					<div class="card-body">

						<app-credit-invoice [orderno]="orderno" (reloadCustomer)="emitCustomerUpdate($event);" (invoiced)="showInvoiceResultandReset($event)" *ngIf="orderno"> </app-credit-invoice>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
