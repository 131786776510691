import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { merge, fromEvent, interval, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';

import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { Location } from '@angular/common'

import { ActivatedRoute, Router } from '@angular/router';
import { VendorService } from '../../services/vendor.service';
import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { InventoryService } from '../../services/inventory.service';
import { PurchasingService } from '../../services/purchasing.service';

@Component({
	selector: 'app-purchase-data',
	templateUrl: './purchase-data.component.html',
	styleUrls: ['./purchase-data.component.scss'],
})

export class PurchaseDataComponent implements OnInit {
	@Input() item: any = [];
	@Output() editing = new EventEmitter < boolean > ();
	@Output() newitemdata = new EventEmitter < any > ();
	@ViewChild('vendorsearch') vendorsearchele: ElementRef;
	adding = false;
	ready = true;
	supplierno: UntypedFormControl;
	purchaseForm: UntypedFormGroup;
	vendorsearchsubscription: any = false;
	vendorresults: any = [];
	properties: any = [];
	today = new Date();
	allvendors: any = false;
	updating: any = false;
	vendorexists: any = false;
	constructor(public globalSearchService: GlobalSearchService, private purchasingService: PurchasingService, private vendorService: VendorService, private route: ActivatedRoute, private fb: UntypedFormBuilder, private inventoryService: InventoryService, public router: Router, private location: Location, private globalsService: GlobalsService) {
	}

	ngOnInit(): void {

		this.vendorService.getVendorSearch('').subscribe((vs: any) => {
			this.allvendors = vs
			this.globalsService.getItemSetup().subscribe((results: any) => {
				this.properties = results
			});

			this.setForm();
		});
	}

	vendorSearch(value: any) {

		this.ready = false;
		fromEvent(this.vendorsearchele.nativeElement, 'keyup')
			.pipe(
				debounceTime(150),
				distinctUntilChanged(),
			)
			.subscribe();


		if (this.vendorsearchsubscription) {
			this.vendorsearchsubscription.unsubscribe();
		}

		this.vendorsearchsubscription = this.vendorService.getVendorSearch(value).subscribe((results: any) => {
			this.vendorresults = results
			this.ready = true;
		});

	}

	setForm() {

		const preferred = (this.item.purchdata.length) ? 0 : 1;
		const price = (this.item.purchdata.length) ? this.item.purchdata[0].price : this.item.cost;
		if (!this.item.item) {
			this.purchaseForm = this.fb.group({
				supplierno: ['', Validators.required],
				effectivefrom: [this.today, Validators.required],
				stockid: [this.item.stockid, Validators.required],
				suppliersuom: [this.item.purchdata[0].suppliersuom, Validators.required],
				conversionfactor: [1, Validators.required],
				supplierdescription: [this.item.purchdata[0].supplierdescription],
				preferred: [0, Validators.required],
				price: [price, Validators.required],
				suppliers_partno: [this.item.purchdata[0].suppliers_partno, Validators.required],
				minorderqty: [1, Validators.required],
				maxorderqty: [1, Validators.required],
				allownegative: true,
			});
		} else {
			this.purchaseForm = this.fb.group({
				supplierno: ['', Validators.required],
				effectivefrom: [this.today, Validators.required],
				stockid: [this.item.item.stockid, Validators.required],
				suppliersuom: [this.item.item.units, Validators.required],
				conversionfactor: [1, Validators.required],
				supplierdescription: [this.item.item.description],
				preferred: [preferred, Validators.required],
				price: [price, Validators.required],
				suppliers_partno: ['', Validators.required],
				minorderqty: [1, Validators.required],
				maxorderqty: [1, Validators.required],
				allownegative: false,
			});
		}

		this.purchaseForm.get('supplierno').valueChanges.subscribe(newValue => {

			const exists = this.item.purchdata.filter( p => {
				return p.supplierno == newValue.supplierid
			});
			this.updating = false;
			if(exists.length) {
				this.updating = true;
			}

			const vendorexists = this.allvendors.filter( p => {
				return p.supplierno == newValue.supplierid
			});

			this.vendorexists = false;
			if(vendorexists.length) {
				this.vendorexists = true;
			}

		})
	}

	onSubmit() {

		if (this.purchaseForm.valid) {
			this.purchasingService.addPurchaseDate(this.purchaseForm.value).subscribe((result: any) => {
				this.item = result
				this.newitemdata.emit(result);
				this.adding = false;
				this.setForm();
			})
		} else {
			this.globalSearchService.getFormValidationErrors(this.purchaseForm)
		}
	}

	editPurchaseData(data: any) {

		this.purchaseForm = this.fb.group({
			supplierno: [data, Validators.required],
			effectivefrom: [this.today, Validators.required],
			stockid: [data.stockid, Validators.required],
			suppliersuom: [data.suppliersuom, Validators.required],
			conversionfactor: [data.conversionfactor, Validators.required],
			supplierdescription: [data.supplierdescription, ],
			preferred: [data.preferred, Validators.required],
			price: [data.price, Validators.required],
			suppliers_partno: [data.suppliers_partno, Validators.required],
			minorderqty: [data.minorderqty, Validators.required],
			maxorderqty: [data.maxorderqty, Validators.required],
		});

		this.adding = true;
		this.vendorexists = true;
		this.purchaseForm.get('supplierno').valueChanges.subscribe(newValue => {
			const exists = this.item.purchdata.filter( p => {
				return p.supplierno == newValue.supplierid
			});
			this.updating = false;
			if(exists.length) {
				this.updating = true;
			}

			const vendorexists = this.allvendors.filter( p => {
				return p.supplierno == newValue.supplierid
			});
			this.vendorexists = false;
			if(vendorexists.length) {
				this.vendorexists = true;
			}
		});

	}

	removePurchaseData(data: any) {

		const isok = (this.item.purchdata.length == 1) ? false : true;
		if (!isok) {
			alert('You can not remove this record.');
		} else {
			if (confirm('Delete Purchase Information?')) {
				this.purchasingService.removePurchaseDate(data).subscribe((result: any) => {

					this.item = result
					this.adding = false;
					this.newitemdata.emit(result);
				})
			}
		}
	}

	addPurchaseData() {
		this.adding = true;

		this.purchaseForm = this.fb.group({
			supplierno: ['', Validators.required],
			effectivefrom: [this.today, Validators.required],
			stockid: [this.item.item.stockid, Validators.required],
			suppliersuom: [this.item.item.units, Validators.required],
			conversionfactor: [1, Validators.required],
			supplierdescription: [this.item.item.description],
			preferred: [0, Validators.required],
			price: [0.00, Validators.required],
			suppliers_partno: ['', Validators.required],
			minorderqty: [1, Validators.required],
			maxorderqty: [1, Validators.required],
			allownegative: false,
		});

		this.vendorexists = false;

		this.purchaseForm.get('supplierno').valueChanges.subscribe(newValue => {
			const exists = this.item.purchdata.filter( p => {
				return p.supplierno == newValue.supplierid
			});
			this.updating = false;
			if(exists.length) {
				this.updating = true;
			}

			const vendorexists = this.allvendors.filter( p => {
				return p.supplierno == newValue.supplierid
			});
			this.vendorexists = false;
			if(vendorexists.length) {
				this.vendorexists = true;
			}
		});
	}

	toggleAdding() {
		this.adding = (this.adding) ? false : true;
	}

	displayFn(vendorid: any): string {

		if (!vendorid) {
			return '';
		}

		return vendorid.suppname;

	}

	ngAfterViewInit(): void {

	}

}
