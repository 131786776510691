<div class="table fixTableHead" #print_table>
<table class="table table-fixed table-hover">
	<thead>
	<tr>
		<th>ID#</th>
		<th>Description</th>
		<th></th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let auth of taxgroups">
		<td>{{ auth.taxgroupid }}</td>
		<td>{{ auth.taxgroupdescription }}</td>
	</tr>
	</tbody>
</table>
</div>
