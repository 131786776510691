<ng-template #details let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Terms and Conditions</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="row justify-content-center m-auto">
			<app-wysiwyg-editor [input]="termsModal" [config]="'limited'" (data)="updateForm(termsModal, $event)"></app-wysiwyg-editor>
		</div>
	</div>
	<div class="modal-footer">
		<div class="row w-100 justify-content-center">
			<div >
				<button mat-raised-button class="btn-danger" (click)="dismissTerms(0)">Cancel Edits</button>
				&nbsp;&nbsp;&nbsp;&nbsp;
				<button mat-raised-button class="btn-light" (click)="dismissTerms(1)">OK</button>
			</div>
		</div>
	</div>
</ng-template>



<div class="main-content" *ngIf="company">
	<form [formGroup]="companyForm" class="text-dark">
		<div class="container-fluid" *ngIf="company">
			<div class="row m-0 p-0">
				<div class="col-md-12">
					<div class="card">
						<div class="card-header card-header-{{color}}">
							<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Company Settings</h4>
						</div>
						<div class="card-body">
							<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent">
								<mat-tab label="Company Preferences">
									<ng-template matTabContent>
										<div class="row m-0 mt-3">
											<div class="col-md-12 text-right">
												<button mat-button mat-raised-button color='primary' (click)="update()">Update</button>
											</div>
										</div>
										<div class="row m-0 p-0 mt-2">
											<div class="col-md-12 text-center mb-3">
												<ngx-dropzone (change)="onSelect($event)">
												  <ngx-dropzone-label>
													<div *ngIf="!logo; else logoPreview">
													  <p>Drag and drop a logo image here or click to select a file</p>
													</div>
													<ng-template #logoPreview>
													  <img [src]="logo" alt="Logo Preview" class="img-fluid" />
													</ng-template>
												  </ngx-dropzone-label>
												</ngx-dropzone>
											  </div>

											<!-- Form Controls -->
											<div class="col-md-6" *ngFor="let control of controls">
												<mat-form-field appearance="fill" *ngIf="control !== 'logo' && control !=='terms_conditions'">
													<mat-label>{{ properCase(control) }}</mat-label>
													<input matInput [formControlName]="control">
												</mat-form-field>
												<mat-form-field appearance="fill" *ngIf="control === 'terms_conditions'">
													<mat-label>{{ properCase(control) }}</mat-label>
													<input matInput [formControlName]="control" (click)="openTermsModal(control)" readonly>
												</mat-form-field>
											</div>
										</div>

									</ng-template>
								</mat-tab>

								<mat-tab label="Configuration">
									<ng-template matTabContent>
										<form [formGroup]="configForm" class="config-form">
											<!-- Header Section -->
											<div class="row m-0 mt-3">
												<div class="col-md-12 text-right">
													<button mat-raised-button class="ml-auto mr-2" color="primary" (click)="toggleSection()">
														<ng-container *ngIf="!sectionVisible">Add Config</ng-container>
														<ng-container *ngIf="sectionVisible">Cancel</ng-container>
													</button>
													<button mat-raised-button color="primary" (click)="updateConfig()">Update</button>
												</div>
											</div>

											<!-- Config Section (Visible on Add Config) -->
											<div *ngIf="sectionVisible" class="config-section">
												<div class="row mt-3">
													<div class="col-md-4">
														<mat-form-field appearance="fill" class="config-field">
															<mat-label>Configuration Name</mat-label>
															<input matInput [formControl]="confname">
														</mat-form-field>
													</div>
													<div class="col-md-4">
														<mat-form-field appearance="fill" class="config-field">
															<mat-label>Configuration Value</mat-label>
															<input matInput [formControl]="confvalue">
														</mat-form-field>
													</div>
													<div class="col-md-4">
														<mat-form-field appearance="fill" class="config-field">
															<mat-label>Configuration Type</mat-label>
															<mat-select [formControl]="conftype">
																<mat-option value="text">Text</mat-option>
																<mat-option value="bool">Boolean</mat-option>
															</mat-select>
														</mat-form-field>
													</div>
												</div>
												<div class="text-right mt-3">
													<button mat-raised-button color="primary" (click)="addConfig()">Add</button>
												</div>
											</div>

											<!-- Existing Configs Section -->
											<div class="row mt-3">
												<div class="col-md-6" *ngFor="let control of configs">
													<ng-container *ngIf="getConfig(control).type === 'bool'; else textInput">
														<mat-checkbox [formControlName]="control">{{ getConfig(control).name }}</mat-checkbox>
													</ng-container>
													<ng-template #textInput>
														<mat-form-field appearance="fill" class="config-field">
															<mat-label>{{ getConfig(control).name }}</mat-label>
															<input matInput [formControlName]="control">
														</mat-form-field>
													</ng-template>
												</div>
											</div>
										</form>
									</ng-template>
								</mat-tab>



								<mat-tab label="Email Settings">
									<ng-template matTabContent>
										<app-mail></app-mail>
									</ng-template>
								</mat-tab>
								<mat-tab label="Store Setup">
									<ng-template matTabContent>
										<app-store-maintenance></app-store-maintenance>
									</ng-template>
								</mat-tab>
								<mat-tab label="Salesperson Setup">
									<ng-template matTabContent>
										<app-sales-people></app-sales-people>
									</ng-template>
								</mat-tab>
							</mat-tab-group>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
