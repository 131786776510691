<ul @grow class="list-group border-1" >
	<div class="row">
		<div class="col-md-6">
			<button mat-raised-button color="accent" (click)="addContact()">Add New Contact</button>
		</div>
		<div class="col-md-6">
			<button mat-raised-button class="btn btn-primary" (click)="onSubmit()">Save</button>
		</div>
	</div>
	<li class="list-group-item border-dark" *ngFor="let contact of vendor.contacts">
		<div class="row">
			<div class="col-3">
				<mat-form-field class="full-width" appearance="outline">
					<mat-label>Name</mat-label>
					<input matInput value="{{contact.contactname}}" required placeholder="Contact Name" (input)="updateContactName($event, contact)" >
				 </mat-form-field>
			</div>
			<div class="col-3">
				 <mat-form-field class="full-width" appearance="outline">
					 <mat-label>Phone</mat-label>
					 <input matInput value="{{contact.phoneno}}"  placeholder="Contact Phone" (input)="updateContactPhone($event, contact)" >
				  </mat-form-field>
			</div>
			<div class="col-3">
				  <mat-form-field class="full-width" appearance="outline">
					  <mat-label>Email</mat-label>
					  <input matInput value="{{contact.email}}"  placeholder="Contact Email" (input)="updateContactEmail($event, contact)" >

				   </mat-form-field>
			</div>
			<div class="col-3">
				   <mat-form-field class="full-width" appearance="outline">
					   <mat-label>Role</mat-label>
					   <input matInput required value="{{contact.role}}" placeholder="Contact Role" (input)="updateContactRole($event, contact)" >
					   <mat-icon matSuffix (click)="removeContact(contact.contid)">remove</mat-icon>
					</mat-form-field>
			</div>
		</div>
	</li>

</ul>
