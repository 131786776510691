<ng-template #detailsModal let-modal >

	<div class="modal-header">
		<h3>Item Search</h3>
	</div>

	<div class="modal-body">
			<div class="col-12 m-0 p-0" style="border: 1px solid gainsboro; height: 100%;">
				<div class="w-100 p-0 m-0 border-bottom cell-header">
					<h4><b>ITEMS</b></h4>
				</div>
				<div class="w-100 m-0 p-1">
					<mat-form-field class="example-full-width">
						<mat-label>Search for items</mat-label>
						<input matInput value="" (keyup)="searchProd($event)">
					</mat-form-field>
				</div>
				<div class="w-100 mt-1 pt-1 bottom-cell">
					<ng-container *ngFor="let prod of items" clas>
						<div class="row p-0 ml-0 mr-0 mb-1 row-entry">
							<div class="col-12 m-0 p-1" *ngIf='!exclusions.items.includes(prod.stockid)' (click)="addExclusion('items', prod)">{{prod.stockid}} - {{prod.description}}<mat-icon class="float-right">add</mat-icon></div>
							<div class="col-12 m-0 p-1 excluded" *ngIf='exclusions.items.includes(prod.stockid)' (click)="removeExclusion('items', prod)">{{prod.stockid}} - {{prod.description}} <mat-icon class="float-right ">delete</mat-icon></div>
						</div>
					</ng-container>
				</div>
			</div>
	</div>
</ng-template>


<div class="main-content mt-2">

	<div class="p-0 m-0 row justify-content-end">
		<button mat-raised-button color="accent" (click)="saveRule()" *ngIf="!savingRule">Save</button>
		<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg" *ngIf="savingRule">
			<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
		</svg>
	</div>

	<div class="col-10 m-auto p-0 m-0">
		<div class="row m-0 p-0">
			<div class="col-4 p-0 m-0" style="border: 1px solid gainsboro; height: 100%; overflow-y:none;">
				<div class="w-100 p-0 m-0 border-bottom cell-header">
					<h4><b>CATEGORIES</b></h4>
				</div>
				<div class="w-100 mt-1 ml-0 mr-0 bottom-cell ">
					<ng-container *ngFor="let cat of filteredCats">
						<div class="row p-0 ml-0 mr-0 mb-1 row-entry">
							<div class="col-12 m-0 p-1" *ngIf='!exclusions.categories.includes(cat.categorydescription)' (click)="addExclusion('categories', cat)">{{cat.categorydescription}} <mat-icon class="float-right">add</mat-icon></div>
							<div class="col-12 m-0 p-1 excluded" *ngIf='exclusions.categories.includes(cat.categorydescription)' (click)="removeExclusion('categories', cat)">{{cat.categorydescription}} <mat-icon class="float-right ">delete</mat-icon></div>
						</div>
					</ng-container>
					<br>
				</div>
			</div>
			<div class="col-4 p-0 m-0" style="border: 1px solid gainsboro; height: 100%;">
				<div class="w-100 p-0 m-0 border-bottom cell-header">
					<h4><b>BRANDS</b></h4>
				</div>
				<div class="w-100 mt-1 pt-1 bottom-cell">
					<ng-container *ngFor="let cat of filteredBrans">
						<div class="row p-0 ml-0 mr-0 mb-1 row-entry">
							<div class="col-12 m-0 p-1" *ngIf='!exclusions.brands.includes(cat.name)' (click)="addExclusion('brands', cat)">{{cat.name}} <mat-icon class="float-right">add</mat-icon></div>
							<div class="col-12 m-0 p-1 excluded" *ngIf='exclusions.brands.includes(cat.name)' (click)="removeExclusion('brands', cat)">{{cat.name}} <mat-icon class="float-right">delete</mat-icon></div>
						</div>
					</ng-container>
				</div>
			</div>
			<div class="col-4 m-0 p-0" style="border: 1px solid gainsboro; height: 100%;">
				<div class="w-100 p-0 m-0 border-bottom cell-header">
					<h4><b>LINES</b></h4>
				</div>
				<div class="w-100 mt-1 pt-1 bottom-cell">
					<ng-container *ngFor="let line of filteredLines">
						<div class="row p-0 ml-0 mr-0 mb-1 row-entry">
							<div class="col-12 m-0 p-1" *ngIf='!exclusions.lines.includes(line.line_description)' (click)="addExclusion('lines', line)">{{line.line_description}} <mat-icon class="float-right">add</mat-icon></div>
							<div class="col-12 m-0 p-1 excluded" *ngIf='exclusions.lines.includes(line.line_description)' (click)="removeExclusion('lines', line)">{{line.line_description}} <mat-icon class="float-right">delete</mat-icon></div>
						</div>
					</ng-container>
				</div>
			</div>

		</div>
	</div>

	<div class="col-10 m-auto p-0 m-0">
		<mat-form-field class="example-full-width" appearance="fill">
			<mat-label>Filter</mat-label>
			<input matInput value="" (keyup)="filterLists($event)">
		</mat-form-field>
	</div>

	<div class='col-10 m-0 p-0 mb-1'>
		<h3 class="ml-2">Exclusion Rules</h3>
		<button mat-raised-button (click)="itemModal()">Exclude Items</button>
	</div>


	<hr>
	<div class="col-10 m-auto p-0 m-0 mb-1">
		<div class="row m-0 p-0">
			<div class="col-3 m-0 p-0">
				<table>
					<tr>
						<th>Categories Excluded</th>
					</tr>
					<tr *ngFor="let i of exclusions.categories" class="exclude-row" (click)="removeExclusionBottom('categories', i)">
						<td>{{i}} <mat-icon class="float-right text-danger">delete</mat-icon></td>
					</tr>
				</table>
			</div>
			<div class="col-3 m-0 p-0">
				<table>
					<tr>
						<th>Brands Excluded</th>
					</tr>
					<tr *ngFor="let i of exclusions.brands" class="exclude-row" (click)="removeExclusionBottom('brands', i)">
						<td>{{i}} <mat-icon class="float-right text-danger">delete</mat-icon></td>
					</tr>
				</table>
			</div>
			<div class="col-3 m-0 p-0">
				<table>
					<tr>
						<th>Prod Lines Excluded</th>
					</tr>
					<tr *ngFor="let i of exclusions.lines" class="exclude-row" (click)="removeExclusionBottom('lines', i)">
						<td>{{i}} <mat-icon class="float-right text-danger">delete</mat-icon></td>
					</tr>
				</table>
			</div>
			<div class="col-3 m-0 p-0">
				<table>
					<tr>
						<th>Items Excluded</th>
					</tr>
					<tr *ngFor="let i of exclusions.items" class="exclude-row" (click)="removeExclusionBottom('items', i)">
						<td>{{i}} <mat-icon class="float-right text-danger">delete</mat-icon></td>
					</tr>
				</table>
			</div>
		</div>

	</div>
