import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { GlobalsService } from '../../services/globals.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

@Component({
	selector: 'app-inventory-upload',
	templateUrl: './inventory-upload.component.html',
	styleUrls: ['./inventory-upload.component.scss'],
	animations: [
		trigger('grow', [ // Note the trigger name
	  transition(':enter', [
		  // :enter is alias to 'void => *'
		  style({ height: '0',width: '0', overflow: 'hidden' }),
		  animate(200, style({ height: '*' , width: '*' })),
		  animate('200ms', style({ opacity: 1 })),
	  ]),
	  transition(':leave', [
		  // :leave is alias to '* => void'
		  animate(300, style({ height: 0, width: 0 , overflow: 'hidden' })),
		  animate('300ms', style({ opacity: 0 }))
	  ])
	  ]),
	]
})
export class InventoryUploadComponent {
@Input() item;
	@Output() editing = new EventEmitter<boolean>();
	@Output() newitemdata = new EventEmitter<boolean>();
	files: File[] = [];
	itemForm: any = false;
	uploadresults: any = [];
	properties: any = [];
	formEditAllowed = [
		'stockid',
		'image',
	]
	uploaddata: any = false;
	mapcols: any = [
		{ field: 'mfgpart', label: 'Part Number' },
		{ field: 'brand', label: 'Brand' },
		{ field: 'price', label: 'Price' },
		{ field: 'stockid', label: 'Item Number' },
	];
	mapping: any = [];
	color: any = 'blue';
	isLoading = false
	sending = false
	filelist: any = [];
	preflight = new UntypedFormControl(true);

	constructor(private globalSearchService: GlobalSearchService, private route: ActivatedRoute, private fb: UntypedFormBuilder,private inventoryService: InventoryService, public router: Router, private location: Location, private globalsService: GlobalsService) {
		this.color = this.globalSearchService.getColor();
	}

	onSubmit() {


	}

	ngOnInit(): void {

		this.inventoryService.getBatchUpload().subscribe( r => {
			this.filelist = r
		})
	}

	onSelect(event: any) {
		this.isLoading = true

		this.files.push(...event.addedFiles);
		for (let i = 0; i < this.files.length; i++) {
			this.readFile(this.files[i]).then(fileContents => {
				const upload = {
					content: fileContents,
					name: event.addedFiles[0].name,
					mime: event.addedFiles[0].type,
					preflight: this.preflight.value
				}

				// this.inventoryService.matchItems(results.tablename).subscribe( r=> {
				//
				// });
				//
				this.inventoryService.uploadInventory(upload).subscribe((results: any) => {
					this.uploaddata = results;
					this.files = [];
					this.inventoryService.getBatchUpload().subscribe( r => {
						this.filelist = r
					})
				});

			});
		}

		this.isLoading = false
	}

	onRemove(event: any) {
		this.files.splice(this.files.indexOf(event), 1);
	}

	private async readFile(file: File): Promise<string | ArrayBuffer> {
		return new Promise<string | ArrayBuffer>((resolve, reject) => {
		  const reader = new FileReader();

			reader.onload = e => {
				return resolve((e.target as FileReader).result);
			};

			reader.onerror = e => {
				console.error(`FileReader failed on file ${file.name}.`);
				return reject(null);
			};

			if (!file) {
				return reject(null);
			}
			reader.readAsDataURL(file);
		});

		this.files = [];
	}

	prepFileExport(f:any) {
		const data = {
			batch_id: f.batch_id,
			file: f.name
		}
		this.sending = true;
		this.inventoryService.prepExportUpload(data).subscribe((results: any) => {
			this.sending = false;
			this.uploaddata = results;

		});
	}

	prepFile(f:any) {
		const data = {
			batch_id: f.batch_id,
			file: f.name
		}
		this.sending = true;
		this.inventoryService.prepUpload(data).subscribe((results: any) => {
			this.sending = false;
			this.uploaddata = results;

		});

	}

	process(upload: any) {
		this.sending = true;

		const data = {
			batch_id: upload.batch_id,
			file: upload.name
		}

		this.inventoryService.processUpload(data).subscribe((results: any) => {
			this.sending = false;
			if(results.success) {
				this.uploaddata = false;
				this.inventoryService.getBatchUpload().subscribe( r => {
					this.filelist = r;
				});
				this.globalSearchService.showNotification('File Processed', 'success', 'bottom', 'right');
			}

		});
	}
	removeFile(f: any) {

		const data = {
			batch_id: f.batch_id,
			file: f.name
		}
		this.inventoryService.removeUpload(data).subscribe((results: any) => {
			this.filelist = results
		});

	}
	ngAfterViewInit(): void {

	}
	back(): void {
		this.location.back()
	}


}
