import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { PrintService } from '../../services/print.service'



@Component({
	selector: 'app-zero-cost',
	templateUrl: './zero-cost.component.html',
	styleUrls: ['./zero-cost.component.scss']
})
export class ZeroCostComponent implements OnInit {
	@Input() data: any = [];
	filename = 'zerocost';
	@ViewChild('print_table') printtable: ElementRef;
	constructor(private globalSearchService: GlobalSearchService, private printService: PrintService) {}

	ngOnInit(): void {}

	exportPdf() {

		const encoded: string = btoa(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}
	exportXls() {
		const encoded: string = btoa(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

}
