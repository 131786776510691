<div class="main-content">
	<div class="col-md-12">
		<div class="card">
			<div class="card-header card-header-danger" *ngIf="flip != 'inactive'">
				<h4 class="card-title "> <i class="material-icons" (click)="toggleFlip('na', '')">arrow_left</i> {{ fliptitle }} </h4>
				<p class="card-category" ></p>
			</div>
			<div class="card-body">
				<div class="container-fluid tp-box" [@flipState]="flip">
					<div class="backside tp-box__side tp-box__back" [ngClass]="flip != 'inactive' ? '' : 'd-none'">
						<app-qantel-order-items [header]="header" [(results)]="results" [(allresults)]="results" [(type)]="type" [reload]="ready" *ngIf="results"></app-qantel-order-items>
					</div>
						<div class="row tp-box__side tp-box__front" id="frontside" *ngIf="picking">
							<div class="col-lg-4 col-md-4 col-sm-6" >
								<div class="card card-stats">
									<div class="card-header card-header-warning card-header-icon">
										<div class="card-icon">
											<i class="material-icons">border_outer</i>
										</div>
										<p class="card-category"><b>Open Orders</b></p>
										<h3 class="card-title" >
											<span *ngIf="picking">{{ picking.totalcount }}</span>
	<span *ngIf="!picking">
											<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
											</span>
											<span *ngIf="notready != 'notready'"></span>
											<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg></span>
										</h3>

										<small *ngIf="picking">
											<p class="card-category text-info text-justify mt-3 text-right">
												<span class=" ml-1 mr-1" (click)="toggleFlip('allone','', true, allone)"> ( <b>{{ allone.length }}</b> ) 3-5 Days </span>
												<span class="ml-1 mr-1"(click)="toggleFlip('alltwo','', true, alltwo)"> ( <b>{{ alltwo.length }}</b> ) 5-7 Late </span>
												 <span class="ml-1 mr-1" (click)="toggleFlip('allthree','', true, allthree)"> ( <b>{{ allthree.length }}</b> ) > 7 Days </span>
											</p>
										</small>
									</div>
									<div class="card-footer">
										<div class="stats" *ngIf="picking.totalcount">
											<a (click)="toggleFlip('openorders','')"><i class="material-icons">content_copy</i>
											View Now</a>
										</div>
										<div class="stats" *ngIf="!picking.totalcount">
										<br />
									</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-4 col-sm-6">
							<div class="card card-stats">
								<div class="card-header card-header-success card-header-icon">
									<div class="card-icon">
										<i class="material-icons">done</i>
									</div>

									<p class="card-category"><b>Ready To Pick</b></p>
									<h3 class="card-title" >
										<span *ngIf="picking">{{ picking.fillablecount }}</span>
										<span *ngIf="!picking">
										<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
										</span>

										<span *ngIf="notready != 'notready'"></span>
										<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg></span>
									</h3>
									<small *ngIf="picking">

										<p class="card-category text-info text-justify mt-3 text-right">
											<span class=" ml-1 mr-1" (click)="toggleFlip('lateone','', true, lateone)"> ( <b>{{ picklateone.length }}</b> ) 3-5 Days </span>
											<span class="ml-1 mr-1"(click)="toggleFlip('latetwo','' , true, latetwo)"> ( <b>{{ picklatetwo.length }}</b> ) 5-7 Late </span>
											<span class="ml-1 mr-1" (click)="toggleFlip('late','' , true, late)"> ( <b>{{ picklatethree.length }}</b> ) > 7 Days </span>
										</p>
									</small>

										<small *ngIf="picking.partialpickedcount"><p class="card-category text-info" (click)="toggleFlip('partialpicked','')"> ( {{ picking.partialpickedcount }} ) Partial Picks</p></small>

								</div>
								<div class="card-footer">
									<div class="stats"  *ngIf="picking.fillablecount">
										<a (click)="toggleFlip('fillable','')"><i class="material-icons">visibility</i>
										 View Now</a>
									</div>
									<div class="stats" *ngIf="!picking.fillablecount">
										<br />
									</div>
									<div class="stats" *ngIf="!picking.fillablecount">
										<br />
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-4 col-md-4  col-sm-6">
							<div class="card card-stats">
								<div class="card-header card-header-danger card-header-icon">
									<div class="card-icon">
										<i class="material-icons">hourglass_disabled</i>
									</div>
									<p class="card-category"><b>Un-fillable Orders</b></p>
									<h3 class="card-title" >

										<span *ngIf="picking">{{ picking.notfillablecount }}</span>
										<span *ngIf="!picking">
										<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
										</span>


										<span *ngIf="notready != 'notready'"></span>
										<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg></span>
									</h3>

									<small *ngIf="picking">

									<p class="card-category text-info text-justify mt-3 text-right">
										<span class="ml-1 mr-1" (click)="toggleFlip('notfillableone','',true, notfillableone)"> ( <b>{{ notfillableone.length }}</b> ) 3-5 Days </span>
										<span class="ml-1 mr-1"(click)="toggleFlip('notfillabletwo','',true, notfillabletwo)"> ( <b>{{ notfillabletwo.length }}</b> ) 5-7 Late </span>
										<span class="ml-1 mr-1" (click)="toggleFlip('notfillablethree','',true, notfillablethree)"> ( <b>{{ notfillablethree.length }}</b> ) > 7 Days </span>
									</p>

									</small>

								</div>
								<div class="card-footer">
									<div class="stats" *ngIf="picking.notfillablecount">
										<a (click)="toggleFlip('notfillablecount','')"><i class="material-icons">visibility</i>
										 View Now</a>
									</div>
									<div class="stats" *ngIf="!picking.notfillablecount">
										<br />
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-4 col-md-4 col-sm-6">
							<div class="card card-stats">
								<div class="card-header card-header-danger card-header-icon">
									<div class="card-icon">
										<i class="material-icons">inventory</i>
									</div>
									<p class="card-category"><b>Return To Bin</b></p>

									<h3 class="card-title" >

										<span *ngIf="picking">{{ returns.length }}</span>
										<span *ngIf="!picking">
										<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
										</span>
										<span *ngIf="notready != 'notready'"></span>
										<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg></span>
									</h3>
								</div>
								<div class="card-footer">
									<div class="stats" *ngIf="returns.length">
										<a (click)="toggleFlip('returns','',returns)"><i class="material-icons">visibility</i>
										 View Now</a>
									</div>
									<div class="stats" *ngIf="!returns.length">
										<br />
									</div>

								</div>
							</div>
						</div>
						<div class="col-lg-4 col-md-4 col-sm-6">
							<div class="card card-stats">
								<div class="card-header card-header-success card-header-icon">
									<div class="card-icon">
										<i class="material-icons">input</i>
									</div>
									<p class="card-category"><b>Ready To Load</b></p>

									<h3 class="card-title" >

										<span *ngIf="picking">{{ picking.pickedcount }}</span>
										<span *ngIf="!picking">
										<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
										</span>
										<span *ngIf="notready != 'notready'"></span>
										<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg></span>
									</h3>
								</div>
								<div class="card-footer">
									<div class="stats" *ngIf="picking.pickedcount">
										<a (click)="toggleFlip('picked','')"><i class="material-icons">visibility</i>
										 View Now</a>
									</div>
									<div class="stats" *ngIf="!picking.pickedcount">
										<br />
									</div>

								</div>
							</div>
						</div>
						<div class="col-lg-4 col-md-4 col-sm-6">
							<div class="card card-stats">
								<div class="card-header card-header-warning card-header-icon">
									<div class="card-icon">
										<i class="material-icons">inventory_2</i>
									</div>
									<p class="card-category"><b>In Picking</b></p>

									<h3 class="card-title" >

										<span *ngIf="picking">{{ picking.inpickcount }}</span>
										<span *ngIf="!picking">
										<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
										</span>
										<span *ngIf="notready != 'notready'"></span>
										<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg></span>
									</h3>
								</div>
								<div class="card-footer">
									<div class="stats" *ngIf="picking.inpickcount">
										<a (click)="toggleFlip('inpick','')"><i class="material-icons">visibility</i>
										 View Now</a>
									</div>
									<div class="stats" *ngIf="!picking.inpick">
										<br />
									</div>

								</div>
							</div>
						</div>
						<div class="col-lg-4 col-md-4 col-sm-6">
							<div class="card card-stats">
								<div class="card-header card-header-primary card-header-icon">
									<div class="card-icon">
										<i class="material-icons">hail</i>
									</div>
									<p class="card-category"><b>Partially Fillable</b></p>
									<h3 class="card-title" >
										<span *ngIf="picking">{{ picking.paritalcount }}</span>
										<span *ngIf="!picking">
										<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
										</span>
										<span *ngIf="notready != 'notready'"></span>
										<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg></span>
									</h3>
								</div>
								<div class="card-footer">
									<div class="stats" *ngIf="picking.paritalcount">
										<a (click)="toggleFlip('parital','')"><i class="material-icons">visibility</i>
										 View Now</a>
									</div>
									<div class="stats" *ngIf="!picking.paritalcount">
										<br />
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-4 col-md-4 col-sm-6">
							<div class="card card-stats">
								<div class="card-header card-header-info card-header-icon">
									<div class="card-icon">
										<i class="material-icons">local_shipping</i>
									</div>
									<p class="card-category"><b>Dispatched</b></p>
									<h3 class="card-title" >
										<span *ngIf="picking">{{ picking.dispatchedcount }}</span>
										<span *ngIf="!picking">
										<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
										</span>
										<span *ngIf="notready != 'notready'"></span>
										<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg></span>
									</h3>
								</div>
								<div class="card-footer">
									<div class="stats" *ngIf="picking.dispatchedcount">
										<a (click)="toggleFlip('dispatched','')"><i class="material-icons">visibility</i>
										 View Now</a>
									</div>
									<div class="stats" *ngIf="!picking.dispatchedcount">
										<br />
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-4 col-md-4 col-sm-6">
							<div class="card card-stats">
								<div class="card-header card-header-info card-header-icon">
									<div class="card-icon">
										<i class="material-icons">receipt</i>
									</div>
									<p class="card-category"><b>Need Invoiced</b></p>
									<h3 class="card-title" >
										<span *ngIf="picking">{{ picking.billablecount }}</span>
										<span *ngIf="!picking">
										<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
										</span>
										<span *ngIf="notready != 'notready'"></span>
										<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg></span>
									</h3>
								</div>
								<div class="card-footer">
									<div class="stats" *ngIf="picking.billablecount">
										<a (click)="toggleFlip('billable','')"><i class="material-icons">visibility</i>
										 View Now</a>
									</div>
									<div class="stats" *ngIf="!picking.billablecount">
										<br />
									</div>
								</div>
							</div>
						</div>


						<div class="col-lg-4 col-md-4 col-sm-6">
							<div class="card card-stats">
								<div class="card-header card-header-rose card-header-icon">
									<div class="card-icon">
										<i class="material-icons">notifications_paused</i>
									</div>
									<p class="card-category"><b>Snoozed</b></p>

									<h3 class="card-title" >

										<span *ngIf="picking">{{ picking.snoozedcount }}</span>
										<span *ngIf="!picking">
										<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
										</span>
										<span *ngIf="notready != 'notready'"></span>
										<span *ngIf="notready == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg></span>
									</h3>
								</div>
								<div class="card-footer">
									<div class="stats" *ngIf="picking.snoozedcount">
										<a (click)="toggleFlip('snoozed','')"><i class="material-icons">visibility</i>
										 View Now</a>
									</div>
									<div class="stats" *ngIf="!picking.snoozedcount">
										<br />
									</div>

								</div>
							</div>
						</div>

						<div class="col-12">
							<div class="row">
								<div class="col-md-6">
									<app-order-search></app-order-search>
								</div>
								<div class="col-md-6">
									<app-item-search></app-item-search>
								</div>
							</div>
						</div>
					</div>
					<div class="row tp-box__side tp-box__front text-center" *ngIf="!picking">
						<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
