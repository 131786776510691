import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, FormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router } from '@angular/router';

import { InventoryService } from '../../../services/inventory.service';
import { GlobalsService } from '../../../services/globals.service';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { Customer } from '../../../classes/customer';
import { Location } from '@angular/common'
import { BarcodeScannerLivestreamComponent } from 'ngx-barcode-scanner';
import { MatStepper } from '@angular/material/stepper';
import { MatInput } from '@angular/material/input';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { exit } from 'process';
//getting tired of focus

@Component({
	selector: 'app-bin-item-scan',
	templateUrl: './bin-item-scan.component.html',
	styleUrls: ['./bin-item-scan.component.scss']
})

export class BinItemScanComponent implements OnInit {

	@ViewChild(BarcodeScannerLivestreamComponent)


		itemInputElement!: ElementRef<HTMLInputElement>;
	binInputElement!: ElementRef<HTMLInputElement>;

	@ViewChild('stepper') private stepper: MatStepper;
	@ViewChild('bin') binFl: ElementRef;
	@ViewChild('searchInput') searchFl: ElementRef;

	barecodeScanner: BarcodeScannerLivestreamComponent;
	barcodeValue;

	@Input() bins;
	//@Input() bins;
	//@Output() binupdate;s

	exitingitems: any = false;
	binGroup: UntypedFormGroup;
	itemGroup:UntypedFormGroup;
	moveQtyArray:any = [];
	inventorysearching: any = false;
	searching = false;
	allitems: any = [];
	allbins: any = [];
	filteredItems: any = [];
	storagebin: any = '';


	selected_items: any = [];
	filtered_items: any = [];
	tobins: any = [];
	alltobins: any = [];
	filteredOptions: any = [];
	affected: any = false;
	neworcurrent = '';
	sending = false;
	onhandonly = false;
	camera_started = false;
	noresults = false;

	searchstring ='';
	lastsearch = '';
	added: any = false;
	step = 0;
	newbin: boolean = false;
	config: any = false;
	verifiedbins: any = [];
	dataReady: boolean = false;


	constructor(private inventoryService: InventoryService, private renderer: Renderer2,private fb: UntypedFormBuilder, private globalSearchService: GlobalSearchService) { }

	ngOnInit(): void {


		this.inventoryService.getVerifiedBinListing().subscribe((res: any)=>{
			this.verifiedbins = res;
		})

		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})

		this.binGroup = this.fb.group({
			bin: ['', [Validators.required]],
		});

		this.itemGroup = this.fb.group({
			searchInput: [''],
		});

		this.itemGroup.get('searchInput').valueChanges.subscribe(newValue => {
			if(newValue == null){
				return;
			}
			if(newValue != '') {
				this.itemSearch(newValue.toUpperCase())
			} else {
				newValue = '';
			}
		});

	}


	itemSearchClick(e: any) {
		e.preventDefault();
		//this.searchFl.nativeElement.focus();
		//this.itemGroup.get('searchInput').setValue('');
	}

	addingItems() {

		// 		let exists = this.bins.filter(bin =>{
		// 			return bin.location.toUpperCase() == this.binGroup.get('bin').value.toUpperCase()
		// 		})
		// 		var pass = true;
		// 		if(!(exists.length)) {
		// 			pass = confirm('Create a new Bin: ' + this.binGroup.get('bin').value.toUpperCase() + '?')
		//
		// 		}

		//		if(pass) {
		this.binGroup.get('bin').setValue(this.binGroup.get('bin').value.toUpperCase())
		this.itemGroup.get('searchInput').setValue('');
		setTimeout(() => (this.searchFl.nativeElement.focus()), 300);
		// } else {
		// 	this.step = 0;
		// 	setTimeout(() => (this.stepper.reset()), 300);
		// }

	}

	toggleCamera() {
		this.camera_started = (this.camera_started) ? false: true;
	}

	barcodeScan() {

	}

	itemSearch(event: any) {

		this.affected = false;
		this.searching = true;
		this.exitingitems = false;
		this.added = false;

		if(this.inventorysearching) {
			this.inventorysearching.unsubscribe()
		}

		this.lastsearch = event;

		this.inventorysearching = this.inventoryService.itemCleanSearchScan(event).subscribe((items: any) => {
			// do not include already selected items
			this.noresults = false;

			const exists = this.tobins.map((binitem: any) => {
				return binitem.stockid
			});

			if(items.length == 0) {
				this.lastsearch = event;
				this.noresults = true;
				this.itemGroup.get('searchInput').setValue('');
			}

			this.exitingitems = items.filter((item:any) => {
				return exists.includes(item.stockid)
			});

			items = items.filter((item:any) => {
				return !exists.includes(item.stockid)
			});

			if(items.length == 1) {
				this.itemGroup.get('searchInput').setValue('');
				this.addSelectedItem(items[0]);
				this.noresults = false;
			}

			if(exists.length == 1) {
				this.itemGroup.get('searchInput').setValue('');
				this.noresults = false;
			}

			this.allitems = items
			this.filtered_items = items

			if(this.onhandonly) {
				this.filtered_items = items.filter((item:any) => {
					return parseFloat(item.qoh) > 0
				});
			}

			this.searching = false;
		});

		//this.renderer.selectRootElement('#search').focus();
		//this.searchElement.nativeElement.focus();
	}

	addSelectedItem(item: any) {

		if(this.tobins.indexOf(item) < 0) {
			this.affected = false;
			const index = this.filtered_items.indexOf(item)
			this.filtered_items.splice(index, 1);
			this.tobins.push(item)
			this.alltobins.push(item)

			const lookup = item.stockid + '' + item.bin;
			this.moveQtyArray[lookup] = item;
			this.moveQtyArray[lookup].moveQty = item.qoh;

			this.added = item;
			this.tobins.sort((a, b) => (a.stockid > b.stockid) ? 1 : (a.stockid === b.stockid) ? ((a.description > b.description) ? 1 : -1) : -1 )
		}

	}

	updateMoveQty(item:any, event: any){
		const lookup = item.stockid + '' + item.bin;
		this.moveQtyArray[lookup].moveQty = event.value;
	}

	removeSelectedItem(item: any) {
		this.added = false;
		this.affected = false;
		let index =this.tobins.indexOf(item);
		this.tobins.splice(index, 1);

		index =this.moveQtyArray.indexOf(item.stockid);
		this.moveQtyArray.splice(index, 1);

		index = this.alltobins.indexOf(item)
		this.alltobins.splice(index, 1);

		if(this.filtered_items.indexOf(item) < 0) {
			this.filtered_items.push(item)
			this.filtered_items.sort((a, b) => (a.stockid > b.stockid) ? 1 : (a.stockid === b.stockid) ? ((a.description > b.description) ? 1 : -1) : -1 )
		}
	}

	isNotItem(event:any) {

		if(this.inventorysearching) {
			this.inventorysearching.unsubscribe();
		}
		this.inventorysearching = this.inventoryService.itemCleanSearchScan(this.binGroup.get('bin').value).subscribe((items: any) => {
			if(items.length === '1') {
				event.preventDefault();
				alert('Scan Bin, Not Item');
				this.step = 0;
				return false;
			} else {
				return true;
			}
		});
	}

	isNewBin() {
		var exists;
		if(this.config.env.package == 'tires'){
			exists = this.verifiedbins.filter(bin => {
				if(this.binGroup.get('bin').value && this.binGroup.get('bin').value != '') {
					return bin.bin.toUpperCase().trim() == this.binGroup.get('bin').value.toUpperCase().trim()
				}
				return false;
			});
		}else{
			exists = this.bins.filter(bin => {
				if(this.binGroup.get('bin').value && this.binGroup.get('bin').value != '') {
					return bin.location.toUpperCase().trim() == this.binGroup.get('bin').value.toUpperCase().trim()
				}
				return false;
			});
		}

		if(this.config.env.package == 'tires'){
			//stopping from getting to next step if bin doesn't exist - joe req 11/5
			exists.length ? this.newbin = false : this.newbin = true;
			if(this.binGroup.get('bin').value == ''){
				this.newbin = false;
			}
		}else{
			this.newbin = false;
		}



		return (exists && exists.length > 0) ? false : true;
	}

	newOrCurrentBin() {

		const exists = this.bins.filter(bin => {
			return bin.location == this.binGroup.get('bin').value
		})

		if(this.binGroup.get('bin').value == '') {
			this.neworcurrent = '';
		} else {
			this.neworcurrent = (exists && exists.length > 0) ? 'Merge to ' +this.binGroup.get('bin').value : 'Creating New Bin: '+this.binGroup.get('bin').value
		}

		return this.neworcurrent;
	}

	updateItems(qohonly: any) {

		this.filtered_items = this.allitems;
		if(qohonly.checked) {
			this.onhandonly = true;
			this.filtered_items = this.allitems.filter((item:any) => {
				return parseFloat(item.qoh) > 0
			});
		} else {
			this.onhandonly = false;
		}
	}

	moveItems() {
		//tobin is container of items being moved

		let proceed = true;

		if(this.isNewBin()) {
			proceed = confirm('Create New Bin?');
		}

		if(proceed) {
			this.sending = true;
			this.inventoryService.moveItemsToBin(this.tobins, this.binGroup.get('bin').value).subscribe((results: any) => {

				this.sending = false;

				const allbins = this.tobins.map( bin => {
					return bin.stockid
				});

				if(results.success) {

					const message = 'Bin(s): '+allbins.join(',')+' Merged To '+this.binGroup.get('bin').value;

					this.globalSearchService.showNotification(message, 'success', 'bottom', 'left')

					this.affected = false;
					this.added = false;

					this.allbins = results.bins
					this.filteredOptions = results.bins;

					this.selected_items = [];
					this.filtered_items = [];

					this.tobins = [];
					this.alltobins = [];
					this.filtered_items = [];
					this.moveQtyArray = [];

					this.stepper.reset();
					this.binGroup.get('bin').setValue('');

					setTimeout(()=>{
						this.binFl.nativeElement.focus();
					},1500);
					//this.stepper.reset();

				} else {

					const message = 'MERGE FAILED! Bin(s): '+allbins.join(',')+' To '+this.binGroup.get('bin').value;
					alert(message);
				}

			});
		}
	}

	filterItem(input: any, term: any , keys: any ){
		const result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) &&	new RegExp(term, 'gi').test(item[key])));
		return result
	}

	ngAfterViewInit() {
		this.binFl.nativeElement.focus();
	}

	onValueChanges(result) {
		this.barcodeValue = result.codeResult.code;
		this.itemSearch(this.barcodeValue);

	}

	onStarted(started) {

	}

}
