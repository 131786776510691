<ng-container *ngIf="!sending">
	<div class="row">
		<div class="col-4">
			<button class="btn btn-success" (click)="exportXls();"><i class="fa fa-file-excel-o"></i></button>
			&nbsp;&nbsp;

			<!-- <button class="btn btn-danger" (click)="exportPdf();"><i class="fa fa-file-pdf-o"></i></button> -->
			<a href="{{this.arlink.apiServer.baseUrl}}{{this.arlink.apiServer.arTrialLink}}{{summary_details.value}}" class="btn btn-danger" ><i class="fa fa-file-pdf-o"></i></a>
		</div>
		<div class="col-md-6">&nbsp;&nbsp;</div>
		<div class="col-2 text-right">

			<mat-form-field appearance="standard" class="">
				<mat-label>Summary Or Detail</mat-label>
				<mat-select [formControl]="summary_details">
					<mat-option *ngFor="let k of summary_or_details" [value]="k.value">
						{{ k.label }}
					</mat-option>
				</mat-select>
			</mat-form-field>

		</div>
	</div>
</ng-container>
<div class="listitem row border-bottom pl-1 pr-1 ml-1 mr-1">
	<ng-container *ngIf="sending">
		<svg class="spinner" width="30px" height="30px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
			<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
		</svg>
	</ng-container>
	<div class="table">
		<input type="text" class="form-control text-right" (input)="onInput($event.target.value)" placeholder="Search..." />
		<ng-container>
			<div class="table table-fluid w-100" #printTable>
			  <table class="table table-fluid w-100">
				<thead class="thead-dark">
				  <tr>
					<th class="sticky-header">Name</th>
					<th class="sticky-header">Customer #</th>
					<th class="sticky-header">Customer Type</th>
					<th class="sticky-header">Terms</th>
					<th class="sticky-header">Credit</th>
					<th class="sticky-header">Status</th>
					<th class="sticky-header text-right">Balance</th>
					<th class="sticky-header text-right">Future</th>
					<th class="sticky-header text-right">Current</th>
					<th class="sticky-header text-right">30 Day</th>
					<th class="sticky-header text-right">60 Day</th>
					<th class="sticky-header text-right">90 Day</th>
					<th class="sticky-header text-right">120 Day</th>
				  </tr>
				</thead>
				<tbody>
				  <ng-container *ngFor="let result of data; let i = index">
					<tr>
					  <td>{{ result.name }}</td>
					  <td><a [routerLink]="'/customers/view/'+result.debtorno">{{ result.debtorno }}</a></td>
					  <td>{{ result.typename }}</td>
					  <td>{{ result.terms }}</td>
					  <td>{{ result.creditlimit | currency }}</td>
					  <td>{{ result.reasondescription }}</td>
					  <td class="text-right">{{ result.balance | currency }}</td>
					  <td class="text-right">{{ result.future | currency }}</td>
					  <td class="text-right">{{ result.current | currency }}</td>
					  <td class="text-right">{{ result.due | currency }}</td>
					  <td class="text-right">{{ result.overdue | currency }}</td>
					  <td class="text-right">{{ result.overdue1 | currency }}</td>
					  <td class="text-right">{{ result.overdue2 | currency }}</td>
					</tr>
					<ng-container *ngIf="!summary_details.value && result.details">
					  <tr>
						<td colspan="13" class="p-0">
						  <div class="card mb-3 ">
							<div class="card-header donotprint">
							  <strong>Details for {{ result.name }}</strong>
							</div>
							<div class="card-body p-0">
							  <table class="table table-bordered table-striped table-hover mb-0">
								<thead class="thead-light">
								  <tr>
									<th></th>
									<th>Type</th>
									<th>Invoice#</th>
									<th>Order#</th>
									<th>Date</th>
									<th>Terms</th>
									<th class="text-right">Balance</th>
									<th class="text-right">Future</th>
									<th class="text-right">Current</th>
									<th class="text-right">30 Day</th>
									<th class="text-right">60 Day</th>
									<th class="text-right">90 Day</th>
									<th class="text-right">120 Day</th>
								  </tr>
								</thead>
								<tbody>
									<ng-container *ngFor="let det of result.details; let b = index">
								  <tr *ngIf="det.name">
										<th></th>
										<td>{{ det.typename }}</td>
										<td>{{ det.transno }}</td>
										<td>{{ det.order_ }}</td>
										<td>{{ det.transdate | date:'short' }}</td>
										<td>{{ det.payterms }}</td>
										<td class="text-right">{{ det.balance | currency }}</td>
										<td class="text-right">{{ det.future | currency }}</td>
										<td class="text-right">{{ det.current | currency }}</td>
										<td class="text-right">{{ det.due | currency }}</td>
										<td class="text-right">{{ det.overdue | currency }}</td>
										<td class="text-right">{{ det.overdue1 | currency }}</td>
										<td class="text-right">{{ det.overdue2 | currency }}</td>

								  </tr>
								  </ng-container>
								</tbody>
							  </table>
							</div>
						  </div>
						</td>
					  </tr>
					</ng-container>
				  </ng-container>
				  <tr>
					<td>TOTAL</td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td class="text-right">{{ total_balance | currency }}</td>
					<td class="text-right">{{ total_future | currency }}</td>
					<td class="text-right">{{ total_current | currency }}</td>
					<td class="text-right">{{ total_due | currency }}</td>
					<td class="text-right">{{ total_overdue | currency }}</td>
					<td class="text-right">{{ total_overdue1 | currency }}</td>
					<td class="text-right">{{ total_overdue2 | currency }}</td>
				  </tr>
				</tbody>
			  </table>
			</div>


			<ng-container *ngIf="paginator_show">
				<mat-paginator [pageSizeOptions]="[50, 100, 500]" showFirstLastButtons aria-label="select page" [hidePageSize]="paginator_show" [pageSize]="pageSize">
				</mat-paginator>
			</ng-container>
		</ng-container>
	</div>
</div>
