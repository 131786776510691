<ng-template #movementdetails let-modal>
  <div class="modal-header">

	<h4 class="modal-title" id="modal-title">Bin Movements</h4>
	<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
  </div>
  <div class="modal-body">
	  <div class="mb-3">
			<button mat-icon-button color="warn" aria-label="Reverse Bin Movements" (click)="reverseMovements(bindetails.header.binmoveid)">
				<mat-icon>restore</mat-icon>
			</button>
		  	<table class="table table-sm">
		  	<thead>
			  	<tr>
				  	<th>Date</th>
				  	<th>Item</th>
				  	<th>From</th>
					<th>To</th>
				  	<th>Type</th>
			  	<tr>
		  	</thead>
		  	<tbody>
			  	<tr *ngFor="let h of bindetails.details">
					<td>{{ h.date_created }}</td>
					<td>{{ h.stockid }}</td>
					<td>{{ h.from }}</td>
					<td>{{ h.to }}</td>
					<td>{{ h.type }}</td>
			  	</tr>
		  	</tbody>
		  </table>
	  </div>
  </div>
  <div class="modal-footer">
  </div>
</ng-template>
<div class="table-container">
			<div class="row">
			    <div class="col-sm-6 form-inline">
					<mat-form-field appearance="outline">
						<mat-label class="text-right">Search By Items/Order</mat-label>
						<input tabindex="0" matInput value="" [formControl]="stockIdCtrl" class="text-right" (input)="getHistory()">
						<mat-hint>Search for an Item/Invoice</mat-hint>
					</mat-form-field>
				</div>
				<div class="col-sm-6 form-inline">
					<form class="form-inline my-2 my-lg-0">
						<mat-form-field appearance="outline">
							<mat-label>Movement Dates</mat-label>
							<mat-date-range-input [rangePicker]="picker">
								<input matStartDate [formControl]="fromdateCtrl" placeholder="Start date">
								<input matEndDate [formControl]="todateCtrl" placeholder="End date">
							</mat-date-range-input>
							<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-date-range-picker #picker></mat-date-range-picker>
						</mat-form-field>
						<button mat-raised-button color="accent" class="ml-2" (click)="getHistory()">
							<mat-icon>refresh</mat-icon>
						</button>
					</form>
				</div>
			</div>
	<table class="table table-sm table-fixed table-sticky">
		<thead>
			<tr>
				<th></th>
				<th>Date</th>
				<th>Description</th>
				<th>To</th>
				<th>Type</th>
			<tr>
		</thead>
		<tbody>
			<tr *ngFor="let h of history">
				<td>



					<button mat-icon-button color="normal" aria-label="view"
					(click)="openModal(movementdetails , h.binmoveid)"
					>
						<mat-icon>visibility</mat-icon>
					</button>
					<!--
						<button mat-icon-button color="warn" aria-label="Reverse Bin Movements" (click)="reverseMovements(h.binmoveid)">
						<mat-icon>restore</mat-icon>
					</button>
					-->
				</td>

				<td>{{ h.date_created }}</td>
				<td>{{ h.description }}</td>
				<td>{{ getToBin(h.variables)}}</td>
				<td>{{ h.type | uppercase }}</td>

			</tr>
		</tbody>
	</table>
</div>
