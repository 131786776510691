import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl,Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { GlobalsService } from '../../../services/globals.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { InventoryService } from '../../../services/inventory.service';

@Component({
	selector: 'app-inventory-edit-prices',
	templateUrl: './inventory-edit-prices.component.html',
	styleUrls: ['./inventory-edit-prices.component.scss'],
	animations: [
		trigger('grow', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: '0',width: '0', overflow: 'hidden' }),
				animate(200, style({ height: '*' , width: '*' })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate(300, style({ height: 0, width: 0 , overflow: 'hidden' })),
				animate('300ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class InventoryEditPricesComponent implements OnInit {
	@Input() item;
	@Output() editing = new EventEmitter<boolean>();
	@Output() newitemdata = new EventEmitter<boolean>();
	itemForm: any = false;
	properties: any = [];

	itemMaterialCost =  new UntypedFormControl('');
	itemLastCost =  new UntypedFormControl('');
	itemActualCost =  new UntypedFormControl('');

	changedate: any= false;
	today = new Date();
	todate = new Date(new Date().setDate(this.today.getMonth() + 1));

	fromdateRef =  new UntypedFormControl(this.today);
	todateRef =  new UntypedFormControl(this.todate);
	pricereference:any = [];
	currencies: any = [];
	config: any = false;
	constructor(public globalSearchService: GlobalSearchService, private datePipe: DatePipe,private route: ActivatedRoute, private fb: UntypedFormBuilder,private inventoryService: InventoryService, public router: Router, private location: Location, private globalsService: GlobalsService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})

		this.globalSearchService.currencies.subscribe(r => {
			this.currencies = r;
		})

	}

	onSubmit() {
		this.pricereference = (this.item) ? this.item.prices : []

		const data = {
			prices: this.item.prices,
			material: this.itemMaterialCost.value,
			lastcost: this.itemLastCost.value,
			actualcost: this.itemActualCost.value
		}

		this.inventoryService.updatePrices(data).subscribe( (result: any) => {
			this.newitemdata.emit(result);
		});

	}

	ngOnInit(): void {
		const details = this.item

		this.itemMaterialCost.setValue(this.financial(this.item.item.cost));
		this.itemLastCost.setValue(this.financial(this.item.item.lastcost));
		this.itemActualCost.setValue(this.financial(this.item.item.actualcost));

	}

	updatePriceDateFrom(value: any , price: any) {
		// Convert the input value to a Date object
		const dateValue = new Date(value);
		const year = dateValue.getFullYear();
		const month = ('0' + (dateValue.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based month
		const day = ('0' + dateValue.getDate()).slice(-2);
		const formattedDate = `${year}-${month}-${day}`;
		price.startdate = formattedDate;
	}

	updatePriceDateTo(value: any , price: any) {
		// Convert the input value to a Date object
		const dateValue = new Date(value);
		const year = dateValue.getFullYear();
		const month = ('0' + (dateValue.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based month
		const day = ('0' + dateValue.getDate()).slice(-2);
		const formattedDate = `${year}-${month}-${day}`;
		price.enddate = formattedDate;
	}

	removePrice(price) {
		this.inventoryService.removePrice(price).subscribe( (result: any) => {
			if(result.success) {
				const index = this.item.prices.indexOf(price);

				if (index !== -1) {
					// Remove the price from the array using splice
					this.item.prices.splice(index, 1);
				}
			}
		});
	}

	updatePrice(value: any , price : any) {
		price.price = value;
	}


	updateCurrency(value: any , price : any) {
		price.currency = value;
	}

	ngAfterViewInit(): void {
		this.globalsService.getItemSetup().subscribe( (results: any) => {
			this.properties = results
		});
	}

	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}

	toggleDates(price: any) {
		const index = this.item.prices.indexOf(price);

		this.item.prices = this.item.prices.map((p, i) => ({
			...p,
			editing: i === index ? !p.editing : false
		}));

		if (this.item.prices[index].editing) {
			// If price is being edited, update the date inputs with the price's start and end dates
			const { startdate, enddate } = this.item.prices[index];

			const fromDate = new Date(startdate);
			const toDate = new Date(enddate);

			const fromOffset = fromDate.getTimezoneOffset();
			const toOffset = toDate.getTimezoneOffset();
			//javascript bad dates
			fromDate.setMinutes(fromDate.getMinutes() + fromOffset);
			toDate.setMinutes(toDate.getMinutes() + toOffset);

			this.fromdateRef.setValue(fromDate);
			this.todateRef.setValue(toDate);
		} else {
			// If not being edited, reset the date inputs
			this.fromdateRef.reset();
			this.todateRef.reset();
		}
	}




	formatDate(date: string): string {
	  return this.datePipe.transform(date, 'shortDate');
	}
}
