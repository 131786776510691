<ng-template #groupEdit let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Product Group</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-header text-center">
					<h4 class="card-title text-center"></h4>
				</div>
				<div class="card-body text-center">
					<div class="row">
						<div class="col-12">
							<div class="row">
								<div class="col-md-6">
									<mat-form-field appearance="outline">
										<mat-label>Name</mat-label>
										<input type="text" class="text-left " matInput [(value)]="editinggroup.name" (change)="updateGroup('name',$event.target.value)" />
									</mat-form-field>
								</div>
								<div class="col-md-6">
									<mat-form-field appearance="outline">
										<mat-label>Description</mat-label>
										<input type="text" class="text-left " matInput [(value)]="editinggroup.description" (change)="updateGroup('desc',$event.target.value)" />
									</mat-form-field>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<mat-form-field appearance="outline">
										<mat-label>Find My</mat-label>
										<input type="text" class="text-left " matInput [(value)]="editinggroup.findmy" (change)="updateGroup('findmy',$event.target.value)" />
									</mat-form-field>
								</div>
								<div class="col-md-6">
									<mat-form-field appearance="outline">
										<mat-label>Group Type</mat-label>
										<mat-select type="text" class="text-left " [(value)]="editinggroup.grouptype" (selectionChange)="updateGroup('grouptype',$event)" >
										<mat-option value="1" >
												Short List
										</mat-option>
										<mat-option value="2" >
												Long List (trigger bottom of product list)
										</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</div>
							<div class="row eduform">
			<div class="col-6">
			Image<br>
			<img *ngIf="editinggroup.thumb" [src]="editinggroup.thumb" style="width:100%;height:auto;">
			</div>
			<div class="col-6" style="height:250px">
			<ngx-dropzone [multiple]="false" (change)="onSelect(editinggroup.group_id, $event)">
			<ngx-dropzone-label>
			<div class="col-12">
				DROP FILE HERE OR CLICK TO UPLOAD
			</div>
			</ngx-dropzone-label>
			</ngx-dropzone>
			</div>
			</div>
							<div class="row">
								<div class="col-12">
									<h3>Group Products</h3>
									<hr>
								</div>
							</div>
							<div class="row">
					<div class="col-2">
					<mat-form-field appearance="outline">
					<mat-label>Brands</mat-label>
					<mat-select multiple="true" [formControl]="brandCtrl">
					<mat-option *ngFor="let r of brands;" [value]="r.manufacturer_id">{{r.name}}</mat-option>
					</mat-select>
					</mat-form-field>			
							</div>
							<div class="col-2">
							<mat-form-field appearance="outline">
					<mat-label>Categories</mat-label>
					<mat-select multiple="true" [formControl]="categoryCtrl">
					<mat-option *ngFor="let r of categories;" [value]="r.categoryid">{{r.categorydescription}}</mat-option>
					</mat-select>
					</mat-form-field>
							</div>
							<div class="col-2">
							<mat-form-field appearance="outline">
					<mat-label>Product Lines</mat-label>
					<mat-select multiple="true" [formControl]="prodCtrl">
					<mat-option *ngFor="let r of productlines;" [value]="r.line_field">{{r.line_description}}</mat-option>
					</mat-select>
					</mat-form-field>
							</div>
							<div class="col-3">
							<mat-form-field appearance="outline">
					<mat-label>Description</mat-label>
					<input matInput [formControl]="descCtrl" class="margin-top">
					</mat-form-field>
							</div>
							<div class="col-3" style="margin-top:17px;">
							<button mat-raised-button color="accent" (click)="getProducts()"><mat-icon>search</mat-icon> Search</button>
							</div>
						</div>
					<div class="row" *ngIf="picklist.length > 0">
					<div class="col-12">
					<table class="table table-striped">
					<thead><tr><th class="text-center" colspan="3">Search Results</th></tr><tr><th>Item Number</th><th>Description</th><th></th></tr></thead><tbody>
					<tr *ngFor="let p of picklist;">
						<td>{{p.stockid}}</td>
						<td>{{p.description}}</td>
						<td><button mat-stroked-button color="danger" (click)="excludeProduct(p)"><mat-icon>delete</mat-icon> Exclude</button></td>
					</tr>
					</tbody>
					</table>
					</div>
					<div class="col-6"></div><div class="col-6"><button class="btn btn-secondary" (click)="addProducts()"><mat-icon>add</mat-icon> Add Products</button></div>
					</div>
					<div class="row">
					<div class="col-12">
					<table class="table table-striped">
					<thead><tr><th class="text-center" colspan="3">Included Products</th></tr><tr><th>Item Number</th><th>Description</th><th></th></tr></thead><tbody>
					<tr *ngFor="let p of editinggroup.members;">
						<td>{{p.stockid}}</td>
						<td>{{p.description}}</td>
						<td><button mat-stroked-button color="danger" (click)="removeMember(p)"><mat-icon>remove</mat-icon> Remove</button></td>
					</tr>
					</tbody>
					</table>
					</div>
					</div>
							<button mat-stroked-button color="success" aria-label="" (click)="saveGroup()" class="mt-2">
								<mat-icon>save</mat-icon> Save Group Changes
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-5">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Product
							Groups</h4>
						<p class="card-category"></p>
					</div>
					<div class="card-body">
						<button mat-stroked-button color="success" aria-label="" (click)="addCustomerGroup()" class="mt-2 mb-2">
							<mat-icon>add</mat-icon> Add Product Group
						</button>

						<div class="container-fluid">
							<mat-form-field appearance="outline">
								<mat-label>Search</mat-label>
								<input type="text" class="form-control text-right" matInput [formControl]="cgroupsearch" />
							</mat-form-field>

							<div class="table ov">
								<div class="text-right">
									<span *ngIf="!searchingcgroups.length"> No Groups</span> Results ({{searchingcgroups.length}})
								</div>
								<table class="table table-sm table-striped table-hover table-bordered table-fixed ov" *ngIf="searchingcgroups.length">
									<thead class="header">
										<tr>
											<th class="header" scope="col">Group ID</th>
											<th class="header" scope="col">Description</th>
											<th class="header text-center" scope="col">
												Members</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let p of searchingcgroups">
											<td>
												<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More">
													<mat-icon>more_vert</mat-icon>
												</button>
												<mat-menu #menu="matMenu">
													<button mat-menu-item (click)="editGroup(p)"  class="btn-sm">
														<mat-icon>edit</mat-icon> Edit Group
													</button>
													<button mat-menu-item color="danger" aria-label="" (click)="removeGroup(p)" class="btn-sm">
														<mat-icon>remove</mat-icon> Remove
													</button>

												</mat-menu>
												{{ p.name }}
											</td>
											<td> {{ p.description }}</td>
											<td class="text-center">{{ p.members.length }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>