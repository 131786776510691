<div class="container-fluid tp-box" [@flipState]="flip" >
	<div class="row tp-box__side tp-box__back" *ngIf="flip == 'active'">
		<div class="card" >
			<div class="card-header card-header-primary card-header-icon">
				<div class="card-icon pointer" (click)="toggleFlip(false, '')">
					<i class="material-icons">login</i>
				</div>
				<h3 class="card-title">{{ title }} </h3>
			</div>
			<div class="card-body">
				<div class="table" #print_table>
					<ng-container >
						<button class="btn btn-success" (click)="exportXls();"><i class="fa fa-file-excel-o"></i></button>
						&nbsp;&nbsp;
						<button class="btn btn-danger" (click)="exportPdf();"><i class="fa fa-file-pdf-o"></i></button>
					</ng-container>
					<table class="table table-sm table-fixed">
						<thead>
						<tr>
							<th>Vendor#</th>
							<th>GRN#</th>
							<th>Order#</th>
							<th>PO Item</th>
							<th>Date</th>
							<th>Item</th>
							<th>Desc</th>
							<th class="text-right">Price</th>
							<th class="text-center">Ordered</th>
							<th class="text-center">Received</th>
							<th class="text-center">Invoiced</th>
							<th class="text-right">Line Total</th>
						</tr>
						</thead>
						<tbody>
						<tr *ngFor="let d of data">
							<td>{{ d.supplierid }}</td>
							<td>{{ d.grnno}}</td>
							<td>{{ d.orderno}}</td>
							<td>{{ d.podetailitem}}</td>
							<td>{{ d.deliverydate }}</td>
							<td>{{ d.itemcode }}</td>
							<td>{{ d.itemdescription }}</td>
							<td class="text-right">{{ d.unitprice | currency}}</td>
							<td class="text-center">{{ d.quantityord}}</td>
							<td class="text-center">{{ d.qtyrecd}}</td>
							<td class="text-center">{{ d.quantityinv}}</td>
							<td class="text-right">{{ d.unitprice * d.quantityord | currency}}</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	<div class="row tp-box__side tp-box__front" id="frontside" [ngClass]="flip == 'inactive' ? '' : 'd-none'">
		<div class="card card-stats">
			<div class="card-header card-header-primary card-header-icon">
				<div class="card-icon pointer" (click)="toggleFlip(data, title)">
					<i class="material-icons">login</i>
				</div>
				<p class="card-category">{{ title }}</p>
				<h3 class="card-title">
					<span *ngIf="data">
						{{ data.length }}
					</span>
					<span *ngIf="!data">
						<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</span>
				</h3>
				<small>
					<p class="card-category text-info text-justify mt-3 text-right">
						&nbsp;
					</p>
				</small>
			</div>
			<div class="card-footer">
				<div class="stats pointer">
					<a (click)="toggleFlip(data, title)">
						<i class="material-icons">content_copy</i>
						View Now</a>
				</div>
				<br />
			</div>
		</div>
	</div>
</div>
