<div class="row">
	<div class="col-md-6">
		<button mat-raised-button color="accent" (click)="addNote()">Add Note</button>
	</div>
	<div class="col-md-6">
		<button mat-raised-button class="btn btn-primary" (click)="onSubmit()">Save</button>
	</div>
</div>
<ul @grow class="list-group list-group-flush p-0 m-0">
	<li class="list-group-item border-bottom mt-0 mb-0 pt-1 pb-1" *ngFor="let note of vendor.notes ">
		<div class="row">
			<div class="col-md-4">
				<mat-form-field appearance="outline" class="" >
					<mat-label>Note Type</mat-label>
					<mat-select
					required
					([value])="note.note_code">
					<mat-option *ngFor="let type of notetypes"
					[value]="type">
					{{type}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-8">
				<mat-form-field class="full-width" appearance="outline">
					<mat-label>Note</mat-label>
					<input matInput required placeholder="Note" (name="note[{{note.noteid}}]" value="{{ note.note }}" (input)="updateNote($event, note)" >
					 <mat-icon matSuffix (click)="removeNote(note.noteid)">remove</mat-icon>
				 </mat-form-field>
			</div>
		</div>
	</li>
</ul>
