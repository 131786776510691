import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';

import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { InventoryService } from '../../services/inventory.service';
import { PrintService } from '../../services/print.service'
import { ReportsService } from '../../services/reports.service'

import * as FileSaver from 'file-saver';

@Component({
	selector: 'app-cid-report',
	templateUrl: './cid-report.component.html',
	styleUrls: ['./cid-report.component.scss']
})
export class CidReportComponent implements OnInit {
	@Input() customer = null;
	@Input() vendor = null;
	@Input() menuapp = true;
	@ViewChild('print_table') printtable: ElementRef;
	searchForm = null;
	categories: any = [];
	productlines: any = [];
	filename: any = 'CustItemSalesDet_';
	brands: any = [];
	user: any = [];
	results: any = [];
	locations: any = [];
	salespeople: any = [];
	sending: any = false;
	loading = false;
	reportResults: any = [];
	sortedReportResults: any = [];
	sortByColumn: string;
	color: any = 'blue';
	sortDirection: string;
	totals: any = [];


	constructor(private reportService: ReportsService, private printService: PrintService, private fb: UntypedFormBuilder, private inventoryService: InventoryService, private globalSearchService: GlobalSearchService) {}
	
	ngOnInit(): void {

		this.globalSearchService.user.subscribe(result => {
			this.user = result;
		});
		this.searchForm = this.fb.group({
			'customer': this.customer,
			'vendor': this.vendor,
			'brand': [],
			'productline': [],
			'salesperson': [],
			'location': [],
			'category': [],
			'startdate': ['', Validators.required],
			'enddate':['', Validators.required],
			'keywords':'',
			'customersearch': '',
		});
		this.inventoryService.getItemCategories().subscribe(c => {
			this.categories = c
		});
		this.inventoryService.getItemProductlines().subscribe(p => {
			this.productlines = p
		});

		this.inventoryService.getBrands().subscribe((results: any) => {
			this.brands = results;
		});
		this.reportService.getSalesPeople().subscribe((results: any) => {
			this.salespeople = results;
		});
		
		this.color = this.globalSearchService.getColor();
		this.reportService.getBranches().subscribe((results: any) => {
			this.locations = results;
		});

	}
	
	cidreport(){
		this.loading = true;
		this.inventoryService.cidReport(this.searchForm.value).subscribe((p: any) => {
			this.loading = false;
			this.reportResults = p.results;
			this.sortedReportResults= p.results;
			this.totals = p.totals;
		});
	
	}
	
	exportPdf() {
		const now = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename + (now.getMonth() + 1) + now.getDate() + now.getFullYear(),
			title: `Customer Item Sales Detail - Created @ ${now.toLocaleString()}`,
			subtitle: this.getReportSubtitle()
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	exportXls() {
		const now = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename + (now.getMonth() + 1) + now.getDate() + now.getFullYear(),
			title: `Customer Item Sales Detail - Created @ ${now.toLocaleString()}`,
			subtitle: this.getReportSubtitle()
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

	getReportSubtitle() {
		let out = '';

		if (this.customer) {

			out += ' for Customer ' + this.customer.customer.brname;

		} else if (this.vendor) {
			out += ' for Vendor ' + this.vendor.name;
		}
		out +=  ' ' + this.searchForm.get('startdate').value.toISOString().split('T')[0] + ' - ' + this.searchForm.get('enddate').value.toISOString().split('T')[0];
		
		return out;
	}
	sortBy(property: string): void {
		if (this.sortByColumn === property) {
			// Toggle sort direction if the same column is clicked
			this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
		} else {
			// Default to ascending order when a new column is clicked
			this.sortDirection = 'asc';
			this.sortByColumn = property;
		}

		this.sortedReportResults.sort((a, b) => {
			// Check if the property values are numeric
			const isNumeric = !isNaN(parseFloat(a[property])) && isFinite(a[property]) && !isNaN(parseFloat(b[property])) && isFinite(b[property]);

			if (isNumeric) {
				// Sort as numbers if the property values are numeric
				const numA = parseFloat(a[property]);
				const numB = parseFloat(b[property]);
				return this.sortDirection === 'asc' ? numA - numB : numB - numA;
			} else {
				// Sort as strings if the property values are not numeric
				const comparison = a[property].localeCompare(b[property]);
				return this.sortDirection === 'asc' ? comparison : -comparison;
			}
		});
	}


	sortAscending(data: any[], property: string): any[] {
		return data.sort((a, b) => {
			if (a[property] < b[property]) {return -1;}
			if (a[property] > b[property]) {return 1;}
			return 0;
		});
	}

	sortDescending(data: any[], property: string): any[] {
		return data.sort((a, b) => {
			if (a[property] > b[property]) {return -1;}
			if (a[property] < b[property]) {return 1;}
			return 0;
		});
	}
}
