import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router } from '@angular/router';

import { VendorService } from '../../../services/vendor.service';
import { GlobalsService } from '../../../services/globals.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { Customer } from '../../../classes/customer';
import { Location } from '@angular/common'

@Component({
	selector: 'app-vendor-notes',
	templateUrl: './vendor-notes.component.html',
	styleUrls: ['./vendor-notes.component.scss'],
	animations: [
		trigger('grow', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: '0',width: '0' }),
				animate(200, style({ height: '*' , width: '*' })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate(300, style({ height: 0, width: 0 })),
				animate('300ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class VendorNotesComponent implements OnInit {
	@Input() vendor;
	@Output() editing = new EventEmitter<boolean>();
	@Output() newvendordata = new EventEmitter<boolean>();
	user: any = []
	noteFrom: UntypedFormGroup;
	notes: any = []
	newnote_id = 0;
	default_type ='INTERNAL';
	notetypes = ['ORDER','AR','INTERNAL','GENERAL'];
	removals: any = [];

	constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder,private vendorService: VendorService, public router: Router, private location: Location, private globalsService: GlobalsService, public globalSearchService: GlobalSearchService) {

	}

	addNote() {
		this.newnote_id = this.newnote_id - 1;
		const newNote ={
			supplierid: this.vendor.supplierid,
			noteid: this.newnote_id,
			note: '',
			note_code: this.default_type,
		}
		this.vendor.notes.push(newNote)
	}

	updateNote(event:any, note: any) {
		const index = this.vendor.notes.indexOf(note);
		this.vendor.notes[index].note = event.target.value;
	}

	removeNote(id: any) {
		this.removals.push(id)
		this.vendor.notes = this.vendor.notes.filter( (note) => {
			return note.noteid != id;
		});
	}

	onSubmit() {
		this.vendorService.updateVendorNotes({ notes: this.vendor.notes, removals: this.removals, supplierid: this.vendor.supplierid }).subscribe((results: any) => {
			this.vendor.notes = results
			this.newvendordata.emit(this.vendor);
			this.editing.emit(false);
		})
	}

	back(): void {
		this.editing.emit(false);
	}

	ngOnInit(): void {

		this.notes = this.vendor.notes;
		if(!this.notes) {
			this.vendor.notes = [];
			this.addNote();
		}

	}
	ngAfterViewInit() {
	}
}
