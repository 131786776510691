<ng-template #addPaymentTypeEle let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Edit</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-header text-center">
					<h4 class="card-title text-center"></h4>
				</div>
				<div class="card-body text-center">
					<div class="row">
						<div class="col-12">
							<form [formGroup]="paymentTermsForm">
								<mat-form-field appearance="outline">
									<mat-label>Name</mat-label>
									<input type="text" class="text-let m-0 p-0" matInput formControlName="paymentname" />
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Sort</mat-label>
									<input type="text" class="text-let m-0 p-0" matInput formControlName="sort_order" />
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Short Code</mat-label>
									<input type="text" class="text-let m-0 p-0" matInput formControlName="payment_code" />
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Day End Label</mat-label>
									<input type="text" class="text-let m-0 p-0" matInput formControlName="dayendlabel" />
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Icon</mat-label>
									<input type="text" class="text-let m-0 p-0" matInput formControlName="fa_icon" />
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Requires Extra Input (e.g. check#)</mat-label>
									<mat-select formControlName="additionalrequired">
										<mat-option [value]="'0'">N</mat-option>
										<mat-option [value]="'1'">Y</mat-option>
									</mat-select>
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Receipt Type</mat-label>
									<input type="text" class="text-let m-0 p-0" matInput formControlName="receipttype" />
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Open Cash Drawer</mat-label>
									<mat-select formControlName="opencashdrawer">
										<mat-option [value]="'0'">N</mat-option>
										<mat-option [value]="'1'">Y</mat-option>
									</mat-select>
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>POS Available</mat-label>
									<mat-select formControlName="pos_enabled">
										<mat-option [value]="'0'">N</mat-option>
										<mat-option [value]="'1'">Y</mat-option>
									</mat-select>
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Refunds Allowed</mat-label>
									<mat-select formControlName="refunds">
										<mat-option [value]="'0'">N</mat-option>
										<mat-option [value]="'1'">Y</mat-option>
									</mat-select>
								</mat-form-field>
								<button mat-stroked-button color="success" aria-label="" (click)="savePaymentType()" class="mt-2">
									<mat-icon>save</mat-icon> Save
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<button mat-stroked-button color="success" aria-label="" (click)="addPaymentType()" class="mt-2">
	<mat-icon>add</mat-icon> Add Payment Type
</button>

<mat-progress-bar mode="indeterminate" color="accent" *ngIf="isLoading"></mat-progress-bar>

<table class="table table-sm mt-3">
	<tr>
		<th></th>
		<th>ID</th>
		<th>Name</th>
		<th>POS</th>
		<th>Refund</th>
		<th></th>
	</tr>
	<tr *ngFor="let type of paytypes">
		<td>
			<button mat-icon-button color="warn" aria-label="delete" class="small-font" (click)="removePaymentType(type)">
				<mat-icon>remove</mat-icon>
			</button>
			<button mat-icon-button color="accent" aria-label="Edit" class="small-font" (click)="editPaymentType(type)">
				<mat-icon>edit</mat-icon>
			</button>
		</td>
		<td>{{ type.paymentid }}</td>
		<td>{{ type.paymentname }}</td>
		<td>{{ yesNoValue(type.pos_enabled) }}</td>
		<td>{{ yesNoValue(type.refunds) }}</td>
		<td></td>
	</tr>
</table>
