<!-- sales-report.component.html -->
<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title">
							<i class="material-icons" (click)="back()">arrow_left</i>
							<i class="fa fa-bar-chart"></i> JPMS - PM Sales by Customer
						</h4>
						<p class="card-category text-right">
							<i class="fa fa-bar-chart"></i>
						</p>
					</div>
					<div class="card-body">
						<form [formGroup]="form" (ngSubmit)="onSubmit()">
							<div class="row">
								<div class="col-2">
									<mat-form-field appearance="fill" class="text-right">
										<mat-label>Date Range</mat-label>
										<mat-date-range-input [rangePicker]="picker">
											<input matStartDate [formControl]="fromdateCtrl" placeholder="Start date">
											<input matEndDate [formControl]="todateCtrl" placeholder="End date">
										</mat-date-range-input>
										<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
										<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
										<mat-date-range-picker #picker></mat-date-range-picker>
									</mat-form-field>
								</div>
								<div class="col-10">
									<div class="form-group">
										<label for="perks">Perks Program:</label>
										<br />
										<div *ngFor="let perk of perks" class="perk-checkbox">
											<mat-checkbox [formControlName]="'perk_' + perk.clubcode">{{ perk.club }}</mat-checkbox>
										</div>
									</div>
								</div>
							</div>


							<div class="form-group">
								<button type="submit" mat-raised-button color="primary" *ngIf="!sending">
									<mat-icon>refresh</mat-icon>
								</button>

								&nbsp;&nbsp;
								<button class="btn btn-success btn-sm" (click)="$event.preventDefault();exportXls()" *ngIf="dataSource">
									<i class="fa fa-file-excel-o"></i> XLS
								</button>
								&nbsp;&nbsp;
								<button class="btn btn-danger btn-sm" (click)="$event.preventDefault();exportPdf()" *ngIf="dataSource">
									<i class="fa fa-file-pdf-o"></i> PDF
								</button>

								<span *ngIf="sending">
									<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
							</div>
						</form>

						<div class="table-responsive" #print_table>
							<h5 *ngIf="reportData && !reportData.length">No results</h5>
							<mat-paginator [pageSizeOptions]="items_per_page" [pageSize]="pagesizedefault" *ngIf="reportData && reportData.length" showFirstLastButtons aria-label="select page"></mat-paginator>	
							<table class="table table-bordered table-striped table-hover"  *ngIf="reportData && reportData.length">
								<thead class="thead-dark">
									<tr>
										<th class="text-center">Customer</th>
										<th class="text-center">Perks Program</th>
										<th class="text-center">Customer Name</th>
										<th class="text-center">Customer Address</th>
										<th class="text-center">City</th>
										<th class="text-center">State</th>
										<th class="text-center">Zip Code</th>
										<th class="text-right">AWA</th>
										<th class="text-right">BRX</th>
										<th class="text-right">CXG</th>
										<th class="text-right">CVX</th>
										<th class="text-right">JCB</th>
										<th class="text-right">MIT</th>
										<th class="text-right">MVR</th>
										<th class="text-right">PC</th>
										<th class="text-right">PCM</th>
										<th class="text-right">PDM</th>
										<th class="text-right">PMM</th>
										<th class="text-right">PMT</th>
										<th class="text-right">PNW</th>
										<th class="text-right">PNT</th>
										<th class="text-right">PSP</th>
										<th class="text-right">XGC</th>
										<th class="text-right">TTC</th>
										<th class="text-right">TTHMP</th>
										<th class="text-right">Total</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let element of dataObs | async;let i = index">
										<td class="text-center">{{ element.customer }}</td>
										<td class="text-center">{{ element.perkprogram }}</td>
										<td class="text-center">{{ element.customername }}</td>
										<td class="text-center">{{ element.customeraddress }}</td>
										<td class="text-center">{{ element.city }}</td>
										<td class="text-center">{{ element.state }}</td>
										<td class="text-center">{{ element.zipcode }}</td>
										<td class="text-right">{{ element.awapuhi | currency }}</td>
										<td class="text-right">{{ element.brx | currency }}</td>
										<td class="text-right">{{ element.crema | currency }}</td>
										<td class="text-right">{{ element.cvx | currency }}</td>
										<td class="text-right">{{ element.jcb | currency }}</td>
										<td class="text-right">{{ element.mitch | currency }}</td>
										<td class="text-right">{{ element.mvr | currency }}</td>
										<td class="text-right">{{ element.color | currency }}</td>
										<td class="text-right">{{ element.colorstuff | currency }}</td>
										<td class="text-right">{{ element.demi | currency }}</td>
										<td class="text-right">{{ element.pmitchell | currency }}</td>
										<td class="text-right">{{ element.expresstools | currency }}</td>
										<td class="text-right">{{ element.neurowetline | currency }}</td>
										<td class="text-right">{{ element.pntline | currency }}</td>
										<td class="text-right">{{ element.pspline | currency }}</td>
										<td class="text-right">{{ element.xgcolor | currency }}</td>
										<td class="text-right">{{ element.ttc | currency }}</td>
										<td class="text-right">{{ element.tthmp | currency }}</td>
										<td class="text-right">{{ calculateLineTotal(element) | currency }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
