import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from '../services/globalsearchservice.service';
@Injectable({
	providedIn: 'root'
})

export class VehicleService {

	key = 'UET6DFMPAXW7BKCB';
	config: any;
	package: any = '';
	constructor(private globalSearchService: GlobalSearchService, private http: HttpClient, private httpClient: HttpClient, private _configSvc: RuntimeConfigLoaderService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})
	}

	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};

	public getVehYears() {

		const method = 'vehicles/years';
		return this.http.get(this.setEndPoint(method));
	}
	public getVehicle(data) {
		const method = 'vehicles/fetch';
		return this.http.post(this.setEndPoint(method), { id: data }, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public getVehicles(data) {
		const method = 'vehicles/search';
		return this.http.post(this.setEndPoint(method), { debtor: data, branch: data }, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public deleteVehicle(data) {
		const method = 'vehicles/remove';
		return this.http.post(this.setEndPoint(method), { id: data }, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public updateVehicle(data) {
		const method = 'vehicles/update';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public getVehMakes(data) {
		const method = 'vehicles/makes';
		return this.http.post(this.setEndPoint(method), { year: data }, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public getVehModels(data, data2) {
		const method = 'vehicles/models';
		return this.http.post(this.setEndPoint(method), { year: data, make: data2 }, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public getVehOpts(data, data2, data3) {
		const method = 'vehicles/options';
		return this.http.post(this.setEndPoint(method), { year: data, make: data2, model: data3 }, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	public getVehFitment(data, data2, data3, data4) {
		const method = 'vehicles/fitment';
		return this.http.post(this.setEndPoint(method), { year: data, make: data2, model: data3, model_type: data4 }, this.httpOptions).pipe(
			catchError(this.handleError('company', data))
		);
	}
	private setEndPoint(method: any) {
		return this.getApi() + method + this.getApiKey()
	}

	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getApiKey() {
		return '&key=' + this.config.apiCredentials.clientId;
	}

	public getKey() {
		return '&apikey=' + this.key;
	}

	private handleError<T>(operation = 'operation', result?: T) {

		return (error: any): Observable<T> => {

			if (error instanceof HttpErrorResponse && error.status === 401) {
				window.location.href = '/#/auth/logout';
			}

			return error;
		};
	}
}