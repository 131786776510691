import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup,ReactiveFormsModule } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { TaxesService } from '../../../services/taxes.service';

@Component({
	selector: 'app-tax-rates',
	templateUrl: './tax-rates.component.html',
	styleUrls: ['./tax-rates.component.scss']
})

//tax rates is not nec.
export class TaxRatesComponent implements OnInit {
	data: any = []
	constructor(private taxesService: TaxesService) { }

	ngOnInit(): void {
		this.taxesService.getTaxRates().subscribe((results: any) => {
			this.data = results;
		})

	}
	yesno(input: string): string {
		return (input == '0') ? 'No' : 'Yes';
	}
}
