import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform,AfterViewInit, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { InventoryService } from '../../../services/inventory.service';

import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';

import { GlobalsService } from '../../../services/globals.service';

import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
	selector: 'app-prices',
	templateUrl: './prices.component.html',
	styleUrls: ['./prices.component.scss']
})

export class PricesComponent implements OnInit {
	@Input() itemdetails: any
	@Input() clonestockid: any
	@Input() purchasedata: any
	@Output() priceDataUpdate = new EventEmitter<any>();
	@Output() updateing = new EventEmitter<any>();

	user: any = [];

	itemForm: UntypedFormGroup;
	properties: any = false;
	files: File[] = [];
	today = new Date();
	searching: any = false;
	prices: any = [];

	constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute,private inventoryService: InventoryService, private location: Location,public router: Router,public cdr: ChangeDetectorRef,private globalSearchService: GlobalSearchService, private globalsService: GlobalsService) { }

	ngOnInit(): void {

	}

	onSelect(event) {
	  this.files.push(...event.addedFiles);
	}

	onRemove(event) {
	  this.files.splice(this.files.indexOf(event), 1);
	  this.updateing.emit(this.prices);
	}

	setPrice(event: any, price: any) {
		const index = this.prices.indexOf(price)
		this.prices[index].price = event;
		this.updateing.emit(this.prices);
	}

	updatePrices(newValue) {
		this.prices.forEach( p => {
			p.price = newValue;
		});
		this.updateing.emit(this.prices);
	}

	back(): void {
		this.location.back()
	}

	updateItem() {
		this.priceDataUpdate.emit(this.prices);
	}

	ngOnChanges(changes: any) {

		if(changes.itemdetails || changes.purchasedata) {
			this.inventoryService.getProductPriceMarkup(this.itemdetails.lineid,this.purchasedata.price).subscribe( (results: any) => {
				this.prices = results
				this.updateing.emit(this.prices);
			});
		}
	}

	ngAfterViewInit(): void {
		//this.itemdetails.lineid

		this.inventoryService.getProductPriceMarkup(this.itemdetails.lineid,this.purchasedata.price).subscribe( (results: any) => {
			this.prices = results
			this.updateing.emit(this.prices);

		});

		if(this.clonestockid != ''){
		   this.inventoryService.getItem({ stockid: this.clonestockid }).subscribe((results: any) => {
				this.prices = results.prices;
				this.updateing.emit(this.prices);
			});
		}
	}


}
