<div class="col-md-12 card" *ngIf="reportForm && telegram_reports">
	<div class="card-header card-header-primary">
		<div class="row">
			<h4 class="pl-2 ">Summary Reports</h4>
			&nbsp;
			<div *ngIf="reports.selectedOptions.selected[0]?.value" class="ml-auto mr-1 text-right">viewing {{getReportTitle(reports.selectedOptions.selected[0]?.value)}}</div>
		</div>
	</div>
	<div class="row w-100 m-0 p-0" >
		<div class="col-md-4">
			<div class="card telegram-card h-300" [formGroup]="reportForm">
				<mat-selection-list #reports [multiple]="false" class="reportlist" (selectionChange)="runReport($event)" formControlName="report">
					<mat-list-option *ngFor="let report of telegram_reports | keyvalue" [value]="report.value">
						<div class="row justify-content-between m-0 p-0">
							{{ removeSort(report.key) }}
							<ng-container *ngIf="report.value == reports.selectedOptions.selected[0]?.value">
								<mat-icon class="selection-icon right-10">toc</mat-icon>
							</ng-container>
						</div>
					</mat-list-option>
				</mat-selection-list>
			</div>
			<button mat-raised-button *ngIf="reportRollup != false" class="reporttotal" (click)="toggleView()">
				<mat-icon *ngIf="view == 'report'" class="material-symbols-outlined viewIcon">web</mat-icon>
				<mat-icon *ngIf="view == 'pie'" class="material-symbols-outlined viewIcon">pie_chart</mat-icon>
				<span *ngIf="view == 'report'"> Report: </span>
				<span *ngIf="view == 'pie'"> Pie Chart: </span>
			</button>
		</div>

		<div class="col-md-8">
			<!--report table-->
			<div class="card telegram-card overflow-auto ">
				<div class="card-header h-300">
					<div *ngIf="fetchingData" class="reportspinner">
						<svg class="spinner" width="100px" height="100px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
							</circle>
						</svg>
					</div>

					<mat-table [dataSource]="datasource" matSort matSortActive="total" matSortDirection="desc" [hidden]="fetchingData || !reportReady || view == 'pie'">
					  <ng-container *ngFor="let col of activeReport">
						<ng-container matColumnDef="{{col.def}}">
						  <mat-header-cell *matHeaderCellDef mat-sort-header class="text-left"> {{col.header}} </mat-header-cell>
						  <mat-cell *matCellDef="let element" class="text-left">
							<ng-container *ngIf="col.header != 'Sales'">{{element[col.def]}}</ng-container>
							<ng-container *ngIf="col.header == 'Sales'">{{element[col.def] | currency}}</ng-container>
						  </mat-cell>
						</ng-container>
					  </ng-container>
					  <mat-header-row *matHeaderRowDef="activeReport_def; sticky: true" style="min-height: 25px;"></mat-header-row>
					  <mat-row *matRowDef="let row; columns: activeReport_def;"></mat-row>
					</mat-table>


					<div class="col-md-12" [hidden]="view != 'pie' || fetchingData">
						<div class="row" [hidden]="!dataPreferences">
							<div id="chartPreferencesTele" class="ct-chart col-md-7 position-relative h-270 p-0" [hidden]="pieEmpty"></div>
							<div class="col-md-5 h-270 overflow-auto p-0" *ngIf="!pieEmpty">
								<table mat-table [dataSource]="pieData" class="mat-elevation-z8" [hidden]="!reportReady">
									<ng-container matColumnDef="Salesman">
										<th mat-header-cell *matHeaderCellDef> Salesman </th>
										<td mat-cell *matCellDef="let element" style="font-size: 12px;" [ngClass]="element.sales == 0.00 ? 'strikethrough' : ''"> {{element.salesman}} </td>
									</ng-container>

									<ng-container matColumnDef="Sales">
										<th mat-header-cell *matHeaderCellDef> Percentage </th>
										<td mat-cell *matCellDef="let element" style="text-align: end; font-weight: bold;" [ngClass]="element.sales == 0.00 ? 'strikethrough' : ''"> {{element.sales}} %</td>
									</ng-container>

									<tr mat-header-row *matHeaderRowDef="displayedPieColumns"></tr>
									<tr mat-row *matRowDef="let row; columns: displayedPieColumns; let i = index;" (mouseenter) ="mouseEnter(i)" (mouseleave) ="mouseLeave(i)" class="pieChartRow"></tr>
								</table>
							</div>
							<div *ngIf="pieEmpty" class="w-100">
								<h3 style="text-align: center;">Pie chart is empty.</h3>
							</div>
						</div>
					</div>


				</div>
			</div>
			<mat-card-footer class="card negmarg" *ngIf="reportRollup != false">
				<div class="justify-content-between ">
					<div class="row justify-content-between pl-4 pr-4">
						<h4 *ngIf="reportRollup['web']; else titlespace">Web Orders</h4>
						<ng-template #titlespace>&nbsp;</ng-template>
						<h4 *ngIf="reportRollup['store']">Store Orders</h4>
					</div>
					<hr class="mt-0">
					<div class="row justify-content-between pl-4 pr-4">
						<ng-container *ngIf="reportRollup['web']; else spacer;"><span>{{reportRollup['web'].total | currency}} &nbsp; ({{reportRollup['web'].count}})</span></ng-container>
						<ng-template #spacer><span> &nbsp;</span></ng-template>
						<ng-container *ngIf="reportRollup['store']"><span>{{reportRollup['store'].total | currency}} &nbsp; ({{reportRollup['store'].count}})</span></ng-container>
					</div>
				</div>
			</mat-card-footer>
		</div>
	</div>
</div>
