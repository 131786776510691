import { Component,  OnChanges, SimpleChanges, OnInit, Input, AfterViewInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { InventoryService } from '../../services/inventory.service';
import { PurchasingService } from '../../services/purchasing.service';
import { OrdersService } from '../../services/orders.service';
import { OmsService } from '../../services/oms.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { FormBuilder, FormGroup, Validators, UntypedFormControl, UntypedFormGroup, FormControl } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import {Buffer} from 'buffer';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';


@Component({
  selector: 'app-warehouse-finalizerec',
  templateUrl: './warehouse-finalizerec.component.html',
  styleUrls: ['./warehouse-finalizerec.component.scss'],
  animations: [
	  trigger('flipState', [
			state('active', style({
				transform: 'rotate3d(0, 1, 0, 180deg)',
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotateY(179deg)'
			})),
			transition('active => inactive', animate('500ms ease-out')),
			transition('inactive => active', animate('500ms ease-in')),
			transition('inactive => orderlookup', animate('500ms ease-out')),
			transition('orderlookup => inactive', animate('500ms ease-in')),
		]),
  ]
})
export class WarehouseFinalizerecComponent implements OnInit {
	@Input() vendor_selected: any = false;
	today = new Date();
	color: any = 'blue';

	location: any = '';
	locations: any = [];
	suppliers: any = false;
	user: any = [];

	CHAT_ROOM = "OrderBoard";
	sheetForm: FormGroup;
	sending: boolean = false;
	submiting: boolean = false;
	counting_items: any =false;
	fetching: boolean = false;
	flip: string = 'inactive';

	recitems: any = false;
	token: any = false;
	loadingdatasubscription: any = false;
	total_scanned: number = 0;
	discrepancy: string = 'inactive';
	showValidTable: boolean = false;
	validScans: any = [];
	invalidScans: any = [];

	vendor_formgroup: FormControl<any>[] = [];
	vendor_fg: FormGroup;


	unique_vendors: any = false;
	looprequest: boolean;
	discrepancy_vendors: any = false;
	selected_vendors: any = [];
	filtered_recitems: any;


	constructor(private fb: FormBuilder,private route: ActivatedRoute, public router: Router, private modalService: NgbModal, private omsService: OmsService, private globalSearchService: GlobalSearchService, private inventoryService: InventoryService, public ordersService: OrdersService, public purchasingService: PurchasingService) {

		this.globalSearchService.mesagesallowed.next(false);
		this.globalSearchService.user.subscribe((result) => {
			this.user = result;
		});

		this.color = this.globalSearchService.getColor();

		this.globalSearchService.user.subscribe((u: any) => {
			this.user = u;
			this.location = u.user.defaultlocation.loccode
		});

	}

	ngOnInit(): void {


		this.globalSearchService.locations.subscribe(async (results: any) => {
			this.locations = results;
		});

		this.purchasingService.getSuppliers('').subscribe(async (results: any) => {
			this.suppliers = results;
			this.sheetForm = this.fb.group({
				keywords: [''],
				location: [this.locations[0].loccode, Validators.required],
				vendor: [this.vendor_selected, Validators.required],
				category: [''],
				lineid: [''],
				stockid: [''],
			});
			this.loadData();
		})

		this.omsService.subscribeToRecScanUpdate((err, data) => {
			//reload data on incoming message - currently double sending
			this.loadData()
			this.filterScans();
		});


	}


	uniqueVendors(datain: any) {
		this.unique_vendors = []
		datain.forEach((vendor: any, index) => {
			this.unique_vendors[index] = vendor.vendor;
		});
	}

	vendorTotals(vendor: any) {

		let vendor_total= this.recitems.reduce(function(accumulator: number, items: any) {
			var addon = 0;
			if(items.in_out == 0){
				addon = parseFloat(items.qty);
			};
			return accumulator + addon;
		}, 0);

		return vendor_total;
	}

	loadData() {

		if(this.loadingdatasubscription) {
			//prevent double calls cancel the firstone
			this.loadingdatasubscription.unsubscribe();
		}

		this.loadingdatasubscription = this.purchasingService.getRecs(this.sheetForm.value).subscribe( r => {
			this.recitems = r;
		});
	}

	resetSheets(event: any) {
		if(event) {
			this.counting_items = false;
			this.fetching = false;
			this.flip = 'inactive';
		}
	}

	pdf() {

	}

	keywordItemSearch() {

	}

	keywordSearch() {

	}

	selectVendor(supplier: any) {
		this.vendor_selected = supplier.supplierid
		this.sheetForm.get('vendor').setValue(supplier.supplierid)
	}

	selectCategory(value: any) {
		this.sheetForm.get('category').setValue(value.categoryid)
	}

	selectProductLine(value: any) {
		this.sheetForm.get('lineid').setValue(value.line_field)
	}


	setQty(event: any, item: any) {
		item.counted = parseFloat(event.target.value);
	}

	saveCounts() {

	}

	finalizeRec() {
		let data = {}
		this.submiting = true;
		let input = this.filtered_recitems.length > 0 ? this.filtered_recitems : this.recitems;
		this.purchasingService.finalizeRec(input).subscribe(async (results: any) => {
			this.submiting = false;
			this.loadData();
			this.omsService.sendRecScanUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
			this.discrepancyFlip();
		})
	}

	removeItem(item: any) {
		let index = this.recitems.indexOf(item);
		let data = {}
		this.purchasingService.removeRec(this.recitems[index]).subscribe(async (results: any) => {
			this.recitems.splice(index,1)
			this.omsService.sendRecScanUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
			this.filterScans();
		})
	}


	updateBin(value: any, item: any) {
		let index = this.recitems.indexOf(item);
		this.recitems[index].bin= value;
	}

	totalScannedRec() {
		this.total_scanned = this.recitems.reduce(function(accumulator: number, items: any) {
			var addon = 0;
			if(items.in_out == 0){
				addon = parseFloat(items.qty);
			};
			return accumulator + addon;
		}, 0);

		return this.total_scanned;
	}

	totalScannedSend() {
		this.total_scanned = this.recitems.reduce(function(accumulator: number, items: any) {
			var addon = 0;
			if(items.in_out == 1){
				addon = parseFloat(items.qty);
			};
			return accumulator + addon;
		}, 0);
		return this.total_scanned;
	}

	totalReqPo() {
		let poreq = this.recitems.reduce(function(accumulator: number, items: any) {
			var addon = 0;
			if(items.in_out == 0){
				if(parseFloat(items.qty) > parseFloat(items.qop)) {
					addon = parseFloat(items.qty) - parseFloat(items.qop);
				}
			}
			return accumulator + addon;
		}, 0);

		return poreq;
	}

	totalReqRga() {
		let rga_req = this.recitems.reduce(function(accumulator: number, items: any) {
			var addon = 0;
			if(items.in_out == 1){
				if(parseFloat(items.qty) > parseFloat(items.qor)) {
					addon = parseFloat(items.qty) - parseFloat(items.qor);
				}
			}
			return accumulator + addon;
		}, 0);
		return rga_req;
	}

	totalForPo() {
		let poreq = this.recitems.reduce(function(accumulator: number, items: any) {
			var addon = 0;
			if(items.in_out == 0){
				if(parseFloat(items.qty) <= parseFloat(items.qop)) {
					addon = parseFloat(items.qty);
				}
			}
			return accumulator + addon;
		}, 0);
		return poreq;
	}

	totalForRga() {
		let poreq = this.recitems.reduce(function(accumulator: number, items: any) {
			var addon = 0;
			if(items.in_out == 1){
				if(parseFloat(items.qty) <= parseFloat(items.qor)) {
					addon = parseFloat(items.qty);
				}
			}
			return accumulator + addon;
		}, 0);
		return poreq;
	}

	totalCommited() {
		let commited = this.recitems.reduce(function(accumulator: number, items: any) {
			var addon = 0;
			if(parseFloat(items.qos)) {
				addon = parseFloat(items.qos);
			}
			return accumulator + addon;
		}, 0);
		return commited;
	}

	updateRec(value: any, item: any) {
		let index = this.recitems.indexOf(item);

		if(value < 0 ){
			value = 0;
		}
		item.qty = value;

		let data = {};
		this.purchasingService.updateScanned(item).subscribe((updated_item: any)  =>{
			this.omsService.sendRecScanUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
			this.globalSearchService.showNotification('Saved','success','top','right');
		});

	}

	saveRec(item:any) {
		let index = this.recitems.indexOf(item);
		this.purchasingService.updateRec(this.recitems[index]).subscribe(async (results: any) => {
			this.recitems = results
			this.globalSearchService.showNotification('Saved','success','top','center');
		})
	}

	identify(index, item) {
		return item.line;
	}

	sanitizeNumber(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(0));
	}

	toggleFlip() {
		this.flip = (this.flip =='inactive') ? 'active' : 'inactive';
		if(this.flip == 'active') {

		}
	}

	ngOnChanges(changes: any) {
	}

	discrepancyFlip(){
		this.discrepancy = (this.discrepancy =='inactive') ? 'active' : 'inactive';
		if(this.discrepancy == 'active'){
			this.selected_vendors = [];
			this.discrepancy_vendors = [];
			this.filtered_recitems = [];
			this.filterScans()
			this.genFormcontrols();
		}
	}

	itemTotalReceived(item:any) {
		let total = this.recitems.reduce(function(accumulator: number, items: any) {
			var addon = 0;
			if(items.stockid == item.stockid){
				addon += parseFloat(items.qty);
			}
			return accumulator + addon;
		}, 0);

		return total;
	}

	filterScans(vendor_recs?){
		if(vendor_recs == '' || vendor_recs == undefined){
			this.recitems.forEach(item => {
				(item.qty == item.qop || item.qty == item.qor || item.total_need_filled == this.itemTotalReceived(item)) ?
					(this.validScans.includes(item) ? '' : this.validScans.push(item)) :
					(this.invalidScans.includes(item) ? '' : this.invalidScans.push(item));
			});

			if(this.recitems.length != (this.validScans.length + this.invalidScans.length)){
				this.validScans = [];
				this.invalidScans = [];
				this.filterScans();
			}

		} else {
			this.validScans = [];
			this.invalidScans = [];
			vendor_recs.forEach(item => {
				//partial scans showing as invalid on split
				(item.qty == item.qop || item.qty == item.qor || item.total_need_filled == this.itemTotalReceived(item)) ?
					(this.validScans.includes(item) ? '' : this.validScans.push(item)) :
					(this.invalidScans.includes(item) ? '' : this.invalidScans.push(item));
			});
			if(vendor_recs.length != (this.validScans.length + this.invalidScans.length)){
				this.validScans = [];
				this.invalidScans = [];
				this.filterScans(vendor_recs);
			}
		}
	}


	genFormcontrols(){
		this.discrepancy_vendors = [];
		(this.recitems).forEach(element => {
			this.discrepancy_vendors.includes(element.vendor) ? '' : this.discrepancy_vendors.push(element.vendor);
		});

		if(this.discrepancy_vendors.length > 1){
			this.selected_vendors = [];

			this.vendor_fg = this.fb.group({});

			(this.discrepancy_vendors).forEach(vendor => {
				this.vendor_fg.addControl(vendor, new UntypedFormControl(true))
				this.selected_vendors.push(vendor);

				this.vendor_fg.get(vendor).valueChanges.subscribe((newV)=>{
					newV == false ? this.selected_vendors.splice(this.selected_vendors.indexOf(vendor), 1) : this.selected_vendors.push(vendor);
					this.filterScansByVendor(this.selected_vendors);
				})
			});
		} else{
			this.selected_vendors = [];
			this.filtered_recitems = [];
		}
	}

	filterScansByVendor(vendors){
		this.filtered_recitems = this.recitems;
		this.filtered_recitems = this.filtered_recitems.filter((scan) => vendors.includes(scan.vendor));
		this.filterScans(this.filtered_recitems);
	}

	toggleValidTable(){
		this.showValidTable = this.showValidTable == true ? false : true;
	}

	mailDiscrepancy(){
		// return;
		// this.filterScans();

		let index = 1;
		let table = '';
		let tableHead =
			// '<!DOCTYPE html>' +
			// 	'<html lang="en">' +
			// 		'<head>' +
			// 			'<title>Discrepancy Report</title>' +
			// 		'</head>' +
			// 		'<body>' +
						'<table>' +
							'<thead>' +
								'<tr>' +
									'<th style="text-align: center; background-color: black; color: white;">INDEX</th>'+
									'<th style="text-align: center; background-color: black; color: white;">ITEM</th>' +
									'<th style="text-align: center; background-color: black; color: white;">VENDOR</th>' +
									'<th style="text-align: center; background-color: black; color: white;">BIN</th>' +
									'<th style="text-align: center; background-color: black; color: white;">QTY_RECEIVED</th>' +
									'<th style="text-align: center; background-color: black; color: white;">MIN</th>' +
									'<th style="text-align: center; background-color: black; color: white;">MAX</th>' +
									'<th style="text-align: center; background-color: black; color: white;">QOH</th>' +
									'<th style="text-align: center; background-color: black; color: white;">QOO</th>' +
									'<th style="text-align: center; background-color: black; color: white;">REMAINING</th>' +
									'<th style="text-align: center; background-color: black; color: white;">QOR</th>' +
									'<th style="text-align: center; background-color: black; color: white;">COMMITED</th>' +
									'<th style="text-align: center; background-color: black; color: white;">SCAN_STATUS</th>' +
								'</tr>' +
							'</thead>';



		let bodies = [this.validScans, this.invalidScans];
		let tableBody = "";
		let validScansBody = "";
		let invalidScansBody = "";
		let bodyIndex = 0;
		let red = "#df232378";
		let pink = "#ff59ba78";
		let yellow = "#c7ff0078";
		let blue = "#444ee478";
		let orange = "#fc7e0998";
		let green = "#38ff0078";
		let purple = "#750dbbae";
		let legend = {
			rec:[],
			send:[],
			shared:[],
		};

		bodies.forEach(scans => {
			// tableBody = "<tbody>";
			if(scans.length > 0){
				scans.forEach(item => {

					let status = {isSet: false, value: ''};
					if(item.in_out == 0){
						if(item.qty != item.qop){
							if(item.total_rec == item.total_need_filled) {
								status.value = "OK";
								status.isSet = true;
							}
							if (item.qop == 0 || item.qop == null){
								status.value = "NO PO";
								status.isSet = true;
							}

							if (!status.isSet && (item.qop > item.qty) && !(item.total_rec == item.total_need_filled)){
								status.value = "Partial";
								status.isSet = true;
							}

							if (!status.isSet && (item.qop < item.total_rec)){
								status.value = "Over Received";
								status.isSet = true;
							}

							if(item.total_rec == item.total_need_filled) {
								status.value = "OK";
								status.isSet = true;
							}

						} else {
							status.value = 'OK';
						}
					} else{
						if(item.qty != item.qor){
							if (item.qor == 0 || item.qor == null){
								status.value = "NO RGA";
								status.isSet = true;
							}
							if (!status.isSet && (item.qor > item.qty)){
								status.value = "Partial Return";
								status.isSet = true;
							}
							if (!status.isSet && (item.qor < item.qty)){
								status.value = "Over Return";
								status.isSet = true;
							}
						}else{
							status.value = 'OK';
						}
					}

					switch(status.value){
						case 'NO PO':
							tableBody += `<tr style="background-color: ${red};">`;
							legend.rec['NO PO'] = red;
							break;
						case 'NO RGA':
							tableBody += `<tr style="background-color: ${pink};">`;
							legend.send['NO RGA'] = pink;
							break;
						case 'Partial Return':
							tableBody += `<tr style="background-color:  ${yellow};">`;
							legend.shared['Partial'] = yellow;
							break;
						case 'Over Return':
							tableBody += `<tr style="background-color: ${blue};">`;
							legend.send['Over Return'] = blue;
							break;
						case 'Partial':
							tableBody += `<tr style="background-color: ${yellow};">`;
							legend.shared['Partial'] = yellow;
							break;
						case 'Over Received':
							tableBody += `<tr style="background-color: ${orange};">`;
							legend.rec['Over Received'] = orange;
							break;
						default:
							if(status.value == 'OK'){
								if(item.in_out == 0){
									tableBody += `<tr style="background-color: ${green};">`;
									legend.rec['OK (REC)'] = green;
								} else{
									tableBody += `<tr style="background-color: ${purple};">`;
									legend.send['OK (SEND)'] = purple;
								}
							}
						break;
					}


					tableBody += '<td style="text-align: center;">' + index + "</td>";

					if(item.stockid === 'MST'){
						tableBody += '<td style="text-align: center;">' + '<a href="https://clevehilltire.com/v8/#/inventory/view/' + item.stockid + '">'+item.stockid+"</a>"+" (#" + item.ponumber+ " )</td>";
					} else {
						tableBody += '<td style="text-align: center;">' + '<a href="https://clevehilltire.com/v8/#/inventory/view/' + item.stockid + '">'+item.stockid+"</a></td>";
					}


					tableBody += '<td style="text-align: center;">' + item.vendor.toString() + "</td>";
					tableBody += '<td style="text-align: center;">' + item.bin.toString() + "</td>";
					tableBody += '<td style="text-align: center;">' + item.qty.toString() + "</td>";

					let min = item.minmax.minimum !== undefined && item.minmax.minimum !== null ? item.minmax.minimum.toString() : "-";
					let max = item.minmax.maximum !== undefined && item.minmax.maximum !== null ? item.minmax.maximum.toString() : "-";
					tableBody += '<td style="text-align: center;">' + min + "</td>";
					tableBody += '<td style="text-align: center;">' + max + "</td>";

					let onhand = item.qoh !== null ? item.qoh.toString() : "0";
					tableBody += '<td style="text-align: center;">' + onhand + "</td>";
					tableBody += '<td style="text-align: center;">' + item.qop.toString() + "</td>";

					let remaining = parseInt(item.qop) != 0 ? (item.qop - item.qty).toString() : "-";
					tableBody += '<td style="text-align: center;">' + remaining + "</td>";

					let rma = item.qor == null ? "0" : item.qor.toString();
					tableBody += '<td style="text-align: center;">' + rma + "</td>";

					tableBody += '<td style="text-align: center;">' + item.qos.toString() + "</td>";
					tableBody += '<td style="text-align: center;">' + status.value.toString() + "</td></tr>";

					index++;

				});
			}

			(bodyIndex == 0) ? validScansBody = tableBody: invalidScansBody = tableBody;
			tableBody = '';
			bodyIndex++;
			});



		let combinedBody =  '<tbody>' + validScansBody + invalidScansBody + '</tbody></table>';
		let validTable = (validScansBody.length > 0 ) ? tableHead + "<tbody>" + validScansBody + "</tbody></table>" : "";
		let invalidTable = (invalidScansBody.length > 0 ) ? tableHead + "<tbody>" + invalidScansBody + "</tbody></table>" : "";
		let combinedTable = tableHead + combinedBody;



		let overallInformation = {
			"Scan Amount" : this.totalScannedRec(),
			"Total Received" : this.totalScannedRec(),
			"On PO" : this.totalForPo(),
			"Requires PO" : this.totalReqPo(),
			"Total Sent" : this.totalScannedSend(),
			"On RGA" : this.totalForRga(),
			"Requires RGA" : this.totalReqRga(),
		}


		let info_html = "<ul>";
		Object.entries(overallInformation).forEach(([key, value]) => {
			info_html+= "<li>" + key.toString() + ": " + value.toString() + "</li>";
		});
		info_html+="</ul>";



		let legend_html = "<ul>";

		Object.entries(legend.rec).forEach(([Key, Value]) =>{
			legend_html += `<li style='background: ${Value};'>` + Key + "</li>";
		});

		Object.entries(legend.send).forEach(([Key, Value]) =>{
			legend_html += `<li style='background: ${Value};'>` + Key + "</li>";
		});

		Object.entries(legend.shared).forEach(([Key, Value]) =>{
			legend_html += `<li style='background: ${Value};'>` + Key + "</li>";
		});

		legend_html+= "</ul>";


		let data = {
				invalid_html: 	Buffer.from(invalidTable.toString(),'utf8').toString('base64'),
				valid_html: 	Buffer.from(validTable.toString(),'utf8').toString('base64'),
				combined_html: 	Buffer.from(combinedTable.toString(),'utf8').toString('base64'),
				info_html: 		Buffer.from(info_html.toString(),'utf8').toString('base64'),
				legend_html: 	Buffer.from(legend_html.toString(),'utf8').toString('base64'),
			};

		this.purchasingService.mailDiscrepancy(data).subscribe(results => {
			if(results['mail_sent'] == false){
				this.mail_loop(data);
			}
		});
	}

	mail_loop(data){
		this.purchasingService.mailDiscrepancy(data).subscribe(results => {
			if(results['mail_sent'] == false){
				this.mail_loop(data);
			}
		});
	}

	back(): void {
		this.location.back()
	}

}
