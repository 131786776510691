import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router } from '@angular/router';

import { CustomerService } from '../../../services/customer.service';
import { GlobalsService } from '../../../services/globals.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { Customer } from '../../../classes/customer';
import { Location } from '@angular/common'

@Component({
	selector: 'app-customer-contacts',
	templateUrl: './customer-contacts.component.html',
	styleUrls: ['./customer-contacts.component.scss'],
	animations: [
		trigger('grow', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: '0', width: '0' }),
				animate(200, style({ height: '*', width: '*' })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate(300, style({ height: 0, width: 0 })),
				animate('300ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class CustomerContactsComponent implements OnInit {

	@Input() customer;
	@Output() editing = new EventEmitter < boolean > ();
	@Output() newcustomerdata = new EventEmitter < boolean > ();
	contactFrom: UntypedFormGroup;
	contacts: any = []
	newcontact_id = 0;
	default_type = '';
	//notetypes = ['ORDER','AR'];
	removals: any = [];
	emailwarn = false;
	editingcontact = false;
	contactForm: UntypedFormGroup;
	contacttypes: any = false;

	constructor(private globalSearchService: GlobalSearchService, private route: ActivatedRoute, private fb: UntypedFormBuilder, private customerService: CustomerService, public router: Router, private location: Location, private globalsService: GlobalsService,) {}

	ngOnInit(): void {

		this.globalSearchService.all_system_types.subscribe((types:any) => {
			this.contacttypes = types.contacttypes
			this.setForm()
		});

	}

	setForm() {

		this.newcontact_id = this.newcontact_id - 1;

		this.contactForm  = this.fb.group({
			debtorno: [this.customer.debtorno, Validators.required],
			contid: [this.newcontact_id, Validators.required],
			name: ['', Validators.required],
			email: ['', [Validators.required,Validators.email]],
			phone: [''],
			role: [[]],
		});
	}

	editContact(contact: any) {

		this.contactForm  = this.fb.group({
			debtorno: [this.customer.debtorno, Validators.required],
			contid: [contact.id, Validators.required],
			name: [contact.name, Validators.required],
			email: [contact.email, Validators.required],
			phone: [contact.phone],
			role: [contact.role],
		});

		this.editingcontact = true;
	}

	toggleAddContact() {
		this.editingcontact = (this.editingcontact) ? false : true;
	}

	updateContactName(event: any, note: any) {
		const index = this.customer.contacts.indexOf(note);
		this.customer.contacts[index].name = event.target.value;
	}

	updateContactEmail(event: any, note: any) {
		const index = this.customer.contacts.indexOf(note);
		const isok = this.isEmail(event.target.value);
		this.emailwarn = false;
		if (!isok) {
			this.emailwarn = true;
		}

		this.customer.contacts[index].email = event.target.value;
	}

	updateContactPhone(event: any, note: any) {
		const index = this.customer.contacts.indexOf(note);
		this.customer.contacts[index].phone = event.target.value;
	}

	updateContactRole(event: any, note: any) {
		const index = this.customer.contacts.indexOf(note);
		this.customer.contacts[index].role = event.target.value;
	}

	addContact() {
		if(this.contactForm.valid) {

			const exists = this.customer.contacts.filter((contact) => {
				return contact.id == this.contactForm.get('contid').value;
			});

			if(!exists.length) {
				this.customer.contacts.push(this.contactForm.value)
			} else {
				const index =  this.customer.contacts.indexOf(exists[0])
				//bad original design..
				this.customer.contacts[index].id = this.contactForm.get('contid').value;
				this.customer.contacts[index].email = this.contactForm.get('email').value;
				this.customer.contacts[index].name = this.contactForm.get('name').value;
				this.customer.contacts[index].phone = this.contactForm.get('phone').value;
				this.customer.contacts[index].role = this.contactForm.get('role').value;
				//this.customer.contacts[index] = this.contactForm.value
			}

			this.updateData()
			this.editingcontact = false;
			this.setForm();
		} else {
			this.globalSearchService.getFormValidationErrors(this.contactForm);
		}
	}

	removeContact(id: any) {
		this.removals.push(id)
		this.customer.contacts = this.customer.contacts.filter((contact) => {
			return contact.id != id;
		});

		this.updateData()
	}

	isEmail(search: string): boolean {
		let serchfind: boolean;
		const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		serchfind = regexp.test(search);
		return serchfind
	}

	updateData() {
		this.customerService.updateDebtorContacts({ contacts: this.customer.contacts, removals: this.removals }).subscribe((results) => {
			this.customerService.getDebtor(this.customer.debtorno).subscribe(async (results: any) => {
				this.customer = results;
				this.newcustomerdata.emit(results);
			});
		})
	}

	onSubmit() {
		this.customerService.updateDebtorContacts({ contacts: this.customer.contacts, removals: this.removals }).subscribe((results) => {
			this.customerService.getDebtor(this.customer.debtorno).subscribe(async (results: any) => {
				this.customer = results;
				this.newcustomerdata.emit(results);
				this.editing.emit(false);
			});
		})
	}

	close() {
		this.editing.emit(false);
	}

	back(): void {
		//this.editing.emit(false);
	}

	ngAfterViewInit() {}

}
