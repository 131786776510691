<ng-template #bannerEdit let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Edit</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-header text-center">
					<h4 class="card-title">Edit Banner</h4>
				</div>
				<div class="card">
					<div class="card-body">
						<form [formGroup]="bannerForm">
							<div class="row mt-2">
								<div class="col-md-3">
									<mat-form-field appearance="outline">
										<mat-label>Id</mat-label>
										<input matInput required value="" formControlName="banner_id">
									</mat-form-field>
								</div>
								<div class="col-md-3">
									<mat-form-field appearance="outline">
										<mat-label>Name</mat-label>
										<input matInput required value="" formControlName="name">
									</mat-form-field>
								</div>
								<div class="col-md-3">
									<mat-form-field appearance="outline">
										<mat-label>Status</mat-label>
										<mat-select formControlName="status">
											<mat-option *ngFor="let st of statuses" [value]="st.value">
												{{st.label }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-3">
									<button mat-raised-button color="primary" (click)="addBannerImage()">Add Image</button>
								</div>
							</div>

							<table class="table table-striped table-bordered mt-3">
								<thead class="thead-dark">
									<tr>
										<th>Title</th>
										<th>Link</th>
										<th>Sort</th>
										<th>Image</th>
										<th></th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let i of banner.images; let index = index">
										<td>
											<input type="text" [name]="'title' + index" [value]="i.title" (input)="updateTitle($event.target.value, index)" class="form-control">
										</td>
										<td>
											<input type="text" [name]="'link' + index" [value]="i.link" (input)="updateLink($event.target.value, index)" class="form-control">
										</td>
										<td>
											<input type="text" [name]="'sort_order' + index" [value]="i.sort_order" (input)="updateSortOrder($event.target.value, index)" class="form-control">
										</td>
										<td>
											<div class="thumbnail-container">
												<img [src]="i.thumb" *ngIf="i.thumb" class="img-thumbnail thumbnail">
											</div>
										</td>
										<td>
											<div class="row text-center w-100 ml-auto mr-auto ">
												<ngx-dropzone [multiple]="false" class="thumbnail-container" (change)="onSelect($event, index)">
													<ngx-dropzone-label>
														<div class="col-12">
															FILE
														</div>
													</ngx-dropzone-label>

													<ngx-dropzone-preview *ngFor="let f of uploadimages" [removable]="true" (removed)="onRemove(f)" class="thumbnail-container"  (error)="onUploadError($event)" (success)="onUploadSuccess($event)">
													</ngx-dropzone-preview>
												</ngx-dropzone>
											</div>
										</td>
										<td>
											<button mat-icon-button color="warn" (click)="removeBannerImage(index)">
												<mat-icon>delete</mat-icon>
											</button>
										</td>
									</tr>
								</tbody>
							</table>
						</form>
					</div>
				</div>
				<div class="card-footer text-muted text-center">
					<button mat-raised-button color="primary" (click)="saveBanner()">Save Banner</button>
				</div>
			</div>

		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<button mat-stroked-button color="success" aria-label="" (click)="addBanner()" class="mt-2 mb-2">
	<mat-icon>add</mat-icon> Add Banner
</button>

<table class="table table-striped table-hover table-bordered">
	<thead class="thead-dark">
		<tr>
			<th scope="col">Banner ID</th>
			<th scope="col">Description</th>
			<th scope="col">Status</th>
			<th scope="col" class="text-center">Edit/Remove</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let s of banners">
			<td>{{ s.banner_id }}</td>
			<td [innerHTML]="s.name"></td>
			<td>{{ s.status_label }}</td>
			<td class="text-center">
				<button mat-icon-button color="primary" (click)="editBanner(s)">
					<mat-icon>edit</mat-icon>
				</button>
				&nbsp;&nbsp;
				<button mat-icon-button color="warn" (click)="removeBanner(s)">
					<mat-icon>delete</mat-icon>
				</button>
			</td>
		</tr>
	</tbody>
</table>
