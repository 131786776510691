import { Injectable } from '@angular/core';
import { catchError , retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpHeaders,HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from '../services/globalsearchservice.service';
@Injectable({
	providedIn: 'root'
})

export class TaxesService {
	key = 'UET6DFMPAXW7BKCB';
	config: any;
	package: any = '';
	user: any = [];

	constructor(private globalSearchService:GlobalSearchService,private http: HttpClient,private httpClient: HttpClient,private _configSvc: RuntimeConfigLoaderService) {
		this.globalSearchService.user.subscribe((result: any) => {
			this.user = result;
		});

		this.globalSearchService.configsubscription.subscribe((result: any) => {
			this.config = result;
		});
	}

	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type':	'application/json',
		})
	};

	public getTaxCategories() {
		const method = 'system/taxes/categories';
		return this.http.get(this.setEndPoint(method));
	}

	public getTaxAuthorities() {
		const method = 'system/taxes/authorities';
		return this.http.get(this.setEndPoint(method));
	}


	public getTaxRates() {
		const method = 'system/taxes/rates';
		return this.http.get(this.setEndPoint(method));
	}

	public getTaxGroups() {
		const method = 'system/taxes/groups';
		return this.http.get(this.setEndPoint(method));
	}



	public updatetTaxAuthority(data) {
		const method = 'system/taxes/updateauthorities'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('inventory', data))
		);
	}

	private setEndPoint(method: any) {
		return this.getApi()+method+this.getApiKey()
	}

	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getApiKey() {
		return '&key=' + this.config.apiCredentials.clientId + '&userid=' + this.user.user.userid + this.getPackage();
	}

	private getPackage() {
		const p = this.globalSearchService.getSessionItem('package');
		if(p) {
			return '&package='+p;
		} else {
			return '';
		}
	}

	public getKey() {
		return '&apikey='+this.key;
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			return error;
		};
	}
}
