<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title">
							<i class="material-icons" (click)="back()">arrow_left</i>
							<i class="fa fa-bar-chart"></i> PMM - Sales Report
						</h4>
						<p class="card-category text-right">
							<i class="fa fa-bar-chart"></i>
						</p>
					</div>
					<div class="card-body">
						<form [formGroup]="form" (ngSubmit)="onSubmit()" class="row">
							<div class="form-group col-md-3">
								<mat-form-field appearance="fill" class="full-width">
									<mat-label>PMM Plan</mat-label>
									<mat-select formControlName="routecode">
										<mat-option value="All">All Plans</mat-option>
										<mat-option *ngFor="let perk of perks" [value]="perk.clubcode">{{ perk.club }}</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="form-group col-md-3">
								<mat-form-field appearance="fill" class="full-width">
									<mat-label>Sales Rep</mat-label>
									<mat-select formControlName="salesrepcode">
										<mat-option value="All">All Salesmen</mat-option>
										<mat-option *ngFor="let salesRep of salesman" [value]="salesRep.salesmanname">{{ salesRep.salesmanname }}</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="form-group col-md-3">
								<mat-form-field appearance="fill" class="full-width">
									<mat-label>Start Date</mat-label>
									<input matInput [matDatepicker]="startPicker" formControlName="datestart">
									<mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
									<mat-datepicker #startPicker></mat-datepicker>
								</mat-form-field>
							</div>
							<div class="form-group col-md-3">
								<mat-form-field appearance="fill" class="full-width">
									<mat-label>End Date</mat-label>
									<input matInput [matDatepicker]="endPicker" formControlName="dateend">
									<mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
									<mat-datepicker #endPicker></mat-datepicker>
								</mat-form-field>
							</div>
							<div class="form-group col-md-2">
								<span *ngIf="sending">
									<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>

								<button type="submit" mat-raised-button color="primary" [disabled]="sending || form.invalid">
									Submit
								</button>
							</div>
							<div class="form-group col-md-12" *ngIf="dataSource.data.length && !sending">
								<button mat-button color="accent" (click)="exportXls()" class="btn btn-success" matTooltip="Convert to Excel">
									<i class="fa fa-file-excel-o"></i>
									<span class="sr-only">Convert to Excel</span>
								</button>
								&nbsp;&nbsp;
								<button mat-button color="warn" (click)="exportPdf()"  class="btn btn-danger" matTooltip="Convert to PDF">
									<i class="fa fa-file-pdf-o"></i>
									<span class="sr-only">Convert to PDF</span>
								</button>
							</div>
						</form>

						<div class="table-responsive" #print_table>
							<table class="table table-bordered table-striped table-hover">
								<thead class="thead-dark">
									<tr>
										<th>Route Number</th>
										<th>Rep</th>
										<th>Cust</th>
										<th>Name</th>
										<th>Address 1</th>
										<th>Address 2</th>
										<th>City</th>
										<th>State</th>
										<th>Zip</th>
										<th>This Year Sales</th>
										<th>Last Year Sales</th>
										<th>Difference</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let element of dataSource.data">
										<td>{{ element.club }}</td>
										<td>{{ element.salesman }}</td>
										<td>{{ element.debtorno }}</td>
										<td>{{ element.debtorname }}</td>
										<td>{{ element.address1 }}</td>
										<td>{{ element.address2 }}</td>
										<td>{{ element.address3 }}</td>
										<td>{{ element.address4 }}</td>
										<td>{{ element.address5 }}</td>
										<td>{{ element.this_year_sales }}</td>
										<td>{{ element.last_year_sales }}</td>
										<td>{{ element.difference }}</td>
									</tr>
								</tbody>
							</table>
							<h5 *ngIf="!dataSource.data.length && !sending" class="text-center"> NO RESULTS</h5>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
