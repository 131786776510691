<form class="navbar-form" autocomplete="off">
	<div class="row">
		<ng-container *ngIf="!creating">
			<div class="col-md-2 mt-3 mb-md-0 mb-3" *ngIf="!lookupOnly">

				<a [routerLink]="'/customers/create'" mat-stroked-button color="danger" *ngIf="!this.user.user.issalesman && (!isPosUser || config.posallowednewcustomer)">New Customer</a>
			</div>
			<div class="col-md-2 mb-md-0 mb-3">
				<mat-slide-toggle [formControl]="include_disabled">Include Disabled</mat-slide-toggle>
				<mat-slide-toggle [formControl]="search_by_order">Search By Order/Invoice</mat-slide-toggle>
				<mat-slide-toggle [formControl]="phoneonly">Phone Number Search</mat-slide-toggle>
			</div>
			<div class="col-md-2 mt-3 mb-0" *ngIf="lookupOnly">

			</div>
			<div class="col-md-2 mt-1 mb-0">
				<mat-form-field appearance="outline" *ngIf="customertypes">
					<mat-label>Customer Type</mat-label>
					<mat-select [formControl]="customertype">
						<mat-option [value]="''">All</mat-option>
						<mat-option *ngFor="let v of customertypes" [value]="v.typeid">
							{{v.typename}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</ng-container>
		<div class="mt-1 mb-0" [ngClass]="{'col-md-6' : !creating, 'col-md-12' : creating } ">

			<mat-form-field appearance="outline" class="smaller-font-form ">
				<span matPrefix *ngIf="all_customers.length && !sending">
					<button mat-icon-button class="p-0" (click)="clearForm()">
						<mat-icon class="p-0">clear</mat-icon>
					</button>
				</span>
				<mat-spinner matPrefix diameter="30" *ngIf="sending"></mat-spinner>
				<ng-container *ngIf="this.user.user.issalesman">
					<input matInput #customerinput (input)="ready=false;" autocomplete="off" class="text-right mt-0 mb-0 pt-0 pb-0 " autocomplete="off" value="" [formControl]="customersearch" placeholder="Search By Phone/Email/Customer#/Name ..." (keydown.enter)="$event.preventDefault();filterCustomers()" (keyUpCheck)="filterCustomers()" appWaitType appCollapseKeyboard autofocus>
				</ng-container>
				<ng-container *ngIf="!this.user.user.issalesman">
					<input matInput #customerinput (input)="ready=false;" autocomplete="off" class="text-right mt-0 mb-0 pt-0 pb-0 " autocomplete="off" value="" [formControl]="customersearch" placeholder="Search By Phone/Email/Customer#/Name ..." (keydown.enter)="$event.preventDefault();filterCustomers()" (keyUpCheck)="filterCustomers()" appWaitType autofocus>
				</ng-container>

				<span matSuffix>
					<button mat-icon-button color="white" type="submit" (click)="filterCustomers()">
						<mat-icon class="p-0">search</mat-icon>
					</button>
				</span>
			</mat-form-field>
		</div>
	</div>
</form>


<div class="table" *ngIf="all_customers">
	<ng-container *ngIf="(!all_customers?.length && ready)">
		<h3 class="text-center">No Results For {{customersearch.value}}</h3>
	</ng-container>
	<mat-table [dataSource]="dataSource" matSort [ngClass]="!all_customers.length ? 'd-none': ''">
		<!-- Actions Column -->
		<ng-container matColumnDef="actions">
			<mat-header-cell *matHeaderCellDef mat-sort-header="customerNumber" class="header-cell">
				Customer#
			</mat-header-cell>
			<mat-cell *matCellDef="let customer" class="body-cell">
				<button mat-stroked-button >
					<ng-container *ngIf="!lookupOnly">View <span [ngClass]="(this.user && this.user.user && this.user.user.issalesman) ? 'd-none' : ''">{{ customer.debtorno }}.{{ customer.branchcode }}</span></ng-container>
					<ng-container *ngIf="lookupOnly">Select <span [ngClass]="(this.user && this.user.user && this.user.user.issalesman) ? 'd-none' : ''">{{ customer.debtorno }}.{{ customer.branchcode }}</span></ng-container>
				</button>
			</mat-cell>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="name">
			<mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell">
				Name
			</mat-header-cell>
			<mat-cell *matCellDef="let customer" class="body-cell mt-1">
				<p class="mat-body-1" [innerHTML]="getCustomerInfo(customer)"></p>
			</mat-cell>
		</ng-container>

		<!-- Billing Address Column -->
		<ng-container matColumnDef="billingaddress">
			<mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell">
				Address
			</mat-header-cell>
			<mat-cell *matCellDef="let customer" class="body-cell">
				<p class="billing-address" [innerHTML]="customer.billingaddress"></p>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="contact">
			<mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell" [ngClass]="(this.user && this.user.user && this.user.user.issalesman) ? 'd-none' : ''">
				Contact
			</mat-header-cell>
			<mat-cell *matCellDef="let customer" class="body-cell" [ngClass]="(this.user && this.user.user && this.user.user.issalesman) ? 'd-none' : ''">
				<p class="billing-address" [innerHTML]="customer.contact"></p>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="address3">
			<mat-header-cell *matHeaderCellDef mat-sort-header class="header-cell" [ngClass]="(this.user && this.user.user && this.user.user.issalesman) ? 'd-none' : ''">
				Region
			</mat-header-cell>
			<mat-cell *matCellDef="let customer" class="body-cell" [ngClass]="(this.user && this.user.user && this.user.user.issalesman) ? 'd-none' : ''">
				<p class="billing-address" [innerHTML]="customer.address3"></p>
			</mat-cell>
		</ng-container>




		<!-- Header and Row Definitions -->
		<mat-header-row *matHeaderRowDef="headercolumns" class="header-row"></mat-header-row>
		<mat-row *matRowDef="let row; columns: headercolumns;" (click)="selectCustomer(row)" class="body-row"></mat-row>
	</mat-table>

	<!-- Paginator -->
	<mat-paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons aria-label="select page" class="mat-paginator" [ngClass]="!all_customers.length ? 'd-none': ''">
	</mat-paginator>
</div>

<div class="table table-responsive text-center" *ngIf="!ready && customersearching">
	<svg class="spinner" width="55px" height="55px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
		<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
		</circle>
	</svg>
</div>
