<form [formGroup]="branchForm" *ngIf="branchForm">
	<h5 class="bold">New Branch</h5>
	<div class="row">
		<div class="col-md-6">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-12">
							<mat-form-field appearance="outline">
								<mat-label>Branch Code</mat-label>
								<input matInput required value="" formControlName="branchcode">
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<mat-form-field appearance="outline">
								<mat-label>Name</mat-label>
								<input matInput required value="" formControlName="brname">
							</mat-form-field>
						</div>
						<div class="col-md-6">
							<mat-form-field appearance="outline">
								<mat-label>Contact Name</mat-label>
								<input matInput required value="" formControlName="contactname">
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<mat-form-field appearance="outline">
								<mat-label>Address 1</mat-label>
								<input matInput required value="" formControlName="braddress1">
							</mat-form-field>
						</div>
						<div class="col-md-6">
							<mat-form-field appearance="outline">
								<mat-label>Address 2</mat-label>
								<input matInput value="" formControlName="braddress2">
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4">
							<mat-form-field appearance="outline">
								<mat-label>City</mat-label>
								<input matInput required value="" formControlName="braddress3">
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="outline" class="ml-2 mr-2">
								<mat-label>Region</mat-label>
								<mat-select formControlName="braddress4">
									<mat-option *ngFor="let zone of zones.zones" [value]="zone.code">
										{{zone.code }}
									</mat-option>
								</mat-select>
							</mat-form-field>

						</div>
						<div class="col-md-4">
							<mat-form-field appearance="outline">
								<mat-label>Postal</mat-label>
								<input matInput required value="" formControlName="braddress5">
							</mat-form-field>
						</div>
						<div class="col-md-12">
							<mat-form-field appearance="outline">
								<mat-label>Country</mat-label>
								<mat-select formControlName="braddress6" (selectionChange)="updateZones($event)">
									<mat-option *ngFor="let zone of zones.countries" [value]="zone.name">
										{{ zone.name }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<mat-form-field appearance="outline">
								<mat-label>Phone</mat-label>
								<input matInput value="" formControlName="phoneno" mask="(000) 000-0000" [showMaskTyped]="true">
							</mat-form-field>
						</div>
						<!-- <div class="col-md-4">
							<mat-form-field appearance="outline">
								<mat-label>Email</mat-label>
								<input matInput value="" formControlName="email">
							</mat-form-field>
						</div> -->
						<div class="col-md-6">
							<mat-form-field appearance="outline">
								<mat-label>Fax</mat-label>
								<input matInput value="" formControlName="faxno">
							</mat-form-field>
						</div>

						<div class="col-md-6" *ngIf="config.env.package != 'tires'">
							<mat-form-field appearance="outline">
								<mat-label>License</mat-label>
								<input matInput formControlName="cosm_license">
							</mat-form-field>
						</div>
						<div class="col-md-6" *ngIf="config.env.package != 'tires'">
							<mat-form-field appearance="outline">
								<mat-label>Expiration</mat-label>
								<input matInput formControlName="licExpDate" [matDatepicker]="licExpDatedatepicker">
								<mat-datepicker-toggle matSuffix [for]="licExpDatedatepicker"></mat-datepicker-toggle>
								<mat-datepicker #licExpDatedatepicker></mat-datepicker>
							</mat-form-field>
						</div>

					</div>

					<div class="clearfix"></div>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="card card-profile">
				<div class="card-body">
					<h6 class="card-category text-gray">Settings</h6>
					<h4 class="card-title"></h4>
					<div class="row">
						<div class="col-md-6">
							<mat-form-field appearance="outline">
								<mat-label>Warehouse</mat-label>
								<mat-select formControlName="defaultlocation">
									<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
										{{loc.loccode}} {{loc.locationname}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-md-6">
							<mat-form-field appearance="outline" *ngIf="salesman">
								<mat-label>Salesperson</mat-label>
								<mat-select formControlName="salesman">
									<mat-option *ngFor="let s of salesman" [value]="s.salesmancode">
										{{s.salesmanname}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4">
						<mat-form-field appearance="outline">
							<mat-label>Route</mat-label>
							<mat-select required formControlName="route" >
								<mat-option *ngFor="let k of routes" [value]="k.route_Id">
									{{ k.route_Id }}: {{ k.route_description }}
								</mat-option>
							</mat-select>
						</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="outline">
								<mat-label>Sales Area</mat-label>
								<mat-select formControlName="area">
									<mat-option *ngFor="let area of areas" [value]="area.areacode">
										{{area.areadescription}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="outline">
								<mat-label>Shipping Method</mat-label>
								<mat-select formControlName="defaultshipvia">
									<mat-option *ngFor="let ship of shipvia" [value]="ship.shipper_id">
										{{ship.shippername}}
									</mat-option>
								</mat-select>
							</mat-form-field>

						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<mat-form-field appearance="outline">
								<mat-label>Tax Id</mat-label>
								<input matInput value="" formControlName="tax_ref">
								<mat-hint *ngIf="!branchForm.get('tax_ref').valid">Minimum Length {{ branchForm.get('tax_ref').value.length }}/9</mat-hint>
							</mat-form-field>
						</div>
						<div class="col-md-6">
							<mat-form-field appearance="outline" *ngIf="taxes">
								<mat-label>Tax Authority</mat-label>
								<mat-select formControlName="taxgroupid">
									<mat-option *ngFor="let tax of taxes" [value]="tax.taxgroupid">
										{{tax.taxgroupdescription}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<mat-form-field appearance="outline">
								<mat-label>Default Payment Method</mat-label>
								<mat-select formControlName="default_paymentmehod">
									<mat-option *ngFor="let p of paymentmethods" [value]="p.paymentid">
										{{p.paymentname}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-md-6">
							<mat-form-field appearance="outline">
								<mat-label>Special Instructions</mat-label>
								<input matInput value="" formControlName="specialinstructions">
							</mat-form-field>
						</div>
						<div class="col-md-12 text-center">
							<mat-slide-toggle formControlName="disabletrans" >
							  Disable Transactions
							</mat-slide-toggle>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row text-center">
		<div class="col-md-12 text-center">
			<button class="ml-auto mr-auto " mat-raised-button [color]="branchForm.valid ? 'primary': 'warn' " (click)="createCustomer()">Create Ship To</button>
		</div>
	</div>
</form>
