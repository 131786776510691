import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../data.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

	constructor(public globalSearchService: GlobalSearchService, public router: Router, public dataService: DataService) { }

	ngOnInit() {

		localStorage.removeItem('user');
		localStorage.removeItem('account');
		localStorage.removeItem('shop');

		this.router.navigate(['auth/login']);
		this.globalSearchService.currentsetuser = false;

	}

}
