import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform,AfterViewInit,OnChanges } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { merge, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';

import { MatSnackBar,MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { PurchasingService } from '../../services/purchasing.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';

@Component({
	selector: 'app-purchase-create',
	templateUrl: './purchase-create.component.html',
	styleUrls: ['./purchase-create.component.scss']
})
export class PurchaseCreateComponent implements OnInit,AfterViewInit,OnChanges {

	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('itemsearch') itemsearch: ElementRef;

	selecctedsupplier = '';
	config: any = []
	color: any ='blue';
	flipstate = 'active';
	supplierDetails: any = false;
	complete = false;

	constructor(private globalsService : GlobalsService, private router: Router,private location: Location,private route: ActivatedRoute,private purchasingService :PurchasingService ,  public globalSearchService: GlobalSearchService) {
		this.selecctedsupplier ='';

	 }
	loadVendor() {

	}
	ngOnInit(): void {
		this.config = this.globalsService.getApiConfig()

		this.color = this.globalSearchService.getColor();


		this.route.params.subscribe(params => {
			if(params['id']) {

				this.selecctedsupplier = params['id'];

				const supplierid= this.selecctedsupplier;
				this.purchasingService.getSupplier(supplierid).subscribe((results:any) => {
					this.supplierDetails = results;
				});
			}
		});
	}



	back(): void {
		this.location.back()
	}

	stopProp(event: any): void{
		event.stopPropagation();
	}

	ngAfterViewInit() {
	}

	vendorSelected(row: any) {
		if(this.supplierDetails.supplierid != row.supplierid) {
			this.flipstate = 'active'
		}

		this.purchasingService.clearCart(row.supplierid).subscribe((results:any) => {
			this.router.navigate(['./purchasing/'+row.supplierid]);
		});


	}

	ngOnChanges(changes: any) {

		//this.updateCart();
	}


}
