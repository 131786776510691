import { Component, OnDestroy, OnInit, ViewChild, OnChanges, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable, of } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import { Directive, HostListener } from '@angular/core';
import { trigger, state, keyframes, style, transition, animate, animation } from '@angular/animations';
import { CustomerService } from '../../services/customer.service';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { UsersService } from '../../services/users.service';
import { PaymentsService } from '../../services/payments.service';
import { OmsService } from '../../services/oms.service';
import { PrintService } from '../../services/print.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as printJS from 'print-js';
import { take } from 'rxjs/operators';

@Component({
	selector: 'app-pos-receipt',
	templateUrl: './pos-receipt.component.html',
	styleUrls: ['./pos-receipt.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(-179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('250ms ease-in')),
			transition('active => inactive', animate('250ms ease-out')),
		]),
		trigger('grow', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: '0', width: '0' }),
				animate(200, style({ height: '*', width: '*' })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate(100, style({ height: 0, width: 0, })),
				animate('100ms', style({ opacity: 0 }))
			])
		]),
		trigger('cinfo', [
			transition(':enter', [
				animate('50ms', style({ opacity: 1, height: 100 })),
			]),
			transition(':leave', [
				animate('100ms', style({ opacity: 1, height: 0 }))
			]),
		]),
		trigger('itemSearch', [
			transition(':enter', animation([style({ transform: 'translate(-800px,0)', }),
				animate('0.2s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(1200px,0)', })
				),
			])),
		]),
		trigger('addFilters', [
			transition(':enter', animation([style({ transform: 'translate(200px,0)', }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(-200px,0)', })
				),
			])),
		]),
		trigger('navIn', [
			transition(':enter', animation([style({ transform: 'translate(200px,0)', }),
				animate('0.10s cubic-bezier(0.59, 0.32, 0.38, 0.33)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.0s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(-200px,0)', })
				),
			])),
		]),
		trigger('shakeit', [
			state('shakestart', style({
				transform: 'scale(1)',
			})),
			state('shakeend', style({
				transform: 'scale(1)',
			})),
			transition('shakestart => shakeend', animate('1000ms ease-in',
				keyframes([
					style({ transform: 'translate3d(-1px, 0, 0)', offset: 0.1 }),
					style({ transform: 'translate3d(2px, 0, 0)', offset: 0.2 }),
					style({ transform: 'translate3d(-4px, 0, 0)', offset: 0.3 }),
					style({ transform: 'translate3d(4px, 0, 0)', offset: 0.4 }),
					style({ transform: 'translate3d(-4px, 0, 0)', offset: 0.5 }),
					style({ transform: 'translate3d(4px, 0, 0)', offset: 0.6 }),
					style({ transform: 'translate3d(-4px, 0, 0)', offset: 0.7 }),
					style({ transform: 'translate3d(2px, 0, 0)', offset: 0.8 }),
					style({ transform: 'translate3d(-1px, 0, 0)', offset: 0.9 }),
				]))),
		])
	],
})
export class PosReceiptComponent implements OnInit {

	@ViewChild('pdfFrame') pdfFrame: ElementRef;

	color: any = 'blue';
	config: any = [];
	receipt_data: any = false;
	recDisplay: any = '';
	sendemail: any = '';
	email = new UntypedFormControl();

	sanitizedPrintLink: SafeResourceUrl;
	base64Pdf: any;

	id: any = '';
	newprint = true;
	routeSubscription: any = false;
	userSubscription: any = false;
	constructor(private elementRef: ElementRef, private sanitizer: DomSanitizer, public printService: PrintService, private paymentsService: PaymentsService, private inventoryService: InventoryService, private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, public omsService: OmsService, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal, public usersService: UsersService) {
		this.color = this.globalSearchService.getColor();
		this.config = this.globalsService.getApiConfig()
	}

	ngOnInit(): void {
		this.globalSearchService.mesagesallowed.next(false);
		this.omsService.setNewOrder();
		this.newprint = true;
		this.routeSubscription = this.route.params.subscribe(params => {
			if (params['id'] != '' && params['id'] !== undefined) {

				this.id = params['id'];

				this.routeSubscription = this.route.params.pipe(take(1)).subscribe(params => {
					if (params['id'] != '' && params['id'] !== undefined) {

						this.id = params['id'];

						this.userSubscription = this.orderService.getOrderReceipt(params['id']).pipe(take(1)).subscribe((rec: any) => {
							this.receipt_data = rec;
							this.recDisplay = rec.display;
							this.base64Pdf = rec.print;

							this.globalSearchService.user.pipe(take(1)).subscribe(user => {
								const printers = this.usersService.getDevicesByType(user.user.userid, 'RECEIPT');
								const data = { pdf: rec.print, user: user, devices: printers };
								console.log(printers);
								if (printers && printers.length) {
									this.omsService.sendPrintCommand({ data, roomName: 'Printers' }, cb => {
										this.omsService.setPrintedOrder();
									});
								} else {
									// Handle no printers found case
									if (this.newprint) {
										this.printPdfContent(rec.print);
										this.omsService.setNewOrder();
									}
								}
							});
						});

					}
				});


			}
		});
	}

	sendPrint() {
		this.orderService.getOrderReceipt(this.id).pipe(take(1)).subscribe((rec: any) => {
			this.receipt_data = rec;
			this.recDisplay = rec.display;
			this.base64Pdf = rec.print;

			this.globalSearchService.user.pipe(take(1)).subscribe(user => {
				const printers = this.usersService.getDevicesByType(user.user.userid, 'RECEIPT');

				const data = { pdf: rec.print, user: user, devices: printers }

				this.omsService.setNewOrder();
				if (printers && printers.length) {
					// Convert the callback-style function into an observable with 'of'
					of(this.omsService.sendPrintCommand({ data, roomName: 'Printers' }, cb => {
						this.omsService.setPrintedOrder();
					})).pipe(take(1)).subscribe();
				} else {
					// If there are no printers, handle the new print
					if (this.newprint) {
						this.printPdfContent(rec.print);
						this.omsService.setNewOrder();
					}
				}
			});
		});
	}

	printPdf() {
		printJS({ printable: this.base64Pdf, type: 'pdf', base64: true });
	}

	printPdfContent(content) {
		if (content) {
			printJS({ printable: content, type: 'pdf', base64: true });
			this.newprint = false;
			this.omsService.setPrintedOrder();
		}
	}

	base64ToArrayBuffer(base64: string): ArrayBuffer {
		const binaryString = this.globalSearchService.base64decode(base64);
		const bytes = new Uint8Array(binaryString.length);
		for (let i = 0; i < binaryString.length; i++) {
			bytes[i] = binaryString.charCodeAt(i);
		}
		return bytes.buffer;
	}

	stringToArrayBuffer(str: string): ArrayBuffer {
		const buffer = new ArrayBuffer(str.length);
		const bufferView = new Uint8Array(buffer);
		for (let i = 0; i < str.length; i++) {
			bufferView[i] = str.charCodeAt(i);
		}
		return buffer;
	}

	newOrder() {
		this.omsService.setPrintedOrder()
		const url = '/counter-pos';
		this.router.navigate([url]);
	}

	printReceipt() {
		const data = {
			id: this.receipt_data.id
		}
		this.orderService.printOrderReciept(data).subscribe(r => {

		});
	}

	ngOnDestroy(): void {
		if (this.userSubscription) {
			this.userSubscription.unsubscribe();
		}
		if (this.routeSubscription) {
			this.routeSubscription.unsubscribe();
		}
	}

	ngAfterViewInit() {

	}

	abstract

	emailReceipt() {
		const data = {
			id: this.receipt_data.id
		}
		if (!this.emailValidate()) {
			this.globalSearchService.showNotification('Email not valid', 'danger', 'bottom', 'left');
		} else {
			this.globalsService.emailInvoice(this.receipt_data.transid, 'email', this.email.value).subscribe((result: any) => {
				this.globalSearchService.showNotification('Email Sent', 'success', 'bottom', 'left');
			});
		}

	}

	emailValidate() {
		return this.email.value == null ? false : this.email.value.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
	}

}