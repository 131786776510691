<ng-template #details let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">{{ displaydetails.title }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<!--
		<mat-chip-set role="list">
			<mat-chip role="listitem" *ngFor="let l of viewedtransactions">
				<button mat-menu-item (click)="viewPayments(l,details)">
					<mat-icon>credit_card</mat-icon>
					<span>Payments</span>
				</button>
			</mat-chip>
		</mat-chip-set>
		-->

		<div class="mb-3" *ngIf="displaydetails">
			<h4></h4>
			<ng-container *ngIf="displaydetails.type == 'html'">
				<div [innerHTML]="displaydetails.display">
				</div>
			</ng-container>
			<ng-container *ngIf="displaydetails.type == 'pdf'">
				<ngx-extended-pdf-viewer [base64Src]="displaydetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true" [filenameForDownload]="displaydetails.title"></ngx-extended-pdf-viewer>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #orderView let-modal>
	<div class="modal-header">
		<div class="modal-title row m-0 p-0" id="modal-title">
			<h4>{{drilldown.name}} {{drilldown.vendor}} Orders for branch {{drilldown.locs}}</h4>
		</div>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="orderdetails">
			<div class="table">
				<table class="table table-sm">
					<tr>
						<th>Order #</th>
						<th>Customer</th>
						<th>Type</th>
						<th class="text-center">Date</th>
						<th class="text-center">Salesman Code</th>
						<th class="text-center">Order Total</th>
					</tr>
					<tr *ngFor="let item of orderdetails">

						<td><button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More" class="donotprint">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #menu="matMenu">
								<button mat-menu-item (click)="openDocument(item,details,'html')">
									<mat-icon>visibility</mat-icon>
									<span>View HTML</span>
								</button>
								<button mat-menu-item (click)="openDocument(item,details,'pdf')">
									<mat-icon>visibility</mat-icon>
									<span>View PDF</span>
								</button>

							</mat-menu>{{item.transno}}
						</td>
						<td>{{item.Customer}} - {{item.Name}}</td>
						<td>{{item.DocType}}</td>
						<td class="text-center">{{item.Date}}</td>
						<td class="text-center">{{item.Salesman}}</td>
						<td class="text-center">{{item.Sales | currency}}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i> Salesman Product Line Ranking Report
						</h4>
						<p class="card-category text-right">SPSLS</p>
					</div>
					<div class="card-body">
						<form class="navbar-form">
							<div class="row">
								<div class="col-4">
									<mat-form-field appearance="outline">
										<mat-label>Transaction Dates</mat-label>
										<mat-date-range-input [rangePicker]="picker">
											<input matStartDate [formControl]="fromdateCtrl" placeholder="Start date">
											<input matEndDate [formControl]="todateCtrl" placeholder="End date">
										</mat-date-range-input>
										<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
										<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
										<mat-date-range-picker #picker></mat-date-range-picker>
									</mat-form-field>
								</div>
								<div class="col-4">
									<mat-form-field appearance="outline">
										<mat-label>Branches</mat-label>
										<mat-select [formControl]="branchRef" multiple #branchFilter appSelectAll>
											<mat-option *ngFor="let k of branches" [value]="k.loccode">{{k.locationname}}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-4">
									<mat-form-field appearance="outline">
										<mat-label>Salespeople</mat-label>
										<mat-select [formControl]="salespersonRef" multiple #salesFilter appSelectAll>
											<mat-option *ngFor="let k of salespeople" [value]="k.salesmancode">{{k.salesmanname}}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-4">
									<mat-form-field appearance="outline">
										<mat-label>Consolidated or Detailed?</mat-label>
										<mat-select [formControl]="conBrRef">
											<mat-option *ngFor="let v of conbr" [value]="v.value">
												{{v.label}}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-2">
									<mat-form-field appearance="outline">
										<mat-label>Vendor</mat-label>
										<mat-select [formControl]="brands" multiple #brandFilter appSelectAll>
											<mat-option *ngFor="let k of properties" [value]="k.supplierid">{{k.suppname}}</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-2">
									<mat-form-field appearance="outline">
										<mat-label>Web Orders?</mat-label>
										<mat-select [formControl]="placedFromRef">
											<mat-option *ngFor="let v of placedfrom" [value]="v.value">
												{{v.label}}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-4">
									<button mat-raised-button color="white" type="submit" (click)="getSPSLS()">
										<mat-icon class="p-0">search</mat-icon> Search
									</button>
									<span *ngIf="sending">
										<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
											<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
										</svg>
									</span>
									&nbsp;&nbsp;
									<ng-container *ngIf="!sending">
										<button class="btn btn-success btn-sm" (click)="$event.preventDefault();exportXls()" *ngIf="orderResults">
											<i class="fa fa-file-excel-o"></i> XLS
										</button>
										&nbsp;&nbsp;
										<button class="btn btn-danger btn-sm" (click)="$event.preventDefault();exportPdf()" *ngIf="orderResults">
											<i class="fa fa-file-pdf-o"></i> PDF
										</button>
									</ng-container>
								</div>
							</div>

						</form>



							<ng-container *ngIf="orderResults">
								<div class="table">
									<h3>Salesman Product Line Ranking Report {{reportCriteria.fromdate | date:'MM/d/yy'}} - {{reportCriteria.todate | date: 'MM/d/yy'}}</h3>
									<h4>{{getReportSubtitle()}}</h4>
									<table class="table w-100 m-auto " *ngIf="orderResults">
										<tr >
											<th>BRANCH #</th>
											<th>VENDOR ID</th>
											<th>VENDOR NAME</th>
											<th>SALES</th>
											<th>SLS ID</th>
										</tr>
										<ng-container *ngFor="let o of orderResults.lines | keyvalue">
											<tr>
												<th colspan="5">{{orderResults.BRtotals[o.key]['name']}} </th>
											</tr>
											<ng-container *ngFor="let loc of o.value | keyvalue; index as i;">
												<ng-container *ngFor="let sls of loc.value | keyvalue">
													<ng-container *ngFor="let line of sls.value ">
														<tr>
															<td>{{ line['Location'] }}</td>
															<td>{{ line['VEND'] }}</td>
															<td>{{ line['VENDOR NAME'] }}</td>
															<td>{{ line.SALES | currency }}</td>
															<td><a class="link" (click)="viewSalesman(line)">{{ line['SALES ID'] }}</a></td>

														</tr>
													</ng-container>
													<tr *ngIf="reportCriteria.conbr != 'C'" class="sls-rollup">
														<th></th>
														<th></th>
														<th></th>
														<th></th>
														<th>{{orderResults.SLStotals[o.key][loc.key]['name']}} subtotal: {{orderResults.SLStotals[o.key][loc.key]['subtotal'] | currency}}</th>
													</tr>

												</ng-container>
											</ng-container>
											<tr>
												<th></th>
												<th></th>
												<th></th>
												<th></th>
												<th>Branch {{o.key }} Total: {{ orderResults.BRtotals[o.key]['total'] | currency}}</th>
											</tr>

										</ng-container>
										<tr>
											<th></th>
											<th></th>
											<th>Count : {{ orderResults.totalrecords }}</th>
											<th>Avg: {{ orderResults.avg | currency }}</th>
											<th>Total: {{ orderResults.total | currency}}</th>
										</tr>
									</table>
								</div>

							</ng-container>
							<div #print_table_pdf style="display: none;">
								<table class="table w-75 m-auto" *ngIf="orderResults">
									<tr colspan="5">
										<th style="text-align: center;">BRANCH #</th>
										<th style="text-align: center;">VENDOR ID</th>
										<th style="text-align: left;">VENDOR NAME</th>
										<th style="text-align: right;">SALES</th>
										<th style="text-align: center;">SLS ID</th>
									</tr>
									<ng-container *ngFor="let o of orderResults.lines | keyvalue">
										<tr>
											<th colspan="5" style="text-align: left;">{{orderResults.BRtotals[o.key]['name']}} </th>
										</tr>
										<ng-container *ngFor="let loc of o.value | keyvalue; index as i;">
											<ng-container *ngFor="let sls of loc.value | keyvalue">
												<ng-container *ngFor="let line of sls.value ">
													<tr>
														<td style="text-align: center;">{{ line['Location'] }}</td>
														<td style="text-align: center;">{{ line['VEND'] }}</td>
														<td style="text-align: left;">{{ line['VENDOR NAME'] }}</td>
														<td style="text-align: right;">{{ line.SALES | currency }}</td>
														<td style="text-align: center;">{{ line['SALES ID'] }}</td>
														<!-- <td><a class="link" (click)="viewCustomer(line.Customer,line.BR, line.Salesman)">{{ line['SALES NAME'] }}</a></td> -->
													</tr>
												</ng-container>
												<tr *ngIf="reportCriteria.conbr != 'C'" class="sls-rollup">
													<th></th>
													<th></th>
													<th></th>
													<th></th>
													<th style="text-align: right;">{{orderResults.SLStotals[o.key][loc.key]['name']}} subtotal: {{orderResults.SLStotals[o.key][loc.key]['subtotal'] | currency}}</th>
												</tr>
											</ng-container>
										</ng-container>
										<tr>
											<th></th>
											<th></th>
											<th></th>
											<th></th>
											<th style="text-align: right;">Branch {{o.key }} Total: {{ orderResults.BRtotals[o.key]['total'] | currency}}</th>
										</tr>
									</ng-container>
									<tr>
										<th></th>
										<th></th>
										<th>Count : {{ orderResults.totalrecords }}</th>
										<th>Avg: {{ orderResults.avg | currency }}</th>
										<th>Total: {{ orderResults.total | currency}}</th>
									</tr>
								</table>
								<table class="table table-sm table-hover w-100" style="width: 100%;">
									<tr>
										<th colspan="9">
											<h4>Vendors Selected</h4>
										</th>
									</tr>
									<tr>
										<th colspan="9">Name</th>
									</tr>
									<ng-container *ngIf="reportCriteria.brands.length > 0">
										<tr *ngFor="let b of reportCriteria.brands">
											<td colspan="9">{{brandData(b, 'name')}}</td>
										</tr>
									</ng-container>
									<ng-container *ngIf="reportCriteria.brands.length == 0">
										<tr *ngFor="let b of properties">
											<td colspan="9">{{b.suppname}}</td>
										</tr>
									</ng-container>
								</table>
							</div>

							<div #print_table_xlsx style="display: none;">
								<table class="table w-85 m-auto " *ngIf="orderResults" style="border-left: 1px dotted gainsbord; border-right: 1px dotted gainsbord">
									<tr colspan="5">
										<th>BRANCH #</th>
										<th>VENDOR ID</th>
										<th>VENDOR NAME</th>
										<th>SALES</th>
										<th>SLS ID</th>
									</tr>
									<ng-container *ngFor="let o of orderResults.lines | keyvalue">
										<tr>
											<th colspan="5">{{orderResults.BRtotals[o.key]['name']}} </th>
										</tr>
										<ng-container *ngFor="let loc of o.value | keyvalue; index as i;">
											<ng-container *ngFor="let sls of loc.value | keyvalue">
												<ng-container *ngFor="let line of sls.value ">
													<tr>
														<td>{{ line['Location'] }}</td>
														<td>{{ line['VEND'] }}</td>
														<td>{{ line['VENDOR NAME'] }}</td>
														<td>{{ line.SALES | currency }}</td>
														<td>
															<pre style="float: right;">{{ line['SALES ID'] }}</pre>
														</td>
													</tr>
												</ng-container>
												<tr *ngIf="reportCriteria.conbr != 'C'" class="sls-rollup">
													<th></th>
													<th></th>
													<th></th>
													<th></th>
													<th>{{orderResults.SLStotals[o.key][loc.key]['name']}} subtotal: {{orderResults.SLStotals[o.key][loc.key]['subtotal'] | currency}}</th>
												</tr>
											</ng-container>
										</ng-container>
										<tr>
											<th></th>
											<th></th>
											<th></th>
											<th></th>
											<th>Branch {{o.key }} Total: {{ orderResults.BRtotals[o.key]['total'] | currency}}</th>
										</tr>
									</ng-container>
									<tr>
										<th></th>
										<th></th>
										<th>Count : {{ orderResults.totalrecords }}</th>
										<th>Avg: {{ orderResults.avg | currency }}</th>
										<th>Total: {{ orderResults.total | currency}}</th>
									</tr>
								</table>
								<table class="table table-sm table-hover w-100" style="width: 100%;">
									<tr>
										<th colspan="9">
											<h4>Vendors Selected</h4>
										</th>
									</tr>
									<tr>
										<th colspan="9">Name</th>
									</tr>
									<ng-container *ngIf="reportCriteria.brands.length > 0">
										<tr *ngFor="let b of reportCriteria.brands">
											<td colspan="9">{{brandData(b, 'name')}}</td>
										</tr>
									</ng-container>
									<ng-container *ngIf="reportCriteria.brands.length == 0">
										<tr *ngFor="let b of properties">
											<td colspan="9">{{b.suppname}}</td>
										</tr>
									</ng-container>
								</table>
							</div>

						</div>

					</div>
				</div>
			</div>
		</div>

</div>
