<div class="container-fluid tp-box" [@flipState]="flip" >
	<div class="row tp-box__side tp-box__back" *ngIf="flip == 'active'">
		<div class="card" >
			<div class="card-header card-header-danger card-header-icon" (click)="toggleFlip(false, '')">
				<div class="card-icon pointer" >
					<i class="material-icons">meeting_room</i>
				</div>
				<h3 class="card-title">{{ title }} </h3>
			</div>
			<div class="row m-0 p-0">
				<div class="col-md-12">
					<mat-form-field appearance="outline">
						<mat-label>From Date</mat-label>
						<input matInput [formControl]="todate" [matDatepicker]="payfrom">
						<mat-datepicker-toggle matSuffix [for]="payfrom"></mat-datepicker-toggle>
						<mat-datepicker #payfrom></mat-datepicker>
					</mat-form-field>
				</div>
				<div class="col-md-12">
					<button tabindex="-1" mat-stroked-button color="accent" class="mt-3" (click)="loadData()" *ngIf="!sending">
						<i class="material-icons">refresh</i>
					</button>

					&nbsp;&nbsp; <button class="btn btn-success btn-sm" (click)="$event.preventDefault();exportXls()" *ngIf="data" >
						<i class="fa fa-file-excel-o"></i> XLS
					</button>
					&nbsp;&nbsp;
					<button class="btn btn-danger btn-sm" (click)="$event.preventDefault();exportPdf()" *ngIf="data" >
						<i class="fa fa-file-pdf-o"></i> PDF
					</button>


					<span *ngIf="sending">
						<svg class="spinner" width="55px" height="55px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
							</circle>
						</svg>
					</span>
				</div>
			</div>


			<div class="card-body"  *ngIf="viewing" >
				<h5>#Customers {{total}}</h5>
				<div class="table" #print_table>
				<table class="table table-fixed table-striped table-hover table-responsive table-fluid" >
					<thead>
						<tr>
							<th>Customer#</th>
							<th>Name</th>
							<th>Address1</th>
							<th>City</th>
							<th>State</th>
							<th>Postal</th>
							<th>SalesPerson</th>
							<th>Last Seen</th>
							<th>Total Spend</th>
							<th>Avg Spend</th>
							<!-- <th>Last Transaction</th> -->
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let d of data">
							<td> <a [routerLink]="'/customers/view/'+d.debtorno">{{d.debtorno}}</a></td>
							<td>{{d.name}}</td>
							<td>{{d.address1}}</td>
							<td>{{d.address3}}</td>
							<td>{{d.address4}}</td>
							<td>{{d.address5}}</td>
							<td><span *ngIf="d.salesmanname">{{d.salesmanname.name}}</span></td>
							<td>{{d.lastseen}}</td>
							<td>{{d.totalspend | currency}}</td>
							<td>{{d.avgspend | currency}}</td>

						</tr>
					</tbody>
				</table>
				</div>
			</div>
		</div>
	</div>
	<div class="row tp-box__side tp-box__front" id="frontside" [ngClass]="flip == 'inactive' ? '' : 'd-none'">
		<div class="card card-stats">
			<div class="card-header card-header-danger card-header-icon" (click)="toggleFlip(data, title)">
				<div class="card-icon pointer" >
					<i class="material-icons">meeting_room</i>
				</div>
				<p class="card-category">{{ title }}</p>
				<h3 class="card-title">
					<span *ngIf="total">
						{{ total }}
					</span>
					<span *ngIf="!data">
						<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</span>
				</h3>
				<small>
					<p class="card-category text-info text-justify mt-3 text-right">
						&nbsp;
					</p>
				</small>
			</div>
			<div class="card-footer">
				<div class="stats pointer">
					<a (click)="toggleFlip(data, title)">
						<i class="material-icons">content_copy</i>
						View Now</a>
				</div>
				<br />
			</div>
		</div>
	</div>
</div>
