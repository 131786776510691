import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ChangeDetectorRef } from '@angular/core';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { InventoryService } from 'app/services/inventory.service';
import { OrdersService } from 'app/services/orders.service';

@Component({
	selector: 'app-productlinetable',
	templateUrl: './productlinetable.component.html',
	styleUrls: ['./productlinetable.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
			state('*', style({ height: '*', visibility: 'visible' })),
			transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
})

export class ProductlinetableComponent implements OnInit {
  @Input() input: any = '';
  @Input() locs: any = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isExpansionDetailRow = (index, row) => row.hasOwnProperty('detailRow');

  displayedColumns: string[] = [];
  datasource: any;
  config: any = false;

  constructor(private globalSearchService: GlobalSearchService, private cdref: ChangeDetectorRef, private ordersService: OrdersService) {
  	this.globalSearchService.configsubscription.subscribe(conf => {
  		this.config = conf;
  	});
  }

  ngOnInit(): void {
  	if(this.locs.length > 1) {
  		this.displayedColumns = ['stockid', 'description', ...this.locs, 'qtysold', 'total'];
  	}else{
  		this.displayedColumns = ['stockid', 'description', 'season', 'designation', 'qtysold', 'total'];
  	}
  	this.datasource = this.input;
  }

  ngAfterViewInit(): void{
  	this.datasource.paginator = this.paginator;
  	this.datasource.sort = this.sort;
  	this.cdref.detectChanges();
  }

  printItemCusts(){ //add excell download for stock custs table here
  	alert('printing customers for this stockid');
  }
}
