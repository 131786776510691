import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchaseCreateComponent } from './purchase-create/purchase-create.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatBadgeModule } from '@angular/material/badge';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { NgSelectModule } from '@ng-select/ng-select';

import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxPaginationModule } from 'ngx-pagination';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';


import { PurchaseorderHeaderComponent } from './purchaseorder-header/purchaseorder-header.component';
import { PurchasingDashboardComponent } from './purchasing-dashboard/purchasing-dashboard.component';
import { PurchasingReceiptjournalComponent } from './purchasing-receiptjournal/purchasing-receiptjournal.component';
import { PurchasingReceiptjournaluploadComponent } from './purchasing-receiptjournalupload/purchasing-receiptjournalupload.component';

import { OpenPurchasesComponent } from './purchasing-dashboard/open-purchases/open-purchases.component';
import { PurchaseOrderReceiveComponent } from './purchase-order-receive/purchase-order-receive.component';
import { PurchasingReorderingComponent } from './purchasing-reordering/purchasing-reordering.component';
import { PurchasingReorderingMinmaxComponent } from './purchasing-reordering/purchasing-reordering-minmax/purchasing-reordering-minmax.component';
import { PurchasingReorderingAvgsalesComponent } from './purchasing-reordering/purchasing-reordering-avgsales/purchasing-reordering-avgsales.component';
import { PurchasingReorderingRestockComponent } from './purchasing-reordering/purchasing-reordering-restock/purchasing-reordering-restock.component';
import { PurchaseOrderEntryComponent } from './purchase-order-entry/purchase-order-entry.component';
import { PurchasingBinSelectComponent } from './purchasing-bin-select/purchasing-bin-select.component';
import { PurchaseCreateMultipleComponent } from './purchase-create-multiple/purchase-create-multiple.component';
import { PurchasingReorderingAvgsalesMixedComponent } from './purchasing-reordering/purchasing-reordering-avgsales-mixed/purchasing-reordering-avgsales-mixed.component';
import { PurchaseOrderViewComponent } from './purchase-order-view/purchase-order-view.component';
import { PurchasingInboundComponent } from './purchasing-dashboard/purchasing-inbound/purchasing-inbound.component';


import { PurchasingBrandPriorityComponent } from './purchasing-brand-priority/purchasing-brand-priority.component';
import { PurchasingGrnsComponent } from './purchasing-dashboard/purchasing-grns/purchasing-grns.component';
import { PurchaseBackordersComponent } from './purchasing-dashboard/purchase-backorders/purchase-backorders.component';
import { PurchasingSuccessComponent } from './purchasing-success/purchasing-success.component';

import { SharedModule } from '../shared/shared.module';
import { DashboardComponentsModule } from '../dashboard-components/dashboard-components.module';
import { PurchasingOpenOrdersComponent } from './purchasing-open-orders/purchasing-open-orders.component';
import { PurchasingReorderingMinmaxTwoComponent } from './purchasing-reordering/purchasing-reordering-minmax-two/purchasing-reordering-minmax-two.component';
import { PurchaseOrderInquiryComponent } from './purchase-order-inquiry/purchase-order-inquiry.component';
import { PurchasingDailychurnComponent } from './purchasing-reordering/purchasing-dailychurn/purchasing-dailychurn.component';



const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
	// Change this to your upload POST address:
	url: 'https://clevehilltire.com/v4/?route=apir/purchasing/filedrop',
	maxFilesize: 500,
	acceptedFiles: '*'
};

@NgModule({
	declarations: [
		PurchaseCreateComponent,
		PurchaseorderHeaderComponent,
		PurchasingDashboardComponent,
		PurchasingReceiptjournalComponent,
		PurchasingReceiptjournaluploadComponent,
		OpenPurchasesComponent,
		PurchaseOrderReceiveComponent,
		PurchasingReorderingComponent,
		PurchasingReorderingMinmaxComponent,
		PurchasingReorderingAvgsalesComponent,
		PurchasingReorderingRestockComponent,
		PurchaseOrderEntryComponent,
		PurchasingBinSelectComponent,
		PurchaseCreateMultipleComponent,
		PurchasingReorderingAvgsalesMixedComponent,
		PurchaseOrderViewComponent,
		PurchasingInboundComponent,

		PurchasingBrandPriorityComponent,
		PurchasingGrnsComponent,
		PurchaseBackordersComponent,
		PurchasingSuccessComponent,
		PurchasingOpenOrdersComponent,
		PurchasingReorderingMinmaxTwoComponent,
		PurchaseOrderInquiryComponent,
		PurchasingDailychurnComponent,

	],
	imports: [
		CommonModule,
		RouterModule,
		NgxDropzoneModule,	
		FormsModule,
		ReactiveFormsModule,
		NgSelectModule,
		MatToolbarModule,
		MatButtonModule,
		MatDialogModule,
		MatDividerModule,
		MatInputModule,
		MatTableModule,
		MatCheckboxModule,
		MatSortModule,
		MatPaginatorModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatExpansionModule,
		MatSelectModule,
		MatMenuModule,
		MatIconModule,
		MatTabsModule,
		MatSlideToggleModule,
		MatStepperModule,
		MatBadgeModule,
		MatTooltipModule,
		DragDropModule,
		SharedModule,
		DashboardComponentsModule,
		NgxPaginationModule,
	],
	providers: [{
		provide: DROPZONE_CONFIG,
		useValue: DEFAULT_DROPZONE_CONFIG
	}]
})
export class PurchasingModule {}
