import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { UsersService } from '../../services/users.service'
import { PaymentsService } from '../../services/payments.service'
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { GlobalsService } from '../../services/globals.service';
import { PrintService } from '../../services/print.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { Location } from '@angular/common'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as FileSaver from 'file-saver';

@Component({
	selector: 'app-dayend-checkout',
	templateUrl: './dayend-checkout.component.html',
	styleUrls: ['./dayend-checkout.component.scss']
})
export class DayendCheckoutComponent implements OnInit {
	@ViewChild('print_table') printtable: ElementRef;
	filename = 'DayEndCheckout';

	user: any = [];
	repdate: any = '';
	paidouts: any = [];
	creditcards: any = [];
	housear: any = [];
	bankdep: any = [];
	otherar: any = [];
	salesdata: any = [];
	taxfees: any = [];
	housearcollect: any = [];
	othersold: any = [];
	salesqty: any = '';
	config: any = [];
	excelLink: any = '';
	results: any = false;
	today = new Date();
	datefrom = new UntypedFormControl(this.today);
	color = 'blue';
	totals: any = {};
	base_totals: any = {
		 paidouts: 0,
		 creditcards: 0,
		 housear: 0,
		 bankdeposit: 0,
		 otherrec: 0,
		 totalrec: 0,
		 sales: 0,
		 taxfees: 0,
		 housearcollect: 0,
		 othersold: 0,
		 overshort: 0,
		 totalcollect: 0
	 };

	 posted = false;

	constructor(private printService: PrintService, public paymentsService: PaymentsService, public usersService: UsersService, private route: ActivatedRoute, public globalSearchService: GlobalSearchService, private modalService: NgbModal, private globalsService: GlobalsService) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
		this.globalSearchService.user.subscribe((result: any) => {
			this.user = result.user;
		});

		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});


		this.loadData();

		this.datefrom.valueChanges.subscribe( newValue => {
			this.loadData();
		})
	}

	getExcel() {

		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename+'.xlsx',
			landscape: true,
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});

		// 		let payload = {
		// 			repdate: this.datefrom.value,
		// 			groupid: this.user.user_group,
		// 			salesqty: this.results.salesqty,
		// 			paidouts: this.results.paidouts,
		// 			creditcards: this.results.creditcards,
		// 			housear: this.results.housear,
		// 			bankdep: this.results.bankdep,
		// 			otherar: this.results.otherar,
		// 			salesdata: this.results.salesdata,
		// 			taxfees: this.results.taxfees,
		// 			housearcollect: this.results.housearcollect,
		// 			othersold: this.results.othersold,
		// 			totals: this.totals
		// 		 };
		//
		//
		// 		this.paymentsService.getDayEndExcel(payload).subscribe((r:any) => {
		// 			this.globalSearchService.downloadXls(r.content, r.filename);
		// 		})
	}

	loadData() {

		const data = { repdate: this.datefrom.value, groupid: this.user.user_group };
		this.globalsService.getDailyARTotals(data).subscribe( (results: any) => {

			this.posted = results.posted;
			this.results = results;
			this.salesqty = results.salesqty;
			this.creditcards = results.creditcards;
			this.housear = results.housear;
			this.paidouts = results.paidouts;
			this.bankdep = results.bankdep;
			this.otherar = results.otherar;
			this.salesdata = results.salesdata;
			this.taxfees = results.taxfees;
			this.housearcollect = results.housearcollect;
			this.othersold = results.othersold;
			this.recalcTotals();
		});
	}

	exportPdf() {

		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename,
			landscape: true,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	recalcTotals() {

		this.totals = this.base_totals;

		//in values
		this.totals.creditcards = this.sumValue(this.results.creditcards);
		this.totals.paidouts = this.sumValue(this.results.paidouts);
		this.totals.housear = this.sumValue(this.results.housear);
		this.totals.bankdeposit = this.sumValue(this.results.bankdep);
		this.totals.otherrec = this.sumValue(this.results.othersold);

		//out values
		this.totals.otherar = this.sumValue(this.results.otherar);
		this.totals.totalrec = this.sumValue(this.results.totalrec);
		this.totals.sales = this.sumValue(this.results.salesdata);
		this.totals.taxfees = this.sumValue(this.results.taxfees);
		this.totals.housearcollect = this.sumValue(this.results.housearcollect);
		this.totals.othersold = this.sumValue(this.results.othersold)

		//actual totals
		this.totals.totalrec = this.financial(
			this.totals.creditcards + this.totals.paidouts + this.totals.housear + this.totals.bankdeposit
		);

		this.totals.totalcollect = this.financial(
			this.totals.sales + this.totals.taxfees + this.totals.housearcollect + this.totals.othersold
		);

		this.totals.overshort = this.financial(this.totals.totalrec - this.totals.totalcollect);

	}

	addEntry(entry: any) {
		const addon =  { 'label': '', 'value': 0, 'removable': 1 }
		entry.push(addon)
	}

	removeEntry(data, entryType) {
		const oindex = entryType.indexOf(data);
		entryType.splice(oindex, 1)
		this.recalcTotals();
	}

	postData() {
		//switch to this.results?
		const pushdata = {
			repdate: this.datefrom.value,
			groupid: this.user.user_group,
			salesqty: this.results.salesqty,
			paidouts: this.results.paidouts,
			creditcards: this.results.creditcards,
			housear: this.results.housear,
			bankdep: this.results.bankdep,
			otherar: this.results.otherar,
			salesdata: this.results.salesdata,
			taxfees: this.results.taxfees,
			housearcollect: this.results.housearcollect,
			othersold: this.results.othersold,
			totals: this.totals
			 };
		this.globalsService.postDailyARTotals(pushdata).subscribe(async (results: any) => {
			if (results.success) {
				this.posted = true;
				this.exportPdf();
			}
		});
	}


	sumValue(valuestosum: any) : number {

		if(valuestosum && valuestosum.length) {
			const parent = this;
			const result = valuestosum.reduce(function(accumulator: number, items: any) {
				return accumulator + parent.financial(items.value);
			}, 0);
		 	return this.financial(result);
		} else {
			return 0.00
		}
	}

	financial(num: any): number {
		return parseFloat(Number.parseFloat(num).toFixed(2));
	}

}
