<ng-container>
	<h4>Coupons</h4>
	<div class="row">
		<div class="col-md-12">

			<div class="col-2 text-left mt-3">
				<button mat-icon-button aria-label="text" color="accent" (click)="addCouponMenu()">
					<i class="material-icons">text_snippet</i>Add Coupon
				</button>
			</div>
			<div class="card">
				<div class="card-body">
					<table class="table table-sm mt-3" style="text-align: center;">
						<tr>
							<th></th>
							<th>Name</th>
							<th>Code</th>
							<th>Type</th>
							<th>Discount</th>
							<th>Threshold Total</th>
							<th>Start Date</th>
							<th>End Date</th>
							<th>Total Uses</th>
							<th>Uses Customer</th>
							<th>Shipping?</th>
							<th>Status?</th>
							<th>Is Stackable?</th>
							<th>Is Taxable?</th>
							<th>Logged?</th>
						</tr>
						<tr *ngFor="let coupon of allCoupons">
							<td>
								<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
									<mat-icon>more_vert</mat-icon>
								</button>

								<mat-menu #menu="matMenu">
									<button mat-menu-item (click)="editCoupon(coupon)">
										<mat-icon>edit</mat-icon>
										<span>Edit Coupon</span>
									</button>
									<button mat-menu-item (click)="deleteCoupon(coupon)">
										<mat-icon>delete</mat-icon>
										<span>Delete Coupon</span>
									</button>
								</mat-menu>
							</td>
							<td>{{ coupon.name }}</td>
							<td>{{ coupon.code }}</td>
							<td>
								<div *ngIf="coupon.type == 1; else type">Percent</div>
								<ng-template #type>Amount</ng-template>
							</td>
							<td>
								<div *ngIf="coupon.type == 1; else discount">{{ coupon.discount | number : '1.2-2' }}%</div>
								<ng-template #discount>{{ coupon.discount | currency }}</ng-template>
							</td>
							<td>{{ coupon.total | currency }}</td>
							<td>{{ coupon.date_start }}</td>
							<td>{{ coupon.date_end }}</td>
							<td>{{ coupon.uses_total }}</td>
							<td *ngIf="coupon.uses_customer != '999999999'">{{ coupon.uses_customer }}</td>
							<td *ngIf="coupon.uses_customer == '999999999'">Any</td>
							<td>
								<div *ngIf="coupon.shipping == 1; else shipping">Enabled</div>
								<ng-template #shipping>Disabled</ng-template>
							</td>
							<td>
								<div *ngIf="coupon.status == 1; else status">Enabled</div>
								<ng-template #status>Disabled</ng-template>
							</td>
							<td>
								<div *ngIf="coupon.stackable == 1; else stackable">Enabled</div>
								<ng-template #stackable>Disabled</ng-template>
							</td>
							<td>
								<div *ngIf="coupon.taxable == 1; else taxable">Enabled</div>
								<ng-template #taxable>Disabled</ng-template>
							</td>
							<td>
								<div *ngIf="coupon.logged == 1; else logged">Enabled</div>
								<ng-template #logged>Disabled</ng-template>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</div>
</ng-container>

<ng-template #couponform let-modal class="modal-lg">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Add Coupon</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>

	<div class="modal-body text-dark" *ngIf="catcontrols.length > 0"><div class="panel panel-default">
		<div class="panel-heading">
			<h3 class="panel-title"><i class="fa fa-pencil"></i>Add Coupon</h3>
		</div>
		<div class="panel-body">
			<form id="form-coupon" [formGroup]="couponForm" class="form-horizontal">
				<div class="tab-content">
					<div class="tab-pane active" id="tab-general">
					<mat-divder class="divider"></mat-divder>
					<h4>Coupon Settings</h4>


						<!--NAME, ENTRY CODE -->
						<div style="display: flex; flex-direction: row;">
							<div class="form-group required col-sm-6">
								<label class="col-sm-12 control-label" id="input-name">Coupon Name</label>
								<div class="col-sm-12">
									<input type="text" name="name" value="" placeholder="Entry Name" id="input-name" class="form-control" formControlName="name"/>
									<label class="text-danger" *ngIf="couponForm.value.name.length < 3">Name must be 3 characters or longer</label>
									<label class="text-danger" *ngIf="couponForm.value.name.length > 128">Name cannot be longer than 128 characters</label>
								</div>
							</div>
							<div class="form-group required col-sm-6">
								<label class="col-sm-12 control-label" id="input-code">Coupon Entry Code</label>
								<div class="col-sm-12">
									<input type="text" name="name" value="" placeholder="Entry Name" id="input-name" class="form-control" formControlName="code" />
									<label class="text-danger" *ngIf="couponForm.value.code.length < 3">Code must be 3 characters or longer</label>
									<label class="text-danger" *ngIf="couponForm.value.code.length > 20">Code cannot be longer than 20 characters</label>
								</div>
							</div>
						</div>



						<div style="display: flex; flex-direction: row;">
							<div class="form-group col-sm-6">
								<label class="col-sm-12 control-label" id="input-date-start">Effective Start Date</label>
								<div class="col-sm-12">
									<input type="date" name="date_start" value="" placeholder="Start Date" data-date-format="YYYY-MM-DD" id="input-date-start" class="form-control"  formControlName="date_start" />
								</div>
							</div>

							<div class="form-group col-sm-6">
								<label class="col-sm-12 control-label" id="input-date-end">Effective End Date</label>
								<div class="col-sm-12">
									<input type="date" name="date_end" value="" placeholder="End Date" data-date-format="YYYY-MM-DD" id="input-date-end" class="form-control"  formControlName="date_end"/>
								</div>
							</div>
						</div>


						<div style="display:flex; flex-direction: row;">
							<div class="form-group col-sm-4">
								<label class="col-sm-12 control-label" id="input-uses-total">
									<span data-toggle="tooltip" title="">Number of Uses</span>
								</label>
								<div class="col-sm-12">
									<label class="text-danger" *ngIf="couponForm.value.uses_total < 1">Total Uses must be at least 1</label>
									<input type="number" min="1" step="1" name="uses_total" value="1" placeholder="Uses Total" id="input-uses-total" class="form-control" formControlName="uses_total" />
								</div>
							</div>

							<div class="form-group col-sm-4">
								<label class="col-sm-12 control-label" id="input-uses-total">
									<span data-toggle="tooltip" title="">Uses Customer ID</span>
								</label>
								<div class="col-sm-10">
									<input type="text" name="uses_customer_ID" value="" placeholder="Enter Customer ID" id="input-uses-total" class="form-control" formControlName="uses_customer"/>
								</div>
							</div>

							<div class="form-group col-sm-4">
								<label class="col-sm-12 control-label" id="input-uses-total">
									<span data-toggle="tooltip" title="">Status?</span>
								</label>

								<div class="col-sm-12">
									<select name="type" id="input-type" class="form-control"  formControlName="status">
										<option value="Enabled">Enabled</option>
										<option value="Disabled">Disabled</option>
									</select>
								</div>
							</div>
						</div>


						<!-- TYPE, AMOUNT, TOTAL -->
						<div style="display: flex; flex-direction: row;">
							<div class="form-group col-sm-4">
								<label class="col-sm-12 control-label" id="input-type"><span data-toggle="tooltip" title=""></span>Coupon Type</label>
								<div class="col-sm-12">
									<select name="type" id="input-type" class="form-control"  formControlName="type"  >
										<option value="Percent" selected="selected">Percent</option>
										<option value="Amount">Amount</option>
									</select>
								</div>
							</div>

							<div class="form-group col-sm-4">
								<label class="col-sm-12 control-label" id="input-discount">Discount Amount</label>
								<div class="col-sm-12">
									<label class="text-danger" *ngIf="couponForm.value.discount < 0">Discount amount must be positive</label>
									<label class="text-danger" *ngIf="couponForm.value.discount > 100 && couponForm.value.type == 1">Discount cannot be greater than 100</label>
									<input type="number" min="0" name="name" value="" placeholder="Coupon Discount" class="form-control" formControlName="discount" />
								</div>
							</div>

							<div class="form-group col-sm-4">
								<label class="col-sm-12 control-label" id="input-total">Threshold Total</label>
								<div class="col-sm-12">
									<label class="text-danger" *ngIf="couponForm.value.total < 0">Total must be positive</label>
									<input type="number" min="0" name="name" value="" placeholder="Entry Total" class="form-control" formControlName="total" />
								</div>
							</div>
						</div>


						<div style="display: flex; flex-direction: row; padding: 0px;" class="col-sm-12">
							<div class="form-group col-sm-4">
								<label class="col-sm-12 control-label" id="input-shipping">Entry Shipping</label>
								<div class="col-sm-12">
									<select name="type" id="input-type" class="form-control"  formControlName="shipping">
										<option value="Enabled">Enabled</option>
										<option value="Disabled">Disabled</option>
									</select>
								</div>
							</div>

							<div class="form-group col-sm-4">
								<label class="col-sm-12 control-label" id="input-uses-total">
									<span data-toggle="tooltip" title="">Is Taxable?</span>
								</label>

								<div class="col-sm-12">
									<select name="type" id="input-type" class="form-control"  formControlName="taxable">
										<option value="Enabled">Enabled</option>
										<option value="Disabled">Disabled</option>
									</select>
								</div>
							</div>

							<div class="form-group col-sm-4">
								<label class="col-sm-12 control-label" id="input-uses-total">
									<span data-toggle="tooltip" title="">Is Stackable?</span>
								</label>

								<div class="col-sm-12">
									<select name="type" id="input-type" class="form-control"  formControlName="stackable">
										<option value="Enabled">Enabled</option>
										<option value="Disabled">Disabled</option>
									</select>
								</div>
							</div>
						</div>







						<br>
						<mat-divder class="divider"></mat-divder>
						<h4>Coupon Items</h4>


						<!-- CATS, PRODS -->
						<div style="display: flex; flex-direction: row;">
							<div class="form-group col-sm-3">
								<div class="form-group">
									<label class="col-sm-12 control-label" id="input-product">Entry Category</label>
								</div>
								<div class="col-sm-10">


									<div *ngFor="let cat of categories; let i = index">
										<mat-checkbox [formControl]="catcontrols[i].control">{{cat.categoryid}}: {{cat.categorydescription}}</mat-checkbox>
									</div>
								</div>
							</div>
							<div class="form-group col-sm-10">
								<div class="col-sm-12" style="display: flex; flex-direction: row;">
									<div class="form-group col-sm-6">
										<label class="col-sm-12 control-label" id="input-product">Entry Product</label>
									</div>
									<div class="form-group col-sm-6">
										<label class="col-sm-12 control-label" id="input-product">Applicable Items</label>
									</div>
								</div>
								<div style="display: flex; flex-direction: row;">
									<div class="col-sm-6">
										<form style="display: flex;">
											<input #category type="text" name="category" value="" style="display: flex; flex-grow: 1;" placeholder="Entry Product" (keyup)="productSearch($event)"/>
										</form>
										<div *ngIf="prodsReady;else emptytable" class="prodTable">
											<table mat-table [dataSource]="prodSearch['datasource']">


											<ng-container matColumnDef="stockid">
												<th mat-header-cell *matHeaderCellDef> Stock ID </th>
												<td mat-cell *matCellDef="let element" style="font-size: 12px;">
													{{element.stockid}}
													<br>
													<span style="font-style: italic; color: rgb(119, 119, 119);">{{element.description}}</span>
												</td>
											</ng-container>



											<tr mat-header-row *matHeaderRowDef="prodSearch['displayedColumns']"></tr>
											<tr mat-row *matRowDef="let row; columns: prodSearch['displayedColumns']" (click)="saveCouponListing('couponForm', 'coupon_product', row.stockid)"></tr>
											</table>
										</div>

										<ng-template #emptytable>
											<div class="prodTable" *ngIf="prodsReady == false">
											</div>
										</ng-template>

									</div>

									<div id="coupon-category" class="col-sm-4">
										<div style="height: 820px; flex: auto; overflow: auto; border: 1px solid gainsboro; border-radius: 5px;">
											<div *ngFor="let coupon_product of couponForm.value.coupon_product">
												<div (click)="removeCouponListing('couponForm', 'coupon_product', coupon_product)"><i class="fa fa-minus-circle"></i>{{ coupon_product }}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>












					</div>
				</div>
			</form>
		</div>
	</div>

	<button *ngIf="!submitting" data-toggle="tooltip" title="Save and Add Coupon" class="btn btn-primary" (click)="saveCoupon()" (keyup.enter)="$event.preventDefault(); $event.stopPropagation()" (keydown.enter)="$event.preventDefault(); $event.stopPropagation()"><i class="fa fa-save"></i></button>
	<div class="text-center contents" *ngIf='submitting'>
		<svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
			<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
		</svg>
	</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #editcouponform let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Edit Coupon</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>


	 <div class="modal-body text-dark" *ngIf="catcontrols.length > 0"><div class="panel panel-default">
		<div class="panel-heading">
			<h3 class="panel-title"><i class="fa fa-pencil"></i>Edit Coupon</h3>
		</div>
		<div class="panel-body">
			<form id="form-coupon" [formGroup]="editcouponForm" class="form-horizontal">
				<div class="tab-content">
					<div class="tab-pane active" id="tab-general">
					<mat-divder class="divider"></mat-divder>
					<h4>Coupon Settings</h4>


						<!--NAME, ENTRY CODE -->
						<div style="display: flex; flex-direction: row;">
							<div class="form-group required col-sm-6">
								<label class="col-sm-12 control-label" id="input-name">Coupon Name</label>
								<div class="col-sm-12">
									<input type="text" name="name" value="" placeholder="Entry Name" id="input-name" class="form-control" formControlName="name"/>
									<label class="text-danger" *ngIf="editcouponForm.value.name.length < 3">Name must be 3 characters or longer</label>
									<label class="text-danger" *ngIf="editcouponForm.value.name.length > 128">Name cannot be longer than 128 characters</label>
								</div>
							</div>
							<div class="form-group required col-sm-6">
								<label class="col-sm-12 control-label" id="input-code">Coupon Entry Code</label>
								<div class="col-sm-12">
									<input type="text" name="name" value="" placeholder="Entry Name" id="input-name" class="form-control" formControlName="code" />
									<label class="text-danger" *ngIf="editcouponForm.value.code.length < 3">Code must be 3 characters or longer</label>
									<label class="text-danger" *ngIf="editcouponForm.value.code.length > 20">Code cannot be longer than 20 characters</label>
								</div>
							</div>
						</div>



						<div style="display: flex; flex-direction: row;">
							<div class="form-group col-sm-6">
								<label class="col-sm-12 control-label" id="input-date-start">Effective Start Date</label>
								<div class="col-sm-12">
									<input type="date" name="date_start" value="" placeholder="Start Date" data-date-format="YYYY-MM-DD" id="input-date-start" class="form-control"  formControlName="date_start" />
								</div>
							</div>

							<div class="form-group col-sm-6">
								<label class="col-sm-12 control-label" id="input-date-end">Effective End Date</label>
								<div class="col-sm-12">
									<input type="date" name="date_end" value="" placeholder="End Date" data-date-format="YYYY-MM-DD" id="input-date-end" class="form-control"  formControlName="date_end"/>
								</div>
							</div>
						</div>


						<div style="display:flex; flex-direction: row;">
							<div class="form-group col-sm-4">
								<label class="col-sm-12 control-label" id="input-uses-total">
									<span data-toggle="tooltip" title="">Number of Uses</span>
								</label>
								<div class="col-sm-12">
									<label class="text-danger" *ngIf="editcouponForm.value.uses_total < 1">Total Uses must be at least 1</label>
									<input type="number" min="1" step="1" name="uses_total" value="1" placeholder="Uses Total" id="input-uses-total" class="form-control" formControlName="uses_total"/>
								</div>
							</div>

							<div class="form-group col-sm-4">
								<label class="col-sm-12 control-label" id="input-uses-total">
									<span data-toggle="tooltip" title="">Uses Customer ID</span>
								</label>
								<div class="col-sm-12">
									<input type="text" name="uses_customer_ID" value="" placeholder="Enter Customer ID" id="input-uses-total" class="form-control" formControlName="uses_customer"/>
								</div>
							</div>

							<div class="form-group col-sm-4">
								<label class="col-sm-12 control-label" id="input-uses-total">
									<span data-toggle="tooltip" title="">Status?</span>
								</label>

								<div class="col-sm-12">
									<select name="type" id="input-type" class="form-control"  formControlName="status">
										<option value="Enabled">Enabled</option>
										<option value="Disabled">Disabled</option>
									</select>
								</div>
							</div>
						</div>


						<!-- TYPE, AMOUNT, TOTAL -->
						<div style="display: flex; flex-direction: row;">
							<div class="form-group col-sm-4">
								<label class="col-sm-12 control-label" id="input-type"><span data-toggle="tooltip" title=""></span>Coupon Type</label>
								<div class="col-sm-12">
									<select name="type" id="input-type" class="form-control"  formControlName="type"  >
										<option value="Percent" selected="selected">Percent</option>
										<option value="Amount">Amount</option>
									</select>
								</div>
							</div>

							<div class="form-group col-sm-4">
								<label class="col-sm-12 control-label" id="input-discount">Discount Amount</label>
								<div class="col-sm-12">
									<label class="text-danger" *ngIf="editcouponForm.value.discount < 0">Discount amount must be positive</label>
									<label class="text-danger" *ngIf="editcouponForm.value.discount > 100 && editcouponForm.value.type == 1">Discount cannot be greater than 100</label>
									<input type="number" min="0" name="name" value="" placeholder="Coupon Discount" class="form-control" formControlName="discount" />
								</div>
							</div>

							<div class="form-group col-sm-4">
								<label class="col-sm-12 control-label" id="input-total">Entry Total</label>
								<div class="col-sm-12">
									<label class="text-danger" *ngIf="editcouponForm.value.total < 0">Total must be positive</label>
									<input type="number" min="0" name="name" value="" placeholder="Entry Total" class="form-control" formControlName="total" />
								</div>
							</div>
						</div>


						<div style="display: flex; flex-direction: row; padding: 0px;" class="col-sm-12">
							<div class="form-group col-sm-4">
								<label class="col-sm-12 control-label" id="input-shipping">Entry Shipping</label>
								<div class="col-sm-12">
									<select name="type" id="input-type" class="form-control"  formControlName="shipping">
										<option value="Enabled">Enabled</option>
										<option value="Disabled">Disabled</option>
									</select>
								</div>
							</div>

							<div class="form-group col-sm-4">
								<label class="col-sm-12 control-label" id="input-uses-total">
									<span data-toggle="tooltip" title="">Is Taxable?</span>
								</label>

								<div class="col-sm-12">
									<select name="type" id="input-type" class="form-control"  formControlName="taxable">
										<option value="Enabled">Enabled</option>
										<option value="Disabled">Disabled</option>
									</select>
								</div>
							</div>

							<div class="form-group col-sm-4">
								<label class="col-sm-12 control-label" id="input-uses-total">
									<span data-toggle="tooltip" title="">Is Stackable?</span>
								</label>

								<div class="col-sm-12">
									<select name="type" id="input-type" class="form-control"  formControlName="stackable">
										<option value="Enabled">Enabled</option>
										<option value="Disabled">Disabled</option>
									</select>
								</div>
							</div>
						</div>



						<br>
						<mat-divder class="divider"></mat-divder>
						<h4>Coupon Items</h4>


						<!-- CATS, PRODS -->
						<div style="display: flex; flex-direction: row;">
							<div class="form-group col-sm-3">
								<div class="form-group">
									<label class="col-sm-12 control-label" id="input-product">Entry Category</label>
								</div>
								<div class="col-sm-10">
									<div *ngFor="let cat of categories; let i = index">
										<mat-checkbox [formControl]="catcontrols[i].control">{{cat.categoryid}}: {{cat.categorydescription}}</mat-checkbox>
									</div>
								</div>
							</div>
							<div class="form-group col-sm-10">
								<div class="col-sm-12" style="display: flex; flex-direction: row;">
									<div class="form-group col-sm-6">
										<label class="col-sm-12 control-label" id="input-product">Entry Product</label>
									</div>
									<div class="form-group col-sm-6">
										<label class="col-sm-12 control-label" id="input-product">Applicable Items</label>
									</div>
								</div>
								<div style="display: flex; flex-direction: row;">
									<div class="col-sm-6">
										<form style="display: flex;">
											<input #category type="text" name="category" value="" style="display: flex; flex-grow: 1;" placeholder="Entry Product" (keyup)="productSearch($event)"/>
										</form>
										<div *ngIf="prodsReady;else emptytable" class="prodTable">
											<table mat-table [dataSource]="prodSearch['datasource']">


											<ng-container matColumnDef="stockid">
												<th mat-header-cell *matHeaderCellDef> Stock ID </th>
												<td mat-cell *matCellDef="let element" style="font-size: 12px;">
													{{element.stockid}}
													<br>
													<span style="font-style: italic; color: rgb(119, 119, 119);">{{element.description}}</span>
												</td>
											</ng-container>



											<tr mat-header-row *matHeaderRowDef="prodSearch['displayedColumns']"></tr>
											<tr mat-row *matRowDef="let row; columns: prodSearch['displayedColumns']" (click)="saveCouponListing('editcouponForm', 'coupon_product', row.stockid)"></tr>
											</table>
										</div>

										<ng-template #emptytable>
											<div class="prodTable" *ngIf="prodsReady == false">
											</div>
										</ng-template>

									</div>

									<div id="coupon-category" class="col-sm-4">
										<div style="height: 820px; flex: auto; overflow: auto; border: 1px solid gainsboro; border-radius: 5px;">
											<div *ngFor="let coupon_product of editcouponForm.value.coupon_product">
												<div (click)="removeCouponListing('editcouponForm', 'coupon_product', coupon_product)"><i class="fa fa-minus-circle"></i>{{ coupon_product }}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>


					</div>
				</div>
			</form>
		</div>
	</div>

	<button *ngIf="!submitting" data-toggle="tooltip" title="Save and Add Coupon" class="btn btn-primary" (click)="saveEditCoupon()" (keyup.enter)="$event.preventDefault(); $event.stopPropagation()" (keydown.enter)="$event.preventDefault(); $event.stopPropagation()"><i class="fa fa-save"></i></button>
	<div class="text-center contents" *ngIf='submitting'>
		<svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
			<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
		</svg>
	</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
