<!--
Use this screen to enter payments FROM your bank account.
Note: To enter a payment FROM a supplier, use a negative Payment amount.
-->
<form [formGroup]="paymentForm" *ngIf="paymentForm">
	<div class="row mt-3">
		<div class="col-md-12 ml-auto mr-auto text-center">
			<mat-button-toggle-group #charge="matButtonToggleGroup" aria-label="Charge" (change)="setChargeType($event)" class="row" formControlName="payment_type" color="accent">
				<mat-button-toggle [value]="method.paymentname" [ngClass]="method.paymentname == chargetype ? 'text-light btn-success' : 'text-dark btn-light'" *ngFor="let method of payment_methods">{{method.paymentname }}</mat-button-toggle>
			</mat-button-toggle-group>
		</div>
		<div class="col-md-6">

			<div class="col-md-12 text-left">
				<mat-form-field appearance="outline">
					<mat-label>Date</mat-label>
					<input matInput formControlName="datepaid" [matDatepicker]="datepaid">
					<mat-datepicker-toggle matSuffix [for]="datepaid"></mat-datepicker-toggle>
					<mat-datepicker #datepaid></mat-datepicker>
				</mat-form-field>
			</div>
			<div class="col-md-12 text-left">
				<mat-form-field appearance="outline">
					<mat-label>Bank Account</mat-label>
					<mat-select formControlName="bankaccount">
						<mat-option *ngFor="let method of bankaccounts" [value]="method.accountcode">
							{{method.bankaccountname }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-12 text-left">
				<mat-form-field appearance="outline">
					<mat-label>Reference</mat-label>
					<input type="text" class="text-right mt-0 pt-0" placeholder="Reference" formControlName="reference" aria-label="Reference" matInput />
				</mat-form-field>
			</div>
			<div class="col-md-12 text-left">
				<mat-form-field appearance="outline">
					<mat-label>Check #</mat-label>
					<input type="text" class="text-right mt-0 pt-0" placeholder="Reference" formControlName="checkno" aria-label="Reference" matInput />
				</mat-form-field>
			</div>
			<div class="col-md-12 text-left">
				<mat-form-field appearance="outline">
					<mat-label>Description</mat-label>
					<input type="text" class="text-right mt-0 pt-0" placeholder="Reference" formControlName="description" aria-label="Reference" matInput />
				</mat-form-field>
			</div>
			<div class="col-md-12 text-left">
				<mat-form-field appearance="outline">
					<mat-label>Discount</mat-label>
					<input type="text" formControlName="discount" class="text-right mt-0 pt-0" placeholder="Reference" aria-label="Reference" matInput />
					<span matSuffix>%</span>
				</mat-form-field>
			</div>
			<div class="col-md-12 text-left">
				<mat-form-field appearance="outline">
					<mat-label>Amount</mat-label>
					<span matPrefix>$</span>
					<input type="text" formControlName="amount" class="text-right mt-0 pt-0" placeholder="Reference" aria-label="Reference" matInput appSelectOnFocus/>

				</mat-form-field>
			</div>

			<div class="col-md-12 text-right">
				<button mat-flat-button color="accent" (click)="addPayment()">
					Add Payment
				</button>
			</div>

		</div>
		<div class="col-md-6">

				<h5 class="text-right"><b>Total Batch Posting:</b>{{ total_payments | currency}}</h5>
				<mat-divider></mat-divider>
				<div class="table">
					<table class="table table-sm table-fixed">
						<thead>
							<tr>
								<th>Type</th>
								<th>Desc</th>
								<th>Account</th>
								<th class="text-center">Disc</th>
								<th class="text-right">Amount</th>
								<th class="text-right">Total</th>
								<th class="text-right"></th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let pay of payments">
								<td>{{ pay.payment_type }}</td>
								<td>{{ pay.description }}</td>
								<td>{{ pay.bankaccount }}</td>
								<td class="text-center">{{ pay.discount }}%</td>
								<td class="text-right">{{ pay.amount | currency}}</td>
								<td class="text-right">{{ getPaymentTotal(pay) | currency}}</td>
								<td>
									<button mat-icon-button color="accent" (click)="removePayment(pay)">
										<mat-icon>remove</mat-icon>
									</button>
								</td>
							</tr>
							<ng-conatiner *ngIf="!payments.length">
								<tr>
									<td colspan="6" class="text-center">No Postings </td>
								</tr>
							</ng-conatiner>
						</tbody>
					</table>
				</div>
				<mat-divider></mat-divider>
				<div class="col-md-12 mt-3 text-right">
					<button mat-flat-button color="accent" (click)="$event.preventDefault();postPayments()">
						<mat-icon>add</mat-icon> Post Payments
					</button>
				</div>
			</div>

		<div class="col-md-12 mt-3">
			<h5 class="text-right"><b>Total Batch Posting:</b>{{ total_payments | currency}}</h5>
			<mat-divider></mat-divider>
			<div class="table">
				<table class="table table-sm table-fixed">
					<thead>
						<tr>
							<th>Type</th>
							<th>Desc</th>
							<th>Account</th>
							<th class="text-center">Disc</th>
							<th class="text-right">Amount</th>
							<th class="text-right">Total</th>
							<th class="text-right"></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let pay of payments">
							<td>{{ pay.payment_type }}</td>
							<td>{{ pay.description }}</td>
							<td>{{ pay.bankaccount }}</td>
							<td class="text-center">{{ pay.discount }}%</td>
							<td class="text-right">{{ pay.amount | currency}}</td>
							<td class="text-right">{{ getPaymentTotal(pay) | currency}}</td>
							<td>
								<button mat-icon-button color="accent" (click)="removePayment(pay)">
									<mat-icon>remove</mat-icon>
								</button>
							</td>
						</tr>
						<ng-conatiner *ngIf="!payments.length">
							<tr>
								<td colspan="6" class="text-center">No Postings </td>
							</tr>
						</ng-conatiner>
					</tbody>
				</table>
			</div>
			<mat-divider></mat-divider>
			<div class="col-md-12 mt-3 text-right">
				<button mat-flat-button color="accent" (click)="$event.preventDefault();postPayments()">
					<mat-icon>add</mat-icon> Post Payments
				</button>
			</div>
		</div>
	</div>
</form>
