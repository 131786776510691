<ng-template #monthView let-modal>
	<div class="modal-header">
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<ng-container *ngIf="datepicked">
			<app-day-sales [inputdate]="datepicked"></app-day-sales>
		</ng-container>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>


<div class="main-content">
	<div class="col-md-12">

		<div class="container-fluid tp-box" [@flipState]="flip">
			<div class="row tp-box__side tp-box__back" [ngClass]="flip != 'inactive' ? '' : 'd-none'">

			</div>
			<div class="row tp-box__side tp-box__front" id="frontside" [ngClass]="flip == 'inactive' ? '' : 'd-none'">
				<!-- NO PURCHASE -->
				<div class="col-md-4" [ngClass]="viewingdata ? 'col-md-12' : 'col-md-4'">
					<app-customer-open-quotes (viewData)="viewingData($event)" ></app-customer-open-quotes>
				</div>
				<div class="col-md-4" [ngClass]="viewingdata ? 'col-md-12' : 'col-md-4'" >
					<app-new-customers (viewData)="viewingData($event)" ></app-new-customers>
				</div>
				<div class="col-md-4" >
					<app-customer-no-purchase></app-customer-no-purchase>
				</div>
				<div class="col-md-12">
					<div class="card">
						<div class="card-body" [ngClass]="dayInvoices ? 'daydrilldown card-body':'card-body'">
							<div class="card-actions">
<!--
									<button mat-raised-button type="button" class="btn btn-info btn-link" matTooltip="Refresh" [matTooltipPosition]="'below'" (click)="loadData()">
									<i class="material-icons">refresh</i>
								</button>

									<button mat-raised-button type="button" class="btn btn-default btn-link" matTooltip="Change Date" [matTooltipPosition]="'below'">
								  <i class="material-icons">edit</i>
								</button> -->

							</div>


							<!-- <h4 class="card-title" *ngIf="overLastStatus.viewing == 'year'">Sales This/Last</h4>
							<h4 class="card-title" *ngIf="overLastStatus.viewing == 'month'">Sales This/Last For {{overLastStatus.monthname}}
								<button mat-raised-button class="float-right" (click)="closeDayView()">Back</button>
							</h4> -->

							<div class="row w-100 card-title">
								<div class="col-4">
									<h4 class="card-title w-100 d-flex justify-content-between">
										<ng-container *ngIf="overLastStatus.viewing == 'year'">Sales This/Last</ng-container>
										<ng-container *ngIf="overLastStatus.viewing == 'month'">Sales This/Last for {{overLastStatus.monthname}}</ng-container>
										<ng-container *ngIf="overLastStatus.viewing == 'day' &&
										overLastStatus.fromyear !== overLastStatus.toyear">Sales for {{overLastStatus.monthname}} {{overLastStatus.day}}, {{overLastStatus.fromyear}} - {{overLastStatus.toyear}}</ng-container>
										<ng-container
										*ngIf="overLastStatus.viewing == 'day' &&
										overLastStatus.fromyear == overLastStatus.toyear">Sales for {{overLastStatus.monthname}} {{overLastStatus.day}}, {{overLastStatus.fromyear}}</ng-container>
									</h4>
								</div>
								<div class="col-4 text-center">
									<ng-container *ngIf="viewingInvoice"><span class="font-weight-lighter ">Viewing #</span><h4 class="font-weight-bolder">&nbsp;{{viewingInvoice['orderno']}}</h4></ng-container>
								</div>
								<div class="col-4 font-weight-lighter ">
									<button mat-raised-button class="float-right" *ngIf="['month', 'day'].includes(overLastStatus.viewing)" color="primary" (click)="overLastBack()">Back</button>
									<div class="float-right row m-auto " *ngIf="overLastStatus.loading">
										<span>{{overLastStatus.message}}</span>
										<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>
									</div>
								</div>
							</div>

							<h5 *ngIf="thisoverlast && !dayInvoices">
								UNITS MTD/MTDL: {{ thisoverlast.mtd_units }} / {{ thisoverlast.mtdlast_units }}

								<span [ngClass]="thisoverlast.percent_updown_units > 0 ? 'text-success' : 'text-danger'"><i class="fa " [ngClass]="thisoverlast.percent_updown_units > 0 ? 'fa-long-arrow-up' : 'fa-long-arrow-down'"></i></span>

								<span [ngClass]="thisoverlast.percent_updown_units < 0 ? 'text-danger': 'text-success'"> {{ thisoverlast.percent_updown_units | percent}}
								</span>

								<br /> SALES MTD/MTDL: {{ thisoverlast.mtd_dollars | currency }} / {{ thisoverlast.mtdlast_dollars | currency}}

								<span [ngClass]="thisoverlast.percent_updown_dollars > 0 ? 'text-success' : 'text-danger'"><i class="fa " [ngClass]="thisoverlast.percent_updown_dollars > 0 ? 'fa-long-arrow-up' : 'fa-long-arrow-down'"></i></span>

								<span [ngClass]="thisoverlast.percent_updown_dollars < 0 ? 'text-danger': 'text-success'"> {{ thisoverlast.percent_updown_dollars | percent}}
								</span>
							</h5>
							<p class="card-category"></p>
							<div class="ct-chart" *ngIf="!thisoverlast">
								<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
							</div>

							<div class="chart-container bg-white " [ngClass]="dayInvoices == false ? '' : 'none'">
								<canvas id="thisYoy" class="ml-auto mr-auto w-100 h-100 chartforced">{{lineover}}</canvas>
							</div>
								<div class="row p-0 m-0 invoice-drilldown" >
									<div class="invoice-fixed p-0 m-0 mat-elevation-z1" *ngIf="displaydetails && dayInvoices">
										<!-- <div [innerHTML]="displaydetails.display"></div> -->
										<ngx-extended-pdf-viewer id="pdfviewer" *ngIf='displaydetails.display' [base64Src]="displaydetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true"></ngx-extended-pdf-viewer>
									</div>
									<table class="w-50 table p-0 m-0 i_table" *ngIf="dayInvoices">
										<thead class="sticky-top ">
											<th>Order #</th>
											<th>Type</th>
											<th>Customer #</th>
											<th>Name</th>
											<th>Terms</th>
											<th>Date</th>
											<th>Total</th>
										</thead>
										<tbody>
											<ng-container *ngFor="let row of dayInvoices">
												<tr (click)="openDocument(row.orderno, row.type, row.id, details, 'pdf')" [ngClass]="viewingInvoice['id'] == row.id ? 'selected i_row' : 'i_row'">
													<td>{{row.orderno}}</td>
													<td>
														<ng-container *ngIf="row.type == 10">Invoice</ng-container>
														<ng-container *ngIf="row.type == 11">Credit</ng-container>
													</td>
													<td>{{row.debtorno}}</td>
													<td>{{row.brname}}</td>
													<td>{{row.payterms}}</td>
													<td>{{row.orddateformatted}}</td>
													<td>{{row.ovamount | currency}}</td>
													<td></td>
												</tr>
											</ng-container>
										</tbody>
									</table>
								</div>


							<div class="table mt-3 table-responsive" *ngIf="thisoverlast && !dayInvoices">
								<table class="table table-bordered " *ngFor="let year of thisoverlast.years;let i = index;">
									<tr>
										<ng-container *ngFor="let month of year.sales;let bf = index;">
											<th *ngIf="bf == 0" class="text-center yearpercent">
												{{ month.year }}
											</th>
											<th class="text-center yearpercent" [ngClass]="overLastStatus.viewing == 'month' ? 'p-3' : ''">
												{{ month.label }}
												<br>

												<ng-container *ngIf="(i != ( thisoverlast.years.length - 1 ))">
													<div class="row p-0 m-0" [ngClass]="overLastStatus.viewing == 'month' ? 'pl-3 pr-3 w-80' : ''">
														<div class="col-6 m-0 p-0 smaller text-left" [ngClass]="getMonthYearQty(month.label, year).class">

															<i class="smaller fa " [ngClass]="getMonthYearQty(month.label, year).icon"></i>
															Y:{{ getMonthYearQty(month.label, year).percent | percent }}
														</div>
														<div class="col-6 m-0 p-0 smaller text-right" [ngClass]="getMonthYearQty(month.label, year).percent_month_color">

															<i class="smaller fa " [ngClass]="getMonthYearQty(month.label, year).percent_month_icon"></i>
															M:{{ getMonthYearQty(month.label, year).percent_month | percent }}

														</div>
													</div>
												</ng-container>
											</th>
										</ng-container>
										<th class="text-center">Total</th>
									</tr>
									<tr>
										<td class="text-center">Qty</td>
										<td class="text-center yearpercent" *ngFor="let month of year.qty">
											<button *ngIf="overLastStatus.viewing == 'year'" mat-flat-button (click)="viewHistory( month.monthnumber, month.year)">{{ month.value | number:'1.0-0' }}</button>
											<button *ngIf="overLastStatus.viewing == 'month'" mat-flat-button (click)="getDaySales(month.daynumber, month.year,'')">{{ month.value | number:'1.0-0' }}</button>
										</td>
										<td class="text-center">{{ getQtyTotalYear( year ) | number:'1.0-0'  }}
										</td>
									</tr>
									<tr>
										<td class="text-center">Sales </td>
										<td class="text-center yearpercent" *ngFor="let month of year.sales">
											{{ month.value | currency }}
										</td>
										<td class="text-center">{{ getSaleTotalYear( year ) | currency }}</td>
									</tr>
									<!-- <tr>
										<td class="text-center">Cost </td>
										<td class="text-center" *ngFor="let month of year.cost">
											{{ month.value | currency }}
										</td>
										<td class="text-center">{{ getCostTotalYear( year ) | currency }}</td>
									</tr> -->
								</table>
							</div>
						</div>
					</div>
				</div>



				<div class="col-md-6">

				</div>
				<div class="col-md-6">

				</div>
				<!-- <div class="col-md-12">
					<app-brand-report [customer]="false"></app-brand-report>
				</div>
				<div class="col-md-6" *ngIf="telegramAllowed">
					<div class="card card-chart">
						<div class="card-header card-header-icon card-header-danger">
							<div class="card-icon">
								<i class="material-icons">pie_chart</i>
							</div>
							<h4 class="card-title">Product Line Sales Top 10 </h4>
						</div>
						<div class="card-body">
							<div class="chart-container bg-white" >
								<canvas id="plineChart" >{{linechart}}</canvas>
							</div>
							<div class="boxed" >
								<table class="table table-fixed ">
									<tr *ngFor="let t of catandlines.line_sales">
										<td>
											<span class="labeldata {{t.className}}"> &nbsp;&nbsp;</span>&nbsp;
											<span *ngIf="t.label != ''">{{ t.label }}</span>
											<span *ngIf="t.label == ''">**UNKNOWN**</span>
										</td>
										<td class="text-right">{{ t.value | currency }}</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6" *ngIf="telegramAllowed">
					<div class="card card-chart">
						<div class="card-header card-header-icon card-header-danger">
							<div class="card-icon">
								<i class="material-icons">pie_chart</i>
							</div>
							<h4 class="card-title">Product Line Sales Lowest 10  </h4>
						</div>
						<div class="card-body">
							<div class="chart-container bg-white" >
								<canvas id="plineChartR" >{{linechartreverse}}</canvas>
							</div>
							<div class="boxed" >
								<table class="table table-fixed ">
									<tr *ngFor="let t of catandlines.line_sales_reversed">
										<td>
											<span class="labeldata {{t.className}}"> &nbsp;&nbsp;</span>&nbsp;
											<span *ngIf="t.label != ''">{{ t.label }}</span>
											<span *ngIf="t.label == ''">**UNKNOWN**</span>
										</td>
										<td class="text-right">{{ t.value | currency }}</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6" *ngIf="telegramAllowed">
					<div class="card card-chart">
						<div class="card-header card-header-icon card-header-danger">
							<div class="card-icon">
								<i class="material-icons">pie_chart</i>
							</div>
							<h4 class="card-title">Category Sales</h4>
						</div>
						<div class="card-body">
							<div class="chart-container bg-white" >
								<canvas id="catChartRef">{{catchart}}</canvas>
							</div>
							<div class="boxed">
							<table class="table table-fixed ">
								<tr *ngFor="let t of catandlines.category_sales">
									<td>
										<span class="labeldata {{t?.className}}">&nbsp;&nbsp;</span>&nbsp;
										<span *ngIf="t.label != ''">{{ t.label }}</span>
										<span *ngIf="t.label == ''">**UNKNOWN**</span>
									</td>
									<td class="text-right">{{ t.value | currency }}</td>
								</tr>
							</table>
							</div>
						</div>
					</div>
				</div>
				-->

			</div>
		</div>
	</div>
</div>
