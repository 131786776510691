<ng-container *ngIf="invoice_result">
	<div class="row">
		<div class="col-md-12 text-center">
			<h4>{{document_text}}
				<span *ngIf="invoice_result.invoice.transno">{{ invoice_result.invoice.transno }}#</span>
				<span *ngIf="invoice_result.transno && !invoice_result.invoice.transno">{{ invoice_result.transno }}#</span>
				Created
			</h4>
			<br />
			<a class="btn btn-danger btn-sm" href="{{ baseUrl }}{{invoiceLink}}{{ invoice_result.invoice.id }}"><i class="fa fa-file-pdf-o"></i> PDF </a>
			<a class="btn btn-warning btn-sm" [routerLink]="'/customers/view/'+order.header.debtorno"> View Customer </a>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="!invoice_result">
	<ng-container *ngIf="order">
		<form [formGroup]="documentDetails" *ngIf="documentDetails">
			<div class="row mt-0 ml-0 mr-0 pl-0 pr-0">
				<div class="col-sm-9 col-md-9">
					<div class="row mt-0 ml-0 mr-0 pl-0 pr-0">
						<div class="col-md-12 m-0 p-0">
							<div class="row m-0 p-0 mt-0 ">
								<div class="col-md-5 mt-0 card-group m-0 p-0 ">
									<div class="col-md-6 card-group ml-0 pl-0 ">
										<div class="card no-shadow">
											<div class="card-header pt-1 bg-light mb-0 pb-1 border-bottom">
												<h6 class="card-title mb-0 pb-0  ">Bill To</h6>
											</div>
											<div class="card-body " [innerHTML]="order.header.billto">
											</div>
										</div>
									</div>
									<div class="col-md-6 card-group pr-0 mr-0">
										<div class="card no-shadow">
											<div class="card-header pt-1 bg-light mb-0 pb-1 border-bottom">
												<h6 class="card-title mb-0 pb-0  ">Ship To</h6>
											</div>
											<div class="card-body mt-0 " [innerHTML]="order.header.shipto">
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-7 mt-0 card-group">
									<div class="card no-shadow">
										<div class="card-body">
											<div class="row">
												<div class="col-md-4">
													<mat-form-field appearance="outline" class="text-right">
														<mat-label>{{ document_text }} Date</mat-label>
														<input matInput formControlName="invoiceDate" [matDatepicker]="invoicedatepicker" appNoEnterKey>
														<mat-datepicker-toggle matSuffix [for]="invoicedatepicker"></mat-datepicker-toggle>
														<mat-datepicker #invoicedatepicker></mat-datepicker>
													</mat-form-field>
												</div>
												<div class="col-md-4">
													<mat-form-field class="full-width" appearance="outline" class="text-center">
														<mat-label>#Packages</mat-label>
														<input matInput formControlName="packages" matInput placeholder="#Packages" appNoEnterKey appNumericField>
													</mat-form-field>
												</div>
												<div class="col-md-4">
													<mat-form-field appearance="outline">
														<mat-label>Sales Person</mat-label>
														<mat-select formControlName="salesperson" appNoEnterKey>
															<mat-option *ngFor="let person of salespeople" [value]="person.salesmancode">
																{{ person.salesmanname }}
															</mat-option>
														</mat-select>
													</mat-form-field>
												</div>
												<div class="col-md-4">
													<mat-form-field class="full-width" appearance="outline">
														<mat-label>Reference#</mat-label>
														<input matInput formControlName="customerref" matInput placeholder="Invoice Note" appNoEnterKey>
													</mat-form-field>
												</div>
												<div class="col-md-4">
													<mat-form-field appearance="outline">
														<mat-label>Ship Via</mat-label>
														<mat-select formControlName="shipvia" appNoEnterKey>
															<mat-option *ngFor="let ship of shipvias" [value]="ship.shipper_id">
																{{ ship.shippername }}
															</mat-option>
														</mat-select>
													</mat-form-field>
												</div>
												<div class="col-md-4">
													<mat-form-field appearance="outline">
														<mat-label>{{ doc_return_type }}</mat-label>
														<mat-select formControlName="document_type" appNoEnterKey>
															<mat-option *ngFor="let t of document_types" [value]="t.value">
																{{ t.label }}
															</mat-option>
														</mat-select>
													</mat-form-field>
												</div>
												<div class="col-md-4">
													<mat-form-field class="full-width" appearance="outline">
														<mat-label>Email</mat-label>
														<input matInput formControlName="email" matInput placeholder="Invoice Note" appNoEnterKey>
													</mat-form-field>
												</div>
												<div class="col-md-4">
													<mat-form-field class="full-width" appearance="outline">
														<mat-label>Picked By</mat-label>
														<input matInput formControlName="pickedby" matInput placeholder="Picked By" appNoEnterKey>
													</mat-form-field>
												</div>
												<div class="col-md-4">
													<mat-form-field class="full-width" appearance="outline">
														<mat-label>Packed By</mat-label>
														<input matInput formControlName="packedby" matInput placeholder="Packed By" appNoEnterKey>
													</mat-form-field>
												</div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-md-12">


							<div class="table table-responsive">
								<table class="table table-striped table-hover table-bordered">
									<thead class="thead-dark">
										<tr>
											<th>Item Description / Stock ID</th>
											<th class="text-center">QOH</th>
											<th class="text-right">Price</th>
											<th class="text-right">
												<i class="fas fa-file-invoice"></i> Invoice / Ordered
											</th>
											<th class="text-center">Sub-Total</th>
											<th class="text-right">Tax</th>
											<th class="text-right">Line Total</th>
										</tr>
									</thead>
									<tbody>
										<ng-container *ngFor="let item of order.details; let i = index">
											<tr>
												<td class="pl-3">
													<strong class="item-description">{{ item.description }}</strong><br>
													<small class="text-muted stock-id">{{ item.stockid }}</small>
												</td>
												<td class="text-center">{{ item.qoh }} {{ item.units }}</td>
												<td class="text-right">
													<strong class="price">{{ item.discountedprice | currency }}</strong>
													<small *ngIf="item.discountpercent > 0" class="text-muted">
														<br>{{ item.unitprice | currency }} @ {{ item.dspdiscount }}
													</small>
												</td>
												<td class="text-right">
													<mat-form-field class="full-width text-right dispatch-field" appearance="outline">
														<input matInput (keyup)="updateDispatchQty($event, item)" type="text" placeholder="Dispatch" [(value)]="item.dispatch" [ngClass]="{'text-danger bolder': item.qoh < item.ordered}" appSelectOnFocus />
														<mat-hint class="text-right invoiced-info" *ngIf="item.qtyremain !== item.ordered">
															{{ item.qtyinvoiced }} / {{ item.ordered }} Invoiced
														</mat-hint>
														<span matSuffix>
															<span [ngClass]="{'text-danger bolder': item.qoh < item.ordered}">
																/ {{ item.qtyremain }}
															</span>
														</span>
														<mat-error></mat-error>
													</mat-form-field>
												</td>
												<td class="text-center subtotal">{{ item.linesubtotal | currency }}</td>
												<td class="text-right">
													<ng-container *ngFor="let tax of item.taxes; let ix = index">
														<mat-form-field class="full-width text-right tax-field" appearance="outline">
															<mat-label>{{ tax.description }}</mat-label> <!-- Tax description as label -->
															<input
																matInput
																type="text"
																[(value)]="tax.taxrate"
																(input)="setTax($event, item, ix)"
																appSelectOnFocus
																class=""
															/>
															<mat-hint class="text-right">Total: {{ tax.total | currency }}</mat-hint>
															<span matSuffix>%</span>
														</mat-form-field>
													</ng-container>
												</td>

												<td class="text-right font-weight-bold total">{{ item.linetotal | currency }}</td>
											</tr>
											<tr *ngIf="item.narrative" class="bg-light">
												<td colspan="7">
													<input type="text" class="form-control note" placeholder="Note" [(value)]="item.narrative" />
												</td>
											</tr>
										</ng-container>
										<tr *ngIf="!order.details">
											<td colspan="7" class="text-center text-primary">NO ITEMS TO {{ document_text | uppercase }}</td>
										</tr>
									</tbody>
								</table>
							</div>

						</div>
					</div>
				</div>
				<div class="col-sm-3 col-md-3 bg-light text-right left-seperator ">
					<div class="row">
						<div class="col-12">
							<mat-form-field class="memo" appearance="fill">
								<mat-label> Memo</mat-label>
								<textarea matInput formControlName="invoiceText" placeholder="Memo For the Customer" appNoEnterKey></textarea>
							</mat-form-field>
						</div>

						<div class="col-12">
							<mat-form-field appearance="fill">
								<mat-label>Terms</mat-label>
								<mat-select formControlName="terms" appNoEnterKey>
									<mat-option *ngFor="let term of terms" [value]="term.termsindicator">
										{{ term.terms }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
					<h5 class="text-right font-weight-bold mb-0">NET TOTAL</h5>
					<h4 class="bolder text-primary text-right">{{ totals.total | currency }}</h4>

					<!-- Shipping Charge -->
					<mat-form-field class="shipping-field full-width" appearance="outline">
						<mat-label>Shipping Charge</mat-label>
						<span matPrefix (click)="setFreightChargeZero()" class="remove-icon">
							<mat-icon>remove</mat-icon>
						</span>
						<input matInput type="text" [(value)]="order.header.freightcost" (input)="setFreightCharge($event)" placeholder="Shipping Charge">
						<span matSuffix>&nbsp;$</span>
					</mat-form-field>

					<!-- Freight Tax -->
					<ng-container *ngFor="let ftax of order.freight_tax; let i = index">
						<mat-form-field class="shipping-tax-field full-width" appearance="outline">
							<mat-label>{{ ftax.description }} {{ ftax.taxrate }}%</mat-label>
							<input matInput type="text" [(value)]="ftax.taxrate" (input)="setFreightTax($event, ftax)" placeholder="Freight Tax" appNoEnterKey>
							<span matSuffix>&nbsp;%</span>
							<mat-hint *ngIf="order.header.freightcost">
								{{ (ftax.taxrate / 100) * order.header.freightcost | currency }}
							</mat-hint>
						</mat-form-field>
					</ng-container>

					<!-- Adjustments -->
					<mat-form-field class="adjustment-field full-width mt-2" appearance="outline" *ngIf="config && config.orderadjustments">
						<mat-label>Adjustment Value</mat-label>
						<span matPrefix>$</span>
						<input matInput type="text" [(value)]="adjustment" (input)="setAdjustment($event.target.value)" placeholder="Adjustment Amount">
					</mat-form-field>

					<mat-form-field class="adjustment-field full-width" appearance="outline" *ngIf="config && totals.adjustment != 0 && config.orderadjustments">
						<mat-label>Adjustment Reason</mat-label>
						<input matInput type="text" [(value)]="adjustmenttext" (input)="setAdjustmentText($event)" placeholder="Adjustment Reason">
					</mat-form-field>

					<!-- Totals -->
					<h5 class="text-right font-weight-bold mb-0">Totals</h5>
					<ul class="list-group text-right ml-0 mr-0 no-border no-background">
						<li class="list-group-item d-flex justify-content-between border-bottom">
							<span class="font-weight-bold">Sub-Total:</span>
							<span>{{ totals.subtotal | currency }}</span>
						</li>
						<li class="list-group-item d-flex justify-content-between border-bottom">
							<span class="font-weight-bold">Shipping:</span>
							<span>{{ totals.freight | currency }}</span>
						</li>
						<li class="list-group-item d-flex justify-content-between border-bottom">
							<span class="font-weight-bold">Tax:</span>
							<span>{{ totals.tax | currency }}</span>
						</li>
						<li class="list-group-item d-flex justify-content-between border-bottom" *ngIf="totals.adjustment != 0">
							<span class="font-weight-bold">Adjustment:</span>
							<span>{{ totals.adjustment }}</span>
						</li>

						<li class="list-group-item d-flex justify-content-between border-bottom">
							<span class="font-weight-bold">Total: </span>
							<span class="text-success font-weight-bold">
								<ng-container *ngIf="totals.adjustment">
									{{ financial(totals.total) + financial(totals.adjustment) | currency }}
								</ng-container>
								<ng-container *ngIf="!totals.adjustment">
									{{ financial(totals.total) | currency }}
								</ng-container>
							</span>
						</li>
						<li class="list-group-item d-flex justify-content-between border-bottom">
							<span class="font-weight-bold text-uppercase">Balance Due:</span>
							<span [ngClass]="{'text-danger': getBalance() > 0, 'text-success': getBalance() <= 0}">
									{{ getBalance() | currency }}
								</span>
						</li>
					</ul>

					<!-- Optional Alert if payment is required -->
					<h4 class="text-center alert alert-danger mt-3"
						*ngIf="(getBalance() > 0 && termRequiresPayment()) && (document_text != 'Credit')">
						CUSTOMER TERM REQUIRES PAYMENT: {{ getBalance() | currency }}
					</h4>

					<mat-divider></mat-divider>

					<ng-container *ngIf="order.details">
						<ng-container *ngIf="document_text !='Credit'">
							<ng-container *ngIf="order && !order.preauthdetails && !config.arpreauth">
								<app-order-payments [totals]="totals" [order_header]="order.header" [debtorno]="order.header.debtorno" [branchcode]="order.header.branchcode" [orderno]="order.header.orderno" [payments]="payments" (payments_added)="updatePayments($event)" (payments_removed)="updatePayments($event)" [document_type]="document_type"></app-order-payments>
								<ul class="list-group text-right ml-0 mr-0 no-border no-background">
									<li class="list-group-item d-flex justify-content-between border-bottom">
										<span class="font-weight-bold text-uppercase">Balance Due:</span>
										<span [ngClass]="{'text-danger': getBalance() > 0, 'text-success': getBalance() <= 0}">
												{{ getBalance() | currency }}
											</span>
									</li>
								</ul>
							</ng-container>
							<ng-container *ngIf="order && order.preauthdetails && config.arpreauth">
								<p [ngClass]="{'auth-message-error': roundToDecimalPlaces(order.preauthdetails.authtotal , 2 ) !== roundToDecimalPlaces(totals.total + totals.adjustment,2), 'auth-message': roundToDecimalPlaces(order.preauthdetails.authtotal,2) === roundToDecimalPlaces(totals.total + totals.adjustment,2)}">
									{{ order.preauthdetails.message }}
								</p>
							</ng-container>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="!sending && order.details">
						<ng-container *ngIf="getTotalDispatching() != 0">
							<button mat-raised-button color="accent" class="mt-3 mb-3" (click)="createInvoice()">{{ document_text }} Account</button>
						</ng-container>
						<ng-container *ngIf="getTotalDispatching() == 0">
							<h3 class="text-center text-danger">NO ITEMS TO {{document_text | uppercase}}</h3>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="sending">
						<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="5" stroke-linecap="round" cx="66" cy="66" r="66"></circle>
						</svg>
					</ng-container>
					<!-- end totals -->
				</div>
			</div>
		</form>
	</ng-container>
</ng-container>