import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-vendordash-invoice',
	templateUrl: './vendordash-invoice.component.html',
	styleUrls: ['./vendordash-invoice.component.scss']
})
export class VendordashInvoiceComponent implements OnInit {

	@Input() data: any = [];
	constructor() {}

	ngOnInit(): void {}

}
