import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

import * as FileSaver from 'file-saver';

import { CustomerService } from '../../../services/customer.service';
import { CreditService } from '../../services/credit.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { PurchasingService } from '../../services/purchasing.service';
import { PaymentsService } from '../../services/payments.service';

import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { saveAs } from 'file-saver';

@Component({
	selector: 'app-credit-dispatch',
	templateUrl: './credit-dispatch.component.html',
	styleUrls: ['./credit-dispatch.component.scss']
})
export class CreditDispatchComponent implements OnInit {

	order: any = {
		header: [],
		details: [],
		//porequired: '',
		//freight_tax: [],
		tax_data: {},
	};

	invoicing: any = true;

	orderno: any ='';
	debtorno: any = '';
	branchcode: any = '';
	//not being sent in the header. on time
	reprintlink: any ='';
	dispatchlink:any ='';
	dispatching = false;
	dispatch_details: any = [];
	//container for specifics about taxes.
	taxes: any = [];

	freight_tax_total = 0;
	freight_charge = 0.00;
	payment_total = 0.00
	freight_taxes:any = []
	display_total: any = '';

	payments_added: any = [];

	headercolumns = [
		'stockid',
		'quantity',
		'units'
	];

	totals = {
		subtotal: 0.00,
		freight: 0.00,
		tax: 0.00,
		discount: 0.00,
		total: 0.00,
	};
	taxdatatotal = 0;

	prefered_vendor: any = [];
	payments: any = [];
	color = 'blue'

	constructor(private creditService: CreditService, private route: ActivatedRoute,private customerService: CustomerService, private location: Location,public router: Router,private globalSearchService: GlobalSearchService, private purchasingService: PurchasingService, private paymentsService: PaymentsService) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {

		this.route.params.subscribe(params => {
			this.orderno = params.transno
			this.creditService.getOrder(this.orderno).subscribe((details) => {
				this.order = details
			})
		})
	}

	emitCustomerUpdate(event: any) {

	}

	showInvoiceResultandReset(event: any) {

	}

	back(): void {
		this.location.back()
	}

	ngAfterViewInit() : void {

	}

}
