<ng-container *ngIf="customerForm">
	<form @grow [formGroup]="customerForm" (ngSubmit)="onSubmit()" class="needs-validation product-add">
		<div class="card-header d-flex justify-content-end">
			<button mat-button mat-raised-button [color]="customerForm.valid ? 'warn' : 'accent'">
				<i class="material-icons">save</i> Save
			</button>
		</div>
		<div class="card-body">
			<div class="row">
				<div class="col-md-8">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Customer Type</mat-label>
						<mat-select formControlName="typeid">
							<mat-option *ngFor="let tpe of customertypes" [value]="tpe?.typeid">
								{{ tpe.typename }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-4 d-flex align-items-center">
					<mat-slide-toggle formControlName="signed_mor">Signed Contract</mat-slide-toggle>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Name</mat-label>
						<input matInput required formControlName="name">
					</mat-form-field>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Address 1</mat-label>
						<input matInput required formControlName="address1">
					</mat-form-field>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Address 2</mat-label>
						<input matInput formControlName="address2">
					</mat-form-field>
				</div>
			</div>

			<div class="row">
				<div class="col-md-4">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>City</mat-label>
						<input matInput required formControlName="address3">
					</mat-form-field>
				</div>
				<div class="col-md-4">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Region</mat-label>
						<mat-select formControlName="address4">
							<mat-option *ngFor="let zone of zones.zones" [value]="zone.code">
								{{ zone.code }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-4">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Postal</mat-label>
						<input matInput required formControlName="address5">
					</mat-form-field>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Country</mat-label>
						<mat-select formControlName="address6">
							<mat-option *ngFor="let zone of zones.countries" [value]="zone.name">
								{{ zone.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>

			<div class="row">
				<div class="col-md-3">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Phone</mat-label>
						<input matInput formControlName="phoneno" mask="(000) 000-0000" [showMaskTyped]="true">
					</mat-form-field>
				</div>
				<div class="col-md-3">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Email</mat-label>
						<input matInput formControlName="invoiceemailaddress">
					</mat-form-field>
				</div>
				<div class="col-md-3">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>License</mat-label>
						<input matInput formControlName="cosm_license">
					</mat-form-field>
				</div>
				<div class="col-md-3">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Expiration</mat-label>
						<input matInput formControlName="licExpDate" [matDatepicker]="licExpDatedatepicker">
						<mat-datepicker-toggle matSuffix [for]="licExpDatedatepicker"></mat-datepicker-toggle>
						<mat-datepicker #licExpDatedatepicker></mat-datepicker>
					</mat-form-field>
				</div>
			</div>

			<mat-divider></mat-divider>

			<div class="row">
				<div class="col-md-6">
					<mat-form-field appearance="outline" class="w-100">
						<mat-label>Tax Id</mat-label>
						<input matInput value="" formControlName="tax_ref" minlength="4">
						<mat-hint *ngIf="customerForm && !customerForm.get('tax_ref').valid">Minimum Length <span *ngIf="customerForm">{{ customerForm.get('tax_ref').value.length }}/9</span></mat-hint>
					</mat-form-field>
				</div>
				<div class="col-md-6">
					<mat-form-field appearance="outline" class="w-100" *ngIf="taxes">
						<mat-label>Tax Authority</mat-label>
						<mat-select formControlName="taxgroupid">
							<mat-option *ngFor="let tax of taxes" [value]="tax.taxgroupid">
								{{ tax.taxgroupdescription }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
		</div>

		<ng-container *ngIf="user.user.customeradmin == '1'">
			<div class="card-footer text-center">
				<button mat-raised-button color="warn" (click)="removeCustomer()">
					Delete Customer
				</button>
			</div>
		</ng-container>
	</form>
</ng-container>
