import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common'
import { InventoryService } from '../../services/inventory.service';
import { OrdersService } from '../../services/orders.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { merge, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';

@Component({
	selector: 'app-inventory-list',
	templateUrl: './inventory-list.component.html',
	styleUrls: ['./inventory-list.component.scss']
})
export class InventoryListComponent implements OnInit {

	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('itemsearch') customersearch: ElementRef;

	searching = false;
	searchfield = [];
	items: any = [];;
	allitems: any = [];
	header: any = [];
	results: any =[];
	inventorysearching: any;
	itemsearchsubscription: any;
	headercolumns: any = [
		'stockid',
		'lineid',
		'description',
		'qoh',
		'cost',
		'price',
		'supplierno',
	  ];
	  color: any = '';

	constructor(private route: ActivatedRoute, private formBuilder: UntypedFormBuilder,public router: Router, private location: Location, public inventoryService: InventoryService, public orderService: OrdersService, private globalSearchService: GlobalSearchService) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
		this.searching = false;
		// this.globalSearchService.lastItem.subscribe(item=> {
		// 	if(item) {
		// 		this.router.navigate(['/inventory/view/'+item])
		// 	}
		// });
	}

	searchItems(input: any) {
		this.searching = true;

		if(this.inventorysearching) {
			this.inventorysearching.unsubscribe()
		}

		this.inventorysearching = this.inventoryService.lookup({ stockid: input.target.value }).subscribe((items: any) => {
			this.allitems = items
			this.searching = false;

			this.items = new MatTableDataSource(items);
			this.items.sort = this.sort;
			this.items.paginator = this.paginator;

		});
	}

	back(): void {
		this.location.back();
	}

	viewItem(row: any) {
		this.router.navigate(['/inventory/view/'+row.stockid])
	}

	announceSortChange(input: any) {

	}

	ngAfterViewInit() {
		  //this.items.sort = this.sort;
		  //this.items.paginator = this.paginator;
	  }

}
