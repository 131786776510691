import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform,AfterViewInit, EventEmitter, Output } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { PurchasingService } from '../../../services/purchasing.service';
import { VendorService } from '../../../services/vendor.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { merge, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


@Component({
	selector: 'app-vendor-lookup',
	templateUrl: './vendor-lookup.component.html',
	styleUrls: ['./vendor-lookup.component.scss']
})
export class VendorLookupComponent implements OnInit {
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('vendorsearch') customersearch: ElementRef;
	@Output() vendor_selected = new EventEmitter < any > ();
	@Input() nonstock = true;
	vendorSearch= new UntypedFormControl('')
	disabled= new UntypedFormControl(false)
	filteredItems: any = [];
	allResults: any = [];

	ready = true;
	vendorsearchsubscription: any = false;
	searching = false
	headercolumns: string[] = [
		'actions',
		'supplierid',
		'suppname',
		'address1',
		'telephone',
		'address4'
	];

	user:any = false;

	constructor(private purchasingService :PurchasingService, private globalSearchService: GlobalSearchService, public vendorService : VendorService, public router: Router) { }

	ngOnInit(): void {
		const supplierid= '';

		this.globalSearchService.user.subscribe((results: any) => {
			if(results) {
				this.user = results;
			}
		});
	}

	onInput() {

		this.ready = false;

		fromEvent(this.customersearch.nativeElement,'keyup')
			.pipe(
				debounceTime(150),
				distinctUntilChanged(),
			)
			.subscribe();
		this.searching = true;
		if(this.vendorsearchsubscription) {
			this.vendorsearchsubscription.unsubscribe();
		}


		const searchvalue = {
			keywords: this.vendorSearch.value,
			inc_disabled: this.disabled.value
		}

		this.vendorsearchsubscription = this.vendorService.getVendorsPost(searchvalue).subscribe((results:any) => {
			this.ready = true;
			this.searching = false;
			if(results.length == 1) {
				this.selectVendor(results[0]);
			} else {
				this.allResults = results
				this.filteredItems = new MatTableDataSource(results);
				this.filteredItems.sort = this.sort;
				this.filteredItems.paginator = this.paginator;
			}

		});
	}

	selectVendor(row: any) {
		this.allResults = [];
		this.filteredItems = []
		this.vendorSearch.setValue('')
		this.vendor_selected.emit(row)
	}

	announceSortChange(sortState: Sort) {
	}

}
