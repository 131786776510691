
<div class="table">
	<table class="table">
		<tr>
			<th>GRN Batch</th>
			<th>Date</th>
			<th>GRN#</th>
			<th>Order#</th>
			<th>Item</th>
			<th>Desc</th>
			<th>Rec</th>
			<th>Invoice</th>
		</tr>
		<tr *ngFor="let v of data">
			<td>{{ v.grnbatch }}</td>
			<td>{{ v.deliverydate }}</td>
			<td>{{ v.grnno }}</td>
			<td>{{ v.orderno }}</td>
			<td>{{ v.itemcode }}</td>
			<td>{{ v.itemdescription }}</td>
			<td>{{ v.qtyrecd }}</td>
			<td>{{ v.quantityinv }}</td>
		</tr>
	</table>
</div>
