<ng-container>
<form  >
	<div class="card-header">
		<div class="row m-0 p-0">
		<div class="col-6 text-right">


		</div>
		<div class="col-6 text-right">
			<button class="ml-auto mr-0" mat-button
			mat-raised-button (click)="onSubmit()" > <i class="material-icons">save</i> Save</button>
		</div>

		</div>

	</div>

		<div class="row">
			<div class="col-md-12">
				<mat-form-field appearance="outline">
					<mat-label><b>MARKUP COST:</b></mat-label>
					<input matInput
					required
					[formControl]="itemActualCost"
					>
				</mat-form-field>
			</div>
			<div class="col-md-12">
				<mat-form-field appearance="outline">
					<mat-label><b>AVG COST:</b></mat-label>
					<input matInput
					required
					[formControl]="itemMaterialCost"
					>
				</mat-form-field>
			</div>
			<div class="col-md-12">
				<mat-form-field appearance="outline">
					<mat-label>LAST COST:</mat-label>
					<input matInput
					required [formControl]="itemLastCost" >
				</mat-form-field>
			</div>
		</div>
		<div class="row mt-2">
			<div class="col-md-12" *ngFor="let price of item.prices; let i = index">
				<mat-form-field appearance="outline">
					<mat-label>
						{{ price.typeabbrev }} {{ price.sales_type }} Price
						<span *ngIf="config.env.package != 'tires'">
							({{ price.currency }} - {{ formatDate(price.startdate) }} to {{ formatDate(price.enddate) }})
						</span>
					</mat-label>
					<input matInput
					   required
					   (input)="updatePrice($event.target.value, price)"
					   [(value)]="price.pricedisplay">



					<span matPrefix (click)="removePrice(price)">
						<mat-icon>remove</mat-icon>
					</span>
					<span matSuffix (click)="toggleDates(price)">
						<mat-icon>settings</mat-icon>
					</span>
				</mat-form-field>

				<ng-container *ngIf="price.editing">
					<div class="row m-0 p-0"  @grow>
						<!-- Currency select dropdown -->
						<div class="col-md-12">
							<mat-form-field appearance="outline">
								<mat-select [value]="price.currency" name="currency" placeholder="Select Currency" (selectionChange)="updateCurrency($event.value, price)">
									<mat-option *ngFor="let currency of currencies" [value]="currency.currency">
										{{ currency.currabrev }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div class="col-md-6">


							<mat-form-field appearance="outline">
								<mat-label>From</mat-label>
								<input matInput #fromDatepickers [matDatepicker]="fromDatepicker" [formControl]="fromdateRef" (dateChange)="updatePriceDateFrom($event.target.value, price)">
								<mat-datepicker-toggle matSuffix [for]="fromDatepicker"></mat-datepicker-toggle>
								<mat-datepicker #fromDatepicker></mat-datepicker>
							</mat-form-field>
						</div>
						<div class="col-md-6" @grow>
							<mat-form-field appearance="outline">
								<mat-label>To</mat-label>
								<input matInput #toDatepickers [matDatepicker]="toDatepicker" [formControl]="todateRef" (dateChange)="updatePriceDateTo($event.target.value, price)">
								<mat-datepicker-toggle matSuffix [for]="toDatepicker"></mat-datepicker-toggle>
								<mat-datepicker #toDatepicker></mat-datepicker>
							</mat-form-field>
						</div>
					</div>
				</ng-container>
			</div>

		</div>

</form>
</ng-container>
