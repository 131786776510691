<ng-template #details let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">{{ displaydetails.title }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="displaydetails">
			<h4></h4>
			<ng-container *ngIf="displaydetails.type == 'html'">
				<div [innerHTML]="displaydetails.display">
				</div>
			</ng-container>
			<ng-container *ngIf="displaydetails.type == 'pdf'">
				<ngx-extended-pdf-viewer [base64Src]="displaydetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true"></ngx-extended-pdf-viewer>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #allocations let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Allocate</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="transid">
			<h4></h4>
			<app-allocations [transid]="transid" [type]="transidtype" *ngIf="transid"></app-allocations>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">

		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">

						<h4 class="card-title " *ngIf="!trandata"><i class="material-icons" (click)="back()">arrow_left</i> Open Payments/Credits</h4>
						<h4 class="card-title " *ngIf="trandata"><i class="material-icons" (click)="back()">arrow_left</i> Allocation Data - {{trandata.typename}} {{trandata.transno}}</h4>
						<p class="card-category"></p>
					</div>
					<div class="card-body">
						<div class="table table-sm">
							<div class="row" *ngIf="transactions">
								<div class="col-md-2">
									&nbsp;&nbsp; <button class="btn btn-success btn-sm" (click)="$event.preventDefault();exportXls()" >
										<i class="fa fa-file-excel-o"></i> XLS
									</button>
									&nbsp;&nbsp;
									<button class="btn btn-danger btn-sm" (click)="$event.preventDefault();exportPdf()" >
										<i class="fa fa-file-pdf-o"></i> PDF
									</button>
								</div>
								<div class="col-md-1">
									<mat-form-field appearance="outline" *ngIf="locations">
										<mat-label>Location</mat-label>
										<mat-select [(ngModel)]="loccode" >
											<mat-option [value]="''">All</mat-option>
											<mat-option *ngFor="let v of locations" [value]="v.loccode">
												{{v.locationname}}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-1">
									<mat-form-field appearance="outline" *ngIf="locations">
										<mat-label>Open Transactions</mat-label>
										<mat-select [(ngModel)]="hasinvoices" (selectionChange)="loadData()">
											<mat-option *ngFor="let v of transactionFilter" [value]="v.value">
												{{v.name}}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-1">
								<mat-form-field appearance="outline" class="">
									<mat-label>Customer Type</mat-label>
									<mat-select [formControl]="customertypefilter" (selectionChange)="filterCustomerType()">
										<mat-option [value]="''">All</mat-option>
										<mat-option *ngFor="let k of customertypes" [value]="k.typeid">
											{{ k.typename }}
										</mat-option>
									</mat-select>
								</mat-form-field>
								</div>
								<div class="col-md-1">
								<mat-form-field appearance="outline" class="">
									<mat-label>Customer Terms</mat-label>
									<mat-select [formControl]="customertermsFilter" (selectionChange)="filterTerms()">
										<mat-option [value]="''">All</mat-option>
										<mat-option *ngFor="let k of terms" [value]="k.termsindicator">
											{{k.termsindicator}} - {{ k.terms }}
										</mat-option>
									</mat-select>
								</mat-form-field>
								</div>

								<div class="ml-auto mr-0 col-5 text-right">
									<mat-form-field appearance="outline">

										<mat-label class="text-right">Search By Document/Customer/Amount</mat-label>
										<span matPrefix *ngIf="loading" class="ml-auto mr-auto text-center">
											<svg class="spinner" width="15px" height="15px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
												<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
											</svg>
										</span>
										<span matPrefix *ngIf="searching" class="ml-auto mr-auto">
											<svg class="spinner" width="15px" height="15px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
												<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
											</svg>
										</span>
										<input tabindex="0" matInput value="" [formControl]="keywords" class="text-right" (input)="searchTransaction($event.target.value)">
										<span matSuffix>
											<button mat-icon-button (click)="loadData()">
												<mat-icon>search</mat-icon>
											</button>
										</span>
										<mat-hint></mat-hint>
									</mat-form-field>
								</div>
							</div>
							<div class="table mt-3" *ngIf="displaymattable">
								<div class="row">
									<div class="col-md-3" >
										Payments: {{ payments.length }}
									</div>
									<div class="col-md-3" >
										Credits: {{ credits.length }}
									</div>
									<div class="col-md-3" >
									</div>
									<div class="col-md-3" >
									</div>
								</div>


								<div class="table" #print_table id="print_table">
									<table mat-table class="table table-sm table-fixed" [dataSource]="transactions" matSort (matSortChange)="announceSortChange($event)" multiTemplateDataRows [trackBy]="identify" >
										<ng-container matColumnDef="transno">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Document#</th>
											<td mat-cell *matCellDef="let tran">
												<ng-container *ngIf="tran.type != '12'">
													<i class="material-icons smaller donotprint" (click)="$event.stopPropagation();openDocument(tran,details,'html')">visibility</i>&nbsp;&nbsp;{{ tran.transno }} {{ tran.typename }}
												</ng-container>
												<ng-container *ngIf="tran.type == '12'">
													{{ tran.transno }} {{ tran.typename }}
												</ng-container>
											</td>
										</ng-container>
										<ng-container matColumnDef="trandate">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
											<td mat-cell *matCellDef="let tran">
												{{ tran.trandate | date:"MM/dd/yy"}}
											</td>
										</ng-container>
										<ng-container matColumnDef="debtorno">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>Customer#</th>
											<td mat-cell *matCellDef="let tran">
												<a [routerLink]="'/customers/view/'+tran.debtorno"><b>{{ tran.combined }}</b></a>
											</td>
										</ng-container>
										<ng-container matColumnDef="name">
											<th mat-header-cell *matHeaderCellDef mat-sort-header class="min-20">Name</th>
											<td mat-cell *matCellDef="let tran" class="min-20">
												{{ tran.name }}
											</td>
										</ng-container>

										<ng-container matColumnDef="invtext">
											<th mat-header-cell *matHeaderCellDef mat-sort-header class="min-30">Text</th>
											<td mat-cell *matCellDef="let tran" [innerHTML]="tran.invtext" class="min-30">

											</td>
										</ng-container>
										<ng-container matColumnDef="opentrans">
											<th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center mat-column-center" arrowPosition="after">Open Invoices</th>
											<td mat-cell class="text-center" *matCellDef="let tran">
												{{ tran.opentrans }}
											</td>
										</ng-container>
										<ng-container matColumnDef="totalamount">
											<th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right mat-column-right" arrowPosition="before"> Total</th>
											<td mat-cell class="text-right" *matCellDef="let tran">
												{{ tran.total | currency }}
											</td>
										</ng-container>
										<ng-container matColumnDef="allocated">
											<th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right mat-column-right" arrowPosition="before">Allocated</th>
											<td mat-cell class="text-right" *matCellDef="let tran">
												{{ tran.alloc | currency }}
											</td>
										</ng-container>
										<ng-container matColumnDef="balance" class="text-right" >
											<th mat-header-cell class="text-right " *matHeaderCellDef mat-sort-header arrowPosition="before" >Balance</th>
											<td mat-cell class="text-right" *matCellDef="let tran;let i = dataIndex;">
												{{ tran.balance | currency }} <i class="material-icons smaller donotprint" (click)="$event.stopPropagation();expandRow(tran,i)">edit</i>
											</td>
										</ng-container>
										<ng-container matColumnDef="expandedDetail">
											<td mat-cell *matCellDef="let tran" [attr.colspan]="headercolumns.length">
										  	<div class="alloc-element-detail"
											   	[@detailExpand]="tran == expandedElement ? 'expanded' : 'collapsed'">
												<div class="alloc-element-description">
													<app-allocations [transid]="tran.id" [type]="tran.type" *ngIf="tran == expandedElement"></app-allocations>
												</div>
										  	</div>
											</td>
									  	</ng-container>
										<tr mat-header-row *matHeaderRowDef="headercolumns"></tr>
										<tr mat-row *matRowDef="let row; columns: headercolumns;let i = dataIndex;" class="alloc-element-row" (click)="expandRow(row, i );"
										[class.alloc-expanded-row]="expandedElement === row"

										></tr>
										<tr mat-row *matRowDef="let row; columns: ['expandedDetail'];" class="w-100 alloc-detail-row donotprint" [class.bg-danger]="row.selected == true"></tr>
									</table>
									<mat-paginator [pageSize]="10000" [pageSizeOptions]="[100, 500, 1000]" showFirstLastButtons aria-label="select page">
									</mat-paginator>
								</div>
							</div>
						</div>
						<!--<div class="row" *ngIf="trandata">
							<table class="table" *ngIf="trandata.viewonly">
								<tr>
									<th> Currently Allocated To This Transaction</th>
								</tr>
								<tr>
									<th>Original Amount: {{trandata.total}} </th>
								</tr>
								<tr>
									<td>
										<table>
											<tr>
												<th> Trans No</th>
												<th>Document Type
												<th>Trans Date</th>
												<th>Allocation Amt</th>
											</tr>
											<tr *ngFor="let tran of trandata.allocfrom">
												<td>{{tran.transno}}</td>
												<td>{{tran.typename}}</td>
												<td>{{tran.trandate}}</td>
												<td>{{tran.amt}}</td>
											</tr>
											<tr>
												<th colspan="3">Remaining Balance: </th>
												<th>{{trandata.balance}} </th>
											</tr>
										</table>
									</td>
								</tr>
							</table>
							<table class="table allocviewtable" *ngIf="!trandata.viewonly">
								<tr>
									<th> Currently Allocated To </th>
									<th> Available Transactions</th>
								</tr>
								<tr>
									<th>Remaining: {{trandata.balance}} </th>
									<th><button mat-stroked-button (click)="transid = ''">All Transactions </button> &nbsp; <button mat-stroked-button [routerLink]="'/customers/view/'+trandata.debtorno">View Customer</button></th>
								</tr>
								<tr>
									<td>
										<table>
											<tr>
												<th> Trans No</th>
												<th>Trans Date</th>
												<th>Total</th>
												<th>Allocation Amt</th>
												<th></th>
											</tr>
											<tr *ngFor="let tran of trandata.allocto">
												<td>{{tran.transno}}</td>
												<td>{{tran.trandate}}</td>
												<td>{{tran.total}}</td>
												<td>{{tran.amt}}</td>
												<td><button mat-stroked-button (click)="removeAllocation(tran, trandata)"> &gt; &gt; </button></td>
											</tr>
										</table>
									</td>
									<td>
										<table>
											<tr>
												<th></th>
												<th> Trans No</th>
												<th>Trans Date</th>
												<th>Total</th>
												<th>Balance</th>
												<th>Allocation Amt</th>
											</tr>
											<tr *ngFor="let tran of trandata.available">
												<td><button mat-stroked-button [disabled]="trandata.balance == 0" (click)="addAllocation(tran, trandata)"> &lt; &lt; </button></td>
												<td>{{tran.transno}}</td>
												<td>{{tran.trandate}}</td>
												<td>{{tran.total}}</td>
												<td>{{tran.balance}}</td>
												<td><input id="amt_{{tran.id}}" mat-input></td>
											</tr>
										</table>

									</td>
								</tr>
							</table>
						</div>-->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
