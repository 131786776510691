import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef, Injectable } from '@angular/core';
import { NestedTreeControl, CdkTreeModule } from '@angular/cdk/tree';
import { ArrayDataSource, SelectionModel } from '@angular/cdk/collections';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';
import { NestableModule } from 'ngx-nestable';
import * as FileSaver from 'file-saver';
import { SystemService } from '../../../services/system.service'
import { InventoryService } from '../../../services/inventory.service'
import { GlobalSearchService } from '../../../services/globalsearchservice.service'
import { UntypedFormBuilder, Validators, UntypedFormControl, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { DropzoneComponent, DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-categories',
	templateUrl: './categories.component.html',
	styleUrls: ['./categories.component.scss']
})

export class CategoriesComponent implements OnInit {

	@ViewChild('subCatModal') subCatRef: ElementRef;
	@ViewChild('assignModal') assignRef: ElementRef;
	options: any = {
		fixedDepth: true
	};
	catname: any = new UntypedFormControl('');
	mfgs: any = new UntypedFormControl('');
	pls: any = new UntypedFormControl('');
	cats: any = new UntypedFormControl('');
	prodsrch: any = new UntypedFormControl('');
	manus: any = [];
	stockcats: any = [];
	prodlines: any = [];
	exclusions: any = [];
	items: any = [];
	resultitems: any = [];
	adding: any = false;
	list: any = [];
	editcat: any = [];
	dropevent: any = '';
	files: File[] = [];
	uploadresults: any = [];
	searching: any = false;

	constructor(private globalSearchService: GlobalSearchService, private fb: UntypedFormBuilder, private systemService: SystemService, private modalService: NgbModal, private inventoryService: InventoryService) {}


	ngOnInit(): void {
		this.loadData();
	}
	addMainCat(){
		this.editcat = [];
		this.catname.value = '';
		this.modalService.open(this.subCatRef, { ariaLabelledBy: 'modal-title', size: 'md' }).result.then((result) => {

		}, (reason) => {
			this.loadData();
		});
	}	
	addSubCat(cat) {
		this.editcat = cat;
		this.catname.value = '';
		this.modalService.open(this.subCatRef, { ariaLabelledBy: 'modal-title', size: 'md' }).result.then((result) => {

		}, (reason) => {
			this.loadData();
		});
	}

	loadData() {
		this.systemService.getWebCategories().subscribe(v => {
			this.list = v;

		});
		this.inventoryService.getItemProductlines().subscribe(v => { this.prodlines = v; });
		this.inventoryService.getItemManufacturer().subscribe(v => { this.manus = v; });
		this.inventoryService.getItemCategories().subscribe(v => { this.stockcats = v; });
	}

	addCat() {

		this.adding = true;
		if (this.editcat && this.editcat.id != null) {
			const data = { parent_id: this.editcat.id, name: this.catname.value };
			this.systemService.addWebCategory(data).subscribe(v => { this.list = v.cats; });
		} else {
			const data = { name: this.catname.value };
			this.systemService.addWebCategory(data).subscribe(v => { this.list = v.cats; });
		}
		this.modalService.dismissAll();
		this.editcat = [];

	}

	removeCat(row: any) {
		this.systemService.removeWebCategory({ id: row.id }).subscribe((res) => {
			this.list = res.cats;
		})
	}

	assign(cat) {
		this.editcat = cat;
		this.systemService.getCategoryItems(this.editcat).subscribe((result) => {

			this.items = result.items;
			this.resultitems = [];
			this.exclusions = [];
			this.modalService.open(this.assignRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((r) => {

			}, (reason) => {
				this.editcat = [];
				this.loadData();
			});

		});
	}
	removeItem(item, stockid) {
		const data = { catid: this.editcat.id, stockid: stockid };
		this.systemService.removeCategoryItems(data).subscribe((result) => {
			if (result.success) {
				delete this.items[item];
			}
		});
	}

	clearSearch() {
		this.resultitems = [];
	}

	exclude(item) {
		this.exclusions.push(item);
		const index = this.resultitems.indexOf(item);

		this.resultitems.splice(index, 1);
	}


	productSearch() {
		const data = {
			manufacturers: this.mfgs.value,
			productlines: this.pls.value,
			categories: this.cats.value,
			exlusions: this.exclusions,
			keywords: this.prodsrch.value
		};

		if (this.searching) {
			this.searching.unsubscribe();
		}

		this.searching = this.systemService.getCategoryItemSearch(data).subscribe((result) => {
			this.resultitems = result;
		});
	}
	saveProducts() {
		const stockids = [];
		this.resultitems.forEach((i) => {
			stockids.push(i.stockid);
		});
		const data = { stockids: stockids, catid: this.editcat.id };

		this.systemService.addCategoryItems(data).subscribe((result) => {
			this.items = result.items;
			this.resultitems = [];
			this.exclusions = [];
			this.loadData();
			this.globalSearchService.showNotification('Products Updated', 'success', 'bottom', 'right');
		});
	}
	
	updateProducts(){
	
		const stockids = [];
		this.items.forEach((i) => {
			stockids.push(i.stockid);
		});
		const data = { stockids: stockids, catid: this.editcat.id, reset: true };

		this.systemService.addCategoryItems(data).subscribe((result) => {
			this.items = result.items;
			this.resultitems = [];
			this.exclusions = [];
			this.loadData();
			this.globalSearchService.showNotification('Products Updated', 'success', 'bottom', 'right');
		});
	
	
	}

	changeArray(e: any) {
		if (this.adding) {
			this.adding = false;
		} else {
			this.systemService.updateCategoryList({ data: this.list }).subscribe((result) => {});
		}
	}

	changeAllowed(item: any, e: any) {
		let found = 0;
		this.list.forEach((k) => {

			if (k.id == item) {
				if (e.checked == true) {
					k.isallowed = 1;
				} else {
					k.isallowed = 0;
				}
				found = 1;
			}
			if (found == 0 && k.children.length > 0) {
				this.changeAllowedHelper(item, e, k.children);
			}
		});

		this.changeArray(e);
	}

	changeColumn(item: any, e: any) {
		let found = 0;
		this.list.forEach((k) => {

			if (k.id == item) {
					k.column = e.target.value;
				found = 1;
			}
			if (found == 0 && k.children.length > 0) {
				this.changeColumnHelper(item, e, k.children);
			}
		});

		this.changeArray(e);
	}
	
	changeAllowedHelper(item: any, e: any, list: any) {
		let found = 0;
		list.forEach((k) => {

			if (k.id == item) {
				if (e.checked == true) {
					k.isallowed = 1;
				} else {
					k.isallowed = 0;
				}
				found = 1;
			}
			if (k.children) {
				if (found == 0 && k.children.length > 0) {
					this.changeAllowedHelper(item, e, k.children);
				}
			}
		});
	}

	changeColumnHelper(item: any, e: any, list: any) {
		let found = 0;
		list.forEach((k) => {

			if (k.id == item) {
				k.column = e.target.value;
				found = 1;
			}
			if (k.children) {
				if (found == 0 && k.children.length > 0) {
					this.changeAllowedHelper(item, e, k.children);
				}
			}
		});
	}
	onUploadError(event: any) {

	}

	onUploadSuccess(event: any) {

	}

	onSelect(cat: any, event: any) {
		this.files.push(...event.addedFiles);
		for (let i = 0; i < this.files.length; i++) {
			this.readFile(this.files[i]).then(fileContents => {
				//stuff

				const upload = {
					content: fileContents,
					name: event.addedFiles[0].name,
					mime: event.addedFiles[0].type,
				}

				this.systemService.uploadCatFile(upload, cat).subscribe((results: any) => {
					this.files = [];
					this.systemService.getWebCategories().subscribe(v => {
						this.list = v;
					});
				});

			});
		}
	}
	
	drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
	}

	private async readFile(file: File): Promise < string | ArrayBuffer > {
		return new Promise < string | ArrayBuffer > ((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = e => {
				return resolve((e.target as FileReader).result);
			};

			reader.onerror = e => {
				console.error(`FileReader failed on file ${file.name}.`);
				return reject(null);
			};

			if (!file) {
				return reject(null);
			}
			reader.readAsDataURL(file);
		});

		this.files = [];
	}
}
