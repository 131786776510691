<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Stock Reports</h4>
						<p class="card-category text-right">Purchasing</p>
					</div>
					<div class="card-body ">
						<mat-tab-group #tabPanel mat-align-tabs="start" color="accent" class="no-overflow">

							<mat-tab label="Min/Max">
								<ng-template matTabContent>
									<app-purchasing-reordering-minmax-two ></app-purchasing-reordering-minmax-two>
								</ng-template>
							</mat-tab>


							<!-- <mat-tab label="Min/Max (IRGL)">
								<ng-template matTabContent>
									<app-purchasing-reordering-minmax></app-purchasing-reordering-minmax>
								</ng-template>
							</mat-tab> -->


							<!--
								<mat-tab label="Back Orders">
								<ng-template matTabContent>
									<app-purchasing-reordering-avgsales-mixed></app-purchasing-reordering-avgsales-mixed>
								</ng-template>
							</mat-tab>

							-->
							<mat-tab label="Daily Turns">
								<ng-template matTabContent>
									<app-purchasing-dailychurn></app-purchasing-dailychurn>
								</ng-template>
							</mat-tab>
<!--
							<mat-tab label="Mixed Recomendations">
								<ng-template matTabContent>
									<app-purchasing-reordering-avgsales-mixed></app-purchasing-reordering-avgsales-mixed>
								</ng-template>
							</mat-tab> -->

							<mat-tab label="Recomendations By Vendor" *ngIf="config.env.package == 'beauty'">
								<ng-template matTabContent>
									<app-purchasing-reordering-avgsales></app-purchasing-reordering-avgsales>
								</ng-template>
							</mat-tab>
							<!--
							<mat-tab label="Min / Max">
								<ng-template matTabContent>
									<app-purchasing-reordering-minmax></app-purchasing-reordering-minmax>
								</ng-template>
							</mat-tab>
							-->
							<mat-tab label="Re-Stock">
								<ng-template matTabContent>
									<app-purchasing-reordering-restock></app-purchasing-reordering-restock>
								</ng-template>
							</mat-tab>
						</mat-tab-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
