import { HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalSearchService } from '../services/globalsearchservice.service';

@Injectable()
export class AuthService {

	public userlogged: boolean;

	constructor(public globalSearchService: GlobalSearchService) {}

	public isAuthenticated(): boolean {

		const user = JSON.parse(localStorage.getItem('user'));
		if(user === null ) {
			return false;
		} else {

			this.userlogged = true;
			this.globalSearchService.user.next(user);
			this.globalSearchService.userData.next(user);

			return true;
		}
	}
}
