<table mat-table [dataSource]="dataset" matSort (matSortChange)="announceSortChange($event)">
	<ng-container matColumnDef="file">
		<th mat-header-cell *matHeaderCellDef mat-sort-header></th>
		<td mat-cell *matCellDef="let tran">
			{{ tran.file}}
		</td>
	</ng-container>
	<ng-container matColumnDef="customerno">
		<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
		<td mat-cell *matCellDef="let tran">
		{{ tran.customerno }}
		</td>
	</ng-container>
	<ng-container matColumnDef="ship_date">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> Date Ordered<th>
		<td mat-cell *matCellDef="let tran" >{{ tran.ship_date }}</td>
	</ng-container>
	<ng-container matColumnDef="transno">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> Order#.</th>
		<td mat-cell *matCellDef="let tran" >{{ tran.transno }}</td>
	</ng-container>

	<ng-container matColumnDef="itemnumber">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> Item#.</th>
		<td mat-cell *matCellDef="let tran" >{{ tran.itemnumber }}</td>
	</ng-container>

	<ng-container matColumnDef="description">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> description#.</th>
		<td mat-cell *matCellDef="let tran" >{{ tran.description }}</td>
	</ng-container>

	<ng-container matColumnDef="sku">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> sku#.</th>
		<td mat-cell *matCellDef="let tran" >{{ tran.sku }}</td>
	</ng-container>
	  <tr mat-header-row *matHeaderRowDef="headercolumns"></tr>
	  <tr mat-row *matRowDef="let row; columns: headercolumns"></tr>
</table>
<mat-paginator [pageSizeOptions]="[25, 50, 100]"
				 showFirstLastButtons
				 aria-label="select page">
</mat-paginator>
