import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform,AfterViewInit,Output,EventEmitter } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { merge, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';

import { MatSnackBar,MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { PurchasingService } from '../../services/purchasing.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';

import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Location } from '@angular/common'

import * as _ from 'underscore';
import * as XLSX from 'xlsx';
declare let $: any;


@Component({
	selector: 'app-vendor-open-pos',
	templateUrl: './vendor-open-pos.component.html',
	styleUrls: ['./vendor-open-pos.component.scss']
})
export class VendorOpenPosComponent implements OnInit {
	@Input() vendor;
	@Output() orderloaded = new EventEmitter < any > ();
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	purchaseorders: any = [];
	allpurchaseorders: any = [];


	headercolumns: string[] = [
		'actions',
		'suppname',
		'orddate',
		'status_id',
		'orderno',
		'requisitionno',
		'ordervalue',
		'deliverydate',
		'initiator',
		'ponumber',
	];

	statuses: any = [];
	openOrderData: any = [];
	config: any = [];
	baselink = '';
	reclink = '';
	color = '';
	podetails: any = [];


	constructor(private route: ActivatedRoute,private purchasingService :PurchasingService ,  private globalsService: GlobalsService, public globalSearchService: GlobalSearchService, public location: Location, private modalService: NgbModal) {

		this.color = this.globalSearchService.getColor();

	}

	ngOnInit(): void {

		this.config = this.globalsService.getApiConfig()
		this.baselink = this.config.apiServer.poLink;
		this.reclink = this.config.apiServer.recLink;

		let search = '';
		if(this.vendor !='') {
			search = this.vendor.supplierid;
		}
		if(search != '') {
			this.purchasingService.getVendorPurchaseOrders(search).subscribe((results: any) => {
				this.purchaseorders= new MatTableDataSource(results.pos);
				this.statuses = results.statuses
				this.purchaseorders.sort = this.sort;
				this.purchaseorders.paginator = this.paginator;
				this.allpurchaseorders= results;
			});
		} else {
			this.purchasingService.getPurchaseOrders().subscribe((results: any) => {
				this.allpurchaseorders= results;
				this.statuses = results.statuses
				this.setTableData(results.pos)
			});
		}
	}

	getStatus(id: any) {
		return this.statuses.filter((st) => {
			return parseFloat(st.status_id) == parseFloat(id)
		})[0];
	}

	setTableData(data: any) {
		this.openOrderData = data;
		this.purchaseorders= new MatTableDataSource(data);
		this.purchaseorders.sort = this.sort;
		this.purchaseorders.paginator = this.paginator;
	}

	loadOrder(orderno: any) {
		this.purchasingService.loadPurchaseOrder(orderno).subscribe((result: any) => {
			this.orderloaded.emit(result);
		});
	}


	updateStatus(event: any, trans: any) {

		const status_details = this.getStatus(event.value);
		const index = this.allpurchaseorders.pos.indexOf(trans)

		this.allpurchaseorders.pos[index].editing = false;
		this.allpurchaseorders.pos[index].status_id = status_details.status_id;
		this.allpurchaseorders.pos[index].statusname = status_details.name;

		this.purchasingService.updateStatus(this.allpurchaseorders.pos[index]).subscribe((result: any) => {

		})

		this.setTableData(this.allpurchaseorders.pos);

	}

	exportExcel() {
		this.globalSearchService.exportJsonToExcel(this.allpurchaseorders.pos, 'OpenPurchaseOrders');
	}

	toggleStatusChange(trans: any) {
		const index = this.allpurchaseorders.pos.indexOf(trans);
		if(this.allpurchaseorders.pos[index].editing){
			this.allpurchaseorders.pos[index].editing = false;
		} else {
			this.allpurchaseorders.pos[index].editing = true;
		}
	}

	onInput(event: any, keys = 'orderno') {
		//tood make this ajax call to search for items on orders as well

		this.purchaseorders = this.globalSearchService.filterItem(this.openOrderData, event, 'orderno,supplierno,deliverydate,itemcode,itemdescription');
	}
	announceSortChange(event: any) {

	}

	viewOrder(orderno: any, content: any) {

		this.purchasingService.getPurchaseOrder(orderno).subscribe((order: any) => {
			this.podetails = order
			this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
		});
	}


	back(): void {
		this.location.back()
	}
	ngAfterViewInit() {
		//this.customers.sort = this.sort;
		//this.customers.paginator = this.paginator;
	}

}
