<div class="main-content">
	<div class="container-fluid">

		<div class="card">
			<div class="card-header card-header-{{color}}">
				<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> User Groups {{ group.name }}</h4>
				<p class="card-category">User Groups</p>
			</div>
			<div class="card-body">
				<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent">
					<mat-tab label="Group Info">
						<div class="row m-0 p-0 mt-3">
							<div class="col-md-10">
								<mat-form-field class="full-width">
									<mat-label>Group Name</mat-label>
									<input matInput [formControl]="groupname" class="group-name-input">
								</mat-form-field>
							</div>
							<div class="col-2">
								<button mat-raised-button color="accent" (click)="saveAccess()" class="update-button">Update</button>
							</div>
							<!-- IP restriction -->
							<div class="col-md-12 ip-restriction-section">
								<h4 class="ml-2">
									<mat-checkbox [(ngModel)]="ipRestricted" [labelPosition]="'after'" [color]="'primary'">IP Restricted</mat-checkbox>
								</h4>
								<div *ngIf="ipRestricted" class="ml-2 mt-3">
									<mat-form-field class="full-width">
										<mat-label>Add an IP</mat-label>
										<input matInput #newAllowedIP>
									</mat-form-field>
									<button mat-raised-button color="primary" (click)="addAllowedIPs(newAllowedIP.value); newAllowedIP.value=''">Add</button>
								</div>
								<div *ngIf="ipRestricted && allowedIPs.length > 0" class="ml-2 mt-3">
									<h5>Current Allowed IPs:</h5>
									<div *ngFor="let ip of allowedIPs; let i = index" class="allowed-ip-container">
										<span class="allowed-ip">{{ ip }}</span>
										<button mat-icon-button color="warn" (click)="removeAllowedIP(i)">
											<mat-icon>remove_circle</mat-icon>
										</button>
									</div>
								</div>
								<div *ngIf="ipRestricted && allowedIPs.length === 0" class="ml-2 mt-3">
									<p class="no-ip-message">No IPs added yet.</p>
								</div>
							</div>
							<div class="col-md-12">
								<mat-form-field appearance="outline" class="w-100">
									<mat-label>Sales Channel</mat-label>
									<mat-select [formControl]="saleschannel">
										<ng-container *ngFor="let p of placedfrom">
											<mat-option [value]="p.value">{{p.name}}</mat-option>
										</ng-container>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col-md-12 allowed-locations-section">
								<h4 class="ml-2 allowed-locations-header">Allowed Locations</h4>
								<mat-list #access class="permission-box" dense>
									<ng-container *ngFor="let route of access_list; let i = index; ">
										<mat-list-item>
											<mat-checkbox class="auth-margin" [checked]="route.authorized" [indeterminate]="updateAccessComplete(route)" [labelPosition]="'after'" [color]="'accent'" (change)="setChildAccessAuthorized($event.checked, route)">
												{{route.title}}
											</mat-checkbox>
										</mat-list-item>
										<ng-container *ngIf="route.children">
											<mat-list class="child-list">
												<mat-list-item *ngFor="let child of route.children ">
													<mat-checkbox class="auth-margin" [labelPosition]="'after'" [color]="'warn'" [checked]="child.authorized" (change)="updateAccess($event.checked, route, child)">
														{{child.title}}
													</mat-checkbox>
												</mat-list-item>
											</mat-list>
										</ng-container>
									</ng-container>
									<mat-divider></mat-divider>
								</mat-list>
							</div>
							<div class="col-md-6 d-none">
								<h4> Modify </h4>
								<mat-list #access class="permission-box" dense>
									<ng-container *ngFor="let route of ROUTES;trackBy: trackByFn ">
										<mat-list-item>
											{{route.title}}
										</mat-list-item>
										<ng-container *ngIf="route.children">
											<mat-list class="child-list">
												<mat-list-item *ngFor="let child of route.children ">
													{{ child.title }}
												</mat-list-item>
											</mat-list>
										</ng-container>
									</ng-container>
									<mat-divider></mat-divider>
								</mat-list>
							</div>
						</div>
					</mat-tab>
					<mat-tab label="Report Access">
						<div class="row m-0 p-0 mt-4">
							<!-- Display All Reports and Group Reports -->
							<div class="col-md-12">
								<div class="section-header">
									<h5>Group Reports</h5>
									<mat-divider></mat-divider>
								</div>
								<input type="text" class="form-control" placeholder="Search" (input)="filterReports($event.target.value)">
								<ng-container *ngFor="let group of all_reports; trackBy: trackByFnTwo">
									<div class="card" *ngIf="group.reports.length > 0">
										<div class="card-header d-flex justify-content-between align-items-center">
											<h6 class="report-type">{{ group.type }}</h6>

										</div>
										<div class="card-body">
											<mat-list class="report-list bordered perm-box mt-2">
												<mat-list-item *ngFor="let loc of group.reports; trackBy: trackByFn">
													<div class="report-info">
														<span>{{ loc.title }}</span>
														<span class="type">{{ loc.type }}</span>
													</div>
													<button mat-icon-button class="toggle-button" color="{{ hasLocation(loc.title) ? 'warn' : 'primary' }}" (click)="toggleReport(loc)">
														<mat-icon aria-label="Toggle">{{ hasLocation(loc.title) ? 'check_circle' : 'radio_button_unchecked' }}</mat-icon>
													</button>
												</mat-list-item>
												<mat-divider></mat-divider>
											</mat-list>
										</div>
									</div>
								</ng-container>
							</div>
						</div>
					</mat-tab>
					<mat-tab label="Users" *ngIf="allUsers">
						<div class="row mb-3 mt-3">
							<div class="col-12 col-md-6">
								<input type="text" class="form-control" placeholder="Search All Users" [(ngModel)]="searchAllTerm" (ngModelChange)="filterAllUsers()">
							</div>
							<div class="col-12 col-md-6">
								<input type="text" class="form-control" placeholder="Search Group Users" [(ngModel)]="searchGroupTerm" (ngModelChange)="filterGroupUsers()">
							</div>
						</div>
						<div class="row">
							<div class="col-12 col-md-6 mb-3">
								<h3>All Users</h3>
								<ul class="list-group">
									<li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let user of filteredAllUsers">
										<div>
											<strong>{{ user.realname }}</strong><br>
											<small>User ID: {{ user.userid }}</small><br>
											<small>Phone: {{ user.phone }}</small><br>
											<small>Email: {{ user.email }}</small><br>
											<small>Default Location: {{ user.defaultlocation }}</small>
											<small>Group: {{ user.groupname }}</small>
										</div>
										<button class="btn btn-outline-primary btn-sm" (click)="addUserToGroup(user)" matTooltip="Add to Group">
											<i class="fa fa-plus"></i> Add
										</button>
									</li>
								</ul>
							</div>
							<div class="col-12 col-md-6">
								<h3>Group Users</h3>
								<ul class="list-group">
									<li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let user of filteredGroupUsers">
										<div>
											<strong>{{ user.realname }}</strong><br>
											<small>User ID: {{ user.userid }}</small><br>
											<small>Phone: {{ user.phone }}</small><br>
											<small>Email: {{ user.email }}</small><br>
											<small>Default Location: {{ user.defaultlocation }}</small>
											<small>Group: {{ user.groupname }}</small>
										</div>
										<button class="btn btn-outline-danger btn-sm" (click)="removeUserFromGroup(user)" matTooltip="Remove from Group">
											<i class="fa fa-minus"></i> Remove
										</button>
									</li>
								</ul>
							</div>
						</div>
					</mat-tab>

				</mat-tab-group>
			</div>
		</div>
	</div>
</div>
