import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';

import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { PurchasingService } from '../../../services/purchasing.service';
import { InventoryService } from '../../../services/inventory.service';
import { PrintService } from '../../../services/print.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
	selector: 'app-purchasing-reordering-avgsales',
	templateUrl: './purchasing-reordering-avgsales.component.html',
	styleUrls: ['./purchasing-reordering-avgsales.component.scss'],
	animations: [
	  trigger('flipState', [
		  state('active', style({
			  transform: 'rotateY(180deg)'
		  })),
		  state('inactive', style({
			  transform: 'rotateY(0)'
		  })),
		  state('orderlookup', style({
			  transform: 'rotateY(180deg)'
		  })),
		  transition('active => inactive', animate('500ms ease-out')),
		  transition('inactive => active', animate('500ms ease-in')),
		  transition('inactive => orderlookup', animate('500ms ease-out')),
		  transition('orderlookup => inactive', animate('500ms ease-in')),
	  ])
	]
})
export class PurchasingReorderingAvgsalesComponent implements OnInit {
	@ViewChild('purchasedetails') purchasedetails: ElementRef;
	@ViewChild('exportTable') exportTableRef: ElementRef;

	locations : any = [];
	reportForm: UntypedFormGroup;
	today = new Date();
	results: any = [];
	loading = false;
	suppliers: any = [];
	watch_items: any = [];
	po_vendors_found: any = [];
	vendor: any = '';
	sending: any = '';
	indexnow = 1
	wathchlist: any = [];
	product_lines: any = [];
	currentstep = 0;
	purchase_item: any = [];

	constructor(private inventoryService: InventoryService, private purchasingService: PurchasingService,private globalSearchService: GlobalSearchService,private location: Location,private route: ActivatedRoute,private globalsService: GlobalsService,private fb: UntypedFormBuilder , private modalService: NgbModal, private printService: PrintService) { }

	ngOnInit(): void {

		this.purchasingService.getSuppliers('').subscribe((results: any) => {
			this.suppliers = results;
			this.reportForm = this.fb.group({
				startdate: [this.today, Validators.required],
				minimum_sales: [3, Validators.required],
				item_type: [1, Validators.required],
				numberofmonths: [2, Validators.required],
				numberofweeks: [8, Validators.required],
				lines: ['',],
				loccode: [this.locations[0].loccode, Validators.required],
				vendorid: ['', Validators.required ]
			});
		});
	}

	creteMultiplePos() {

	}

	updateProductLines() {
		const data = {
			supplier: this.reportForm.get('vendorid').value
		}
		this.inventoryService.getBrandProductlines(data).subscribe((r: any) => {
			this.product_lines = r;

			const selected = [];
			this.product_lines.forEach(a => {
				selected.push(a.line_field)
			});
			this.reportForm.get('lines').setValue(selected)
		});
	} 
	editBin(item:any) {
		item.editing = (item.editing) ? false: true;
	}

	updateQuantity(event: any, item: any) {
		item.qty_purchase = event;
	}

	updateBinItem(event: any, item: any) {
		const index = this.results.indexOf(item);
		item.bin = event;
		item.editing = false;
	}

	setPurchOrderOptions(item: any, pdata: any)  {
		item.selected_option = pdata;
		this.modalService.dismissAll();
	}

	viewPurchData(item: any) {
		this.purchase_item = item;
		this.modalService.open(this.purchasedetails, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {

		}, (reason) => {
			this.purchase_item = {};
		});


	}

	removeItem(item: any) {
		const index = this.results.indexOf(item);
		this.results.splice(index, 1);
	}

	getRecomendations() {
		if(this.reportForm.valid) {
			this.loading = true;
			this.purchasingService.getStockRecomendationsAvg(this.reportForm.value).subscribe((results: any) => {
				this.results = results
				this.loading = false;
				this.vendor = this.reportForm.get('vendorid').value;
			});
		}
	}

	ngAfterViewInit() {

		this.globalSearchService.locations.subscribe(async (results: any) => {
			this.locations = results;
		});
	}

	vendorHasFillable(vendor: any){
		return true;
	}
	
	printResults(po: any) {

		const vendor = this.suppliers.filter( (s:any) => {
			const findindex = s.supplierid;
			return po == findindex
		})[0];
		vendor.loccode = this.reportForm.get('loccode').value;
		vendor.lines = this.results;

		const data = vendor

		this.purchasingService.printPreviewPo(data).subscribe( (results:any) => {
			this.goToLink(results.url);
		});
	}
	goToLink(url: string) {
		window.open(url, '_blank');
	}

	exportPdf() {

		const encoded: string = this.globalSearchService.base64encode(this.exportTableRef.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: 'AvgSalesReorder',
			title: 'Average Sales Re-Order',
			subtitle: 'Created ' + this.today.toLocaleString(),
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}
	exportXls() {
		const encoded: string = this.globalSearchService.base64encode(this.exportTableRef.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: 'AvgSalesReorder',
			title: 'Average Sales Re-Order',
			subtitle: 'Created ' + this.today.toLocaleString(),
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}
	submitPo(po: any) {

		const proceed = confirm('You are about to create a purchase order. Continue?');

		if(proceed) {

			this.sending = true;
			const vendor = this.suppliers.filter( (s:any) => {
				const findindex = s.supplierid;
				return po == findindex
			})[0];

			const remove_vendor = po;

			vendor.loccode = this.reportForm.get('loccode').value;
			vendor.lines = this.results.filter( (i:any) => {return i.qty_purchase > 0 });

			if(vendor.lines.length) {
				const data = [
					{ vendor: vendor }
				]

				this.purchasingService.createPurchaseOrdersFromRecomend(data).subscribe((result: any) => {
					this.sending = false;
					if (result.success) {

						this.globalSearchService.showNotification(result.message, 'success', 'bottom', 'right');

						this.indexnow++;

						const next_set = [];
						const suppindex = '';
						this.results = [];

						this.currentstep = 0;
						this.sending = false;
					} else {
						alert(result.message)
						this.sending = false;
					}


				});
			} else {
				this.sending = false;
			}
		}
	}
}
