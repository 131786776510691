import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReportsService } from '../services/reports.service';
import { GlobalSearchService } from '../services/globalsearchservice.service';

import { Location } from '@angular/common'
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors, FormControl } from '@angular/forms';

@Component({
	selector: 'app-reports',
	templateUrl: './reports.component.html',
	styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
	title = 'reports';
	reports: any = [];
	allreports: any = false;
	color = '';
	filterTerm = new UntypedFormControl('')
	constructor(private globalSearchService: GlobalSearchService, private reportsService: ReportsService, private location: Location) {
		this.color = this.globalSearchService.getColor();
	}

	groups: any = [];

	ngOnInit(): void {

		this.filterTerm.valueChanges.subscribe(newValue => {
			if (newValue != '') {
				const filtered = this.globalSearchService.filterItem(this.allreports, newValue, 'title');
				if (filtered.length) {
					this.reports = filtered;
				} else {
					this.reports = this.allreports;
				}
			} else {
				this.reports = this.allreports;
			}

		})

		this.reportsService.getReports().subscribe(
			(reports: any) => {
				//filter for system
				this.reports = reports.filter((rep: any) => rep.type !== 'system');
				this.allreports = this.reports;

				this.allreports.forEach((rep) => {
					if (this.groups.indexOf(rep.group) < 0) {
						this.groups.push(rep.group);
					}
				});

				this.groups.sort();

			});
	}

	getGroupReports(group: any) {

		if (!this.reports) {
			return [];
		}

		const reports = this.reports.filter((g: any) => {
			return g.group == group
		});

		return reports;
	}

}
