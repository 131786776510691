import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { OmsService } from '../../services/oms.service';
import { Order, PickItem, PickItems, OrderTypes, OrderHeader  } from '../../classes/orders';
import { ItemList } from '../../classes/items';
import { Location } from '@angular/common'

@Component({
	selector: 'app-order-lookup',
	templateUrl: './order-lookup.component.html',
	styleUrls: ['./order-lookup.component.scss']
})
export class OrderLookupComponent implements OnInit {

	@ViewChild('stockidsearch') stockidSearchField: ElementRef;

	public orderForm: UntypedFormGroup;

	allorders: OrderHeader;
	picking: OrderTypes;
	forcesearch = true;

	constructor(private route: ActivatedRoute, private formBuilder: UntypedFormBuilder,private ordersService: OrdersService, public router: Router, private location: Location, private inventoryService: InventoryService, private omsService: OmsService, private globalSearchService: GlobalSearchService) { }

	ngOnInit(): void {

		this.globalSearchService.pickingOrderData.subscribe((results: OrderTypes) => {
			this.picking = results;
		});

		this.route.params.subscribe(params => {
			const orderno = params['id'];
			if(orderno) {
				this.forcesearch = false;
			}
			if(!orderno) {
				this.forcesearch = true;
				this.ordersService.getOrders().subscribe((orders: OrderHeader) => {
					this.allorders = orders
				});
			}
		});
	}

}
