import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common'
import { InventoryService } from 'app/services/inventory.service';
import { FormBuilder } from '@angular/forms';
import { QantelService } from 'app/services/qantel.service';
import { timeout } from 'rxjs/operators';
import { ElementRef } from '@angular/core';

@Component({
	selector: 'app-barcode-assigner',
	templateUrl: './barcode-assigner.component.html',
	styleUrls: ['./barcode-assigner.component.scss']
})
export class BarcodeAssignerComponent implements OnInit {
	color = 'blue'
	searchForm: any;
	filteredItems: any;
	inventorysearching: any;
	multProd: any = false;
	singProd: any = false;
	allitems: any = false;
	showStockidSearch: boolean = false;
	barcodeSearched: any;
	activeProduct: any = false;
	type: any = 'add';
	ref: any = false;


	constructor(public qantelService: QantelService, private fb: FormBuilder, private router: Router, public location: Location, public inventorySerive: InventoryService) { }


	ngOnInit(): void {
		this.setForm()
	}


	search() {
		const barcode = this.searchForm.get('barcode').value;
		this.barcodeSearched = barcode;
		this.showStockidSearch = false;
		this.allitems = false;
		this.searchForm.get('prod').value = '';

		if(barcode && barcode != ''){

			const data = {barcode: barcode}

			this.inventorySerive.absoluteItemSearch(data).subscribe((res)=>{
				const itemCount = res.length;
				if(itemCount > 1){ //if item count is > 1 show items in list and allow clicking to make bigger
					this.singProd = false;
					this.multProd = res;
				}
				if(itemCount == 1){ //single prod = just display
					this.multProd = false;
					//testing image
					// res[0].details.item.full_size_image = 'assets/beauty.png'
					// res[0].details.item.thumb = 'assets/beauty.png'
					this.singProd = res[0];

				}
				if(itemCount == 0){ //show search for assigning (qantel app)
					this.multProd = false;
					this.singProd = false;
					this.showStockidSearch = true;
					this.focus('prod');
				}
			})
		}
	}

	searchSku(){
		const event = this.searchForm.get('prod').value;

		if(this.inventorysearching) {
			this.inventorysearching.unsubscribe()
		}

		this.inventorysearching = this.qantelService.rawSearch(event).subscribe((items: any) => {
			this.allitems = items
		});
	}

	assign(prod, type, fromprod?: any){
		var data = {
			stockid: prod.stockid,
			barcode: this.barcodeSearched,
			type: type
		}
		if(fromprod){
			data['from'] = fromprod.stockid
		}

		this.inventorySerive.addBarcodeClean(data).subscribe((res)=>{
			if(!this.multProd){
				this.resetAll();
				this.searchForm.get('barcode').setValue(data.barcode);
				this.search();
			}else{
				var index = this.multProd.indexOf(this.multProd.find((row)=>{return row.stockid == this.activeProduct.stockid}))
				this.multProd.splice(index, 1);
				this.activeProduct = false;
				this.singProd = false;
				if(this.multProd.length == 1){
					this.resetAll()
					this.searchForm.get('barcode').setValue(data.barcode);
					this.search()
				}
			}
		})
	}

	activeProd(prod){
		this.activeProduct = prod;
		this.singProd = prod;
	}

	setForm(){
		this.searchForm = this.fb.group({
			barcode: [''],
			prod: ['']
		});
	}

	change(type){
		this.type = type;
		this.showStockidSearch = this.showStockidSearch ? false : true;
		this.focus('prod');
	}

	focus(type){
		if(type == 'top'){
			document.getElementById('top').scrollIntoView();
		}
		if(type == 'prod'){
			setTimeout(()=>{
				document.getElementById('prodsearch').focus();
			}, 100);
			}
		if(type == 'barcode'){
			document.getElementById('barcodesearch').focus();
		}
	}

	resetAll(){
		this.searchForm.reset();
		this.singProd = false;
		this.multProd = false;
		this.showStockidSearch = false;
		this.allitems = false;
		this.focus('top');
		this.focus('barcode');
	}

	onSubmit(){

	}


	back() {
		this.location.back();
	}
}
