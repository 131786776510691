<div class="main-content">
	<div class="col-md-12">
		<div class="container-fluid tp-box" [@flipState]="flip">
			<div class="backside tp-box__side tp-box__back" [ngClass]="flip != 'inactive' ? '' : 'd-none'">
				<div class="card" [ngClass]="flip != 'inactive' ? '' : 'd-none'">
					<div class="card-header card-header-{{color}}-5" *ngIf="flip != 'inactive'">
						<h4 class="card-title "> <i class="material-icons" (click)="toggleFlip()">arrow_left</i> {{ subtitle }} </h4>
						<p class="card-category"></p>
					</div>
					<div class="card-body">
						<div class="input-group no-border" (click)="toggleFlip()">
							<div class="ripple-container"></div>
						</div>
						<div class="results mt-2 mb-2" *ngIf="viewingdata">
							<app-open-purchases [title]="subtitle" [dataset]="viewdata" *ngIf="viewapp == 'pos'"></app-open-purchases>
							<app-purchasing-inbound [title]="subtitle" [dataset]="viewdata" *ngIf="viewapp == 'inbound'"></app-purchasing-inbound>
							<app-purchasing-grns [title]="subtitle" [dataset]="viewdata" *ngIf="viewapp == 'grns'"></app-purchasing-grns>
							<app-purchase-backorders [title]="subtitle" [dataset]="viewdata" *ngIf="viewapp == 'backorders'"></app-purchase-backorders>
						</div>
					</div>
				</div>
			</div>


			<div class="row tp-box__side tp-box__front" id="frontside">
				<div class="col-lg-4 col-md-4 col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-info card-header-icon">
							<div class="card-icon">
								<i class="material-icons">move_to_inbox</i>
							</div>
							<p class="card-category">Open PO's</p>
							<h3 class="card-title">
								{{ totals.open }}
								<span>
									<svg *ngIf="!purchaseorders" class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
							</h3>
							<small>
								<p class="card-category text-info text-justify mt-3 text-right">
								</p>
							</small>
						</div>
						<div class="card-footer">
							<div class="stats">
								<a (click)="showData(purchaseorders, 'pos', 'Open PO\'s')"><i class="material-icons">content_copy</i>
									View Now</a>
							</div>
							<br />
						</div>
					</div>
				</div>
				<div class="" [ngClass]="{'col-lg-4 col-md-4 col-sm-6': viewing_title!='Open GRNs', 'col-lg-12 col-md-12 col-sm-12': viewing_title=='Open GRNs'}">
					<app-outstanding-grns (viewData)="toggleFlipTest($event)" ></app-outstanding-grns>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-danger card-header-icon">
							<div class="card-icon">
								<i class="material-icons">pending_actions</i>
							</div>
							<p class="card-category">Back Order Items</p>
							<h3 class="card-title">
								{{ backorders.length }}
								<span>
									<svg *ngIf="!backorders.length" class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
							</h3>
							<small>
								<p class="card-category text-info text-justify mt-3 text-right">
								</p>
							</small>
						</div>
						<div class="card-footer">
							<div class="stats">
								<a (click)="showData(backorders, 'backorders', 'Back Order Items')"><i class="material-icons">content_copy</i>
									View Now</a>
							</div>
							<br />
						</div>
					</div>
				</div>

				<div class="col-lg-4 col-md-4 col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-danger card-header-icon">
							<div class="card-icon">
								<i class="material-icons">inbox</i>
							</div>
							<p class="card-category">Partially Received PO's</p>
							<h3 class="card-title">
								{{ partials.length }}
								<span>
									<svg *ngIf="!purchaseorders" class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
							</h3>
							<small>
								<p class="card-category text-info text-justify mt-3 text-right">
								</p>
							</small>
						</div>
						<div class="card-footer">
							<div class="stats">
								<a (click)="showData(partials,'pos', 'Partially Received' )"><i class="material-icons">content_copy</i>
									View Now</a>
							</div>
							<br />
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-warning card-header-icon">
							<div class="card-icon">
								<i class="material-icons">inbox</i>
							</div>
							<p class="card-category">Inbound PO's</p>
							<h3 class="card-title">
								{{ inbound.length }}
								<span>
									<svg *ngIf="!purchaseorders" class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
							</h3>
							<small>
								<p class="card-category text-info text-justify mt-3 text-right">
								</p>
							</small>
						</div>
						<div class="card-footer">
							<div class="stats">
								<a (click)="showData(inbound,'inbound', 'Inbound')"><i class="material-icons">content_copy</i>
									View Now</a>
							</div>
							<br />
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-success card-header-icon">
							<div class="card-icon">
								<i class="material-icons">receipt</i>
							</div>
							<p class="card-category">Ready To Invoice</p>
							<h3 class="card-title">
								{{ invoice.length }}
								<span>
									<svg *ngIf="!purchaseorders" class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
							</h3>
							<small>
								<p class="card-category text-info text-justify mt-3 text-right">
								</p>
							</small>
						</div>
						<div class="card-footer">
							<div class="stats">
								<a (click)="showData(invoice,'grns', 'Ready To Invoice')"><i class="material-icons">content_copy</i>
									View Now</a>
							</div>
							<br />
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-primary card-header-icon">
							<div class="card-icon">
								<i class="material-icons">thumb_down_alt</i>
							</div>
							<p class="card-category">Invoice Issues</p>
							<h3 class="card-title">
								{{ invoice_issues.length }}
								<span>
									<svg *ngIf="!purchaseorders" class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
							</h3>
							<small>
								<p class="card-category text-info text-justify mt-3 text-right">
								</p>
							</small>
						</div>
						<div class="card-footer">
							<div class="stats">
								<a (click)="showData(invoice_issues,'pos', 'Invoice Issues')"><i class="material-icons">content_copy</i>
									View Now</a>
							</div>
							<br />
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-danger card-header-icon">
							<div class="card-icon">
								<i class="material-icons">assignment_return</i>
							</div>
							<p class="card-category">Vendor Returns</p>
							<h3 class="card-title">
								{{ returns.length }}
								<span>
									<svg *ngIf="!purchaseorders" class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
							</h3>
							<small>
								<p class="card-category text-info text-justify mt-3 text-right">
								</p>
							</small>
						</div>
						<div class="card-footer">
							<div class="stats">
								<a (click)="showData(returns,'pos','Vendor Returns')"><i class="material-icons">content_copy</i>
									View Now</a>
							</div>
							<br />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
