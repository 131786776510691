import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform,AfterViewInit,Directive, HostListener,ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../../services/customer.service';
import { GlobalsService } from '../../services/globals.service';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { Location , DatePipe } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-system-types-view',
	templateUrl: './system-types-view.component.html',
	styleUrls: ['./system-types-view.component.scss']
})

export class SystemTypesViewComponent implements OnInit {

	@ViewChild('addCustomer') addcustomeref: ElementRef;
	@ViewChild('editExclusions') ruleRef: ElementRef;
	@ViewChild('addVendor') addvendorref: ElementRef;
	@ViewChild('addSalesTypeEle') addsalestyperef: ElementRef;
	@ViewChild('addPaymentTermEle') addPaymentTermref: ElementRef;
	@ViewChild('addClubEle') addclubref: ElementRef;

	isLoading = false;
	salestypes: any = [];
	supptypes: any = [];
	customertypes: any = [];
	payment_terms: any = [];
	all_types: any = [];
	color: any = 'blue'
	customerTypeForm: UntypedFormGroup;
	paymentTermsForm: UntypedFormGroup;
	vendorTypeForm: UntypedFormGroup;
	salesTypeForm: UntypedFormGroup;
	exclusionForm: UntypedFormGroup;
	clubForm: UntypedFormGroup;
	uploaddata: any = false;
	files: any = [];
	tablename: any = false;
	mapcols: any = [
		{ field: 'mfgpart', label: 'Part Number' },
		{ field: 'brand', label: 'Brand' },
		{ field: 'price', label: 'Price' },
		{ field: 'stockid', label: 'Item Number' },
	];
	editrule_type: any = 'P';
	editrule_name: any = '';
	currentrule: any = [];
	currenttype: any = '';
	ruleset: any = [];
	ruletypes: any = {'B': 'Brand',
					  'P': 'Product Line',
					  'C': 'Item Category',
					  'S': 'Specific Item' };
	custtypes: any = [];
	prodlines: any = [];
	categories: any = [];
	brands: any = [];
	clubs: any = [];
	mapping: any = [];

	constructor(private inventoryService: InventoryService, private customerService : CustomerService, private globalsService : GlobalsService, private fb: UntypedFormBuilder,private route: ActivatedRoute,private location: Location,public router: Router,private globalSearchService: GlobalSearchService,private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {

		this.globalSearchService.all_system_types.subscribe((alltypes: any) => {

			this.customerTypeForm = this.fb.group({
				typeid: [''],
				typename: ['', Validators.required],
				address_type: ['2', Validators.required] // Default to '2' (Visible POS)
			});

			this.vendorTypeForm = this.fb.group({
				typeid: [''],
				typename: ['', Validators.required],
			});

			this.salesTypeForm = this.fb.group({
				typeabbrev: ['', Validators.required],
				sales_type: ['', Validators.required],
				costMultiplier: ['1', Validators.required],
				loyaltypoints: ['0', Validators.required],
				fallback: [''],
				tablename: [''],
				newpricebook: [false],
			});

			if(alltypes) {
				this.supptypes = alltypes.supptypes;
				this.customertypes = alltypes.debtortypes;
				this.salestypes = alltypes.salestypes;
			}
			this.inventoryService.getItemCategories().subscribe((res) => {
			Object(res).sort((a,b) => (a.categorydescription > b.categorydescription) ? 1 : ((b.categorydescription > a.categorydescription) ? -1 : 0))
			this.categories = res;
			});
			this.inventoryService.getItemProductlines().subscribe(p => {
			this.prodlines = p
			});
			this.inventoryService.getBrands().subscribe((results: any) => {
			this.brands = results;
			this.customerService.getClubs().subscribe(details => {
			this.clubs = details;
		});
		})

		});

		this.globalSearchService.terms.subscribe((terms: any) => {
			this.payment_terms = terms


			this.paymentTermsForm = this.fb.group({
				termsindicator: ['', Validators.required],
				terms: ['', Validators.required],
				daysbeforedue: ['0', Validators.required],
				dayinfollowingmonth: ['1', Validators.required],
				cc_process: ['0', Validators.required],
				decline: ['0', Validators.required],
				collect_payment:[ '0', Validators.required],
				donot_print: ['0', Validators.required],
				onaccountallowed: ['0', Validators.required],
				order_entry: ['1', Validators.required],
			});
		});
	}

	yesNoValue(input:any) {
		return (input == '1') ? 'Y': 'N';
	}

	onSelect(event: any) {
		this.isLoading = true

		this.files.push(...event.addedFiles);
		for (let i = 0; i < this.files.length; i++) {
			this.readFile(this.files[i]).then(fileContents => {
				const upload = {
					content: fileContents,
					name: event.addedFiles[0].name,
					mime: event.addedFiles[0].type,
				}

				// this.inventoryService.matchItems(results.tablename).subscribe( r=> {
				//
				// });
				//
				this.inventoryService.uploadPriceBook(upload).subscribe((results: any) => {
					this.salesTypeForm.get('tablename').setValue(results.tablename);
					this.tablename = results.tablename;
					this.uploaddata = results;
					this.files = [];
				});

			});
		}

		this.isLoading = false
	}

	updatePriceBook() {

	}

	private async readFile(file: File): Promise < string | ArrayBuffer > {
		return new Promise < string | ArrayBuffer > ((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = e => {
				return resolve((e.target as FileReader).result);
			};

			reader.onerror = e => {
				console.error(`FileReader failed on file ${file.name}.`);
				return reject(null);
			};

			if (!file) {
				return reject(null);
			}
			reader.readAsDataURL(file);
		});

		this.files = [];
	}


	editPaymentTermDispaly(atype: any ) {
		this.isLoading = true;

		this.paymentTermsForm = this.fb.group({
			termsindicator: [atype.termsindicator, Validators.required],
			terms: [atype.terms, Validators.required],
			daysbeforedue: [atype.daysbeforedue, Validators.required],
			dayinfollowingmonth: [atype.dayinfollowingmonth, Validators.required],
			cc_process: [atype.cc_process, Validators.required],
			decline: [atype.decline, Validators.required],
			collect_payment:[ atype.collect_payment, Validators.required],
			donot_print: [atype.donot_print, Validators.required],
			onaccountallowed: [atype.onaccountallowed, Validators.required],
			order_entry: [atype.order_entry, Validators.required],
		});

		this.modalService.open(this.addPaymentTermref, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});

		this.isLoading = false;
	}

	editCustomerTypeDisplay(atype: any ) {
		this.isLoading = true;

		this.customerTypeForm = this.fb.group({
			typeid: [atype.typeid],
			typename: [atype.typename, Validators.required],
			address_type: [atype.address_type, Validators.required],
		});


		this.modalService.open(this.addcustomeref, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {
		}, (reason) => {

		});

		this.isLoading = false;

	}

	editCustomerType(type: any) {
		this.isLoading = true
		if(this.customerTypeForm.valid) {
			this.globalsService.saveCustomerType(this.customerTypeForm.value).subscribe( result => {
				this.customertypes = result;
				this.modalService.dismissAll();
			});
		}

		this.isLoading = false
	}

	removeCustomerType(type: any) {
		this.isLoading = true

		this.globalsService.removeCustomerType(type).subscribe( result => {
			this.customertypes = result;
		});

		this.isLoading = false
	}

	addCustomerType() {
		this.isLoading = true

		if(this.customerTypeForm.valid) {
			this.globalsService.saveCustomerType(this.customerTypeForm.value).subscribe( result => {
				this.customertypes = result;
				this.modalService.dismissAll();
			});
		}

		this.isLoading = false
	}
	typechange(){

		this.editrule_type = this.exclusionForm.get('rule_type').value;
	}
	editCustomerTypeExclusions(custtype: any){

		this.editrule_type = 'P';
		const data = {'typeid': custtype.typeid };
		this.editrule_name = custtype.typename;
		this.currenttype = custtype.typeid;
		this.currentrule = '';
		this.globalsService.getTypeExclusionRules(data).subscribe((a)=>{
		this.ruleset = a;

			this.exclusionForm = this.fb.group({
			'rule_type': ['',Validators.required],
			'rule_inclusive':['1'],
			'rule_value':['',Validators.required],
			});
			this.modalService.open(this.ruleRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
		});
	}
	editRule(rule: any){

				this.editrule_type = rule.ruletype;
				this.currentrule = rule.id;
				this.exclusionForm = this.fb.group({
					rule_type: [rule.ruletype, Validators.required],
					rule_value: [rule.rulevalue, Validators.required],
					rule_inclusive: [rule.inclusive, Validators.required],
				});
	}
	deleteRule(ruleid: any){
		const data = { 	'typeid': this.currenttype,
						 'id': ruleid.id,
						 };

		this.globalsService.deleteExclusionRule(data).subscribe(r => {
			this.ruleset = r;
		});

	}
	saveRule(){

		const data = { 	'typeid': this.currenttype,
						 'id': this.currentrule,
						 'rule_type' : this.exclusionForm.get('rule_type').value,
						 'rule_value': this.exclusionForm.get('rule_value').value,
						 'rule_inclusive' : this.exclusionForm.get('rule_inclusive').value
						 };

		this.globalsService.saveExclusionRule(data).subscribe(r => {
			this.ruleset = r;
			this.currentrule = '';
			this.exclusionForm.get('rule_value').setValue('');
			this.exclusionForm.get('rule_type').setValue('');
		});
	}
	addPaymentTermView() {
		this.isLoading = true;

		this.paymentTermsForm = this.fb.group({
			termsindicator: ['', Validators.required],
			terms: ['', Validators.required],
			daysbeforedue: ['0', Validators.required],
			dayinfollowingmonth: ['1', Validators.required],
			cc_process: ['0', Validators.required],
			decline: ['0', Validators.required],
		});

		this.modalService.open(this.addPaymentTermref, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
		this.isLoading = false;
	}


	addCustomerTypeView() {
		this.isLoading = true

		this.modalService.open(this.addcustomeref, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});

		this.isLoading = false
	}

	addVendorTypeDisplay() {
		this.isLoading = true

		this.modalService.open(this.addvendorref, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});

		this.isLoading = false
	}

	editVendorTypeDisplay(atype: any) {
		this.isLoading = true

		this.vendorTypeForm = this.fb.group({
			typeid: [atype.typeid],
			typename: [atype.typename, Validators.required],
		});

		this.modalService.open(this.addvendorref, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});

		this.isLoading = false
	}

	removeVendorType(type: any) {
		this.isLoading = true

		this.globalsService.removeVendorType(type).subscribe( result => {
			this.supptypes = result;
		});

		this.isLoading = false
	}

	editSalesTypeDispaly(atype:any) {
		this.salesTypeForm = this.fb.group({
			typeabbrev: [atype.typeabbrev, Validators.required],
			sales_type: [atype.sales_type, Validators.required],
			costMultiplier: [atype.sales_type, Validators.required],
			loyaltypoints: [atype.loyaltypoints, Validators.required],
			ispricebook: [atype.ispricebook, Validators.required],
			fallback: [atype.fallback],
			newpricebook: [false],
			tablename: [''],
		});

		this.modalService.open(this.addsalestyperef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});

	}

	addVendorType() {
		if(this.vendorTypeForm.valid) {
			this.globalsService.saveVendorType(this.vendorTypeForm.value).subscribe( result => {
				this.supptypes = result;
				this.modalService.dismissAll();
			});
		}
	}

	editSalesType(type: any) {

	}

	removeSalesType(type: any) {
		//TODO! PREVENT THIS IF CUSTOMERS SETUP @ PRICE TYPE
		const confirm_remove = confirm('Remove sales type ' + type.typeabbrev + '?');
		if(confirm_remove) {
			this.globalsService.removeSalesType(type).subscribe( result => {
				this.salestypes = result;
			});
		}
	}

	removePaymentTerm(type: any) {
		//TODO! PREVENT THIS
		const confirm_remove = confirm('Remove Term ' + type.typename + '?');
		if(confirm_remove) {
			this.globalsService.removePaymentTerm(type).subscribe( result => {
				this.payment_terms = result;
			});
		}

	}

	addPaymentTerm() {

		if(this.paymentTermsForm.valid) {
			this.globalsService.savePaymentTerm(this.paymentTermsForm.value).subscribe( result => {
				this.payment_terms = result;
				this.modalService.dismissAll();
			});
		}

	}

	addSalesTypeDisplay() {
		this.modalService.open(this.addsalestyperef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}

	addSalesType() {

		if(this.salesTypeForm.valid) {
			this.globalsService.saveSalesType(this.salesTypeForm.value).subscribe( result => {
				this.salestypes = result.salestypes;
				this.modalService.dismissAll();
			});
		}

	}
	addClubDisplay() {

		this.clubForm = this.fb.group({
			id: [],
			club: ['', Validators.required],
		});
		this.modalService.open(this.addclubref, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}
	editClubDisplay(club: any) {

		this.clubForm = this.fb.group({
			id: [club.id],
			club: [club.club, Validators.required],
		});
		this.modalService.open(this.addclubref, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}
	addClub(){

		this.customerService.saveClub(this.clubForm.value).subscribe( result => {
				this.clubs = result;
			});
	}
	removeClub(type: any) {
		//TODO! PREVENT THIS IF CUSTOMERS SETUP @ CLUB
		const confirm_remove = confirm('Remove club ' + type.club + '?');
		if(confirm_remove) {
			this.customerService.removeClub(type).subscribe( result => {
				this.clubs = result.clubs;
				if(result.success){
				this.globalSearchService.showNotification('Updated','success','bottom', 'right');
				} else {
				this.globalSearchService.showNotification('Club still contains customers','danger','bottom', 'right');
				}
			});
		}
	}
	getFallbackLevel() {
		const value: string = this.salesTypeForm.get('typeabbrev').value;
		return (value != 'J' && value != 'B') ? 'B'  : '' ;
	}


	back(): void {
		this.location.back()
	}

	ngAfterViewInit(): void {

	}
}
