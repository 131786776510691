<div class="content-container ml-2 mr-2" [ngClass]="removeConfirmOpen ? 'dialogOpen' : 'dialogClosed'">
	<p-accordion [activeIndex]="0" *ngIf="details" class="pl-2 pr-2" id="groups">
		<ng-container *ngFor="let group of details.groups">
			<p-accordionTab>
				<ng-template pTemplate="header">
					<span class="flex align-items-center gap-2 w-full">
						<span class="font-bold white-space-nowrap title">{{ group.name }}&nbsp;&nbsp;</span>
						<span class="font-weight-lighter">&nbsp;&nbsp;{{ group.description }}</span>
						<p-badge value="{{ group.products.length }} Items in group" class="badge-right" severity="info"></p-badge>
					</span>
				</ng-template>
				<ul class="prodlist" style="list-style-type: none; padding: 0; margin: 0;">
					<div class="row sticky">
						<div class="col-md-1 img-min-width"></div>
						<div class="col-md-4"></div>
						<div class="col-md-6">
							<div class="row justify-content-between h-20 text-nowrap">
								<div class="col-md-2 mt-auto mb-auto">
									<h4>Line</h4>
								</div>
								<div class="col-md-4 mt-auto mb-auto">
									<h4>Brand</h4>
								</div>
								<div class="col-md-2 mt-auto mb-auto">
									<h4>QOH</h4>
								</div>
								<div class="col-md-4 mt-auto mb-auto">
									<h4>Price</h4>
								</div>
							</div>
						</div>
						<div class="col-md-1"></div>
					</div>
					<li *ngFor="let prod of group.products" [ngClass]="prod.stockid == details.stockid ? 'viewingid prodrow' : 'prodrow'" style="margin-bottom: 10px;">
						<div class="row h-100">
							<div class="col-md-1 img-min-width">
								<img [src]="prod.image" width="75px" height="75px">
							</div>
							<div class="col-md-4">
								<div class="mt-auto mb-auto">
									<span *ngIf="prod.stockid !== details.stockid">
										<a [routerLink]="['/inventory/view', prod.stockid]">{{ prod.stockid }}</a>
									</span>
									<span *ngIf="prod.stockid == details.stockid">{{ prod.stockid }}</span>
									<br>
									<span class="font-weight-lighter text-decoration-none">{{ prod.description }}</span>
								</div>
							</div>
							<div class="col-md-6">
								<div class="row justify-content-between h-100">
									<div class="col-md-2 mt-auto mb-auto">
										<span>{{ prod.lineid }}</span>
									</div>
									<div class="col-md-4 mt-auto mb-auto">
										<span>{{ prod.manufacturer.name }}</span>
									</div>
									<div class="col-md-2 mt-auto mb-auto">
										<span>{{ prod.qoh }}</span>
									</div>
									<div class="col-md-4 mt-auto mb-auto">
										<span>{{ prod.prices[0].price | currency }}</span>
									</div>
								</div>
							</div>
							<div class="col-md-1">
								<div class="col-md-12 mt-auto mb-auto">
									<p-toast position="top-right"></p-toast>
									<p-confirmPopup></p-confirmPopup>
									<ng-container *ngIf="prod.stockid == details.stockid">
										<p-badge (click)="removeFromGroup($event, prod, group)" value="remove" severity="success"></p-badge>
									</ng-container>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</p-accordionTab>
		</ng-container>
	</p-accordion>
</div>
