import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { CustomerService } from '../../services/customer.service';


@Component({
	selector: 'app-matching-doc-customer-type',
	templateUrl: './matching-doc-customer-type.component.html',
	styleUrls: ['./matching-doc-customer-type.component.scss'],
	animations: [
		trigger('flipState', [
	   state('active', style({
		   transform: 'rotate3d(0, 1, 0, 179deg)',
	   })),
	   state('inactive', style({
		   transform: 'rotateY(0)'
	   })),
	   state('orderlookup', style({
		   transform: 'rotateY(179deg)'
	   })),
	   transition('active => inactive', animate('500ms ease-out')),
	   transition('inactive => active', animate('500ms ease-in')),
	   transition('inactive => orderlookup', animate('500ms ease-out')),
	   transition('orderlookup => inactive', animate('500ms ease-in')),
		]),
		trigger('openClose', [
		  state(':enter', style({ height: '*' })),
		  state(':leave', style({ height: '0px' })),
		  transition('false <=> true', animate(500))
	  ]),
	  trigger('filterAnimation', [
		  transition(':enter, * => 0, * => -1', []),
		  transition(':increment', [
			  query(':enter', [
				  style({ opacity: 0, width: 0 }),
				  stagger(50, [
					  animate('300ms ease-out', style({ opacity: 1, width: '*' })),
				  ]),
			  ], { optional: true })
		  ]),
		  transition(':decrement', [
			  query(':leave', [
				  stagger(50, [
					  animate('300ms ease-out', style({ opacity: 0, width: 0 })),
				  ]),
			  ])
		  ]),
	  ]),
	  trigger('grow', [ // Note the trigger name
		  transition(':enter', [
			  // :enter is alias to 'void => *'
			  style({ height: '0', width: '0' }),
			  animate(200, style({ height: '*', width: '*' })),
			  animate('200ms', style({ opacity: 1 })),
		  ]),
		  transition(':leave', [
			  // :leave is alias to '* => void'
			  animate(100, style({ height: 0, width: 0 })),
			  animate('100ms', style({ opacity: 0 }))
		  ])
	  ]),
	]
})
export class MatchingDocCustomerTypeComponent implements OnInit {
	@Input() flip = 'inactive';
	@Output() viewData = new EventEmitter < any > ();
	viewing: any = false;

	data: any = false;
	title = 'Matching National Account Receivables';

	constructor(private customerService: CustomerService) {}

	ngOnInit(): void {
		this.customerService.getMatchingTypeDocs().subscribe(async (results: any) => {
			this.data = results;
		});
	}

	toggleFlip(data: any, title: string) {

		if (!data) {
			this.flip = 'inactive';
		} else {
			this.flip = (this.flip == 'active') ? 'inactive' : 'active';
		}

		if (this.flip == 'active') {
			this.viewing = data;
			this.title = title
			const dashboard_data = { data: data, title: this.title }
			this.viewData.emit(dashboard_data);
		} else {
			this.viewData.emit(false);
		}
	}
}
