<div class="main-content">
	<div class="container-fluid">
		<div class="card" *ngIf="problems.length < 1">
			<div class="card-body">
				<div class="row">
				<div class="col-8">
				<mat-label>Vendor</mat-label>
				<mat-select (selectionChange)="getRemainingReceiving()" [(ngModel)]="selectedVendor">
				<mat-option *ngFor="let v of vendors" [value]="v.supplierid">
											{{v.supplierid}} - {{v.suppname}}
										</mat-option>
				</mat-select>
				</div>
				<div class="col-4 text-right">
				 <h1>{{scanned}}/{{expected}}</h1>
				</div>
				</div>
				<div class="row">
				<div class="col-12">
				<mat-progress-bar mode="determinate" [value]="percentdone"></mat-progress-bar>
				</div>
				</div>
				<div class="row text-right">
				<button mat-raised-button color="standard" (click)="checkDiscrepancy()">Show Issues</button>&nbsp;
				<button mat-raised-button color="standard" (click)="holdRec()">Pause Receiving</button>
				</div>
				<div class="row">
				
				<div class="item-box">
								<ng-container *ngFor="let item of openlines">
									<mat-card class="item-box-content text-center" [ngClass]="{picked: item.received == item.ordered , overpicked: item.received > item.ordered}">

										<mat-card-title class="pl-1 text-center">PO#{{ item.order }} <br>({{ item.stockid }})</mat-card-title>
										<!--<mat-divider inset></mat-divider>-->
										<mat-card-content class="m-1 pl-1 pr-1">
										<h3>{{item.received }} / {{item.ordered }}</h3>
										<mat-progress-bar mode="determinate" [value]="item.received/item.ordered*100"></mat-progress-bar>
										</mat-card-content>
									</mat-card>
								</ng-container>
							</div>
						</div>
				
			</div>
		</div>
		<div class="card" *ngIf="problems.length > 0">
			<div class="card-body">
				<div class="row">
				<h3> Problems Report </h3>
				</div>
				<div class="row text-right">
				<button mat-raised-button color="standard" (click)="clearDiscrepancy()">Back to Scan</button> <button mat-raised-button color="standard" (click)="exportPDF('probrep')">Print Discrepancies</button>
				<br><button mat-raised-button color="standard" (click)="finalize()">Finalize Receiving</button>
				</div>
				<div class="row" id="probrep">
					<table class="table" width="100%">
					<tr><th>Item Number</th><th>Expected</th><th>Scanned</th></tr>
					<tr *ngFor="let p of problems">
					<td>{{p.stockid}}</td><td>{{p.ordered}}</td><td>{{p.received}}</td>
					</tr>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
