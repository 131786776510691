<div class="main-content">
	<div class="container-fluid">
		<div class="col-md-12">
			<div class="card pr-0 pl-0">
				<div class="card-header card-header-{{color}}-2">
					<h4 class="card-title "><i class="material-icons">arrow_left</i> Picking Lists</h4>
					<p class="card-category"></p>
					<div class="messages row">
						<div class="col-12 col-md-12">

						</div>
					</div>
				</div>
				<div class="card-body">
					<form [formGroup]="searchForm" >
						<mat-form-field appearance="outline">
							<mat-label>Search For Order</mat-label>
							<input matInput required value="" formControlName="keywords" class="text-right">
							<span matSuffix><i class="material-icons">search</i></span>
						</mat-form-field>
					</form>
					<ng-container *ngIf="orders">
						<div class="row border-bottom" *ngFor=" let order of orders">
							<div class="col-3">
								<a href="{{ baselink + order.orderno }}" class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-md-2" >
									<small><i class="material-icons">print</i></small>
								</a>

							</div>
							<div class="col-3">
								{{ order.orddate }} <br>
								{{ order.orderno }}
							</div>
							<div class="col-3">
								<b>{{ order.debtorno }}-{{ order.branchcode }}</b>
								{{ order.deliverto }}<br>
								{{ order.deladd1 }}
							</div>
							<div class="col-3">
								{{ order.customerref }}

							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
