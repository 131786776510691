import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { UntypedFormBuilder, UntypedFormControl, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';

import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { PurchasingService } from '../../../services/purchasing.service';
import { PrintService } from '../../../services/print.service';
import { InventoryService } from '../../../services/inventory.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
	selector: 'app-purchasing-dailychurn',
	templateUrl: './purchasing-dailychurn.component.html',
	styleUrls: ['./purchasing-dailychurn.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotateY(179deg)'
			})),
			transition('active => inactive', animate('500ms ease-out')),
			transition('inactive => active', animate('500ms ease-in')),
			transition('inactive => orderlookup', animate('500ms ease-out')),
			transition('orderlookup => inactive', animate('500ms ease-in')),
		])
	]
})
export class PurchasingDailychurnComponent implements OnInit {
	@ViewChild('purchasedetails') purchasedetails: ElementRef;
	@ViewChild('itemhistoryele') itemhistoryele: ElementRef;
	@ViewChild('exportTable') exportTableRef: ElementRef;


	flip = 'inactive';
	locations: any = [];
	reportForm: UntypedFormGroup;
	today = new Date();
	results: any = [];
	loading = false;
	suppliers: any = [];
	purchase_item: any = [];
	foundvendors: any = [];
	itemhistory: any = false;
	item: any = false;
	purchitem: any = false;
	months: any = false;
	multiplier = new UntypedFormControl(60)
	rolling_months: any = false;
	loading_remote: any = false;
	filters: any = [];
	futuremonths: any = [];
	selected_filters: any = [];
	selected_checks: any = [];
	purchase_items: any = [];
	brands: any = [];
	po_vendors: any = [];
	remoteqtys: any = [];
	product_lines: any = [];
	allresults: any = [];
	po_vendors_found: any = false;
	wathchlist: any = [];
	indexnow = 1;
	sending = false;
	currentstep = 0;
	allitems: any = false;
	watch_items: any = false;
	retriving: any = false;
	reviewlabel = 'Review Primary Recommendations'
	config: any = [];
	user: any = false;
	winter_values: any = [{ 'label': 'Non Winter Tires', 'value': '0' }, { 'label': 'Winter Tires', 'value': '1' }, { 'label': '3-Peak All-Weather', 'value': '2' }];
	numberofdays: any = 0;
	constructor(private inventoryService: InventoryService, private purchasingService: PurchasingService, private globalSearchService: GlobalSearchService, private location: Location, private route: ActivatedRoute, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal, private printService: PrintService) {

		this.config = this.globalsService.getApiConfig();
		this.globalSearchService.user.subscribe(results => {
			this.user = results
		});

	}

	getFilterString(): string {
		return '';
	}

	ngOnInit(): void {
		this.purchasingService.getFiltersTwo().subscribe(async (result: any) => {
			this.filters = result;
		});

		this.purchasingService.getSuppliers('').subscribe((results: any) => {

			const today = new Date();
			const priorDate = new Date(new Date().setDate(today.getDate() - 365));

			this.numberofdays = this.datediff(today, priorDate);

			this.suppliers = results;
			this.reportForm = this.fb.group({
				startdate: [priorDate, Validators.required],
				enddate: [today, Validators.required],
				minimum_sales: [0, Validators.required],
				item_type: [1, Validators.required],
				numberofmonths: [2],
				numberofweeks: [8],
				loccode: [this.user.user.defaultlocation.loccode, Validators.required],
				filters: [],
				brands: [],
				season: [],
				lines: [],
				supplier: ['', Validators.required],
				days_supply: [60]
			});
			
			
			
			this.inventoryService.getBrands().subscribe((r: any) => {
				this.brands = r;

				const selected = [];
				this.brands.forEach(a => {
					selected.push(a.name)
				});
				this.reportForm.get('brands').setValue(selected)
			});

			this.inventoryService.getItemProductlines().subscribe((r: any) => {
				this.product_lines = r;

				const selected = [];
				// this.brands.forEach( a=> {
				// 	selected.push(a.name)
				// });
				// this.reportForm.get('brands').setValue(selected)
			});

			this.reportForm.markAllAsTouched();
			
			Object.keys(this.reportForm.controls).forEach(field => {
			  const control = this.reportForm.get(field);
			  if (control.invalid) {
				console.log(`Error in ${field}: `, control.errors);
			  }
			});
		});
	}

	datediff(first, second) {
		return Math.round((second - first) / (1000 * 60 * 60 * 24));
	}

	updateTargets(value: any) {
		if (this.po_vendors_found && this.po_vendors_found.length) {
			this.po_vendors_found.forEach(v => {
				this.results[v].forEach(i => {
					i.target_avg_day = (i.avg_day * value) - i.actual_on_hand
				})
			})
		}
	}

	updateTargetsPullover() {
		if (this.po_vendors_found && this.po_vendors_found.length) {
			this.po_vendors_found.forEach(v => {
				this.results[v].forEach(i => {
					i.target_avg_day = (i.avg_day * this.reportForm.get('days_supply').value) - i.actual_on_hand
				})
			})
		}
	}

	updateProductLines() {
		const data = {
			brands: this.reportForm.get('brands').value
		}
		this.inventoryService.getBrandProductlines(data).subscribe((r: any) => {
			this.product_lines = r;

			const selected = [];
			this.product_lines.forEach(a => {
				selected.push(a.line_field)
			});
			this.reportForm.get('lines').setValue(selected)
		});
	}

	updateBrands(event: any) {
		this.reportForm.get('supplier').setValue(event.supplierid)
		const supplierid = event.supplierid;
		const defaultBrands = this.brands.filter(r => {
			return supplierid == r.a2
		})
		const selected = [];
		defaultBrands.forEach(a => {
			selected.push(a.name)
		})

		if (!selected.length) {
			// this.brands.forEach( a=> {
			// 	selected.push(a.name)
			// })
		}

		this.reportForm.get('brands').setValue(selected)


		const data = {
			supplier: event.supplierid
		}
		this.inventoryService.getBrandProductlines(data).subscribe((r: any) => {
			this.product_lines = r;

			const selected = [];
			this.product_lines.forEach(a => {
				selected.push(a.line_field)
			});
			this.reportForm.get('lines').setValue(selected)
		});

	}

	getItemHistory(pitem: any) {
		this.purchitem = pitem;

		this.inventoryService.getItem({ stockid: pitem.stockid }).subscribe((item: any) => {
			this.item = item;
			this.inventoryService.getHistory(pitem.stockid).subscribe((results: any) => {
				this.itemhistory = results;
				this.modalService.open(this.itemhistoryele, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

				}, (reason) => {
					this.itemhistory = {};
				});

			})
		})
	}

	updateChecks(event: any, filter_id: any) {

		if (!this.selected_checks[filter_id]) {
			this.selected_checks[filter_id] = [];
		}

		const data = { value: event.source.value };

		if (event.checked) {
			this.selected_checks[filter_id].push(data)
		} else {

			const index = this.selected_checks[filter_id].indexOf(data);

			this.selected_checks[filter_id] = this.selected_checks[filter_id].filter((selected, index) => {
				return selected.value != event.source.value
			});
		}
		//prevent empty send
		if (this.selected_checks[filter_id].length) {
			this.selected_filters[filter_id] = { filter_id: filter_id, value: this.selected_checks[filter_id] };
		} else {
			this.selected_filters[filter_id] = {};
		}
	}

	createBatchBo() {
		this.flip = (this.flip == 'inactive') ? 'active' : 'inactive';
	}

	creteMultiplePos() {

	}

	updateSearchSelect(event: any, filter_id: any) {
		this.selected_filters[filter_id] = { filter_id: filter_id, value: event.value };
	}

	updateSearchSelectMulti(event: any, filter_id: any) {
		//event.value.valueforEach(val => {
		this.selected_filters[filter_id] = { filter_id: filter_id, value: event.value };

		//})
	}

	editBin(item: any) {
		item.editing = (item.editing) ? false : true;
	}

	updateQuantity(event: any, item: any) {
		this.po_vendors.forEach((po: any) => {
			const poline = po.lines.filter(a => {
				return a.stockid == item.stockid;
			})[0]
			if (poline) {
				poline.orderqty = event;
			}
		})

		item.qty_purchase = event;
		item.orderqty = event;
	}

	updatePrice(event: any, item: any) {
		item.price = event;
		this.reduceItems();
	}


	updateBinItem(event: any, item: any) {
		const index = this.results.indexOf(item);
		item.bin = event;
		item.editing = false;
	}

	setPurchOrderOptions(item: any, pdata: any) {
		item.selected_option = pdata;
		this.modalService.dismissAll();
	}

	viewPurchData(item: any) {
		this.purchase_item = item;


		this.modalService.open(this.purchasedetails, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {

		}, (reason) => {
			this.purchase_item = {};
		});
	}

	exportXls() {

		const encoded: string = this.globalSearchService.base64encode(this.exportTableRef.nativeElement.innerHTML);

		const today = new Date();

		const data = {
			content: encoded,
			filename: 'DailySalesMixReorder',
			title: 'Daily Sales Mix Reorder Re-Order',
			subtitle: 'Created ' + today.toLocaleString(),
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});

	}

	removePoItem(line: any, po: any, item: any) {
		const poindex = this.po_vendors.indexOf(po);
		const lineindex = this.po_vendors[poindex].lines.indexOf(line);
		this.po_vendors[poindex].lines.splice(lineindex, 1);
		const index = this.results.indexOf(item);
		this.results.splice(index, 1);
	}

	removeItem(item: any) {
		const index = this.results.indexOf(item);
		this.results.splice(index, 1);
	}

	reduceItems() {

		this.foundvendors = this.allresults.vendors
		this.months = this.allresults.months
		this.futuremonths = this.allresults.future_months
		const poitems = [];
		this.allresults.data.forEach((item: any) => {

			const already_ordered = 0;
			const qty = item.qty_purchase;

			item.vendor = item.selected_option.supplierno;
			item.price = parseFloat(item.selected_option.price);
			const data = {
				vendor: item.selected_option.supplierno,
				vendor_name: item.selected_option.suppname,
				stockid: item.stockid,
				description: item.description,
				orderqty: qty,
				price: parseFloat(item.selected_option.price),
				options_selected: item.selected_option,
				purchdata: item.purchdata,
				item: item,
			}
			poitems.push(data);

		});

		const all_vendors = poitems.map(a => a.vendor);
		const unique_vendors = all_vendors.filter((x, i, a) => a.indexOf(x) == i)
		this.purchase_items = poitems;
		if (poitems.length) {
			const data = { poitems: poitems }
			this.loading_remote = true;
			this.purchasingService.getRemoteQty(data).subscribe((results: any) => {
				this.allresults = results;
				this.loading_remote = false;
				//map data down: broken down by vendor
				//this.reduceItems();
			});
		}
	}

	getRecomendations() {

		if (this.reportForm.valid) {
			this.indexnow = 1;

			this.reviewlabel = 'Review Primary Recommendations'
			this.reportForm.get('filters').setValue(this.selected_filters);
			this.loading = true;

			if (this.retriving) {
				this.retriving.unsubscribe();
			}

			this.retriving = this.purchasingService.getDailyRecomendations(this.reportForm.value).subscribe((results: any) => {

				this.loading = false;
				this.allresults = results;
				this.rolling_months = results.rolling_months
				this.results = results.data;
				this.allitems = results.data;
				this.months = results.months;
				this.futuremonths = results.future_months;
				this.po_vendors_found = results.vendors;
				this.watch_items = results.watch_items;
				this.retriving = false;

				this.updateTargetsPullover()
			});
		}
	}


	submitPo(po: any) {

		const proceed = confirm('You are about to create a purchase order. Continue?');

		if (proceed) {

			this.sending = true;
			const vendor = this.suppliers.filter((s: any) => {
				const findindex = s.supplierid + ': ' + s.suppname;
				return po == findindex
			})[0];

			const remove_vendor = po;

			vendor.loccode = this.reportForm.get('loccode').value;
			vendor.lines = this.results[po].filter((i: any) => { return i.qty_purchase > 0 });

			if (vendor.lines.length) {
				const data = [
					{ vendor: vendor }
				]

				this.purchasingService.createPurchaseOrdersFromRecomend(data).subscribe((result: any) => {
					this.sending = false;
					if (result.success) {

						this.globalSearchService.showNotification(result.message, 'success', 'bottom', 'right');

						this.indexnow++;

						switch (this.indexnow) {
						case 2:
							this.reviewlabel = 'Review Secondary Recommendations'
							break;
						case 3:
							this.reviewlabel = 'Review Tertiary Recommendations'
							break;
						}
						const newresults = this.results[po].filter((ro: any) => {
							return ((ro.qty_purchase <= ro.target_purchase) && ro.purchdata.length);
						});

						const watchlist = this.results[po].filter((ro: any) => {
							return ((ro.qty_purchase > ro.target_purchase) && ro.purchdata.length);
						});

						const next_set = [];
						const suppindex = '';
						this.results = [];


						newresults.forEach((i: any, index) => {
							//secondary supplier?
							const newpdata = i.purchdata.filter((p: any) => {
								return parseInt(p.preferred_sort) == this.indexnow;
							})[0]

							if (newpdata) {
								const suppindex = newpdata.supplierno + ': ' + newpdata.suppname;
								const thisalreadythere = this.po_vendors_found.indexOf(suppindex);

								if (thisalreadythere < 0) {
									this.po_vendors_found.push(suppindex)
								}

								i.vendor = suppindex
								i.selected_option = newpdata
								i.qty_purchase = i.target_purchase - i.qty_purchase;
								i.total_available = newpdata.total_available

								if (newpdata.total_available < i.qty_purchase) {
									i.qty_purchase = newpdata.total_available;
								}

								if (!this.results[suppindex]) {
									this.results[suppindex] = [];
								}

								this.results[suppindex].push(i)

							} else {

							}
						});

						watchlist.forEach((i: any, index) => {
							this.wathchlist.push(i)
						});


						const remove = this.po_vendors_found.indexOf(po)
						this.po_vendors_found.splice(remove, 1)

						if (this.po_vendors_found.length) {
							this.currentstep = 0;
						}
						this.sending = false;
					} else {
						alert(result.message)
						this.sending = false;
					}


				});
			} else {
				this.sending = false;
			}
		}
	}

	vendorHasFillable(po: any) {
		const openlines = this.results[po].filter((line: any) => {
			return line.qty_purchase > 0
		})
		return (openlines.length) ? true : false;
	}
	printResults(po: any) {

		const vendor = this.suppliers.filter((s: any) => {
			const findindex = s.supplierid + ': ' + s.suppname;
			return po == findindex
		})[0];

		vendor.loccode = this.reportForm.get('loccode').value;
		vendor.lines = this.results[po];

		const data = vendor

		this.purchasingService.printPreviewPo(data).subscribe((results: any) => {
			this.goToLink(results.url);
		});
	}

	goToLink(url: string) {
		window.open(url, '_blank');
	}

	discontinueItems() {


		const proceed = confirm('Discontinue ' + this.watch_items.length + ' Items?')
		if (proceed) {
			this.purchasingService.discontinueItems(this.watch_items).subscribe((result: any) => {
				this.watch_items = this.watch_items.filter((r: any) => {
					return (!r.watchlist);
				});
			});
		}

	}

	dateChanged() {
		this.numberofdays = this.datediff(new Date(this.reportForm.get('enddate').value), new Date(this.reportForm.get('startdate').value));
	}

	ngAfterViewInit() {

		this.globalSearchService.locations.subscribe(async (results: any) => {
			this.locations = results;
		});
	}

}
