import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Message } from '../classes/message';
import { Observable } from 'rxjs';
import { io } from 'socket.io-client';
import { GlobalSearchService } from './globalsearchservice.service';
import { UsersService } from './users.service';

@Injectable({
	providedIn: 'root'
})


export class ChatService {
	socket;
	public chain = [];
	environment:any = [];
	config: any = [];
	userData: any = [];
	package: any = '';

	constructor(private usersService: UsersService, private globalSearchService: GlobalSearchService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});

		this.globalSearchService.user.subscribe(r => {
			if(r) {
				this.userData = r.user;
				const token = this.userData.userid
				this.setupSocketConnection(token);
			}
		});

		this.globalSearchService.socket.subscribe( existingsocket => {
			if(existingsocket) {
				this.socket = existingsocket;
			}
		});
	}

	setupSocketConnection(token: string) {
		if(!this.socket) {
			this.socket = io(this.config.apiServer.socket, {
				auth: {
					token
				}
			});
			this.globalSearchService.socket.next(this.socket);
		}

		this.globalSearchService.socket.subscribe( existingsocket => {
			if(existingsocket) {
				this.socket = existingsocket;
			}
		});
	}

	// Handle message receive event
	subscribeToMessages = (cb) => {

		if (!this.socket) {
			this.setupSocketConnection(this.userData.username)
		}

		this.joinRoom('messages');

		this.socket.on('messagesent', msg => {
			this.chain = [...this.chain, msg];
			//this.globalSearchService.messages.next(this.chain);
			return cb(null, msg);
		});
	}

	subscribeToCards = (cb) => {
		if (!this.socket) {return (true);}
		this.socket.on('cardupdate', msg => {
			return cb(null, msg);
		});
	}

	sendMessage = ({ message, roomName }, cb) => {
		if (this.socket) {this.socket.emit('messagesent', { message, roomName }, cb);}
	}

	sendCardUpdate = (data, cb) => {
		if (this.socket) {this.socket.emit('cardupdate', data, cb);}
	}

	joinRoom = (roomName) => {
		this.socket.emit('join', roomName);
	}

	disconnect() {
		if (this.socket) {
			this.socket.disconnect();
		}
	}
}
