import { Component, SimpleChanges, OnChanges,OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform , Output, EventEmitter } from '@angular/core';
import { DatePipe ,Location } from '@angular/common';
import {  FormGroup, FormControl, UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective,UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import * as FileSaver from 'file-saver';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
	selector: 'app-inventory-locations-create',
	templateUrl: './inventory-locations-create.component.html',
	styleUrls: ['./inventory-locations-create.component.scss'],
	animations: [
		trigger('grow', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: '0', width: '0' }),
				animate(200, style({ height: '*', width: '*' })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate(100, style({ height: 0, width: 0, })),
				animate('100ms', style({ opacity: 0 }))
			])
		]),
	]

})
export class InventoryLocationsCreateComponent implements OnInit {

	@Input() editing: any = false;
	@Output() complete = new EventEmitter <any> ();
	receipt_message_middleman = new UntypedFormControl('');
	editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: 'auto',
		minHeight: '0',
		maxHeight: 'auto',
		width: 'auto',
		minWidth: '0',
		translate: 'yes',
		enableToolbar: true,
		showToolbar: true,
		defaultParagraphSeparator: '',
		defaultFontName: '',
		defaultFontSize: '',
		fonts: [
			{ class: 'arial', name: 'Arial' },
			{ class: 'times-new-roman', name: 'Times New Roman' },
			{ class: 'calibri', name: 'Calibri' },
			//{ class: 'comic-sans-ms', name: 'Comic Sans MS' }
		],
		customClasses: [{
			name: 'quote',
			class: 'quote',
		},
		// {
		// 	name: 'redText',
		// 	class: 'redText'
		// },
		{
			name: 'Title',
			class: 'titleText',
			tag: 'h2',
		},
		],
		sanitize: true,
		toolbarPosition: 'top',
		toolbarHiddenButtons: [
			['bold', 'italic'],
			['fontSize']
		]
	};

	loccode: any = '';
	locselected = false;
	ld: any = [];
	term: any = '';

	taxgroup: any = '';
	taxes: any = [];
	currencies: any = [];
	bankaccounts: any = [];
	types: any = [];
	zones: any = [];
	locationForm: any = false;
	//todo move this to config
	config: any =[];
	alltaxes: any = false;

	constructor(private route: ActivatedRoute, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, private fb: UntypedFormBuilder, public globalsService: GlobalsService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})

		this.globalSearchService.taxgroups.subscribe(async (results: any) => {
			this.taxes = results;
			this.alltaxes = results;
		});

	}

	ngOnInit(): void {
		this.globalsService.getDefaultZones().subscribe(details => {
			this.zones = details;
		});
	}

	ngOnChanges(changes: any) {


		//todo make this bettter
		if(changes.editing.currentValue == '') {
			this.locationForm = this.fb.group({
				loccode: ['', [Validators.required]],
				locationname: ['', [Validators.required]],
				deladd1: ['', [Validators.required]],
				deladd2: ['', []],
				deladd3: ['', [Validators.required]],
				deladd4: [this.config.defaults.region, [Validators.required]],
				deladd5: ['', [Validators.required]],
				deladd6: [this.config.defaults.country, [Validators.required]],
				tel: ['', []],
				email: ['', []],
				contact: ['', [Validators.required]],
				fax: ['', []],
				receipt_message:['', []],
				taxgroupid:['', []],
				taxgroupid_cert:['', []],
			});
		}

		if(this.editing) {
			this.locationForm = this.fb.group({
				loccode: [this.editing.loccode, [Validators.required]],
				locationname: [this.editing.orignalname, [Validators.required]],
				deladd1: [this.editing.deladd1, [Validators.required]],
				deladd2: [this.editing.deladd2, []],
				deladd3: [this.editing.deladd3, [Validators.required]],
				deladd4: [this.editing.deladd4, [Validators.required]],
				deladd5: [this.editing.deladd5, [Validators.required]],
				deladd6: [this.editing.deladd6, [Validators.required]],
				tel: [this.editing.tel, []],
				email: [this.editing.email, []],
				contact: [this.editing.contact, [Validators.required]],
				fax: [this.editing.fax, []],
				receipt_message:[this.editing.receipt_message, []],
				taxgroupid:[this.editing.taxgroupid,  [Validators.required]],
				taxgroupid_cert:[this.editing.taxgroupid_cert,  [Validators.required]],
			});
			//	this.receipt_message_middleman.setValue(this.editing.receipt_message);
			this.locationForm.get('loccode').disable();
		}

		// this.receipt_message_middleman.valueChanges.subscribe(newValue =>{
		// 	this.locationForm.get('receipt_message').setValue(newValue);
		// });
	}

	updateZones(value: any) {
		const thisid = this.zones.countries.filter(r => r.name == value.value)[0];
		this.globalsService.getCountryZones(thisid.country_id).subscribe((details:any) => {
			this.zones.zones = details.zone;
		});
	}

	updateLocation() {
		if (this.locationForm.valid) {

			const encoded= this.globalSearchService.base64encode(this.locationForm.get('receipt_message').value);
			this.locationForm.get('receipt_message').setValue(encoded)
			this.locationForm.get('loccode').enable();
			this.globalsService.updateLocation(this.locationForm.value).subscribe(details => {
				//set global
				this.globalsService.getLocations().subscribe(r => {
					this.globalSearchService.locations.next(r);
				})

				this.locationForm.get('loccode').disable();
				this.complete.emit(true);
			});
		}
	}

	cancel() {
		this.complete.emit(true);
	}

	back(): void {
		this.location.back()
	}

	ngAfterViewInit() {

	}
}
