<div class="col-md-12 ">
<div *ngIf="!editing">			
<table class="table table-striped table-hover table-bordered">
											<thead class="thead-dark">
												<tr>
													<th scope="col">Information Title</th>
													<th scope="col"><button mat-icon-button color="primary" (click)="newinfo()">
															<mat-icon>add</mat-icon> Add
														</button></th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let s of information">
													<td>{{ s.title }}</td>
													<td class="text-center">
														<button mat-icon-button color="primary" (click)="editInfo(s)">
															<mat-icon>edit</mat-icon> Edit
														</button>
														&nbsp;&nbsp;
														<button mat-icon-button color="warn" (click)="deleteInfo(s)">
															<mat-icon>delete</mat-icon> Remove
														</button>
													</td>
												</tr>
											</tbody>
										</table>			
</div>
<div *ngIf="editing">
						<div class="col-md-12 pull-right">
									<button mat-stroked-button color="warn" (click)="cancelEdit()">
															<mat-icon>undo</mat-icon> Cancel
														</button>
									<button mat-stroked-button color="primary" (click)="saveEdit()">
															<mat-icon>save</mat-icon> Save
														</button>
						</div>
						<br />
						<form [formGroup]="editFormGroup">
								<div class="col-md-12">
									<mat-form-field appearance="outline">
										<mat-label>Title</mat-label>
										<input type="text" formControlName="title" matInput placeholder="Title" />
									</mat-form-field>
														
								</div>
								<div class="col-md-12">
									<mat-form-field appearance="outline">
										<mat-label>Meta Title</mat-label>
										<input type="text" formControlName="meta_title" matInput placeholder="META TITLE" />
									</mat-form-field>
														
								</div>
								<div class="col-md-12">
									<mat-form-field appearance="outline">
										<mat-label>Meta Description</mat-label>
										<input type="text" formControlName="meta_description" matInput placeholder="METS DESC" />
									</mat-form-field>
														
								</div>
								<div class="col-md-12">
									<mat-form-field appearance="outline">
										<mat-label>Meta Keywords</mat-label>
										<input type="text" formControlName="meta_keyword" matInput placeholder="META KEYWORD" />
									</mat-form-field>
														
								</div>
								<div class="col-md-12">
									Description
									<angular-editor formControlName="description" [config]="editorConfig"></angular-editor>
								</div>
								<hr>
								<div class="col-md-12">
									<mat-form-field appearance="outline">
										<mat-label>Status</mat-label>
										<mat-select formControlName="status">
										<mat-option value="0">
										Disabled
										</mat-option>
										<mat-option value="1">
										Enabled
										</mat-option>
										</mat-select>
									</mat-form-field>
														
								</div>
								<div class="col-md-12">
									<mat-form-field appearance="outline">
										<mat-label>Display On Bottom</mat-label>
										<mat-select formControlName="bottom">
										<mat-option value="0">
										No
										</mat-option>
										<mat-option value="1">
										Yes
										</mat-option>
										</mat-select>
									</mat-form-field>
														
								</div>
								<div class="col-md-12">
									<mat-form-field appearance="outline">
										<mat-label>Sort Order</mat-label>
										<input matInput type="text" formControlName="sort_order" placeholder="0">
									</mat-form-field>
														
								</div>
								<div class="col-md-12">
									<mat-form-field appearance="outline">
										<mat-label>Stores</mat-label>
										<mat-select formControlName="stores" multiple appSelectAll>
										<mat-option *ngFor="let s of storeOptions;" value="{{s.store_id}}">
										{{s.name}}
										</mat-option>
										</mat-select>
									</mat-form-field>
														
								</div>
								<div class="col-md-12">
									<mat-form-field appearance="outline">
										<mat-label>Layout Override</mat-label>
										<mat-select formControlName="Layout">
										<mat-option value="">
										&nbsp;
										</mat-option>
										<mat-option *ngFor="let s of layouts;" value="{{s.layout_id}}">
										{{s.name}}
										</mat-option>
										</mat-select>
									</mat-form-field>
														
								</div>
						</form>
</div>
</div>

