<div class="col-12">
    <div class="row w-100 p-0 m-0">
        <mat-form-field appearance="outline" class="flex-a">
            <mat-label>Status</mat-label>
            <mat-select [formControl]="statusRef">
                <mat-option *ngFor="let opt of statuses" [value]="opt.value">
                    {{opt.view}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <button mat-flat-button (click)="getBins()" class="refresh"><mat-icon>refresh</mat-icon></button>
    </div>
</div>

<table *ngIf="!loadingData" class="table">
    <thead>
        <tr>
            <th>ID</th>
            <th>Customer</th>
            <th>Desc.</th>
            <th>Parcels</th>
            <th>Bin</th>
            <th>Date Created</th>
            <th>Last Updated</th>
            <th>Action</th>
        </tr>
    </thead>
    <ng-container *ngFor="let row of tableData">
        <tr *ngIf="row.pickedup == statusRef.value" class="tablerow">
            <td>{{row.storage_id}}</td>
            <td>{{row.customer}}</td>
            <td>{{row.description}}</td>
            <td>{{row.parcels}}</td>
            <td>{{row.bin}}</td>
            <td>{{row.date_created | date: 'MM/d/yy'}}</td>
            <td>{{row.last_update | date: 'MM/d/yy'}}</td>
            <td (click)="changeStatus(row)" class="cursor">
                <button mat-stroked-button class="action">
                    <ng-container *ngIf="row.pickedup == 0">Mark Picked Up<mat-icon>open_in_browser</mat-icon></ng-container>
                    <ng-container *ngIf="row.pickedup == 1">Mark In Storage<mat-icon>assignment_late</mat-icon></ng-container>
                </button>
            </td>
        </tr>
    </ng-container>
</table>