import { Component, SimpleChanges, OnChanges, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, EventEmitter, Pipe, PipeTransform } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors, FormControl, FormGroup, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { CustomerService } from '../../../services/customer.service';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { UsersService } from '../../services/users.service';
import { DispatchService } from '../../services/dispatch.service';
import { OmsService } from '../../services/oms.service';
import { CouponService } from 'app/services/coupon.service';
import { MatTableDataSource } from '@angular/material/table';


@Component({
	selector: 'app-coupons',
	templateUrl: './coupons.component.html',
	styleUrls: ['./coupons.component.scss']
})
export class CouponsComponent implements OnInit {
	@Input() customer: any = false;
	@Output() addCoupon = new EventEmitter < any > ();

	@ViewChild('couponlistform') couponlistformRef: ElementRef;
	@ViewChild('couponform') addcouponformRef: ElementRef;
	@ViewChild('editcouponform') editcouponformRef: ElementRef;
	couponForm: FormGroup;
	editcouponForm: FormGroup;
	couponCategoryInput: string;
	couponProductInput: string;

	defaultAddCoupon: any;
	allCoupons: any;
	categories: Object;
	catcontrols: any[] = [];
	prodsReady = false;
	prodSearch: { datasource: any; displayedColumns: string[]; };
	submitting = false;

	constructor(private dispatchService: DispatchService, private inventoryService: InventoryService, private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, public omsService: OmsService, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal, public usersService: UsersService, private couponService: CouponService) {}

	ngOnInit(): void {
		this.getCategories();

		this.couponForm = this.fb.group({
			name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(120)]),
			code: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
			type: new FormControl('Percent'),
			coupon_stockid: new FormControl(''),
			discount: new FormControl('0', [Validators.required, Validators.minLength(1)]),
			total: new FormControl('1'),
			logged: new FormControl('Enabled'),
			shipping: new FormControl('Enabled'),
			date_start: new FormControl(this.getTimeFormat()),
			date_end: new FormControl(this.getTimeFormat()),
			uses_total: new FormControl('1', [Validators.required, Validators.minLength(1)]),
			uses_customer: new FormControl('0', [Validators.required, Validators.minLength(1)]),
			status: new FormControl('Enabled'),
			taxable: new FormControl('Disabled'),
			stackable: new FormControl('Disabled'),
			coupon_product: new FormArray([]),
			coupon_category: new FormArray([]),
			top: new FormControl('Disabled'), //?, required in DB insertion
			toptext: new FormControl(''), //?, required in DB insertion
		})

		this.defaultAddCoupon = Object.assign({}, this.couponForm.value);

		this.editcouponForm = this.fb.group({
			coupon_id: new FormControl(''),
			name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(120)]),
			code: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
			type: new FormControl('Percent'),
			coupon_stockid: new FormControl(''),
			discount: new FormControl('', [Validators.required, Validators.minLength(1)]),
			total: new FormControl('1'),
			logged: new FormControl('Enabled'),
			shipping: new FormControl('Enabled'),
			date_added: new FormControl(this.getTimeFormat()),
			date_end: new FormControl(this.getTimeFormat()),
			date_start: new FormControl(this.getTimeFormat()),
			uses_total: new FormControl('1', [Validators.required, Validators.minLength(1)]),
			uses_customer: new FormControl('0', [Validators.required, Validators.minLength(1)]),
			status: new FormControl('Enabled'),
			taxable: new FormControl('Disabled'),
			stackable: new FormControl('Disabled'),
			coupon_product: new FormArray([]),
			coupon_category: new FormArray([]),
			top: new FormControl('Disabled'), //?, required in DB insertion
			toptext: new FormControl(''), //?, required in DB insertion
		})

		this.getAllCoupons();
	}

	getTimeFormat(): string {
		const cTime = new Date();

		const month = (((cTime.getUTCMonth() + 1) < 10) ? '0' : '') + (cTime.getUTCMonth() + 1);
		const day = ((cTime.getUTCDate() < 10) ? '0' : '') + cTime.getUTCDate();
		const year = cTime.getUTCFullYear();

		return year + '/' + month + '/' + day;
	}

	getAllCoupons() {
		(async () => this.couponService.getAllCoupons().subscribe(async (result) => {
			this.allCoupons = result;
		}))();
	}

	deleteCoupon(coupon: Object = {}) {
		if (coupon.hasOwnProperty('coupon_id') && confirm('Would you like to delete this coupon?')) {
			this.couponService.deleteCoupon({ selected: [coupon['coupon_id']] }).subscribe(result => {
				this.getAllCoupons();
				alert(`Coupon with ID ${coupon['coupon_id']} has been deleted`);
			})
		}
	}

	saveCouponListing(form: string, listing: string, item: any) {
		const input = item;

		if (input.length < 1) {
			return;
		}

		this[form].value[listing].push(input);
	}

	removeCouponListing(form: string, listing: string, item: any) {
		if (item.length < 1) {
			return;
		}

		this[form].value[listing] = this[form].value[listing].filter((entry: any) => entry != item)
	}

	addCouponMenu() {
		this.couponForm.setValue(this.defaultAddCoupon);
		this.catcontrols.map((i) => {
			i.control.value ? i.control.setValue(false) : '';
		})
		this.modalService.open(this.addcouponformRef, { ariaLabelledBy: 'model-title', size: 'x1' }).result.then(result => {}, reason => {})
	}

	saveCoupon() {
		if (!this.couponForm.valid) {
			alert('Coupon form is invalid');

			return;
		}

		this.submitting = true;

		//Convert "Enabled" to "1", "Disabled" to "0"
		const convertToBool = (...inputs: string[]): void => {
			for (let i = 0; i < inputs.length; ++i) {
				form[inputs[i]] = (form[inputs[i]] == 'Enabled') ? '1' : '0';
			}
		}

		const form = Object.assign({}, this.couponForm.value);
		form['coupon_category'] = this.catcontrols.filter((i) => i.control.value == true).map((i) => i.id);
		form['type'] = (form['type'] == 'Percent') ? '1' : '0';
		convertToBool('logged', 'shipping', 'status', 'taxable', 'stackable', 'top');


		this.couponService.addCoupon(form).subscribe(result => {
			this.submitting = false;
			if(result['status'] == 'error'){
				alert(result['message']);
			}else{
				this.getAllCoupons();
				this.modalService.dismissAll();
			}
		})
	}

	saveEditCoupon() {

		if (!this.editcouponForm.valid) {
			alert('Coupon form is invalid');

			return;
		}

		this.submitting = true;


		//Convert "Enabled" to "1", "Disabled" to "0"
		const convertToBool = (...inputs: string[]): void => {
			for (let i = 0; i < inputs.length; ++i) {
				form[inputs[i]] = (form[inputs[i]] == 'Enabled') ? '1' : '0';
			}
		}

		const form = Object.assign({}, this.editcouponForm.value);

		form['coupon_category'] = this.catcontrols.filter((i) => i.control.value == true).map((i) => i.id);
		form['type'] = (form['type'] == 'Percent') ? '1' : '0';

		convertToBool('logged', 'shipping', 'status', 'taxable', 'stackable', 'top');


		this.couponService.editCoupon(form).subscribe((result) => {
			this.submitting = false;
			if(result['status'] == 'error'){
				alert(result['message']);
			}else{
				this.getAllCoupons();
				this.modalService.dismissAll();
			}
		})
	}

	/**
	 * Insert a coupon into the edit coupon form, and open the edit menu
	 * @param coupon - Coupon to remove
	 */
	editCoupon(coupon: Object = {}) {
		const form = Object.assign({}, coupon);


		const convertFromBool = (...inputs: string[]): void => {
			for (let i = 0; i < inputs.length; ++i) {
				form[inputs[i]] = (form[inputs[i]] == '1') ? 'Enabled' : 'Disabled';
			}
		}

		form['type'] = (form['type'] == '1') ? 'Percent' : 'Amount';
		convertFromBool('logged', 'shipping', 'status', 'taxable', 'stackable', 'top');

		if(form['coupon_category'].length > 0){
			form['coupon_category'].forEach(activeCat => {
				this.catcontrols.map((i) => {
					if(i.id == activeCat.category_id){
						i.control.setValue(true);
					}
				})
			});
		}else{
			this.catcontrols.map((i) => i.control.setValue(false));
		}



		const category: FormArray = new FormArray([...form['coupon_category'].map(item => new FormControl(item['category_id']))]);
		const product: FormArray = new FormArray([...form['coupon_product'].map(item => new FormControl(item['product_id']))]);



		form['coupon_category'] = [];
		form['coupon_product'] = [];

		this.editcouponForm.patchValue(form)
		this.editcouponForm.setControl('coupon_category', category);
		this.editcouponForm.setControl('coupon_product', product);


		this.modalService.open(this.editcouponformRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then(
			result => {},
			reason => {}
		)
	}

	openMenu() {
		this.modalService.open(this.couponlistformRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then(result => {}, reason => {});
	}

	getCategories(){
		this.inventoryService.getItemCategories().subscribe((res) => {
			Object(res).sort((a,b) => (a.categorydescription > b.categorydescription) ? 1 : ((b.categorydescription > a.categorydescription) ? -1 : 0))
			this.categories = res;

			Object.entries(res).forEach(([k,v]) => {
				this.catcontrols.push({ id: v.categoryid, control:new UntypedFormControl(false) });
			})
		});
	}

	productSearch(input: any){
		this.prodsReady = false;
		const data = {
			stockid: input.target.value
		}
		this.inventoryService.itemSearch(data).subscribe((res) => {
			this.prodSearch = {
				datasource: new MatTableDataSource(res),
				displayedColumns: ['stockid']
			}
			this.prodsReady = true;
		})
	}
}
