import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { Location } from '@angular/common'
import { OmsService } from '../../services/oms.service';
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { OrdersService } from '../../services/orders.service';
import { QantelService } from '../../services/qantel.service';


import { OrderTypes, Order } from '../../classes/orders';
import { trigger, state, style, transition, animate } from '@angular/animations';
import * as XLSX from 'xlsx';
import * as jQuery from 'jquery';


@Component({
	selector: 'app-qantel-order-dash',
	templateUrl: './qantel-order-dash.component.html',
	styleUrls: ['./qantel-order-dash.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotateY(179deg)'
			})),
			transition('active => inactive', animate('500ms ease-out')),
			transition('inactive => active', animate('500ms ease-in')),
			transition('inactive => orderlookup', animate('500ms ease-out')),
			transition('orderlookup => inactive', animate('500ms ease-in')),
		])
	]
})
export class QantelOrderDashComponent implements OnInit {
	picking: any = [];
	flip = 'inactive';
	header: any;
	results: any;
	fliptitle: any;
	billable: any;

	picklateone = [];
	picklatetwo = [];
	picklatethree = [];

	notfillableone = [];
	notfillabletwo = [];
	notfillablethree = [];

	allorders;
	allone = [];
	alltwo = [];
	allthree = [];

	returns = [];


	fileName;
	public type: string;
	public error;

	constructor(private qantelService: QantelService,private route: ActivatedRoute, private globalSearchService: GlobalSearchService, private ordersService: OrdersService, private omsService: OmsService, public cdr: ChangeDetectorRef, private location: Location, public router: Router, private formBuilder: UntypedFormBuilder) {}
	notready;
	status;
	carddata;
	data;
	ready;

	ngOnInit(): void {
		this.error = '';

		const localpick = false;

		this.qantelService.getPicking().subscribe((results: OrderTypes) => {
			this.picking = results;
			localStorage.setItem('picking', JSON.stringify(results))
			this.globalSearchService.pickingOrderData.next(results);
		});

		//let localpick = JSON.parse(localStorage.getItem('picking'));
		this.omsService.subscribeToPicks((err, data) => {
			if (data) {
				this.picking = data;
				this.globalSearchService.pickingOrderData.next(data);
				//localStorage.setItem('picking', JSON.stringify(data.body));

			}
		});

		if (localpick) {
			this.picking = localpick
			this.globalSearchService.pickingOrderData.next(localpick);
			this.globalSearchService.pickingOrderData.subscribe((results: OrderTypes) => {
				this.picking = results;
			});

		} else {

			this.globalSearchService.pickingOrderData.subscribe((results: any) => {
				this.picking = results;

				if (this.picking) {

					this.allorders = [...this.picking.notfillable, ...this.picking.fillable, ...this.picking.partialpicked]

					this.allone = this.getFilteredLates(this.allorders, 5, 3)
					this.alltwo = this.getFilteredLates(this.allorders, 7, 5)
					this.allthree = this.getFilteredLates(this.allorders, 30, 7)
					this.billable = this.getFilteredLates(this.picking.billable, 5, 3)

					this.notfillableone = this.getFilteredLates(this.picking.notfillable, 5, 3)
					this.notfillabletwo = this.getFilteredLates(this.picking.notfillable, 7, 5)
					this.notfillablethree = this.getFilteredLates(this.picking.notfillable, 30, 7)

					this.picklateone = this.getFilteredLates(this.picking.fillable, 5, 3)
					this.picklatetwo = this.getFilteredLates(this.picking.fillable, 7, 5)
					this.picklatethree = this.getFilteredLatesForced(this.picking.fillable, 90, 7);
					this.returns = this.getReturns(this.picking.fillable);

				}
			});
		}

		this.route.params.subscribe(params => {
			if (params['type']) {
				this.toggleFlip(params['type'], 'inactive')
			}
		});

	}

	getReturns(datalist) {

		const result = datalist.filter((item: any) => {
			return (item.PICK < 0);

		});

		return result;

	}

	getFilteredLatesForced(datalist, fromdays: number, todays: number) {

		const result = datalist.filter((item: any) => {
			const subject = new Date(item.DATE_ORDERED);
			subject.setDate(subject.getDate());

			const before = new Date('1999-01-01');
			before.setDate(before.getDate());

			const until = new Date();
			until.setDate(until.getDate() - todays);

			return (subject >= before && subject <= until);

		});

		return result;

	}

	getFilteredLates(datalist, fromdays: number, todays: number) {

		const result = datalist.filter((item: any) => {
			const subject = new Date(item.DATE_ORDERED);
			subject.setDate(subject.getDate());

			const before = new Date();
			before.setDate(before.getDate() - fromdays);

			const until = new Date();
			until.setDate(until.getDate() - todays);

			return (subject >= before && subject <= until);

		});

		return result;

	}

	addDays(date: Date, days: number): Date {
		date.setDate(date.getDate() + days);
		return date;
	}

	getHeader() {
		return this.header
	}

	getData() {
		return this.results
	}

	toggleFlip(dataset, setvalue, forcedata = false, list = []) {
		if (setvalue != '') {
			this.flip = setvalue;
		}
		///this is a bad idea. needs fixed

		switch (dataset) {
		case 'na':
			break;
		case 'billable':
			this.fliptitle = 'Needs Invoices';
			this.data = this.picking.billable
			this.type = 'billable';
			break;
		case 'inpick':
			this.fliptitle = 'In Route Builder';
			this.data = this.picking.inpick
			this.type = 'inpick';
			break;
		case 'snoozed':
			this.fliptitle = 'Snoozed';
			this.type = 'snoozed';
			this.data = this.picking.snoozed
			break;
		case 'returns':
			this.fliptitle = 'Return to Bin';
			this.type = 'returns';
			this.data = this.returns
			break;
		case 'notfillablethree':
			this.fliptitle = '7+ Not Fillable Orders';
			this.type = 'notfillablethree';
			this.data = this.notfillablethree;
			break;
		case 'notfillabletwo':
			this.fliptitle = '5-7 Not Fillable Orders';
			this.type = 'notfillabletwo';
			this.data = this.notfillabletwo;
			break;
		case 'notfillableone':
			this.fliptitle = '3+ Not Fillable Orders';
			this.type = 'notfillableone';
			this.data = this.notfillableone;
			break;
		case 'allthree':
			this.fliptitle = 'All 7+ Orders';
			this.type = 'allthree';
			this.data = this.allthree;
			break;
		case 'alltwo':
			this.fliptitle = 'All 5-7 Orders';
			this.type = 'alltwo';
			this.data = this.alltwo;
			break;
		case 'allone':
			this.fliptitle = 'All 3-5 Orders';
			this.type = 'allone';
			this.data = this.allone;
			break;
		case 'late':
			this.fliptitle = '7+ Late Orders';
			this.type = 'late';
			this.data = this.picklatethree;
			break;
		case 'lateone':
			this.fliptitle = '3-5 Orders';
			this.type = 'latetwo';
			this.data = this.picklateone;
			break;
		case 'latetwo':
			this.fliptitle = '5-7 Orders';
			this.type = 'latetwo';
			this.data = this.picklatetwo;
			break;
		case 'partialpicked':
			this.fliptitle = 'Parital Picks';
			this.type = 'partialpicked';
			this.data = this.picking.partialpicked;
			break;
		case 'dispatched':
			this.fliptitle = 'Dispatched';
			this.type = 'dispatched';
			this.data = this.picking.dispatched;
			break;
		case 'picked':
			this.fliptitle = 'Picked Orders';
			this.type = 'picked';
			this.data = this.picking.picked;
			break;
		case 'parital':
			this.fliptitle = 'Partially Fillable Orders';
			this.type = 'parital';
			this.data = this.picking.parital;
			break;
		case 'notfillablecount':
			this.fliptitle = 'Non Fillable Orders';
			this.type = 'notfillablecount';
			this.data = this.picking.notfillable;
			break;
		case 'fillable':
			this.fliptitle = 'Fillable Orders';
			this.type = 'fillable';
			this.data = this.picking.fillable;
			break;
		case 'openorders':
			this.fliptitle = 'Open Orders';
			this.type = 'openorders';
			this.data = [...this.picking.notfillable, ...this.picking.fillable, ...this.picking.partialpicked];
			break;
		default:
			this.fliptitle = dataset;

			if (forcedata) {
				this.data = list;
			} else {
				this.data = this.picking[dataset];
			}

			this.type = 'late';

			break;
		}
		switch (this.flip) {
		case 'inactive':
			this.flip = 'active';
			this.parseData(this.data);
			//this.updateData();
			//this.focusInput();
			break;
		default:

			this.flip = 'inactive';
			break;
		}

	}

	parseData(listin) {
		const newheader = [];
		(Object.keys(Object.values(listin)[0])).forEach((keys: any, vals: any) => {
			if (keys) {
				if (keys != 'sort') {
					newheader.push({ title: keys, data: keys })
				}
			}
		});
		const newvalues = []
		listin.forEach((keys: any, vals: any) => {
			newvalues.push(keys);
		});

		this.header = newheader;
		this.results = newvalues;
	}



	back(): void {
		this.location.back()
	}



	focusInput() {

	}


	exportExcel(): void {
		/* table id is passed over here */
		const element = document.getElementById('excel-table');
		const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

		/* save to file */
		XLSX.writeFile(wb, this.fileName);

	}
}
