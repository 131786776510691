import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { InventoryService } from '../../services/inventory.service';
import { CustomerService } from '../../services/customer.service';
import { Location } from '@angular/common'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as FileSaver from 'file-saver';

@Component({
	selector: 'app-consignment-edit',
	templateUrl: './consignment-edit.component.html',
	styleUrls: ['./consignment-edit.component.scss']
})
export class ConsignmentEditComponent implements OnInit {
	@Input() customerid: any = false;
	@Input() customer: any = false;
	@Output() updateconsignment: EventEmitter < any > = new EventEmitter();

	headercolumns: string[] = [
		'stockid',
		'brname',
		'description',
		'quantity',
		'date_stocked',
		'actions',
	];
	@ViewChild('consignEdit') specEditRef: ElementRef;
	@ViewChild('returnEdit') putawayRef: ElementRef;

	transactions: any = [];
	consignitems: any = [];
	brname: any = '';
	bin_data: any = [];
	items: any = [];
	itembins: any = [];
	debtorno: any = '';
	color: any = 'blue';
	displaydetails: any = [];
	transid: any = '';
	trandata: any = [];
	total_qty = 0;
	max_qty = 0;
	addstockid: any = '';
	putaway: any = [];
	putawayqty = 0;
	putawaybin: any = '';
	carttotals: any;
	dspcost = true;
	filteredOptions: any = [];
	allitems: any = [];
	customerdata: any = [];

	//cart
	price: any = [];
	quantity: any = [];
	discount: any = [];
	notes: any = [];
	imsearch: any = false;
	user: any = false;

	constructor(private route: ActivatedRoute, public customerService: CustomerService, public inventoryService: InventoryService, public globalSearchService: GlobalSearchService, private modalService: NgbModal, private globalsService: GlobalsService) {
		this.globalSearchService.user.subscribe(r => {
			this.user = r;
		});
	}

	ngOnInit(): void {

		this.route.params.subscribe(params => {
			this.debtorno = params['id'];
			this.customerService.getConsignment({ debtorno: this.debtorno }).subscribe((results: any[]) => {
				this.consignitems = results;
			});
			this.customerService.getDebtor(this.debtorno).subscribe((results: any[]) => {
				this.customerdata = results;

			});
		});
		//this.inventoryService.getAllItems().subscribe((results: any) => {
		//	this.allitems = results;
		//	this.filteredOptions = [];
		//});
	}

	setItemLocation(event:any) {
		this.customer.branchcode = event.value
	}

	ngAfterViewInit() {

		this.inventoryService.getBins().subscribe((bins: any) => {
			this.bin_data = bins;
		});
	}
	autocompleteFilter() {
		if(this.addstockid !='') {
			if(this.imsearch) {
				this.imsearch.unsubscribe()
			}

			const data = {
				stockid: this.addstockid
			};

			this.imsearch = this.inventoryService.itemSearch(data).subscribe(results => {

				const no_zero = results.filter( r => {
					return parseFloat(r.qty) > 0
				})
				//this.itembins = no_zero;

				this.allitems = no_zero;
				this.filteredOptions = no_zero;
			});
		}
	}

	addConsignmentItem() {
		this.addstockid = '';
		this.itembins = [];
		this.autocompleteFilter();
		this.modalService.open(this.specEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}

	moveConsignmentItem() {
		// /this.user.user.defaultlocation.loccode
		const data = { debtorno: this.debtorno, stockid: this.addstockid, branchcode:this.customer.branchcode, bins: this.itembins, loccode: this.customer.customer.defaultlocation };

		const totalmovements = this.itembins.reduce(function(accumulator, c) {

			return accumulator + parseFloat(c.quantityreq);
		}, 0);

		if(totalmovements != 0) {
			this.customerService.saveConsignment(data).subscribe((results: any) => {
				this.consignitems = results;
				this.updateconsignment.emit(true);
				this.modalService.dismissAll();
			});
		} else {
			this.globalSearchService.showNotification('No Qty To Move','danger','bottom','left')
		}
	}

	removeConsignmentItem() {
		const data = { debtorno: this.debtorno, branchcode:this.customer.branchcode, stockid: this.addstockid, bins: this.putaway, loccode: this.customer.customer.defaultlocation };
		this.customerService.removeConsignment(data).subscribe((results: any) => {
			this.consignitems = results;
			this.updateconsignment.emit(true);
			this.modalService.dismissAll();
		});
	}

	removeConsignItem(stockid, max) {
		this.max_qty = max;
		this.total_qty = 0;
		this.addstockid = stockid;
		this.putawaybin = '';
		this.putawayqty = 0;
		this.putaway = [];
		this.modalService.open(this.putawayRef, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {

		}, (reason) => {

		});
	}

	assignConsignmentItem() {
		if (this.putawayqty > this.max_qty) {
			this.putawayqty = this.max_qty;
		}
		this.max_qty = this.max_qty - this.putawayqty;
		const bindata = { bin: this.putawaybin, qty: this.putawayqty };
		this.putaway.push(bindata);
		this.total_qty = this.total_qty + this.putawayqty;
	}

	getItemBins(stockid) {

		this.inventoryService.getItemBins({ stockid: stockid, loccode: this.customer.customer.defaultlocation }).subscribe((results: any) => {
			this.itembins = results;
		});
	}

	filterItem(input: any, term: any, keys: any) {
		const result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));
		return result
	}
}
