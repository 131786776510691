import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, FormControl, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { Location } from '@angular/common'
import { interval, Subscription, Observable } from 'rxjs';

import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { ReportsService } from '../../services/reports.service';
import { PaymentsService } from '../../services/payments.service';
import { OrdersService } from '../../services/orders.service';
import { PrintService } from '../../services/print.service'
import { UsersService } from '../../services/users.service'
import * as FileSaver from 'file-saver';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
	selector: 'app-dri',
	templateUrl: './dri.component.html',
	styleUrls: ['./dri.component.scss']
})
export class DriComponent implements OnInit {

	@ViewChild('print_table') printtable: ElementRef;
	@ViewChild('voidDetails') voidDetailsRef: ElementRef;
	@ViewChild('details') detailsRef: ElementRef;

	color: any = 'blue';
	running: any = false;
	filename = 'dri';
	title = 'DRI - Register Inquiry';
	payments: any = [];
	totals: any = [];
	today = new Date();
	datefrom = new UntypedFormControl(this.today);
	branch: any = new UntypedFormControl('');
	loccode: any = '';
	branches: any = [];
	total_trans: any = 0;
	config: any = [];
	total_pay: any = 0;
	user: any = [];
	displaydetails: any = false;


	constructor(private ordersService: OrdersService, private usersService: UsersService, private _changeDetectorRef: ChangeDetectorRef, private location: Location, private reportsService: ReportsService, private printService: PrintService, private globalSearchService: GlobalSearchService, private globalsService: GlobalsService, private paymentsService: PaymentsService, private fb: UntypedFormBuilder, private route: ActivatedRoute, public router: Router, private modalService: NgbModal) {

		this.color = this.globalSearchService.getColor();

	}

	ngOnInit(): void {

		this.globalSearchService.user.subscribe(result => {
			this.user = result;
			this.branch.value = this.user.user.defaultlocation.loccode;
		});
		this.reportsService.getBranches().subscribe((results: any) => {
			this.branches = results;
		});
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});
	}

	loadData() {
		this.running = true;
		const data = { from: this.datefrom.value, to: this.datefrom.value, branch: this.branch.value }
		this.paymentsService.getDRI(data).subscribe((results: any) => {
			this.running = false;
			this.payments = results;
			this.totals = results.totals;
			this.total_trans = results.transactions.reduce(function(accumulator: number, items: any) {
				return accumulator + parseFloat(items.total);
			}, 0);

			this.total_pay = results.summary.reduce(function(accumulator: number, items: any) {
				return accumulator + parseFloat(items.amount);
			}, 0);

		})
	}

	back(): void {
		this.location.back();
	}

	exportPdf() {

		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}
	exportXls() {
		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

	openDocument(transaction: any, display: string) {

		switch (transaction.type) {
			case '11':
				this.globalsService.getCreditNote(transaction.id, display).subscribe((result: any) => {
					this.displaydetails = result;
					this.modalService.open(this.detailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((mresult) => {}, (reason) => {});
				})
				break;
			case '10':
				this.globalsService.getInvoice(transaction.id, display).subscribe((result: any) => {
					this.displaydetails = result;
					this.modalService.open(this.detailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((mresult) => {}, (reason) => {});
				})

				break;
		}
	}

	updateDayPayMents(event: any) {
		this.today = event;
	}
}