<ng-template #details let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">{{ displaydetails.title }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="displaydetails">
			<h4></h4>
			<ng-container *ngIf="displaydetails.type == 'html'">
				<div [innerHTML]="displaydetails.display">
				</div>
			</ng-container>
			<ng-container *ngIf="displaydetails.type == 'pdf'">
				<ngx-extended-pdf-viewer [base64Src]="displaydetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true" [filenameForDownload]="displaydetails.title"></ngx-extended-pdf-viewer>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>


<ng-template #orderView let-modal>
	<div class="modal-header">
		<div class="modal-title row m-0 p-0" id="modal-title">
			<h4>Orders for <b>{{modal_ref.stockid}}</b> | <span class="font-weight-lighter ">{{modal_ref.description}}</span></h4>
		</div>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="orderdetails">
			<div class="table">
				<table class="table table-sm w-100">
					<tr>
						<th class="text-center">Order #</th>
                        <th class="text-center">Location</th>
                        <th class="text-center">Date</th>
						<th class="text-center">Customer</th>
                        <th class="text-left">Name</th>
                        <th class="text-center">Salesman ID</th>
                        <th class="text-left">Salesman</th>
						<th class="text-center">QTY</th>
						<th class="text-right">Price</th>
                        <th class="text-right">Discount</th>
                        <th class="text-right">EXT</th>
					</tr>
					<tr *ngFor="let ord of orderdetails">
                        <td class="text-left"><button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More" class="donotprint">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #menu="matMenu">
								<button mat-menu-item (click)="openDocument(ord,details,'html')">
									<mat-icon>visibility</mat-icon>
									<span>View HTML</span>
								</button>
								<button mat-menu-item (click)="openDocument(ord,details,'pdf')">
									<mat-icon>visibility</mat-icon>
									<span>View PDF</span>
								</button>

							</mat-menu>{{ord.orderno}}
						</td>
                        <td class="text-center">{{ord.fromstkloc}}</td>
                        <td class="text-center">{{ord.trandate}}</td>
						<td class="text-center">{{ord.debtorno}}</td>
                        <td class="text-left "> {{ord.brname}}</td>
                        <td class="text-center ">{{ord.salesperson}}</td>
                        <td class="text-left ">{{ord.salesmanname}}</td>
						<td class="text-center">{{ord.qty}}</td>
						<td class="text-right">{{ord.price | currency}}</td>
						<td class="text-right">{{ord.discount | percent}}</td>
						<td class="text-right">{{ord.EXT | currency}}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>




<!-- lookup -->
<div class="main-content">
<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-header card-header-{{color}}-6">
					<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i>  Customer Product Line Sales History Report</h4>
					<p class="card-category text-right"></p>
				</div>
				<div class="card-body">
	<div class="m-auto main-width">
        <div class="row d-flex justify-content-between dotted-divider" *ngIf="!customer">
           <h4 class="padding-t-25">Select a customer</h4>
        </div>
        <ng-container *ngIf="!customer || displayLookup">
            <app-customer-lookup (customer_selected)="selectCustomer($event)" [lookupOnly]="true" [ngClass]="customer ? 'mt-10 dblock' : 'dblock'"></app-customer-lookup>
        </ng-container>
    </div>


<!-- report filters -->
<div class="row m-0 p-0" *ngIf="customer">
		<div class="col-md-3 mt-3">
			<mat-form-field appearance="outline">
				<mat-label>Transaction Dates</mat-label>
				<mat-date-range-input [rangePicker]="picker">
					<input matStartDate [formControl]="fromdateCtrl" placeholder="Start date">
					<input matEndDate [formControl]="todateCtrl" placeholder="End date">
				</mat-date-range-input>
				<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-date-range-picker #picker></mat-date-range-picker>
			</mat-form-field>
		</div>
		<div class="col-md-2 mt-3" [formGroup]="lineForm">
			<!-- <mat-form-field appearance="outline" >
				<mat-label>Product Line</mat-label>
				<mat-select formControlName="prodlineRef">
					<mat-option *ngFor="let k of prodlines" [value]="k.line_field">({{k['line_field']}})&nbsp;{{k['line_description']}}</mat-option>
				</mat-select>
			</mat-form-field> -->
			<mat-form-field appearance="outline" >
				<mat-label>Brand</mat-label>
				<mat-select formControlName="prodlineRef" (selectionChange)="updateBrand($event)" multiple>
					<mat-option *ngFor="let k of brands" [value]="k.manufacturer_id">{{k['name']}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<!-- <div class="col-md-2 mt-3">
			<mat-form-field appearance="outline">
				<mat-label>Consolidated or Branch?</mat-label>
				<mat-select [formControl]="conBrRef">
					<mat-option *ngFor="let v of conbr" [value]="v.value">
						{{v.label}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div> -->
		<div class="col-md-7 mt-3 dflex pt-2 justify-content-end ">
			<span *ngIf="sending">
				<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
					<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
				</svg>
			</span>
			<button mat-raised-button class="btn-success btn-height" (click)="$event.preventDefault();exportXls()" *ngIf="reportData">
				<i class="fa fa-file-excel-o"></i> XLS
			</button>
			&nbsp;
			<button mat-raised-button class="btn-danger btn-height" (click)="$event.preventDefault();exportPdf()" *ngIf="reportData">
				<i class="fa fa-file-pdf-o"></i> PDF
			</button>
			&nbsp;
			<button mat-raised-button color="primary" class="btn-height" (click)="showCustomerLookup()"><mat-icon>person</mat-icon>{{customer.debtorno}}&nbsp;{{customer.name}}</button>
			&nbsp;
			<button mat-raised-button color="accent" class=" btn-height" (click)="getCPDET()" *ngIf="!sending" [disabled]="!lineForm.valid">
				<mat-icon>refresh</mat-icon>Run
			</button>
		</div>
	</div>


    <!-- table -->
    <div class="pl-5 pr-5 w-100 m-auto ">

    <ng-container *ngIf="reportData && customer">
            <div>
                <br>
                <h3><span class="font-weight-bolder ">{{reportCriteria.prodline}}</span> <span class="font-weight-light "> Brand Sales for </span> <span class="font-weight-bold ">{{customer.name}} (<a href="#/customers/view/{{customer.debtorno}}">{{customer.debtorno}}</a>) </span> <span class="font-weight-light "> {{reportCriteria.fromdate | date:'MM/d/yy'}} - {{reportCriteria.todate | date:'MM/d/yy'}}</span></h3>
            </div>
			<hr>
			<div #printTable class="w-100 p-0 m-0" *ngIf="reportData.reportdata.length">
				<table class="table table-fixed table-hover w-100" *ngIf="brandnames.length > 1">
					<thead>
						<tr> <th class="">Brand Selected</th> </tr>
					</thead>
					<ng-container *ngFor="let r of brandnames">
						<tr> <td>{{r}}</td> </tr>
					</ng-container>
				</table>
				<div class="table">
					<table class="table table-fixed table-hover w-100">
						<thead>
                            <tr>
                                <th class="">Times Ordered</th>
                                <th>Last Sold</th>
                                <th>Brand</th>
								<th>Line</th>
                                <th>Stock ID</th>
                                <th>Description</th>
                                <th class="text-center ">Qty</th>
                                <th class="text-right ">Price</th>
                                <th class="text-right">Discount</th>
                                <th class="text-right ">Sales EXT.</th>
                            </tr>
                        </thead>
							<ng-container *ngFor="let r of reportData.reportdata">
								<tr>
									<td>{{ r['Times Ordered'] }}</td>
                                    <td>{{r.lastsold}}</td>
									<td>{{ r.brand }}</td>
									<td>{{r.lineid}}</td>
									<td><a (click)="openDrilldown(r)" class="link">{{ r.stockid }}</a></td>
									<td> {{ r.description }} </td>
									<td class="text-center ">{{ r['QTY Ordered'] }}</td>
									<td class="text-right ">{{ r.price | currency }}</td>
                                    <td class="text-right ">{{ r.discount | percent }}</td>
									<td class="text-right "> {{ r.EXT | currency }} </td>
								</tr>
							</ng-container>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th class="text-center ">Total Qty: {{reportData.total_qty}}</th>
                                <th></th>
                                <th></th>
                                <th class="text-right ">Total Sales: {{reportData.total_sales | currency}}</th>
                            </tr>
					</table>
				</div>
			</div>
            <div *ngIf="reportData.reportdata.length == 0">
                <h4 class="text-center ">No history</h4>
            </div>
		</ng-container>
        </div>


</div>
			</div>
		</div>
	</div>
</div>
</div>
