import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, Pipe, PipeTransform, EventEmitter , ViewEncapsulation} from '@angular/core';

import { FiltersService } from '../../services/filters.service';
import { OrdersService } from '../../services/orders.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

@Component({
	selector: 'app-item-filters',
	templateUrl: './item-filters.component.html',
	styleUrls: ['./item-filters.component.scss'],
})
export class ItemFiltersComponent implements OnInit {
	@Input() items;
	@Input() keywords;
	@Input() limit;
	@Input() customer;
	@Output() itemupdate = new EventEmitter < any > ();
	@Output() filtersSelected = new EventEmitter < any > ();
	@Output() clearAll = new EventEmitter < any > ();

	filters: any[];
	inventorysearching: any = false;
	search_items: any = [];
	selected_filters = [];
	filtered_items: any;
	selected_checks = [];
	issalesman = false;
	filteredOptions: any = [];
	constructor(private globalSearchService: GlobalSearchService, private filtersService: FiltersService, private ordersService: OrdersService) {
		this.filtered_items = this.items;
		this.globalSearchService.user.subscribe(u => {

			if (u.user.issalesman) {
				this.issalesman = true;
			}
		});
	}

	ngOnInit(): void {
		this.filtersService.getFilters().subscribe((results: any) => { this.filters = results; });
		this.filtered_items = this.items;
	}

	applyFilters() {
		this.itemFilter();
	}

	updateChecks(event: any, filter_id: any) {

		if (!this.selected_checks[filter_id]) {
			this.selected_checks[filter_id] = [];
		}

		const data = { value: event.source.value };

		if (event.checked) {
			this.selected_checks[filter_id].push(data)
		} else {

			const index = this.selected_checks[filter_id].indexOf(data);


			this.selected_checks[filter_id] = this.selected_checks[filter_id].filter((selected, index) => {
				return selected.value != event.source.value
			});
		}
		//prevent empty send
		if(this.selected_checks[filter_id].length) {
			this.selected_filters[filter_id] = { filter_id: filter_id, value: this.selected_checks[filter_id] };
		} else {
			this.selected_filters[filter_id] = {};
		}


	}

	searchOptions(options: any[], searchTerm: string) {
		const lowerCaseTerm = searchTerm.toLowerCase();
		this.filteredOptions = options.filter(option =>
		  option.label.toLowerCase().includes(lowerCaseTerm)
		);
	  }


	updateSearchSelect(event: any, filter_id: any) {
		this.filtered_items = this.items;
		if(event.value != 'reset'){
			this.selected_filters[filter_id] = { filter_id: filter_id, value: event.value };
		} else {
			this.selected_filters[filter_id] = {};
		}

	}

	itemFilter() {
		const data = { keywords: this.keywords, limit: this.limit, customer: this.customer, filters: this.selected_filters };
		this.itemupdate.emit(data)
		this.filtersSelected.emit(this.selected_filters)
	}

	resetFilters(){
		this.filtersService.getFilters().subscribe((results: any) => { this.filters = results; });
		this.selected_checks = [null, {}];
		this.selected_filters = [];

		this.clearAll.emit(this.selected_filters)
	}

}
