<ng-container>
	<form @grow [formGroup]="vendorForm"  (ngSubmit)="onSubmit()"
		class="needs-validation product-add"  >
		<div class="card-header">
			<div class="col-12 text-right">
				<button class="ml-auto mr-0" mat-button
				mat-raised-button [color]="vendorForm.valid ? 'accent': 'warn' "> <i class="material-icons">save</i> Save</button>
			</div>
		</div>
		<div class="row">
				<div class="col-md-12">
					<mat-form-field appearance="outline">
						 <mat-label>Name</mat-label>
						 <input matInput required value="" formControlName="suppname">
					 </mat-form-field>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
				<mat-form-field appearance="outline">
					 <mat-label>Address 1</mat-label>
					 <input matInput required value="" formControlName="address1" >
				 </mat-form-field>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
				<mat-form-field appearance="outline">
					 <mat-label>Address 2</mat-label>
					 <input matInput value="" formControlName="address2">
				 </mat-form-field>
				</div>
			</div>
			<div class="row">
				<div class="col-md-4">
				<mat-form-field appearance="outline">
				 <mat-label>City</mat-label>
				 <input matInput required value="" formControlName="address3">
				 </mat-form-field>
			</div>
			<div class="col-md-4">
				<mat-form-field appearance="outline" class="">
					<mat-label>Region</mat-label>
					<mat-select formControlName="address4">
					<mat-option *ngFor="let zone of zones.zones"
					[value]="zone.code">
					{{zone.code }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-4">
				<mat-form-field appearance="outline">
				 <mat-label>Postal</mat-label>
				 <input matInput required value="" formControlName="address5">
				 </mat-form-field>
			</div>
			<div class="col-md-12">
				<mat-form-field appearance="outline" class="">
					<mat-label>Country</mat-label>
					<mat-select formControlName="address6" >
					<mat-option *ngFor="let zone of zones.countries"
					[value]="zone.name">
					{{ zone.name }} </mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
	</form>
</ng-container>
