import { Component, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent {
  formGroup: FormGroup;
  customizationForm: FormGroup;
  fields = [
	{ label: 'Text Field', type: 'text', name: 'textField' },
	{ label: 'Number Field', type: 'number', name: 'numberField' },
	{ label: 'Textarea', type: 'textarea', name: 'textareaField' },
	{ label: 'Checkbox', type: 'checkbox', name: 'checkboxField' },
	{ label: 'Radio Button', type: 'radio', name: 'radioField' },
	{ label: 'Dropdown', type: 'select', name: 'selectField', options: ['Option 1', 'Option 2', 'Option 3'] },
	{ label: 'Paragraph', type: 'paragraph', name: 'paragraph' },
	{ label: 'Image', type: 'image', name: 'image' }
  ];
  formControls = [];
  currentControl: any;
  color = 'blue';
  constructor(private fb: FormBuilder, private http: HttpClient, private modalService: NgbModal) {
	this.formGroup = this.fb.group({});
	this.customizationForm = this.fb.group({
	  label: [''],
	  placeholder: [''],
	  required: [false]
	});
  }

  onDrop(event: CdkDragDrop<any[]>) {
	if (event.previousContainer !== event.container) {
	  const field = event.previousContainer.data[event.previousIndex];
	  const controlCopy = { ...field, name: `${field.name}_${this.formControls.length}` };
	  this.formControls.push(controlCopy);
	  this.formGroup.addControl(controlCopy.name, new FormControl('', this.getValidators(controlCopy)));
	}
  }

  removeField(index: number) {
	const field = this.formControls[index];
	this.formGroup.removeControl(field.name);
	this.formControls.splice(index, 1);
  }

  getValidators(field: any) {
	const validators = [];
	if (field.required) {
	  validators.push(Validators.required);
	}
	if (field.type === 'number') {
	  validators.push(Validators.pattern(/^-?\d+$/));
	}
	return validators;
  }

  openCustomizer(field: any, customizer: TemplateRef<any>) {
	this.currentControl = field;
	this.customizationForm.patchValue({
	  label: field.label,
	  placeholder: field.placeholder,
	  required: field.required
	});
	this.modalService.open(customizer);
  }

  saveCustomization(modal: any) {
	const values = this.customizationForm.value;
	this.currentControl.label = values.label;
	this.currentControl.placeholder = values.placeholder;
	this.currentControl.required = values.required;
	modal.close();
  }

  onSubmit() {
	const formData = {
	  name: 'Dynamic Form',
	  fields: this.formControls
	};

	this.http.post('/path/to/create_form.php', formData)
	  .subscribe(response => {
		console.log(response);
	  }, error => {
		console.error(error);
	  });
  }
}
