<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-danger">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Vendor Items</h4>
						<p class="card-category">Items for Vendor </p>
					</div>
					<div class="card-body">
						<div class="table table-striped table-no-bordered table-hover dataTable dtr-inline ">
							<table datatable [dtOptions]="dtOptions" class="table table-striped table-no-bordered table-hover dataTable dtr-inline " *ngIf="items">
								<thead>
									<tr>
										<th>Item Number</th>
										<th>Description</th>
                                        <th>Vendor Item Number</th>
										<th>Vendor UOM</th>
										<th>Vendor Price</th>
                                        <th>Qty On Hand</th>
                                        <th>Qty Committed</th>
                                        <th>Qty Available</th>
                                        <th>Qty On Order</th>
									</tr>
								</thead>
								<tbody>
									<tr  *ngFor="let item of items; ">
										<td>{{ item.stockid }}</td>
										<td>{{ item.supplierdescription }}</td>
                                        <td>{{ item.suppliers_partno }}</td>
                                        <td>{{ item.suppliersuom }}</td>
										<td>{{ item.price }}</td>
										<td>{{ item.qoh }}</td>
                                        <td>{{ item.commit }}</td>
                                        <td>{{ item.avail }}</td>
                                        <td>{{ item.onorder }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>