<!-- modal -->
<ng-template #detailsModal let-modal>

	<div class="modal-header">
		<h4 *ngIf="toggle.value">Going out MSTs</h4>
		<h4 *ngIf="!toggle.value">Receiving MSTs ({{selected_vendor.supplierid}})</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>


	<div class="modal-body">

		<!-- salesorder msts (send)-->
		<table class="table" *ngIf="toggle.value">
			<thead>
				<tr>
					<th></th>
					<th>PO#</th>
					<th>DESC</th>
					<th>Note</th>
					<th>Deliver to</th>
					<th class="text-center">ORDERED</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let mst of mstlist" style="height: 50px;">
					<td><button mat-raised-button (click)="preloadItem(mst); setSelectedMst(mst)">select</button></td>
					<td>{{ mst.orderno }}</td>
					<td>{{ mst.itemdesc }}</td>
					<td>{{ mst.line_note }}</td>
					<td>{{ mst.deliverto }}</td>
					<td class="text-center">{{ mst.quantity }}</td>
				</tr>
			</tbody>
		</table>

		<!-- purchorder msts (rec) -->
		<table class="table" *ngIf="!toggle.value">
			<thead>
				<tr>
					<th></th>
					<th>PO#</th>
					<th>DESC</th>
					<th>Note</th>
					<th class="text-center">ORDERED</th>
					<th>RECIEVED</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let mst of mstlist" style="height: 50px;">
					<td><button mat-raised-button (click)="preloadItem(mst); setSelectedMst(mst)">select</button></td>
					<td>{{ mst.orderno }}</td>
					<td>{{ mst.itemdescription }}</td>
					<td>{{ mst.line_note }}</td>
					<td class="text-center">{{ mst.quantityord }}</td>
					<td class="text-center">{{ mst.quantityrecd }}</td>
				</tr>
			</tbody>
		</table>

	</div>

	<div class="modal-footer"></div>

</ng-template>


<div class="card">
	<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i> Inventory Receiving </h4>

	<div class="card-body">

		<!-- search -->
		<div class="itemsearchbar">
			<div style="display: flex">
				<mat-slide-toggle [formControl]="toggle" style="margin-top: auto; margin-bottom: auto;">
					<div class="w40" *ngIf="toggle.value">Send</div>
					<div class="w40" *ngIf="!toggle.value">Rec</div>
				</mat-slide-toggle>
				<mat-form-field appearance="outline" class="flexauto">
					<input matInput [formControl]="recitem" #itemrec id="itemrec" value="" class="text-right" placeholder="* Item Search  " (keyup.enter)="$event.stopPropagation();$event.preventDefault();lookupVersionEight();$event.target.blur();">
					<div matSuffix class="text-right">
						<button mat-icon-button type="button" color="white" (click)="$event.stopPropagation();$event.preventDefault();lookupVersionEight()">
							<mat-icon>search</mat-icon>
						</button>
					</div>
				</mat-form-field>
			</div>
		</div>
		<div>
			<div class="flex-row">
				<h4 class="text-center pointer flexauto" (click)="toggleVendors(false)">
					<div class="supplierselected">
						{{ selected_vendor.supplierid }} - {{ selected_vendor.suppname }}
					</div>
				</h4>
				<ng-container *ngIf="!toggle.value"><a mat-button class="mst-button" (click)="openMSTmodal()">MST</a></ng-container>
			</div>
			<mat-button-toggle-group #charge="matButtonToggleGroup" (change)="updateVendor($event)" [ngClass]="active_select == 0 ? 'hide' : 'buttongroup'" aria-label="Charge" color="accent" class="buttongroup" @grow>
				<mat-button-toggle [value]="vendor" [ngClass]="selected_vendor == vendor ? 'text-light btn-success' : 'text-dark btn-light'" *ngFor="let vendor of vendors" class="buttongroup_b" (click)="toggleVendors(true);">{{vendor.bankref}}</mat-button-toggle>
			</mat-button-toggle-group>
		</div>

		<!-- viewing item -->
		<ng-container *ngIf="viewingitem">
			<h4 class="text-center mt-3" *ngIf="vendor_openpos" style="display: none">
				{{ vendor.value.suppname }} PENDING: ({{ vendor_openpos.total_pending}})
			</h4>
			<table class="table table-sm table-fixed fixTableHead" style="display: none">
				<thead>
					<tr>
						<th></th>
						<th>ITEM</th>
						<th>DESC</th>
						<th>PO#</th>
						<th class="text-center">ORDERED</th>
						<th class="text-center">RECIEVED</th>
						<th class="text-center">PENDING</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let d of vendor_openpos.details">
						<td><button mat-raised-button (click)="loadItemByStockID(d.itemcode); ">select</button></td>
						<td>{{ d.itemcode }}</td>
						<td>{{ d.itemdescription }}</td>
						<td>{{ d.orderno }}</td>
						<td class="text-center">{{ d.quantityord }}</td>
						<td class="text-center">{{ d.quantityrecd }}</td>
						<td class="text-center">{{ d.remaining }}</td>
					</tr>
				</tbody>
			</table>
		</ng-container>

		<div>
			<!-- {{itemio | json}} -->
			<ng-container *ngIf="itemio">
				<app-warehouse-scaninout [mstdetails]="mstdetails" [itemdetails]="itemio" [send_toggle]="toggle.value" [vendorpos]="vendor_openpos" [vendorrgas]="vendor_openrgas" [vendor_selected]="selected_vendor.supplierid" (focus_search)="focusSearch($event)" (show_confirm)="showConfirm($event)"></app-warehouse-scaninout>
			</ng-container>
		</div>

		<nav class="navbar fixed-bottom text-dark navbar-dark bg-light border-top justify-content-right mat-elevation-z24" *ngIf="!show_confirm" [ngClass]="itemlist.length > 1 ? 'focus' : 'nofocus'">
			<div class="ml-0 mr-auto nav-item centered">
				<form [formGroup]="secondFormGroup" (keydown.enter)="$event.preventDefault()">
					<ng-template matStepLabel *ngIf="!last_item">Scanning</ng-template>
					<ng-template matStepLabel *ngIf="last_item">Last Scan: <b>{{last_item.item.stockid}}</b></ng-template>
					<ng-template matStepLabel>Scanning</ng-template>


					<!-- multipe search table -->
					<div class="table table-responsive text-center" *ngIf="searching">
						<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</div>
					<div class="table table-responsive multitemtable" *ngIf="itemlist && itemlist.length">
						<table class="table table-sm">
							<thead>
								<tr>
									<th></th>
									<th class="text-left">ITEM</th>
									<th class="text-left">DESC</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let item of itemlist ">
									<td> <a mat-raised-button (click)="preloadItem(item)">Select</a></td>
									<td class="text-left">
										{{ item.stockid }}
									</td>
									<td class="text-left">{{ item.description }}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<ng-container *ngIf="itemlist && !itemlist.length">
						<div class="row">
							<div class="col-md-12 text-center">
								<h5 class="bold">No Results, would you like to add the barcode?
								</h5>
							</div>
							<div class="col-md-12 text-center">
								<button mat-raised-button color="accent" (click)="dspBarcodeAdd($event)" *ngIf="!addingbarcode">
									<mat-icon>add</mat-icon> Add Barcode
								</button>
								<app-qantel-item-barcode-add class="barcodeApp" *ngIf="addingbarcode" [searchedfor]="lastsearch" (barcode_added)="addedBarcode($event)">
								</app-qantel-item-barcode-add>
							</div>
						</div>
					</ng-container>
					<!-- <ng-container *ngIf="itemio">
								<app-warehouse-scaninout [itemdetails]="itemio" [vendorpos]="vendor_openpos" [vendor_selected]="selected_vendor.supplierid" (last_success)="last_success($event)"></app-warehouse-scaninout>
							</ng-container> -->
					<ng-container *ngIf="!viewingitem">
						<h4 class="text-center mt-3">No Item Selected</h4>
					</ng-container>

				</form>
			</div>
		</nav>
	</div>
</div>
