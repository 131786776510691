<ng-container *ngIf="reversalForm">
	<div class="row text-center">
		<div class="col-md-12 text-center">
			Payment Reversal
			<div class="input-group no-border">
				<form [formGroup]="reversalForm" >
					<div class="row">
						<div class="col-md-4">
							<mat-form-field appearance="fill">
								<mat-label>Transaction Date</mat-label>
								<input matInput [matDatepicker]="picker" formControlName="date">
								<mat-hint>MM/DD/YYYY</mat-hint>
								<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
								<mat-datepicker #picker></mat-datepicker>
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field class="full-width" appearance="fill">
								<mat-label>Amount</mat-label>
								<input matInput formControlName="amount"  />
							</mat-form-field>
						</div>
						<div class="col-md-4">
							<mat-form-field class="" appearance="fill">
								<mat-label>Reason</mat-label>
								<input matInput formControlName="reason" matInput placeholder="Reason for reversal">
							</mat-form-field>
						</div>

					</div>
					<div class="row">
						<div class="col-md-12">

							<button mat-raised-button color="accent" (click)="createRefund()">Reverse Payment</button>
							&nbsp;<mat-slide-toggle formControlName="card">Refund Card</mat-slide-toggle>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</ng-container>
