
<div class="main-content">
	<div class="container-fluid">
		<div class="col-md-12">
			<div class="card">
				<div class="card-header card-header-danger">
					<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Dispatches</h4>
					<p class="card-category">Dispatches</p>
				</div>
				<div class="card-body">
					<div class="table table-sm" *ngFor="let truck of trucks | keyvalue">
						 <div class="col-12 col-lg-12 p-0 m-0 border-top border-bottom border-dark" *ngIf="ready && dispatches">
							<div class="row p-0 m-0"  >
								<div class="row p-0 m-0 table track " style="flex-wrap: nowrap;overflow-x:scroll;">
									<div class="col-1 p-0 m-0 bg-dark white pl-2 pt-3">
											{{ truck.value.truck_name }}
									</div>
									<div id="dispatched-{{disp.invoice}}" class="col-auto p-0 m-0 border-left" *ngFor="let disp of dispatches[truck.key]; let i = index;">
										<h5 class="p-0 m-0 text-center bg-primary text-white" [ngClass]="status == 'Delivered' ? 'delivered' : ''">{{ disp.status }}</h5>
										<div [ngClass]="i%2 ? 'bg-dark white' : 'bg-white text-dark'">
											<h5 class="p-0 m-0 text-center ">
												{{disp.invoice}}
											</h5>
											<div class="p-1 m-1" [innerHTML]="disp.address">
												<br>
												<a href="#" class="order-details text-primary" data-id="<?php echo $dispatched['orderno'];?>" data-toggle="modal" data-target="#detailsModal" >#({{ disp.qty }}) @  </a>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row" *ngIf="!dispatches && ready">
						<div class="col-12 col-lg-12 p-0 mmt-2 mb-2  text-center" >
							 <span class="alert alert-warning">No Dispatches</span>
						</div>
					</div>

					<div class="table table-responsive text-center" *ngIf="!ready">
				<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
				</svg>
			</div>
				</div>
			</div>
		</div>
	</div>
</div>
