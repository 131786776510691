<ng-container *ngIf="!customer_creation">
	<div class="row">
		<div class="col-md-6  text-left">
			<button mat-raised-button color="accent" (click)="$event.preventDefault();$event.stopPropagation();addNote();" id="addnote">Add Note</button>
		</div>
		<div class="col-md-6 text-right">
			<button mat-raised-button class="btn btn-primary" (click)="$event.preventDefault();$event.stopPropagation();onSubmit()">Save</button>
		</div>
	</div>

	<ng-container *ngIf="customer">
		<ul @grow class="list-group list-group-flush p-0 m-0">
			<div class="row border-bottom" *ngFor="let note of customer.notes ">
				<div class="col-md-4 mr-0">
					<mat-form-field appearance="outline" class="" >
						<mat-label>Note Type</mat-label>
						<mat-select
						required
						(selectionChange)="updateTopic($event, note)"
						[(value)] ="note.note_code"
						>
						<mat-option *ngFor="let type of notetypes" [value]="type">
							{{type}}
						</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-8  ml-0">
					<mat-form-field class="full-width" appearance="outline">
						<mat-label>Note</mat-label>
						<input matInput required placeholder="Note" name="note[{{note.noteid}}]" value="{{ note.note }}" (input)="updateNote($event, note)" >
						<mat-icon matSuffix (click)="removeNote(note.noteid)" *ngIf="note.noteid > 0 ">remove</mat-icon>
					</mat-form-field>
				</div>
			</div>
		</ul>
	</ng-container>
</ng-container>



<ng-container *ngIf="customer_creation">
	<div class="row">
		<div class="col-md-12  d-flex justify-content-center ">
			<button class="w-100 " mat-stroked-button color="primary" (click)="addNote($event);" id="addnote">Add Note</button>
		</div>

	</div>

	<ng-container *ngIf="customer">
		<ul @grow class="list-group list-group-flush p-0 m-0">
			<div class="row border-bottom" *ngFor="let note of customer.notes ">
				<div class="col-md-4 mr-0">
					<mat-form-field appearance="outline" class="" >
						<mat-label>Note Type</mat-label>
						<mat-select
						required
						(selectionChange)="updateTopic($event, note)"
						[(value)] ="note.note_code"
						>
						<mat-option *ngFor="let type of notetypes" [value]="type">
							{{type}}
						</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-8  ml-0">
					<mat-form-field class="full-width" appearance="outline">
						<mat-label>Note</mat-label>
						<input matInput required placeholder="Note" name="note[{{note.noteid}}]" value="{{ note.note }}" (input)="updateNote($event, note)" >
						<mat-icon matSuffix (click)="removeNote(note.noteid)" *ngIf="note.noteid > 0 || customer_creation">remove</mat-icon>
					</mat-form-field>
				</div>
			</div>
		</ul>
	</ng-container>
</ng-container>
