<ng-template #setupModal let-modal>
	<div class="modal-header">
		<h4 *ngIf="selected_event" class="modal-title" id="modal-title">{{ selectedLoc.location_name }}</h4>
		<h4 *ngIf="!selected_event" class="modal-title" id="modal-title">New Location</h4>
		<button type="button" mat-flat-button color="accent" (click)="saveLocation()"><mat-icon>save</mat-icon> Save Changes </button>&nbsp;
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">		
			<form [formGroup]="locForm">		
			<div class="container-fluid">
			<div class="row eduform">
			<div class="col-12">
			<mat-form-field appearance="outline">
			<mat-label>Name</mat-label>
			<input matInput formControlName="location_name" class="margin-top" autocomplete="off">			
			</mat-form-field>
			</div>
			</div>
			
			<div class="col-12">
			<mat-form-field appearance="outline">
			<mat-label>Description</mat-label>
			<input matInput formControlName="location_description" class="margin-top" autocomplete="off">
			</mat-form-field>
			</div>
			<div class="col-12">
			<mat-form-field appearance="outline">
			<mat-label>Address 1</mat-label>
			<input matInput formControlName="address1" class="margin-top" autocomplete="off">
			</mat-form-field>
			</div>
			<div class="col-12">
			<mat-form-field appearance="outline">
			<mat-label>Address 2</mat-label>
			<input matInput formControlName="address2" class="margin-top" autocomplete="off">
			</mat-form-field>
			</div>
			<div class="col-12">
			<mat-form-field appearance="outline">
			<mat-label>City</mat-label>
			<input matInput formControlName="address3" class="margin-top" autocomplete="off">
			</mat-form-field>
			</div>
			<div class="col-12">
			<mat-form-field appearance="outline">
			<mat-label>State / Province</mat-label>
			<input matInput formControlName="address4" class="margin-top" autocomplete="off">
			</mat-form-field>
			</div>
			<div class="col-12">
			<mat-form-field appearance="outline">
			<mat-label>Postal Code</mat-label>
			<input matInput formControlName="address5" class="margin-top" autocomplete="off">
			</mat-form-field>
			</div>
			<div class="col-12">
			<mat-form-field appearance="outline">
			<mat-label>Phone</mat-label>
			<input matInput formControlName="phone" class="margin-top" autocomplete="off">
			</mat-form-field>
			</div>
			</div>
			</form>
	</div>
</ng-template>
<div class="main-content">
<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-header card-header-{{color}}-6">
					<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Education Locations</h4>
					<p class="card-category text-right"></p>
				</div>
				<div class="card-body">
				<button mat-stroked-button (click)="addLocation()" >
												<mat-icon>add</mat-icon>
												<span>Add</span>
											</button>
				<div class="table-wrapper"  *ngIf="!loading && loclist.length">
									<mat-paginator [pageSizeOptions]="items_per_page" [pageSize]="pagesizedefault"></mat-paginator>
									  <table class="table table-sm table-striped">
										<thead class="thead-dark">
										  <tr>
											<th></th>
											<th >Location Name</th>
											<th >Description</th>
											<th class="text-center">Address</th>
											<th class="text-center">Phone</th>
											<th class="text-center">&nbsp;</th>
										  </tr>
										</thead>
										<tbody>
										<tr class="datarow" *ngFor="let loc of dataObs | async;let i = index">
											<td><button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
											<mat-icon>more_vert</mat-icon>
											</button><mat-menu #menu="matMenu">
											
											<button mat-menu-item (click)="editLocation(loc)" >
												<mat-icon>edit</mat-icon>
												<span>Edit Location</span>
											</button>
											<button mat-menu-item (click)="deleteLocation(loc)">
												<mat-icon>remove</mat-icon>
												<span>Remove Location</span>
											</button>
										</mat-menu></td>
											<td class="text-center ">
												{{ loc.location_name }}.
											</td>
											<td>{{ loc.location_description }}</td>
											<td>{{ loc.location_options.address1 }}<br>{{loc.location_options.address3 }}, {{loc.location_options.address4 }} {{loc.location_options.address5}}</td>
											<td>{{ loc.location_options.phone}}</td>
											
											</tr>
										</tbody>
									  </table>
									</div>
									<div class="table" *ngIf="!loading && !loclist.length">
										<h4 class="text-center"> No Results</h4>
									</div>
				</div>
			</div>
		</div>
	</div>
</div>