<form [formGroup]="itemForm" (ngSubmit)="updateItem()" *ngIf="itemForm">
	<div class="row">
		<div class="col-md-12">
			<div class="card border">
				<div class="card-header card-header-warning card-header-icon">
					<div class="card-icon">
						<i class="material-icons">info</i>
					</div>
					<h4 class="card-title bold">Purchase Info</h4>
					<div class="card-title col-12 text-right">
						<button mat-raised-button color="accent">Next</button>
					</div>
					<div class="card-body text-dark">
						<div class="row">
							<div class="col-md-6">
								<mat-form-field class="full-width text-right" appearance="outline" color="accent">
									<mat-label>Vendor</mat-label>
									<input type="text" placeholder="Vendor Search" aria-label="Vendor" matInput formControlName="supplierno" [matAutocomplete]="auto" (input)="vendorSearch($event.target.value)">
									<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectVendor($event.option.value)">
										<mat-option *ngFor="let vendor of vendors" [value]="vendor.supplierid">
											<b>{{ vendor.supplierid }}</b>-{{ vendor.suppname}}
										</mat-option>
									</mat-autocomplete>
								</mat-form-field>
							</div>
							<div class="col-md-6">
								<mat-form-field appearance="outline" class="">
									<mat-label>Preferred</mat-label>
									<mat-select formControlName="preferred">
										<mat-option *ngFor="let k of properties.preferred" [value]="k.value">
											{{ k.name }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>

							<div class="col-md-4">
								<mat-form-field appearance="outline">
									<mat-label>Item Code</mat-label>
									<input matInput required value="" formControlName="stockid">
								</mat-form-field>
							</div>
							<div class="col-md-4">
								<mat-form-field appearance="outline">
									<mat-label>Description</mat-label>
									<input matInput required value="" formControlName="supplierdescription">
								</mat-form-field>
							</div>
							<div class="col-md-4">
								<mat-form-field appearance="outline">
									<mat-label>Part #</mat-label>
									<input matInput required value="" formControlName="suppliers_partno">
								</mat-form-field>
							</div>
							<div class="col-md-4">
								<mat-form-field appearance="outline" class="">
									<mat-label>Unit of Measure</mat-label>
									<mat-select formControlName="suppliersuom">
										<mat-option *ngFor="let k of properties.uom" [value]="k.value">
											{{ k.name }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col-md-4">
								<mat-form-field appearance="outline">
									<mat-label>Purchase Price</mat-label>
									<input matInput required formControlName="price">
								</mat-form-field>
							</div>
							<div class="col-md-2">
								<mat-form-field appearance="outline">
									<mat-label>Min Order Qty</mat-label>
									<input matInput required formControlName="minorderqty">
								</mat-form-field>
							</div>
							<div class="col-md-2">
								<mat-form-field appearance="outline">
									<mat-label>Max Order Qty</mat-label>
									<input matInput required value="" formControlName="maxorderqty">
								</mat-form-field>
							</div>
						</div>
						<div class="row">

							<div class="col-md-4">
							<mat-form-field appearance="outline">
								<mat-label>Effective From</mat-label>
								<input matInput [matDatepicker]="effectivefrom" formControlName="effectivefrom">
								<mat-datepicker-toggle matSuffix [for]="effectivefrom"></mat-datepicker-toggle>
								<mat-datepicker #effectivefrom></mat-datepicker>
							</mat-form-field>
							</div>
							<div class="col-md-2">
								<mat-form-field appearance="outline">
									<mat-label>Conversion Factor</mat-label>
									<input matInput required value="" formControlName="conversionfactor">
								</mat-form-field>
							</div>
							<div class="col-md-2">
								<mat-form-field appearance="outline">
									<mat-label>Lead Time </mat-label>
									<input matInput required value="" formControlName="leadtime">
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
