import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ValidationErrors, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Location } from '@angular/common'

import { merge, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

declare let $: any;

@Component({
	selector: 'app-bank-accounts',
	templateUrl: './bank-accounts.component.html',
	styleUrls: ['./bank-accounts.component.scss']
})
export class BankAccountsComponent implements OnInit {

	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('locationsearch') customersearch: ElementRef;
	@ViewChild('editAddAccount') editAddRef: ElementRef;

	loccode: any = '';
	locselected = false;
	ld: any = [];
	term: any = '';

	taxgroup: any = '';
	taxes: any = [];
	currencies: any = [];
	bankaccounts: any = [];
	types: any = [];
	zones: any = [];
	accountForm: any = false;
	groups: any = [];
	pandl: any = [];
	accounts: any = [];
	results: any = [];
	headercolumns: string[] = [
		'actions',
		'accountcode',
		'currcode',
		'bankaccountcode',
		'bankaccountname',
		//"bankaccountnumber"
	];

	color = 'blue'

	constructor(private route: ActivatedRoute, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, private fb: UntypedFormBuilder, public globalsService: GlobalsService, private modalService: NgbModal) {

		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
		this.loadData();
	}

	loadData() {
		this.globalsService.getBankAccounts().subscribe((results: any) => {
			this.accounts = new MatTableDataSource(results);
			this.accounts.sort = this.sort;
			this.accounts.paginator = this.paginator;
		});

		this.globalsService.getCurrencies().subscribe((results: any) => {
			this.currencies = results
		});
	}

	updateAccount() {
		if (this.accountForm.valid) {
			this.globalsService.saveBankAccount(this.accountForm.value).subscribe(results => {
				this.modalService.dismissAll()
				this.accounts = new MatTableDataSource(results);
				this.accounts.sort = this.sort;
				this.accounts.paginator = this.paginator;
			});
		} else {
			this.getFormValidationErrors()
		}
	}

	newAccount() {

		this.accountForm = this.fb.group({
			accountcode: ['', [Validators.required]],
			bankaccountcode: ['', [Validators.required]],
			bankaccountname: ['', [Validators.required]],
			currcode: ['USD', [Validators.required]],
			invoice: [1],
			bankaccountnumber: ['', [Validators.required]],
			bankaddress: [''],
			importformat: [''],
			nextchecknum: [''],
		});

		this.modalService.open(this.editAddRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {

		});
	}

	deleteAccount(row: any) {
		this.globalsService.deleteBankAccount(row).subscribe((result: any) => {
			if (result.success) {
				this.loadData();
				this.globalSearchService.showNotification('Account Deleted', 'success', 'bottom', 'center');
			} else {
				this.globalSearchService.showNotification(result.message, 'danger', 'bottom', 'center');
			}
		});
	}

	selectAccount(account: any) {
		//this.router.navigate(['./system/bank-accounts/' + row.accountcode]);
		this.globalsService.getBankAccount(account).subscribe((results: any) => {

			this.ld = account;
			this.accountForm = this.fb.group({
				accountcode: [results.accountcode, [Validators.required]],
				bankaccountcode: [results.bankaccountcode, [Validators.required]],
				bankaccountname: [results.bankaccountname, [Validators.required]],
				currcode: [results.currcode, [Validators.required]],
				invoice: [results.invoice, ],
				bankaccountnumber: [results.bankaccountnumber, [Validators.required]],
				bankaddress: [results.bankaddress, ],
				importformat: [results.importformat, ],
				nextchecknum: [results.nextchecknum, ],
			});

			this.modalService.open(this.editAddRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
		});
	}

	getFormValidationErrors() {
		Object.keys(this.accountForm.controls).forEach((key, index) => {
			const controlErrors: ValidationErrors = this.accountForm.get(key).errors;
			if (controlErrors != null) {
				Object.keys(controlErrors).forEach(keyError => {
					//this.customerForm.controls[index].markAsTouched()
					this.accountForm.markAllAsTouched();
					this.accountForm.markAsUntouched()

					if (key == 'reference') {
						key = 'PO Number';
					}

					this.globalSearchService.showNotification(key + ' ' + keyError, 'danger', 'bottom', 'right')
				});
			}
		});
	}

	announceSortChange(sortState: Sort) {

	}

	back(): void {
		this.location.back()
	}


}
