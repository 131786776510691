<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card tp-box">
					<div class="card-header card-header-{{config.color}}-1">
						<div class="row">
							<div class="col-md-12">
								<h4 class="card-title bold">
									<i class="material-icons" (click)="back()">arrow_left</i>
									Credit Created
								</h4>
								<div class="card-category text-right" *ngIf="order.header">
									{{ order.header.name }}: # <b>{{ order.header.orderno }}</b>
								</div>
							</div>
						</div>
					</div>
					<div class="card-body">
						<ng-container>
							<div class="row">
								<ng-container *ngIf="!dispatching">
									<div class="col-12 text-center">
										<h3 class="bold">RAM#: {{ orderno}}
											<span [innerHTML]="modifed ? 'Updated' : 'Created'"></span>
										</h3>
										<mat-divider></mat-divider>
										<div class="row">
											<div class="col-md-3 ml-auto mr-auto mt-2 mb-2">
												<a mat-raised-button color="warn" (click)="editOrder(orderno)">Edit Credit</a>
											</div>
											<div class="col-md-3 ml-auto mr-auto mt-2 mb-2">
												<a href="{{ reprintlink }}" mat-raised-button color="warn">Re-print Pick</a>
											</div>
											<div class="col-md-3 ml-auto mr-auto mt-2 mb-2">
												<a [routerLink]="'/receivables/dispatch/'+orderno" mat-raised-button color="accent" class="ml-2 mr-2">Credit</a>
											</div>
											<div class="col-md-3 ml-auto mr-auto mt-2 mb-2">
												<button mat-raised-button color="success" [routerLink]="'/receivables/credit-note'">New Credit</button>
											</div>
										</div>
										<mat-divider></mat-divider>
										<div class="col-md-12 ml-auto mr-auto mt-4 " *ngIf="sending">
											<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
												<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
											</svg>
										</div>
									</div>
								</ng-container>
							</div>
						</ng-container>
					</div>


				</div>
			</div>
		</div>
	</div>
</div>
