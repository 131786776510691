<ng-template #reorderBox let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">ReOrder</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-header text-center">
					<h4 class="card-title text-center"></h4>
				</div>
				<div class="card-body text-center">
					<div class="row">
						<div class="col-6">
							<mat-form-field appearance="outline">
								<mat-label>Stock ID</mat-label>
								<input disabled="disabled" type="text" class="text-left " matInput [(ngModel)]="reorderstockid" />
							</mat-form-field>
						</div>
						<div class="col-6">
							<mat-form-field appearance="outline">
								<mat-label>Quantity</mat-label>
								<input type="text" class="text-left " matInput [(ngModel)]="orderquantity" />
							</mat-form-field>

							<button mat-stroked-button color="success" aria-label="" (click)="addToOrder(reorderstockid)" class="mt-2">
								<mat-icon>shopping_cart</mat-icon> Add To Cart
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<div class="container-fluid mt-3 tp-box" [@flipState]="flip">
	<div class="tp-box__side tp-box__back" [ngClass]="flip == 'inactive' ? 'd-none' : ''">
		<button mat-stroked-button color="secondary" (click)="toggleFlip()"> View Current Items </button>
		<app-consignment-build [customerid]="customerdata.debtorno" [customer]="customerdata" (updateconsignment)="updateCustomerEvent($event)"></app-consignment-build>
	</div>
	<div id="frontside" class="row tp-box__side tp-box__front m-0 p-0 " [ngClass]="flip == 'active' ? 'd-none' : ''">
		<div class="row">
			<div class="col-4">
				<button mat-stroked-button color="secondary" (click)="toggleFlip()"> Modify Items </button>
			</div>
			<div class="col-8 text-right">
				<ng-container *ngIf="printing">
					<svg class="spinner" width="15px" height="15px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
						<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
					</svg>
				</ng-container>
				<ng-container *ngIf="!printing">
					<button class="btn btn-danger btn-sm" (click)="pdf()">
						<i class='fa fa-file-pdf-o'></i>
					</button>
					<button class="btn btn-success btn-sm" (click)="excel()">
						<i class='fa fa-file-excel-o'></i>
					</button>
				</ng-container>
			</div>
		</div>

		<div class="table mt-3" #print_table>
			<table mat-table class="table table-hover table-striped" [dataSource]="consignitems">
				<ng-container matColumnDef="stockid">
					<th mat-header-cell *matHeaderCellDef>Item#</th>
					<td mat-cell *matCellDef="let item">
						{{ item.stockid }}
					</td>
				</ng-container>
				<ng-container matColumnDef="brname">
					<th mat-header-cell *matHeaderCellDef>ShipTo</th>
					<td mat-cell *matCellDef="let item">
						{{ item.brname }}
					</td>
				</ng-container>
				<ng-container matColumnDef="description">
					<th mat-header-cell *matHeaderCellDef>Description</th>
					<td mat-cell *matCellDef="let item">
						{{ item.description }}
					</td>
				</ng-container>
				<ng-container matColumnDef="date_stocked">
					<th mat-header-cell class="text-right" *matHeaderCellDef> Last Consigned</th>
					<td mat-cell class="text-right" *matCellDef="let item">
						{{ item.date_stocked | date: 'MM/dd/yyyy' }}
					</td>
				</ng-container>
				<ng-container matColumnDef="cost" class="donotprint">
					<th mat-header-cell class="text-right" *matHeaderCellDef>Cost</th>
					<td mat-cell class="text-right" *matCellDef="let item">
						{{item.price | currency}}
					</td>
				</ng-container>
				<ng-container matColumnDef="quantity">
					<th mat-header-cell class="text-center" *matHeaderCellDef>Qty</th>
					<td mat-cell class="text-center" *matCellDef="let item">
						{{item.qty | number : '1.0-0'}}
					</td>
				</ng-container>
				<ng-container matColumnDef="ext">
					<th mat-header-cell class="text-right" *matHeaderCellDef>Total</th>
					<td mat-cell class="text-right" *matCellDef="let item">
						{{item.price * item.qty | currency}}
					</td>
				</ng-container>
				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let item">
						<button mat-stroked-button color="accent" class="donotprint" (click)="orderConsignItem(item)"> Reorder </button>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="headercolumns"></tr>
				<tr mat-row *matRowDef="let row; columns: headercolumns"></tr>
			</table>

		</div>

	</div>
</div>

<!-- right side cart-->
<!--
<div class="fixed-plugin">
	<section class="dspcost text-center">
		<mat-checkbox class="margin mt-2" [(ngModel)]="dspcost"></mat-checkbox>
	</section>
	<div class="dropdown show-dropdown text-center cart-fixed" *ngIf="items">
		<a href="#" data-toggle="dropdown" aria-expanded="false">
			<i class="fa fa-shopping-cart fa-2x text-white">
				{{ items.length }}
			</i>
			<span class="text-white" *ngIf="total_qty">({{total_qty}})</span>
		</a>
		<ul class="dropdown-menu cart-fixed-content " x-placement="bottom-start" id="rightcart" *ngIf="items">
			<li class="header-title">Customer Consignment</li>
			<ng-container *ngIf="items">
				<div class="container text-right" id="rightcart-content" *ngIf="!items.length">
					<div class="row mt-0 mb-0 pt-0 pb-0 border-top small-font">
						<div class="col-md-12 mt-0 pt-0 text-center mb-3 mt-3">
							<h4>No Current Items</h4>
						</div>
					</div>
				</div>
				<div class="container text-right" id="rightcart-content" (click)="stopProp($event)">
					<div class="row mt-0 mb-0 pt-0 pb-0 border-top small-font" *ngFor="let item of items">
						<div class="col-md-12 mt-0 pt-0 text-left">
							<button mat-icon-button aria-label="Change Vendor" color="accent" (click)="viewPurchOrderOptions(item)">
								<mat-icon class="smaller-font">edit</mat-icon>
							</button>
							<span *ngIf="item.po_options" color="accent">{{ item.po_options.supplierno }}&nbsp;</span>
							<b>{{item.stockid}}</b> <span class="bold">{{item.description}}</span>
						</div>
						<div class="col-md-1 mt-2 p-0">
							<img src="{{ item.thumb}}" class="img img-responzive" lazy />
						</div>
						<div class="col-md-4 text-right">
							<mat-form-field appearance="outline" class="m-0 p-0">
								<mat-label>Line Notes</mat-label>
								<input matInput maxlength="64" placeholder="Line Notes." [value]="item.notes" (input)="updateNote($event,item)">
							</mat-form-field>
						</div>
						<div class="col-md-3 text-right">
							<mat-form-field appearance="outline" class="m-0 p-0">
								<mat-label>Price</mat-label>
								<span matPrefix>$</span>
								<input matInput type="text" class="text-right" placeholder="Price" [value]="item.price" (input)="updatePrice($event,item)">
							</mat-form-field>
						</div>
						<div class="col-md-3 text-right">
							<mat-form-field appearance="outline" class="m-0 p-0">
								<mat-label>Qty</mat-label>
								<span matPrefix>#</span>
								<input matInput type="text" placeholder="QTY" class="text-center" [value]="item.quantity" (input)="updateQuantity($event, item)">
							</mat-form-field>
						</div>
						<div class="col-1 m-0 p-0 pt-2 text-right">
							<button mat-icon-button color="warn" aria-label="remove" (click)="removeFromOrder(item.cart_id)">
								<mat-icon>remove</mat-icon>
							</button>
						</div>
					</div>
				</div>
				<div *ngIf="items.length">
					<div class="row">
						<div class="col-12 text-right" *ngFor="let total of carttotals">
							{{ total.title }} {{ total.text | currency}}
						</div>
					</div>
					<div class="row">
						<div class="col-4 text-left">
							<button class="btn btn-danger" (click)="clearCart()">Clear</button>
						</div>
						<div class="col-4 text-center">
							<button class="btn btn-success" (click)="orderEntry()">Place Order</button>
						</div>
						<div class="col-4 text-right">
							<button class="btn btn-primary" (click)="saveQuote()">Save Quote</button>
						</div>
					</div>
				</div>
			</ng-container>
		</ul>
	</div>
</div> -->
<div class="fixed-plugin">
			<div class="dropdown show-dropdown text-center cart-fixed" *ngIf="items">
				<a href="#" data-toggle="dropdown" aria-expanded="false"  >
					<!-- <mat-icon>shopping_cart</mat-icon> 2x?-->
					<i class="ml-auto mr-auto fa fa-shopping-cart fa-1x text-white" [ngClass]="!proceed ? 'text-danger' : ''">
						({{ items.length }})
					</i>
					<span class="text-white" *ngIf="total_qty"><br><i class="fa fa-caret-left fa-1x text-white" aria-hidden="true"></i> ({{total_qty}})</span>
					<span class="text-white"><br> {{ getTotal() | currency}}</span>
					<!-- <button class="nav-item ml-auto mr-auto" mat-raised-button (click)="placeOrder()" color="accent" *ngIf="flip =='active'">
						<span *ngIf="!editing_order">Create</span>
						<span *ngIf="editing_order">Update</span>
					 </button> -->
				</a>
				<ul class="dropdown-menu cart-fixed-content" x-placement="bottom-start" id="rightcart" *ngIf="items">
					<li class="header-title" [ngClass]="{'hasitems': items.length }">Current Order
					</li>
					<ng-container>
						<div class="container text-right" id="rightcart-content" *ngIf="!items.length">
							<div class="row mt-0 mb-0 pt-0 pb-0 border-top small-font">
								<div class="col-md-12 mt-0 pt-0 text-center mb-3 mt-3">
									<h4>No Current Items</h4>
								</div>
							</div>
						</div>
						<div class="container text-right" id="rightcart-content" >

							<ng-container *ngIf="items.length" (click)="stopProp($event)">

								<div class="row mt-0 mb-0 pt-0 pb-0 border-top small-font" *ngFor="let item of items" (click)="stopProp($event)" [ngClass]="{'parent': ((item.option != '') && (item.option != 'Component')) ? 'true' : '', 'component': ((item.option != '') && (item.option == 'Component')) ? 'true' : ''}">
									<div class="col-md-12 mt-0 pt-0 text-left">
										<b>{{item.stockid}}</b>&nbsp;&nbsp;<span class="bold">{{item.description}}<span *ngIf="item.option && item.option != 'Component'"><span class="parent-tag"> KIT </span></span></span>
									</div>
									<div class="text-center col-md-3" >
											<div *ngIf="item.option && item.option == 'Component'">
												<div class="flex-row"><mat-icon class="h-19">segment</mat-icon><span class="component-tag">component</span></div>
											</div>
											<div [ngClass]="{ 'bg-warning': item.porequired == '1', 'bg-success': item.porequired == '0' }" *ngIf="!item.parent_id && config.pofromorder" >
												<p class="text-light mt-3" *ngIf="item.porequired == '0'"> Available</p>
												<p class="text-dark mt-3" *ngIf="item.porequired == '1'"> PO Required</p>
											</div>
									</div>

									<div class="col-md-2 text-right" *ngIf="config.discounts && item.mbflag != 'F' && editsallowed.discounts">
										<mat-form-field appearance="outline" class="text-right">
											<mat-label>Discount</mat-label>
											<input matInput type="text" class="text-right" placeholder="Discount" [value]="item.discount" (input)="updateDiscount($event,item)" appNumericField>
											<span matSuffix>%</span>
										</mat-form-field>
									</div>
									<div class="col-md-4 m-0 p-0 ">
										<mat-form-field appearance="outline" class="m-0 p-0 text-right">
											<mat-label>Price</mat-label>
											<span matPrefix>$</span>
											<input matInput type="text" class="text-right" placeholder="Price" [value]="item.price" (input)="updatePrice($event,item)" appNumericField [disabled]="!editsallowed.discounts">
											<mat-hint *ngIf="config.discounts && item.discount > 0" class="text-right text-danger bolder">
												Discount Price {{ getDiscounPrice(item) | currency }} @ {{ item.discount }}%
											</mat-hint>
										</mat-form-field>
									</div>
									<div class="col-md-2 mt-0 mb-0 pt-0 pb-0 text-right">
										<mat-form-field appearance="outline" class="m-0 p-0 text-center">
											<mat-label >Qty</mat-label>
											<span matPrefix>#</span>
											<input matInput type="text" placeholder="QTY" class="text-center" [value]="item.quantity" (input)="updateQuantity($event, item)" appNumericField>
										</mat-form-field>
									</div>
									<div class="col-1 m-0 p-0 pt-2 text-right">
										<button mat-icon-button color="warn" aria-label="remove" (click)="removeFromOrder(item.cart_id)">
											<mat-icon>remove</mat-icon>
										</button>
									</div>
								</div>
								<div class="col-12 p-0 m-0 text-right" *ngFor="let total of cart_totals">
									<span *ngIf="(total.text !== '0.00' && total.title == 'Discount Total') || !['Discount Total'].includes(total.title)">
										<b>{{total.title }}</b>: {{ total.text | currency }}
									</span>

								</div>
							</ng-container>
						</div>
					</ng-container>
				</ul>
			</div>
		</div>
