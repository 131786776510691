import { Injectable } from '@angular/core';
import { catchError , retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpHeaders,HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';

import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from '../services/globalsearchservice.service';


@Injectable({
	providedIn: 'root'
})
export class BinLabelsService {

	key = 'UET6DFMPAXW7BKCB';
	config: any;
	package: any = '';

	constructor(private globalSearchService: GlobalSearchService,private http: HttpClient, private httpClient: HttpClient,private _configSvc: RuntimeConfigLoaderService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})
	}

	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type':	'application/json',
		})
	};


	public removeStorageItem(tag: any) {
		const method = 'tools/binlabels/removestorageitem&tag='+tag;
		return this.http.get(this.setEndPoint(method));
	}

	public getStorageItems() {
		const method = 'tools/binlabels/storagelist';
		return this.http.get(this.setEndPoint(method));
	}
	public getNextTag() {
		const method = 'tools/binlabels/nexttag';
		return this.http.get(this.setEndPoint(method));
	}

	public printStorageLabel(data: any ) {
		const method = 'tools/binlabels/storage'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('binlabels', data))
		);
	}

	public printTireLabel(data: any ) {
		const method = 'tools/binlabels/tire'
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('binlabels', data))
		);
	}

	public printSingle(text : any ) {
		const method = 'tools/binlabels/single'
		const data = { text: text }
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('binlabels', data))
		);
	}

	public printRange(from : any , to: any) {
		const method = 'tools/binlabels/range'
		const data = { from: from, to: to }
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('binlabels', data))
		);
	}

	private setEndPoint(method: any) {
		return this.getApi()+method+this.getApiKey()
	}

	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getApiKey() {
		 return '&key='+this.config.apiCredentials.clientId;
	}

	public getKey() {
		return '&apikey='+this.key;
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			return error;
		};
	}
}
