<div class="container-fluid mt-3">
	<div class="row">

		<div class="col-lg-12">
			<div class="card">

				<div class="card-header" *ngIf="order.details">
					<h3 class="card-title mb-0">Pay Order# {{order.header.orderno}}</h3>
				</div>
				<div class="card-body">

					<div class="button-container text-center" *ngIf="!order.details">
						<h4 class="invoiced-message">Order Has Been Invoiced</h4>
					</div>


					<ng-container *ngIf="order.details">
						<div class="button-container">
							<!-- Cancel Button -->
							<button mat-raised-button class="btn btn-danger fullsize mb-3" (click)="cancelOrder(order.header.orderno)">
								CANCEL
							</button>

							<!-- Invoice & Pay Button, hidden when 'sending' is true -->
							<ng-container *ngIf="!sending; else loadingSpinner">
								<button
									mat-raised-button
									color="accent"
									class="btn btn-primary fullsize mb-3"
									[ngClass]="{
										'bad': getBalance() > 0,
										'good': getBalance() === 0.00,
										'warn': getBalance() < 0.00
									}"
									[disabled]="getBalance() > 0"
									(click)="createInvoice()"
								>
									INVOICE & PAY
								</button>
							</ng-container>

							<!-- Loading Spinner shown when 'sending' is true -->
							<ng-template #loadingSpinner>
								<div class="spinner-container fullsize mb-3">
									<mat-spinner></mat-spinner> <!-- Angular Material Spinner -->
								</div>
							</ng-template>
						</div>

						<!-- Order Payments Component and Validation Warning -->
						<ng-container *ngIf="order.saved_totals && order?.header?.debtorno">
							<app-pos-order-payments-after
								[totals]="order.saved_totals"
								[pos]="true"
								[order_header]="order.header"
								[items]="order.details"
								[debtorno]="order.header.debtorno"
								[branchcode]="order.header.branchcode"
								[orderno]="order.header.orderno"
								[payments]="payments"
								(payments_added)="updatePayments($event)"
								(payments_removed)="updatePayments($event)"
								[document_type]="'10'"
							></app-pos-order-payments-after>

							<li
								*ngIf="getBalance() > 0 && termRequiresPayment() && document_text !== 'Credit'"
								class="alert alert-danger"
							>
								CUSTOMER TERM REQUIRES PAYMENT {{ getBalance() | currency }}
							</li>
						</ng-container>
					</ng-container>


				</div>
			</div>
		</div>
	</div>
</div>
