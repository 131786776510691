import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router } from '@angular/router';

import { InventoryService } from '../../../services/inventory.service';
import { GlobalsService } from '../../../services/globals.service';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { Customer } from '../../../classes/customer';
import { Location } from '@angular/common'
import { GlobalSearchService } from 'app/services/globalsearchservice.service';

@Component({
	selector: 'app-bin-merge',
	templateUrl: './bin-merge.component.html',
	styleUrls: ['./bin-merge.component.scss']
})
export class BinMergeComponent implements OnInit {
	@Input() bins;
	@Output() binupdate;

	tobins: any = [];
	allbins: any = [];
	filteredbins: any = [];
	filteredtobins: any = [];
	transferBin = new UntypedFormControl('');
	filteredOptions: any = [];
	searching = false;
	search_available = '';
	search_current = '';
	neworcurrent = ''
	sending = false;
	merged = false;
	affected: any = false;
	onhandoly = true;
	config: any;
	newbin: boolean = false;
	verifiedbins: any = [];
	constructor(private inventoryService : InventoryService, private globalSearchService: GlobalSearchService) { }

	ngOnInit(): void {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})

		this.inventoryService.getVerifiedBinListing().subscribe((res: any)=>{
			this.verifiedbins = res;
		})

		this.allbins = this.bins
		this.filteredbins = this.bins
		this.filteredOptions = this.bins;

		this.filteredbins.sort((a, b) => (a.location > b.location) ? 1 : (a.location === b.location) ? ((a.location > b.location) ? 1 : -1) : -1 )

		this.transferBin.valueChanges.subscribe(newValue => {
			this.filteredOptions = this.filterValues(newValue.toUpperCase());
			this.affected = false;
			this.newOrCurrentBin();
		})

	}

	toggleOnHandOnly() {
		this.onhandoly = (!this.onhandoly) ? true : false;
	}

	moveItems() {
		this.sending = true;
		this.inventoryService.mergeBins(this.tobins, this.transferBin.value.toUpperCase()).subscribe((results: any) => {

			this.sending = false;
			if(results.success) {

				const allitems = this.tobins.map( bin => {
					return bin.stockid
				});

				const message = 'Bin(s): '+ allitems.join(',')+' Merged To '+this.transferBin.value;
				alert(message);

				this.allbins = results.bins
				this.filteredbins = results.bins
				this.filteredOptions = results.bins;
				this.tobins = [];
				this.filteredtobins = [];
				this.transferBin.setValue('');
				this.search_current = '';
				this.search_available = '';
				this.neworcurrent ='';
				this.merged = true;
				this.affected = results.affected;
			} else {
				this.merged = false;
				const allbins = this.tobins.map( bin => {
					return bin.location
				});
				const message = 'MERGE FAILED! Bin(s): '+allbins.join(',')+' To '+this.transferBin.value;
				alert(message);
			}
		});
	}

	newOrCurrentBin() {
		var exists;
		if(this.config.env.package == 'tires'){
			exists = this.verifiedbins.filter(bin => {
				return bin.bin.toUpperCase() == this.transferBin.value.toUpperCase()
			})
		}else{
			exists = this.allbins.filter(bin => {
				return bin.location.toUpperCase() == this.transferBin.value.toUpperCase()
			})
		}


		if(this.transferBin.value =='') {
			this.neworcurrent = '';

		} else {
			if(exists.length){
				this.newbin = false;
				this.neworcurrent = 'Merge to ' +this.transferBin.value;
			}else{
				if(this.config.env.package == 'tires'){
					this.newbin = true;
					this.neworcurrent = `${this.transferBin.value} not on file, please see backoffice`;
				}else{
					this.neworcurrent = 'Creating New Bin: '+this.transferBin.value
				}
			}
		}
	}

	moveTo(bin: any) {
		//did something stupid..
		this.affected = false;
		let index =this.allbins.indexOf(bin)
		this.allbins.splice(index, 1);

		index =this.filteredbins.indexOf(bin)
		this.filteredbins.splice(index, 1);

		this.tobins.push(bin)
		this.filteredtobins.push(bin)
		this.filteredbins.sort((a, b) => (a.location > b.location) ? 1 : (a.location === b.location) ? ((a.location > b.location) ? 1 : -1) : -1 )
	}

	moveFrom(bin: any) {
		this.affected = false;
		const index = this.tobins.indexOf(bin)
		this.tobins.splice(index, 1);
		this.filteredtobins.splice(index, 1);

		this.filteredbins.push(bin)
		this.filteredbins.sort((a, b) => (a.location > b.location) ? 1 : (a.location === b.location) ? ((a.location > b.location) ? 1 : -1) : -1 )
	}

	searchBin(event: any) {
		this.filteredbins = this.filterItem(this.allbins, event.target.value, 'location');
	}

	searchSelectedBin(event: any) {
		this.filteredtobins = this.filterItem(this.tobins, event.target.value, 'location');
	}

	filterItem(input: any, term: any , keys: any ){
		if(!term){
			//this.assignCopy(input);
		}
		const result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) &&	new RegExp(term, 'gi').test(item[key])));
		return result
	}

	filterValues(search: string) {
		return this.allbins.filter( (value) => {
			return value.location.toLowerCase().indexOf(search.toLowerCase()) === 0
		});
	}

}
