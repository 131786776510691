<div class="wrapper">
	<div class="sidebar" data-color="azure" data-background-color="white" data-image="./assets/img/sidebar-1.jpg">
		<app-sidebar cdkTrapFocus></app-sidebar>
		<div class="sidebar-background"></div>
	</div>
	<div class="main-panel">
		<app-navbar></app-navbar>
		<router-outlet></router-outlet>
		<div *ngIf="isMaps('maps')">
			<app-footer></app-footer>
		</div>
	</div>
</div>
