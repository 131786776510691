import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { CustomerService } from '../../services/customer.service';
import { Location } from '@angular/common'
import { trigger, state, style, transition, animate } from '@angular/animations';

import * as FileSaver from 'file-saver';

@Component({
	selector: 'app-customer-orders',
	templateUrl: './customer-orders.component.html',
	styleUrls: ['./customer-orders.component.scss']
})
export class CustomerOrdersComponent implements OnInit {
	invoices;
	dtOptions: DataTables.Settings = {};
	constructor(private route: ActivatedRoute, private formBuilder: UntypedFormBuilder,private customerService: CustomerService, private location: Location) {
	  this.route.params.subscribe(params => {
			const customerid = params['id'];

			this.customerService.getInvoices(customerid).subscribe(invoices => this.invoices = invoices);
		});

	}

	ngOnInit(): void {
	  this.dtOptions = {
			pagingType: 'full',
			pageLength: 100,
			processing: true
		};



	}

	back(): void {
		this.location.back()
	}


}
