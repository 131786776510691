<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}">
						<h4 class="card-title" id="headof" (click)="back()"><i class="material-icons">arrow_left</i> Account Inquiry</h4>
					</div>
					<div class="card-body">
						<form [formGroup]="formGroup">
							<div class="row">

								<div class="col-md-8">
									<app-gl-account-lookup (glselected)="selectGl($event)"></app-gl-account-lookup>
								</div>
								<div class="col-md-2">
									<mat-form-field appearance="outline" >
										<mat-label>Posted</mat-label>
										<mat-select formControlName="posted">
											<mat-option *ngFor="let p of postendonly" [value]="p.value">
												{{ p.label }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-2">
									<mat-form-field appearance="outline" >
										<mat-label>GL Tags</mat-label>
										<mat-select formControlName="gl_tag">
											<mat-option *ngFor="let tag of tags" [value]="tag">
												{{ tag }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-6">
									<mat-form-field appearance="outline" >
										<mat-label>From Period</mat-label>
										<mat-select formControlName="from_period" (selectionChange)="updateToOptions($event.value)">
											<mat-option *ngFor="let period of from_period_options" [value]="period.periodno">
												{{period.lastdate_in_period }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="col-md-6">
									<mat-form-field appearance="outline" >
										<mat-label>To Period</mat-label>
										<mat-select formControlName="to_period">
											<mat-option *ngFor="let period of to_period_options" [value]="period.periodno">
												{{period.lastdate_in_period }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

								<div class="col-md-12 text-right">
									<button class="mt-3" mat-raised-button [matMenuTriggerFor]="menu" *ngIf="transresults.periods">Export</button>
									<mat-menu #menu="matMenu">
										<button mat-menu-item (click)="exportExcel()"><i class="fa fa-file-excel-o"></i> XLS</button>
										<button mat-menu-item (click)="exportPDF('acctrep')"><i class="fa fa-file-pdf-o"></i> PDF </button>
									</mat-menu>
									<button mat-raised-button color="accent" (click)="getAccount()">Run</button>
								</div>
							</div>
						</form>
						<div class="row" #print_table>
							<div class="col-md-12">
								<h3 class="mt-2 bold mb-2"> </h3>
								<div class="table" id="acctrep" *ngIf="transresults">
									<h4 class="bold">Account Inquiry</h4>
									<h4>{{ selectedaccount.accountname }} ({{selectedaccount.accountcode}}) </h4>
									<ng-container *ngIf="periods_transactions.length">
											<table class="table table-sm" *ngFor="let transperiods of periods_transactions">
												<tr>
													<th>Type</th>
													<th>Number</th>
													<th class="text-right">Date</th>
													<th class="text-right">Debit</th>
													<th class="text-right">Credit</th>
													<th class="text-right">Check Number</th>
													<th class="text-right">Description</th>
													<th class="text-right">Balance</th>
													<th class="text-right">Tag</th>
												</tr>
												<tr>
													<th colspan="3">Starting Period Balance</th>
													<th class="text-right"><span *ngIf="transperiods.startbalance > 0">{{transperiods.startbalance | currency}}</span>
													</th>
													<th class="text-right"><span *ngIf="transperiods.startbalance <= 0">{{transperiods.startbalance | currency}}</span></th>
													<th colspan="4"></th>
												</tr>
												<tr *ngFor="let trans of transperiods.transactions">
													<td>{{ trans.type }}</td>
													<td>{{ trans.typeno }}</td>
													<td class="text-right">{{ trans.trandate }}</td>
													<td class="text-right">{{ trans.debit | currency}}</td>
													<td class="text-right">{{ trans.credit | currency}}</td>
													<td class="text-right">{{ trans.chequeno }}</td>
													<td class="text-right">{{ trans.narrative }}</td>
													<td class="text-right">{{ trans.balance | currency}}</td>
													<td class="text-right">{{ trans.tag }}</td>
												</tr>
											</table>
									</ng-container>
									<ng-container *ngIf="!periods_transactions.length && transresults">
										<h4 class="text-left"> No results</h4>
									</ng-container>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
