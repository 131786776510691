import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet, MatBottomSheetConfig } from '@angular/material/bottom-sheet';
import { GlobalsService } from '../services/globals.service';
import { GlobalSearchService } from '../services/globalsearchservice.service';
import { ROUTES } from '../classes/routes';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { OmsService } from '../services/oms.service';
import { OrdersService } from '../services/orders.service';
import { UsersService } from '../services/users.service';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss']
})

export class SearchComponent {
	@ViewChild('checkedOrders') springTemplate = {} as TemplateRef < any > ;
	@ViewChild('newOrders') newOrdersTemplate = {} as TemplateRef < any > ;
	filterresults = [];
	filteredItems;
	orderData: any;
	customerData;
	locations: any = [];
	user: any = [];
	openOrderData: any = [];
	allresults: any = [];
	selectedItem: any = '';
	location = new UntypedFormControl();
	selected_location: any = false;
	config: any = false;
	datasets: any = false;
	selectedset: any = '';
	checkedorders: any = false;
	opened = false
	ordersopened = false
	@Input() name: string;
	neworders: any = [];
	issalesman: any = false;
	innorderentry: any = false;
	private routeSubscription: any;
	isPosUser: any = false;

	constructor(private usersService: UsersService, private route: ActivatedRoute, private activatedRoute: ActivatedRoute, private globalsService: GlobalsService, private bottomSheet: MatBottomSheet, private ordersService: OrdersService, private omsService: OmsService, private globalSearchService: GlobalSearchService, public router: Router) {


		this.init(true);

		this.routeSubscription = this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {

				if (event.url === '/orders/entry') {
					this.innorderentry = true;
				}
			});
	}


	init(event: any) {

		this.globalSearchService.configsubscription.subscribe(conf => {

			this.config = conf;
			this.globalSearchService.user.subscribe(result => {
				this.user = result;

				this.selected_location = result.user.defaultlocation.loccode;

				if (this.user.pass) {
					this.globalSearchService.locations.subscribe(l => {
						this.locations = l;
					});
				}

				if (this.user.user.issalesman) {
					this.issalesman = true;
				}

				this.usersService.isPosUser().subscribe((isPOS) => {
					if (isPOS) {
						this.isPosUser = true;
					}
				})
			});
		});
	}

	initLoad(event: any) {
		this.init(event);
		this.globalSearchService.locations.subscribe(l => {
			this.locations = l;
		});
	}

	getValue() {
		return (this.selected_location) ? this.selected_location : '01';
	}

	vibrate() {
		if (window.navigator && window.navigator.vibrate) {
			navigator.vibrate(2000);
		} else {
			// Not supported
		}
	}

	closeBottomSheet() {
		this.bottomSheet.dismiss(this.springTemplate);
	}

	loadCheckedOrders() {
		const input = { loccode: this.selected_location }
		this.ordersService.getCheckedOrders(input).subscribe(r => {
			this.checkedorders = r;
		});
	}

	loadOrder(orderno: any) {
		this.globalSearchService.reloadingOrder(true);
		this.ordersService.loadSalesOrder(orderno).subscribe((result: any) => {
			this.router.navigate(['./orders/entry/' + result.header.debtorno + '/' + result.header.branchcode]);
			this.globalSearchService.reloadingOrder(false);

		});
	}

	selectItemLookup(event: any) {
		this.router.navigate(['./customers/view/' + event.debtorno]);
	}

	pickOrder(event: any) {
		this.router.navigate(['./orders/pick/' + event]);
	}

	invoiceOrder(event: any) {
		this.router.navigate(['orders/invoice/' + event + '/dispatch']);
	}

	ngOnInit() {

		this.globalSearchService.selectedlocation.subscribe(result => {

			if (!result) {
				this.globalSearchService.selectedlocation.next(this.user.user.defaultlocation.loccode)
				result = this.user.user.defaultlocation.loccode
			}
			this.selected_location = result;

		});
	}

	setDataSet(event: any) {
		const newDataSet = event.value;

		localStorage.removeItem('app');

		this.globalSearchService.setPackage('app', event.value.config.env.package)

		const newpackaege = this.globalSearchService.getSessionItem('app');
		this.globalSearchService.configsubscription.next(event.value.config);

		this.selectedset = event.value.config.env.package;
		this.config = event.value.config;
		this.router.navigate(['/']);
		window.location.reload();

	}

	changeLocation(event: any) {

		const newLocation = event.value;

		let newloc = this.locations.filter((l: any) => {
			return l.loccode == newLocation;
		})[0];

		//reset the users default location on change - not permanent.
		//default to clevehill
		if (newloc === null || newloc === false || newloc == '') {
			newloc = '01'
		}

		this.user.user.defaultlocation = newloc;
		this.globalSearchService.user.next(this.user)
		this.globalSearchService.userData.next(this.user);
		this.globalSearchService.selectedlocation.next(newLocation)
		this.selected_location = newLocation;

		localStorage.setItem('user', JSON.stringify(this.user))
	}

	onChange(event: any) {

		const option = this.allresults.filter(f => {
			return f.name == event.target.value;
		})[0];

		if (option) {
			if (option.link) {
				this.router.navigate([option.link])
			}
		}
	}

	navto(link: any) {
		this.router.navigate([link])
	}

	onInput(event: any, keys = 'orderno') {
		// this pushes the input value into the service's Observable.

		this.globalSearchService.searchTerm.next(event.target.value);

		const term = event.target.value;

		this.globalSearchService.orderData.subscribe((cards: any) => {
			this.orderData = cards;
		});

		this.globalSearchService.customerData.subscribe((customers: any) => {
			this.customerData = customers;
		});

		this.globalSearchService.openOrderData.subscribe((openorders: any) => {
			this.openOrderData = openorders;
		});

		const allresults = [];

		if (this.openOrderData) {
			const openorders = this.filterItem(this.openOrderData, event.target.value, 'ADDRESS_1,CUST_NAME');
			if (openorders) {
				openorders.forEach(function(value) {
					allresults.push({ content: 'Open Order', name: value.ORDER_NUMBER + ' ' + value.CUST_NAME, link: 'orders/' + value.ORDER_NUMBER })
				});
			}
		}

		if (this.orderData) {
			const todo = this.filterItem(this.orderData.todo, event.target.value, 'orderno,content');
			const pending = this.filterItem(this.orderData.review, event.target.value, 'orderno,content');
			const done = this.filterItem(this.orderData.done, event.target.value, 'orderno,content');

			if (todo) {
				todo.forEach(function(value) {
					allresults.push({ content: 'Oms ToDo', name: value.content + value.content, link: 'orders/oms' })
				});
			}

			if (pending) {
				pending.forEach(function(value) {
					allresults.push({ content: 'Oms Pending', name: value.content + value.content, link: 'orders/oms' })
				});
			}
			if (done) {
				done.forEach(function(value) {
					allresults.push({ content: 'Oms Done', name: value.content + value.content, link: 'orders/oms' })
				});
			}
		}

		if (ROUTES) {
			const routes = this.filterItem(ROUTES, event.target.value, 'title,icon');
			routes.forEach(function(value) {
				if (value.children) {

					if (value.children.length) {
						const thesekeys = 'title,icon'
						const childrensearch = Object.assign([], value.children).filter((item) => thesekeys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(event.target.value, 'gi').test(item[key])));

						childrensearch.forEach(function(childvalue) {
							allresults.push({ content: childvalue.title, name: childvalue.title, link: '' + childvalue.path })
						});
					}
				}
			});
		};

		this.allresults = allresults;
		this.setFiltered(allresults)
	}

	getFiltered() {
		return this.filteredItems;
	}

	setFiltered(results) {
		this.filteredItems = results;
	}

	assignCopy() {
		this.filteredItems = Object.assign([], this.orderData);
	}

	filterItem(input, term, keys) {
		if (!term) {
			this.assignCopy();
		}
		const result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));
		return result.splice(0, 15)
	}

	clicked(link) {
		this.router.navigate([link]);
	}

	ngOnDestroy() {
		// if (this.routeSubscription) {
		// 	this.routeSubscription.unsubscribe();
		// }
	}

}