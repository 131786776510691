
<div class="row m-0 p-0">
	<div class="col-md-12">
		<div class="card border">
			<div class="card-header card-header-warning card-header-icon">
				<div class="card-icon">
					<i class="material-icons">local_offer</i>
				</div>
				<h4 class="card-title bold">Prices</h4>
				<div class="card-title col-12 text-right">
					<button mat-raised-button color="accent" (click)="updateItem()">Next</button>
				</div>
				<div class="card-body text-dark">
					<div class="row">
						<div class="col-8">
							<h4>PRODUCT LINE: {{ itemdetails.lineid }}</h4>
						</div>
						<div class="col-4">
							<mat-form-field appearance="outline" class="text-right">
								<mat-label>Base Price</mat-label>
								<input matInput (input)="updatePrices($event.target.value)">
							</mat-form-field>
						</div>
					</div>
					<div class="row border-bottom" *ngFor="let price of prices">
						<div class="col-md-4 mt-3">
							{{ price.typeabbrev }}: {{ price.sales_type }}:
						</div>
						<div class="col-md-4">
							<mat-form-field appearance="outline" class="text-right">
								<mat-label>Price</mat-label>
								<input matInput required [value]="price.price" (input)="setPrice($event.target.value, price)">
							</mat-form-field>
						</div>
						<div class="col-md-4">
							{{ price.typedsp }} {{ price.amount }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
