import { Component, OnDestroy, OnInit, SimpleChanges, OnChanges, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit ,  Directive, HostListener, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { DropzoneComponent, DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router } from '@angular/router';
import { ArchiveService } from '../../services/archive.service';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { TableModule } from 'primeng/table';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { Product } from '../../classes/orders';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PrintService } from '../../services/print.service'

@Component({
	selector: 'app-archive-inventory',
	templateUrl: './archive-inventory.component.html',
	styleUrls: ['./archive-inventory.component.scss']
})
export class ArchiveInventoryComponent implements OnInit {
	@ViewChild('print_table') printtable: ElementRef;

	archived_dates: any = false;
	archived_data: any = false;

	total_balance: any = 0;
	total_future : any = 0;
	total_current: any = 0;
	//30
	total_due : any = 0;
	//60
	total_overdue: any = 0;
	//90
	total_overdue1: any = 0;
	//120
	total_overdue2: any = 0;

	date_selected: any = false;
	filename = 'Archived Inventory Value';

	constructor(public printService: PrintService, public archiveService: ArchiveService, private route: ActivatedRoute, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, private modalService: NgbModal) {}

	ngOnInit(): void {
		this.loadData();
	}

	loadData() {
		this.archiveService.getArchivedInventoryDates().subscribe(r => {
			this.archived_dates = r;
		})
	}

	loadDate(date_selected: any) {
		this.date_selected = date_selected;
		const date_data = { date: date_selected }
		this.archiveService.getArchivedInventoryByDay(date_data).subscribe(r => {
			this.archived_data = r;
		})

	}

	getCategoryTotalUnits(category: any) {
		const cdata = this.archived_data.summary.filter(c => {
			return c.categoryid == category
		})[0];

		return this.int(cdata.inventory_count);
	}

	getCategoryTotal(category: any) {
		const cdata = this.archived_data.summary.filter(c => {
			return c.categoryid == category
		})[0];

		return this.financial(cdata.inventory_value);
	}

	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}

	int(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(0));
	}



	updateTotals() {
		this.total_balance = this.archived_data.reduce(function(accumulator, c) {
			return accumulator + parseFloat(c.balance);
		}, 0);

		this.total_future = this.archived_data.reduce(function(accumulator, c) {
			return accumulator + parseFloat(c.future);
		}, 0);

		this.total_current = this.archived_data.reduce(function(accumulator, c) {
			return accumulator + parseFloat(c.current);
		}, 0);
		//30
		this.total_due = this.archived_data.reduce(function(accumulator, c) {
			return accumulator + parseFloat(c.due);
		}, 0);
		//60
		this.total_overdue = this.archived_data.reduce(function(accumulator, c) {
			return accumulator + parseFloat(c.overdue);
		}, 0);
		//90
		this.total_overdue1 = this.archived_data.reduce(function(accumulator, c) {
			return accumulator + parseFloat(c.overdue1);
		}, 0);
		//120
		this.total_overdue2 = this.archived_data.reduce(function(accumulator, c) {
			return accumulator + parseFloat(c.overdue2);
		}, 0);
	}

	exportPdf() {

		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const today = new Date();
		const datesel = new Date( this.date_selected)
		const data = {
			content: encoded,
			filename: this.filename,
			title: 'Archive Inventory Value',
			subtitle: 'Date: '+datesel.toLocaleString()+' created '+today.toLocaleString()
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	exportXls() {
		const today = new Date();
		const datesel = new Date( this.date_selected)
		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);

		const data = {
			content: encoded,
			filename: this.filename,
			title: 'Archive Inventory Value',
			subtitle: 'Date: '+datesel.toLocaleString()+' created '+today.toLocaleString()
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}


}
