import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform,AfterViewInit,Directive, HostListener,ViewEncapsulation  } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../../services/customer.service';
import { GlobalsService } from '../../services/globals.service';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { Location , DatePipe } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-shipping-methods',
	templateUrl: './shipping-methods.component.html',
	styleUrls: ['./shipping-methods.component.scss']
})
export class ShippingMethodsComponent implements OnInit {

	@ViewChild('editModal') editModalRef: ElementRef;

	isLoading = false;
	shipvias: any = [];
	supptypes: any = [];
	customertypes: any = [];
	payment_terms: any = [];
	all_types: any = [];
	color: any = 'blue'
	shipViaForm: UntypedFormGroup;
	uploaddata: any = false;
	files: any = [];
	tablename: any = false;
	mapcols: any = [
		{ field: 'mfgpart', label: 'Part Number' },
		{ field: 'brand', label: 'Brand' },
		{ field: 'price', label: 'Price' },
		{ field: 'stockid', label: 'Item Number' },
	];
	mapping: any = [];

	constructor(private inventoryService: InventoryService, private globalsService : GlobalsService, private fb: UntypedFormBuilder,private route: ActivatedRoute,private location: Location,public router: Router,private globalSearchService: GlobalSearchService,private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {

		this.shipViaForm = this.fb.group({
			id: [''],
			shippername: ['', Validators.required],
			mincharge: ['', Validators.required],
		});

		this.loadData();

	}

	loadData() {
		this.isLoading = true;
		this.globalsService.getShipVia().subscribe(async (results: any) => {
			this.isLoading= false;
			this.globalSearchService.shipvia.next(results)
			this.shipvias = results;
		});
	}

	addShipper() {

		this.shipViaForm = this.fb.group({
			id: [''],
			shippername: ['', Validators.required],
			mincharge: ['', Validators.required],
		});

		this.modalService.open(this.editModalRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}

	yesNoValue(input:any) {
		return (input == '1') ? 'Y': 'N';
	}

	private async readFile(file: File): Promise < string | ArrayBuffer > {
		return new Promise < string | ArrayBuffer > ((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = e => {
				return resolve((e.target as FileReader).result);
			};

			reader.onerror = e => {
				console.error(`FileReader failed on file ${file.name}.`);
				return reject(null);
			};

			if (!file) {
				return reject(null);
			}
			reader.readAsDataURL(file);
		});

		this.files = [];
	}


	editSalesType(type: any) {

	}

	editShipVia(type: any) {
		this.shipViaForm = this.fb.group({
			id: [type.shipper_id],
			shippername: [type.shippername, Validators.required],
			mincharge: [type.mincharge],
		});

		this.modalService.open(this.editModalRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}

	saveShipVia() {
		this.globalsService.saveShipVia(this.shipViaForm.value).subscribe( result => {
			if(result.success) {
				this.shipvias = result.data;
				this.modalService.dismissAll();
			} else {
				this.globalSearchService.showNotification(result.message, 'danger', 'top','right');
			}
		});
	}

	removeShiper(type: any) {
		//TODO! PREVENT THIS IF CUSTOMERS SETUP @ PRICE TYPE
		const confirm_remove = confirm('Remove Shipper ' + type.shippername + '?');
		if(confirm_remove) {
			this.globalsService.removeShiper(type).subscribe( result => {
				if(result.success) {
					this.shipvias = result.data;
				} else {
					this.globalSearchService.showNotification(result.message, 'danger', 'top','right');
				}
			});
		}
	}


	back(): void {
		this.location.back()
	}

	ngAfterViewInit(): void {

	}
}
