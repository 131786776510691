<div class="main-content">
	<div class="col-md-12">
		<div class="container-fluid tp-box" [@flipState]="flip">
			<div class="row tp-box__side tp-box__front" id="frontside" [ngClass]="flip == 'inactive' ? '' : 'd-none'">
				<div class="" [ngClass]="{'col-lg-4 col-md-4 col-sm-6': viewing_title!='agedc', 'col-lg-12 col-md-12 col-sm-12': viewing_title=='agedc'}" @grow>
					<app-over-due-customers (viewData)="toggleFlipTest($event)"></app-over-due-customers>
				</div>
				<div [ngClass]="{'col-lg-4 col-md-4 col-sm-6': viewing_title!='All Customers', 'col-lg-12 col-md-12 col-sm-12': viewing_title=='All Customers'}">
					<app-all-customers (viewData)="toggleFlipTest($event)"></app-all-customers>
				</div>

				<div class="" [ngClass]="{'col-lg-4 col-md-4 col-sm-6': viewing_title!='Matching Receivables', 'col-lg-12 col-md-12 col-sm-12': viewing_title=='Matching Receivables'}" @grow>
					<app-matching-docs (viewData)="toggleFlipTest($event)"></app-matching-docs>
				</div>
				<div class="" [ngClass]="{'col-lg-4 col-md-4 col-sm-6': viewing_title!='Matching National Account Receivables', 'col-lg-12 col-md-12 col-sm-12': viewing_title=='Matching National Account Receivabless'}" @grow>
					<app-matching-doc-customer-type (viewData)="toggleFlipTest($event)"></app-matching-doc-customer-type>
				</div>
				<div class="" [ngClass]="{'col-lg-4 col-md-4 col-sm-6': viewing_title!='Open Invoices By Term', 'col-lg-12 col-md-12 col-sm-12': viewing_title=='Open Invoices By Term'}" @grow>
					<app-receivables-byshipvia-report (viewData)="toggleFlipTest($event)"></app-receivables-byshipvia-report>
				</div>
			</div>
		</div>
	</div>