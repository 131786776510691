
<table mat-table #table2 [dataSource]="datasource" matSort matSortActive="sales" matSortDirection="desc">
    <ng-container matColumnDef="debtorno">
        <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="config.env.package == 'tires' ? 'flex12': ''"> Debtor no.</mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]="config.env.package == 'tires' ? 'flex12': ''"> {{element.debtorno}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="config.env.package == 'tires' ? 'flex25': ''"> Customer </mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]="config.env.package == 'tires' ? 'flex25': ''"> {{element.name}} </mat-cell>
    </ng-container>


    <ng-container matColumnDef="typename" *ngIf="config.env.package == 'tires'">
        <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="config.env.package == 'tires' ? 'flex25': ''"> Customer Type. </mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]="config.env.package == 'tires' ? 'flex25': ''"> {{element.typename}} </mat-cell>
    </ng-container>

     <ng-container *ngIf="locs.length > 1">
        <ng-container *ngFor="let loc of locs;">
            <ng-container matColumnDef="{{loc}}">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="loc-mat-header jc-center" matTooltip="Info about the action" matTooltipPosition="above"> {{loc}} </mat-header-cell>
                <mat-cell *matCellDef="let element" class="jc-center border" [ngClass]="element[loc] != '' ? 'units': 'no-units'">
                    <ng-container *ngIf="element[loc] != ''; else empty">
                        {{element[loc]}}
                    </ng-container>
                    <ng-template #empty>-</ng-template>
                </mat-cell>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container matColumnDef="units">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="jc-center" [ngClass]="config.env.package == 'tires' ? 'flex12': ''"> # of Units </mat-header-cell>
        <mat-cell *matCellDef="let element" class="jc-center" [ngClass]="config.env.package == 'tires' ? 'flex12': ''"> {{element.qty}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="sales">
        <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="config.env.package == 'tires' ? 'flex25 ta-r': ''"> Sales </mat-header-cell>
        <mat-cell *matCellDef="let element" class="btn-cell" [ngClass]="config.env.package == 'tires' ? 'flex25 ta-r': ''">
            {{element.sales | currency}}
            <!-- <button id="ignore-btn-event2" mat-raised-button class="xlsx" style="display: none;" (click)="printCustOrders()"><i id="ignore-btn-icon2" class="fa fa-file-excel-o"></i></button> -->
        </mat-cell>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="subdisplayedColumns" class="sub-table-header"></tr>
    <mat-row *matRowDef="let row; columns: subdisplayedColumns;" matRipple class="element-row" [cdkDetailRow]="row" [cdkDetailRowTpl]="tpl" class="sub-table-row">
    </mat-row>
</table>


<ng-template #tpl let-element >
    <div class="" [@detailExpand] style="overflow: hidden" *ngIf="element['orders'].length > 0">
        <app-productlineordertable [input]="element['orders']"></app-productlineordertable>
    </div>
</ng-template>