<div class="main-content">

	<div class="container-fluid tp-box" [@flipState]="flip">
		<div class="row backside tp-box__side tp-box__back" [ngClass]="flip != 'inactive' ? '' : 'd-none'">

			<div class="col-12">
				<div class="card">
					<div class="card-header card-header-{{color}}" (click)="toggleFlip('na')">
						<h4 class="card-title"><i class="material-icons">arrow_left</i> {{ viewing }}</h4>
					</div>
					<div class="card-body">
						<ng-container [ngSwitch]="viewing">
							<!-- changed method: todo follow new method -->
							<app-inventory-export *ngSwitchCase="'All Items'" [data]="allitems"></app-inventory-export>
							<ng-container *ngSwitchCase="'Need Bin Location'">
								<app-need-bin-location></app-need-bin-location>
							</ng-container>
							<ng-container *ngSwitchCase="'Negative Stock'">
								<app-negative-items></app-negative-items>
							</ng-container>
							<app-missingpurchdata *ngSwitchCase="'Missing Purchasing Data'" [data]="missingpurch"></app-missingpurchdata>
							<app-missingimages *ngSwitchCase="'Missing Images'"></app-missingimages>
							<app-boms *ngSwitchCase="'boms'" [data]="boms"></app-boms>
							<app-zero-cost *ngSwitchCase="'Zero Cost'" [data]="zerocost"></app-zero-cost>
							<inventory-remote-new-items *ngSwitchCase="'New Available Items'" [data]="remotenewitems"></inventory-remote-new-items>

							<app-item-missingbrands *ngSwitchCase="'Missing Brands'" [data]="missingbrands"></app-item-missingbrands>


						</ng-container>
					</div>
				</div>
			</div>

		</div>
		<div class="row tp-box__side tp-box__front" id="frontside">
			<div class="col-lg-4 col-md-4 col-sm-4">
				<div class="card card-stats">
					<div class="card-header card-header-success card-header-icon">
						<div class="card-icon">
							<i class="material-icons">content_copy</i>
						</div>
						<p class="card-category">All Items</p>
						<h3 class="card-title" *ngIf="allitems != 'notready'">{{ allitems.length }}</h3>
						<span *ngIf="allitems == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg></span>
					</div>
					<div class="card-footer">
						<div class="stats">
							<a (click)="toggleFlip('All Items')"><i class="material-icons">visibility</i> View Now</a>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-md-4 col-sm-4">
				<div class="card card-stats">
					<div class="card-header card-header-warning card-header-icon">
						<div class="card-icon">
							<i class="material-icons">store</i>
						</div>
						<p class="card-category">Need Bin Location</p>
						<h3 class="card-title">
							<span *ngIf="needlocation != 'notready'">{{ needlocation.length }}</span>
							<span *ngIf="needlocation == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg></span>
						</h3>
					</div>
					<div class="card-footer">
						<div class="stats">
							<a class="pointer" (click)="toggleFlip('Need Bin Location')"><i class="material-icons">visibility</i>
								View Now</a>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-md-4 col-sm-4">
				<div class="card card-stats">
					<div class="card-header card-header-success card-header-icon">
						<div class="card-icon">
							<i class="material-icons">content_copy</i>
						</div>
						<p class="card-category">Negative Items</p>
						<h3 class="card-title" *ngIf="negativestock != 'notready'">{{ negativestock.length }}</h3>
						<span *ngIf="negativestock == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg></span>
					</div>
					<div class="card-footer">
						<div class="stats">
							<a (click)="toggleFlip('Negative Stock')"><i class="material-icons">visibility</i> View Now</a>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-md-4 col-sm-4">
				<div class="card card-stats">
					<div class="card-header card-header-danger card-header-icon">
						<div class="card-icon">
							<i class="material-icons">shop_two</i>
						</div>
						<p class="card-category">Missing Purchasing Data</p>
						<h3 class="card-title" *ngIf="missingpurch != 'notready'">{{ missingpurch.length }}</h3>
						<span *ngIf="missingpurch == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg></span>
					</div>
					<div class="card-footer">
						<div class="stats">
							<a (click)="toggleFlip('Missing Purchasing Data')"><i class="material-icons">visibility</i> View Now</a>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-4 col-md-4 col-sm-4" *ngIf="config.env.package == 'tires'">
				<div class="card card-stats">
					<div class="card-header card-header-primary card-header-icon">
						<div class="card-icon">
							<i class="material-icons">image</i>
						</div>
						<p class="card-category">Missing Attributes/Images</p>
						<h3 class="card-title" *ngIf="missingimages != 'notready' && missingimages">
							<div class="missingimages_count">
								<div class="flex_column">
									<div>
										<div class="line-5" *ngIf="missingimages['v4']">{{ missingimages['v4'].length }} </div>
										<mat-label class="line-label">V4</mat-label>
									</div>
								</div>
								<div class="flex_column">
									<div>
										<div class="line-5"  *ngIf="missingimages['v8']">{{missingimages['v8'].length}}</div>
										<mat-label class="line-label">V8</mat-label>
									</div>
								</div>
							</div>
						</h3>
						<span *ngIf="missingimages == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg></span>
					</div>
					<div class="card-footer">
						<div class="stats">
							<a (click)="toggleFlip('Missing Images')"><i class="material-icons">visibility</i> View Now</a>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-4 col-md-4 col-sm-4">
				<div class="card card-stats">
					<div class="card-header card-header-primary card-header-icon">
						<div class="card-icon">
							<i class="material-icons">list_alt</i>
						</div>
						<p class="card-category">BOMs</p>
						<h3 class="card-title" *ngIf="missingimages != 'notready'">
							<!-- empty title info -->
						</h3>
					</div>
					<div class="card-footer">
						<div class="stats">
							<a (click)="toggleFlip('boms')"><i class="material-icons">visibility</i> View Now</a>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-4 col-md-4 col-sm-4">
				<div class="card card-stats">
					<div class="card-header card-header-danger card-header-icon">
						<div class="card-icon">
							<i class="material-icons">attach_money</i>
						</div>
						<p class="card-category">Zero Cost</p>
						<h3 class="card-title" *ngIf="missingimages != 'notready'">{{ zerocost.length }}</h3>
						<span *ngIf="zerocost == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg></span>
					</div>
					<div class="card-footer">
						<div class="stats">
							<a (click)="toggleFlip('Zero Cost')"><i class="material-icons">visibility</i> View Now</a>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-4 col-md-4 col-sm-4">
				<div class="card card-stats">
					<div class="card-header card-header-success card-header-icon">
						<div class="card-icon">
							<i class="material-icons">add_box</i>
						</div>
						<p class="card-category">New Available Items</p>
						<h3 class="card-title" *ngIf="remotenewitems != 'notready'">{{ remotenewitems.length }}</h3>
						<span *ngIf="negativestock == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg></span>
					</div>
					<div class="card-footer">
						<div class="stats">
							<a (click)="toggleFlip('New Available Items')"><i class="material-icons">visibility</i> View Now</a>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-4 col-md-4 col-sm-4">
				<div class="card card-stats">
					<div class="card-header card-header-success card-header-icon">
						<div class="card-icon">
							<i class="material-icons">add_box</i>
						</div>
						<p class="card-category">Missing Brands</p>
						<h3 class="card-title" *ngIf="missingbrands != 'notready'">{{ missingbrands.length }}</h3>
						<span *ngIf="missingbrands == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg></span>
					</div>
					<div class="card-footer">
						<div class="stats">
							<a (click)="toggleFlip('Missing Brands')"><i class="material-icons">visibility</i> View Now</a>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</div>
