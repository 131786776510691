<form [formGroup]="emailForm" (submit)="saveEmailSettings()">
	<div class="col-12 text-right">
		<button mat-stroked-button class="mt-2">
			<mat-icon>save</mat-icon> Save
		</button>
	</div>
	<div class="form-group">
		<label class="col-sm-2 control-label" for="input-mail-engine"><span data-toggle="tooltip" title="" data-original-title="Only choose 'Mail' unless your host has disabled the php mail function.">Mail Engine</span></label>
		<div class="col-sm-10">
			<mat-form-field appearance="fill">
				<mat-label>MAIL Engine</mat-label>
				<input matInput formControlName="config_mail_engine">
			</mat-form-field>
		</div>
	</div>
	<div class="form-group">
		<label class="col-sm-2 control-label" for="input-mail-parameter"><span data-toggle="tooltip" title="" data-original-title="When using 'Mail', additional mail parameters can be added here (e.g. -f email@storeaddress.com)."></span></label>
		<div class="col-sm-10">

			<mat-form-field appearance="fill">
				<mat-label>Mail Parameters</mat-label>
				<input matInput formControlName="config_mail_parameter">
			</mat-form-field>
		</div>
	</div>
	<div class="form-group">
		<div class="col-sm-10">
			<mat-form-field appearance="fill">
				<mat-label>SMTP Hostname</mat-label>
				<input matInput formControlName="config_mail_smtp_hostname">
			</mat-form-field>
		</div>
	</div>
	<div class="form-group">
		<div class="col-sm-10">
			<mat-form-field appearance="fill">
				<mat-label>SMTP Username</mat-label>
				<input matInput formControlName="config_mail_smtp_username">
			</mat-form-field>
		</div>
	</div>
	<div class="form-group">
		<div class="col-sm-10">
			<mat-form-field appearance="fill">
				<mat-label>SMTP Password</mat-label>
				<input matInput formControlName="config_mail_smtp_password">
			</mat-form-field>
		</div>
	</div>
	<div class="form-group">
		<div class="col-sm-10">
			<mat-form-field appearance="fill">
				<mat-label>SMTP Port</mat-label>
				<input matInput formControlName="config_mail_smtp_port">
			</mat-form-field>
		</div>
	</div>
	<div class="form-group">
		<label class="col-sm-2 control-label" for="input-mail-smtp-timeout">SMTP </label>
		<div class="col-sm-10">
			<mat-form-field appearance="fill">
				<mat-label>SMTP Timeout</mat-label>
				<input matInput formControlName="config_mail_smtp_timeout">
			</mat-form-field>
		</div>
	</div>
	<!--
		<legend>Mail Alerts</legend>
		<div class="form-group">
			<label class="col-sm-2 control-label"><span data-toggle="tooltip" title="" data-original-title="Select which features you would like to receive an alert email on when a customer uses them.">Alert Mail</span></label>
			<div class="col-sm-10">
				<div class="well well-sm" style="height: 150px; overflow: auto;">
					<div class="checkbox">
						<label> <input type="checkbox" name="config_mail_alert[]" value="account" checked="checked">
							Register
						</label>
					</div>
					<div class="checkbox">
						<label> <input type="checkbox" name="config_mail_alert[]" value="affiliate">
							Affiliate
						</label>
					</div>
					<div class="checkbox">
						<label> <input type="checkbox" name="config_mail_alert[]" value="order" checked="checked">
							Orders
						</label>
					</div>
					<div class="checkbox">
						<label> <input type="checkbox" name="config_mail_alert[]" value="review">
							Reviews
						</label>
					</div>
				</div>
			</div>
		</div>
	-->
	<div class="form-group">
		<div class="col-sm-10">
			<mat-form-field appearance="fill">
				<mat-label>Additional Alert Mail</mat-label>
				<input matInput formControlName="config_mail_alert_email">
			</mat-form-field>
		</div>
	</div>
</form>
