import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { InventoryService } from 'app/services/inventory.service';

@Component({
	selector: 'app-bestsellers',
	templateUrl: './bestsellers.component.html',
	styleUrls: ['./bestsellers.component.scss']
})
export class BestsellersComponent implements OnInit {
	stockCats: any = false;
	stockBrands: any = false;
	stockLines: any = false;
	filteredCats: any = false;
	filteredLines: any = false;
	filteredBrans: any = false;
	stockItems: any = false;
	items: any;
	searching: any = false;
	@ViewChild('detailsModal') detailsModalEle: ElementRef;

	exclusions: any = {
		categories: [],
		brands: [],
		lines: [],
		items: []
	}


	exclusionsID: any = {
		categories: [],
		brands: [],
		lines: [],
		items: []
	}
	savingRule: any = false;

	constructor(private globalSearchService: GlobalSearchService, private inventoryService: InventoryService, private fb: UntypedFormBuilder, private modalService: NgbModal) {

	}

	ngOnInit(): void {

		this.inventoryService.getItemCategories().subscribe((cats: any) => {
			this.stockCats = cats.sort((a1, a2) => {
				return (a1.categorydescription > a2.categorydescription) ? 1 : (a1.categorydescription < a2.categorydescription) ? -1 : 0;
			});

			this.filteredCats = this.stockCats;
			this.dataReady();
		})

		this.inventoryService.getItemProductlines().subscribe(lines => {
			this.stockLines = lines;
			this.filteredLines = lines;
			this.dataReady();
		})

		this.inventoryService.getMfgs().subscribe(manus => {
			this.stockBrands = manus;
			this.filteredBrans = manus;
			this.dataReady();
		})

	}


	dataReady(){
		if(this.stockBrands && this.stockCats && this.stockLines){
			this.inventoryService.loadBestSellerRule().subscribe((r)=>{
				if(r !== null){
					this.setConfig(r);
				}
			})
		}
	}

	saveRule(){
		const data = {
			rule :JSON.stringify(this.exclusionsID)
		}
		this.savingRule = true;
		this.inventoryService.saveBestSellerRule(data).subscribe((r)=>{
			this.globalSearchService.showNotification('Best Seller Rules Updated', 'success', 'bottom', 'left');
			this.savingRule = false;
		})
	}


	setConfig(r){
		this.exclusionsID = r;

		this.exclusionsID['brands'].forEach(id => {
			var brand = this.stockBrands.find((b)=> b.manufacturer_id === id);
			this.exclusions['brands'].push(brand.name);
		});

		this.exclusionsID['categories'].forEach(id => {
			var brand = this.stockCats.find((b)=> b.categoryid === id);
			this.exclusions['categories'].push(brand.categorydescription);
		});

		this.exclusionsID['lines'].forEach(id => {
			var brand = this.stockLines.find((b)=> b.line_field === id);
			this.exclusions['lines'].push(brand.line_description);
		});

		this.exclusionsID['items'].forEach(item => {
			this.exclusions['items'].push(item);
		});


	}


	addExclusion(type, value){
		switch(type){
			case 'categories':
				this.exclusions[type].includes(value.categorydescription) ? '' : this.exclusions[type].push(value.categorydescription);
				this.exclusionsID[type].includes(value.categoryid) ? '' : this.exclusionsID[type].push(value.categoryid);
			break;
			case 'brands':
				this.exclusions[type].includes(value.name) ? '' : this.exclusions[type].push(value.name);
				this.exclusionsID[type].includes(value.manufacturer_id) ? '' : this.exclusionsID[type].push(value.manufacturer_id);
			break;
			case 'lines':
				this.exclusions[type].includes(value.line_description) ? '' : this.exclusions[type].push(value.line_description);
				this.exclusionsID[type].includes(value.line_field) ? '' : this.exclusionsID[type].push(value.line_field);
			break;
			case 'items':
				this.exclusions[type].includes(value.stockid) ? '' : this.exclusions[type].push(value.stockid);
				this.exclusionsID[type].includes(value.stockid) ? '' : this.exclusionsID[type].push(value.stockid);
			break;
		}


	}

	removeExclusion(type, value){
		switch(type){
			case 'categories':
				this.exclusions[type].splice(this.exclusions[type].indexOf(value.categorydescription), 1);
				this.exclusionsID[type].splice(this.exclusions[type].indexOf(value.categoryid), 1);
			break;
			case 'brands':
				this.exclusions[type].splice(this.exclusions[type].indexOf(value.name), 1);
				this.exclusionsID[type].splice(this.exclusions[type].indexOf(value.manufacturer_id), 1);
			break;
			case 'lines':
				this.exclusions[type].splice(this.exclusions[type].indexOf(value.line_description), 1);
				this.exclusionsID[type].splice(this.exclusions[type].indexOf(value.line_field), 1);
			break;
			case 'items':
				this.exclusions[type].splice(this.exclusions[type].indexOf(value.stockid), 1);
				this.exclusionsID[type].splice(this.exclusions[type].indexOf(value.stockid), 1);
			break;
		}
	}

	removeExclusionBottom(type, value){
		switch(type){
			case 'categories':
				value = this.stockCats.find((cat) =>cat.categorydescription == value);
				this.exclusions[type].splice(this.exclusions[type].indexOf(value.categorydescription), 1);
				this.exclusionsID[type].splice(this.exclusions[type].indexOf(value.categoryid), 1);
			break;
			case 'brands':
				value = this.stockBrands.find((cat) =>cat.name == value);
				this.exclusions[type].splice(this.exclusions[type].indexOf(value.name), 1);
				this.exclusionsID[type].splice(this.exclusions[type].indexOf(value.manufacturer_id), 1);
			break;
			case 'lines':
				value = this.stockLines.find((cat) =>cat.line_description == value);
				this.exclusions[type].splice(this.exclusions[type].indexOf(value.line_description), 1);
				this.exclusionsID[type].splice(this.exclusions[type].indexOf(value.line_field), 1);
			break;
			case 'items':
				this.exclusions[type].splice(this.exclusions[type].indexOf(value), 1);
				this.exclusionsID[type].splice(this.exclusions[type].indexOf(value), 1);
			break;
		}
	}

	searchProd(input: any) {
		if (input.target.value == '') {
			return;
		}
		if (this.searching) {
			this.searching = false;
		}

		const data = {
			stockid: input.target.value
		}

		this.searching = this.inventoryService.itemSearch(data).subscribe((r) => {
			this.items = r;
		})
	}

	filterLists(event){
		const input = event.target.value.toUpperCase();

		if(input != ''){
			this.filteredBrans = this.stockBrands.filter((brand)=>{
				if(brand.mfg_code.includes(input) || brand.name.includes(input)){
					return brand;
				}
			})
			this.filteredCats = this.stockCats.filter((cat)=>{
				if(cat.categorydescription.includes(input)){
					return cat;
				}
			})
			this.filteredLines = this.stockLines.filter((line)=>{
				if(line.line_field.includes(input) || line.line_description.includes(input)){
					return line;
				}
			})
		}else{
			this.filteredBrans = this.stockBrands;
			this.filteredCats = this.stockCats;
			this.filteredLines = this.stockLines;
		}
	}


	itemModal(){
		this.modalService.open(this.detailsModalEle, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {}, (reason) => {

		});
	}

}
