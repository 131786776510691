import { Component, OnInit, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { PrintService } from '../../services/print.service'
import { FormControl, UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
	selector: 'app-trial-balance',
	templateUrl: './trial-balance.component.html',
	styleUrls: ['./trial-balance.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
			transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
		]),
	],
})
export class TrialBalanceComponent implements OnInit {
	@Input() data: any = [];
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('printTable') printTableRed: ElementRef;
	sumdet = 'summary';

	trial_balance_columns: any = [
		'name',
		'debtorno',
		'terms',
		'creditlimit',
		'reasondescription',
		'typename',
		'balance',
		'future',
		'current',
		'due',
		'overdue',
		'overdue1',
		'overdue2',
	];

	po_columns: string[] = [
		'typename_po',
		'transno_po',
		'order__po',
		'trandate_po',
		'daysbeforedue_po',
		'payterms_po',
		'balance_po',
		'future_po',
		'current_po',
		'due_po',
		'overdue1_po',
		'overdue2_po',
		'reasondescription_po',
	];

	trial_balance_columns_expand: any = [...this.trial_balance_columns, 'expand'];
	expandedElement: poElement | null;

	summary_details = new FormControl(false);
	summary_or_details = [
		{ label: 'Summary', value: true },
		{ label: 'Detail', value: false },
	]
	show_details = false;

	matdata: any
	pdf = false;
	hold_data: any = [];
	all: any = [];
	sending = false;
	pageSize = 25;
	total_balance = 0;
	total_current = 0;
	total_due = 0;
	total_overdue1 = 0;
	total_overdue2 = 0;
	total_overdue = 0;
	total_future = 0;
	paginator_show = true;
	arlink: any = ''
	constructor(private globalSearchService: GlobalSearchService, private printService: PrintService) {}

	async ngOnInit(): Promise < void > {

		this.arlink = this.globalSearchService.config;

		this.summary_details.setValue(true);
		this.all = this.data;
		this.hold_data = this.data;
		this.summary_details.valueChanges.subscribe(newvalue => {
			if (!newvalue) {
				this.show_details = true;
			} else {
				this.show_details = false;
			}
		})


		this.updateTotals();

	}

	updateTotals() {
		this.total_balance = this.data.reduce(function(accumulator, c) {
			return accumulator + parseFloat(c.balance);
		}, 0);

		this.total_future = this.data.reduce(function(accumulator, c) {
			return accumulator + parseFloat(c.future);
		}, 0);

		this.total_current = this.data.reduce(function(accumulator, c) {
			return accumulator + parseFloat(c.current);
		}, 0);
		//30
		this.total_due = this.data.reduce(function(accumulator, c) {
			return accumulator + parseFloat(c.due);
		}, 0);
		//60
		this.total_overdue = this.data.reduce(function(accumulator, c) {
			return accumulator + parseFloat(c.overdue);
		}, 0);
		//90
		this.total_overdue1 = this.data.reduce(function(accumulator, c) {
			return accumulator + parseFloat(c.overdue1);
		}, 0);
		//120
		this.total_overdue2 = this.data.reduce(function(accumulator, c) {
			return accumulator + parseFloat(c.overdue2);
		}, 0);
	}

	onInput(value: any) {
		if (value != '') {
			this.data = this.globalSearchService.filterItem(this.all, value, 'name,debtorno,terms');
		} else {
			this.data = this.all
		}

		this.updateTotals();

		this.matdata = new MatTableDataSource(this.data);
		this.matdata.sort = this.sort;
		this.matdata.paginator = this.paginator;

	}

	ngAfterViewInit() {
		this.matdata = new MatTableDataSource(this.data);
		this.matdata.sort = this.sort;
		this.matdata.paginator = this.paginator;

	}

	exportPdf() {
		this.sending = true;
		this.paginator_show = false;
		const today = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.printTableRed.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: 'ar_balance',
			landscape: true,
			title: 'Customer Trial Balance',
			subtitle: today.toDateString(),
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.sending = false;
		});
	}

	exportXls() {
		this.sending = true;
		const encoded: string = this.globalSearchService.base64encode(this.printTableRed.nativeElement.innerHTML);

		const today = new Date();

		const data = {
			content: encoded,
			filename: 'ar_balance',
			title: 'Customer Trial Balance',
			subtitle: today.toDateString(),
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.sending = false;
		});
	}

	announceSortChange(event: any) {
		// alert(event);
		// this.matdata.sort = this.sort;
	}

}

export interface poElement {
	details: Array < any > ;
}
