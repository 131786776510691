import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { InventoryService } from '../../services/inventory.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Location } from '@angular/common'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-inventory-transfers',
	templateUrl: './inventory-transfers.component.html',
	styleUrls: ['./inventory-transfers.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('300ms ease-in')),
			transition('active => inactive', animate('300ms ease-out')),
		]),
	]
})
export class InventoryTransfersComponent implements OnInit {

	transferlist: any = [];
	color = 'blue';
	flip = 'inactive';
	displaydetails: any = '';
	viewedtransactions: any = [];
	statuses: any = [
		{ 'code': '0', 'description': ' Entered ' },
		{ 'code': '1', 'description': ' Released ' },
		{ 'code': '2', 'description': ' Received ' },
		{ 'code': '3', 'description': ' Canceled ' }
	]
	editingtransfer: any = '';
	locations: any = [];
	tranlocations: any = [];
	alllocations: any = [];
	searchForm: UntypedFormGroup;
	config: any = false;
	user: any = [];

	constructor(private location: Location, private fb: UntypedFormBuilder, private modalService: NgbModal, private globalsService: GlobalsService, private globalSearchService: GlobalSearchService, private inventoryService: InventoryService) {
		this.config = this.globalsService.getApiConfig();
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {

		this.inventoryService.getTransferLocations().subscribe((results: any) => {
			if (results) {
				this.tranlocations = results;
			}
		});
		this.globalsService.getUserLocations().subscribe((results: any) => {
			if (results) {
				this.locations = results;
			}
		});

		this.globalSearchService.user.subscribe(result => {
			this.user = result;
		});

		this.globalsService.getAllLocations().subscribe((results: any) => {
			if (results) {
				this.alllocations = results;
			}
		});

		this.searchForm = this.fb.group({
			'fromloc': '',
			'toloc': this.user.user.defaultlocation.loccode,
			'startdate': '',
			'enddate': '',
			'status': '',
			'locchk': true,
		});
		this.getTransfers();
	}

	addTransfer() {
		this.flip = 'active';
	}

	tComp(event) {
		if (event != '') {
			if(event == 'Conflict'){
				this.globalSearchService.showNotification('Transfer Edit ' + event + '', 'danger', 'bottom', 'left');
			}else{
				this.globalSearchService.showNotification('Transfer #' + event + ' Created', 'success', 'bottom', 'left');
			}
		}else{
			this.globalSearchService.showNotification('Changes Saved', 'success', 'bottom', 'left');
		}
		this.editingtransfer = '';
		this.getTransfers();
		this.flip = 'inactive';
	}

	back(): void {
		this.location.back()
	}

	getTransfers() {
		//If to and from are the same, show all transfers for that locations
		if (this.searchForm.get('fromloc').value == this.searchForm.get('toloc').value) {
			this.searchForm.get('locchk').setValue(true);
		} else {
			this.searchForm.get('locchk').setValue(false);
		}
		this.inventoryService.getTransfers(this.searchForm.value).subscribe((results: any) => {
			if (results) {
				this.transferlist = results;
			}
		});
	}

	editTransfer(transno) {
		this.flip = 'active';
		this.editingtransfer = transno;
	}

	openDocument(transaction: any, content: any, display: string) {
		this.viewedtransactions.push(transaction);
		this.globalsService.getTransfer(transaction.transno, display).subscribe((result: any) => {
			this.displaydetails = result;
			this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
		});
	}

	cancelTransfer(transfer: any) {
		this.inventoryService.cancelTransfer(transfer).subscribe((res) => {
			this.getTransfers();
		})
	}

	reload() {
		this.getTransfers();
	}

	reset() {
		this.flip = this.flip == 'inactive' ? 'active' : 'inactive';
		this.editingtransfer = false;

	}


}
