import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AppConfig } from '../app.config';
import { GlobalSearchService } from './globalsearchservice.service';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
@Injectable({
	providedIn: 'root'
})
export class ConfigService {
	private config: any;
	private availableConfigs: string[];
	private currentConfig: string = environment.name;
	public localStorageKey: string = 'v8ConfigOverride';
	private defaultConfig: string = `assets/config/config.${environment.name}.json`;
	constructor(private appConfig: AppConfig, private _configSvc: RuntimeConfigLoaderService, private http: HttpClient) {

	}

	loadConfig(configName: string): Observable < any > {
		let jsonFile = `assets/config/config.${environment.name}.json`;

		if (configName !== environment.name) {
			jsonFile = `assets/config/config.${configName}.json`;
		}

		return this.http.get(jsonFile).pipe(
			map(config => {
				if (this.isValidConfig(config)) {
					return config;
				} else {
					return this.getDefaultConfig();
				}
			}),
			tap(config => {
				this.config = config;
				this.currentConfig = configName;
				try {
					localStorage.setItem(this.localStorageKey, JSON.stringify(config));
				} catch (e) {
					console.log(e)
				}
			}),
			catchError(error => {
				return of(this.getDefaultConfig());
			})
		);
	}

	private isValidConfig(config: any): boolean {
		if (!config) {
			//'Configuration is null or undefined.');
			return false;
		}
		if (typeof config.apiCredentials.clientId !== 'string') {
			//'Invalid or missing "clientId":', config.apiCredentials.clientId);
			return false;
		}
		if (!config.env || typeof config.env.package !== 'string') {
			//'Invalid or missing "env.package":', config.env);
			return false;
		}
		return true;
	}

	private getDefaultConfig(): any {
		this.config = this.defaultConfig;
		const defaultConfig = this.defaultConfig;
		this.currentConfig = environment.name;
		try {
			localStorage.setItem(this.localStorageKey, JSON.stringify(defaultConfig));
		} catch (e) {}
		return defaultConfig;
	}

	getConfig(): any {
		return this.config;
	}

	loadAvailableConfigs(): Observable < string[] > {
		return this.http.get < string[] > ('assets/config/config-list.json').pipe(
			tap(configs => {
				this.availableConfigs = configs;
			})
		);
	}

	getAvailableConfigs(): string[] {
		return this.availableConfigs;
	}

	getCurrentConfig(): string {
		return this.currentConfig;
	}
}
