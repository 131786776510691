import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
	selector: '[appNumericField]'
})
export class NumericFieldDirective {

	acceptedCharacters: string[] = [
		'-', '.', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab',
		'ArrowRight', 'ArrowLeft', 'Delete', 'Home', 'End', 'Shift', ' '
	];

	constructor(private el: ElementRef, private renderer: Renderer2) {}

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		// Allow space key only if the input is empty or deleting the entire value
		if (event.key === ' ' && this.el.nativeElement.value.length === 0) {
			return;
		}

		// Allow navigation and shortcut keys
		if (
			event.ctrlKey || event.metaKey ||
			this.acceptedCharacters.includes(event.key) ||
			event.key.startsWith('Arrow') ||
			event.key === 'Tab'
		) {
			return;
		}

		// Prevent any other input
		event.preventDefault();
		this.highlightInvalidInput();
	}

	@HostListener('paste', ['$event'])
	onPaste(event: ClipboardEvent) {
		const pastedText = event.clipboardData.getData('text');
		if (!/^-?\d*\.?\d*$/.test(pastedText)) {
			event.preventDefault();
			this.highlightInvalidInput();
		}
	}

	private highlightInvalidInput() {
		this.renderer.setStyle(this.el.nativeElement, 'border-color', 'red');
		setTimeout(() => {
			this.renderer.removeStyle(this.el.nativeElement, 'border-color');
		}, 500);
	}
}