import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, Injectable, ElementRef, Output, EventEmitter } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { DataService } from '../../../data.service';
import { ChatService } from '../../../services/chat.service';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { TableModule } from 'primeng/table';

import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

import { Message } from '../../../classes/message';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { UsersService } from '../../../services/users.service';
import { InventoryService } from '../../../services/inventory.service';
import { PrintService } from '../../../services/print.service'
import { trigger, state, style, transition, animate } from '@angular/animations';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-transfers-release',
	templateUrl: './transfers-release.component.html',
	styleUrls: ['./transfers-release.component.scss']
})
export class TransfersReleaseComponent implements OnInit {

	@ViewChild('binSelect') releaseRef: ElementRef;
	@Output('refreshTransfers') refreshTransfers = new EventEmitter < any > ();
	transferlist: any = [];
	color = 'blue';
	locations: any = [];
	user: any = [];
	transferData: any = {};
	items: any = [];
	toaddress: any = '';
	fromaddress: any = '';
	isLoading: boolean = false;
	statuses: any = [{ 'code': '0', 'description': ' Entered ' },
		{ 'code': '1', 'description': ' Released ' },
		{ 'code': '2', 'description': ' Received ' },
		{ 'code': '3', 'description': ' Canceled ' }
	]
	searchForm: UntypedFormGroup;
	constructor(private fb: UntypedFormBuilder, private globalsService: GlobalsService, private globalSearchService: GlobalSearchService, private inventoryService: InventoryService, private modalService: NgbModal) {}

	ngOnInit(): void {

		this.globalsService.getUserLocations().subscribe((results: any) => {
			if (results) {
				this.locations = results;
			}
		});

		this.globalSearchService.user.subscribe(result => {
			this.user = result;
		});
		this.searchForm = this.fb.group({
			'fromloc': this.user.user.defaultlocation.loccode,
			'toloc': '',
			'startdate': '',
			'enddate': '',
			'status': '0'
		});

		this.getTransfers();
	}

	updateBinQuantity(event: any, item: any) {
		const index = this.items.indexOf(item);
		this.items[index].quantity = event.target.value;
	}
	getTransfers() {

		this.inventoryService.getTransfers(this.searchForm.value).subscribe((results: any) => {
			if (results) {
				this.transferlist = results;
			}
		});
	}

	getTransferData(transno) {
		this.isLoading = true;
		this.inventoryService.getTransferData({ transno: transno, type: 'REL' }).subscribe((results: any) => {
			if (results) {
				// Transfer header and line items
				this.transferData = results;
				this.items = results.lines;

				// Construct the "to" and "from" address using transfer header
				const header = results.transferheader;
				this.toaddress = `${header.locationname}\n${header.deladd1}\n${header.deladd3} ${header.deladd4}, ${header.deladd5}`;
				this.fromaddress = `${header.shipname}\n${header.shipadd1}\n${header.shipcity} ${header.shipstate}, ${header.shipzip}`;

				// Filter bin quantities for each item
				// this.items = this.items.map(item => {
				// 	const matchedBin = item.bins.find(bin => bin.bin === item.bincode);
				// 	item.binqoh = matchedBin ? matchedBin.maxqty : 0;
				// 	return item;
				// });
				this.isLoading = false;
				this.modalService.open(this.releaseRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then(
					(result) => {
						// Handle modal success case
					},
					(reason) => {
						// Handle modal dismiss case
					}
				);
			}
		});

	}
	parseF(val: any) {

		return parseFloat(val);
	}

	// Method to get status description based on code
	getStatusDescription(status: string): string {
		const foundStatus = this.statuses.find(s => s.code === status);
		return foundStatus ? foundStatus.description : 'Unknown';
	}

	// Method to get the status class for styling
	getStatusClass(status: string): string {
		switch (status) {
			case '0': return 'entered';
			case '1': return 'released';
			case '2': return 'received';
			case '3': return 'cancelled';
			default: return '';
		}
	}

	releaseItems(items) {

		const data = { 'transno': this.transferData.transferheader.transno, 'fromstkloc': this.transferData.transferheader.fromstkloc, lines: items };

		this.inventoryService.releaseTransfer(data).subscribe((results: any) => {
			if (results.success == true) {
				this.globalSearchService.showNotification('Transfer Released', 'success', 'bottom', 'left');
				this.modalService.dismissAll();
				this.getTransfers();
				this.refreshTransfers.emit();
			}
		});
	}

	toggleAll(event: any) {
		const isChecked = event.checked;
		this.items.forEach(item => {
			item.selected = isChecked;
			if (isChecked) {
				item.savedqty = item.quantity;
				item.quantity = item.transferqty;

			} else {
				item.quantity = (item.savedqty) ? item.savedqty : 0;
			}
		});
	}

}