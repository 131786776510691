<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card tp-box">
					<div class="card-header card-header-{{ color }}-6">
						<div class="row m-0 p-0">
							<div class="col-12">
								<h4 class="card-title bold">
									<mat-icon (click)="back()">arrow_left</mat-icon>
									New Purchase Order: {{ vendorFormGroup.get('suppname').value }}
								</h4>
							</div>
						</div>
					</div>
					<div class="card-body" *ngIf="vendorFormGroup ">
						<ng-container *ngIf="vendorinfo && !complete">
							<app-shared-purchase-order-entry [vendor]="vendor_data" [flip]="'inactive'" [invoicing]="invoicing" (reloadVendor)="loadVendor($event.header.supplierno)" class="w-100 no-overflow"></app-shared-purchase-order-entry>

						</ng-container>
						<ng-container *ngIf="complete">
							<div class="card-body">
								<div class="row">
									<div class="col-12 text-center">
										<h4 class="bold">{{ summary.message }}</h4>
										<div class="row">
											<div class="col-md-3 mt-2 mb-2 text-center">
												<a mat-raised-button color="warn" href="{{baselink}}{{ponumber}}">
													Print Purchase Order</a>
											</div>
											<div class="col-md-3 mt-2 mb-2 text-center">
												<a mat-raised-button color="warn" href="{{recdoc}}{{ponumber}}">
													Print Receiving Document</a>
											</div>
											<div class="col-md-3 mt-2 mb-2 text-center">
												<a [routerLink]="'/purchasing/receive'" mat-raised-button color="accent" class="ml-2 mr-2">Receive</a>
											</div>
											<div class="col-md-3 mt-2 mb-2 text-center">
												<button mat-raised-button color="success" [routerLink]="'/purchasing'">New Order</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
