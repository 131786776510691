<div class="main-content">
	<div class="row m-0 w-100 bay-container">
		<ng-container *ngFor="let bay of bays | keyvalue">
			<div class="card p-3" [ngClass]="(bay.value.removing.status ? ' bayRemoveMode ' : '') + '' + (bayTwoInactive ? 'col-5 bay2' : 'col-4 bay3')">
				<div class="row p-0 m-0">
					<div class="col-4">
						<h2 (click)="resetLoads(bay.value)" class="text-nowrap">BAY {{bay.value.id}}
							<span *ngIf="bay.value.finalizing"> FINALIZING... </span>
							<span *ngIf="bay.value.removing.status" class="align-middle text-white bg-danger p-1 rounded-pill indicator">REMOVING</span>
						</h2>
						<h4 *ngIf="bay.value.device.serial" class="text-nowrap">Device #: {{bay.value.device.serial}}</h4>
					</div>

					<div class="col-8 text-right" *ngIf="bay.value.total_items">
						<h4 class="font-weight-light med-normal-font">
							<span (click)="complete_shortcut(bay.value)">{{bay.value.truck.name}}</span>
							<ng-container *ngIf="bay.value.truck.driver">
								({{bay.value.truck.driver}})
							</ng-container>
						</h4>
						<h2 class="font-weight-bold">{{bay.value.scans / bay.value.total_items | percent}}</h2>
					</div>

					<ng-container *ngIf="!bay.value.error.status && !bay.value.finalizing">
						<mat-progress-bar class="mb-5 rounded bigger-bar" mode="determinate" [value]="(bay.value.scans / bay.value.total_items) * 100"></mat-progress-bar>
					</ng-container>

					<ng-container *ngIf="!bay.value.error.status && bay.value.finalizing">
						<mat-progress-bar class="mb-5 rounded bigger-bar" mode="indeterminate" ></mat-progress-bar>
					</ng-container>

					<ng-container *ngIf="bay.value.error.status">
						<div class="p-0 m-25 w-100 med-normal-font text-center rounded-pill bg-danger text-white errorMessage">
							<h3 class="m-0">{{bay.value.error.message}}</h3>
						</div>
					</ng-container>

				</div>
				<div class="w-100 lastremove">
					<div class="w-100 display-4 bg-danger text-white rounded-pill pad-10 text-xl-center" *ngIf="bay.value.removing.last && bay.value.removing.status" >
						<h3 class="med-bold-font lh-5">Last remove: {{bay.value.removing.last}}</h3>
					</div>
					<!-- <div class="row">
						<div class="col-10 m-0 p-0">
							<mat-form-field appearance="outline">
								<mat-label>scan tester</mat-label>
								<input matInput placeholder="" [(ngModel)]="bay1input">
							</mat-form-field>
						</div>
						<div class="col-1 p-0 m-0"><button class="w-100" (click)="sendScan(bay.value)">scan</button></div>
					</div> -->
				</div>
				<ng-container *ngIf="!bay.value.completing">
					<ul class="m-0 p-0 list-unstyled" *ngIf="bay.value.dispatch.length > 1 && !bay.value.completing">
						<li *ngFor="let ord of bay.value.dispatch; let top = index">
							<ng-container *ngIf="ord.ordertype != 0">
								<!-- active scan card -->
								<div class="card card-spacing" *ngIf="top == 1;" >
									<div class="card-header card-header-{{color}}-6">
										<span class="float-left">{{ord.header.brname}}</span>
										<span class="float-right font-weight-bolder xl-bold-font">#{{ord.header.orderno}}</span>
									</div>
									<div class="card-body">
										<ul class="p-0 m-0 list-unstyled">
											<li *ngFor="let i of ord.orderdetails;">
												<ul class="entry-list m-0 p-0 larger-font display-4">
													<ng-container *ngIf="i.qtyloaded < i.quantity">
														<li>
														<div class="spec-label w-25 med-bold-font" (click)="removePick(bay.value, ord, i)">ITEM#</div>
														<div class="spec-value w-75 fw-500 xl-bold-font">
															<span class="pull-left w-100">
																{{i.stkcode}}
																<span class="pull-right qty-span text-white" [ngClass]="i.qtyloaded == 0 || i.qtyloaded == undefined ? 'bg-danger' : i.qtyloaded < i.quantity ? 'bg-warning': i.qtyloaded == i.quantity ? 'bg-success' : 'bg-danger'" (click)="addPick(bay.value, ord, i)">{{i.qtyloaded}}/{{i.quantity}}</span>
																<br>
																<div>
																	<ng-container *ngIf="i.stkcode != 'MST' && i.stkcode && ord.iteminfo[i.stkcode]">
																		<span class="pull-left pl-1 med-normal-font">{{ord.iteminfo[i.stkcode].mfg}}</span>
																	</ng-container>
																	<ng-container *ngIf="i.stkcode == 'MST'">
																		<span class="pull-left pl-1 med-normal-font ">PART#:&nbsp;{{getSuppPartno(i.po_options)}}</span>
																	</ng-container>
																	<br>
																	<span class="pull-left pl-1 med-normal-font">{{i.itemdesc}}</span>
																	<br>
																	<span class="pull-left pl-1 smal-normal-font bin-span align-middle">{{i.bin}}&nbsp;<mat-icon class="bin-icon align-bottom">inventory_2</mat-icon></span>
																</div>
															</span>

														</div>
														</li>
													</ng-container>
												</ul>
											</li>
											<!-- loaded but order not fullfilled -->
											<li *ngFor="let i of ord.orderdetails">
												<ul class="entry-list m-0 p-0 larger-font display-4">
													<li>
													<ng-container *ngIf="i.qtyloaded == i.quantity">
														<div class="spec-label w-25 med-bold-font">ITEM#</div>
														<div class="spec-value w-75 fw-500 med-bold-font green-full opacity-half">
															<span class="pull-left">{{i.stkcode}}</span>
															<span class="pull-right"><mat-icon class="align-middle">local_shipping</mat-icon>&nbsp;{{i.qtyloaded}}/{{i.quantity}}</span>
														</div>
													</ng-container>
													</li>
												</ul>
											</li>
										</ul>
									</div>
								</div>
								<!-- next ups runs off of index of list-->
								<div class="card card-spacing opacity-half" *ngIf="top > 1">
									<div class="card-body row d-flex justify-content-between large-font ml-1 mr-1">
										<div class="d-inline-block font-weight-bolder">#{{ord.header.orderno}}</div>
										<div class="d-inline-block">{{ord.header.brname}}</div>
										<div class="d-inline-block">{{ord.totalqty}}</div>
									</div>
								</div>

							</ng-container>
						</li>
					</ul>
					<!-- fulfilled scan cards -->
					<ng-container *ngIf="bay.value.scans >= bay.value.total_items && bay.value.scans != 0 && bay.value.dispatch.length == 2">
						<div class="rounded raised-zindex" [ngClass]="bay.value.hasoverscan ? 'bg-warning text-black' : 'bg-success text-white'">
							<h4 class="p-2 font-weight-bold display-3 text-center">RUN LOADED</h4>
						</div>
					</ng-container>
					<ul class="m-0 p-0 list-unstyled scrollable" *ngIf="bay.value.dispatch || bay.value.fulfilledOrds">
						<ng-container *ngFor="let ord of bay.value.fulfilledOrds">
							<div class="card card-spacing opacity-half " *ngIf="ord.ordertype != '11'" [ngClass]="ordScanAmount(ord) > calcExpectedScans([ord]) ? 'overscan': 'green-full'">
									<div class="card-body row d-flex justify-content-between large-font ml-1 mr-1" >
										<!-- <div class="col-1 d-inline-block"><mat-icon class="text-success">local_shipping</mat-icon></div> -->
										<div class="col-7 flex-align-center text-left">
											<div class="w-100">
												<div class="d-inline-block font-weight-bolder">#{{ord.header.orderno}}</div>
											</div>
											<div class="w-100">
												<div class="d-inline-block">{{ord.header.brname}}</div>
											</div>
										</div>


										<div class="col-5 flex-align-center text-right ">
											<div class="w-100">
												<div class="d-inline-block text-right font-heavy">
													<ng-container *ngFor="let item of ord.orderdetails">
														{{item.stockid}}<br>
													</ng-container>
												</div>
											</div>
											<div class="w-100 ">
												<div class="d-inline-block text-right"><mat-icon class="align-middle">local_shipping</mat-icon> {{ordScanAmount(ord)}} / {{calcExpectedScans([ord])}} </div>
											</div>
										</div>
									</div>
								</div>
						</ng-container>
					</ul>
					<div *ngIf="!bay.value.dispatch">
						<div class="m-auto">No Dispatch active</div>
					</div>
				</ng-container>
					<ng-container *ngIf="bay.value.completing">
						<div class='col-12 text-center med-bold-font'><h4>Overscans present. Complete anyways?</h4></div>
						<ng-container *ngFor="let ord of bay.value.fulfilledOrds">
							<div class="card card-spacing opacity-half overscan" *ngIf="ord.ordertype != '11' && ordScanAmount(ord) > calcExpectedScans([ord])">
									<div class="card-body row d-flex justify-content-between large-font ml-1 mr-1" >
										<!-- <div class="col-1 d-inline-block"><mat-icon class="text-success">local_shipping</mat-icon></div> -->
										<div class="col-7 flex-align-center text-left">
											<div class="w-100">
												<div class="d-inline-block font-weight-bolder">#{{ord.header.orderno}}</div>
											</div>
											<div class="w-100">
												<div class="d-inline-block">{{ord.header.brname}}</div>
											</div>
										</div>


										<div class="col-5 flex-align-center text-right ">
											<div class="w-100">
												<div class="d-inline-block text-right font-heavy">
													<ng-container *ngFor="let item of ord.orderdetails">
														{{item.stockid}}<br>
													</ng-container>
												</div>
											</div>
											<div class="w-100 ">
												<div class="d-inline-block text-right"><mat-icon class="align-middle">local_shipping</mat-icon> {{ordScanAmount(ord)}} / {{calcExpectedScans([ord])}} </div>
											</div>
										</div>
									</div>
								</div>
						</ng-container>
						<div class="col-12 d-flex text-center" *ngIf="!bay.value.finalizing">
							<div class="col-5">
								<button class="w-100" mat-raised-button color="secondary" (click)="bay.value.completing = false">Go back</button>
							</div>
							<div class="col-2"></div>
							<div class="col-5">
								<button class="w-100" mat-raised-button color="primary" (click)="completeBay(bay.value, true)">Confirm</button>
							</div>
						</div>
					</ng-container>
			</div>
		</ng-container>

		<!-- <button (click)="discover()">discover</button> -->
	</div>
</div>