import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Output, EventEmitter, Pipe, PipeTransform, Renderer2, AfterViewInit, OnChanges, SimpleChanges, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate, animation } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss']
})
export class ItemDetailsComponent {
	@Input() item_details: any = [];
	cleanAndFormatHTML(htmlContent: string): string {
		// Clean up the HTML content
		const cleanedHTML = htmlContent
			// Remove excessive white spaces
			.replace(/\s{2,}/g, ' ')
			// Remove extra line breaks
			.replace(/\n\s*\n/g, '\n')
			// Trim leading and trailing white spaces
			.trim();

		// Format the HTML content nicely
		let formattedHTML = '';
		const parser = new DOMParser();
		const doc = parser.parseFromString(cleanedHTML, 'text/html');
		if (doc.body) {
			formattedHTML = doc.body.innerHTML;
		}

		return formattedHTML;
	}
}
