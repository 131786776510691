<div class="fixed-plugin" *ngIf="active">
    <div class="dropdown show-dropdown closed show">
        <a href="#" data-toggle="dropdown" aria-expanded="true" (click)="resetCount()" [ngClass]="messagecount > 0 ? 'text-danger' : ''">
            <i class="material-icons" *ngIf="messagecount">message</i>
            <i class="material-icons" *ngIf="!messagecount">chat_bubble_outline</i>
            <span class="notification text-danger" *ngIf="messagecount">{{ messagecount }}</span>
        </a>
        <ul class="dropdown-menu " x-placement="bottom-start" style="position: absolute; top: 41px; left: -231px; will-change: top, left;">
            <!--<li class="button-container text-center m-0 p-0" routerLinkActive="active"> -->
                <app-chatbox></app-chatbox>
            <!--</li>-->
            <!--<li class="header-title"></li>
            <li class="adjustments-line">
                <a href="javascript:void(0)" class="switch-trigger active-color">
                    <div class="ml-auto mr-auto">
                        <span class="badge filter badge-purple" data-color="purple"></span>
                        <span class="badge filter badge-azure" data-color="azure"></span>
                        <span class="badge filter badge-green" data-color="green"></span>
                        <span class="badge filter badge-orange" data-color="orange"></span>
                        <span class="badge filter badge-danger active" data-color="danger"></span>
                    </div>
                    <div class="clearfix"></div>
                <div class="ripple-container"></div></a>
            </li>
		-->

        </ul>
    </div>
</div>
