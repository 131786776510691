import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { Location } from '@angular/common'
import { InventoryService } from '../../../services/inventory.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { OrdersService } from '../../../services/orders.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service'
import { PrintService } from '../../../services/print.service'

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
import * as moment from 'moment';

@Component({
	selector: 'app-bin-list',
	templateUrl: './bin-list.component.html',
	styleUrls: ['./bin-list.component.scss']
})
export class BinListComponent implements OnInit {
	@Input() bins;
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	@ViewChild('bindetails') bindetails: ElementRef;
	@ViewChild('printtable') printtable: ElementRef;


	bin_data: any = [];
	showbin: any = false;
	binitems: any = false;
	allbinitems: any = false;
	onhandonly = false;
	filtered_items: any = false;

	greater_than: any = -1 ;
	less_than: any= -1 ;
	max: any;
	min: any;

	headercolumns: any = [
		  'location',
		  'count',
		  'active_items',
		  'capacity',
		  'totalitems'
	];

	binsearch: any = [];

	constructor(public printService: PrintService, public globalSearchService: GlobalSearchService, private modalService: NgbModal,private route: ActivatedRoute, private formBuilder: UntypedFormBuilder,public router: Router, private location: Location, public inventoryService: InventoryService, public orderService: OrdersService) { }

	ngOnInit(): void {

		this.bin_data = this.bins

		const minmax = this.bins.reduce(
			(accumulator, bin) => {
				return [
					Math.min(bin.active_items, accumulator[0]),
					Math.max(bin.active_items, accumulator[1])
				];
			}, [Number.MAX_VALUE, Number.MIN_VALUE]
		);

		if(minmax) {
			this.min = minmax[0];
			this.max = minmax[1];
			this.greater_than = minmax[0];
			this.less_than = minmax[1];
		}

		this.bins = new MatTableDataSource(this.bins);
		this.bins.sort = this.sort;
		this.bins.paginator = this.paginator;


	}

	updateItems(qohonly: any) {
		if(qohonly.checked) {
			this.binitems = this.allbinitems.filter((item:any) => {
				return parseFloat(item.qoh) > 0
			});
		} else {
			this.binitems = this.allbinitems;
		}
	}

	announceSortChange(event: any) {

	}

	openModal(content) {
		this.showbin = true;
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
		}, (reason) => {
			this.showbin = false;
		});
	  }

	binSearch(event: any) {

		const results = this.filterItem(this.bin_data, event.target.value, 'location');
		this.bins = new MatTableDataSource(results);
		this.bins.sort = this.sort;
		this.bins.paginator = this.paginator;
	}

	applyQtyFilter(value: any) {

		if(this.greater_than == '') {
			this.greater_than = this.min
		}

		if(this.less_than == '') {
			this.less_than = this.max
		}

		this.greater_than = parseFloat(this.greater_than);
		this.less_than = parseFloat(this.less_than);

		let results = []
		if(this.less_than >=0 ) {
			results = this.bin_data.filter((bin: any) => {
				return parseFloat(bin.active_items) <= this.less_than;
			});
		}

		if(this.greater_than >=0 ) {
			let gdata = this.bin_data;
			if(results.length) {
				gdata = results;
			}

			results = gdata.filter((bin: any) => {
				return parseFloat(bin.active_items) >= this.greater_than;
			});
		}

		this.bins = new MatTableDataSource(results);
		this.bins.sort = this.sort;
		this.bins.paginator = this.paginator;

	}

	showBinModal(bin) {
		this.binitems = [];

		this.inventoryService.getBinItems(bin).subscribe((results: any) => {
			this.binitems = results
			this.allbinitems = results
			this.showbin = bin;
			this.openModal(this.bindetails);
		});

	}

	filterItem(input, term , keys ){

		const result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) &&	new RegExp(term, 'gi').test(item[key])));
		return result
	}

	exportExcel(name: any, data: any) {
		//much simpler
		//var table_elt = document.getElementById(id);
		const worksheet = XLSX.utils.json_to_sheet(data);

		const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
		//var workbook = XLSX.utils.table_to_book(table_elt);
		const ws = workbook.Sheets['Sheet1'];
		XLSX.utils.sheet_add_aoa(ws, [[name+' '+new Date().toISOString()]], { origin:-1 });
		XLSX.writeFile(workbook, name+' '+new Date()+'.xlsx');
	}

	exportPdf() {

		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: 'bins',
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}


	ngAfterViewInit() {
		this.bins.sort = this.sort;
		this.bins.paginator = this.paginator;
	}
}
