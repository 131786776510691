import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { BarcodeScannerLivestreamModule, BarcodeScannerLivestreamOverlayModule } from 'ngx-barcode-scanner';


import { OrdersComponent } from './orders/orders.component';
import { QantelOrderDashComponent } from './qantel-order-dash/qantel-order-dash.component';
import { QantelItemSearchComponent } from './qantel-item-search/qantel-item-search.component';
import { QantelItemViewComponent } from './qantel-item-view/qantel-item-view.component';
import { QantelItemBarcodeAddComponent } from './qantel-item-barcode-add/qantel-item-barcode-add.component';

import { SharedModule } from '../shared/shared.module';
import { QantelOrderItemsComponent } from './qantel-order-items/qantel-order-items.component'

@NgModule({
	declarations: [
		OrdersComponent,
		QantelOrderDashComponent,
		QantelItemSearchComponent,
		QantelItemViewComponent,
		QantelItemBarcodeAddComponent,
		QantelOrderItemsComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		ReactiveFormsModule,
		FormsModule,
		ScrollingModule,
		MatToolbarModule,
		MatButtonModule,
		MatDialogModule,
		MatDividerModule,
		MatInputModule,
		MatTableModule,
		MatCheckboxModule,
		MatSortModule,
		MatPaginatorModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatExpansionModule,
		MatSelectModule,
		MatMenuModule,
		MatIconModule,
		MatSlideToggleModule,
		MatStepperModule,
		MatListModule,
		MatTabsModule,
		MatDividerModule,
		SharedModule,
		BarcodeScannerLivestreamModule,
		BarcodeScannerLivestreamOverlayModule
	],
	exports:[
		QantelItemBarcodeAddComponent
	]
})
export class QantelModule {}
