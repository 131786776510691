<div class="main-content main">
    <div class="mat-elevation-z2 report-div">
        <mat-progress-bar mode="determinate" value="{{prgoress}}"></mat-progress-bar>
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" [(selectedIndex)]="matIndex" (selectedTabChange)="run_report($event);" class="mat-tab-row">
            <mat-tab label="NSRB" class="tab-cell">
                <div class="col-md-12 report-container" *ngIf="reportdata">
                    <mat-table #table [dataSource]="datasource">
                        <ng-container *ngFor="let col of displayedColumns">
                            <ng-container matColumnDef="{{col}}">
                                <mat-header-cell *matHeaderCellDef>{{col}}</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element[col]}} </mat-cell>
                            </ng-container>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns" class="fc-black"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                </div>
            </mat-tab>
            <mat-tab label="RECDOC" class="tab-cell">
                <div class="col-md-12 report-container" *ngIf="reportdata">
                    <mat-table #table [dataSource]="datasource">
                        <ng-container *ngFor="let col of displayedColumns">
                            <ng-container matColumnDef="{{col}}">
                                <mat-header-cell *matHeaderCellDef>{{col}}</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element[col]}} </mat-cell>
                            </ng-container>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns" class="fc-black"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                </div>
            </mat-tab>
            <mat-tab label="NEEDS" class="tab-cell">
                <div class="col-md-12 report-container" *ngIf="reportdata">
                    <mat-table #table [dataSource]="datasource">
                        <ng-container *ngFor="let col of displayedColumns">
                            <ng-container matColumnDef="{{col}}">
                                <mat-header-cell *matHeaderCellDef>{{col}}</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element[col]}} </mat-cell>
                            </ng-container>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns" class="fc-black"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                </div>
            </mat-tab>
            <mat-tab label="CREDIT ITEMS" class="tab-cell">
                <div class="col-md-12 report-container" *ngIf="reportdata">
                    <mat-table #table [dataSource]="datasource">
                        <ng-container *ngFor="let col of displayedColumns">
                            <ng-container matColumnDef="{{col}}">
                                <mat-header-cell *matHeaderCellDef>{{col}}</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element[col]}} </mat-cell>
                            </ng-container>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns" class="fc-black"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                </div>
            </mat-tab>
            <mat-tab label="PICKABLE ORDERS" class="tab-cell">
                <div class="spinnerdiv" *ngIf="reportrunning">
                    <span>
                        <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
                            </circle>
                        </svg>
                    </span>
                </div>
                <div class="col-md-12 report-container" *ngIf="reportdata">
                    <mat-table #table [dataSource]="datasource" *ngIf="!reportrunning">
                        <ng-container *ngFor="let col of displayedColumns">
                            <ng-container matColumnDef="{{col}}">
                                <mat-header-cell *matHeaderCellDef>{{col}}</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element[col]}} </mat-cell>
                            </ng-container>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns" class="fc-black"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
