<div class="main-content">
	<div class="container-fluid">
		<div class="col-md-12">
			<div class="card">
				<div class="card-header card-header-{{color}}-5">
					<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Load Sheet</h4>
					<p class="card-category"></p>
					<div class="messages"></div>
				</div>

				<div class="w-100">
					<mat-tab-group mat-stretch-tabs mat-align-tabs="end" color="accent" (selectedIndex)="loadshhetrunsheet">
						<mat-tab label="Load Sheet">
							<div class="card-body">
								<div class="row mb-3">
									<ng-container *ngIf="user.user.dispatchadmin == '1'">
										<div *ngIf="truck.value" class="col-md-3">
											&nbsp;&nbsp;&nbsp;
												<button mat-raised-button color="primary" (click)="loadAll(truck.value)" style="visibility:hidden">
													<span>Mark All Loaded </span>
												</button>
										</div>
									</ng-container>
									<div [ngClass]="user.user.dispatchadmin == '1' ? 'col-md-3': 'col-md-6'">
										<mat-form-field class="full-width text-right" appearance="outline" color="accent">
											<mat-label>Select Truck</mat-label>
											<input type="text" placeholder="Select a Truck" aria-label="Truck" matInput [formControl]="truck" [matAutocomplete]="truckSelect" (input)="truckFilter($event.target.value)">
											<mat-autocomplete #truckSelect="matAutocomplete"
											(optionSelected)="loadRun($event.option.value)"
											[displayWith]="displayFn">
												<mat-option [value]="''">All</mat-option>
												<mat-option *ngFor="let truck of trucks" [value]="truck">
													{{truck.name }}
												</mat-option>
											</mat-autocomplete>
										</mat-form-field>
									</div>
									<div class="col-md-6">
										<mat-form-field appearance="outline">
											<mat-label>Scan/Search</mat-label>
											<input tabindex="0" autofocus autocomplete="off" aria-hidden="false" matInput [formControl]="itemScanCtrl" value="" class="text-right" (keyup.enter)="scanItem()">
											<span matSuffix>
												<button mat-icon-button color="white" (click)="scanItem()">
													<mat-icon>search</mat-icon>
												</button>
											</span>
											<span matSuffix *ngIf="itemScanCtrl.value != ''">
												<button mat-icon-button color="white" (click)="itemScanCtrl.setValue('')">
													<mat-icon>clear</mat-icon>
												</button>
											</span>
										</mat-form-field>
									</div>
									<div class="col-6 col-md-1" *ngIf="!isdriver">
										<a mat-raised-button color="white" href="{{delsheetlink}}{{truck.value.id}}" *ngIf="active_dispatches.length">
											<i class="fa fa-file-pdf-o"></i> PDF
										</a>
									</div>
									<ng-container *ngIf="!isdriver">
										<div class="col-6 col-md-1 text-right text-md-left" >
											<button mat-raised-button color="white" (click)="exportLoadSheetXls()">
												<i class="fa fa-file-excel-o"></i> XLS
											</button>
										</div>
									</ng-container>
									<div class="col-6 text-left">
										<b>ITEMS:</b>{{ total_items }}
									</div>
									<div class="col-6 text-right">
										<span [ngClass]="total_order_volume > total_capacity ? 'text-danger': ''">
											<b>CAPACITY:</b> {{ total_order_volume | number : '1.2-2' }} / {{ total_capacity | number : '1.2-2' }}
										</span>
									</div>
								</div>
							</div>
							<div class="" id="printableRunSheet" #printableRunSheet>
								<ng-container *ngFor="let da of dispatches;trackBy: identify">
								<div class="row small-font border-bottom item-list m-0 p-0" *ngIf="da.ordertype != 11">
									<ng-container *ngFor="let i of da.orderdetails" >
										<li class="dd-item dd3-item item-box" >
											<div class="dd-handle dd3-handle" [ngClass]="{credit: da.ordertype=='11',loaded: i.loaded, picked: da.pick_complete, started: (i.pickstatus == '23' && !da.pick_complete) }">&nbsp;</div>
											<mat-card class="dd3-content p-1 ">
												<mat-card-content>
													<div class="row m-0 p-0 no-overflow">
														<div class="col-12 m-0 p-0" [ngClass]="user.user.dispatchadmin == '1' ? 'col-md-6' : 'col-md-8'">
															<ul class="entry-list m-0 p-0" >
																<li>
																	<div class="spec-label">ITEM#</div>
																	<div class="spec-value">{{ i.stkcode }}</div>
																</li>
																<li>
																	<div class="spec-label">DESC#</div>
																	<div class="spec-value">{{ i.itemdesc }}</div>
																</li>
																<li>
																	<div class="spec-label">ORDER#</div>
																	<div class="spec-value">{{ da.header.orderno }}</div>
																</li>
																<li>
																	<div class="spec-label">BIN</div>
																	<div class="spec-value">
																		<b>
																		<span *ngIf="i.bin ==''">NEED</span>
																		{{ i.bin }}
																		</b>
																	</div>
																</li>
																<li>
																	<div class="spec-label">QTY</div>
																	<div class="spec-value"><b>{{ i.quantity }}</b></div>
																</li>

															</ul>
														</div>
														<div class="col-12 ml-2 col-md-2 mt-md-4 m-0 p-0 text-center">
															<mat-form-field class="full-width" appearance="outline">
																<span matPrefix>
																	<button class=" btn btn-just-icon btn-white btn-fab btn-round btn-sm" (click)="removePick(da, i)">
																		<small>
																			<i class="material-icons">remove</i>
																		</small>
																	</button>
																</span>
																<input matInput type="text" class="text-right" type="text" [(value)]="i.qtyloaded" [ngClass]="i.qtyloaded < i.quantity ? 'text-danger': 'text-success'" (keyup)="setQty($event.target.value, i, da)">
																<span matSuffix> / <b>{{ i.quantity }}</b></span>
																<span matSuffix>
																	<button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-2" (click)="addPick(da, i)">
																		<small><i class="material-icons">add</i></small>
																	</button>
																</span>
																<mat-error></mat-error>
															</mat-form-field>
														</div>

														<div class="col-2 col-md-2 mt-2 mt-md-4 m-0 p-0 text-center" *ngIf="user.user.dispatchadmin == '1'">
															<button mat-mini-fab color="primary" (click)="itemToTruck(da,i)" *ngIf="!i.loaded">
																<mat-icon>file_download_done</mat-icon>
															</button>
															<button mat-mini-fab color="accent" (click)="removeItemToTruck(da,i)" *ngIf="i.loaded">
																<mat-icon>remove</mat-icon>
															</button>
														</div>

													</div>
												</mat-card-content>
											</mat-card>
										</li>
									</ng-container>
								</div>
								</ng-container>
							</div>
						</mat-tab>
						<!--*ngIf="checkLoad();"-->
						<mat-tab label="Run Sheet" >
							<ng-template matTabContent>
							<div class="card-body">
								<div class="row mb-3" *ngIf="user.user.dispatchadmin == '1'">
									<div class="col-3" *ngIf="truck.value">
										<button mat-raised-button color="primary" (click)="clearRun(truck.value)">
											<span>Clear Run</span>
										</button>
										&nbsp;&nbsp;&nbsp;
										<button mat-raised-button color="primary" (click)="deliverRun(truck.value)">
											<span>Mark All Delivered </span>
										</button>
									</div>
								</div>
							</div>
							<app-dispatch-deliverysheet [events]="eventsSubject.asObservable()" [selectedId]="selectedtruck.id"></app-dispatch-deliverysheet>
							</ng-template>
						</mat-tab>
					</mat-tab-group>
				</div>
			</div>
		</div>
	</div>
</div>
