<ng-template #journalDetails let-modal>
	<div class="modal-header">
		<div class="modal-title row m-0 p-0" id="modal-title" >
			<h4 *ngIf="payment_journal_editing">Payment #{{ payment_journal_editing.transno | json }} Journal</h4>
		</div>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">

		<div class="row">
			<div class="col-md-12">
				<h4>Current Journal
				<span *ngIf="payment_journal_editing.journal_no == '0'">N/A</span>
				<span *ngIf="payment_journal_editing.journal_no != '0'">
					 #{{ payment_journal_editing.journal_no }}
				</span>
				</h4>
			</div>
			<div class="col-md-3">
				<button mat-raised-button n-submit color="accent"  class="mt-4" (click)="$event.preventDefault();openNewJournal($event)">
					<mat-icon>add</mat-icon> New Journal
				</button>
			</div>
			<div class="col-md-3">
				<mat-form-field appearance="outline"  *ngIf="payment_journal_editing">
					<mat-label>TO JOURNAL#</mat-label>
					<mat-select [formControl]="payment_journal_edit">
						<mat-option *ngFor="let cur of openjournals" [value]="cur">
							{{ cur.userid }} {{ cur.journal_date | date:'shortDate' }} #{{ cur.journal_no }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-3">
				<button mat-raised-button  n-submit color="accent" class="mt-4" (click)="updateJournal()">Move Journal</button>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #voidDetails let-modal>
	<div class="modal-header">
		<div class="modal-title row m-0 p-0" id="modal-title">
			<h4>Void Payment #{{ void_trans.transno }}</h4>
		</div>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">

		<mat-form-field appearance="outline" class="col-2">
			<mat-label>Void Date</mat-label>
			<input matInput [formControl]="voiddate" [matDatepicker]="payfrom">
			<mat-datepicker-toggle matSuffix [for]="payfrom"></mat-datepicker-toggle>
			<mat-datepicker #payfrom></mat-datepicker>
		</mat-form-field>

		<div class="row">
			<div class="col-md-4">
				<ul class="list-group">
					<li class="list-group-item no-border">Void Payment#{{ void_trans.transno }}</li>
					<li class="list-group-item no-border">Type: {{ void_trans.banktranstype  }}</li>
					<li class="list-group-item no-border">Order#: {{ void_trans.cc_data.order_number }}</li>
					<li class="list-group-item no-border">Amount: {{ void_trans.amount | currency }}</li>
					<li class="list-group-item no-border">Return Inventory?&nbsp;&nbsp;&nbsp;<mat-checkbox class="mt-4" [formControl]="create_credit">Return Inventory</mat-checkbox>
					</li>
				</ul>
			</div>
			<div class="col-md-4">
				<ul class="list-group">
				</ul>
			</div>
			<div class="col-md-4">
				<ul class="list-group">
				</ul>
			</div>
		</div>
		<button mat-raised-button color="accent" (click)="processVoid()" *ngIf="!voiding">Void Payment</button>
		<span *ngIf="voiding"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
				<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
			</svg>
		</span>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">
		<div class="row tp-wrapper m-0 p-0">
			<div class="col-md-12 ">
				<div class="card ">
					<div class="card-header card-header-{{color}}-6 ">
						<h4 class="card-title" (click)="back()">
							<i class="material-icons">arrow_left</i>
							Payment Report
						</h4>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-md-3">
								<mat-form-field appearance="outline" *ngIf="locations">
									<mat-label>Warehouse</mat-label>
									<mat-select [formControl]="defaultlocation" multiple appSelectAll>
										<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
											{{ loc.locationname }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<div class="col-md-3">
								<mat-form-field appearance="outline">
									<mat-label>Type</mat-label>
									<mat-select [formControl]="paymenttypefilter">
										<mat-option [value]="'all'">--- All ---</mat-option>
										<mat-option *ngFor="let cur of payment_methods" [value]="cur">
											{{ cur.paymentname }}
										</mat-option>
									</mat-select>
								</mat-form-field>

							</div>
							<div class="col-3">
								<mat-form-field appearance="outline">
									<mat-label>From Date</mat-label>
									<input matInput (dateChange)="updateDayPayMents($event.value)" [formControl]="datefrom" [matDatepicker]="payfrom">
									<mat-datepicker-toggle matSuffix [for]="payfrom"></mat-datepicker-toggle>
									<mat-datepicker #payfrom></mat-datepicker>
								</mat-form-field>
							</div>
							<div class="col-3">
								<mat-form-field appearance="outline">
									<mat-label>To Date</mat-label>
									<input matInput (dateChange)="updateDayPayMents($event.value)" [formControl]="dateto" [matDatepicker]="payto">
									<mat-datepicker-toggle matSuffix [for]="payto"></mat-datepicker-toggle>
									<mat-datepicker #payto></mat-datepicker>
								</mat-form-field>
							</div>
							<div class="col-md-3">
								<button class="mt-3" mat-raised-button color="accent" (click)="loadData()" *ngIf="!running">Load</button>

								&nbsp;&nbsp; <button class="btn btn-success btn-sm" (click)="$event.preventDefault();exportXls()" *ngIf="payments.summary && payments.transactions" >
									<i class="fa fa-file-excel-o"></i> XLS
								</button>
								&nbsp;&nbsp;
								<button class="btn btn-danger btn-sm" (click)="$event.preventDefault();exportPdf()" *ngIf="payments.summary&& payments.transactions" >
									<i class="fa fa-file-pdf-o"></i> PDF
								</button>

								<span *ngIf="running"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>

							</div>

						</div>
						<!--left-->
						<div class="row">



							<div class="col-md-12" id="payrep" *ngIf="!running">
								<ng-container *ngIf="payments.transactions">
									<div class="table" #print_table>

										<h3>Summary</h3>
										<p class="location-sub-header">
										  {{ getLocationNamesFormatted() }}
										</p>
										<div class="bg-white mt-2 rounded ">
											<div id="receipts">
												<table class="table table-fixed compact table-sm w-50 collapsed noprint">
													<thead>
														<tr>
															<th>Type</th>
															<th class="text-right">Amount</th>
														</tr>
													</thead>
													<tbody>
														<tr *ngFor="let p of payments.summary">
															<td>{{ p.type }}</td>
															<td class="text-right"> {{ p.amount | currency }}</td>
														</tr>
														<tr>
															<th>CC Total</th>
															<th class="text-right">{{ cctotal | currency }} </th>
														</tr>
														<tr>
															<th>Total</th>
															<th class="text-right"> {{ total_pay | currency }}</th>
														</tr>
													</tbody>
												</table>
												<h3 class="mt-2 mb-4 noprint">Payment Details</h3>
												<p class="location-sub-header">
												  {{ getLocationNamesFormatted() }}
												</p>
												<table id="rcptTable" name="rcptTable" class="table compact table-sm table-fixed collapsed">
													<thead>
														<tr>
															<th class="text-center">Date</th>
															<th class="text-center">Receipt#</th>
															<th class="text-center">Document#</th>
															<th class="text-left">Customer#</th>
															<th class="text-left">Ref#</th>
															<th class="text-left">Name</th>
															<th class="text-right mincolumn">Amount</th>
															<th class="text-right">Discount</th>
															<th class="text-right">Remaining</th>
															<th class="text-center">Settled</th>
															<th class="text-center">Journal#</th>
															<th class="text-center">Type</th>
															<th class="text-center donotprint">Reference</th>
															<th class="text-center ">User</th>
															<th class="donotprint"></th>
														</tr>
													</thead>
													<tbody>
														<tr *ngFor="let pay of payments.transactions">
															<td class="text-center"> {{ pay.inputdate }}</td>
															<td class="text-center"> {{ pay.transno }} </td>
															<td class="text-center"> {{ pay.order_ }} </td>
															<td class="text-left text-nowrap">
																<a [routerLink]="'/customers/view/' + pay.debtorno">
																	<b>{{ pay.debtorno }}.{{ pay.branchcode }}</b>
																</a>
															</td>
															<td class="text-left text-nowrap">{{ pay.ref }}</td>
															<td class="text-left text-nowrap">{{ pay.name }}</td>

															<td class="text-right mincolumn">{{ pay.ovamount | currency }}</td>
															<td class="text-right">{{ pay.ovdiscount | currency }}</td>
															<td class="text-right">{{ pay.leftto | currency }}</td>
															<td class="text-center" [ngClass]="(pay.settled == 'Yes') ? 'text-success' : ''">{{ pay.settled }}</td>
															<td class="text-center">
																&nbsp;<i class="fa fa-edit donotprint" (click)="toggleEditJournal(pay)"></i>
																<span *ngIf="pay.journal_no  != '0'" >
																	{{ pay.journal_no }}
																</span>
																<span *ngIf="pay.journal_no  == '0'" >
																	N/A
																</span>
															</td>
															<td class="text-center">
																<span *ngIf="!pay.edit">{{ pay.banktranstype}}</span>
																&nbsp;<i class="fa fa-edit donotprint" (click)="toggleEdit(pay)"></i>
																<ng-container *ngIf="pay.edit">
																	<mat-form-field appearance="outline">
																		<mat-label>Payment Type#</mat-label>
																		<mat-select [formControl]="payment_type_edit">
																			<mat-option *ngFor="let cur of payment_methods" [value]="cur.paymentname">
																				{{ cur.paymentname }}
																			</mat-option>
																		</mat-select>
																	</mat-form-field>
																	<button class="btn btn-sm btn-danger" (click)="savePaymentUpdate(pay)">
																		<i class="fa fa-save"></i>
																	</button>
																</ng-container>
															</td>
															<td class="text-center donotprint">{{ pay.reference}}</td>
															<td class="text-center">{{ pay.placedby}}</td>
															<td class="text-center donotprint">
																<ng-container *ngIf="pay.cc_data">
																	<a (click)="voidPayment(pay)" class="pointer" *ngIf="pay.cc_data.completed == '1'">VOID</a>
																	..XX {{ pay.cc_data.card_last_four }}
																	{{ pay.cc_data.paynote }}
																</ng-container>
															</td>
														</tr>

														<tr>
															<td class="text-center"><B>TOTAL</B></td>
															<td class="text-center"></td>
															<td class="text-left"></td>
															<td class="text-left"></td>
															<td class="text-left"></td>

															<td class="text-right text-nowrapnumber" colspan="2">
															{{ total_sub | currency }}
															</td>
															<td class="text-right text-nowrapnumber">{{ total_discount | currency }}</td>
															<td class="text-center"></td>
															<td class="text-center"></td>
															<td class="text-center"></td>
															<td class="text-center donotprint"></td>
															<td class="text-center donotprint"></td>
															<td></td>
														</tr>


													</tbody>
												</table>
												<table class="table table-fixed compact table-sm w-50 collapsed donotshow">
													<thead>
														<tr>
															<th>Type</th>
															<th class="text-right">Amount</th>
														</tr>
													</thead>
													<tbody>
														<tr *ngFor="let p of payments.summary">
															<td>{{ p.type }}</td>
															<td class="text-right"> {{ p.amount | currency }}</td>
														</tr>
														<tr>
															<th>CC Total</th>
															<th class="text-right">{{ cctotal | currency }} </th>
														</tr>
														<tr>
															<th>Total</th>
															<th class="text-right"> {{ total_pay | currency }}</th>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</ng-container>
								<ng-container *ngIf="!payments.transactions">
									<h4>No Payments Found</h4>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
