<div class="main-content">
<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-header card-header-{{color}}-5">
					<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Coupons</h4>
					<p class="card-category"></p>
				</div>
				<div class="card-body">
					<app-coupons></app-coupons>
				</div>
			</div>
		</div>
	</div>
</div>
