<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-3">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Brand Priorities </h4>
						<p class="card-category">Brand Priorities </p>
					</div>
					<div class="card-body ">
						<div class="row border-top mb-2 mr-2" *ngFor="let brand of brands">
							<div class="col-6">
								<ul class="list-group">
								  	<li class="list-group-item border-bottom" ><b>{{ brand.name }}</b></li>
								</ul>
							</div>

							<div class="col-6">
								<div class="row">
									<div class="col-2 mt-4 text-right">
										<span matSuffix>
											<button mat-stroked-button color="success" (click)="addVendor(brand)"><mat-icon>add</mat-icon></button>
										</span>
									</div>
									<div class="col-10 text-left">
									<mat-form-field appearance="outline">
										<mat-label>Vendor</mat-label>
										<mat-select [formControl]="vendor">
											<mat-option *ngFor="let v of vendors" [value]="v">
												{{v.suppname}}
											</mat-option>
										</mat-select>
									</mat-form-field>
									</div>

									<ng-container *ngIf="brand.priorities">

										<div cdkDropList class="vendor-list"
											[cdkDropListData]="brand.priorities"
											(cdkDropListDropped)="drop($event, brand)">
											<div class="vendor-box text-left" *ngFor="let p of brand.priorities; let i = index;" cdkDrag>
												<span class="col-8 ml-4 mt-3" matBadge="{{ i + 1 }}" matBadgeOverlap="false" matBadgePosition="before" matBadgeColor="accent"  matBadgeSize="small">

														<b>{{ p.supplierid }}</b> {{ p.suppname }}
												</span>
												<span class="col-2 ml-auto">
													<button mat-stroked-button color="success" (click)="removeVendor(p, brand)" (mousedown)="$event.stopPropagation()"><mat-icon>remove</mat-icon></button>
												</span>
											</div>
										</div>
									</ng-container>
								</div>
							</div>

							<mat-divider></mat-divider>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
