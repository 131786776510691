<div class="main-content">
	<div class="container-fluid" >
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{ color }}-4" >
						<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i>Invoice Created</h4>
						<p class="card-category bold">{{ transno }}</p>
					</div>
					<div class="card-body">
						<div class="row text-center">
							<div class="col-md-12">
								<h4>Invoice # {{ transno }} Created</h4>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
