import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { OrdersService } from '../../services/orders.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { TableModule } from 'primeng/table';
import * as FileSaver from 'file-saver';

import { trigger, state, style, transition, animate } from '@angular/animations';


@Component({
	selector: 'app-dispatches-maintance',
	templateUrl: './dispatches-maintance.component.html',
	styleUrls: ['./dispatches-maintance.component.scss']
})
export class DispatchesMaintanceComponent implements OnInit {
	dispatches;
	trucks;
	ready: boolean;
	color = 'blue';

	constructor(private globalSearchService: GlobalSearchService, private orderService: OrdersService, private location: Location, public cdr: ChangeDetectorRef) {
		this.color = this.globalSearchService.getColor();

	}

	ngOnInit(): void {
		this.ready = false;


	}


	back(): void {
		this.location.back()
	}

}
