<table mat-table [dataSource]="datasource" class="mat-elevation-z8" matSort matSortActive="total" matSortDirection="desc">
    <ng-container matColumnDef="orderno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Order no.</th>
        <td mat-cell *matCellDef="let element"> {{element.orderno}} </td>
    </ng-container>
    <ng-container matColumnDef="orddate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Order Date </th>
        <td mat-cell *matCellDef="let element"> {{element.orddate}} </td>
    </ng-container>
    <ng-container matColumnDef="fromstkloc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Loccode </th>
        <td mat-cell *matCellDef="let element"> {{element.fromstkloc }} </td>
    </ng-container>
     <ng-container matColumnDef="salesperson">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Salesperson ID </th>
        <td mat-cell *matCellDef="let element"> {{element.salesperson }} </td>
    </ng-container>
    <ng-container matColumnDef="salesmanname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Salesperson Name </th>
        <td mat-cell *matCellDef="let element"> {{element.salesmanname }} </td>
    </ng-container>
    <ng-container matColumnDef="payterms">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Payterms </th>
        <td mat-cell *matCellDef="let element"> {{element.payterms }} </td>
    </ng-container>
    <ng-container matColumnDef="unitprice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Price </th>
        <td mat-cell *matCellDef="let element"> {{element.unitprice | currency }} </td>
    </ng-container>
    <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Quantity </th>
        <td mat-cell *matCellDef="let element"> {{element.quantity }} </td>
    </ng-container>
    <ng-container matColumnDef="discountpercent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Discount % </th>
        <td mat-cell *matCellDef="let element"> {{element.discountpercent | percent }} </td>
    </ng-container>
    <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Total  </th>
        <td mat-cell *matCellDef="let element"> {{element.total | currency }} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="subdisplayedColumns" class="sub-table-header-row"></tr>
    <tr mat-row *matRowDef="let row; columns: subdisplayedColumns;" class="sub-table-row"></tr>
</table>