import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit , OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { interval, Subscription, Observable } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { InventoryService } from '../../services/inventory.service';
import { PurchasingService } from '../../services/purchasing.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


@Component({
	selector: 'app-inventory-movements',
	templateUrl: './inventory-movements.component.html',
	styleUrls: ['./inventory-movements.component.scss'],
	animations: [
		trigger('grow', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: '0', width: '0' }),
				animate(200, style({ height: '*', width: '*' })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate(300, style({ height: 0, width: 0, overflow: 'hidden' })),
				animate('300ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class InventoryMovementsComponent implements OnInit {
	@Input() item;

	@Output() editing = new EventEmitter < boolean > ();
	@Output() newitemdata = new EventEmitter < boolean > ();
	@ViewChild('details') detailsRef: ElementRef;

	itemForm: UntypedFormGroup;
	properties: any = [];
	afterdate = new Date();
	todate = new Date(new Date().setDate(this.afterdate.getDate() - 365));
	locations: any = [];
	systypes: any = [{ typeid: 10, typename: 'Invoices' },{ typeid: 11, typename: 'Credits' },{ typeid: 17, typename: 'Adjustments' },{ typeid: 25, typename: 'Receiving' },{ typeid: 75, typename: 'Consignment' }];
	sending = false;
	results: any = [];
	searching: any = false;
	displaydetails: any = false;
	podetails: any = false;

	private sort: MatSort;
	dataObs: Observable < any > ;
	dataSource: MatTableDataSource < any > ;
	items_per_page = [25, 50, 100, 200, 500, 1000];
	pagesizedefault = 500;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.setDataSourceAttributes();
	}

	@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {

		this.paginator = mp;
		this.setDataSourceAttributes();
	}

	setDataSourceAttributes() {
		this.dataSource.paginator = this.paginator;
	}

	constructor(private _changeDetectorRef: ChangeDetectorRef,private route: ActivatedRoute, private fb: UntypedFormBuilder, private inventoryService: InventoryService, public router: Router, private location: Location, private globalsService: GlobalsService, public globalSearchService: GlobalSearchService,private modalService: NgbModal,public purchasingService:PurchasingService) {}

	onSubmit() {
		this.inventoryService.updateStock(this.item.itemstock).subscribe((result: any) => {
			this.newitemdata.emit(result);
		})
	}

	ngOnInit(): void {
		this.setPagination([]);
		this.globalSearchService.locations.subscribe(async (results: any) => {
			this.locations = results;
			const details = this.item;
			this.setForm()
		});
	}

	getMovements() {

		if(this.itemForm.valid) {
			this.sending = true;
			this.results = [];

			if(this.searching) {
				this.searching.unsubscribe();
			}
			this.searching = this.inventoryService.getMovements(this.itemForm.value).subscribe(r => {
				this.sending = false;
				this.results = r;
				this.setPagination(r);
			});
		}
	}

	updateQty(value: any, item: any) {
		item.quantity = value;
	}

	ngAfterViewInit(): void {
		this.globalsService.getItemSetup().subscribe((results: any) => {
			this.properties = results
		});
	}

	openDocument(transaction: any, type: any, display: any) {

		this.displaydetails = false;
		this.podetails = false;
		switch(type) {
		case '25':
			this.purchasingService.getPurchaseOrder(transaction.poorderno).subscribe((order: any) => {
				this.displaydetails = order
				this.podetails = order;
				this.modalService.open(this.detailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
			});
			break;
		case '11':
			this.globalsService.getCreditNote(transaction.id, display).subscribe((result: any) => {
				this.displaydetails = result;
				this.modalService.open(this.detailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
			})
			break;
		case '10':
			this.globalsService.getInvoice(transaction.id, display).subscribe((result: any) => {
				this.displaydetails = result;
				this.modalService.open(this.detailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
			})
			break;
		}
	}

	setForm() {
		this.itemForm = this.fb.group({
			stockid: [this.item.item.stockid, Validators.required],
			loccode: [this.locations[0]?.loccode, Validators.required],
			systypes: [''],
			fromdate: [this.todate, Validators.required],
			todate: [this.afterdate, Validators.required],
		});

		this.getMovements()
	}

	ngOnChanges(changes: any) {
		this.setForm()
	}

	setPagination(tableData) {
		this.dataSource = new MatTableDataSource < any > (tableData);
		this._changeDetectorRef.detectChanges();
		this.dataSource.paginator = this.paginator;
		this.dataObs = this.dataSource.connect();
	}

}
