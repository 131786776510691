import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { OrdersService } from '../../services/orders.service';
import { ReportsService } from '../../services/reports.service';
import { CardLists, Card } from '../../classes/orders';
import { Router } from '@angular/router';
import { Ng2CompleterModule } from 'ng2-completer';
import { Location } from '@angular/common'
import { PrintService } from '../../services/print.service'
import * as FileSaver from 'file-saver';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

declare let $: any;

@Component({
	selector: 'app-csrpt',
	templateUrl: './csrpt.component.html',
	styleUrls: ['./csrpt.component.scss']
})
export class CsrptComponent implements OnInit {
	@ViewChild('orderView') orderStatusViewRef: ElementRef;
	@ViewChild('details') detailsRef: ElementRef;
	@ViewChild('print_tablepdf') printtablepdf: ElementRef;
	@ViewChild('print_tablexlx') printtablexlx: ElementRef;
	@ViewChild('salesFilter') salesmanRef: MatSelect;
	@ViewChild('branchFilter') branchesRef: MatSelect;
	itemSearchRef = new FormControl('');
	orderSearchRef = new FormControl('');
	conBrRef = new UntypedFormControl('C');
	placedFromRef = new UntypedFormControl('');
	branchRef = new UntypedFormControl('');
	salespersonRef = new UntypedFormControl('');
	filterresults: any = [];
	filteredItems: any = [];
	orderData: CardLists;
	customerData: any = [];
	openOrderData: any = [];
	orders: any = [];
	orderSearch: any = [];
	orderResults: any = false;
	ordersFound: any = [];
	todate = new Date();
	fromdate = new Date();
	reportCriteria = {
		fromdate: '',
		todate: '',
		locs: [],
		salesperson: [],
		conbr: '',
		placedfrom: ''
	};

	@Input() name: string;
	color: any = 'blue';
	config: any = [];
	pickinglink: any = '';
	filename: any = '';
	invoicelink: any = '';
	displaydetails: any = false;
	orderdetails: any = false;
	fromdateCtrl = new UntypedFormControl('');
	todateCtrl = new UntypedFormControl('');
	sending: any = false;
	branches: any = [];
	salespeople: any = [];
	conbr: any = [{ label: 'Consolidated', value: 'C' }, { label: 'Branch', value: 'B' }];
	placedfrom: any = [{ label: 'Included', value: '' }, { label: 'Excluded', value: '1' }, { label: 'Only', value: '2' }];
	exportType: string;

	ngOnInit(): void {}
	constructor(private globalSearchService: GlobalSearchService, private printService: PrintService, private globalsService: GlobalsService, public router: Router, private reportsService: ReportsService, private location: Location, private modalService: NgbModal) {

		this.color = this.globalSearchService.getColor();
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});

		this.globalSearchService.mesagesallowed.next(false);

		this.reportsService.getBranches().subscribe((results: any) => {
			this.branches = results;
		});

		this.reportsService.getSalesPeople().subscribe((results: any) => {
			this.salespeople = results;
		});

		const today = new Date();
		const priorDate = new Date(today.getFullYear(), today.getMonth(), 1);
		this.fromdate = priorDate;

		this.fromdateCtrl.setValue(this.fromdate)
		this.todateCtrl.setValue(this.todate)
	}

	public getCRPT() {
		if (!this.branchRef.value.length) {
			this.showNotification('Please Select a Branch', 'danger', 'top', 'center');
			return;
		}
		if (!this.salespersonRef.value.length) {
			this.showNotification('Please Select a Sales Person', 'danger', 'top', 'center');
			return;
		}
		this.sending = true;
		this.reportCriteria = {
			fromdate: this.fromdateCtrl.value.toISOString().split('T')[0],
			todate: this.todateCtrl.value.toISOString().split('T')[0],
			locs: this.branchRef.value,
			conbr: this.conBrRef.value,
			placedfrom: this.placedFromRef.value,
			salesperson: this.salespersonRef.value
		};

		this.reportsService.getCSRPT(this.reportCriteria).subscribe(r => {
			this.orderResults = r;
			this.sending = false;
		})
	}

	viewCustomer(debtorno: any, loccode: any, salesp: any) {
		const drilldown = {
			cust: debtorno,
			sp: salesp,
			loc: loccode,
			fromdate: this.reportCriteria.fromdate,
			todate: this.reportCriteria.todate,
			locs: this.reportCriteria.locs,
			conbr: this.reportCriteria.conbr,
			placedfrom: this.reportCriteria.placedfrom,
			salesperson: this.reportCriteria.salesperson
		};
		this.reportsService.getCSRPTDrill(drilldown).subscribe((order: any) => {
			this.orderdetails = order
			this.modalService.open(this.orderStatusViewRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
		});
	}
	openDocument(transaction: any, content: any, display: string) {

		switch (transaction.DocType) {
			case 'Credit':
				this.globalsService.getCreditNote(transaction.id, display).subscribe((result: any) => {
					this.displaydetails = result;
					this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
				})
				break;
			case 'Invoice':
				this.globalsService.getInvoice(transaction.id, display).subscribe((result: any) => {
					this.displaydetails = result;
					this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
				})
				break;
		}
	}
	exportPdf() {
		const d = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.generateExportHTML());
		const data = {
			content: encoded,
			filename: 'Customer_sales_by_salesman_' + d.getMonth() + d.getDate() + d.getFullYear(),
			title: `Customer Sales by Salesman Ranking Report ${this.reportCriteria.fromdate} - ${this.reportCriteria.todate}`,
			subtitle: this.getSubTitle()
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	exportXls() {
		const d = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.generateExportHTML());
		const data = {
			content: encoded,
			filename: 'Customer_sales_by_salesman_' + d.getMonth() + d.getDate() + d.getFullYear(),
			title: `Customer Sales by Salesman Ranking Report ${this.reportCriteria.fromdate} - ${this.reportCriteria.todate}`,
			subtitle: this.getSubTitle()
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

	getSubTitle() {
		let out = '';
		if (this.reportCriteria.conbr == 'C') {
			out += 'Consolidated Report'
		} else {
			out += 'Branch Report'
		}
		if (this.reportCriteria.locs.length == this.branches.length) {
			out += ' For All Locations'
		} else {
			out += ' For Locations: ';
			(this.reportCriteria.locs).forEach((loc) => {
				out += loc + ', ';
			});
			out = out.slice(0, -2);
		}
		if (this.reportCriteria.salesperson.length == this.salespeople.length) {
			out += ' and All Salespeople'
		} else {
			out += ' and Salesperson(s): ';
			(this.reportCriteria.salesperson).forEach((person) => {
				out += person + ', ';
			});
			out = out.slice(0, -2);
		}
		if (this.reportCriteria.placedfrom == '') {
			out += ' Including Web Orders';
		}
		if (this.reportCriteria.placedfrom == '1') {
			out += ' Excluding Web Orders ';
		}
		if (this.reportCriteria.placedfrom == '2') {
			out += ' Only Web Orders';
		}
		return out;
	}


	back(): void {
		this.location.back()
	}

	selectAllToggle(filter_name: string) {
		let filter;
		switch (filter_name) {
			case 'salesman':
				filter = this.salesmanRef;
				break;
			case 'branch':
				filter = this.branchesRef;
				break;
			default:
				return;
		}
		const selected = filter.options.find(opt => opt.selected);
		if (selected) {
			filter.options.forEach((item: MatOption) => item.deselect());
		} else {
			filter.options.forEach((item: MatOption) => item.select());
		}
	}

	showNotification(message, type, from, align) {
		//  const type = ['','info','success','warning','danger'];
		// const color = Math.floor((Math.random() * 4) + 1);

		$.notify({
			icon: 'notifications',
			message: message

		}, {
			type: type,
			timer: 1000,
			placement: {
				from: from,
				align: align
			},
			template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
				'<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
				'<i class="material-icons" data-notify="icon">notifications</i> ' +
				'<span data-notify="title">{1}</span> ' +
				'<span data-notify="message">{2}</span>' +
				'<div class="progress" data-notify="progressbar">' +
				'<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
				'</div>' +
				'</div>'
		});
	}

	getTotalsByBR(salesmanKey: string): {
		[br: string]: number
	} {
		const totalsByBR: {
			[br: string]: number
		} = {};

		if (this.orderResults && this.orderResults.lines) {
			this.orderResults.lines.forEach((salesmanGroup: any) => {
				if (salesmanGroup[salesmanKey]) {
					const lines = salesmanGroup[salesmanKey].lines;
					lines.forEach((line: any) => {
						if (!totalsByBR[line.BR]) {
							totalsByBR[line.BR] = 0;
						}
						totalsByBR[line.BR] += parseFloat(line.Sales);
					});
				}
			});
		}

		return totalsByBR;
	}

	generateExportHTML(): string {
		const currencyFormatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
		});

		let html = `
			<table class="table" style="width: 100%">
			  <tr>
				<th style="text-align: center;">BR</th>
				<th style="text-align: center;">Customer</th>
				<th style="text-align: center;">Name</th>
				<th style="text-align: center;">Salesman</th>
				<th style="text-align: right;">Zip</th>
				<th style="text-align: right;">Sales</th>
			  </tr>
		`;

		this.orderResults.lines.forEach((salesmanGroup: any) => {
			Object.keys(salesmanGroup).forEach(salesmanKey => {
				if (salesmanKey !== 'total') {
					const lines = salesmanGroup[salesmanKey].lines;
					html += `<tr><th colspan="6" style="text-align: left;"> Branch ${salesmanKey} </th></tr>`;
					lines.forEach((line: any) => {
						html += `
				  <tr>
					<td>${line.BR}</td>
					<td>${line.Customer}</td>
					<td style="text-align: center;">${line.Name}</td>
					<td style="text-align: center;">${line.Salesmanname}</td>
					<td style="text-align: right;">${line.Zip}</td>
					<td style="text-align: right;">${currencyFormatter.format(line.Sales)}</td>
				  </tr>
				`;
					});
					html += `
				<tr class="salesman-rollup">
				  <th></th>
				  <th></th>
				  <th></th>
				  <th></th>
				  <th></th>
				  <th>${salesmanKey} Total: ${currencyFormatter.format(salesmanGroup[salesmanKey].total)}</th>
				</tr>
			  `;
				}
			});
		});

		html += `
			<tr>
			  <th></th>
			  <th></th>
			  <th></th>
			  <th>Count: ${this.orderResults.totalrecords}</th>
			  <th>Avg: ${currencyFormatter.format(this.orderResults.avg)}</th>
			  <th>Total: ${currencyFormatter.format(this.orderResults.total)}</th>
			</tr>
		  </table>
		`;

		return html;
	}


}
