<div class="tree-container">
    <div class="flex-row mw-350 sticky mb-20 reverse">
        <div class="flex-col f80">
            <div class="flex-row">
                <button mat-raised-button class="btnn plain" (click)="expandAll()">Expand</button>
                <button mat-raised-button class="btnn plain" (click)="selectAll()">Select All</button>
            </div>
            <div class="flex-row">
                <button mat-raised-button class="btnn plain" (click)="compactAll()">Compact</button>
                <button mat-raised-button class="btnn plain" (click)="deselectAll()">Clear</button>
            </div>
        </div>
        <div class="flex-col">
            <button mat-raised-button class="btnn plain" (click)="hide()">Hide</button>
        </div>
    </div>


    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree reverse">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
            <button mat-icon-button disabled></button>
            <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)" (change)="todoLeafItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding>
            <button mat-icon-button disabled></button>
            <mat-form-field>
                <input matInput #itemValue placeholder="New item...">
            </mat-form-field>
            <button mat-button (click)="saveNode(node, itemValue.value)">Save</button>
        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
            <mat-checkbox [checked]="descendantsAllSelected(node)" [indeterminate]="descendantsPartiallySelected(node)" (change)="todoItemSelectionToggle(node)">
                {{node.item}}
            </mat-checkbox>
            <!-- <button mat-icon-button (click)="addNewItem(node)"><mat-icon>add</mat-icon></button> -->
        </mat-tree-node>
    </mat-tree>
</div>