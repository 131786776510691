<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-5">
						<h4 class="card-title" id="headof"><i class="material-icons">arrow_left</i> Order Invoiced</h4>
						<p class="card-category">
							<b></b>
						</p>
					</div>
					<div class="card-body">
						<div class="row m-0 p-0">
							<div class="col-12 text-center">
								<h1 class="text-success">SUCCESS</h1>
								<h4 class="bold">CUSTOMER#: {{ invoice.debtorno }}.{{ invoice.branchcode }}</h4>
								<h4 class="bold">DOCUMENT#: {{ invoice.transno }}</h4>
								<h4 class="bold">INVOICE#: {{ invoice.order_ }}</h4>
							</div>
							<div class="col-12 text-center mb-2">
								<div class="circle-loader load-complete">
									<div class="checkmark-complete draw checkmark"></div>
								</div>
							</div>
							<div class="row button-row text-center ml-auto mr-auto mt-0">
								<div class="button-container">
									<button mat-raised-button (click)="rePrint();" class="btn btn-primary" *ngIf="show_print">
										<i class="fa fa-print"></i>&nbsp;Print Invoice
									</button>
								</div>

								<div class="button-container">
									<a mat-raised-button href="{{ reprintlink }}" class="btn btn-warning">
										<i class="fa fa-file-pdf-o"></i>&nbsp;PDF
									</a>
								</div>

								<div class="button-container">
									<a mat-raised-button class="btn btn-success" [routerLink]="'/customers/view/'+invoice.debtorno">
										View Customer
									</a>
								</div>

								<div class="button-container" *ngIf="isPosUser">
									<a mat-raised-button class="btn btn-danger" [routerLink]="'/counter-pos'">
										New Order
									</a>
								</div>

								<div class="button-container" *ngIf="!isPosUser">
									<a mat-raised-button class="btn btn-danger" [routerLink]="'/orders/entry'">
										New Order
									</a>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>