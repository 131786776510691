import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, AfterViewInit,TemplateRef,ChangeDetectionStrategy } from '@angular/core';
import { startOfDay,endOfDay,subDays,addDays,endOfMonth,isSameDay,isSameMonth,addHours, } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent,CalendarEventAction,CalendarEventTimesChangedEvent,CalendarView, } from 'angular-calendar';
import { EventColor } from 'calendar-utils';

import { ReportsService } from '../../services/reports.service';

const colors: Record < string, EventColor > = {
	red: {
		primary: '#ad2121',
		secondary: '#FAE3E3',
	},
	blue: {
		primary: '#1e90ff',
		secondary: '#D1E8FF',
	},
	yellow: {
		primary: '#e3bc08',
		secondary: '#FDF1BA',
	},
};

@Component({
	selector: 'app-day-sales',
	//changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [
		`
		h3 {
		  margin: 0 0 10px;
		}

		pre {
		  background-color: #f5f5f5;
		  padding: 15px;
		}
	  `,
	],
	templateUrl: './day-sales.component.html',
	styleUrls: ['./day-sales.component.scss']
})
export class DaySalesComponent implements OnInit {
	@Input() inputdate: any = false;

	@ViewChild('modalContent', { static: true }) modalContent: TemplateRef < any > ;
	view: CalendarView = CalendarView.Month;
	daysales: any = false;
	CalendarView = CalendarView;
	viewDate: Date = new Date();
	modalData: {
		action: string;
		event: CalendarEvent;
	};

	refresh = new Subject < void > ();

	actions: CalendarEventAction[] = [{
		label: '<i class="fas fa-fw fa-pencil-alt"></i>',
		a11yLabel: 'Edit',
		onClick: ({ event }: { event: CalendarEvent }): void => {
			this.handleEvent('Edited', event);
		},
	},
	{
		label: '<i class="fas fa-fw fa-trash-alt"></i>',
		a11yLabel: 'Delete',
		onClick: ({ event }: { event: CalendarEvent }): void => {
			this.events = this.events.filter((iEvent) => iEvent !== event);
			this.handleEvent('Deleted', event);
		},
	},
	];

	events: CalendarEvent[] = []

	activeDayIsOpen = false;

	constructor(public reportsService: ReportsService, private modal: NgbModal) {

	}

	ngOnInit(): void {
		this.reportsService.getDaySales().subscribe(async (r:any) => {
			this.daysales =r;
		})

		if(this.inputdate) {

			this.viewDate = this.inputdate;
		}
	}

	getISODate(date) {
		return date.toLocaleDateString('en-ca');
	}

	getDateSales(day:any) {
		const date = this.getISODate(day);
		const sales = this.daysales.filter(d => {
			return d.trandate == date
		});
		let sold = 0;

		if(sales[0]) {
			if(sales[0].invoiced) {
				sold = sales[0].invoiced;
			}
		}

		return this.financial(sold);
	}

	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}

	dayClicked({ date, events }: { date: Date;events: CalendarEvent[] }): void {
		if (isSameMonth(date, this.viewDate)) {
			if (
				(isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
				events.length === 0
			) {
				this.activeDayIsOpen = false;
			} else {
				this.activeDayIsOpen = true;
			}
			this.viewDate = date;
		}
	}

	eventTimesChanged({ event,newStart,newEnd,
	}: CalendarEventTimesChangedEvent): void {
		this.events = this.events.map((iEvent) => {
			if (iEvent === event) {
				return {
					...event,
					start: newStart,
					end: newEnd,
				};
			}
			return iEvent;
		});
		this.handleEvent('Dropped or resized', event);
	}

	handleEvent(action: string, event: CalendarEvent): void {
		this.modalData = { event, action };
		this.modal.open(this.modalContent, { size: 'lg' });
	}

	addEvent(): void {
		this.events = [
			...this.events,
			{
				title: 'New event',
				start: startOfDay(new Date()),
				end: endOfDay(new Date()),
				color: colors.red,
				draggable: true,
				resizable: {
					beforeStart: true,
					afterEnd: true,
				},
			},
		];
	}

	deleteEvent(eventToDelete: CalendarEvent) {
		this.events = this.events.filter((event) => event !== eventToDelete);
	}

	setView(view: CalendarView) {
		this.view = view;
	}

	closeOpenMonthViewDay() {
		this.activeDayIsOpen = false;
	}
}