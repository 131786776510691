import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Location } from '@angular/common'
import { InventoryService } from '../../services/inventory.service';
import { OrdersService } from '../../services/orders.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { merge, fromEvent, forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';

@Component({
	selector: 'app-inventory-search',
	templateUrl: './inventory-search.component.html',
	styleUrls: ['./inventory-search.component.scss']
})
export class InventorySearchComponent implements OnInit {

	@Input() stockid;
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('itemSearch') itemSearchRef: ElementRef;
	itemSearchControl = new UntypedFormControl('');
	categoryControl = new UntypedFormControl('');
	productLineControl = new UntypedFormControl('');
	searching = false;
	searchfield = [];
	items: any = [];;
	allitems: any = [];
	header: any = [];
	results: any = [];
	inventorysearching: any;
	itemsearchsubscription: any;
	categories: any = false;
	productlines: any = false;
	user: any = false;
	include_disabled = new UntypedFormControl(false);
	headercolumns: any = [
		'stockid',
		'supplierno',
		'lineid',
		'description',
		'qoh',
		'cost',
		'price',

	];

	color: any = 'blue'
	typing: boolean = false;
	page_size: any = [25, 50, 100, 500]
	page_size_default: any = 50;
	constructor(
		private route: ActivatedRoute,
		private formBuilder: UntypedFormBuilder,
		public router: Router,
		private location: Location,
		private inventoryService: InventoryService,
		private orderService: OrdersService,
		private globalSearchService: GlobalSearchService
	) {
		this.loadDataConcurrently();
		this.subscribeToUserUpdates();
		this.globalSearchService.getColor();
	}

	private loadDataConcurrently(): void {
		forkJoin({
			categories: this.inventoryService.getItemCategories(),
			productlines: this.inventoryService.getItemProductlines()
		}).subscribe(({ categories, productlines }) => {
			this.categories = categories;
			this.productlines = productlines;
		});
	}

	private subscribeToUserUpdates(): void {
		this.globalSearchService.user.subscribe(user => this.user = user);
	}

	getTotalQoh(itemqoh: any[]): number {
		return itemqoh?.reduce((total_qoh, q) =>
			q.loccode === this.user?.user?.defaultlocation?.loccode ?
			total_qoh + parseFloat(q.quantity) :
			total_qoh, 0) || 0;
	}

	ngOnInit(): void {
		this.itemSearchControl.valueChanges.pipe(
			tap(() => this.typing = true),
			debounceTime(900)
		).subscribe(() => this.searchItems());
	}



	searchItems(): void {
		this.searching = true;
		this.unsubscribeFromPreviousSearch();

		const search = {
			stockid: this.itemSearchControl.value,
			category: this.categoryControl.value,
			productlines: this.productLineControl.value,
			discontinued: this.include_disabled.value
		};

		this.inventorysearching = this.inventoryService.lookup(search).subscribe((items: any[]) => {
			this.typing = false;
			this.searching = false;

			if (items.length === 1) {
				this.clearSearchResults();
				this.viewItem(items[0]);
			} else {
				this.handleMultipleItems(items);
			}
		});
	}

	private unsubscribeFromPreviousSearch(): void {
		if (this.inventorysearching) {
			this.inventorysearching.unsubscribe();
		}
	}

	private clearSearchResults(): void {
		this.allitems = [];
		this.items = [];
		this.itemSearchControl.setValue('');
	}

	private handleMultipleItems(items: any[]): void {
		this.page_size = [25, 50, 100, 500, items.length];
		this.page_size_default = Math.min(items.length, 100);

		this.allitems = items;
		this.items = new MatTableDataSource(items);
		this.items.sort = this.sort;
		this.items.paginator = this.paginator;
	}

	clearForm() {
		this.itemSearchControl.setValue('');
		this.allitems = [];
		this.items = [];
		this.searching = false;
	}

	back(): void {
		this.location.back();
	}

	viewItem(row: any) {
		this.allitems = [];
		this.items = [];
		this.itemSearchControl.setValue('');
		this.router.navigate(['/inventory/view/' + row.stockid])
	}

	announceSortChange(input: any) {

	}

}