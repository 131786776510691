<ng-template #pdfdisplay let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title"></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body" *ngIf="baseurl">
		{{ baseurl+slug.seo_url }}

		<div class="mb-3">
			<ng-container *ngIf="this.fetching">
				<mat-progress-bar mode="indeterminate"></mat-progress-bar>
			</ng-container>
			<ng-container *ngIf="!this.fetching && pdfcontent">
				<ngx-extended-pdf-viewer [base64Src]="pdfcontent" [useBrowserLocale]="true" [textLayer]="true" [showHandToolButton]="true" [showDownloadButton]="true" [showPresentationModeButton]="true" [showOpenFileButton]="false" [showBookmarkButton]="false" [showSecondaryToolbarButton]="false"></ngx-extended-pdf-viewer>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<!-- <div class="main-content">
<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-header card-header-{{color}}-5">
					<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> QR Codes
						</h4>
					<p class="card-category"></p>
				</div>
				<div class="card-body"> -->
					<div *ngIf="dealsheets">
						<div class="row mt-2 mb-2">
							<div class="col-11 w-100"  *ngIf="baseurl">


								<form [formGroup]="dealForm" class="w-100">
				  				<h3 class="mb-4"></h3>

				  				<!-- Start of mat-tab-group -->
				  				<mat-tab-group [(selectedIndex)]="tabIndex">

									<!-- Start of mat-tab "FILE" -->
									<mat-tab label="File">
					  				<div class="row border-bottom py-3">

										<!-- Slug Input -->
										<div class="col-lg-5 col-md-6 mb-3">
						  				<mat-form-field appearance="standard" class="w-100">
											<mat-label>Slug</mat-label>
											<input matInput required formControlName="seo_url" (input)="updateAppend()" autocomplete="off">
											<mat-hint>{{ baseurl }}{{ append }}</mat-hint>
						  				</mat-form-field>
										</div>

										<!-- File Upload Area -->
										<div class="col-lg-4 col-md-6 mb-3" *ngIf="dealForm.get('seo_url').value">
						  				<ngx-dropzone [multiple]="false" (change)="onSelect($event)" class="centered-content">
											<div *ngIf="sending">
							  				<mat-spinner></mat-spinner>
											</div>
											<div *ngIf="!sending">
							  				<ngx-dropzone-label class="m-2">
												DROP FILE HERE OR CLICK TO UPLOAD<br>TO LINK QRCODE TO FILE
							  				</ngx-dropzone-label>
							  				<ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
												<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
							  				</ngx-dropzone-preview>
											</div>
						  				</ngx-dropzone>
										</div>

										<!-- QR Code and Download/Save Buttons -->
										<div class="col-lg-3 col-md-12 text-center">
						  				<ng-container *ngIf="dealForm.get('seo_url').value">
											<h4>{{ append }}</h4>
											<qrcode *ngIf="tabIndex === 0" [qrdata]="baseurl + append" [errorCorrectionLevel]="'M'"></qrcode>
											<div class="d-flex flex-column align-items-center mt-3">
							  				<a mat-raised-button [href]="qrCodeDownloadLink" class="btn btn-primary mb-2" download="{{ dealForm.get('seo_url').value }}">Download</a>
							  				<button mat-raised-button color="accent" (click)="saveWithoutFile()">Save Without File</button>
											</div>
						  				</ng-container>
										</div>

					  				</div>
									</mat-tab>

									<!-- Start of mat-tab "LINK" -->
									<mat-tab label="Link">
					  				<div class="row border-bottom py-3">

										<!-- Slug and URL Inputs -->
										<div class="col-lg-5 col-md-6 mb-3">
						  				<mat-form-field appearance="standard" class="w-100">
											<mat-label>Slug</mat-label>
											<input matInput required formControlName="seo_url" (input)="updateAppend()" autocomplete="off">
											<mat-hint>{{ baseurl }}{{ append }}</mat-hint>
						  				</mat-form-field>

						  				<mat-form-field appearance="standard" *ngIf="dealForm.get('seo_url').value" class="w-100">
											<mat-label>URL</mat-label>
											<input matInput formControlName="input_url" (input)="updateUrl()" [pattern]="urlPattern" autocomplete="off">
											<mat-hint>{{ content_url }}</mat-hint>
						  				</mat-form-field>
										</div>

										<!-- QR Code and Download/Save Buttons -->
										<div class="col-lg-3 col-md-12 text-center">
						  				<ng-container *ngIf="dealForm.get('input_url').value">
											<h4>{{ content_url }}</h4>
											<qrcode [qrdata]="content_url" [errorCorrectionLevel]="'M'"></qrcode>
											<div class="d-flex flex-column align-items-center mt-3">
							  				<a mat-raised-button [href]="qrCodeDownloadLink" class="btn btn-primary mb-2" download="{{ dealForm.get('seo_url').value }}">Download</a>
							  				<button mat-raised-button color="accent" (click)="saveUrl()">Save Without File</button>
											</div>
						  				</ng-container>
										</div>

					  				</div>
									</mat-tab>

				  				</mat-tab-group>
								</form>

							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<h3 class="card-title mt-3">Deal Sheets</h3>
								<div class="card">
									<div class="card-body">
										<div class="d-flex justify-content-between align-items-center mb-3">
											<mat-form-field appearance="standard" class="flex-grow-1 me-3">
												<mat-label>Search</mat-label>
												<input matInput placeholder="Enter search term" [formControl]="search" (input)="searchDeals()">
											</mat-form-field>
											<button mat-raised-button color="primary" (click)="search.setValue('');searchDeals();">Clear</button>
										</div>
										<div class="table-responsive">
											<table class="table table-striped table-hover table-bordered">
												<thead>
													<tr>
														<th class="text-center"></th>
														<th class="text-center">Type</th>
														<th class="w-25 text-center">QR</th>
														<th class="w-25 text-center">Slug</th>
														<th class="w-25 text-center">File</th>
														<th class="w-25 text-center">Link</th>
														<th class="w-25 text-center">PDF</th>
													</tr>
												</thead>
												<tbody>
													<tr *ngFor="let d of dealsheets" [ngClass]="{ 'table-active': d === slug }" (click)="setSlug(d)">
														<td class="text-center">
															<button mat-raised-button color="warn" (click)="removeSelectedSlug(d)">Remove</button>
														</td>
														<td class="text-center"> {{ d.type }} </td>

														<td *ngIf="d.type === 'file'" class="text-center" style="max-width:45px !important;">
															<qrcode [qrdata]="baseurl + d.seo_url" [width]="45" [errorCorrectionLevel]="'M'" style="max-width:45px !important;"></qrcode>
														</td>
														<td *ngIf="d.type === 'link'" class="text-center" style="max-width:45px !important;">
															<qrcode [qrdata]="d.alturl" [width]="45" [errorCorrectionLevel]="'M'" style="max-width:45px !important;"></qrcode>
														</td>
														<td class="text-center">{{ d.seo_url }}</td>
														<td class="text-center">
															<span *ngIf="d.pdf">{{ d.pdf }}</span>
															<span *ngIf="!d.pdf" class="text-danger">N/A</span>
														</td>
														<td class="text-center">
															<a *ngIf="d.type === 'file'" href="{{ baseurl + d.seo_url }}" target="_blank">View</a>
															<a *ngIf="d.type === 'link'" href="{{ d.alturl }}" target="_blank">View</a>
														</td>
														<td class="text-center">
															<button *ngIf="d.type === 'file'" mat-flat-button color="warn" (click)="viewPdf(d)">PDF</button>
															<button *ngIf="d.type === 'link'" mat-flat-button color="warn" (click)="viewPdf(d)" class="d-none">PDF</button>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				<!-- </div>
			</div>
		</div>
	</div>
</div>
</div> -->