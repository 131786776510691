import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { OmsService } from '../../services/oms.service';
import { DispatchService } from '../../services/dispatch.service';
import { MatAccordion } from '@angular/material/expansion';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-dispatches-trucks',
	templateUrl: './dispatches-trucks.component.html',
	styleUrls: ['./dispatches-trucks.component.scss']
})

export class DispatchesTrucksComponent implements OnInit {
	truck: any = false;
	trucks: any = false;
	truckForm: UntypedFormGroup;
	@ViewChild('editTruckEle') editTruckRef: ElementRef;

	constructor(private fb: UntypedFormBuilder,private modalService: NgbModal, private dispatchService: DispatchService, public omsService: OmsService, public location: Location, public ordersService: OrdersService, private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, public formBuilder: UntypedFormBuilder, public inventoryService: InventoryService) {}

	ngOnInit(): void {

		this.truckForm = this.fb.group({
			driver: ['', Validators.required],
			truck_id: [],
		});

		this.dispatchService.getAllTrucks().subscribe((results: any) => {
			this.trucks = results;
		});

	}

	removeTruck() {
		this.dispatchService.removeTruck(this.truck).subscribe(results => {
			this.trucks = results;
			this.modalService.dismissAll()
		})
	}

	addTruck() {
		this.truckForm = this.fb.group({
			truck_id: [null],
			truck_name: ['', Validators.required],
			capacity: [232.5],
		});

		this.modalService.open(this.editTruckRef, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {

		}, (reason) => {

		});


	}

	editTruck(truck: any) {
		this.truck = truck
		this.truckForm = this.fb.group({
			truck_id: [truck.truck_id, Validators.required],
			truck_name: [truck.truck_name, Validators.required],
			capacity: [truck.capacity],
		});

		this.modalService.open(this.editTruckRef, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {

		}, (reason) => {

		});

	}

	saveTruck() {
		if(this.truckForm.valid) {
			this.dispatchService.saveTruck(this.truckForm.value).subscribe(results => {
				this.trucks = results;
				this.modalService.dismissAll()
			})
		}
	}


}
