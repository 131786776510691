<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card ">
					<div class="card-header card-header-{{ color }}-5">
						<h4 class="card-title">
							<i class="material-icons" (click)="back()">arrow_left</i>
							<span class="bold">Dispatch Maintenance</span>
						</h4>
					</div>
					<div class="card-body">
						<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent" w-100>
							<mat-tab label="Trucks">
								<app-dispatches-trucks></app-dispatches-trucks>
							</mat-tab>
							<!--
								<mat-tab label="Pickers">
							</mat-tab>
						-->
						</mat-tab-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
