import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { InventoryService } from 'app/services/inventory.service';
import { OrdersService } from 'app/services/orders.service';
import { Location } from '@angular/common'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { BarcodeScannerLivestreamComponent } from 'ngx-barcode-scanner';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
	selector: 'app-warehouse-item-lookup',
	templateUrl: './warehouse-item-lookup.component.html',
	styleUrls: ['./warehouse-item-lookup.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(360deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('250ms ease-in')),
			transition('active => inactive', animate('250ms ease-out')),
		])
	]
})
export class WarehouseItemLookupComponent implements OnInit {
	@ViewChild('detailsModal') detailsModalEle: ElementRef;
	@ViewChild('searchRef') searchRefRef: ElementRef;
	itemForm: UntypedFormGroup;
	itemlist: any = false;
	lookup = new UntypedFormControl('');
	item: any = false;
	ordertitle = '';
	orderdetails: any = [];
	searching: boolean;
	showlist: boolean;
	historyloading: boolean;
	showorder: boolean;
	lastsearch = '';
	noresults = false;
	searchsub: any = false;
	color: any = '';
	viewingitem: any = false;
	binDisplay: any = false;
	itemio: any = false;
	config: any = false;
	user: any = false;
	constructor(private location: Location,public inventoryService: InventoryService, private globalSearchService: GlobalSearchService, public ordersService: OrdersService, private modalService: NgbModal) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
			this.globalSearchService.user.subscribe(u => {
				this.user = u
			})
		});
		this.color = this.globalSearchService.getColor();

	}

	ngOnInit(): void {
		setTimeout(() => {
			this.searchRefRef.nativeElement.focus();
		}, 100);
	}


	lookupVersionEight() {
		this.searching = true;
		this.lastsearch = this.lookup.value;

		if (this.searchsub) {
			this.searchsub.unsubscribe();
		}

		if (this.lookup.value != '') {
			const data = { stockid: this.lookup.value }
			this.searchsub = this.inventoryService.itemSearch(data).subscribe((results: any) => {
				this.itemlist = results
				if (results.length == 1) {
					this.itemio = results;
					this.loadItem(results[0]);
				} else {
					this.viewingitem = false;
				}
				this.searching = false;
				this.lookup.setValue('');
			});
		} else {
			this.lastsearch = '';
			this.searching = false;
			this.showlist = false;
			this.noresults = false;
			this.itemlist = null;
		}
	}

	addNegativeBins() {
		const binadd = [];
		const total_negative = 0;
		let total_onhand = 0;
		for (const key in this.itemio.bins) {
			for (const vals in this.itemio.bins[key]) {

				const thisbinval = this.financial(this.itemio.bins[key][vals].quantity);
				total_onhand += thisbinval;
				const bindata = this.itemio.bins[key][vals]
				binadd.push(bindata)
				if (thisbinval < 0) {

				}
			}
		}

		const sorted = binadd.sort((n1, n2) => {
			if (n1.quantity > n2.quantity) {
				return -1;
			}

			if (n1.quantity < n2.quantity) {
				return 1;
			}
			return 0;
		});

		this.binDisplay = sorted;
	}

	loadItem(item: any) {
		this.inventoryService.loadItem(item.stockid).subscribe((result: any) => {

			this.viewingitem = true;
			this.item = result;
			this.itemlist = false;
			this.itemio = result;
			this.addNegativeBins();
		});
	}


	orderReservedPicks(orderno: any, stockid: any){
		this.ordersService.getOrderReservedItems(orderno, stockid).subscribe((results: any) => {
			return results;
		});

	}



	checkOverflow (element: any) {
		return element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;
	}


	closeSearch(){
		this.itemlist = false;
	}


	loadOrder(order) {
		this.ordersService.getOrder(order).subscribe((results: any) => {
			this.showorder = true;
			this.orderdetails = results;

			this.modalService.open(this.detailsModalEle, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {

			}, (reason) => {

			});

		});
	}

	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}

	back(): void {
		this.location.back()
	}


}
