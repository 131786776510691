<form [formGroup]="minMaxForm" *ngIf="minMaxForm" (ngSubmit)="updateMinMax()">
<div class="row" *ngIf="item">
	<div class="col-md-2 mt-3">
		<button mat-raised-button color="primary" (click)="nonStockItem()">NON STOCK ITEM?</button>
	</div>
	<div class="col-md-3">
		<app-location-autocomplete (value_selected)="setLocCode($event)"></app-location-autocomplete>
	</div>
	<div class="col-md-2">
		<mat-form-field appearance="outline">
			<mat-label>MIN</mat-label>
			<span matPrefix>#</span>
			<input matInput formControlName="minimum" type="number" value="{{ item.minimum | number }}" placeholder="MIN" class="text-right" >
			<span matSuffix></span>
		</mat-form-field>
	</div>
	<div class="col-md-2">

		<mat-form-field appearance="outline">
			<mat-label>MAX</mat-label>
			<span matPrefix>#</span>
			<input matInput formControlName="maximum" type="number" value="{{ item.minmax.minimum | number }}"  placeholder="MAX" class="text-right">
		</mat-form-field>
	</div>
	<div class="col-3 mt-3 text-right">
		<button mat-raised-button color="accent">
			<mat-icon>save</mat-icon> SAVE
		</button>
	</div>
</div>
</form>
