<!-- <ng-template #detailsModal let-modal>

	<div class="modal-header">
		<h4>Kit Creation</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>

	<div class="modal-body">
			<form [formGroup]="createKitForm">

				<div class="flex-row">
					<mat-form-field class="attribute-formfield" style="width: 50%;">
						<mat-label>Kit name</mat-label>
						<input matInput placeholder="" value="" formControlName="kit_name" class="elipse">
					</mat-form-field>

					<div class="flex-col" style="flex: auto;">

						<div class="flex-row" style="margin: auto;">
							<h4 style="margin-right: 10px; width: 120px;">Effective after</h4>
							<md-input-container class="attribute-formfield">
								<input mdInput type="date" placeholder="" value="" formControlName="kit_effectiveafter" class="elipse" style="width: 100%;">
							</md-input-container>
						</div>

						<div class="flex-row" style="margin:auto">
							<h4 style="margin-right: 10px; width: 120px;">Effective to</h4>
							<md-input-container class="attribute-formfield">
								<input mdInput type="date" placeholder="" value="" formControlName="kit_effectiveto" class="elipse" style="width: 100%;">
							</md-input-container>
						</div>

					</div>

				</div>

				<a mat-raised-button style="margin-left: 5px;">Create Kit</a>

			</form>
	</div>

	<div class="modal-footer"></div>

</ng-template> -->






<div class="mtop20" *ngIf="!editing">
	<div class="flex-row">

		<div style="width: 100%;">
			<mat-form-field appearance="outline" class="searchbar" >
				<input matInput [formControl]="lookup" #itemrec id="itemrec" value="" class="margin-top" autocomplete="off"
					placeholder="* Item Search  "
					(keyup.enter)="$event.stopPropagation();$event.preventDefault();lookupVersionEight();$event.target.blur();">
				<div matSuffix class="text-right">
					<button mat-icon-button type="button" color="white"
						(click)="$event.stopPropagation();$event.preventDefault();lookupVersionEight()">
						<mat-icon>search</mat-icon>
					</button>
				</div>
			</mat-form-field>
		</div>

	</div>

	<!-- multiple select list -->
	<div class="table table-responsive text-center searchlist" *ngIf="searching">
		<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
			<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
		</svg>
	</div>

	<div class="table table-responsive searchlist" #searhlist *ngIf="itemlist && itemlist.length">
		<table mat-table [dataSource]="datasource" class="mat-elevation-z8" *ngIf="datasource">

			<ng-container matColumnDef="parent">
				<th mat-header-cell *matHeaderCellDef> Parent </th>
				<td mat-cell *matCellDef="let element"> {{element.parent == undefined ? element.stockid : element.parent}} </td>
			</ng-container>

			<ng-container matColumnDef="description">
				<th mat-header-cell *matHeaderCellDef> Components </th>
				<td mat-cell *matCellDef="let element"> {{element.description}} </td>
			</ng-container>

            <ng-container matColumnDef="effectiveto">
				<th mat-header-cell *matHeaderCellDef> Effective To </th>
				<td mat-cell *matCellDef="let element"> {{element.effectiveto}} </td>
			</ng-container>

            <ng-container matColumnDef="effectiveafter">
				<th mat-header-cell *matHeaderCellDef> Effective After </th>
				<td mat-cell *matCellDef="let element"> {{element.effectiveafter}} </td>
			</ng-container>

			<ng-container matColumnDef="action">
				<th mat-header-cell *matHeaderCellDef> Action </th>
				<td mat-cell *matCellDef="let element"><a mat-raised-button (click)="openLookup(element)">Edit</a></td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

		</table>
	</div>
	<mat-paginator #paginator [pageSizeOptions]="[25,50,100]" showFirstLastButtons></mat-paginator>
</div>

<app-bommodal *ngIf="editing" (closeEvent)="editing = $event; loadAllBoms()" [openclosed]="activeParent"></app-bommodal>