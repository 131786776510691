<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card tp-box">
					<div class="card-header card-header-{{config.color}}-6">


						<h4 class="card-title">
							<i class="material-icons" (click)="back()">arrow_left</i>
							&nbsp;<button mat-icon-button [cdkCopyToClipboard]="customer_data.debtorno+'.'+customer_data.branchcode"><small><mat-icon>content_copy</mat-icon></small> </button><span class="bold">{{customer_data.debtorno}}.{{customer_data.branchcode}}: {{customer_data.name}}</span>
						</h4>
					</div>
					<div class="card-body">
						<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent" [(selectedIndex)]="selectedindex">
							<mat-tab label="Order">
								<ng-template matTabContent>
									<div class="no-overflow">
										<app-shared-order-entry [type]="'10'" [customer]="customer_data" [flip]="'inactive'" (customer_updated)="customerChanged($event)"></app-shared-order-entry>
									</div>
								</ng-template>
							</mat-tab>
							<mat-tab label="Transactions">
								<ng-template matTabContent>
									<mat-tab-group #tabPanel mat-align-tabs="start" class="no-overflow" color="accent" (selectedTabChange)="resetTrans=true">
										<mat-tab label="Customer Inquiry">
											<ng-template matTabContent>
												<app-customer-transactions [customerdata]="customerdatafortrans">
												</app-customer-transactions>
											</ng-template>
										</mat-tab>
									</mat-tab-group>
								</ng-template>
							</mat-tab>
							<mat-tab label="Open Orders ({{ customerdatafortrans?.opens?.orders || 0 }})">
								<ng-template matTabContent>
									<ng-container >
										<app-open-orders [customerdata]="customer_data" (orderloaded)="loadOrder($event)" (orderinvoice)="moveTODispatch($event)" (preorderloaded)="loadOrder($event)" (cart_updated)="loadOrder($event)" (cancel)="clearCartCancel()">
										</app-open-orders>
									</ng-container>
								</ng-template>
							</mat-tab>

							<mat-tab label="Change Designated Customer">
								<ng-template matTabContent>
									<app-order-change-customer [(customer)]="customer_data" (customer_updated)="customerChanged($event)"></app-order-change-customer>
								</ng-template>
							</mat-tab>
						</mat-tab-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>