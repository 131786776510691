import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';


@Component({
	selector: 'app-vendordash-payments',
	templateUrl: './vendordash-payments.component.html',
	styleUrls: ['./vendordash-payments.component.scss']
})
export class VendordashPaymentsComponent implements OnInit {
	@Input() data: any = [];
	constructor() {}

	ngOnInit(): void {}

}
