<ng-container>
<h4 class="mt-3 text-center" *ngIf="searchedfor"> Adding Barcode {{ searchedfor  }} </h4>
<div class="row">
	<div class="col-md-4" [ngClass]="!selecteditem ? 'col-md-8' : ''">
		<h5 class="bold">{{ selecteditem.stockid }} {{ selecteditem.description }}</h5>
		<mat-form-field appearance="outline">
			<mat-label>Items Search</mat-label>
			<input type="text" placeholder="Items Search" aria-label="Items Search" matInput [formControl]="itemSearchForm">
			<button *ngIf="itemSearchForm.value != ''" matSuffix mat-icon-button aria-label="Clear" (click)="itemSearchForm.setValue('')">
				<mat-icon>close</mat-icon>
			</button>
			<mat-hint *ngIf="filteredItems.length"><b>ITEMS</b> ({{filteredItems.length}})</mat-hint>
		</mat-form-field>
		<div class="row bordered border-top" *ngFor="let item of filteredItems" (click)="selectItem(item)">
			<div class="col-md-4">
				Item# <b>{{ item.stockid }}</b><br>
				{{ item.description }}
			</div>
			<div class="col-md-4">
				Part#<br>
				{{ item.supplierno }}
			</div>
			<div class="col-md-4">
				Bin#<br>
				<b>{{ item.bin }}</b>
			</div>
		</div>
	</div>
	<div class="col-md-4" *ngIf="selecteditem" >
		<ng-container >
			<h5 class="bold">Barcode: {{ barcodeForm.value }} , To: {{ selecteditem.stockid}}</h5>
			<mat-form-field appearance="outline">
				<mat-label >Add Barcode To {{ selecteditem.stockid}}</mat-label>
				<input type="text" placeholder="Barcode" aria-label="Barcode" matInput [formControl]="barcodeForm">
				<button *ngIf="barcodeForm.value != ''" matSuffix mat-icon-button aria-label="Clear" (click)="barcodeForm.setValue('')">
					<mat-icon>close</mat-icon>
				</button>
			</mat-form-field>
		</ng-container>
	</div>
	<div class="col-md-4 mt-4 text-center">
		<button mat-raised-button color="accent" (click)="saveBarcode()" [ngClass]="selecteditem.stockid ? 'btn btn-success' : 'btn btn-danger'">
			<mat-icon>save</mat-icon> Save Barcode
		</button>
	</div>
</div>
</ng-container>
