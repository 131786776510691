<div class="example-container mat-elevation-z8" [hidden]="!datasource || datasource.data.length == 0">
    <mat-table #table [dataSource]="datasource" matSort matSortActive="total" matSortDirection="desc" class="table-borders">
        <ng-container matColumnDef="stockid">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Stock ID </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.stockid}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Item Desc. </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
        </ng-container>
        <ng-container *ngIf="config.env.package == 'tires'">
            <ng-container matColumnDef="designation">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Item Designation. </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.designation}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="season">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Season </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.season}} </mat-cell>
            </ng-container>
        </ng-container>
        <ng-container matColumnDef="qtysold">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="t-center"> # of Units </mat-header-cell>
            <mat-cell *matCellDef="let element" class="t-center"> {{element.qtysold}} </mat-cell>
        </ng-container>


        <ng-container *ngIf="locs.length > 1">
            <ng-container *ngFor="let loc of locs;">
                <ng-container matColumnDef="{{loc}}">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="loc-mat-header jc-center" matTooltip="Info about the action" matTooltipPosition="above"> {{loc}} </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="jc-center border" [ngClass]="element[loc] != '' ? 'units': 'no-units'">
                        <ng-container *ngIf="element[loc] != ''; else empty">
                            {{element[loc]}}
                        </ng-container>
                        <ng-template #empty>-</ng-template>
                    </mat-cell>
                </ng-container>
            </ng-container>
        </ng-container>


        <ng-container matColumnDef="total">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Sales </mat-header-cell>
            <mat-cell *matCellDef="let element" class="btn-cell">
                <ng-container *ngIf="element.total != '' && element.total != undefined;">{{element.total | currency}}</ng-container>
                <ng-container *ngIf="element.total == '' || element.total == undefined;">{{0.00 | currency}}</ng-container>
                <!-- <button id="ignore-btn-event" mat-raised-button class="xlsx" style="display: none;" (click)="printItemCusts()"><i id="ignore-btn-icon" class="fa fa-file-excel-o"></i></button> -->
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="locs.length > 1 ? '' : 'jc-spacebetween'"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple [cdkDetailRow]="row" [cdkDetailRowTpl]="tpl" [ngClass]="locs.length > 1 ? 'element-row' : 'jc-spacebetween element-row'">
        </mat-row>
    </mat-table>
</div>
<mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" class="paginnator mat-elevation-z4 table-borders" [hidden]="datasource.data.length == 0"></mat-paginator>

<ng-template #tpl let-element>
    <div class="" [@detailExpand] style="overflow: hidden" *ngIf="element['custs'].length > 0">
        <app-productlinecusttable [input]="element['custs']" [locs]="locs"></app-productlinecusttable>
    </div>
</ng-template>

<div *ngIf="datasource.data.length == 0">
    <div class="no-sales-div mat-elevation-z8">
        <h4 class="no-sales-text">--&nbsp;Product line has had no sales for the selected date range&nbsp;--</h4>
    </div>
</div>