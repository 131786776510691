import { Component, OnInit } from '@angular/core';

import { MatNativeDateModule } from '@angular/material/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
	selector: 'app-timeline',
	templateUrl: './timeline.component.html',
	styleUrls: ['./timeline.component.scss']
})

export class TimelineComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {


	}
	date;
	alternate = true;
	toggle = true;
	color = false;
	size = 40;
	expandEnabled = true;
	contentAnimation = true;
	dotAnimation = true;
	side = 'left';

	entries = [
		{
			header: 'header',
			content: 'content'
		}
	]

	addEntry() {
		this.entries.push({
			header: 'header',
			content: 'content'
		})
	}

	removeEntry() {
		this.entries.pop();
	}

	onHeaderClick(event) {
		if (!this.expandEnabled) {
			event.stopPropagation();
		}
	}

	onDotClick(event) {
		if (!this.expandEnabled) {
			event.stopPropagation();
		}
	}

	onExpandEntry(expanded, index) {

	}

	toggleSide() {
		this.side = this.side === 'left' ? 'right' : 'left';
	}

	inputEvent(event){
		// Return date object

	}
	changeEvent(event){
		// Return date object

	}

}
