import { Component, OnInit,Input } from '@angular/core';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
	selector: 'app-iframe-view',
	templateUrl: './iframe-view.component.html',
	styleUrls: ['./iframe-view.component.scss']
})
export class IframeViewComponent implements OnInit {
	@Input() src: any = '';
	@Input() type: any = false;
	link: SafeResourceUrl;
	config: any [];
	color: any = 'blue';
	token: any = false;
	constructor(private sanitizer: DomSanitizer, private globalSearchService: GlobalSearchService) {
		this.color = this.globalSearchService.getColor();
		//this.config.apiServer.baseUrl
	}

	ngOnInit(): void {
		if(!this.type) {
			this.link = this.sanitizer.bypassSecurityTrustResourceUrl(this.src+'&header=false&template_color='+this.color);
		} else {
			this.link = this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
		}
	}


}
