<table mat-table [dataSource]="data" matSort (matSortChange)="announceSortChange($event)">
	<ng-container matColumnDef="orderno">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> Order#</th>
		<td mat-cell *matCellDef="let tran">{{ tran.orderno }}</td>
	</ng-container>
	<ng-container matColumnDef="grnno">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> Grn#
		<th>
		<td mat-cell *matCellDef="let tran">{{ tran.grnno }}</td>
	</ng-container>
	<ng-container matColumnDef="itemcode">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> Item#</th>
		<td mat-cell *matCellDef="let tran">{{ tran.itemcode }}</td>
	</ng-container>

	<ng-container matColumnDef="itemdescription">
		<th mat-header-cell *matHeaderCellDef mat-sort-header>Desc</th>
		<td mat-cell *matCellDef="let tran">{{ tran.itemdescription }}</td>
	</ng-container>

	<ng-container matColumnDef="unitprice">
		<th mat-header-cell *matHeaderCellDef mat-sort-header>Price</th>
		<td mat-cell *matCellDef="let tran">{{ tran.unitprice | currency }}</td>
	</ng-container>
	<ng-container matColumnDef="qtyrecd">
		<th mat-header-cell *matHeaderCellDef mat-sort-header>Received</th>
		<td mat-cell *matCellDef="let tran">{{ tran.qtyrecd }}</td>
	</ng-container>
	<ng-container matColumnDef="quantityord">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> Ordered</th>
		<td mat-cell *matCellDef="let tran">{{ tran.quantityord }}</td>
	</ng-container>
	<ng-container matColumnDef="toinvoice">
		<th mat-header-cell *matHeaderCellDef mat-sort-header> To Invoice</th>
		<td mat-cell *matCellDef="let tran">{{ tran.toinvoice }}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef="headercolumns"></tr>
	<tr mat-row *matRowDef="let row; columns: headercolumns"></tr>
</table>
<mat-paginator [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons aria-label="select page">
</mat-paginator>
