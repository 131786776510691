<ng-template #editModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Edit</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<i class="fa fa-close"></i>
		</button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-header text-center">
					<h4 class="card-title text-center"></h4>
				</div>
				<div class="card-body text-center">
					<div class="row">
						<div class="col-12">
							<form [formGroup]="shipViaForm">
								<mat-form-field appearance="outline">
									<mat-label>Name</mat-label>
									<input type="text" class="text-let m-0 p-0" matInput formControlName="shippername" />
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Charge $</mat-label>
									<input type="text" class="text-let m-0 p-0" matInput formControlName="mincharge" />
								</mat-form-field>
								<button mat-stroked-button color="success" aria-label="" (click)="saveShipVia()" class="mt-2">
									<mat-icon>save</mat-icon> Save
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer"></div>
</ng-template>

<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-5">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Shipping Methods </h4>
					</div>
					<div class="card-body">
						<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent">
							<mat-tab label="Shipping Methods">
								<button mat-stroked-button color="success" aria-label="" (click)="addShipper()" class="mt-2">
									<mat-icon>add</mat-icon> Add Shipper
								</button>
								<mat-progress-bar mode="indeterminate" color="accent" *ngIf="isLoading"></mat-progress-bar>
								<table class="table table-fixed table-sm mt-3">
									<tr>
										<th></th>
										<th>Name</th>
										<th>$Charge</th>
									</tr>
									<tr *ngFor="let type of shipvias">
										<td>
											<button mat-icon-button color="warn" aria-label="delete" class="small-font" (click)="removeShiper(type)">
												<mat-icon>remove</mat-icon>
											</button>
											<button mat-icon-button color="accent" aria-label="Edit" class="small-font" (click)="editShipVia(type)">
												<mat-icon>edit</mat-icon>
											</button>
										</td>
										<td>{{ type.shippername }}</td>
										<td>{{ type.mincharge }}</td>
									</tr>
								</table>
							</mat-tab>
						</mat-tab-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
