<ng-template #detailsModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">{{ orderdetails.header.name }} ( {{ orderdetails.header.debtorno }} ) </h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-12">
				<h4>INVOICE: <b>{{ orderdetails.header.orderno  }}</b><br>
				</h4>
			</div>
		</div>

		<div class="table table-responsive">
			<table class="table table-sm">
				<thead>
					<tr>
						<th class="text-left">ITEM</th>
						<th class="text-left">DESC</th>
						<th class="text-center">QTY</th>
						<th class="text-center">PRICE</th>
						<th class="text-right">EXT.</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of orderdetails.details">
						<td class="text-left"><b>{{ item.stkcode }}</b></td>
						<td>{{ item.description }}</td>
						<td class="text-center">{{ item.ordered }} </td>
						<td class="text-center">{{ item.ordered | currency}} </td>
						<td class="text-right">{{ item.unitprice * item.ordered | currency }} </td>
					</tr>
				</tbody>
			</table>
		</div>

	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<div class="table mt-3 shift-20" *ngIf="itemdetails">
	<ng-container *ngIf="itemdetails.item">
		<h4 class="text-center mt-0 mb-0">
			<b style="font-weight: bold">{{ itemdetails.item.description }}</b>
			<b *ngIf="itemdetails.item.description == 'MST '">| {{mstdetails.itemdescription}} - REF#{{mstdetails.orderno}}</b>
		</h4>
		<h5 class="text-center">( {{ itemdetails.item.stockid }} )</h5>
		<div class="card">
			<div class="card-header card-header-tabs card-header-{{color}}-2 p-0">
				<div class="nav-tabs-navigation">
					<div class="nav-tabs-wrapper text-nowrap">
						<ul class="nav nav-tabs col-12" data-tabs="tabs">
							<li class="nav-item col-6 mt-0 mb-0 text-center">
								<a mat-button class="nav-link active" href="#info" data-toggle="tab">
									<i class="material-icons">code</i> Receive
									<div class="ripple-container"></div>
								</a>
							</li>
							<li class="nav-item col-6 mt-0 mb-0 text-center">
								<a mat-button class="nav-link" href="#history" data-toggle="tab">
									<i class="material-icons">cloud</i>Purch
									<div class="ripple-container"></div>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="card-body">
				<div class="tab-content">
					<div class="tab-pane active" id="info">
						<div class="row m-0 p-0">

							<ng-container *ngIf="!send_toggle">
								<div class="col-4 text-center">
									EXPECTED<br>
									<span *ngIf="vendorpos.total_pending">
										{{ vendorpos?.total_pending }}
									</span>
									<span *ngIf="!vendorpos.total_pending">
										0
									</span>
								</div>
								<div class="col-4 text-center">
									ONORDER:<br>
									<span *ngIf="vendorpos.original_inbound">
										{{ vendorpos?.original_inbound }}
									</span>
									<span *ngIf="!vendorpos.original_inbound">
										0
									</span>
								</div>
								<div class="col-4 text-center">
									SCANNED:<br><span *ngIf="already_scanned">{{ getTotalScannedIn() }}</span>
									<span *ngIf="!already_scanned">0</span>
								</div>
							</ng-container>

							<ng-container *ngIf="send_toggle">
								<div class="col-4 text-center">
									EXPECTED<br>
									<span *ngIf="vendorrgas.total_pending">
										{{ vendorrgas?.total_pending }}
									</span>
									<span *ngIf="!vendorrgas.total_pending">
										0
									</span>
								</div>
								<div class="col-4 text-center">
									ON RGA:<br>
									<span *ngIf="vendorrgas.total_pending">
										{{ vendorrgas?.total_pending }}
									</span>
									<span *ngIf="!vendorrgas.total_pending">
										0
									</span>
								</div>
								<div class="col-4 text-center">
									SCANNED:<br><span *ngIf="already_scanned">{{ getTotalScannedIn() }}</span>
									<span *ngIf="!already_scanned">0</span>
								</div>
							</ng-container>

							<div class="col-12">
								<ul class="entry-list">
									<li>
										<div class="spec-label">ON HAND </div>
										<div class="spec-value">{{ itemdetails.total_qoh }}<span *ngIf="already_scanned">+{{ getTotalScannedIn() }}</span></div>
									</li>
									<li>
										<div class="spec-label">ON ORDER</div>
										<div class="spec-value">{{ itemdetails.openpos }}</div>
									</li>
									<li>
										<div class="spec-label">COMMITTED</div>
										<div class="spec-value">{{ itemdetails.reserved }}</div>
									</li>
									<li>
										<div class="spec-label">AVAILABLE</div>
										<div class="spec-value">{{ itemdetails.total_qoh - itemdetails.reserved }}</div>
									</li>
									<li>
										<div class="spec-label">MIN/MAX</div>
										<div class="spec-value">
											<ng-container *ngIf="itemdetails.item">
												{{ itemdetails.item.minmax.minimum }} / {{ itemdetails.item.minmax.maximum }}
											</ng-container>
										</div>
									</li>
									<li *ngFor="let loc of itemdetails.bins | keyvalue">
										<div class="spec-label">DEFAULT LOCATION</div>
										<div class="overflow-scroll">
											<ng-container *ngFor="let l of loc.value">
												<ng-container *ngIf="l.defaultbin == '1'">
													<div>
														<div class="spec-value">{{ l.bin }} ({{ l.quantity }})</div>
													</div>
												</ng-container>
											</ng-container>
										</div>
									</li>
									<li *ngFor="let loc of itemdetails.bins | keyvalue">
										<div class="spec-label">LOCATION</div>
										<div class="overflow-scroll">
											<ng-container *ngFor="let l of loc.value">
												<ng-container *ngIf="l.defaultbin != '1' && l.quantity > 0">

													<div class="spec-value">{{ l.bin }} ({{ l.quantity }})</div>
												</ng-container>
											</ng-container>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<div class="row mt-2 mb-2" *ngIf="itemdetails">


							<div class="col-12 text-center">
								<h6 class="mt-0 mb-0">ORDERS</h6>
							</div>
							<div class="col-12 text-center" *ngIf="!itemdetails.reserved_orders">
								N/A
							</div>
							<div class="col-md-12 text-center" *ngIf="itemdetails.reserved_orders">
								<span class="ml-auto mr-auto" *ngFor="let order of itemdetails.reserved_orders">
									<a class="order-details" (click)="loadOrder(order.orderno)">
										<b>&nbsp;&nbsp;{{ order.orderno}}&nbsp;&nbsp;</b>
									</a>
								</span>
							</div>

							<div class="col-12 text-center text-md-center">
								<h6 class="mt-0 mb-0">BARCODES</h6>
								<div class="spec-value">{{ item.barcode }}</div>
							</div>
							<div class="col-md-12 text-center ">
								<ng-container *ngIf="itemdetails.openpos">
									<!--
										<span class="ml-auto mr-auto" *ngFor="let po of itemdetails.openpos">
											<b>Expected {{po.quantityord }} </b>
										</span>
									-->
								</ng-container>
							</div>
						</div>

					</div>
					<div class="tab-pane" id="history">
						<div class="table text-center" *ngIf="historyloading">
							<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg>
						</div>
						<div class="col-12" *ngIf="!history && !historyloading">
							<div class="alert alert-warning">No Purchase History</div>
						</div>
						<table class="table table-sm" *ngIf="history">
							<thead>
								<tr>
									<th class="text-left w-20">SUPP</th>
									<th class="text-left w-20">PO</th>
									<th class="text-left w-20">DATE</th>
									<th class="text-center w-20">QTY</th>
									<th class="text-right w-20">COST</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let h of history">
									<td class="text-left"> {{h.suppname}}</td>
									<td class="text-left"> {{h.orderno}}</td>
									<td class="text-left"> {{h.orddate}}</td>
									<td class="text-center"> {{h.quantityord}}</td>
									<td class="text-right"> {{h.unitprice | currency}}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</div>

<nav class="navbar fixed-bottom text-dark navbar-dark bg-light border-top justify-content-right mat-elevation-z24">

	<!-- scan history -->
	<ng-container>
		<div class="scan_history" *ngIf="!show_scan_history" (click)="toggleHistory()" style="text-align: center;">
			<div style="display: flex; justify-content: center;">
				Open Scan history
				<mat-icon>keyboard_arrow_up</mat-icon>
			</div>
		</div>
	</ng-container>

	<ng-container>
		<!-- scan history (showing)-->
		<div class="scan_history_showing focus" *ngIf="show_scan_history">

			<!-- top close button -->
			<div class="borderbottom-blk">
				<button mat-flat-button color="warn" class="closeHistoryButton" (click)="toggleHistory();">Close Scan History<mat-icon>keyboard_arrow_down</mat-icon></button>
			</div>

			<div *ngFor="let scan of scan_history; let indexOfelement=index;" class="scan_block">
				<span class="scan_index"><b>{{indexOfelement + 1}}</b></span>
				<div class="scan_content">
					<div class="content_container">
						<div>
							<div>
								<span style="font-size:12px">( {{scan.vendor}} )</span>
								<b><span style="font-size:15px; text-decoration: underline;">{{scan.stockid}}<span style="font-size: 10px;" *ngIf="scan.stockid === 'MST'"> ({{scan.ponumber}})</span></span></b>
							</div>

							<div style="display: flex;">
								<button mat-icon-button matTooltip="Drop scan" class="drop_scan_button" (click)="remove_scanned(scan)">
									<mat-icon>cancel</mat-icon>
								</button>

								<b>
									<span *ngIf="scan.in_out == 0" class="scan_qty_box" [ngClass]="{
									'no_po':scan.qty > scan.qop,
									'partial':scan.qty < scan.qop && scan.qty > 0}">
										<span>QTY: </span>{{scan.qty}}
									</span>
									<span *ngIf="scan.in_out == 1" class="scan_qty_box rga">
										<span>QTY: </span>{{scan.qty}}
									</span>
								</b>

							</div>
						</div>
					</div>

					<button mat-stroked-button class="decrement" color="warn" (click)="decrement_scanned(scan)">
						<mat-icon>remove</mat-icon>
					</button>
					<button mat-stroked-button class="increment" (click)="increment_scanned(scan)">
						<mat-icon>add</mat-icon>
					</button>
				</div>
			</div>

			<div *ngIf="scan_history.length < 1" class="empty_history">
				<mat-icon color="primary">info</mat-icon>
				<div>Scan a tire to see history</div>
			</div>

			<div class="borderbottom-blk" *ngIf="scan_history.length >= 6">
				<button mat-flat-button color="warn" style="width: 100%; border-radius: 0px;" (click)="toggleHistory();">Close Scan History<mat-icon>keyboard_arrow_down</mat-icon></button>
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf="!show_scan_history">
		<div class="ml-0 col-4 mr-auto nav-item">
			<mat-form-field appearance="outline" class="ml-2 mr-2 text-center" color="accent">
				<mat-label>BIN</mat-label>
				<input matInput type="text" placeholder="BIN" aria-label="BIN" [formControl]="qtyBin" class="bold bolder" (input)="updateBin($event.target.value)">
			</mat-form-field>
		</div>

		<div class="ml-auto col-4 mr-0 nav-item">
			<mat-form-field appearance="outline" class="ml-2 mr-2 text-center" color="accent" autocomplete="off">
				<mat-label *ngIf="send_toggle">Qty To Send</mat-label>
				<mat-label *ngIf="!send_toggle">Qty To Rec</mat-label>
				<span matPrefix>
					<!-- <button class=" btn btn-just-icon btn-white btn-fab btn-round btn-sm" (click)="removePick($event)">
						<small>
							<i class="material-icons">remove</i>
						</small>
					</button> -->
				</span>
				<input *ngIf="send_toggle" type="number" placeholder="Sending Qty" aria-label="Sending Qty" matInput [formControl]="qtyRec" class="bold bolder" (input)="updateQty($event.target.value, $event)" (keydown.enter)="$event.preventDefault();">
				<input *ngIf="!send_toggle" type="number" placeholder="Receiving Qty" aria-label="Receving Qty" matInput [formControl]="qtyRec" class="bold bolder" (input)="updateQty($event.target.value, $event)" (keydown.enter)="$event.preventDefault();">

				<span matSuffix>
					&nbsp;&nbsp;&nbsp;
					<!-- <button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-2" (click)="addPick($event)">
							<small><i class="material-icons">add</i></small>
						</button> -->
				</span>
			</mat-form-field>
		</div>
		<div class="ml-auto col-4 nav-item">
			<button [disabled]="(qtyRec.value == 0 || !qtyRec.value)" mat-raised-button color="accent" (click)="$event.stopPropagation();$event.preventDefault();updatePutAny();" (keydown.enter)="$event.preventDefault()">CONFIRM</button>
		</div>
	</ng-container>
</nav>
