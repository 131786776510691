<div class="table-responsive w-100">
  <table class="table table-sm table-bordered text-center smaller-font" *ngIf="aged">
	<thead class="table-header-grey text-dark">
	  <tr>
		<th class="w-20">BALANCE</th>
		<th class="w-20">FUTURE</th>
		<th class="w-20">CURRENT</th>
		<th class="w-20">30</th>
		<th class="w-20">60</th>
		<th class="w-20">90</th>
		<th class="w-20">120</th>
	  </tr>
	</thead>
	<tbody>
	  <tr>
		<td class="w-20 pointer" [ngClass]="getBalanceClass(aged.balance)" (click)="emitZero()">{{ aged.balance | currency }}</td>
		<td class="w-20 pointer" [ngClass]="getBalanceClass(aged.future)" (click)="emitOne()">{{ aged.future | currency }}</td>
		<td class="w-20 pointer" [ngClass]="getBalanceClass(aged.current)" (click)="emitTwo()">{{ aged.current | currency }}</td>
		<td class="w-20 pointer" [ngClass]="getBalanceClass(aged.due)" (click)="emitThree()">{{ aged.due | currency }}</td>
		<td class="w-20 pointer" [ngClass]="getBalanceClass(aged.overdue)" (click)="emitFour()">{{ aged.overdue | currency }}</td>
		<td class="w-20 pointer" [ngClass]="getBalanceClass(aged.overdue1)" (click)="emitFive()">{{ aged.overdue1 | currency }}</td>
		<td class="w-20 pointer" [ngClass]="getBalanceClass(aged.overdue2)" (click)="emitSix()">{{ aged.overdue2 | currency }}</td>
	  </tr>
	</tbody>
  </table>
</div>
