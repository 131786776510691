import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform,AfterViewInit,Directive, HostListener,ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../../services/customer.service';
import { GlobalsService } from '../../services/globals.service';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { Location , DatePipe } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-payment-types',
	templateUrl: './payment-types.component.html',
	styleUrls: ['./payment-types.component.scss']
})
export class PaymentTypesComponent {
	@ViewChild('addPaymentTypeEle') addPaymentTypeEleRef: ElementRef;
	isLoading = false;
	salestypes: any = [];
	supptypes: any = [];
	customertypes: any = [];
	payment_terms: any = [];
	all_types: any = [];
	color: any = 'blue'
	customerTypeForm: UntypedFormGroup;
	paymentTermsForm: UntypedFormGroup;
	vendorTypeForm: UntypedFormGroup;
	salesTypeForm: UntypedFormGroup;
	uploaddata: any = false;
	files: any = [];
	tablename: any = false;
	mapcols: any = [
		{ field: 'mfgpart', label: 'Part Number' },
		{ field: 'brand', label: 'Brand' },
		{ field: 'price', label: 'Price' },
		{ field: 'stockid', label: 'Item Number' },
	];
	mapping: any = [];
	paytypes: any = [];
	constructor(private inventoryService: InventoryService, private globalsService : GlobalsService, private fb: UntypedFormBuilder,private route: ActivatedRoute,private location: Location,public router: Router,private globalSearchService: GlobalSearchService,private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {

		this.globalSearchService.all_system_types.subscribe((alltypes: any) => {

			this.paymentTermsForm = this.fb.group({
				paymentid: ['', Validators.required],
				paymentname: ['', Validators.required],
				pos_enabled: ['0', Validators.required],
				paymenttype: [''],
				receipttype: ['1', Validators.required],
				usepreprintedstationery: ['0', Validators.required],
				opencashdrawer: ['0', Validators.required],
				sort_order: ['0'],
				payment_code: [''],
				refunds: ['0', Validators.required],
				cc_process: ['0', Validators.required],
				additionalrequired: ['0' , Validators.required],
				dayendlabel: [''],
				fa_icon: [''],
			});

			if(alltypes) {
				this.paytypes = alltypes.paytypes;
			}
		});
	}

	yesNoValue(input:any) {
		return (input == '1') ? 'Y': 'N';
	}

	addPaymentType() {
		this.isLoading = true

		this.paymentTermsForm = this.fb.group({
			paymentid: ['', Validators.required],
			paymentname: ['', Validators.required],
			pos_enabled: ['0', Validators.required],
			paymenttype: [''],
			receipttype: ['1', Validators.required],
			usepreprintedstationery: ['0', Validators.required],
			opencashdrawer: ['0', Validators.required],
			sort_order: ['0'],
			payment_code: [''],
			refunds: ['0', Validators.required],
			cc_process: ['0', Validators.required],
			additionalrequired: ['0' , Validators.required],
			dayendlabel: [''],
			fa_icon: [''],
		});

		this.modalService.open(this.addPaymentTypeEleRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});

		this.isLoading = false
	}


	editPaymentType(atype: any) {
		this.isLoading = true

		this.paymentTermsForm = this.fb.group({
			paymentid: [atype.paymentid, Validators.required],
			paymentname: [atype.paymentname, Validators.required],
			pos_enabled: [atype.pos_enabled, Validators.required],
			paymenttype: [atype.paymenttype],
			receipttype: [atype.receipttype, Validators.required],
			usepreprintedstationery: [atype.usepreprintedstationery, Validators.required],
			opencashdrawer: [atype.opencashdrawer, Validators.required],
			sort_order: [atype.sort_order],
			payment_code: [atype.payment_code],
			refunds: [atype.refunds, Validators.required],
			cc_process: [atype.cc_process, Validators.required],
			additionalrequired: [atype.additionalrequired, Validators.required],
			dayendlabel: [atype.dayendlabel],
			fa_icon: [atype.fa_icon],
		});

		this.modalService.open(this.addPaymentTypeEleRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});

		this.isLoading = false
	}

	removePaymentType(type: any) {
		this.isLoading = true

		this.globalsService.removePaymentType(type).subscribe( result => {
			this.paytypes = result;
		});

		this.isLoading = false
	}

	savePaymentType() {

		this.globalsService.savePaymentType(this.paymentTermsForm.value).subscribe( result => {
			this.paytypes = result;
			this.modalService.dismissAll();
		});

	}

	back(): void {
		this.location.back()
	}

	ngAfterViewInit(): void {

	}
}
