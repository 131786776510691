import { Component, OnInit, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalSearchService } from '../../../services/globalsearchservice.service'
import { PrintService } from '../../../services/print.service'


@Component({
	selector: 'app-vendordash-overdue',
	templateUrl: './vendordash-overdue.component.html',
	styleUrls: ['./vendordash-overdue.component.scss']
})
export class VendordashOverdueComponent implements OnInit {

	@Input() data: any = [];
	sending = false;
	pdf = false;
	paginator_show = true;
	@ViewChild('print_table') printtable: ElementRef;

	constructor(private globalSearchService: GlobalSearchService, private printService: PrintService) {

	}

	ngOnInit(): void {}

	exportPdf() {
		this.sending = true;
		this.paginator_show = false;
		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: 'ap_balance',
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.sending = false;
		});
	}

	exportXls() {
		this.sending = true;

		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: 'ap_balance',
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.sending = false;
		});
	}


}
