import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { InventoryService } from 'app/services/inventory.service';

@Component({
	selector: 'app-bin-storage',
	templateUrl: './bin-storage.component.html',
	styleUrls: ['./bin-storage.component.scss']
})
export class BinStorageComponent implements OnInit {

	tableData: any = false;
	loadingData: any = false;

	statusRef = new UntypedFormControl(0);
	searchRef = new UntypedFormControl('');

	statuses: any = [
		{
			value: 0,
			view: 'In Storage'
		},
		{
			value: 1,
			view: 'Picked up'
		}
	]

	constructor(public inventoryService: InventoryService, public globalSearchService: GlobalSearchService) { }

	ngOnInit(): void {
		this.getBins();
	}


	changeStatus(data) {
		const input = {
			id: data.storage_id,
			status: data.pickedup == 0 ? 1 : 0
		}

		this.inventoryService.updateStorageBin(input).subscribe((res) => {
			this.tableData = res;
			const message = input.status == 0 ? 'in storage.' : 'picked up.'
			const coding = input.status == 0 ? 'warning' : 'success'
			this.globalSearchService.showNotification(`Storage ID #${data.storage_id} set to ${message}`  , coding, 'bottom', 'right');
		})
	}

	getBins() {
		this.inventoryService.getStorageBins().subscribe((results) => {
			this.tableData = results;
		})
	}


}
