import { Component, OnDestroy, OnInit, SimpleChanges, OnChanges ,ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';

import { FormBuilder, FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { ReportsService } from 'app/services/reports.service';
import { MatChip } from '@angular/material/chips';
import * as Chartist from 'chartist';
import { GlobalsService } from 'app/services/globals.service';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';


@Component({
	selector: 'app-telegram-reports',
	templateUrl: './telegram-reports.component.html',
	styleUrls: ['./telegram-reports.component.scss']
})


export class TelegramReportsComponent implements OnInit {
	@Input() menuapp;
	datasource: any;
	@ViewChild(MatSort) sort: MatSort;
	telegram_reports: any = {
		'1-Today': 'todaywebdisplay',
		'2-Yesterday': 'yesterdaywebdisplay',
		'3-Salesman Today': 'salesmantoday',
		'4-Salesman Yesterday': 'salesmanyesterday',
		'5-Salesman This Month': 'salesmanthismonth'
	};
	activeReport: any = false;
	range = new FormGroup({
		start: new FormControl < Date | null > (new Date(new Date().setMonth(new Date().getMonth() - 1))),
		end: new FormControl < Date | null > (new Date()),
	});

	activeReport_def: string[];
	fetchingList = false;
	fetchingData = false;
	reportForm: FormGroup < { report: FormControl < any > ; } > ;
	unsorted_reports: any;
	reportReady: boolean;
	reportRollup: any = false;
	view = 'report';
	loadedReport: any = {};
	pieLabels: any;
	pieSlice: any = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
	storedStyle: any;
	dataPreferences: any = false;
	pieEmpty = false;
	reportsub: any = false;
	config: any;
	user: any;

	telegramAdmin: any;
	pieData: any = false;
	displayedPieColumns: any = ['Salesman', 'Sales'];

	constructor(private reportService: ReportsService, private fb: FormBuilder, private globalSearchService: GlobalSearchService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
			this.globalSearchService.user.subscribe(u => {
				this.user = u
				if (u.user.issalesman) {
					this.telegramAdmin = false;
				}
			})
		});
	}

	ngOnInit(): void {
		this.fetchingList = true;
		this.reportForm = this.fb.group({
			report: [null]
		});

		this.reportService.getTelegramReports().subscribe((results: any) => {
			this.telegram_reports = results;
			this.fetchingList = false;
			if(Object.values(this.telegram_reports)[0]) {
				this.reportForm.get('report').setValue([Object.values(this.telegram_reports)[0]])
				this.runReport('ignore', Object.values(this.telegram_reports)[0]);
			}
		});
	}

	runReport(event: any, override ? : any): void {
		let report;
		if (this.reportsub != false) {
			this.reportsub.unsubscribe();
		}
		this.dataPreferences = false;
		if (event == 'ignore') { //initial report run
			report = override;
		} else {
			report = event.options[0]._value.toLowerCase();
		}

		this.fetchingData = true;
		this.reportReady = false;

		this.reportsub = this.reportService.runTelegramReport(report).subscribe((result) => {
			this.fetchingData = false;

			this.loadedReport = result;
			this.activeReport = result['headers'];
			this.activeReport_def = this.activeReport.map((col) => col.def);

			result['data'].sort((a, b) => a.total < b.total ? 1 : 0);
			this.datasource = new MatTableDataSource(result['data']);
			this.datasource.sort = this.sort;
			this.reportRollup = [];
			this.reportRollup = result['rollup'];


			this.buildChart();
			// this.reportReady = true;
		}, (err) => {}, () => {
			this.reportReady = true;
		});
	}


	getReportTitle(input: any) {
		let title = '';
		Object.entries(this.telegram_reports).forEach((i) => {
			if (i[1] == input) {
				title = i[0];
			}
		});
		if(title != '') {
			return this.removeSort(title);
		}
		return 'N/A';
	}

	removeSort(input) {
		return input.split('-')[1];
	}


	toggleView() {
		this.view == 'report' ? this.view = 'pie' : this.view = 'report';
		if (this.view == 'pie') {
			this.buildChart();
		}
	}

	buildChart() {
		let total;
		this.pieEmpty = true;
		if (this.loadedReport.rollup) {
			total = this.loadedReport.rollup['store'].total;
			if (this.loadedReport.rollup['web']) {
				total += this.loadedReport.rollup['web'].total;
			}
		}
		const temp = {
			labels: this.loadedReport.data.map((i) => i.name),
			series: this.loadedReport.data.map((i) => {
				const tot = i.total;
				if (!(tot == 0)) {
					this.pieEmpty = false;
					return Number(tot / total * 100).toFixed(2);
				} else {
					return Number(0).toFixed(2);
				}
			})
		};

		this.dataPreferences = temp;
		const optionsPreferences = {
			height: '280px'
		};

		this.pieLabels = this.loadedReport['data'].map((i) => i.name);
		this.pieData = this.buildPieTable(this.pieLabels, this.dataPreferences.series);
		new Chartist.Pie('#chartPreferencesTele', this.dataPreferences, optionsPreferences);
	}

	sliceMatch(input, direction, labelValue) {
		//highlight pie slice
		const eles = document.getElementsByClassName('ct-series-' + this.pieSlice[input]);
		Object.values(eles).forEach((element: any) => {
			const child: any = element.firstElementChild;
			const style: any = getComputedStyle(child);
			this.storedStyle = style['fill'];
			if (child.classList[0] == 'ct-slice-pie' && element.parentElement.parentNode.id == 'chartPreferencesTele') {
				if (direction == 'enter') {
					child.setAttribute('style', 'fill: rgb(171, 71, 189) !important; transition: 100ms');
				} else {
					child.setAttribute('style', `'fill: ${this.storedStyle} !important; transition: 350ms'`);
				}
			}
		});

		//display matched slice label
		const labels = document.getElementsByClassName('ct-label');
		Object.values(labels).forEach((element: any) => {
			if (element.innerHTML == labelValue && element.parentNode.parentNode.parentNode.id == 'chartPreferencesTele') {
				if (direction == 'enter') {
					element.setAttribute('style', 'fill: rgb(23, 23, 23) !important; transition: 100ms; text-shadow: 1px 1px 2px gainsboro;');
				} else {
					element.setAttribute('style', '\'fill: 0,0,0 !important; transition: 350ms\'');
				}
			}
		});
	}

	mouseEnter(i) {
		if (this.dataPreferences.series[i] !== '0.00') {
			this.sliceMatch(i, 'enter', this.dataPreferences.labels[i]);
		}
	}

	mouseLeave(i) {
		if (this.dataPreferences.series[i] !== '0.00') {
			this.sliceMatch(i, 'out', this.dataPreferences.labels[i]);
		}
	}

	buildPieTable(salesmen: any, sales: any) {
		const data = [];
		for (let index = 0; index < salesmen.length; index++) {
			data[index] = { salesman: salesmen[index], sales: sales[index] };
		}
		return new MatTableDataSource(data);
	}

	ngOnChanges(change: any) {

	}



}
