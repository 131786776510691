<div class="main-content">
	<div class="col-md-12">
		<div class="container-fluid tp-box" [@flipState]="flip">
			<div class="row tp-box__side tp-box__front" id="frontside" [ngClass]="flip == 'inactive' ? '' : 'd-none'">
				<div class="" [ngClass]="{'col-lg-4 col-md-4 col-sm-6': viewing_title!='Pickable Orders', 'col-lg-12 col-md-12 col-sm-12': viewing_title=='Pickable Orders'}" @grow>
					<app-pickable-orders (viewData)="toggleFlipTest($event)" ></app-pickable-orders>
				</div>

				<div class="" [ngClass]="{'col-lg-4 col-md-4 col-sm-6': viewing_title!='Picked', 'col-lg-12 col-md-12 col-sm-12': viewing_title=='Picked'}" @grow>
					<app-picking-orders (viewData)="toggleFlipTest($event)" ></app-picking-orders>
				</div>

				<div class="" [ngClass]="{'col-lg-4 col-md-4 col-sm-6': viewing_title!='Purchase Orders', 'col-lg-12 col-md-12 col-sm-12': viewing_title=='Purchase Orders'}" @grow>
					<app-purch-open-orders (viewData)="toggleFlipTest($event)" ></app-purch-open-orders>
				</div>

		</div>
	</div>
</div>
