import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DispatchService } from 'app/services/dispatch.service';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';

@Component({
	selector: 'app-dispatch-driver-unload',
	templateUrl: './dispatch-driver-unload.component.html',
	styleUrls: ['./dispatch-driver-unload.component.scss']
})
export class DispatchDriverUnloadComponent implements OnInit {
	color: 'blue';
	returnedTrucks: any = [];
	truckForm: any = false;
	showDelivered = false;
	formReady: boolean;
	barcodeForm: any;

	constructor(private fb: FormBuilder, public dispatchService: DispatchService, private searchService: GlobalSearchService) { }

	ngOnInit(): void {
		this.loadReturnedTrucks()
	}

	loadReturnedTrucks() {
		this.dispatchService.getReturnedTrucks().subscribe((res: any)=>{
			res.forEach(truck => {
				this.returnedTrucks.push(truck);
			});
		})
		this.setForms()
	}

	setTruckForm() {
		this.truckForm = this.fb.group({
			'truckref': new FormControl('')
		})
	}

	setBarcodeForm(){
		this.barcodeForm = this.fb.group({
			'barcoderef': new FormControl('')
		})
	}

	setForms(){
		this.setTruckForm();
		this.setBarcodeForm();
		this.formReady = true;
	}

	removePick(da: any, load, event: any) {

		const request = {
				item: {
					stockid: load.stockid,
					orderlineno: load.linenumber ? load.linenumber : load.lineno,
					bin: load.bin
				},
				dispatch: {
					orderno: load.orderno,
					ordertype: da.disp.type
				},
				validateByCode: true,
				barcode:event.target.value
			}

		if (load.loaded > 0) {
			this.dispatchService.unloadItemFromTruck(request).subscribe(results => {
				if (results.success) {
					load.loaded -= 1;
				}else{
					load['error'] = 'Scan did not match expected SKU';
					setTimeout(()=>{
						load.error = false;
					}, 5000);
				}
			})
		}
		event.target.value = '';
	}




}
