<ng-container *ngIf="camera_started">
	<app-bin-barcode (barcodeScanned)="barcodeScan($event)"></app-bin-barcode>
</ng-container>

<mat-stepper #stepper labelPosition="bottom" linear color="accent" *ngIf="binGroup" [(selectedIndex)]="step" >
	<mat-step [stepControl]="binGroup" errorMessage="Bin is required.">
		<ng-template matStepContent>
			<form [formGroup]="binGroup">
				<ng-template matStepLabel>Scan Bin</ng-template>
				<mat-form-field appearance="outline">
					<mat-label>Bin</mat-label>

					<input
					#bin
					type="text"
					matInput tabindex="0"
					placeholder="Bin"
					aria-label="Select Bin / Create Bin"
					[maxLength]="8"
					required
					formControlName="bin"
					class="uppercase"
					autofocus
					(keydown.enter)="isNotItem($event)"
					>
					<!-- (keydown.enter)="addingItems($event)" -->
				</mat-form-field>

				<div class="col-12 text-center mt-3">
					<div class="alert alert-danger" *ngIf="isNewBin() && binGroup.get('bin').value != '' ">
						<ng-container *ngIf="config.env.package == 'tires' && binGroup.get('bin').value != ''">
							Bin "<b>{{ binGroup.get('bin').value }}</b>" not on file, please see back office staff if you wish to proceed.
						</ng-container>
						<ng-container *ngIf="config.env.package != 'tires'">
							CREATING NEW BIN {{ binGroup.get('bin').value }}
						</ng-container>
					</div>

						<ng-container *ngIf="config.env.package == 'tires'">
							<button mat-button mat-raised-button color="accent" matStepperNext (click)="addingItems();" [disabled]="newbin">Next</button>
						</ng-container>
						<ng-container *ngIf="config.env.package != 'tires'">
							<button mat-button mat-raised-button color="accent" matStepperNext (click)="addingItems();">Next</button>
						</ng-container>

				</div>

			</form>
		</ng-template>
	</mat-step>
	<mat-step [stepControl]="itemGroup" >

			<ng-template matStepLabel>Add Items
				<span *ngIf="binGroup.valid"><br />{{ binGroup.get('bin').value | uppercase }}</span>
			</ng-template>
		<!--<ng-template matStepContent>-->
			<form [formGroup]="itemGroup">
				<!--
					<a tab-index="-1" mat-button matPrefix (click)="toggleCamera()">
					<i class="fa fa-camera"></i>&nbsp;&nbsp;(Use Camera)
				</a>
				-->
				<mat-divider></mat-divider>
				<mat-form-field appearance="outline">
					<mat-label>Search/Scan Items</mat-label>
					<input
					matInput
					#searchInput
					id="searchInput"
					type="text"
					tabindex="1"
					placeholder="Search For Items"
					aria-label="Search For Items"
					formControlName="searchInput"
					appFocusMe
					autofocus
					(keydown.enter)="itemSearchClick($event)">
					<button tab-index="-1" *ngIf="itemGroup.get('searchInput').value != ''" matSuffix mat-icon-button aria-label="Clear" (click)="itemGroup.get('searchInput').setValue('')">
						<mat-icon>close</mat-icon>
					</button>
					<mat-hint *ngIf="filtered_items.length"><b>ITEMS</b> ({{filtered_items.length}})</mat-hint>
				</mat-form-field>

				<div class="text-danger border-danger bold" *ngIf="noresults && !searching">
					NOT FOUND</div>
				<ng-container *ngIf="exitingitems">
					<span *ngFor="let e of exitingitems">
						<b>{{ e.stockid }} Already In List</b>
					</span>

				</ng-container>
				<ng-container *ngIf="added">
					<span class="text-success">{{ added.stockid }} Added</span>
				</ng-container>
				<div>
					<div class="row mt-4">
						<div class="col-md-6 mt-4">
							<h5>Search Results</h5>
							<mat-divider></mat-divider>
							<ng-container *ngIf="searching">
								<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
							</ng-container>
							<div class="text-danger border-danger bold" *ngIf="noresults">{{ lastsearch | uppercase }} NOT FOUND</div>
							<mat-list dense class="bin-box mt-3" *ngIf="filtered_items.length">
								<mat-list-item class="mat-list-item-bordered" *ngFor="let item of filtered_items" (click)="addSelectedItem(item);">
									<p matLine><b>{{ item.stockid }}</b> {{ item.bin }} : ({{ item.qoh }})</p>
									<p matLine>{{ item.description }} </p>
									<span class="ml-auto mr-0"><small>
											<mat-icon color="warning" class="small bold">add</mat-icon>
										</small></span>

								</mat-list-item>
							</mat-list>
							<mat-action-list class="bin-box mt-3" dense *ngIf="!filtered_items.length && !searching && !noresults">
								<mat-list-item class="text-warning bold" *ngIf="!noresults">Search For Items</mat-list-item>

							</mat-action-list>
						</div>
						<div class="col-md-6 mt-4">
							<h5>Added Items</h5>
							<mat-divider></mat-divider>

							<div class="col-md-6">
								<div *ngIf="sending">
									<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</div>
								<div *ngIf="affected">
									<h6 class="mt-2 mb-2">Affected</h6>
									<div class="row" *ngFor="let item of affected">
										<div class="col-12"> {{ item.stockid }} </div>
									</div>
								</div>
							</div>
							<mat-list dense class="bin-box mt-3" *ngIf="tobins.length">
								<mat-list-item *ngFor="let item of tobins" class="mat-list-item-bordered block-h90">


										<p matLine><b>{{ item.stockid }}</b> {{ item.bin }}: ({{ item.qoh}}) </p>
										<p matLine>{{ item.description }} </p>

										<div style="display: flex;">
											<md-input-container style="display: inline;" (click)="updateMoveQty(item, $event.target)">
												<input mdInput type="number" [value]="item.qoh" [min]="0" [max]="item.qoh" (keyup)="updateMoveQty(item, $event.target)">
											</md-input-container>
											<span class="ml-auto mr-0 margin-auto">
												<mat-icon color="accent" class="flex-center bold" (click)="removeSelectedItem(item)">cancel</mat-icon>
											</span>
										</div>
								</mat-list-item>
							</mat-list>
							<mat-list class="bin-box mt-3" dense *ngIf="!tobins.length">
								<mat-list-item class="text-warning bold">Select Items to Move</mat-list-item>
							</mat-list>
						</div>
					</div>
					<div class="col-12 text-center mt-3">
						<button mat-button mat-raised-button color="accent" matStepperPrevious>Back</button>&nbsp;&nbsp;
						<button mat-button mat-raised-button color="accent" matStepperNext>Review</button>
					</div>
				</div>
			</form>
		<!--</ng-template>-->

	</mat-step>
	<mat-step>
		<ng-template matStepLabel>Move Items</ng-template>
		<div class="col-md-12">
			<h5 class="bold" *ngIf="isNewBin()">{{ newOrCurrentBin() }}</h5>
			<mat-list dense class="bin-box" *ngIf="tobins.length">
				<mat-list-item *ngFor="let item of tobins" class="mat-list-item-bordered">
					<p matLine><b>{{ item.stockid }}</b> {{ item.bin }}: ({{moveQtyArray[item.stockid + item.bin].moveQty}}/{{ item.qoh}})</p>
					<p matLine>{{ item.description }} </p>
				</mat-list-item>
			</mat-list>
		</div>

		<div *ngIf="sending">
			<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
				<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
			</svg>
		</div>
		<div>
			<div class="col-12 text-center mt-3">
			<button mat-button mat-raised-button color="accent" matStepperPrevious>
				Back
			</button>&nbsp;&nbsp;
			<button *ngIf="this.binGroup.valid && tobins.length && !sending" mat-raised-button color="warn"  (click)="moveItems();">Move Items</button>
			</div>
		</div>
	</mat-step>
</mat-stepper>
