<footer class="footer ">
    <div class="container-fluid">
        <nav class="pull-left">
            <ul>
                <li>
                </li>
            </ul>
        </nav>
        <div class="copyright pull-right">
            &copy; {{ version }}
            <!-- {{test | date: 'yyyy'}}. <a href="https://www.qbc.com" target="_blank">QBC Systems</a>. -->
        </div>
    </div>
</footer>
