<div class="row">
	<mat-card class="tire-card " *ngIf="item_details">
		<mat-card-title-group>
			<mat-card-title>
				<h3 [innerHTML]="cleanAndFormatHTML(item_details.descriptions.name)"></h3>
				<section class="section-small" [innerHTML]="cleanAndFormatHTML(item_details.descriptions.description)"></section>
			</mat-card-title>
			<div class="tire-image">
				<div class="tire-image-container" *ngIf="item_details.image_large">
					<img mat-card-lg-image src="{{ item_details.image_large }}" class="img img-responsive large-thumb" lazy>
				</div>
			</div>
		</mat-card-title-group>
		<mat-card-content class="padding-0">
			<mat-tab-group #tabPanel mat-align-tabs="start" color="accent">
				<mat-tab label="Overview">
					<div class="row">
						<div class="col-md-6">
							Features:<br>
							<span [innerHTML]="cleanAndFormatHTML(item_details.descriptions.features)"></span>
						</div>
						<div class="col-md-6">
							Benefits:<br>
							<span [innerHTML]="cleanAndFormatHTML(item_details.descriptions.benefits)"></span>
						</div>
					</div>
				</mat-tab>
				<mat-tab label="Attributes">
					<div class="row">
						<div class="col-md-6">
							<ul class="spec-list" *ngFor="let a of item_details.additional; let i = index">
								<li *ngIf="!(i % 2)">
									<div class="spec-label"> {{ a.label }}</div>
									<div class="spec-value"> {{ a.value }}</div>
								</li>
							</ul>
						</div>
						<div class="col-md-6">
							<ul class="spec-list" *ngFor="let a of item_details.additional; let i = index">
								<li *ngIf="i % 2">
									<div class="spec-label ml-1"> {{ a.label }}</div>
									<div class="spec-value"> {{ a.value }}</div>
								</li>
							</ul>
						</div>
					</div>
				</mat-tab>
				<mat-tab label="Specs">
					<!-- <mat-form-field appearance="standard" *ngIf="!addingattribute" class="text-right">
						<mat-label>Search Attributes</mat-label>
						<input type="text" class="text-right m-0 p-0" matInput (input)="searchAttributes($event.target.value)" />
					</mat-form-field> -->

					<div class="row">
						<div class="col-6">
							<ul class="spec-list" *ngFor="let a of item_details.specs; let i = index">
								<li *ngIf="!(i % 2)">
									<div class="spec-label"> {{ a.label }}</div>
									<div class="spec-value"> {{ a.value }}</div>
								</li>
							</ul>
						</div>
						<div class="col-6">
							<ul class="spec-list" *ngFor="let a of item_details.specs; let i = index">
								<li *ngIf="i % 2">
									<div class="spec-label ml-1"> {{ a.label }}</div>
									<div class="spec-value"> {{ a.value }}</div>
								</li>
							</ul>
						</div>
					</div>
				</mat-tab>
			</mat-tab-group>
		</mat-card-content>
	</mat-card>
</div>
