<div class="container-fluid tp-box" [@flipState]="flip" >
	<div class="row tp-box__side tp-box__back" *ngIf="flip == 'active'">
		<div class="card" >
			<div class="card-header card-header-success card-header-icon">
				<div class="card-icon pointer" (click)="toggleFlip(false, '')">
					<i class="material-icons">done</i>
				</div>
				<h3 class="card-title">{{ title }} </h3>
			</div>
			<div class="card-body"  *ngIf="viewing">
				<app-open-orders [orderstatus]="data" (orderloaded)="selectItemLookup($event)" (preorderloaded)="selectItemLookup($event)" (orderinvoice)="invoiceOrder($event)"></app-open-orders>
			</div>
		</div>
	</div>
	<div class="row tp-box__side tp-box__front" id="frontside" [ngClass]="flip == 'inactive' ? '' : 'd-none'">
		<div class="card card-stats">
			<div class="card-header card-header-success card-header-icon">
				<div class="card-icon pointer" (click)="toggleFlip(data, title)">
					<i class="material-icons">done</i>
				</div>
				<p class="card-category">{{ title }}</p>
				<h3 class="card-title">
					<span *ngIf="data">
						{{ data.length }}
					</span>
					<span *ngIf="!data">
						<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</span>
				</h3>
				<small>
					<p class="card-category text-info text-justify mt-3 text-right">
						&nbsp;
					</p>
				</small>
			</div>
			<div class="card-footer">
				<div class="stats pointer">
					<a (click)="toggleFlip(data, title)">
						<i class="material-icons">content_copy</i>
						View Now</a>
				</div>
				<br />
			</div>
		</div>
	</div>
</div>
