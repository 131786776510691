import { Component, OnInit , Input, Output,EventEmitter, ViewChild, AfterViewInit,ElementRef, AfterContentInit } from '@angular/core';
import { BarcodeScannerLivestreamComponent } from 'ngx-barcode-scanner';

@Component({
	selector: 'app-bin-barcode',
	templateUrl: './bin-barcode.component.html',
	styleUrls: ['./bin-barcode.component.scss']
})

//upc
export class BinBarcodeComponent implements OnInit {

	@Output() barcodeScanned = new EventEmitter<string>();
	@ViewChild(BarcodeScannerLivestreamComponent)
		barcodeScanner: BarcodeScannerLivestreamComponent;

	constructor() { }

	ngOnInit(): void {

	}

	barcodeValue;

	ngAfterViewInit() {
		this.barcodeScanner.start();
	}

	onValueChanges(result) {
		this.barcodeValue = result.codeResult.code;
		this.barcodeScanned.emit(result.codeResult.code)
	}

	onStarted(started) {

	}

}
