
<h3>Current Counts Entered</h3>
<div class="row mb-2">
	<div class="col-5 ">
		<!-- <a class="btn btn-danger" href="{{printlink}}">

		</a> -->
		<button class="btn btn-danger" (click)="exportPdf()" *ngIf="items && !count_results">
			<i class="fa fa-file-pdf-o"></i>&nbsp;&nbsp;PDF
		</button>

		<button class="btn btn-danger" (click)="exportPdfTwo()" *ngIf="count_results">
			<i class="fa fa-file-pdf-o"></i>&nbsp;&nbsp;PDF FINAL
		</button>
	</div>
	<div class="col-1  text-right">
		<span class="text-right" *ngIf="fetching">
			<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
				<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
			</svg>
		</span>
	</div>
	<div class="col-6 text-right">
		<button type="button" id="button-close" class="btn btn-dark" (click)="closeCounts()" *ngIf="!count_results"> Close Counts And Adjust Stock </button>
		<button type="button" class="btn btn-danger" (click)="clearCounts()" > RESET</button>
	</div>
</div>


<div class="container-full" *ngIf="count_results">
	<div class="table" #print_table_two>
	<h4><b>Stock Count Results #{{ transno }}</b></h4>
	<table class="table table-fixed table-hover table-striped table-bordered pure-table pure-table-bordered">
		<thead>
			<tr>
				<th class="text-center">VENDOR</th>
				<th class="text-center">ITEM</th>
				<th class="text-center">BIN</th>
				<th class="text-center" >DESCRIPTION</th>
				<th class="text-center " >QOH</th>
				<th class="text-center" >ADJUSTMENT#</th>
				<th class="text-center " >COST</th>
				<th class="text-center" >ADJUSTMENT$</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let item of count_results">
				<td class="text-center">{{ item.vendor }}</td>
				<td class="text-center">{{ item.partno }}</td>
				<td class="text-center">{{ item.bin }}</td>
				<td class="text-center">{{ item.description }}</td>
				<td class="text-center">{{ item.qoh }}</td>

				<td class="text-center">{{ item.movement }}</td>
				<td class="text-center">{{ item.standardcost | currency }}</td>

				<td class="text-center">{{ item.adjustment | currency }}</td>
			</tr>
			<tr>
				<th class="text-left subhead">TOTALS</th>
				<th class="text-center subhead"></th>
				<th class="text-center subhead"></th>
				<th class="text-center subhead"></th>
				<th class="text-center subhead"></th>
				<th class="text-center subhead"></th>
				<th class="text-center subhead">{{ getTotalFinalQty() }}</th>
				<th class="text-right subhead">{{ getTotalFinalValue() | currency }}</th>
			</tr>
		</tbody>
	 </table>
	</div>
</div>
<div class="table" *ngIf="items && !count_results" #print_table>
	<table class="table table-fixed table-hover table-striped table-bordered pure-table pure-table-bordered ">
		<thead>
			<tr>
				<th class="text-left">ITEM</th>
				<th class="text-left">BIN</th>
				<th class="text-left " >DESCRIPTION</th>
				<th class="text-right" >COST</th>
				<th class="text-center " >QOH</th>
				<th class="text-center " >COUNTED</th>
				<th class="text-center" >ADJUSTMENT QTY</th>
				<th class="text-right" >ADJUSTMENT$</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let item of items; let i = index;">
				<td class="text-left">{{ i + 1}}. {{ item.item }}</td>
				<td class="text-left">{{ item.bin }}</td>
				<td class="text-left">{{ item.description }}</td>
				<td class="text-right">{{ item.standardcost | currency }}</td>
				<td class="text-center">{{ item.qoh }}</td>
				<td class="text-center">{{ item.counted }}</td>
				<td class="text-center">{{ item.adjustment }}</td>
				<td class="text-right">{{ lineTotal(item) | currency }}</td>
			</tr>
		</tbody>
		<tr>
			<th class="text-left subhead">TOTALS</th>
			<th class="text-center subhead"></th>
			<th class="text-center subhead"></th>
			<th class="text-center subhead"></th>
			<th class="text-center subhead"></th>
			<th class="text-center subhead"></th>
			<th class="text-center subhead">{{ getTotalQty() }}</th>
			<th class="text-right subhead">{{ getTotalValue() | currency }}</th>
		</tr>
 	</table>
</div>
