import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, Injectable ,ElementRef } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { DataService } from '../../data.service';
import { ChatService } from '../../services/chat.service';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { TableModule } from 'primeng/table';
import { DailySales } from '../../classes/reports';
import { DashboardService } from '../../services/dashboard.service';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';
import { GlobalsService } from '../../services/globals.service';
import { Message } from '../../classes/message';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { UsersService } from '../../services/users.service';
import { InventoryService } from '../../services/inventory.service';
import { PrintService } from '../../services/print.service'
import { trigger, state, style, transition, animate } from '@angular/animations';
import * as Chartist from 'chartist';

import {
	startOfDay,
	endOfDay,
	subDays,
	addDays,
	endOfMonth,
	isSameDay,
	isSameMonth,
	addHours,
} from 'date-fns';
import {
	CalendarEvent,
	CalendarEventAction,
	CalendarEventTimesChangedEvent,
	CalendarView,
} from 'angular-calendar';


@Component({
	selector: 'app-inventory-dashboard',
	templateUrl: './inventory-dashboard.component.html',
	styleUrls: ['./inventory-dashboard.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotateY(179deg)'
			})),
			transition('active => inactive', animate('500ms ease-out')),
			transition('inactive => active', animate('500ms ease-in')),
			transition('inactive => orderlookup', animate('500ms ease-out')),
			transition('orderlookup => inactive', animate('500ms ease-in')),
		])
	]
})
export class InventoryDashboardComponent implements OnInit {

	@ViewChild('print_table') printtable: ElementRef;
	info: any = [];
	openorders: any = [];
	negativestock: any = 'notready';
	needlocation: any = 'notready';
	missingpurch: any = 'notready';
	missingimages: any = 'notready';
	remotenewitems: any = 'notready';
	allitems: any = 'notready';
	boms: any = 'notready';
	viewing: any = '';
	flip = 'inactive';
	filename = 'inventory';
	ordersbyday: any = [];
	max: any = [];
	percentup: any = [];
	newMessage: string;
	messageList: string[] = [];
	sender;

	SENDER;
	messages = [];
	message: any = [];;
	CHAT_ROOM = 'DashBoard';
	messageForm: any = [];;
	carddate: any = [];;
	posbyday: any = [];;
	orderpweek: any = [];;
	orderweek: any = [];;
	popweek: any = [];;
	poweek: any = [];;
	popercentup: any = [];;
	taxorders: any = [];
	zerocost: any = [];
	missingbrands: any = [];
	public dataDailySalesChart: any = {
		labels: [],
		series: [],
	};

	userData: any = [];;
	user: any = [];

	view: CalendarView = CalendarView.Month;
	CalendarView = CalendarView;
	viewDate: Date = new Date();
	modalData: {
		action: string;
		event: CalendarEvent;
	};

	color = 'blue'
	needloclink: any = '';
	config: any = false;
	constructor(private globalsService: GlobalsService,public dashboardService: DashboardService, private printService: PrintService,private inventoryService: InventoryService, public dataService: DataService, public formBuilder: UntypedFormBuilder, private globalSearchService: GlobalSearchService, private usersService: UsersService) {
		this.color =this.globalSearchService.getColor();
		this.config = this.globalsService.getApiConfig()

		this.globalSearchService.user.subscribe((results: any) => {
			if (results) {
				this.user = results.user;
			}
		});
	}

	ngOnInit() {

		this.userData = this.usersService.getLocalUser();

		this.openorders = 'notready';
		this.needlocation = 'notready';
		this.negativestock = 'notready';
		this.zerocost = 'notready';

		this.inventoryService.getNegative().subscribe(async (results: any) => {
			this.negativestock = results;
		});

		this.inventoryService.getAllItems().subscribe(async (results: any) => {
			this.allitems = results;
			this.globalSearchService.allItems.next(results)
		});

		this.inventoryService.getItemsMissingBrand().subscribe(async (results: any) => {
			this.missingbrands = results;
		});


		this.needloclink = this.config.apiServer.baseUrl+'reports/report/html/?report=samples/221122023111.sql';

		this.dashboardService.getNeedLocation().subscribe((results: any) => {
			this.needlocation = results.data;
		});

		this.inventoryService.getMissingPurchaseData().subscribe(async (results: any) => {
			this.missingpurch = results;
		});

		this.inventoryService.getMissingImages().subscribe(async (results: any) => {
			this.missingimages = results;
		});

		this.inventoryService.getRemoteNewItems().subscribe(async (results: any) => {
			this.remotenewitems = results;
		});

		this.inventoryService.getZeroCost().subscribe(async (results: any) => {
			this.zerocost = results;
		});



	}

	exportPdf() {

		const encoded: string = btoa(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}
	exportXls() {
		const encoded: string = btoa(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

	toggleFlip(viewing: any) {
		this.flip = (this.flip == 'inactive') ? 'active' : 'inactive';
		this.viewing = viewing;
	}

	randomString(length, chars) {
		let result = '';
		for (let i = length; i > 0; --i) {result += chars[Math.floor(Math.random() * chars.length)];}
		return result;
	}

}
