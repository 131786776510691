import { Component, OnDestroy, OnInit,ViewChild,ChangeDetectorRef ,AfterViewInit,ElementRef } from '@angular/core';

import { Location } from '@angular/common'

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GeneralLedgerService } from '../../services/general-ledger.service';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

@Component({
	selector: 'app-balance-sheet',
	templateUrl: './balance-sheet.component.html',
	styleUrls: ['./balance-sheet.component.scss']
})
export class BalanceSheetComponent implements OnInit {
	periods: any = [];
	default_period: any = [];
	default_toperiod: any = '';
	default_fromperiod: any = '';
	excellink: any = '';
	pdflink: any = '';
	formGroup: UntypedFormGroup;
	loading = false;
	trial_balance: any = [];
	repdate = '';
	title = '';
	from_period_options: any = [];
	to_period_options: any = [];
	trial_balance_totals: any = [];
	color: any ='';
	constructor(private location:Location,private generalLedgerService: GeneralLedgerService, private globalSearchService: GlobalSearchService, private fb: UntypedFormBuilder) { }

	ngOnInit(): void {
		this.color = this.globalSearchService.getColor();
		this.generalLedgerService.getPeriods().subscribe(async (results: any) => {
			this.periods = results.periods
			this.from_period_options = results.periods

			this.formGroup = this.fb.group({
				from_period: [results.default_fromperiod, [Validators.required]],
			});
		});
	}
	back() {
  	this.location.back()
	}
	getTrialBalance() {

		if(this.formGroup.valid) {
			this.loading= true;
			this.generalLedgerService.getBalanceSheet(this.formGroup.value).subscribe(async (results: any) => {
				this.loading= false;
				this.title = results.title;
				this.repdate = results.repdate;
				this.trial_balance = results.data;
				this.trial_balance_totals = results.total;
				this.excellink = results.excellink;
				this.pdflink = results.pdflink;
			});
		}

	}

	getTrialBalanceExp(type: any) {

		if(this.formGroup.valid) {
			this.loading= true;
			if(type == 1) { //PDF

			} else if (type == 2) {//XLS


			}
			this.generalLedgerService.getBalanceSheet(this.formGroup.value).subscribe(async (results: any) => {
				this.loading= false;
				this.title = results.title;
				this.repdate = results.repdate;
				this.trial_balance = results.data;
				this.trial_balance_totals = results.total;

			});
		}

	}


}
