<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card tp-box">
					<div class="card-header card-header-{{color}}-5">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i>
							Payment
						</h4>
						<div class="card-category" *ngIf="order.header">
							<div class="row m-0 p-0">
								<div class="col-4">
									<b>{{ debtorno }}.{{ branchcode }} {{ order.header.name }} </b>
								</div>
								<div class="col-8 text-right">
									#{{ orderno }}
								</div>
							</div>
						</div>
					</div>
					<div class="card-body">
						<ng-container *ngIf="orderno" >
							<app-pos-order-invoice [orderno]="orderno" [ctlordirect]="'direct'" (reloadCustomer)="emitCustomerUpdate($event);" (invoiced)="showInvoiceResultandReset($event)" *ngIf="orderno"> </app-pos-order-invoice>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
