import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { merge, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';

import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { PurchasingService } from '../../services/purchasing.service';
import { OrdersService } from '../../services/orders.service';
import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { OmsService } from '../../services/oms.service';
import { trigger, state, style, transition, animate, animation } from '@angular/animations';

@Component({
	selector: 'app-purchasing-success',
	templateUrl: './purchasing-success.component.html',
	styleUrls: ['./purchasing-success.component.scss']
})
export class PurchasingSuccessComponent implements OnInit {
	user: any;
	config: any;

	total_qty = 0;
	totals: any = [];
	CHAT_ROOM = 'OrderBoard';
	taxgroups: any = [];
	salespeople: any = [];
	shipvia: any = [];
	terms: any = [];
	zones: any = [];
	locations: any = [];
	holdreasons: any = [];

	itemSearchForm: UntypedFormGroup;
	vendorFormGroup: UntypedFormGroup;

	vendor: any = [];

	limits = [
		{ name: '25', value: '25' },
		{ name: '50', value: '50' },
		{ name: '100', value: '100' },
		{ name: '150', value: '150' },
		{ name: '250', value: '200' },
		{ name: '500', value: '500' },
		{ name: 'No Limit', value: false },
	];

	vendorinfo = true;
	searching = false;
	dspcost = true;
	complete = false;
	editing_order = false;
	inventorysearching: any = false;

	search_items: any = [];
	filtered_items: any = [];
	cart_items: any = [];
	total_cart_qty = 0;
	cart_totals: any = [];
	summary: any = [];
	color: any = 'blue'
	baselink: any = [];
	ponumber: any = false;
	recdoc: any = false;

	constructor(private omsService: OmsService, private router: Router, private fb: UntypedFormBuilder, private location: Location, private route: ActivatedRoute, private purchasingService: PurchasingService, private globalSearchService: GlobalSearchService, private ordersService: OrdersService, private globalsService: GlobalsService) {
		this.config = this.globalsService.getApiConfig()
		this.color = this.globalSearchService.getColor();
		this.baselink = this.config.apiServer.baseUrl + this.config.apiServer.poLink;
		this.recdoc = this.config.apiServer.baseUrl + this.config.apiServer.recLink;
	}

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			if (params['id']) {
				const supplierid = params['id'];
				this.purchasingService.getSuppliers(supplierid).subscribe((result: any) => {
					this.vendor = result;
					if (params['order']) {
						this.editing_order = false;
						this.ponumber = params['order'];
						this.purchasingService.getPurchaseOrder(params['order']).subscribe((results: any) => {
							this.complete = true;
							this.summary = results;
						});
					}
				});
			}
		});
	}

	back(): void {
		this.location.back()
	}

	ngAfterViewInit(): void {

	}

}
