
<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i> Vendor Sales by Branch
						</h4>
						<p class="card-category text-right">VRPT</p>
					</div>
					<div class="card-body">
						<app-vrpt-content ></app-vrpt-content>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
