
<div class="modal" tabindex="-1" role="dialog" id="runList">
  <div class="modal-dialog modal-xl" role="document">
	<div class="modal-content">
	  <div class="modal-header" >
		<h4 class="modal-title" *ngIf="active_run">Route List: {{ active_run.name }} x {{ totalonrun  }} </h4>
		<button type="button" class="close" data-dismiss="modal" aria-label="Close">
		  <span aria-hidden="true">&times;</span>
		</button>
	  </div>
	  <div class="modal-body" *ngIf="runlist">
		  <div class="row border-bottom pickitem" *ngFor="let item of runlist">
			<div class="col-10 text-left">
				<b>DESCRIPTION:</b> {{ item.description }}<br>
			</div>
			<div class="col-2 text-right">
				QTY {{ item.ordered }} | QOH {{ item.qoh }}
			</div>
			<div class="offset-lg-1 col-6 text-left text-nowrap">
				ITEM: <b>{{ item.stockid }}</b>
				<br />
				ORDER: <span class="text-primary"><b>{{ item.orderno }}</b></span>
			</div>
			<div class="col-5 text-right">
				<h6>BIN: <span class="text-primary"><b>{{ item.bin }}</b></span></h6>
				<h6 class="text-primary">{{ item.deliverydate | dateAgo | uppercase  }}</h6>
			</div>
		</div>
	  </div>
	  <div class="modal-footer">
		<button type="button" class="btn btn-primary d-none">Save changes</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
	  </div>
	</div>
  </div>
</div>

<div class="modal fade" id="detailsModal" tabindex="-1" role="dialog" aria-labelledby="detailsModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-xl" role="document">
		<div class="modal-content">
			<div class="modal-header" >
				<h4 class="modal-title" id="detailsModalLabel" > </h4>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close" >
					<span aria-hidden="true">&times;</span>
				</button>
			</div>


			<div class="modal-body" id="columnfocus" >
				<div class="row">
					<div class="col-8 mt-0 pt-0 mb-0 pb-0" id="map"></div>
					<div class="col-4 ">
						<ul class="list-group"  *ngIf="focusdata">
							<li class="list-group-item" *ngFor="let column of focusdata; let i = index;">
								<i class="material-icons">dot</i>
								<p class="label label-danger">{{ column.orderno }}</p>
								<p [innerHTML]="column.content"></p>
								<h6><i class="ti-time"></i> {{ column.date_updated }}  </h6>
							</li>
						</ul>
					</div>
				</div>
				<div class="modal-footer">
					<div class="row">
						<div class="col-xs-6" id="distance"></div>
						<div class="col-xs-6" id="time"></div>
						<div class="col-xs-12 text-right">
							<button type="button" class="btn btn-secondary" data-dismiss="modal" >Close</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="main-content">
	<div class="container-fluid">
		<div class="col-md-12">
			<div class="card">
				<div class="card-header card-header-tt" >
					<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Order Pick</h4>
					<p class="card-category"></p>
						<div class="messages">
						<ng-select [items]="sortcolumns"
							bindLabel="name"
							[multiple]="true"
							autofocus
							bindValue="id"
							[(ngModel)]="selectedColumn" *ngIf="sortcolumns">
						</ng-select>


					</div>
				</div>
				<div class="card-body" *ngIf="pickable">
					<div class="row" cdkDropListGroup>
						<div class="col-6 col-md-2 col-lg-2 pl-0 pr-0 ml-0 mr-0 p-0">
							<div class=" ml-0 mr-0 ">
								<div class="drag-container ">
									<div class="section-heading">OPEN ORDERS <button class="btn btn-sm btn-primary " (click)="exportExcel(filteredItems)">XLS</button></div>
									<div class="col-12">
										<input type="text" #filterSearch (input)="onInput($event)" (keyup)="onInput($event)" placeholder="Search Orders" class="form-control">
									</div>

									<div cdkDropList #openList="cdkDropList" [cdkDropListData]="filteredItems" class="item-list scrolling-left " (cdkDropListDropped)="drop($event, 'openlist')" *ngIf="filteredItems">
									<div class="card item-box mt-0 mb-1 boxhide" *ngFor="let item of filteredItems" cdkDrag >
										<div class="card-body m-2 pl-0 pr-0 pt-4 boxhide" *ngIf="item.allowed">
											<p>
												<span class="text-primary"><b>{{ item.order }}</b></span>
												<br>
												<span [innerHTML]="item.address"></span>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

						<div class="col-6 col-md-10 col-lg-10 p-0" *ngIf="sortcolumns">
								<div class="small" *ngFor="let column of sortcolumns">
							 		<div class="p-0 m-0 " *ngIf="sortcolumns">
										<div class="row p-0 m-0" *ngIf="selectedColumn && selectedColumn.includes(column.id)" >
											<div class="row p-0 m-0 table">
													<div class="col-12 col-lg-12 pt-0 pb-0 mt-0 mb-0 card-header pl-2 pr-0 mr-0 ml-0 card-header-primary ">
														<b>{{ column.name }}</b>&nbsp; &nbsp; &nbsp;<b>Orders: ({{ carddata[column.id].length }}) </b> &nbsp; &nbsp; &nbsp;
														<button class="order-details btn btn-sm btn-primary" data-toggle="modal" data-target="#detailsModal" (click)="drawMap(column.id)"><b>Map</b></button>
														<button class="btn btn-sm btn-primary" (click)="getRecalc(column.id)"><b>Recalc</b></button>
														<button class="btn btn-sm btn-primary" (click)="clearRun(column.id)"><b>Clear</b></button>
														<button class="btn btn-sm btn-primary" (click)="submitDispatch(column.id)"><b>Commit TO DISPATCH</b></button>
														<button class="btn btn-sm btn-primary " (click)="exportExcel(carddata[column.id])">XLS</button>
														<button class="btn btn-sm btn-primary " data-toggle="modal" data-target="#runList" (click)="runList(column.id)"><i class="material-icons">list</i></button>



													</div>
													<div class="col-12 col-lg-12 scrolling-wrapper  pl-0 ml-0">
														<div id="column-{{column.id}}" cdkDropList [cdkDropListData]="carddata[column.id]" cdkDropListOrientation="horizontal" class="item-list" (cdkDropListDropped)="drop($event, column.id)">
															<div *ngIf="carddata[column.id]">
																<div class="card item-box pt-0 pl-2 mt-0 m-0 boxhide" *ngFor="let item of carddata[column.id]" cdkDrag [ngClass]="completed_orders.includes(item.order) ? 'card-header-info':''">
																	<div class="card-body m-2 p-0 pb-2 mt-0 boxhide">
																		<span class="text-primary" ><b>{{ item.order }} {{ item.sort }}</b></span><br><span [innerHTML]="item.address"></span>
																	</div>
																</div>
															</div>
														</div>
													</div>
											</div>
										</div>


										<div class="row p-0 m-0 table" *ngIf="!selectedColumn">
											<div class="row p-0 m-0 table">
												<div class="col-12 col-lg-12 pt-0 pb-0 mt-0 mb-0 card-header pl-2 pr-0 mr-0 ml-0 card-header-primary ">
													<b>{{ column.name }}</b>&nbsp; &nbsp; &nbsp;<b>Orders: ({{ carddata[column.id].length }}) </b> &nbsp; &nbsp; &nbsp;
													<button class="order-details btn btn-sm btn-primary" data-toggle="modal" data-target="#detailsModal" (click)="drawMap(column.id)"><b>Map</b></button>
													<button class="btn btn-sm btn-primary" (click)="getRecalc(column.id)"><b>Recalc</b></button>
													<button class="btn btn-sm btn-primary" (click)="clearRun(column.id)"><b>Clear</b></button>
													<button class="btn btn-sm btn-primary" (click)="submitDispatch(column.id)"><b>Commit TO DISPATCH</b></button>
													<button class="btn btn-sm btn-primary " (click)="exportExcel(carddata[column.id])">XLS</button>
													<button class="btn btn-sm btn-primary " data-toggle="modal" data-target="#runList" (click)="runList(column.id,column)"><i class="material-icons">list</i></button>

												</div>
												<div class="col-12 col-lg-12 scrolling-wrapper  pl-0 ml-0">
													<div id="column-{{column.id}}" cdkDropList [cdkDropListData]="carddata[column.id]" cdkDropListOrientation="horizontal" class="item-list" (cdkDropListDropped)="drop($event, column.id)">
														<div *ngIf="carddata[column.id]">
															<div class="card item-box pt-0 pl-2 mt-0 m-0 boxhide" *ngFor="let item of carddata[column.id]" cdkDrag [ngClass]="completed_orders.includes(item.order) ? 'card-header-success text-white':''">
																<div class="card-body m-2 p-0 pb-2 mt-0 boxhide">
																	<span class="text-primary" ><b>{{ item.order }} {{ item.sort }}</b></span><br><span [innerHTML]="item.address"></span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
				</div>
			</div>
		</div>
	</div>
</div>
