<div class="main-content">
	<div class="container-fluid">
		<div class="card" *ngIf="forcesearch">
			<div class="card-body">
				<app-order-search></app-order-search>
			</div>
		</div>
		<div class="card" *ngIf="!forcesearch">
			<app-order-view></app-order-view>
		</div>
	</div>
</div>