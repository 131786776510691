import { Component, OnInit, Input,ChangeDetectorRef,OnChanges, ViewChild, ElementRef } from '@angular/core';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { GlobalsService } from '../../services/globals.service'
import { InventoryService } from '../../services/inventory.service'
import { PrintService } from '../../services/print.service'
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors, FormControl, FormGroup, FormArray } from '@angular/forms';
import { interval, Observable } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';


@Component({
	selector: 'app-inventory-export',
	templateUrl: './inventory-export.component.html',
	styleUrls: ['./inventory-export.component.scss']
})
export class InventoryExportComponent implements OnInit {
	@Input() data: any = false;
	filename = 'inventory_export';
	@ViewChild('print_table') printtable: ElementRef;
	public searchForm: UntypedFormGroup;
	sending: any = false;
	config: any = [];
	user: any = false;
	tabledata: any = [];
	rawdata: any = false;
	brands: any = [];
	categories: any = [];
	plines: any = [];
	fromdate: any = '';
	todate: any = '';
	pricenames: any = [];
	showimage: any = false;
	headercolumns: string[] = [
		'stockid',
		'categoryid',
		'lineid',
		'description',
		'materialcost',
		'discontinued',
		'barcode',
		'image',
		'enabled',
		'dwo',
		'sort',
		'sort2',
		'date_created',
	];

	private sort: MatSort;
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.setDataSourceAttributes();
	}

	@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
		this.paginator = mp;
		this.setDataSourceAttributes();
	}

	setDataSourceAttributes() {
		this.dataSource.paginator = this.paginator;
	}
	
	dataSource: MatTableDataSource < any > ;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataObs: Observable < any > ;
	items_per_page = [20 , 100, 250, 500, 1000];
	pagesizedefault = 100;

	constructor(private fb: UntypedFormBuilder, private globalsService: GlobalsService, private _changeDetectorRef: ChangeDetectorRef, private inventoryService: InventoryService, private globalSearchService: GlobalSearchService, private printService: PrintService) {

		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
			this.globalSearchService.user.subscribe(u => {
				this.user = u
			})
		});
		
		this.inventoryService.getItemCategories().subscribe((res) => {
			Object(res).sort((a,b) => (a.categorydescription > b.categorydescription) ? 1 : ((b.categorydescription > a.categorydescription) ? -1 : 0))
			this.categories = res;
		});
		this.inventoryService.getItemProductlines().subscribe(p => {
			this.plines = p
		});
		this.globalsService.getItemSetup().subscribe((results: any) => {
			this.brands = results.brands
		});
	}

	ngOnInit(): void {
	
	
	
	const today = new Date();
		const priorDate = new Date(today.getFullYear()-1, today.getMonth(), today.getDate()-1);
		this.fromdate = priorDate;
		this.todate = today;
		
		this.searchForm = this.fb.group({
			fromDateCtrl: [''],
			toDateCtrl: [''],
			brand: [''],
			category: [''],
			plines: [''],
			images: ['0'],
			quantity:['0'],
			status: ['1'],
			showprice: ['0'],			
		});
			this.getItemExport();
	}

	
	setPagination(tableData) {
		this.dataSource = new MatTableDataSource <any> (tableData);
		this._changeDetectorRef.detectChanges();
		this.dataSource.paginator = this.paginator;
		this.dataObs = this.dataSource.connect();
	}
	
	getItemExport(){
	
		this.sending = true;
		if(this.searchForm.get('images').value == 1){
		this.showimage = true;
		} else {
		this.showimage = false;
		}

		this.inventoryService.getItemExport(this.searchForm.value).subscribe(r => {
			this.sending = false;
			this.data = r.data;
			this.pricenames = r.pricenames;
			this.setPagination(this.data);
		});
	}


	exportPdf() {

		const encoded: string = this.globalSearchService.base64encode(this.buildHtmlForExport());
		const today = new Date();
		const data = {
			content: encoded,
			filename: this.filename + today.getMonth() + today.getDate()+today.getFullYear(),
			title: ' Item Export ',
			subtitle: ' created '+today.toLocaleString()
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	exportXls() {
		const today = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.buildHtmlForExport());
		const data = {
			content: encoded,
			filename: this.filename+ today.getMonth() + today.getDate()+today.getFullYear(),
			title: ' Item Export ',
			subtitle: ' created '+today.toLocaleString()
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}
	
	getPrice(item: any, pricelv: any){
	console.log(item);
	console.log(item.prices);
	const priceval = item.prices[pricelv];
		if (priceval){
		return priceval;
		} else {
		return 0;
		}
	}

	buildHtmlForExport() {
		let html = '';
		if(this.data) {
			html = '<table class="table table-fixed table-sm" #print_table>';
			html += '<thead>';
			html += '<tr>';
			html += '<th>Item#</th>';
			html += '<th>Category</th>';
			html += '<th>ProductLine</th>';
			html += '<th>Description</th>';
			html += '<th>Material Cost</th>';
			html += '<th>Discontinued</th>';
			html += '<th>Barcode</th>';
			if(this.showimage == true){
			html += '<th>Image</th>';
			}
			html += '<th>Enabled</th>';
			html += '<th>DWO</th>';
			html += '<th>Sort</th>';
			html += '<th>Sort2</th>';
			html += '<th>Created</th>';
			this.pricenames.forEach(function(value) {
			html += '<th>'+value.sales_type+'</th>';
			});
			html += '</tr>';
			html += '</thead>';
			html += '<tbody>';

			this.data.forEach( (d:any) => {
				html += '<tr *ngFor="let d of data" class="donotshow">'
				html += '<td>'
				html += d.stockid
				html += '</td>'
				html += '<td>' +d.categoryid+'</td>'
				html += '<td>' +d.lineid+'</td>'
				html += '<td>' +d.description+'</td>'
				html += '<td>' +d.materialcost+'</td>'
				html += '<td>' +d.discontinued+'</td>'
				html += '<td>' +d.barcode+'</td>'
				if(this.showimage == true){
				html += '<td>' +d.image+'</td>'
				}
				html += '<td>' +d.enabled+'</td>'
				html += '<td>' +d.dwo+'</td>'
				html += '<td>' +d.sort+'</td>'
				html += '<td>' +d.sort2+'</td>'
				html += '<td>' +d.date_created+'</td>'
				this.pricenames.forEach(function(value) {
				html += '<th>'+ (d.prices[value.sales_type] ? d.prices[value.sales_type] : '0.00') +'</th>'
				});
				html += '</tr>'
			});

			html += '</tbody>'
			html += '</table>'
		}
		return html;
	}

}
