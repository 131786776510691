import { Component, OnDestroy, SimpleChanges, OnChanges, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Output, Input, EventEmitter,Pipe, PipeTransform, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { JsonPipe, ViewportScroller } from '@angular/common';

import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';

import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { OmsService } from '../../services/oms.service';
import { DispatchService } from '../../services/dispatch.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
//import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import * as moment from 'moment';
import { parseJSON } from 'date-fns';

declare const google: any;

@Component({
	selector: 'app-dispatches-reorg-after-commit',
	templateUrl: './dispatches-reorg-after-commit.component.html',
	styleUrls: ['./dispatches-reorg-after-commit.component.scss']
})
export class DispatchesReorgAfterCommitComponent implements OnInit {
	@ViewChild('orderdetails') orderdetailsref: ElementRef;

	@Input() selectedId: any = false;
	@Input() isdispatch: any = false;
	@Input() reloaddispatch: any = false;
	@Output() removed= new EventEmitter < any > ();
	CHAT_ROOM = 'OrderBoard';
	transfertruck = new UntypedFormControl('')
	selectedorder: any = false;
	loadingrun: any = false;
	loadingdata: any = false;
	active_dispatches: any = false;
	active_allpoints: any = false;
	filtereditems: any = [];
	listData: any = [];
	sending = false;
	dispatches: any = [];
	allpoints: any = [];

	total_capacity = 0;
	total_order_volume = 0;
	total_items = 0;
	trucks: any = [];
	alltrucks: any = [];
	user: any = [];
	modal_ref: any = false;
	constructor(private fb: UntypedFormBuilder, private dispatchService: DispatchService, public omsService: OmsService, public location: Location, public ordersService: OrdersService, private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, public formBuilder: UntypedFormBuilder, public inventoryService: InventoryService, private modalService: NgbModal) {

		this.globalSearchService.user.subscribe((result) => {
			this.user = result;
			this.omsService.setupSocketConnection(this.user.user.userid);
		});
	}

	ngOnInit(): void {



		this.dispatchService.getTrucks().subscribe((result: any) => {

			if (result.length == 1) {
				this.selectedId = result[0];
				this.total_capacity = result[0].capacity
			} else {
				this.total_capacity = result[0].capacity
			}

			this.trucks = result;
			this.alltrucks = result

			this.route.params.subscribe(params => {
				if (params['id'] && params['id'] != '') {

					const truck = this.trucks.filter(t => {
						return t.id == params['id']
					})[0];

					this.selectedId = truck.id;
					this.loadData();

				} else {
					this.loadData()
				}
			});
		});


		this.omsService.subscribeToOrderPicks((err, data) => {
			switch (data.type) {
			case 'pick':
				//this.loadData();
				break;
			}
		})

		this.omsService.subscribeToCards((err, data) => {

			switch (data.type) {
			case 'pick':
				this.loadData();
				break;
			case 'buildingroute':
				this.loadData();
				break;
			case 'dispatch':
				this.loadData();
				break;
			}
		})


	}

	removeFromDispatch(dispatch: any) {
		this.dispatchService.removeDispatch(dispatch.did).subscribe(results => {
			this.loadData();
			this.removed.emit(dispatch.did)
		})
	}


	cancel(dispatch: any) {
		this.dispatchService.cancelDispatch(dispatch).subscribe(results => {
			this.modal_ref.dismiss();
			this.loadData();
		})
	}

	deliverRun(truck: any) {
		const proceed = confirm('Mark All As Delivered?');
		if (proceed) {
			this.dispatchService.deliverRun(truck).subscribe(results => {
				this.loadData();
				this.modalService.dismissAll();
			})
		}
	}

	clearRun(truck: any) {
		const proceed = confirm('Clear This Run?');
		if (proceed) {
			this.dispatchService.clearRun(truck).subscribe(results => {
				this.loadData();
				this.modalService.dismissAll();
			})
		}
	}

	dropAtHome(dispatch: any) {
		this.dispatchService.returnDispatch(dispatch).subscribe(results => {
			this.loadData();
			this.modalService.dismissAll();
		})
	}

	delivered(dispatch: any) {
		this.dispatchService.deliverDispatch(dispatch).subscribe(results => {
			this.loadData();
			if (!this.isdispatch) {
				this.modalService.dismissAll();
			} else {
				//this.modalService.dismissAll();
				//this.activeModal.close();
				this.globalSearchService.showNotification('updated', 'success', 'top', 'center')
			}
		})
	}

	altLocation() {
		if (this.transfertruck.value) {
			this.dispatchService.transferDispatch(this.selectedorder.did, this.transfertruck.value).subscribe(results => {
				this.loadData();
				this.modalService.dismissAll();
			})
		}
	}

	viewOrder(order: any) {
		this.selectedorder = order
		this.modal_ref = this.modalService.open(this.orderdetailsref, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false }).result.then((result) => {}, (reason) => {
			this.selectedorder = false;
		});
	}

	loadData() {

		if (this.loadingrun) {
			this.loadingrun.unsubscribe();
		}

		// alert('del sheet');
		// alert((this.selectedId.id));

		let loadrun = this.selectedId.id;

		if (typeof this.selectedId === 'string' || !loadrun) {
			loadrun = this.selectedId;
		}
		this.sending = true;
		this.loadingrun = this.dispatchService.getActiveRun(loadrun).subscribe((results: any) => {
			this.sending = false;
			this.listData = results.dispatched;
			this.active_dispatches = results.dispatched;
			this.active_allpoints = results.allpoints;
		});

	}

	ngOnChanges(changes: any) {
		if (changes.reloaddispatch) {

			if (changes.reloaddispatch.currentValue == true) {
				this.loadData();
			}
		}
	}

	identify(index, item) {
		return item.did;
	}

	reorgDispatch(event: CdkDragDrop < any > ) {
		const thisitem = event.previousContainer.data[event.previousIndex];
		const proceed = true;
		if (proceed) {
			if (event.previousContainer === event.container) {
				moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
			} else {

				transferArrayItem(event.previousContainer.data,
					event.container.data,
					event.previousIndex,
					event.currentIndex);
			}

			const cardaction = {
				list: this.listData,
			}

			if (cardaction) {
				this.dispatchService.updateDispatchSort(cardaction).subscribe(r => {
					const data = { truck: this.selectedId, user: this.user }
					if (data) {
						this.omsService.sendDispatchPositionUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
					}
				});
			}

		}
	}
}
