<ng-container *ngIf="!menuapp">
	<form [formGroup]="searchForm" class="m-0 p-0">
		<div class="main_card">
			<div class="row">
				<div class="col-12">
					<h3> Customer Item Sales Detail Report </h3>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12 col-md-3 col-3">
				<mat-form-field color="accent" appearance="outline">
                <mat-label>Enter a date range</mat-label>

                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="Start date" formControlName="startdate">
                        <input matEndDate placeholder="End date" formControlName="enddate">
                    </mat-date-range-input>

                    <mat-date-range-picker #picker></mat-date-range-picker>
					<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
				</mat-form-field>
				</div>
				<div class="col-xs-12 col-md-3 col-3">
					<mat-form-field appearance="outline">
						<mat-label>Brands</mat-label>
						<mat-select formControlName="brand" multiple appSelectAll>
							<mat-option *ngFor="let v of brands" [value]="v.manufacturer_id">
								{{v.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-xs-12 col-md-3 col-3">
					<mat-form-field appearance="outline">
						<mat-label>Categories</mat-label>
						<mat-select formControlName="category" multiple appSelectAll>
							<mat-option *ngFor="let v of categories" [value]="v.categoryid">
								{{v.categoryid }} - {{v.categorydescription}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-xs-12 col-md-3 col-3">
					<mat-form-field appearance="outline">
						<mat-label>Salespeople</mat-label>
						<mat-select formControlName="salesperson" multiple appSelectAll>
							<mat-option *ngFor="let v of salespeople" [value]="v.salesmancode">
								{{v.salesmancode}} - {{v.salesmanname}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-xs-12 col-md-3 col-3">
					<mat-form-field appearance="outline">
						<mat-label>Locations</mat-label>
						<mat-select formControlName="location" multiple appSelectAll>
							<mat-option *ngFor="let v of locations" [value]="v.loccode">
								{{v.loccode}} - {{v.locationname}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-xs-12 col-md-3 col-3">
					<span *ngIf="sending">
						<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</span>
					<button mat-raised-button class="btn-success btn-height" (click)="$event.preventDefault();exportXls()" *ngIf="reportResults.length != 0">
						<i class="fa fa-file-excel-o"></i> XLS
					</button>
					&nbsp;
					<button mat-raised-button class="btn-danger btn-height" (click)="$event.preventDefault();exportPdf()" *ngIf="reportResults.length != 0">
						<i class="fa fa-file-pdf-o"></i> PDF
					</button>
					&nbsp;
					<button mat-raised-button color="accent" class="mt-2" (click)="cidreport()" *ngIf="!sending">
						<mat-icon>refresh</mat-icon>
					</button>
				</div>
			</div>
		</div>
	</form>
	<div #print_table>
		<div class="table table-responsive text-center" *ngIf="loading">
			<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
			</svg>
		</div>
		<table class="table table-striped table-bordered table-hover table-fixed"  *ngIf="!loading">
			<thead>
				<tr>
					<th (click)="sortBy('date')">Date</th>
					<th (click)="sortBy('number')">Document #</th>
					<th (click)="sortBy('customerid')">Customer</th>
					<th (click)="sortBy('name')">Customer Name</th>
					<th (click)="sortBy('itemdescription')">Description</th>
					<th (click)="sortBy('lineid')">Line</th>
					<th (click)="sortBy('linedescription')">Line Description</th>
					<th (click)="sortBy('category')">Category</th>
					<th (click)="sortBy('mfgpart')">MFG Part#</th>
					<th (click)="sortBy('stockid')">Item#</th>
					<th (click)="sortBy('loccode')">Location</th>
					<th (click)="sortBy('price')">Sale Price</th>
					<th (click)="sortBy('qty')">Qty</th>
					<th></th>
					<th (click)="sortBy('total')">Total</th>
					<th (click)="sortBy('salesperson')">Salesperson</th>
					<th (click)="sortBy('ordersource')">Order Source</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let r of sortedReportResults">
					<td>{{ r.date }}</td>
					<td>{{ r.number }}<br>{{r.type}}</td>
					<td>{{ r.customerid }}</td>
					<td>{{ r.name }}</td>
					<td>{{ r.itemdescription }}</td>
					<td>{{ r.lineid }}</td>
					<td>{{ r.linedescription }}</td>
					<td>{{ r.category }}</td>
					<td>{{ r.mfgpart }}</td>
					<td>{{ r.stockid }}</td>
					<td>{{ r.loccode }}</td>
					<td>{{ r.price }}</td>
					<td></td>
					<td>{{ r.qty }}</td>
					<td>{{ r.total }}</td>
					<td>{{ r.salesperson }}</td>
					<td>{{ r.ordersource }}</td>
				</tr>
			</tbody>
			<tfoot>
				<tr><td></td><th>Customers</th>
					<td>{{ totals.customers }}</td>
					<td colspan="9">&nbsp;</td>
					<th> Qty</th>
					<td>{{ totals.qty }} </td>
					<th> Total </th>
					<td>{{ totals.total }} </td>
				</tr>
			</tfoot>
		</table>
	</div>
</ng-container>

<ng-container *ngIf="menuapp">

<div class="main-content">
	<div class="container-fluid">
		<div class="card">
			<div class="card-header card-header-{{ color}}-5">
				<h4 class="card-title">Customer Item Sales Detail Report</h4>
				<p class="card-category"></p>
			</div>
			<div class="card-body">
				<form [formGroup]="searchForm" class="m-0 p-0">
			<div class="row m-0 p-0">
				<div class="col-xs-12 col-md-3 col-3">
				<mat-form-field color="accent" class="datepicker" appearance="outline">
                <mat-label>Enter a date range</mat-label>
                <div class="daterange-div">
                    <mat-date-range-input [rangePicker]="picker" class="daterange-input">
                        <input matStartDate placeholder="Start date" formControlName="startdate">
                        <input matEndDate placeholder="End date" formControlName="enddate">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </div>
				</mat-form-field>
				</div>
				<div class="col-xs-12 col-md-3 col-3">
					<mat-form-field appearance="outline">
						<mat-label>Brands</mat-label>
						<mat-select formControlName="brand" multiple appSelectAll>
							<mat-option *ngFor="let v of brands" [value]="v.manufacturer_id">
								{{v.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-xs-12 col-md-3 col-3">
					<mat-form-field appearance="outline">
						<mat-label>Categories</mat-label>
						<mat-select formControlName="category" multiple appSelectAll>
							<mat-option *ngFor="let v of categories" [value]="v.categoryid">
								{{v.categoryid }} - {{v.categorydescription}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-3 mt-3">
					<span *ngIf="sending">
						<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</span>
					<button mat-raised-button class="btn-success btn-height" (click)="$event.preventDefault();exportXls()" *ngIf="reportResults.length != 0">
						<i class="fa fa-file-excel-o"></i> XLS
					</button>
					&nbsp;
					<button mat-raised-button class="btn-danger btn-height" (click)="$event.preventDefault();exportPdf()" *ngIf="reportResults.length != 0">
						<i class="fa fa-file-pdf-o"></i> PDF
					</button>
					&nbsp;
					<button mat-raised-button color="accent" class="mt-2" (click)="cidreport()" *ngIf="!sending">
						<mat-icon>refresh</mat-icon>
					</button>
				</div>
				<div class="col-xs-12 col-md-3 col-3">
					<mat-form-field appearance="outline">
						<mat-label>Salespeople</mat-label>
						<mat-select formControlName="salesperson" multiple appSelectAll>
							<mat-option *ngFor="let v of salespeople" [value]="v.salesmancode">
								{{v.salesmancode}} - {{v.salesmanname}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-xs-12 col-md-3 col-3">
					<mat-form-field appearance="outline">
						<mat-label>Locations</mat-label>
						<mat-select formControlName="location" multiple appSelectAll>
							<mat-option *ngFor="let v of locations" [value]="v.loccode">
								{{v.loccode}} - {{v.locationname}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-xs-12 col-md-3 col-3">
					<mat-form-field appearance="outline">
						<mat-label>Customer Search</mat-label>
						<input matInput formControlName="customersearch" placeholder="Customer Number or Name"/>
					</mat-form-field>
				</div>
				<div class="col-xs-12 col-md-3 col-3">
					<mat-form-field appearance="outline">
						<mat-label>Part / Description / Invoice# Search</mat-label>
						<input matInput formControlName="keywords" placeholder="xxxxx"/>
					</mat-form-field>
				</div>

			</div>
			</form>
			</div>


	<div #print_table>
		<table class="table table-striped table-bordered table-hover table-fixed" *ngIf="!loading">
			<thead>
				<tr>
					<th (click)="sortBy('date')">Date</th>
					<th (click)="sortBy('number')">Document #</th>
					<th (click)="sortBy('customerid')">Customer</th>
					<th (click)="sortBy('name')">Customer Name</th>
					<th (click)="sortBy('itemdescription')">Description</th>
					<th (click)="sortBy('lineid')">Line</th>
					<th (click)="sortBy('linedescription')">Line Description</th>
					<th (click)="sortBy('category')">Category</th>
					<th (click)="sortBy('mfgpart')">MFG Part#</th>
					<th (click)="sortBy('stockid')">Item#</th>
					<th (click)="sortBy('loccode')">Location</th>
					<th (click)="sortBy('price')">Sale Price</th>
					<th (click)="sortBy('qty')">Qty</th>
					<th></th>
					<th (click)="sortBy('total')">Total</th>
					<th (click)="sortBy('salesperson')">Salesperson</th>
					<th (click)="sortBy('ordersource')">Order Source</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let r of sortedReportResults">
					<td>{{ r.date }}</td>
					<td>{{ r.number }}<br>{{r.type}}</td>
					<td>{{ r.customerid }}</td>
					<td>{{ r.name }}</td>
					<td>{{ r.itemdescription }}</td>
					<td>{{ r.lineid }}</td>
					<td>{{ r.linedescription }}</td>
					<td>{{ r.category }}</td>
					<td>{{ r.mfgpart }}</td>
					<td>{{ r.stockid }}</td>
					<td>{{ r.loccode }}</td>
					<td>{{ r.price}}</td>
					<td>{{ r.qty }}</td>
					<td></td>
					<td>{{ r.total }}</td>
					<td>{{ r.salesperson }}</td>
					<td>{{ r.ordersource }}</td>
				</tr>
			</tbody>
			<tfoot>
				<tr><td></td><th>Customers</th>
					<td>{{ totals.customers }}</td>
					<td colspan="9">&nbsp;</td>
					<th> Qty</th>
					<td>{{ totals.qty }} </td>
					<th> Total </th>
					<td>{{ totals.total }} </td>
				</tr>
			</tfoot>

		</table>
	</div>
	</div>
	</div>
	</div>
</ng-container>
