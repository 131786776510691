
<ng-container *ngIf="customerForm">
	<form [formGroup]="customerForm" *ngIf="customer" >
		<div class="row  m-0 p-0 mb-2 ">
			<div class="col-md-4">
				<mat-form-field appearance="standard">
					<mat-label>Warehouse</mat-label>
					<mat-select formControlName="defaultlocation">
						<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
							{{ loc.locationname }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-4">
				<mat-form-field class="full-width" appearance="standard">
					<mat-label>Entered By</mat-label>
					<input matInput formControlName="enteredby" matInput placeholder="Entered By">
				</mat-form-field>
			</div>
			<div class="col-md-4">
				<mat-form-field class="full-width" appearance="standard">
					<mat-label>Taken By</mat-label>
					<input matInput formControlName="takenby" matInput placeholder="Taken By">
				</mat-form-field>
			</div>
		</div>
		<div class="row m-0 p-0 mb-2">
			<div class="card-deck">
				<div class="card col-md-4 m-0 p-0">
					<div class="card-header card-header-rose card-header-icon">
						<div class="card-icon pointer" (click)="toggleEditBilling()">
							<i class="material-icons">credit_card</i>
						</div>
						<h5 class="card-title bold">Bill To:</h5>
						<ng-container *ngIf="!editbilling">
							<div class="card-body text-dark form-inputs">
								<div class="row">
									<div class="col-12 ">
										<span [innerHTML]="customer.billto"></span>
										<br>
										Credit Limit: {{ customer.creditlimit | currency }}<br>
										Price Level : {{ customerForm.get('salestype').value }}<br>
									</div>
								</div>
							</div>
						</ng-container>
						<ng-container *ngIf="editbilling">
							<div class="card-body text-dark form-inputs" @grow>
								<div class="row">
									<div class="col-md-12">
										<mat-form-field appearance="standard">
											<mat-label>Name</mat-label>
											<input matInput required value="" formControlName="name">
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<mat-form-field appearance="standard">
											<mat-label>Address 1</mat-label>
											<input matInput required value="" formControlName="address1">
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<mat-form-field appearance="standard">
											<mat-label>Address 2</mat-label>
											<input matInput value="" formControlName="address2">
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col-md-4">
										<mat-form-field appearance="standard">
											<mat-label>City</mat-label>
											<input matInput value="" formControlName="address3">
										</mat-form-field>
									</div>
									<div class="col-md-4">
										<mat-form-field appearance="standard" class="" *ngIf="zones">
											<mat-label>Region</mat-label>
											<mat-select formControlName="address4">
												<mat-option *ngFor="let zone of zones.zones" [value]="zone.code">
													{{zone.code }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-4">
										<mat-form-field appearance="standard">
											<mat-label>Postal</mat-label>
											<input matInput value="" formControlName="address5">
										</mat-form-field>
									</div>
									<div class="col-md-12">
										<mat-form-field appearance="standard" class="" *ngIf="zones">
											<mat-label>Country</mat-label>
											<mat-select formControlName="address6">
												<mat-option *ngFor="let zone of zones.countries" [value]="zone.name">
													{{ zone.name }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
				<div class="card col-md-4 mt-0 pt-0 mb-0 mt-0 pl-1 pr-1">
					<div class="card-header card-header-danger card-header-icon">
						<div class="card-icon pointer" (click)="toggleEditShipping()">
							<i class="material-icons">account_circle</i>
						</div>

						<h5 class="card-title">Ship To: {{ this.customerForm.get('branchcode').value}}
							<ng-container *ngIf="customer.shiptocount > 1">
								<small>
									<button mat-icon-button [matMenuTriggerFor]="branchchange" color="accent" aria-label="Change Branch">
										<mat-icon>menu</mat-icon>
									</button>
								</small>
								<mat-menu #branchchange="matMenu">
									<ng-container *ngFor="let branch of customer.branches">
										<button mat-menu-item (click)="changeBranch(branch.branchcode)">
											<mat-icon>sync_alt</mat-icon>
											<span>{{ branch.branchcode }}:  {{branch.brname}} {{ branch.braddress1 }}</span>
										</button>
									</ng-container>
								</mat-menu>
								<small class="text-dark">({{customer.shiptocount}})</small>
							</ng-container>
						</h5>

						<ng-container *ngIf="!editshipping">
							<div class="card-body text-dark form-inputs">
								<div class="row">
									<div class="col-12 ">
										<span [innerHTML]="customer.shipto"></span>

										<p class="mt-2">
											Contact: <b>{{ customer.contactname }}</b><br>
										Phone#: <b>{{ this.customerForm.get('phoneno').value }}</b><br>
										Email:
											<b>
											<span *ngIf="customerForm.get('email').value == ''">
												N/A
											</span>
											<span *ngIf="customerForm.get('email').value != ''">
												{{ this.customerForm.get('email').value }}
											</span>
											</b>
										</p>
									</div>
								</div>
							</div>
						</ng-container>
						<ng-container *ngIf="editshipping">
							<div class="card-body text-dark form-inputs" @grow>
								<div class="row">
									<div class="col-md-12 ">
										<mat-form-field appearance="standard">
											<mat-label>Name</mat-label>
											<input matInput value="" formControlName="brname">
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12 ">
										<mat-form-field appearance="standard">
											<mat-label>Address 1</mat-label>
											<input matInput value="" formControlName="braddress1">
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<mat-form-field appearance="standard">
											<mat-label>Address 2</mat-label>
											<input matInput value="" formControlName="braddress2">
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col-md-4">
										<mat-form-field appearance="standard">
											<mat-label>City</mat-label>
											<input matInput value="" formControlName="braddress3">
										</mat-form-field>
									</div>
									<div class="col-md-4">
										<mat-form-field appearance="standard" class="" *ngIf="zones">
											<mat-label>Region</mat-label>
											<mat-select formControlName="braddress4">
												<mat-option *ngFor="let zone of zones.zones" [value]="zone.code">
													{{zone.code }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-4">
										<mat-form-field appearance="standard">
											<mat-label>Postal</mat-label>
											<input matInput value="" formControlName="braddress5">
										</mat-form-field>
									</div>
									<div class="col-md-12">
										<mat-form-field appearance="standard" class="" *ngIf="zones">
											<mat-label>Country</mat-label>
											<mat-select formControlName="braddress6">
												<mat-option *ngFor="let zone of zones.countries" [value]="zone.name">
													{{ zone.name }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<mat-form-field appearance="standard">
											<mat-label>Price List</mat-label>
											<mat-select formControlName="salestype" (selectionChange)="repriceCart($event)">
												<mat-option *ngFor="let tpe of salestypes" [value]="tpe?.typeabbrev">
													{{tpe.typeabbrev}} {{tpe.sales_type}}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-6">
										<mat-form-field class="full-width" appearance="standard">
											<mat-label>Phone</mat-label>
											<input matInput formControlName="phoneno" matInput placeholder="phone#">
										</mat-form-field>
									</div>
									<div class="col-md-6">
										<mat-form-field class="full-width" appearance="standard">
											<mat-label>Email</mat-label>
											<input matInput formControlName="email" matInput placeholder="email">
										</mat-form-field>
									</div>
								</div>
							</div>
						</ng-container>

					</div>
				</div>
				<div class="card col-md-4 m-0 p-0">
					<div class="card-header card-header-success card-header-icon">
						<div class="card-icon" (click)="toggleEditSettings()">
							<i class="material-icons">settings</i>
						</div>
						<h5 class="card-title bold">Order Details:</h5>
						<ng-container>
							<div class="card-body text-dark form-inputs">
								<div class="row smaller">
									<div class="col-md-6">
										<mat-form-field appearance="standard">
											<mat-label>Sales Person</mat-label>
											<mat-select formControlName="salesman">
												<mat-option *ngFor="let person of salespeople" [value]="person.salesmancode">
													{{ person.salesmanname }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-6">
										<mat-form-field appearance="standard">
											<mat-label>Terms</mat-label>
											<mat-select formControlName="paymentterms">
												<mat-option *ngFor="let term of terms" [value]="term.termsindicator">
													{{ term.terms }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-6">
										<mat-form-field appearance="standard">
											<mat-label>Ship Via</mat-label>
											<mat-select formControlName="defaultshipvia">
												<mat-option *ngFor="let ship of shipvia" [value]="ship.shipper_id">
													{{ ship.shippername }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-6">
										<mat-form-field appearance="standard">
											<mat-label>Freight</mat-label>
											<span matPrefix>$</span>
											<input matInput formControlName="freightcost" matInput placeholder="Freight Cost" class="text-right">
										</mat-form-field>
									</div>

									<div class="col-md-12">
										<mat-form-field appearance="standard">
											<mat-label>Credit Type</mat-label>
											<mat-select formControlName="credit_type">
												<mat-option *ngFor="let t of credit_types" [value]="t.value">
													{{ t.label }}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div class="col-md-8">
										<mat-form-field class="full-width" appearance="standard">
											<mat-label>Customer PO#</mat-label>
											<input formControlName="reference" matInput placeholder="PO number">
										</mat-form-field>
									</div>
									<div class="col-md-4">

										<mat-slide-toggle class="mt-3" formControlName="print">AutoPrint?</mat-slide-toggle>

									</div>

									<ng-container *ngIf="customerForm">
									<div class="col-md-12" *ngIf="customerForm.get('credit_type').value=='writeoff'">
										<mat-form-field class="full-width" appearance="standard">
											<mat-label>Account</mat-label>
											<input matInput type="text" placeholder="Gl Account" aria-label="Gl Account" matInput formControlName="glaccount" [matAutocomplete]="auto" (keydown.enter)="$event.preventDefault();">
											<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
												<mat-option *ngFor="let option of glaccounts" [value]="option">
													{{ option.accountcode }} {{ option.accountname }}
												</mat-option>
											</mat-autocomplete>
										</mat-form-field>
									</div>
									</ng-container>
								</div>
							</div>
						</ng-container>

						<!--<ng-container *ngIf="editsettings">
								<div class="card-body text-dark form-inputs" @openClose>

								</div>
							</ng-container>
						-->

					</div>
				</div>

			</div>
		</div>

		<div class="row m-0 p-0">
			<div class="col-md-6 m-0 p-0">
				<mat-form-field class="mr-1 full-widthh" appearance="fill">
					<mat-label>Dispatch Note</mat-label>
					<textarea matInput formControlName="comments" placeholder="Ex. Opens at 2pm..."></textarea>
				</mat-form-field>
			</div>
			<div class="col-md-6 m-0 p-0">
				<mat-form-field class="ml-1 w-100 full-width" appearance="fill">
					<mat-label>Memo</mat-label>
					<textarea matInput formControlName="notes" placeholder="Ex. Thank you for your buisness..."></textarea>
				</mat-form-field>
			</div>
		</div>
		<div class="row mb-1 mt-1" *ngFor="let note of customer.notes">
			<h5>Notes</h5>
			<div class="col-md-3 ml-auto mr-auto border-bottom">
				{{ note.noteuserid }} {{ note.notedate }}
			</div>
			<div class="col-md-9 border-bottom">
				Note: {{ note.note }}
			</div>
			<mat-divider></mat-divider>
		</div>
	</form>
	<ng-container class="w-100">
		<div class="container-fluid text-right" id="rightcart-content" *ngIf="!cart_items.length">
			<div class="row mt-0 mb-0 pt-0 pb-0 border-top small-font">
				<div class="col-md-12 mt-0 pt-0 text-center mb-3 mt-3">
					<h4>No Current Items</h4>
				</div>
			</div>
		</div>
		<div class="table w-100" *ngIf="cart_items.length">
			<table class="w-100 table table-fixed table-sm">
				<thead>
					<tr>
						<th class="text-left">Item</th>
						<th class="text-left">Note</th>
						<th class="text-right small-td" *ngIf="config.discounts">Discount</th>
						<th class="text-right small-td w-25">Price</th>
						<th class="text-center w-25">Qty</th>
						<th class="text-right">Total</th>
						<th class="text-right"></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of cart_items">
						<td class="text-left">{{item.stockid}}&nbsp;&nbsp;<span class="bold">{{item.description}}</span></td>
						<td class="text-left ">
							<mat-form-field appearance="standard" class="m-0 p-0" *ngIf="item.mbflag != 'F'">
								<mat-label>Line Notes</mat-label>
								<input matInput maxlength="64" placeholder="Line Notes." [value]="item.notes" (input)="updateNote($event,item)">
							</mat-form-field>
						</td>
						<td class="text-right w-25" *ngIf="config.discounts">
							<mat-form-field appearance="standard" class="text-right" *ngIf="item.mbflag != 'F'">
								<mat-label>Discount</mat-label>
								<input matInput type="text" class="text-right" placeholder="Discount" [value]="item.discount" (input)="updateDiscount($event,item)" appSelectOnFocus>
								<span matSuffix>%</span>
							</mat-form-field>
						</td>
						<td class="text-right w-25">
							<mat-form-field appearance="standard" class="m-0 p-0 text-right">
								<mat-label>Price</mat-label>
								<span matPrefix>$</span>
								<input matInput type="text" class="text-right" placeholder="Price" [value]="item.price" (input)="updatePrice($event,item)" appSelectOnFocus>
								<mat-hint *ngIf="config.discounts && item.discount > 0" class="text-right text-danger bolder">
									Discount Price {{ getDiscounPrice(item) | currency }} @ {{ item.discount }}%
								</mat-hint>
							</mat-form-field>
						</td>
						<td class="text-center w-25">
							<mat-form-field appearance="standard" class="m-0 p-0 ">
								<mat-label>Qty</mat-label>
								<span matPrefix>#</span>
								<input matInput type="text" placeholder="QTY" class="text-center" [value]="item.quantity" (input)="updateQuantity($event, item)" appSelectOnFocus>
							</mat-form-field>
						</td>
						<td class="text-right">
							{{ toNegative(getDiscountTotal(item))| currency }}
						</td>
						<td class="text-right">
							<button mat-icon-button color="warn" aria-label="remove" (click)="removeFromOrder(item.cart_id)">
								<mat-icon>remove</mat-icon>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div *ngIf="cart_items.length">
			<div class="col-12 p-0 m-0 text-right" *ngFor="let total of cart_totals">
				{{total.title }}: {{ toNegative(total.text) | currency }}
			</div>
		</div>
	</ng-container>
</ng-container>
