import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationService, MessageService } from 'primeng/api';
import { InventoryService } from 'app/services/inventory.service';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';

@Component({
	selector: 'app-inventory-productgroups',
	templateUrl: './inventory-productgroups.component.html',
	styleUrls: ['./inventory-productgroups.component.scss'],
	providers: [ConfirmationService, MessageService]
})


export class InventoryProductgroupsComponent implements OnInit {
	@Input() details = false;
	removeConfirmOpen = false;
	constructor(private confirmationService: ConfirmationService, private messageService: MessageService, private inventoryService: InventoryService, private globalSearchService: GlobalSearchService) {}

	ngOnInit(): void {}


	removeFromGroup(event: Event, prod, group) {
		this.removeConfirmOpen = true;

		this.confirmationService.confirm({
			target: event.target as EventTarget,
			message: `Do you want to remove this item from "${group.name}" ?`,
			icon: 'pi pi-info-circle',
			acceptButtonStyleClass: 'p-button-danger p-button-sm',
			accept: () => {

				//remove selected
				const prods = group.products.filter((i) => {
					return i.stockid !== prod.stockid;
				})

				//update group
				const data = {
					members: prods,
					group_id: group.group_id,
					name: group.name,
					description: group.description
				}
				this.inventoryService.saveGroup(data).subscribe(results => {});

				//remove group from item details
				this.details['groups'] = (this.details['groups']).filter((g) => {
					return g.group_id !== group.group_id;
				})

				this.globalSearchService.showNotification(`Removed ${prod.stockid} from group "${group.name}"`, 'success', 'bottom', 'right');
				this.removeConfirmOpen = false;

			},
			reject: () => {
				this.globalSearchService.showNotification(`Cancelled ${prod.stockid} remove from group "${group.name}"`, 'warning', 'bottom', 'right');
				this.removeConfirmOpen = false;
			}
		});
	}

}
