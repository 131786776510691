import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import * as jQuery from 'jquery';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { OmsService } from '../../services/oms.service';
import { QantelService } from '../../services/qantel.service';

import * as _ from 'underscore';
import * as XLSX from 'xlsx';


@Component({
	selector: 'app-qantel-order-items',
	templateUrl: './qantel-order-items.component.html',
	styleUrls: ['./qantel-order-items.component.scss']
})
export class QantelOrderItemsComponent implements OnInit {

	@Input() header;
	@Input() results;
	@Input() allresults;
	@Input() type;
	@Input() ready;

	@ViewChild(DataTableDirective) dtElement: DataTableDirective;
	@ViewChild('searching') searchingSearchField: ElementRef;

	fileName: any = '';
	CHAT_ROOM = 'OrderBoard';

	originallength = 0;
	filteredItems: any = [];
	snoozeorder: any = [];
	snoozetime: any = [];

	constructor(public qantelService: QantelService, public cdr: ChangeDetectorRef, public router: Router, private globalSearchService: GlobalSearchService, private omsService: OmsService) {}

	ngOnInit(): void {}

	getHeader() {
		return this.header;
	}

	getResults() {
		return this.allresults;
	}

	public setSnooze(event: any) {
		this.snoozetime = event.target.value;
	}

	public onInput(event: any, keys = 'itemnumber') {

		const term = event.target.value;
		const allresults = [];

		if (this.getResults()) {

			const search = this.filterItem(this.getResults(), event.target.value, 'DESCRIPTION,ITEM_NUMBER,ORDER_NUMBER,CUSTOMER,LINE,SHIP_VIA,CUST_NAME');

			if (search) {
				search.forEach(function(value) {
					allresults.push(value)
				});
			}
		}

		this.setFiltered(allresults)
	}

	getFiltered() {
		return this.results;
	}

	setFiltered(results) {
		this.results = results;
	}

	assignCopy(input) {
		this.filteredItems = Object.assign([], input);
	}

	filterItem(input, term, keys) {
		if (!term) {
			this.assignCopy(input);
		}
		let result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));


		result = _.sortBy(result, 'ORDER_NUMBER');

		return result;
	}

	navTo(orderno, type) {
		this.router.navigate(['./orders/lookup/' + orderno + '/' + type]);
	}

	setSnoozeOrder(orderno) {
		this.snoozeorder = orderno;
	}

	snooze(orderno) {

		const result = this.allresults.filter((item: any) => {
			return item.ORDER_NUMBER != orderno;
		});

		this.results = result;
		this.allresults = result;
		const data = { orderno: orderno, days: this.snoozetime };
		switch (this.type) {
		case 'snoozed':
			this.omsService.sendSnoozeRemove({ data, roomName: this.CHAT_ROOM }, cb => {});
			break;
		default:
			this.omsService.sendSnoozeUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
			break;
		}

	}

	exportExcel(datain): void {
		/* table id is passed over here */
		const element = datain;
		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(element);

		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
		/* save to file */
		XLSX.writeFile(wb, 'OrderView.xlsx');
	}

	dateEvent(type: string, event: MatDatepickerInputEvent < Date > ) {

	}
}
