<div class="row m-0 p-0">
	<!-- <ul *ngIf="archived_dates">
		<li *ngFor="let d of archived_dates">
			<div class="pointers" (click)="loadDate(d.dateadded)"></div>
		</li>
	</ul> -->
	<div class="col-md-4 mt-3 ">
		<ng-container *ngIf="archived_dates ">
			<mat-form-field appearance="fill">
				<mat-label>Dates Available</mat-label>
					<mat-select (selectionChange)="loadDate($event.value)">
					<mat-option *ngFor="let d of archived_dates" [value]="d.dateadded">
					{{ d.dateadded | date}}
					</mat-option>
					</mat-select>
			</mat-form-field>
		</ng-container>
	</div>
</div>
<div class="row m-0 p-0" *ngIf="archived_data">

	<div class="col-12">
		<h3>{{ date_selected | date }}</h3>
		<a class="btn btn-danger" (click)="exportPdf();"><i class="fa fa-file-pdf-o"></i></a>
		<a class="btn btn-success" (click)="exportXls();"><i class="fa fa-file-excel-o"></i></a>

		<div class="table w-100" #print_table>
			<table class="table table-fixed">
				<thead>
					<tr>
						<th>Name</th>
						<th>Customer #</th>
						<th>Terms</th>
						<th>Credit</th>
						<th>Status</th>
						<th class="text-right">Balance</th>
						<th class="text-right">future</th>
						<th class="text-right">Current</th>
						<th class="text-right">30 Day</th>
						<th class="text-right">60 Day</th>
						<th class="text-right">90 Day</th>
						<th class="text-right">120 Day</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let result of archived_data; let i = index">
						<td>{{ result.name }}</td>
						<td>{{ result.debtorno }}</td>
						<td>{{ result.terms }}</td>
						<td>{{ result.creditlimit | currency }}</td>
						<td>{{ result.reasondescription }}</td>
						<td class="text-right">{{ result.balance | currency }}</td>
						<td class="text-right">{{ result.future | currency }}</td>
						<td class="text-right">{{ result.current | currency}}</td>
						<td class="text-right">{{ result.due | currency}}</td>
						<td class="text-right">{{ result.overdue | currency}}</td>
						<td class="text-right">{{ result.overdue1 | currency}}</td>
						<td class="text-right">{{ result.overdue2 | currency}}</td>
					</tr>
					<tr>
						<td>TOTAL</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td class="text-right">{{ total_balance | currency }}</td>
						<td class="text-right">{{ total_future | currency}}</td>
						<td class="text-right">{{ total_current | currency}}</td>
						<td class="text-right">{{ total_due | currency}}</td>
						<td class="text-right">{{ total_overdue | currency}}</td>
						<td class="text-right">{{ total_overdue1 | currency}}</td>
						<td class="text-right">{{ total_overdue2 | currency}}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
