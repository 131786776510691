<ng-template #addGroupInput let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Add Group</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-header text-center">
					<h4 class="card-title text-center"></h4>
				</div>
				<div class="card-body text-center">
					<div class="row">
						<div class="col-12">
							<form [formGroup]="addGroupForm">
								<mat-form-field appearance="outline">
									<mat-label>Name</mat-label>
									<input type="text" class="text-let m-0 p-0" matInput formControlName="name" />
								</mat-form-field>
								<button mat-stroked-button color="success" aria-label="" (click)="addGroup()" class="mt-2">
									<mat-icon>save</mat-icon> Save
								</button>

							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-3">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> User Groups</h4>
						<p class="card-category">User Groups</p>
					</div>
					<div class="card-body">
						<div class="table">
							<div class="row">
								<div class="col-8 text-left mt-3">
									<button mat-stroked-button color="success" aria-label="" (click)="openModal()" class="mt-2">
										<mat-icon>add</mat-icon> Add Group
									</button>
								</div>

								<div class="col-md-4">
									<mat-form-field appearance="outline" class="text-right">
										<mat-label>Search</mat-label>
										<input tabindex="1" matInput value="" (input)="onInput()" [formControl]="usergroupSearch" class="text-right" autofocus (keydown.enter)="$event.preventDefault();onInput()">
										<span matSuffix>
											<button tabindex="-1" mat-icon-button color="white">
												<mat-icon (click)="searchUserGroups()">search</mat-icon>
											</button>
										</span>
										<mat-progress-bar mode="indeterminate" color="accent" *ngIf="isSearching"></mat-progress-bar>
									</mat-form-field>
								</div>
							</div>

							<div class="table-container">
								<table mat-table [dataSource]="usergroups" matSort (matSortChange)="announceSortChange($event)">
									<ng-container matColumnDef="actions">
										<th mat-header-cell *matHeaderCellDef></th>
										<td mat-cell *matCellDef="let usergroup">
											<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
												<mat-icon>more_vert</mat-icon>
												<mat-menu #menu="matMenu">
													<button mat-menu-item [routerLink]="'edit/'+usergroup.user_group_id">
														<mat-icon>edit</mat-icon>
														<span>Edit User Group</span>
													</button>
													<button mat-menu-item (click)="confirmDeletion(usergroup)">
														<mat-icon>delete</mat-icon>
														<span>Delete User Group</span>
													</button>
												</mat-menu>
											</button>
										</td>
									</ng-container>
									<ng-container matColumnDef="user_group_id">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>Group ID</th>
										<td mat-cell *matCellDef="let usergroup"> {{ usergroup.user_group_id }}</td>
									</ng-container>
									<ng-container matColumnDef="name">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
										<td mat-cell *matCellDef="let usergroup"> {{ usergroup.name }}</td>
									</ng-container>
									<ng-container matColumnDef="sales_channel">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>Sales Channel</th>
										<td mat-cell *matCellDef="let usergroup"> {{ getNameByValue(usergroup.sales_channel) }}</td>
									</ng-container>
									<tr mat-header-row *matHeaderRowDef="headercolumns;sticky: true;"></tr>
									<tr mat-row *matRowDef="let row; columns: headercolumns"></tr>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
