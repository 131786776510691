import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';

import { Location } from '@angular/common'

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { GeneralLedgerService } from '../../services/general-ledger.service';
import { PrintService } from '../../services/print.service';

@Component({
	selector: 'app-sales-tax-compressed',
	templateUrl: './sales-tax-compressed.component.html',
	styleUrls: ['./sales-tax-compressed.component.scss']
})
export class SalesTaxCompressedComponent implements OnInit {
	@ViewChild('print_table') printtable: ElementRef;
	periods: any = [];
	default_period: any = [];
	default_toperiod: any = '';
	default_fromperiod: any = '';
	formGroup: UntypedFormGroup;
	loading = false;
	taxes: any = [];
	title = '';
	from_period_options: any = [];
	to_period_options: any = [];
	totals: any = [];
	color: any = '';
	taxauth: any = [];
	config: any = [];
	from_taxauth: any = [];
	to_taxauth: any = [];
	pdflink: any = '';
	excellink: any = '';
	group_totals: any = false;
	exporting = false
	details_summary = [
		{ label: 'Summary', value: 'S' },
		{ label: 'Details', value: 'D' },
	]

	today = new Date();

	constructor(private globalsService: GlobalsService, private printService: PrintService,private location: Location, private generalLedgerService: GeneralLedgerService, private globalSearchService: GlobalSearchService, private fb: UntypedFormBuilder) {}

	ngOnInit(): void {
		this.color = this.globalSearchService.getColor();

		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});

		this.globalsService.getTaxes().subscribe(async (tax: any) => {
			this.taxauth = tax;
			this.from_taxauth = tax;
			this.to_taxauth = tax;
			const defaultValue = [];

			tax.forEach(c => {
				defaultValue.push(c.taxgroupid);
			});

			this.generalLedgerService.getPeriods().subscribe(async (results: any) => {

				this.periods = results.periods
				this.from_period_options = results.periods
				this.to_period_options = results.periods

				const today = new Date();
				const priorDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);

				this.formGroup = this.fb.group({
					fromperiod: [results.default_fromperiod, [Validators.required]],
					toperiod: [results.default_toperiod, [Validators.required]],
					fromdate: [priorDate, [Validators.required]],
					todate: [today, [Validators.required]],
					from_tax: [defaultValue],
					//to_tax: [this.to_taxauth[this.to_taxauth.length -1 ].taxgroupid],
					details_sumamry: ['S']
				});
			});
		});
	}

	exportPdf() {
		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.title,
			landscape: true
		}
		this.exporting = true;
		this.printService.pdf(data).subscribe((result: any) => {
			this.exporting = false;
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	exportXls() {

		this.exporting = true;
		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.title,
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.exporting = false;
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

	// updateToOptions(value: any) {
	// 	this.to_taxauth = this.taxauth.filter((tax) => {
	// 		return parseInt(tax.taxgroupid) >= parseInt(value)
	// 	});
	// }

	updateToPeriodOptions(value: any) {
		this.to_period_options = this.periods.filter((period) => {
			return parseInt(period.periodno) > parseInt(value)
		});
	}

	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}

	back() {
		this.location.back();
	}

	getTaxReport() {
		if (this.formGroup.valid) {
			this.loading = true;
			this.generalLedgerService.getCompressedTaxReport(this.formGroup.value).subscribe(async (results: any) => {
				this.loading = false;
				this.title = results.title
				this.taxes = results.data;
				this.totals = results.totals;
				this.group_totals = results.group_totals;

				this.pdflink = results.pdflink;
				this.excellink = results.excellink;
			});
		}
	}

}
