import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Message } from '../classes/message';
import { Observable } from 'rxjs';
import { io } from 'socket.io-client';
import { UsersService } from './users.service';
import { UserGroup, User } from '../classes/user';
import { catchError , retry } from 'rxjs/internal/operators';
import { HttpHeaders,HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { GlobalSearchService } from './globalsearchservice.service';
import { GlobalsService } from './globals.service';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';

@Injectable({
	providedIn: 'root'
})
export class SocketService {
	key = 'UET6DFMPAXW7BKCB';
	config: any;
	//todo make connection reusabel
	socket: any;
	details: any;
	user : any = [];
	environment = {
		production: false,
		SOCKET_ENDPOINT: 'wss://socket.clevehilltire.com:5009',
		user: '',
	};

	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type':	'application/json',
		})
	};

	constructor(private globalsService: GlobalsService,private http: HttpClient,private _configSvc: RuntimeConfigLoaderService) {

		this.config = this._configSvc.getConfig()
		if(this.config) {

			if(this.globalsService.user) {
				this.user = this.globalsService.user.user;
			}

			this.environment.SOCKET_ENDPOINT = this.config.apiServer.socket;
		}
		//this.environment.user = this.globalsService.user
		//user.userid;
	}

	setupSocketConnection(token: string) {

		if(!this.socket) {
			if(!this.user){
				token = this.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
			} else {
				token = this.user.userid;
			}

			this.socket = io(this.environment.SOCKET_ENDPOINT, {
				auth: { token },
				reconnection: false
			});

			this.socket.connected ? '' : this.socket.connect();


		} else {

		}
	}

	subscribeToGroupUpdate = (cb) => {

		if(!this.socket) {
			const token = this.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
			this.setupSocketConnection(token)
		}

		this.joinRoom('Users');

		this.socket.on('updategroup', msg => {
			return cb(null, msg);
		});
	}

	sendUserGroupUpdate= ({ data, roomName }, cb) => {
		if(!this.socket) {
			this.setupSocketConnection(this.user.userid)
		}

		if (this.socket) {this.socket.emit('usergroupupdate', { data , roomName }, cb);}
	}

	joinRoom = (roomName) => {
		this.socket.emit('join', roomName);
	}

	disconnect() {
		if (this.socket) {
			this.socket.disconnect();
			this.socket.connect();
		}
	}
	//interal updates
	public getUserUpdate(user: any) {
		this.user = user;
		const method = 'users/getuserbyid';
		return this.http.post(this.setEndPoint(method), user, this.httpOptions).pipe(
			catchError(this.handleError('users', user))
		);
	}

	private getApi() {
		  return this.config.apiServer.crossPoint;
	}

	private getApiKey() {
		return '&key='+this.config.apiCredentials.clientId+'&userid='+this.user.user.userid;
	}

	public getKey() {
		  return '&apikey='+this.key+'&userid='+this.user.user.userid;
	}

	private getPackage() {
		const p = sessionStorage.getItem('app')
		if(p) {
			return '&package='+p;
		} else {
			return '';
		}
	}


	private setEndPoint(method: any) {
		  return this.getApi()+method+this.getApiKey()+this.getPackage()
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {

			return error;
		};
	}

	randomString(length: any, chars: any) {
		let result = '';
		for (let i = length; i > 0; --i) {result += chars[Math.floor(Math.random() * chars.length)];}
		return result;
	}
}
