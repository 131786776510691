import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from '../services/globalsearchservice.service';
@Injectable({
	providedIn: 'root'
})

export class LoginService {
	key = 'UET6DFMPAXW7BKCB';
	config: any;
	package: any = '';

	constructor(private globalSearchService:GlobalSearchService,private http: HttpClient, private httpClient: HttpClient, private _configSvc: RuntimeConfigLoaderService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})

	}
	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};

	public login(data) {

		const method = 'login';
		if (this.config.apiCredentials.clientId) {
			this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + this.config.clientId);
		}
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('login', data))
		);
	}

	private getApi() {
		return this.config.apiServer.endpoint;
	}

	private getApiKey() {

		// if (this.config.clientId) {
		// 	this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + this.config.clientId);
		//   }
		//
		//   // Check if userId exists before adding it to headers
		//   if (this.config.userId) {
		// 	this.httpOptions.headers = this.httpOptions.headers.set('UserId', this.config.userId);
		//   }
		//
		if (this.config.apiCredentials.clientId) {
			this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + this.config.apiCredentials.clientId);
		}

		return '&key=' + this.config.apiCredentials.clientId;

	}

	public getKey() {
		if (this.config.apiCredentials.clientId) {
			this.httpOptions.headers = this.httpOptions.headers.set('Authorization', 'Bearer ' + this.config.apiCredentials.clientId);
		}
		return '&key=' + this.config.apiCredentials.clientId;

	}

	private setEndPoint(method: any) {
		return this.getApi() + method + this.getApiKey()
	}

	private handleError<T>(operation = 'operation', result?: T) {

		return (error: any): Observable<T> => {
			if (error instanceof HttpErrorResponse && error.status === 401) {

				// Redirect to login page using window.navigate
				alert('Access From this device is not allowed');
			}

			return error;
	  };
	}
}
