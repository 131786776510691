<ng-template #details let-modal>
	<div class="modal-header">
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>

	<div class="modal-body" >
		<app-customer-trans [customerdata]="customer_data" [pos]="false" >
		</app-customer-trans>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class=" row border-bottom pl-1 pr-1 ml-1 mr-1">
	<ng-container *ngIf="!sending">
		<button class="btn btn-success" (click)="exportXls();"><i class="fa fa-file-excel-o"></i></button>
		&nbsp;&nbsp;
		<button class="btn btn-danger" (click)="exportPdf();"><i class="fa fa-file-pdf-o"></i></button>
	</ng-container>
	<ng-container *ngIf="sending">
		<svg class="spinner" width="30px" height="30px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
			<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
		</svg>
	</ng-container>

	<div class="table" #print_table>
		<h4>Results: {{ data.length }}</h4>
		<table class="table table-sm">
			<thead>
			<tr>
				<th class="noprint"></th>
				<th>Customer#</th>
				<th>Name</th>
				<th>Total</th>
			</thead>
			<tbody>
			<tr *ngFor="let d of data">
				<td class="noprint">
					<a mat-button (click)="$event.preventDefault();autoAllocateMatching(d)" *ngIf="allowed_allocate">
						<mat-icon>published_with_changes</mat-icon>
					</a>
				</td>
				<td>
					<a (click)="$event.preventDefault();showTransactions(d)">{{ d.debtorno}}</a></td>
				<td>{{ d.name }}</td>
				<td>{{ d.total | currency }}</td>
			</tr>
			</tbody>
		</table>
	</div>
</div>
