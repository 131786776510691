<ng-container *ngIf="!sending">
	<button class="btn btn-success" (click)="exportXls();"><i class="fa fa-file-excel-o"></i></button>
	&nbsp;&nbsp;
	<button class="btn btn-danger" (click)="exportPdf();"><i class="fa fa-file-pdf-o"></i></button>
</ng-container>
<ng-container *ngIf="sending">
	<svg class="spinner" width="30px" height="30px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
		<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
	</svg>
</ng-container>
<div class="table" #print_table>
	<table class="table">
		<tr>
			<th>Vendor</th>
			<th>Name</th>
			<th>Terms</th>
			<th>Bal</th>
			<th>30 Day</th>
			<th>60 Day</th>
			<th>90 Day</th>
		</tr>
		<tr *ngFor="let v of data">
			<td>{{ v.supplierid }}</td>
			<td>{{ v.suppname }}</td>
			<td>{{ v.terms }}</td>
			<td>{{ v.balance | currency }}</td>
			<td>{{ v.due | currency }}</td>
			<td>{{ v.overdue1 | currency }}</td>
			<td>{{ v.overdue2 | currency }}</td>
		</tr>
	</table>
</div>
