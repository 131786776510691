<ng-container *ngIf="filters">
	<ng-container *ngIf="!issalesman">
		<div class="row text-center">
			<button mat-raised-button color="primary" (keyup.enter)="$event.preventDefault();" (keydown.enter)="$event.preventDefault();" class="resetButton" color="primary" (click)="$event.preventDefault(); applyFilters();" class="ml-auto mr-auto">
				<mat-icon>done</mat-icon> Apply
			</button>
			&nbsp;
			<button mat-raised-button color="accent" (keyup.enter)="$event.preventDefault();" (keydown.enter)="$event.preventDefault();" class="resetButton" color="accent" (click)="$event.preventDefault(); resetFilters();" class="ml-auto mr-auto">
				<mat-icon>restore</mat-icon> Reset
			</button>
		</div>

		<ng-container *ngFor="let filter of filters">

			<h5 class="mb-1 mt-2">{{ filter.name }}</h5>
			<ng-container *ngIf="filter.input_type == 'select'">
			  <div class="row">
				<mat-form-field appearance="outline" class="col-12 custom-mat-select">
				  <mat-label>{{ filter.name }}</mat-label>
				  <mat-select [(value)]="filter.default_value" (selectionChange)="updateSearchSelect($event, filter.filter_id)" panelClass="custom-mat-panel">
					<mat-option *ngFor="let opt of filter.options" [value]="opt.value">
					  {{ opt.label }}
					</mat-option>
				  </mat-select>
				</mat-form-field>
			  </div>
			</ng-container>


			<ng-container *ngIf="filter.input_type == 'multis'">
				<div class="row">
					<mat-form-field appearance="outline" class="col-12">
						<mat-label>{{ filter.name }}</mat-label>
						<mat-select [(value)]="filter.default_value" (selectionChange)="updateSearchSelect($event, filter.filter_id)" multiple appSelectAll>
							<mat-option *ngFor="let opt of filter.options" [value]="opt.value">
								{{opt.label }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</ng-container>
			<ng-container *ngIf="filter.input_type == 'checkbox'">
				<div class="row">
					<mat-checkbox class="col-6" *ngFor="let opt of filter.options" [value]="opt.value" (change)="updateChecks($event, filter.filter_id)">
						{{opt.label }}
					</mat-checkbox>
				</div>
			</ng-container>
			<mat-divider></mat-divider>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="issalesman">
		<div class="row m-0 p-0 ">
			<div class="mt-2 col-6 d-none d-lg-inline col-md-6 text-center col-lg-2 mb-2">
				<button mat-raised-button color="accent" (keyup.enter)="$event.preventDefault();" (keydown.enter)="$event.preventDefault();" class="resetButton" color="accent" (click)="$event.preventDefault(); resetFilters();" class="ml-auto mr-auto">
					<mat-icon>restore</mat-icon>
				</button>
			</div>
			<ng-container *ngFor="let filter of filters">
				<ng-container *ngIf="filter.input_type == 'select'">
					<div class="col-md-6 col-lg-4">
						<mat-form-field appearance="outline" >
							<mat-label>{{ filter.name }}</mat-label>
							<mat-select [(value)]="filter.default_value" (selectionChange)="updateSearchSelect($event, filter.filter_id)">
								<mat-option value="reset">All</mat-option>
								<mat-option *ngFor="let opt of filter.options" [value]="opt.value">
									{{opt.label }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</ng-container>
				<ng-container *ngIf="filter.input_type == 'multis'">
					<div class="col-md-6 col-lg-4">
						<mat-form-field appearance="outline">
							<mat-label>{{ filter.name }}</mat-label>
							<mat-select [(value)]="filter.default_value" (selectionChange)="updateSearchSelect($event, filter.filter_id)" multiple appSelectAll>
								<mat-option *ngFor="let opt of filter.options" [value]="opt.value">
									{{opt.label }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</ng-container>
				<ng-container *ngIf="filter.input_type == 'checkbox'">
					<div class="m-0 p-0 ol-md-3 col-lg-4">
						<mat-checkbox class="col-6" *ngFor="let opt of filter.options" [value]="opt.value" (change)="updateChecks($event, filter.filter_id)">
							{{opt.label }}
						</mat-checkbox>
					</div>
				</ng-container>
			</ng-container>

			<div class="mt-2 col-6 d-lg-none col-md-6 text-center col-lg-2 mb-2">
				<button mat-raised-button color="accent" (keyup.enter)="$event.preventDefault();" (keydown.enter)="$event.preventDefault();" class="resetButton" color="accent" (click)="$event.preventDefault(); resetFilters();" class="ml-auto mr-auto">
					<mat-icon>restore</mat-icon>
				</button>
			</div>
			<div class="mt-2 col-6 col-md-6 text-center col-lg-2 ">
				<button mat-raised-button color="primary" (keyup.enter)="$event.preventDefault();" (keydown.enter)="$event.preventDefault();" class="resetButton" color="primary" (click)="$event.preventDefault(); applyFilters();" class="ml-auto mr-auto">
					<mat-icon>search</mat-icon>
				</button>
			</div>
		</div>
	</ng-container>
</ng-container>
