<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">

					<div class="card-header card-header-{{ color }}-5" >
						<h4 class="card-title pointer"><i class="material-icons" (click)="back()">arrow_left</i> Customers</h4>
							<p class="card-category text-right">Customer Lookup</p>
					</div>
					<div class="card-body">
						<app-customer-lookup (customer_selected)="selectCustomer($event)"></app-customer-lookup>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
