<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Open Purchase Orders </h4>
					</div>
					<div class="card-body">
						<app-open-purchase-orders [vendor]="vendor_data" (orderloaded)="selectItemLookup($event)" (po_data)="updatePoData($event)"></app-open-purchase-orders>
					</div>
				</div>
			</div>
		</div>
	</div>
