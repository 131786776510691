import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup,ReactiveFormsModule } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../../services/customer.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';

@Component({
	selector: 'app-customer-shiptos',
	templateUrl: './customer-shiptos.component.html',
	styleUrls: ['./customer-shiptos.component.scss'],
	animations: [
		trigger('grow', [ // Note the trigger name
		  transition(':enter', [
			// :enter is alias to 'void => *'
				style({ height: '0',width: '0', overflow: 'hidden' }),
				animate(200, style({ height: '*' , width: '*' })),
				animate('200ms', style({ opacity: 1 })),
		  ]),
		  transition(':leave', [
			// :leave is alias to '* => void'
				animate(300, style({ height: 0, width: 0 , overflow: 'hidden' })),
				animate('300ms', style({ opacity: 0 }))
		  ])
		]),
	]
})
export class CustomerShiptosComponent implements OnInit {
	@Input() customerdata;
	@Input() newcustomer;
	@Input() edittoggle;
	@Output() updatebranch = new EventEmitter<boolean>();

	branches: any = [];
	branch_data: any = [];
	editing = false;
	newcustomertoggle = false;
	currentbranch: any = [];
	currentindex: any;
	branchFormGroup: UntypedFormGroup;
	//todo make this part of configurations or define company variables on login

	defaults = {
		country:'United States',
		region: 'NY',
		holdreason: '1',
		currency: 'USD',
		terms: '1',
		salestype: 'J',
		customertype: '1',
		discount: 0,
		credit: 1000,
		email_invoice: false,
		requirepo: false,
		email_statement: false,
		interest: 3,
		warehouse: '01',
		shipvia: '1',
		area: '01',
		territory: '01',
		salesman: '01',
		disabled: false,
		tirefee: true,
		taxauth: '119',
		branchcode: '00',
	};

	headercolumns: string[] = [
		'debtorno',
		'branchccode',
		'brname',
		'braddress1',
		'braddress3',
		'braddress4',
		'braddress5',
	];

	constructor(private fb: UntypedFormBuilder,public customerService: CustomerService,public globalSearchService: GlobalSearchService,public router: Router) { }

	ngOnInit(): void {
		this.branch_data = this.customerdata.branches;
		this.branches = new MatTableDataSource(this.customerdata.branches);
		if(this.customerdata.branches.length == 1) {
			this.editing = true;
			this.currentbranch = this.customerdata.branches[0];
			this.currentindex = this.customerdata.branches.findIndex((branch) => {
				return branch.branchcode == this.currentbranch.branchcode
			});
		}
	}

	ngAfterViewInit() {

	}

	updateCustomer(input: any){
		this.newcustomer = false;
		this.editing = false;
		this.currentbranch = false;
		//this.customerdata.branches[this.currentindex] = input
		this.updatebranch.emit(input)
	}

	editBranch(branchcode: any) {
		this.editing = true;
		this.newcustomer = false;

		this.currentbranch = this.branch_data.filter((branch) => {
			return branch.branchcode == branchcode;
		})[0];


		this.currentindex = this.customerdata.branches.findIndex((branch) => {
			return branch.branchcode == branchcode
		});

	}

	removeBranch(input: any) {
		const c = confirm('Remove this branch?');
		if(c) {
			this.customerService.removeBranch(this.customerdata.debtorno, input).subscribe((result: any) => {
				if(result) {
					if(!result.success) {
						alert(result.message);
					} else {
						this.customerService.getDebtor(this.customerdata.debtorno).subscribe((results:any) => {
							this.customerdata = results;
							this.updatebranch.emit(this.customerdata)
						});
					}
				}
			});
		}
	}

	updateEditing(input: any) {
		this.editing = input;
	}

}
