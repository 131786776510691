import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { EducationService } from '../../services/education.service';
import { Observable } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatSortModule, MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, ValidationErrors, FormControl, UntypedFormGroup, UntypedFormControl } from '@angular/forms';


@Component({
  selector: 'app-education-locations',
  templateUrl: './education-locations.component.html',
  styleUrls: ['./education-locations.component.scss']
})
export class EducationLocationsComponent {
	color = '';
	loclist: any = [];
	@ViewChild('setupModal') setupRef: ElementRef;
	locForm: UntypedFormGroup;
	selectedLoc: any = false;
	
	dataSource: MatTableDataSource < any > ;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataObs: Observable < any > ;
	items_per_page = [5, 10, 20 , 100, 500, 1000];
	pagesizedefault = 20;
	loading: any = false;
	
	constructor(private _changeDetectorRef: ChangeDetectorRef, private fb: UntypedFormBuilder, private educationService: EducationService, private globalSearchService: GlobalSearchService, private route: ActivatedRoute, public router: Router, private location: Location, private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
		
		this.educationService.getLocations().subscribe((a) => {
		if(a){
		this.loclist = a;
		}
		this.setPagination([]);
		this.setPagination(this.loclist);
		this.locForm = this.fb.group({
			location_name: ['', Validators.required],
			location_description: ['', Validators.required],
			address1: [''],
			address2: [''],
			address3: [''],
			address4: [''],
			address5: [''],
			phone: [''],
		});
		});
		
		
	}
	
	setPagination(tableData) {
		this.dataSource = new MatTableDataSource <any> (tableData);
		this._changeDetectorRef.detectChanges();
		this.dataSource.paginator = this.paginator;
		this.dataObs = this.dataSource.connect();

	}
	
	back(): void {
		this.location.back()
	}
	
	addLocation(){
	
		this.locForm = this.fb.group({
			location_name: ['', Validators.required],
			location_description: ['', Validators.required],
			address1: [''],
			address2: [''],
			address3: [''],
			address4: [''],
			address5: [''],
			phone: [''],
		});
		this.modalService.open(this.setupRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}
	
	editLocation(loc: any){
		this.selectedLoc = loc;
		this.locForm = this.fb.group({
			location_name: [loc.location_name, Validators.required],
			location_description: [loc.location_description, Validators.required],
			address1: [loc.location_options.address1],
			address2: [loc.location_options.address2],
			address3: [loc.location_options.address3],
			address4: [loc.location_options.address4],
			address5: [loc.location_options.address5],
			phone: [loc.location_options.phone],
		});
		this.modalService.open(this.setupRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}
	
	saveLocation(){
		
		if(this.locForm.valid){
		const data = { location_id: this.selectedLoc.events_locationid, locdetails: this.locForm.value};
		
		this.educationService.saveLocation(data).subscribe((a) => {
		this.loclist = a;
		this.setPagination(this.loclist);
		this.selectedLoc = [];
		this.modalService.dismissAll();
		});
		} else {
		 this.getFormValidationErrors(this.locForm);
		}
	}
	deleteLocation(loc: any){
		const data = {location_id: loc.events_locationid};
		this.educationService.deleteLocation(data).subscribe((a) => {
		this.loclist = a;
		this.setPagination([]);
		this.setPagination(this.loclist);
		});
	}
	
	getFormValidationErrors(form: any) {
		Object.keys(form.controls).forEach((key, index) => {
			const controlErrors: ValidationErrors = form.get(key).errors;

			if (controlErrors != null) {
				Object.keys(controlErrors).forEach(keyError => {
					//this.customerForm.controls[index].markAsTouched()
					form.markAllAsTouched();
					//form.markAsUntouched()

					this.globalSearchService.showNotification(key + ' ' + keyError, 'danger', 'bottom', 'right')
				});
			}
		});
	}
}
