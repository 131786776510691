import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ViewportScroller , Location , DatePipe } from '@angular/common';
import { Subject, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { OmsService } from '../../services/oms.service';
import { PurchasingService } from '../../services/purchasing.service';
import { VendorService } from '../../services/vendor.service';
import { MatAccordion } from '@angular/material/expansion';
import { MatProgressBar } from '@angular/material/progress-bar';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

@Component({
	selector: 'app-delivery-board',
	templateUrl: './delivery-board.component.html',
	styleUrls: ['./delivery-board.component.scss']
})
export class DeliveryBoardComponent implements OnInit {
	percentdone = 0;
	scanned = 0;
	expected = 0;
	vendors: any = [];
	openlines: any = [];
	lineresults: any = [];
	token = '';
	color: any = '';
	config:  any = [];
	problems: any = [];
	selectedVendor: any = '';

	constructor(private purchasingService: PurchasingService, private vendorService: VendorService, private omsService: OmsService, private globalSearchService: GlobalSearchService) {

		this.token = this.globalSearchService.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
		this.omsService.setupSocketConnection(this.token);
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});
	}

	ngOnInit(): void {
		this.percentdone = (this.scanned / this.expected * 100);

		this.vendorService.getVendors().subscribe(results => this.vendors = results);

		this.omsService.subscribeToPuts((err, data) => {

			const stockchk = data.data.item.stockid;
			let qtyadd = data.data.item.quantityrecd;
			this.scanned = this.scanned + parseInt(qtyadd);
			for(let x = 0; x<this.openlines.length; x++){
				if(this.openlines[x].stockid == stockchk && this.openlines[x].ordered != this.openlines[x].received){
					const remqty = this.openlines[x].ordered - this.openlines[x].received;

					if(qtyadd > remqty){
						qtyadd = qtyadd - remqty;
						this.openlines[x].received = this.openlines[x].ordered;
					}
					else {

						this.openlines[x].received = parseInt(this.openlines[x].received) + parseInt(qtyadd);
						qtyadd = 0;
					}
					if(qtyadd <= 0){
						break;
					}

				}

			}
			//Any leftover must be an overscan, so find the first and add to it for tracking purposes
			if(qtyadd > 0){
				for(let x = 0; x<this.openlines.length; x++){
					if(this.openlines[x].stockid == stockchk){
						this.openlines[x].received = parseInt(this.openlines[x].received) + parseInt(qtyadd);
						qtyadd = 0;
						break;
					}

				}
			}
			//If we STILL have some left to add, then this item wasn't expected, but we should add it to the list on screen anyway.
			if(qtyadd > 0){
				this.openlines.push({ 'received':qtyadd, 'ordered': 0, 'stockid': stockchk, 'order': 'xxxxx' });
			}
			this.percentdone = (this.scanned / this.expected * 100);
		})

		this.openlines = [];
	}


	getRemainingReceiving(){

		const data = { vendor: this.selectedVendor };
		this.purchasingService.getTruckIncoming(data).subscribe(results => {
			this.openlines = [];
			this.lineresults = results;
			this.expected = 0;
			this.scanned = 0;
			for(let x = 0; x < this.lineresults.length; x++){
				this.expected = this.expected + parseInt(this.lineresults[x].ordered);
				this.scanned = this.scanned + parseInt(this.lineresults[x].received);
				this.openlines.push(this.lineresults[x]);
			}
			this.percentdone = (this.scanned / this.expected * 100);
		});

	}

	checkDiscrepancy(){
		this.problems = [];
		for(let x = 0; x< this.openlines.length; x++){
			if(this.openlines[x].received != this.openlines[x].ordered){
				this.problems.push(this.openlines[x]);
			}
		}
	}
	clearDiscrepancy(){
		this.problems = [];
	}
	holdRec(){
		const data = { vendor: this.selectedVendor, itemdata: this.openlines };
		this.purchasingService.setTruckHold(data).subscribe(results => {
			this.openlines = [];
			this.selectedVendor = '';
			this.expected = 0;
			this.scanned = 0;
			this.percentdone = 0;
		});

	}
	finalize(){
		const data = { vendor: this.selectedVendor };
		this.purchasingService.setTruckDone(data).subscribe(results => {
			this.openlines = [];
			this.problems = [];
			this.selectedVendor = '';
			this.expected = 0;
			this.scanned = 0;
			this.percentdone = 0;
		});
	}
	exportPDF(element: any) {

		const html = htmlToPdfmake(document.getElementById(element).innerHTML, {});

		const docDefinition = {
			pageSize: 'Letter',
			defaultStyle: {
				fontSize: 8,
			},
			content: html,
			styles: {
				table: {
					fontSize: 12,
				},
				anotherStyle: {
					italics: true,
					alignment: 'right'
				}
			},
			pageMargins: [40, 60, 40, 60],
		};

		//const documentDefinition = { content: html };
		pdfMake.createPdf(docDefinition).download();
	}

}
