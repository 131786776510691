import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform } from '@angular/core';
import { DropzoneComponent , DropzoneDirective,
	DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { PurchasingService } from '../../services/purchasing.service';
import { ActivatedRoute, Router } from '@angular/router';

import { UploadResults } from '../../classes/upload';

@Component({
	selector: 'app-purchasing-receiptjournalupload',
	templateUrl: './purchasing-receiptjournalupload.component.html',
	styleUrls: ['./purchasing-receiptjournalupload.component.scss']
})
export class PurchasingReceiptjournaluploadComponent implements OnInit {

	files: File[] = [];
	selectedsupplier = '';
	supplierDetails: any = {};
	uploadresults: UploadResults;
	user: any = [];

	public config: DropzoneConfigInterface = {
		clickable: true,
		maxFiles: 1,
		autoReset: null,
		errorReset: null,
		cancelReset: null
	};

	constructor(private route: ActivatedRoute,private purchasingService :PurchasingService ) { }

	ngOnInit(): void {
		this.route.params.subscribe(params => {
			if(params['id']) {
				this.selectedsupplier = params['id'];
				const supplierid= this.selectedsupplier;
				this.purchasingService.getSuppliers(supplierid).subscribe((results:any) => {
					this.supplierDetails = results;
				});
			}
		})
	}

	onUploadError(event: any) {

	}

	onUploadSuccess(event: any) {

	}

	createPO() {
		this.purchasingService.createPurchaseOrder(this.supplierDetails , this.supplierDetails, this.uploadresults, this.user ).subscribe((results:any) => {

		});
	}
	onSelect(event: any) {
		this.files.push(...event.addedFiles);
		for (let i = 0; i < this.files.length; i++) {
			this.readFile(this.files[i]).then(fileContents => {
				this.purchasingService.uploadFile(fileContents).subscribe((results:UploadResults) => {
					this.uploadresults = results;
				});
			});
		}
	}

	onRemove(event: any) {
		this.files.splice(this.files.indexOf(event), 1);
	}

	private async readFile(file: File): Promise<string | ArrayBuffer> {
		return new Promise<string | ArrayBuffer>((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = e => {
				return resolve((e.target as FileReader).result);
			};

			reader.onerror = e => {
				console.error(`FileReader failed on file ${file.name}.`);
				return reject(null);
			};

			if (!file) {
				return reject(null);
			}
			reader.readAsDataURL(file);
		});

		this.files = [];
	}

}
