import { Component, OnDestroy, SimpleChanges, OnChanges, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';
import { interval, Subscription, Subject } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd } from '@angular/router';


import { OrdersService } from '../../services/orders.service';
import { CustomerService } from '../../services/customer.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { PrintService } from '../../services/print.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as FileSaver from 'file-saver';

import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { L } from '@angular/cdk/keycodes';

@Component({
	selector: 'app-customer-cdet',
	templateUrl: './customer-cdet.component.html',
	styleUrls: ['./customer-cdet.component.scss']
})

export class CustomerCdetComponent implements OnInit {
	@Input() customer;
	@Input() menuapp: any = true;
	@ViewChild('printTable') printTableRef: ElementRef;

	range = new FormGroup({
		fromdateCtrl: new FormControl(),
		todateCtrl: new FormControl()
	});

	email = new UntypedFormControl('')
	cdetLink: any = '';
	config: any = false;
	searchsubscripiton: any = false;
	sending = false;
	results: any = [];
	filtered_results: any = [];
	all_results: any = [];
	fromdate = new Date();
	todate = new Date();
	filename = 'CDET';
	displayLookup = true;
	color: any = 'blue';

	constructor(private activatedRoute: ActivatedRoute, private printService: PrintService, private route: ActivatedRoute, public ordersService: OrdersService, public globalSearchService: GlobalSearchService, public router: Router, public customerService: CustomerService, private modalService: NgbModal, private fb: UntypedFormBuilder, private globalsService: GlobalsService) {
		pdfDefaultOptions.assetsFolder = 'assets';
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
			this.cdetLink = r.apiServer.baseUrl + r.apiServer.cdetLink
		})
	}

	ngOnInit(): void {
		if (this.customer && !this.menuapp) {
			this.populateForms();
			this.loadData();
		} else {
			this.activatedRoute.params.subscribe(({ id }) => {
				if (!isNaN(id)) {
					this.results = false;
					this.customerService.getDebtor(id).subscribe((data: any) => {
						this.customer = data;
						this.populateForms();
						this.loadData();
						this.displayLookup = false;
					});

				}
			});
		}
	}

	emailCdet() {
		const data = {
			debtorno: this.customer.debtorno,
			from: this.range.get('fromdateCtrl').value,
			to: this.range.get('todateCtrl').value,
			type: 'email',
			email: this.email.value,
		}
		this.sending = true;
		if (this.searchsubscripiton) {
			this.searchsubscripiton.unsubscribe();
		}
		this.searchsubscripiton = this.customerService.getCdet(data).subscribe((results: any) => {
			this.globalSearchService.showNotification('Email Sent', 'success', 'bottom', 'right');
			this.sending = false;
		});
	}

	isoValue(date: any) {
		if (date && date != '') {
			return date.toISOString();
		}
		return '';
	}

	exportXls() {
		this.sending = true;

		const encoded: string = this.globalSearchService.base64encode(this.printTableRef.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.sending = false;
		});
	}

	loadData() {

		const data = {
			debtorno: this.customer.debtorno,
			from: this.range.get('fromdateCtrl').value,
			to: this.range.get('todateCtrl').value,
		}
		this.sending = true;
		if (this.searchsubscripiton) {
			this.searchsubscripiton.unsubscribe();
		}
		this.searchsubscripiton = this.customerService.getCdet(data).subscribe((results: any) => {
			this.results = results;
			this.filtered_results = results;
			this.all_results = results;
			this.sending = false;
		});
	}


	populateForms() {
		const today = new Date();
		const d = new Date(today);
		d.setDate(1);
		d.setMonth(0);
		const y = d.getFullYear() - 1;
		d.setFullYear(y);

		const firstdayoflastmonth = new Date();
		firstdayoflastmonth.setDate(1);
		firstdayoflastmonth.setMonth(12);
		firstdayoflastmonth.setFullYear(y);
		const lastdayoflastmonth = new Date();
		lastdayoflastmonth.setMonth(12, 0);
		lastdayoflastmonth.setFullYear(y);

		this.fromdate = firstdayoflastmonth
		this.range.get('fromdateCtrl').setValue(d);
		this.range.get('todateCtrl').setValue(lastdayoflastmonth);
		this.email.setValue(this.customer.customer.email);
	}


	selectCustomer(event: any) {
		this.customer = {};
		this.customer = event;
		this.customer.customer = event;
		this.displayLookup = false;
		this.populateForms()
		this.router.navigate(['/customers/cdet', this.customer.debtorno]);
		this.loadData();
	}

	showCustomerLookup() {
		this.displayLookup == true ? this.displayLookup = false : this.displayLookup = true;
	}
}
