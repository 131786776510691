<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-5">
						<h4 class="card-title"><i class="material-icons" (click)="back()">arrow_left</i> Inventory Upload</h4>
					</div>
					<div class="card-body">
						<ng-container *ngIf="!uploaddata.length">
							<div class="row">
								<div class="col-md-3 mb-3">
									<!-- <mat-slide-toggle class="nav-link" [formControl]="preflight">
										<mat-label>Pre Flight Only</mat-label>
									</mat-slide-toggle> -->

									<ngx-dropzone [multiple]="false" (change)="onSelect($event)">
										<ngx-dropzone-label class="m-2">
											DROP FILE HERE OR CLICK TO UPLOAD
										</ngx-dropzone-label>
										<ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)" (error)="onUploadError($event)" (success)="onUploadSuccess($event)">
											<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
										</ngx-dropzone-preview>
									</ngx-dropzone>
								</div>
								<div class="col-md-9">
									<mat-spinner matPrefix diameter="30" *ngIf="sending"></mat-spinner>
									<table class="table table-fixed table-striped" *ngIf="filelist.files && !sending">
										<thead>
											<tr>
												<th>File</th>
												<th>Status</th>
												<th>Records</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let f of filelist.files">
												<td>{{ f.name }}</td>
												<td>{{ f.status }}</td>
												<td>{{ f.records }}</td>
												<td>
													<button class="btn btn-secondary btn-sm mr-2" (click)="prepFileExport(f);">MatchFields</button>
													<button class="btn btn-primary btn-sm mr-2" (click)="prepFile(f);">Process</button>
													<button class="btn btn-danger btn-sm" (click)="removeFile(f);">Remove</button>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</ng-container>
						<ng-container *ngIf="uploaddata">
							<h4>{{ uploaddata.records }}</h4>
							<div class="row">
								<div class="col-md-12">
									<div class="text-center">
										<span class="mr-3">Count: {{ uploaddata.records }}</span>
										<span class="mr-3">Update: {{ uploaddata.updates }}</span>
										<span class="mr-3">New: {{ uploaddata.new }}</span>
										<span class="mr-3">New Matrix: {{ uploaddata.matrix_new_cnt }}</span>
										<span class="mr-3">Update Matrix: {{ uploaddata.matrix_up_cnt }}</span>
									</div>
								</div>
							</div>
							<div class="table-responsive">
								<table class="table table-bordered">
									<thead>
										<tr>
											<th class="text-center" *ngFor="let header of uploaddata.headers">{{ header }}</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let item of uploaddata.tmpdata">
											<td class="text-center" *ngFor="let header of uploaddata.headers">{{ item[header] }}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="alert alert-info" *ngIf="!uploaddata || uploaddata.tmpdata.length === 0">No Files</div>
							<div class="text-right">
								<button class="btn btn-primary" (click)="process(uploaddata)">Process</button>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
