<div class="page-wrapper">
	<div class="authentication-box">
		<div class="container">
			<div class="row text-center">
				<div class="offset-md-4 col-md-6 card-right ml-auto mr-auto">
					<div class="card tab2-card ">
						<img class="card-img-top img img-responsive img-fluid ml-auto mr-auto w-90 logo" src="{{logo}}" alt="">
						<div class="card-body">
							<i class="icon-user mr-2"></i>Login
							<div class="tab-pane fade active show" id="account" role="tabpanel" aria-labelledby="account-tab">
								<form [formGroup]="loginForm" (ngSubmit)="onLogin()" class="form-horizontal auth-form" novalidate="">
									<div class="form-group">
										<input required="" formControlName="userName" name="login[username]" type="email" class="form-control" placeholder="Username" id="veightInputEmail1">
									</div>
									<div class="form-group">
										<input required="" formControlName="password" name="login[password]" type="password" class="form-control" placeholder="Password">
									</div>
									<div class="form-terms">

										<div *ngIf="loginForm.value.txterror" class="alert alert-danger">{{ loginForm.value.txterror }}</div>
										<input type="hidden" name="txterror" formControlName="txterror" value="{ loginForm.txterror }}">

										<div class="custom-control custom-checkbox mr-sm-2 d-none">
											<input type="checkbox" class="custom-control-input" id="customControlAutosizing">
											<label class="custom-control-label" for="customControlAutosizing">Remember me</label>
											<a href="javascript:void(0)" class="btn btn-default forgot-pass">lost your
												password</a>
										</div>
									</div>

									<div *ngIf="txterror" class="alert alert-danger">{{ txterror }}</div>


									<div class="form-button">
										<button class="btn btn-primary" type="submit">Login</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
