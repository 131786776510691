import { Component, OnDestroy, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms'
import { Subject, Observable, Subscription, forkJoin } from 'rxjs';
import { take, debounceTime, finalize } from 'rxjs/operators';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { PaymentsService } from '../../services/payments.service'

@Component({
	selector: 'app-pos-order-payments-after',
	templateUrl: './pos-order-payments-after.component.html',
	styleUrls: ['./pos-order-payments-after.component.scss']
})
export class PosOrderPaymentsAfterComponent {

	paymentForm: UntypedFormGroup;

	@Input() order_header;
	@Input() items: any = [];
	@Input() totals: any = [];
	@Input() pos: any = false;
	@Input() document_type: any = '30';
	@ViewChild('amountId') amountIdRef: ElementRef;
	@Input() payments: any = [];

	//not being sent in the header. on time
	@Input() orderno: any;
	@Input() debtorno: any;
	@Input() branchcode: any;

	@Output() payments_added = new EventEmitter < any > ();
	@Output() payments_removed = new EventEmitter < any > ();

	paymentmethods: any = false
	bankaccounts: any = false;
	loadingcards = false;
	default_payment = 'Cash';

	hasprofile: any = false;
	profile_cards: any = false;
	payment_card: any = false;
	card_required: any = false;
	preselected_payment: any = false;

	balance_due: any = 0.00;
	refund = new FormControl(false);
	change_due: any = false;

	constructor(private formBuilder: UntypedFormBuilder, private globalSearchService: GlobalSearchService, private paymentsService: PaymentsService) {}

	ngOnInit(): void {
		this.globalSearchService.payment_methods.subscribe((results: any) => {
			if (results) {
				this.paymentmethods = results.filter((result: any) => result.pos_enabled === '1');
			} else {
				this.getPaymentMethods();
			}
		});

		this.globalSearchService.bankaccounts.subscribe((results: any) => {
			this.bankaccounts = results;
			const account = (this.bankaccounts && this.bankaccounts[0]) ? this.bankaccounts[0].accountcode : '';
			this.resetPayForm();
		});

		//this.paymentForm.markAllAsTouched();

	}

	setBalanceValue(): void {
		const balance = this.getBalance();
		if (balance >= 0) {
			if (this.paymentForm) {
				this.paymentForm.get('amount').setValue(this.financial(balance));
			}
		} else {
			if (this.paymentForm) {
				this.paymentForm.get('amount').setValue(0.00);
			}
		}
	}

	getPaymentMethods() {
		this.globalSearchService.updatePaymentMethods();
		this.globalSearchService.payment_methods.subscribe((results: any) => {
			if (results) {
				this.paymentmethods = results.filter((result: any) => result.pos_enabled === '1');
			}
		});
	}

	addPaymentInput() {
		this.resetPayForm();
	}

	addPayment() {
		if (this.paymentForm.valid) {
			//order 1 is pre payment
			const data = {
				payment: this.paymentForm.value,
				order: {
					orderno: (this.order_header.orderno) ? this.order_header.orderno : '1',
					debtorno: this.order_header.debtorno,
					branchcode: this.order_header.branchcode,
					document_type: this.document_type
				}
			};

			if (data.payment && data.payment.amount) {
				const amount = parseFloat(data.payment.amount);

				if (!isNaN(amount) && amount !== 0) {
					this.paymentsService.addPayment(data).subscribe((results: any[]) => {
						this.payments_added.emit(false);
						this.resetPayForm();
					});
				}
			}


		}
	}

	removePayment(payment_id: any) {
		this.paymentsService.removePayment(payment_id).subscribe((results: any[]) => {
			this.payments = results;
			this.setBalanceValue();
			this.payments_removed.emit(false);
		});
	}

	updatePaymentAmount(event: any, payment: any) {
		payment.amount = event.target.value;
		this.updatePayment(payment)
	}

	updatePaymentType(event: any) {

		const thispay = this.paymentmethods.filter(p => {
			return p.paymentname == event.value
		})[0];

		if (thispay) {
			if (thispay.cc_process != '0') {
				this.card_required = true;
			} else {
				this.card_required = false;
				this.paymentForm.get('card_selected').setValue(false);
			}
		}

		this.setBalanceValue();
	}

	updatePaymentReference(event: any, payment: any) {
		payment.reference = event.target.value;
		this.updatePayment(payment)
	}

	updatePaymentBank(event: any, payment: any) {
		payment.bankaccount = event.target.value;
		this.updatePayment(payment)
	}

	updatePayment(payment: any) {
		//this.payments[index].splice(0,1);
		this.paymentsService.updatePayment(payment).subscribe((results: any[]) => { this.payments = results });
		this.getPayments();
	}

	resetPayForm() {

		const account = (this.bankaccounts && this.bankaccounts[0]) ? this.bankaccounts[0].accountcode : '';

		let initvalue = this.balance_due;

		this.paymentForm = this.formBuilder.group({
			reference: [''],
			amount: [initvalue, [Validators.required, Validators.pattern('^[0-9.]*$')]],
			type: [this.default_payment, [Validators.required]],
			bankaccount: [account, [Validators.required]],
			card_selected: [false],
			charge_card: [false],
		});

		this.setBalanceValue()
	}

	getPayments() {
		this.payments_added.emit(false);
	}

	getBalance(): number {
		if (this.totals) {
			const total = this.totals.find(t => t.code === 'total');
			const totalAmount = total ? this.financial(total.text) : 0.00;
			const balance = totalAmount - this.financial(this.getTotalPayment());

			if (balance < 0) {
				this.payments.forEach(pay => {
					if (this.openCashDroor(pay.payment_type_id) && balance < 0) {
						this.change_due = balance;
						// Additional logic related to cash payments and change can be added here if needed
					}
				});
			} else {
				this.change_due = 0;
			}
			this.balance_due = balance;
			return balance;
		}
		return 0
	}

	openCashDroor(method: any) {

		let value = false;
		if (this.paymentmethods) {
			const thispay = this.paymentmethods.filter(p => {
				return p.paymentid == method
			})[0];
			if (thispay) {
				if (thispay.opencashdrawer == '1') {
					value = true;
				}
			}
		}

		return value;
	}

	getTotalPayment() {
		let total = 0.00
		if (this.payments && this.payments.length > 0) {
			total = this.payments.reduce(function(accumulator, am) {
				return accumulator + parseFloat(am.amount);;
			}, 0);
		}

		total = this.financial(total);
		return total;
	}

	changeCard(event: any) {
		if (event && event.card_type) {
			if (this.paymentForm) {
				this.paymentForm.get('type').setValue(event.card_type);
			}
		}
	}

	lastFour(value: any) {
		return 'XX' + value.substr(value.length - 4);
	}

	isNegative() {
		return (this.financial(this.paymentForm.get('amount').value) < 0)
	}

	ngOnChanges(changes: any) {

		// Check if debtorno changes, fetch payments and profile
		if (changes.debtorno) {
			this.getPayments();
			this.loadProfile(this.debtorno, this.branchcode);

			if (changes.order_header?.currentValue?.preselectedpay) {
				this.preselected_payment = changes.order_header.currentValue.preselectedpay;
			}
		}

		// Update payment totals if payments change
		if (changes.payments?.currentValue?.length) {
			const payment_total = this.payments.reduce((acc, payment) => acc + parseFloat(payment.amount), 0);

			if (this.paymentForm) {
				this.setBalanceValue();
			}
		}

		// Update totals if they change and no payments exist
		if (changes.totals && this.paymentForm) {
			if (!this.payments?.length && changes.totals.currentValue?.total) {
				this.setBalanceValue();
			}
		}

		// Update form defaults if untouched
		if (this.paymentForm?.untouched) {
			if (this.bankaccounts?.length) {
				this.paymentForm.get('bankaccount').setValue(this.bankaccounts[0].accountcode);
			}
			this.paymentForm.get('type').setValue(this.paymentmethods[0].paymentname);

			const paymentId = this.getPaymentIdFromName(this.paymentmethods[0].paymentname);
			this.setCardRequired(paymentId);
		}

		// Preselect a payment card if profile cards are not loaded yet
		if (!this.profile_cards && this.preselected_payment) {
			this.loadingcards = true;
			this.paymentsService.loadProfile(this.debtorno, this.branchcode)
				.pipe(take(1))
				.subscribe((result: any) => {
					this.loadingcards = false;

					if (result.cards?.length) {
						this.hasprofile = true;
						this.profile_cards = result.cards;

						this.paymentForm.get('type').setValue(this.preselected_payment.card_type);
						const selectedCard = this.profile_cards.find((card: any) => card.card_id === this.preselected_payment.card_id);
						this.paymentForm.get('card_selected').setValue(selectedCard);

						const paymentId = this.getPaymentIdFromName(this.preselected_payment.card_type);
						this.setCardRequired(paymentId);
					} else {
						this.hasprofile = false;
						this.profile_cards = [];
						this.payment_card = false;
					}
				});
		}

		// Recalculate balance based on payments and totals
		if (this.payments && this.paymentForm && this.totals?.total) {
			this.setBalanceValue();
		}

		this.getBalance();
	}

	getPaymentIdFromName(name: any) {
		const type = this.paymentmethods.filter(p => {
			return p.paymentname == name
		})[0];

		if (type) {
			return type.paymentid;
		}
		return false;

	}

	loadProfile(debtorno: any, branchcode: any) {
		this.loadingcards = true;

		this.paymentsService.loadProfile(debtorno, branchcode)
			.pipe(take(1)) // Automatically complete after the first emission
			.subscribe((result: any) => {
				this.loadingcards = false;

				if (result.cards && result.cards.length > 0) {
					this.hasprofile = true;
					this.profile_cards = result.cards;

					// Set default card
					const defaultCard = result.cards.find((card: any) => card.default);
					this.paymentForm.get('card_selected').setValue(defaultCard);
				} else {
					this.hasprofile = false;
					this.profile_cards = [];
					this.payment_card = false;
					this.paymentForm.get('card_selected').setValue(false);
				}
			});
	}



	setCardRequired(method: any) {

		if (method) {
			const thispay = this.paymentmethods.filter(p => {
				return p.paymentid == method
			})[0];

			if (thispay) {
				if (thispay.cc_process != '0') {
					this.card_required = true;
				} else {
					this.card_required = false;
					this.paymentForm.get('charge_card').setValue(false);
					this.paymentForm.get('card_selected').setValue(false);
				}
			}
		}
	}

	setChargeType(event: any) {
		if (event.value) {
			this.paymentForm.get('type').setValue(event.value)
			this.updatePaymentType(event.value)
			this.setCardRequired(event.value);

			setTimeout(() => {
				this.amountIdRef.nativeElement.focus();
			}, 0);
		}
	}

	removeAllPayments() {
		// this.payments = [];
		const payload = {
			orderno: this.orderno,
			debtorno: this.debtorno,
			branchcode: this.branchcode,
		}
		this.paymentsService.removeAllPayments(payload).subscribe((results: any[]) => {
			//this.getPayments();
			this.payments_removed.emit(false);
		});
	}

	runKey(value: any) {

		switch (value) {
			case 'add':
				this.addPayment();
				break;
			case 'backspace':
				const newvalue = (this.paymentForm.get('amount').value + '').slice(0, -1);
				if (newvalue != '') {
					this.paymentForm.get('amount').setValue(newvalue);
				} else {
					this.paymentForm.get('amount').setValue(0.00);
				}

				break;
			case 'minus':

				this.makeNegativePayment()
				break;
			case '0':
			case '.':
				const periodval = this.paymentForm.get('amount').value + '' + value;
				this.paymentForm.get('amount').setValue(periodval);
				break;
			default:
				const currentvalue = this.paymentForm.get('amount').value + '' + value;
				this.paymentForm.get('amount').setValue(this.financial(currentvalue));
				break;
		}
	}

	makeNegativePayment() {
		//Math.abs()
		const newvalue = -1 * this.financial(this.paymentForm.get('amount').value);
		if (newvalue > 1) {
			this.refund.setValue(false);
		} else {
			this.refund.setValue(true);
		}

		this.paymentForm.get('amount').setValue(newvalue)
	}


	showRemoveAllButton(): boolean {
		return this.payments && this.payments.length > 0;
	}

	convertArrayToTitleText(arr: {
		[key: string]: number
	} []): any[] {
		return arr.map(obj =>
			Object.keys(obj).map(key => ({
				title: key,
				text: obj[key].toFixed(2)
			}))
		);
	}

	financial(x) {
		if (Number.isNaN(x)) {
			x = 0
		}
		return parseFloat(Number.parseFloat(x).toFixed(2));
	}
}