<ng-template #binadd let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">New Bin Creation</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<form [formGroup]="bincreation">
			<mat-form-field appearance="outline">
				<mat-label>Bin ID</mat-label>
				<input matInput placeholder="Ex. DIF, JKP" value="" formControlName="bininput">
			</mat-form-field>
		</form>
		<button mat-raised-button [disabled]="bincreation.get('bininput').value == ''" (click)="createBin()">Add Bin</button>
	</div>
</ng-template>

<ng-template #binSelect let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Qty To Receive</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="col-md-6 card-group">
				<div class="card m-0">
					<div class="card-header">
						From:
					</div>
					<div class="card-body" style="white-space: pre-line">
						{{ fromaddress }}
					</div>
				</div>
			</div>
			<div class="col-md-6 card-group">
				<div class="card m-0">
					<div class="card-header">
						To:
					</div>
					<div class="card-body" style="white-space: pre-line">
						{{ toaddress }}
					</div>
				</div>
			</div>
		</div>
		<div class="row" style="background:black;color:white;">
			<div class="col-md-2">
				Item Code
			</div>
			<div class="col-md-3">
				Description
			</div>
			<div class="col-md-1">
				Ship Qty
			</div>
			<div class="col-md-3">
				Bin
			</div>
			<div class="col-md-2">
				Qty to Receive <mat-checkbox (change)="toggleAll($event)" class="text-white"></mat-checkbox>
			</div>
			<div class="col-md-1 text-center">
				Status
			</div>
		</div>
		<div class="row bottom-divider" *ngFor="let bin of items">
			<div class="col-md-2 mt-auto mb-auto ">
				{{bin.stockid}}
			</div>
			<div class="col-md-3 mt-auto mb-auto ">
				{{bin.description}}
			</div>
			<div class="col-md-1 mt-auto mb-auto text-center ">
				{{bin.releasedqty | number: '1.0-0' }}
			</div>
			<div class="col-md-3 row mt-auto mb-auto ">
				<mat-form-field appearance="outline">
					<mat-label>Bin</mat-label>
					<mat-select (selectionChange)="updateBinSelection($event, bin)" [value]="setBin(bin)">
						<!-- <mat-option (click)="openBinAdd(bin)"><mat-icon>add</mat-icon>Add a bin</mat-option> -->
						<ng-container *ngFor="let bincode of bin.bins">
							<mat-option *ngIf="bincode.bin != ''" [value]="bincode.bin">
								{{bincode.bin}}
							</mat-option>
						</ng-container>

					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-2 mt-auto mb-auto ">
				<mat-form-field appearance="outline">
					<mat-label>Qty</mat-label>
					<span matPrefix>#</span>
					<input matInput tabindex="{{ i + 2 }}" type="text" minimum="0" value="{{ bin.quantity  }}" autofocus (input)="updateBinQuantity($event, bin )" class="text-right">
				</mat-form-field>
			</div>
			<div class="col-md-1 mt-auto mb-auto text-center" [ngClass]="parseF(bin.transferqty) > parseF(bin.quantity) ? 'warn' : parseF(bin.transferqty) == parseF(bin.quantity) ? 'good' : 'bad'">
				<mat-icon>check_box</mat-icon>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-10">
			<h4 class="ml-auto text-right">Total Quantity Received: {{ getTotalQtyRecv() }}</h4>
			<h4 class="ml-auto text-right">Total Value: {{ getTotalQtyTimesPrice()
												 | currency }}</h4>
		</div>
		<div class="col-md-2">
			<button class="btn btn-success" (click)="receiveItems(items)"> Receive Items </button>
		</div>
	</div>
</ng-template>
<div class="container-fluid">

	<div class="row">

		<div class="container-fluid">
			<form [formGroup]="searchForm">
				<div class="row">
					<div class="col-md-4">
						<mat-form-field appearance="fill">
							<mat-label>Start Date</mat-label>
							<input matInput [matDatepicker]="picker1" formControlName="startdate" (dateChange)="getTransfers()" />
							<mat-datepicker-toggle matSuffix [for]="picker1">
							</mat-datepicker-toggle>
							<mat-datepicker #picker1></mat-datepicker>
						</mat-form-field>
					</div>
					<div class="col-md-4">
						<mat-form-field appearance="fill">
							<mat-label>End Date</mat-label>
							<input matInput [matDatepicker]="picker2" formControlName="enddate" (dateChange)="getTransfers()" />
							<mat-datepicker-toggle matSuffix [for]="picker2">
							</mat-datepicker-toggle>
							<mat-datepicker #picker2></mat-datepicker>
						</mat-form-field>
					</div>
					<div class="col-md-4">
						<mat-form-field appearance="fill">
							<mat-label>To Location</mat-label>
							<mat-select formControlName="toloc" (selectionChange)="getTransfers()">
								<mat-option *ngIf="locations.length > 1" value="">
									---- Any ----
								</mat-option>
								<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
									{{loc.locationname }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
			</form>
			<div class="table ov">
				<div class="text-right"> Results ({{transferlist.length}})</div>
				<table class="table table-sm">
					<tr>
						<th>Document #</th>
						<th>Input Date</th>
						<th>From Location</th>
						<th>To Location</th>
						<th>Notes</th>
						<th>Status</th>
						<th>Total</th>
					</tr>
					<tr *ngFor="let t of transferlist">
						<td><button [disabled]="t.tostkloc != user.user.defaultlocation.loccode" class="btn btn-sm button-primary" (click)="getTransferData(t.transno);">{{ t.transno }}</button></td>
						<td>{{ t.inputdate | date }}</td>
						<td>{{ t.fromstkloc }}</td>
						<td>{{ t.tostkloc }}</td>
						<td>{{ t.notes }}</td>
						<td>
							<div [ngClass]="statuses[t.status].code == 0 ? 'entered' : statuses[t.status].code == 1 ? 'released' : statuses[t.status].code == 2 ? 'received' : statuses[t.status].code == 3 ? 'cancelled' : ''">{{ statuses[t.status].description }}</div>
						</td>
						<td>{{t.ovasaletotal | currency}}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</div>
