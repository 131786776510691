<div class="main-content">
	<div class="container-fluid">
		<div class="card">
			<div class="card-header card-header-{{color}}-1">
				<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Open RMAs</h4>
				<p class="card-category">Open RMAs</p>
			</div>
			<div class="card-body">
				<app-open-credits [customerdata]="customerdata" ></app-open-credits>
			</div>
		</div>
	</div>
</div>
