import { Component, ViewChild, ChangeDetectorRef, OnInit, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { Observable, forkJoin } from 'rxjs';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { ReportsService } from '../../services/reports.service';
import { PaymentsService } from '../../services/payments.service';
import { OrdersService } from '../../services/orders.service';
import { PrintService } from '../../services/print.service';
import * as FileSaver from 'file-saver';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-openorders-and-invoices',
	templateUrl: './openorders-and-invoices.component.html',
	styleUrls: ['./openorders-and-invoices.component.scss']
})
export class OpenordersAndInvoicesComponent implements OnInit {
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatPaginator, { static: true }) orderpaginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild('invoiceDetails') invoiceDetailsRef: ElementRef;
	@ViewChild('orderDetails') orderDetailsRef: ElementRef;

	dataSource = new MatTableDataSource < any > ();
	dataSourceOrders = new MatTableDataSource < any > ();

	displayedColumns: string[] = ['actions', 'orderno', 'trandate', 'typename', 'customer', 'ovamount', 'ovgst', 'ovfreight', 'total', 'printedpackingslip'];

	dataObs: Observable < any > ;
	dataObsOrders: Observable < any > ;
	invoices: any[] = [];
	openorders: any[] = [];
	items_per_page = [10, 25, 50, 100, 500];
	pagesizedefault = 100;
	running = false;
	runningOrders = false;
	today = new Date();
	payments: any = [];
	transactions: any = [];
	mergedResults: any = [];
	searchInput = new FormControl('');
	searchInputOrders = new FormControl('');
	showOrderDetails = false;
	showInvoiceDetails = false;
	showDatePicker = false;
	total_pay = '';
	total_trans = 0;
	total_sub = 0;
	total_tax = 0;
	total_freight = 0;
	total_fee = 0;
	total_discount = 0;

	statusSearch = new UntypedFormControl('');
	datefrom = new FormControl(this.today);
	dateto = new FormControl(this.today);
	datefromOrders = new FormControl(this.today);
	datetoOrders = new FormControl(this.today);
	create_credit = new FormControl(true);
	defaultlocation = new FormControl('');
	salesperson = new FormControl('');
	order_statuses: any = [];
	user: any;
	salesman: any;
	orderdetails: any = false;
	displaydetails: any = false;
	sortDirection = 'asc';
	sortByColumn = '';
	config: any = false;
	invoicelink = '';
	constructor(
		private ordersService: OrdersService,
		private cdr: ChangeDetectorRef,
		private location: Location,
		private reportsService: ReportsService,
		private printService: PrintService,
		private globalSearchService: GlobalSearchService,
		private globalsService: GlobalsService,
		private paymentsService: PaymentsService,
		private fb: FormBuilder,
		private modalService: NgbModal
	) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;

			this.ordersService.getOrderStatuses().subscribe(r => {
				this.order_statuses = r;
				// Filter out the order status with ID 51
				if (!this.config.liveinventory) {
					const filteredStatuses = this.order_statuses
						.filter(status => status.order_status_id !== '51')
						.map(status => status.order_status_id);
					this.statusSearch.setValue(filteredStatuses);
				}
			});
		});
	}

	ngOnInit() {
		this.invoicelink = this.config.apiServer.baseUrl + this.config.apiServer.pickingInvoice;
		this.initDateControls();
		this.globalSearchService.userData.subscribe(r => {
			this.user = r;
			this.salesman = this.user.user.issalesman ? this.user.user.salesman : null;
			this.loadAllData();
		});

		this.searchInput.valueChanges.subscribe(() => this.search());
		this.datefrom.valueChanges.subscribe(() => this.loadAllData());
		this.dateto.valueChanges.subscribe(() => this.loadAllData());
	}

	initDateControls() {
		const sevenDaysAgo = new Date();
		sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 0);

		this.datefrom.setValue(sevenDaysAgo);
		this.dateto.setValue(this.today);
		this.datefromOrders.setValue(sevenDaysAgo);
		this.datetoOrders.setValue(this.today);
	}

	loadAllData() {
		const requestParams = {
			from: this.datefrom.value,
			to: this.dateto.value,
			locations: this.defaultlocation.value,
			salesperson: this.salesman
		};

		this.running = this.runningOrders = true;

		forkJoin({
			invoices: this.paymentsService.getDailyTransactions(requestParams),
			orders: this.ordersService.getItemOrderSearch(requestParams)
		}).subscribe(({ invoices, orders }) => {
			this.running = this.runningOrders = false;
			this.processInvoicesData(invoices);
			this.processOrdersData(orders);



			this.mergedResults = [...this.transactions, ...this.openorders];



			this.updateTotals(this.mergedResults);
			this.setPagination(this.mergedResults);
		});
	}

	processInvoicesData(invoices: any) {
		this.payments = invoices;

		// Ensure each transaction has the printedpackingslip flag
		this.transactions = invoices.transactions.map((transaction: any) => {
			if (!transaction.hasOwnProperty('printedpackingslip')) {
				transaction.printedpackingslip = '1'; // Add printedpackingslip with value '1' if it doesn't exist
			}
			return transaction;
		});

		this.updateTotals(this.transactions);
	}

	processOrdersData(orders: any) {

		const selectedStatuses = this.statusSearch.value; // This is now an array of selected statuses

		if (selectedStatuses.length > 0 && !selectedStatuses.includes('')) {
			const searchresults = orders.filter(order => {
				return selectedStatuses.includes(order.orderstatusid)
			});

			orders = searchresults;
		}

		this.openorders = orders;
	}

	updateTotals(transactions: any[]) {
		this.total_trans = transactions.reduce((acc, item) => acc + parseFloat(item.total), 0);
		this.total_sub = transactions.reduce((acc, item) => acc + parseFloat(item.ovamount), 0);
		this.total_tax = transactions.reduce((acc, item) => acc + parseFloat(item.ovgst), 0);
		this.total_freight = transactions.reduce((acc, item) => acc + parseFloat(item.ovfreight), 0);
		this.total_fee = transactions.reduce((acc, item) => acc + parseFloat(item.ovfee), 0);
		this.total_discount = transactions.reduce((acc, item) => acc + parseFloat(item.ovdiscount), 0);
		this.total_pay = transactions.reduce((acc, item) => acc + parseFloat(item.amount), 0);
	}

	search() {
		const value = this.searchInput.value?.trim();
		const filteredData = value ? this.globalSearchService.filterItem(this.mergedResults, value, 'orderno,deliverto,transno,name,total,debtorno,branchcode') : this.mergedResults;
		this.setPagination(filteredData);
		this.updateTotals(filteredData);
	}

	setPagination(data: any[]) {

		this.dataSource = new MatTableDataSource < any > (data);
		this.cdr.detectChanges();
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
		this.dataObs = this.dataSource.connect();

	}

	viewOrder(orderno: any) {
		this.showOrderDetails = true;
		this.ordersService.getOrder(orderno).subscribe(order => {
			this.orderdetails = order;
			this.showModal(this.orderDetailsRef, 'orderDetails', order);
		});
	}

	openDocument(transaction: any, display: string) {
		this.showInvoiceDetails = true;
		const fetchDetails = transaction.type === '11' ? this.globalsService.getCreditNote(transaction.id, display) : this.globalsService.getInvoice(transaction.id, display);
		fetchDetails.subscribe(result => {
			this.showModal(this.invoiceDetailsRef, 'invoiceDetails', result);
		});
	}

	showModal(ref: ElementRef, modalType: string, data: any) {
		if (modalType === 'orderDetails') {
			this.orderdetails = data;
		} else if (modalType === 'invoiceDetails') {
			this.displaydetails = data;
		}
		this.modalService.open(ref, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then(() => {
			this.resetModalState(modalType);
		}, () => {
			this.resetModalState(modalType);
		});
	}

	resetModalState(modalType: string) {
		if (modalType === 'orderDetails') {
			this.showOrderDetails = false;
			this.orderdetails = null;
		} else if (modalType === 'invoiceDetails') {
			this.showInvoiceDetails = false;
			this.displaydetails = null;
		}
	}

	toggleDatePicker(): void {
		this.showDatePicker = !this.showDatePicker;
	}

	isInvalidDateRange(): boolean {
		// Check if either datefrom or dateto is empty (null or undefined)
		return !this.datefrom.value || !this.dateto.value;
	}

	calculateDaysBetween(startDate: Date | null, endDate: Date | null): number {
		if (!startDate || !endDate) {
			return 0;
		}
		const millisecondsPerDay = 1000 * 60 * 60 * 24;
		return Math.round((endDate.getTime() - startDate.getTime()) / millisecondsPerDay);
	}
}