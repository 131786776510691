
<ng-template #binSelect let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title" *ngIf="transferData?.transferheader">Qty To Release #{{  transferData.transferheader.transno }} </h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<i class="fa fa-close"></i>
		</button>
	</div>
	<div class="modal-body text-dark">
		<!-- From and To Address Cards -->
		<div class="row mb-3">
			<div class="col-md-6">
				<div class="card m-0">
					<div class="card-header">
						From:
					</div>
					<div class="card-body pre-line">
						{{ fromaddress }}
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="card m-0">
					<div class="card-header">
						To:
					</div>
					<div class="card-body pre-line">
						{{ toaddress }}
					</div>
				</div>
			</div>
		</div>

		<!-- Total Transfer Value and Total Items Shipped -->
		<div class="row mb-3">
			<div class="col-md-6" *ngIf="transferData?.transferheader?.total_transfer_value">
				<div><strong>Total Transfer Value:</strong> {{ transferData.transferheader.total_transfer_value | currency }}</div>
			</div>
			<div class="col-md-6" *ngIf="transferData?.transferheader?.total_items_shipped">
				<div><strong>Total Items Shipped:</strong> {{ transferData.transferheader.total_items_shipped }}</div>
			</div>
		</div>

		<!-- Table Headers -->
		<div class="row table-header align-items-center mb-2">
			<div class="col-md-2">
				<strong>Item Code</strong>
			</div>
			<div class="col-md-3">
				<strong>Description</strong>
			</div>
			<div class="col-md-1">
				<strong>Bin</strong>
			</div>
			<div class="col-md-1">
				<strong>Req'd Qty</strong>
			</div>
			<div class="col-md-1">
				<strong>Bin Qty</strong>
			</div>
			<div class="col-md-2 text-center">
				<strong>Qty to Release</strong>
				<mat-checkbox (change)="toggleAll($event)" class="text-white"></mat-checkbox>
			</div>
			<div class="col-md-1 text-center">
				<strong>Status</strong>
			</div>
		</div>

		<!-- Item Rows -->
		<div class="row item-row mb-2" *ngFor="let bin of items; let i = index">
			<div class="col-md-2">
				{{bin.stockid}}
			</div>
			<div class="col-md-3 text-left">
				{{bin.description}}
			</div>
			<div class="col-md-1">
				{{bin.bincode}}
			</div>
			<div class="col-md-1">
				{{bin.transferqty}}
			</div>
			<div class="col-md-1">
				{{bin.from_bin_qoh}}
			</div>
			<div class="col-md-2">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Qty</mat-label>
					<input matInput tabindex="{{ i + 2 }}" type="number" [value]="bin.quantity" (input)="updateBinQuantity($event, bin)" class="text-right">
				</mat-form-field>
			</div>
			<div class="col-md-1 text-center status-indicator" [ngClass]="{
				'warn': parseF(bin.transferqty) > parseF(bin.quantity),
				'good': parseF(bin.transferqty) == parseF(bin.quantity),
				'bad': parseF(bin.transferqty) < parseF(bin.quantity)
			}">
				<mat-icon>{{ parseF(bin.transferqty) === parseF(bin.quantity) ? 'check_box' : 'error' }}</mat-icon>
			</div>
		</div>
	</div>


	<div class="row">
		<div class="col-md-10"></div>
		<div class="col-md-2">
			<button class="btn btn-success" (click)="releaseItems(items)">Release Items</button>
		</div>
	</div>
</ng-template>


<div class="container-fluid">

	<div class="row">

		<div class="container-fluid">
			<form [formGroup]="searchForm">
				<div class="row">
					<div class="col-md-4">
						<mat-form-field appearance="fill">
							<mat-label>Start Date</mat-label>
							<input matInput [matDatepicker]="picker1" formControlName="startdate" (dateChange)="getTransfers()" />
							<mat-datepicker-toggle matSuffix [for]="picker1">
							</mat-datepicker-toggle>
							<mat-datepicker #picker1></mat-datepicker>
						</mat-form-field>
					</div>
					<div class="col-md-4">
						<mat-form-field appearance="fill">
							<mat-label>End Date</mat-label>
							<input matInput [matDatepicker]="picker2" formControlName="enddate" (dateChange)="getTransfers()" />
							<mat-datepicker-toggle matSuffix [for]="picker2">
							</mat-datepicker-toggle>
							<mat-datepicker #picker2></mat-datepicker>
						</mat-form-field>
					</div>
					<div class="col-md-4">
						<mat-form-field appearance="fill">
							<mat-label>From Location</mat-label>
							<mat-select formControlName="fromloc" (selectionChange)="getTransfers()">
								<mat-option *ngIf="locations.length > 1" value="">
									---- Any ----
								</mat-option>
								<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
									{{loc.locationname }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
			</form>
			<div class="table ov">
				<div class="text-right"> Results ({{transferlist.length}})</div>
				<!-- Add a spinner that is displayed when loading -->
				<div *ngIf="isLoading" class="text-center">
					<mat-spinner></mat-spinner>
				</div>

				<!-- Table for transfers -->
				<table class="table table-sm" *ngIf="!isLoading">
					<thead>
						<tr>
							<th>Document #</th>
							<th>Input Date</th>
							<th>From Location</th>
							<th>To Location</th>
							<th>Notes</th>
							<th>Status</th>
							<th>Total</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let t of transferlist">
							<td>
								<button
									[disabled]="t.fromstkloc !== user.user.defaultlocation.loccode"
									class="btn btn-sm button-primary"
									(click)="getTransferData(t.transno)">
									{{ t.transno }}
								</button>
							</td>
							<td>{{ t.inputdate | date }}</td>
							<td>{{ t.fromstkloc }}</td>
							<td>{{ t.tostkloc }}</td>
							<td>{{ t.notes }}</td>
							<td>
								<div [ngClass]="getStatusClass(t.status)">
									{{ getStatusDescription(t.status) }}
								</div>
							</td>
							<td>{{ t.ovasaletotal | currency }}</td>
						</tr>
					</tbody>
				</table>


			</div>
		</div>
	</div>
</div>
