import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, Injectable, ElementRef, OnChanges } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { DataService } from '../../../data.service';
import { ChatService } from '../../../services/chat.service';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { TableModule } from 'primeng/table';

import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { GlobalsService } from '../../../services/globals.service';
import { InventoryService } from '../../../services/inventory.service';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
	selector: 'app-transfers-edit',
	templateUrl: './transfers-edit.component.html',
	styleUrls: ['./transfers-edit.component.scss']
})
export class TransfersEditComponent implements OnInit {


	@Output() transferComplete = new EventEmitter<any>();
	@Input() transno: any = '';
	transfer_total: number;
	transfer_qty: number;
	searching = false;
	ngOnChanges(changes: any) {

		this.loadTransfer(changes.transno.currentValue.editingtransfer);

	}

	@ViewChild('binSelect') binSelectRef: ElementRef;
	@Output() resetTransfer = new EventEmitter<any>();

	locations: any = [];
	tranlocations: any = [];
	items: any = [];
	needbin: any = [];
	fromaddress: any = '';
	toaddress: any = '';
	transferForm: UntypedFormGroup;
	transferData: any = false;
	searchVal: any = '';
	addlist: any = [];
	dellist: any = [];
	changelist: any = [];
	tabreset: any = 1;

	dataSource: MatTableDataSource<any>;
	@ViewChild('print_items') print_itemsRef: ElementRef;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataObs: Observable<any>;
	items_per_page = [25, 50, 100];
	constructor(private _changeDetectorRef: ChangeDetectorRef, private fb: UntypedFormBuilder, private globalSearchService: GlobalSearchService, private globalsService: GlobalsService, private inventoryService: InventoryService, private modalService: NgbModal) { }


	ngOnInit(): void {
		this.inventoryService.getTransferLocations().subscribe((results: any) => {
  		if (results) {
  			this.tranlocations = results;
  		}
  	});
	this.globalsService.getUserLocations().subscribe((results: any) => {
  		if (results) {
  			this.locations = results;
  		}
  	});
		this.transferForm = this.fb.group({
			'searchVal': '', 'transno': '', 'fromstkloc': '00', 'tostkloc': '00', 'inputdate': '2023-12-31', 'notes': '', 'status': 0,
			'lines': []
		});
		this.setPagination([]);
	}

	loadTransfer(transno) {

		this.inventoryService.getTransferData({ 'transno': transno }).subscribe((results: any) => {
			if (results) {

				this.transferData = {
					'header': { 'transno': results.transferheader.transno, 'fromstkloc': results.transferheader.fromstkloc, 'tostkloc': results.transferheader.tostkloc, 'inputdate': results.transferheader.inputdate, 'notes': results.transferheader.notes, 'status': results.transferheader.status },
					'lines': []
				};

				results.lines.forEach((line: any, index) => {

					this.transferData.lines.push({
						'transferlineno': line.transferlineno, 'stockid': line.stockid, description: line.description, 'bincode': line.bincode, 'transferqty': line.transferqty, 'releasedqty': line.releasedqty, 'receivedqty': line.receivedqty,
						'shipdate': line.shipdate, 'recdate': line.recdate, 'narrative': line.narrative, 'completed': line.completed, 'price': line.price
					});
				});
				this.toaddress = results.transferheader.locationname + '\n' + results.transferheader.deladd1 + '\n' + results.transferheader.deladd3 + ' ' + results.transferheader.deladd4 + ', ' + results.transferheader.deladd5;
				this.fromaddress = results.transferheader.shipname + '\n' + results.transferheader.shipadd1 + '\n' + results.transferheader.shipcity + ' ' + results.transferheader.shipstate + ', ' + results.transferheader.shipzip;
				this.transferForm.controls['transno'].setValue(transno);
				this.transferForm.controls['fromstkloc'].setValue(results['transferheader'].fromstkloc);
				this.transferForm.controls['tostkloc'].setValue(results['transferheader'].tostkloc);
				this.transferForm.get('notes').setValue(this.transferData.header.notes);
				this.priceTransfer();
			}
		});


	}

	updateHeader() {
		this.transferData.header.fromstkloc = this.transferForm.get('fromstkloc').value;
		this.transferData.header.tostkloc = this.transferForm.get('tostkloc').value;

		this.inventoryService.getLocationAddress({ 'loccode': this.transferData.header.fromstkloc }).subscribe((results: any) => {
			if (results) {
				this.fromaddress = results.locationname + '\n' + results.deladd1 + '\n' + results.deladd3 + ' ' + results.deladd4 + ', ' + results.deladd5;
			}
		});
		this.inventoryService.getLocationAddress({ 'loccode': this.transferData.header.tostkloc }).subscribe((results: any) => {
			if (results) {
				this.toaddress = results.locationname + '\n' + results.deladd1 + '\n' + results.deladd3 + ' ' + results.deladd4 + ', ' + results.deladd5;
			}
		});
	}
	decrement(item: any) {
		const index = this.items.indexOf(item);
		if (this.items[index].quantity > 0) {
			this.items[index].quantity -= 1;
		}
	}

	increment(item: any) {
		const index = this.items.indexOf(item);
		this.items[index].quantity += 1;
	}
	updateSearchQuantity(event: any, item: any) {
		const index = this.items.indexOf(item);
		this.items[index].quantity = event.target.value;
	}
	updateBinQuantity(event: any, item: any) {
		const index = this.needbin.indexOf(item);
		this.needbin[index].quantity = event.target.value;
	}
	updateLineQty(event: any, item: any) {
		const index = this.transferData.lines.indexOf(item);
		item.transferqty = event.target.value;
		this.changelist.push(item);
		this.transferData.lines[index].transferqty = event.target.value;
		this.priceTransfer();
	}

	addItems(event) {
		this.needbin = [];
		event.forEach((line: any, index) => {

			if (line.bins.length > 1) {
				line.bins.forEach((bin: any, index2) => {
					this.needbin.push({ 'stockid': line.stockid, 'description': line.description, 'needquantity': line.quantity, 'quantity': 0, 'bin': bin.bin, 'binmax': bin.maxqty, 'price': line.price });
				});
			} else {
				if (line.quantity > 0) {
					this.transferData.lines.push({
						'transferlineno': this.transferData.lines.length, 'stockid': line.stockid, description: line.description, 'bincode': line.bins[0].bin, 'transferqty': line.quantity, 'releasedqty': 0, 'receivedqty': 0,
						'shipdate': '0000-00-00', 'recdate': '0000-00-00', 'narrative': '', 'completed': 0, 'price': line.price
					});
					this.addlist.push({
						'transferlineno': this.transferData.lines.length, 'stockid': line.stockid, description: line.description, 'bincode': line.bins[0].bin, 'transferqty': line.quantity, 'releasedqty': 0, 'receivedqty': 0,
						'shipdate': '0000-00-00', 'recdate': '0000-00-00', 'narrative': '', 'completed': 0, 'price': line.price
					});
					this.globalSearchService.showNotification('Added ' + line.quantity + 'x ' + line.stockid, 'success', 'bottom', 'right');

				}
			}
		});
		if (this.needbin.length > 0) {
			this.modalService.open(this.binSelectRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

			}, (reason) => {

			});
		}
		this.priceTransfer();
		this.items.filter((i) => {
			i.quantity = 0;
		})
	}
	addBins(event) {
		event.forEach((line: any, index) => {
			if (line.quantity > 0) {
				this.transferData.lines.push({
					'transferlineno': this.transferData.lines.length, 'stockid': line.stockid, 'description': line.description, 'bincode': line.bin, 'transferqty': line.quantity, 'releasedqty': 0, 'receivedqty': 0,
					'shipdate': '0000-00-00', 'recdate': '0000-00-00', 'narrative': '', 'completed': 0
				});
				this.addlist.push({
					'transferlineno': this.transferData.lines.length, 'stockid': line.stockid, 'description': line.description, 'bincode': line.bin, 'transferqty': line.quantity, 'releasedqty': 0, 'receivedqty': 0,
					'shipdate': '0000-00-00', 'recdate': '0000-00-00', 'narrative': '', 'completed': 0
				});
				this.globalSearchService.showNotification('Added ' + line.quantity + 'x ' + line.stockid, 'success', 'bottom', 'right');

			}
		});
		this.priceTransfer();
		this.needbin = [];
		this.modalService.dismissAll();
	}

	removeItem(item: any) {
		const index = this.transferData.lines.indexOf(item);
		this.dellist.push(item);
		this.transferData.lines.splice(index, 1);

		this.globalSearchService.showNotification('Removed ' + item.transferqty + 'x ' + item.stockid, 'warning', 'bottom', 'right');
		this.priceTransfer();
	}

	itemSearch() {
		this.searching = true;
		const data = { 'keywords': this.transferForm.get('searchVal').value, 'fromstkloc': this.transferData.header.fromstkloc, 'tostkloc': this.transferData.header.tostkloc };
		this.inventoryService.getTransferItems(data).subscribe((results: any) => {
			if (results.length) {
				this.items_per_page = [25, 50, 100, results.length];
				this.setPagination(results);
				this.items = results;
			}
			this.searching = false;
		});
	}

	submitTransfer() {
		if (this.transferData.header.fromstkloc == this.transferData.header.tostkloc) {
			this.globalSearchService.showNotification('Must Transfer to Different Location', 'warning', 'bottom', 'left');
		} else if (this.transferData.lines.length == 0) {
			this.globalSearchService.showNotification('Must Transfer at least One Item', 'warning', 'bottom', 'left');
		} else {

			const data = { 'fromstkloc': this.transferData.header.fromstkloc, 'tostkloc': this.transferData.header.tostkloc, 'notes': this.transferForm.get('notes').value, 'ovasaletotal': this.transfer_total };
			const header = this.transferData.header;
			header['ovasaletotal'] = this.priceTransfer();

			this.inventoryService.getTransferData({ transno: this.transno.editingtransfer }).subscribe((res) => {
				if (res['status'] == 1) {
					alert("This transfer has been released by another user. Changes will be reverted.");
					this.transferComplete.emit('Conflict');
				} else {
					this.addlist.forEach((line: any, index) => {
						line.transno = this.transferData.header.transno;
						this.inventoryService.addTransferLine(line).subscribe((results: any) => { });
					});

					this.dellist.forEach((line: any, index) => {
						line.transno = this.transferData.header.transno;
						this.inventoryService.removeTransferLine(line).subscribe((results: any) => { });
					});

					this.changelist.forEach((line: any, index) => {
						line.transno = this.transferData.header.transno;
						this.inventoryService.updateTransferLine(line).subscribe((results: any) => { });
					});

					this.inventoryService.updateTransferHeader(header).subscribe((results: any) => {

					})

					this.transferComplete.emit(this.transferData.header.transno);

					this.transferForm = this.fb.group({
						'searchVal': '', 'transno': '', 'fromstkloc': '00', 'tostkloc': '00', 'inputdate': '2023-12-31', 'notes': '', 'status': 0,
						'lines': []
					});
					this.transferData = {
						'header': { 'transno': '', 'fromstkloc': '00', 'tostkloc': '00', 'inputdate': '2023-12-31', 'notes': '', 'status': 0 },
						'lines': []
					};
					this.items = [];
					this.priceTransfer();
					this.tabreset = 0;
				}



			});
		}
	}
	cancel() {
		this.transferComplete.emit('');
	}

	setPagination(tableData) {
		this.dataSource = new MatTableDataSource<any>(tableData);
		this._changeDetectorRef.detectChanges();
		this.dataSource.paginator = this.paginator;
		this.dataObs = this.dataSource.connect();
	}

	priceTransfer() {
		this.transfer_total = 0;
		this.transfer_qty = 0;
		this.transferData.lines.map((i) => {
			this.transfer_total += (i.price * i.transferqty);
			this.transfer_qty += Number(i.transferqty);
		})
		return this.transfer_total;
	}
}
