<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="iframe-container embed-responsive h-90">
						<iframe allowfullscreen class="embed-responsive-item" [src]="urlSafe"></iframe>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
