import { Directive, ElementRef } from '@angular/core';

@Directive({
	selector: '[appFocusMe]'
})

export class FocusMeDirective {
	constructor(private host: ElementRef) {}

	ngAfterViewInit() {
	//this.host.nativeElement.style.backgroundColor = 'yellow';
		this.host.nativeElement.focus();
	}
}
