import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { distinctUntilChanged , switchMap, tap, take } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import * as FileSaver from 'file-saver';

import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate, animation } from '@angular/animations';
import { CustomerService } from '../../services/customer.service';
import { CreditService } from '../../services/credit.service';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { GeneralLedgerService  } from '../../services/general-ledger.service';
import { OmsService } from '../../services/oms.service';

@Component({
	selector: 'app-credit-freestanding',
	templateUrl: './credit-freestanding.component.html',
	styleUrls: ['./credit-freestanding.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(-180deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('500ms ease-in')),
			transition('active => inactive', animate('500ms ease-out')),
		]),
		trigger('cinfo', [
			transition(':enter', [
				animate('50ms', style({ opacity: 1, height: 100 })),
			]),
			transition(':leave', [
				animate('100ms', style({ opacity: 1, height: 0 }))
			]),
		]),
		trigger('itemSearch', [
			transition(':enter', animation([style({ transform: 'translate(-800px,0)', }),
				animate('0.2s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(1200px,0)', })
				),
			])),
		]),
		trigger('addFilters', [
			transition(':enter', animation([style({ transform: 'translate(200px,0)', }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(-200px,0)', })
				),
			])),
		]),
	],
})
export class CreditFreestandingComponent implements OnInit {

	@ViewChild('itemdetails') itemDetails: ElementRef;
	@ViewChild('purchasedetails') purchasedetails: ElementRef;
	@ViewChild('creditover') creditover: ElementRef;
	@ViewChild('configproductele') configproductele: ElementRef;
	@ViewChild('itemHistoryRef') itemHistoryRef: ElementRef;
	@ViewChild('nonstock') nonstockref: ElementRef;

	CHAT_ROOM = 'OrderBoard';
	orderResults: any = [];
	customer_data: any = false;
	debtorno: any = '';
	branchcode: any = '';
	today = new Date();
	//cart
	dspcost = true;
	showGuide = false;
	total_cart_qty = 0;
	cart_items: any = [];
	cart_totals: any = [];
	selectedindex = 0;
	activeLink: any = 'order_entry';
	original_order: any = false;
	//subscription to search
	inventorysearching: any = false;

	search_items: any = [];
	filtered_items: any = [];
	searching = false;

	taxgroups: any = [];
	salespeople: any = [];
	shipvia: any = [];
	terms: any = [];
	zones: any = [];
	locations: any = [];
	holdreasons: any = [];
	itemhistory: any = [];



	addonfields: [
		'datewanted',
		'enteredby',
		'takenby',
		'freightcost',
	];

	addingitems = false;
	customerinfo = true;
	//viewing item
	item_details: any = false;
	editing_order = false;
	credit_invoice = false;
	order_details: any = [];
	purchase_items: any = [];
	user: any = [];

	showfilters = false;
	overcredit = false;
	allowed_credit = true;
	proceed = true;
	overcredit_override = '';
	selecteditem: any = false;
	config: any = [];


	color = 'blue'
	reloadedOrder: any;


	constructor(private generalLedgerService: GeneralLedgerService, private ordersService:OrdersService,private inventoryService: InventoryService, private route: ActivatedRoute, private customerService: CustomerService, private creditService: CreditService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, public omsService: OmsService, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal) {


		this.config = this.globalsService.getApiConfig();
		this.color = this.globalSearchService.getColor();


		this.globalSearchService.user.subscribe((results: any) => {
			if (results) {
				this.user = results.user;
			}
		});

	}
	fetching: any = false;
	fetchingorder: any = false;

	ngOnInit(): void {
	  this.globalSearchService.mesagesallowed.next(false);

	  this.route.params
		.pipe(distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)))
		.subscribe(params => {
		  if (params['debtor'] && params['debtor'] !== '') {
			this.debtorno = params['debtor'];
			this.branchcode = params['branch'];
		  }

		  if (params['id'] && params['id'] !== '') {
			if (this.fetching) {
			  this.fetching.unsubscribe();
			}

			this.fetching = this.creditService.getTransactionById(params['id'])
			  .subscribe((order: any) => {
				this.debtorno = order.debtorno;
				this.branchcode = order.branchcode;
				this.credit_invoice = params['id'];

				this.loadCustomer();

				this.editing_order = false;
				this.original_order = false;

				const payload = {
				  orderno: order.order_,
				  transno: order.transno
				};

				if (this.fetchingorder) {
				  this.fetchingorder.unsubscribe();
				}

				this.fetchingorder = this.creditService.loadCreditFromSalesOrderWTransno(payload)
				  .subscribe((results: any) => {
					this.original_order = results;

					if (results.header.options) {
					  this.customer_data.options = results.header.options;
					}

					this.order_details = results;

					if (results.warn) {
					  this.reloadedOrder = order.order_;
					  this.globalSearchService.showNotification('Items already credited not added.', 'danger', 'bottom', 'right');
					}
				  });
			  });
		  } else {
			this.loadCustomer();
		  }
		});
	}


	selectCustomer(data: any) {
		this.debtorno = data.debtorno
		this.branchcode = data.branchcode;

		this.loadCustomer();
	}


	loadCustomer() {
	  this.original_order = false;

	  // Fetch cart items and then customer data, without nested subscriptions
	  this.creditService.getCart(this.debtorno, this.branchcode).pipe(
		take(1), // Take the first emission from getCart and complete
		tap((items: any) => {
		  this.cart_items = items || []; // Assign cart items, default to empty array if null/undefined
		}),
		// Switch to getCustomer once cart items are fetched
		switchMap(() => this.creditService.getCustomer(this.debtorno, this.branchcode).pipe(take(1))),
		tap((results: any) => {
		  // Check for the original order and prevent redundant customer data loading
		  let addon = false;
		  if (this.customer_data?.original_order && this.customer_data.debtorno === this.debtorno) {
			addon = this.customer_data.original_order;
		  }

		  // Update customer data and preserve the original order if applicable
		  this.customer_data = results;
		  if (addon) {
			this.customer_data.original_order = addon;
			this.original_order = addon;
		  }
		})
	  ).subscribe(); // No need for nested subscriptions
	}


	updateFilteredItems(event: any) {
		this.searching = true;
		this.creditService.getItemFilter(event).subscribe((items: any) => {
			//this.search_items = items
			this.search_items = items
			this.filtered_items = items
			this.searching = false;
		});
	}

	viewOptions(item: any) {
		this.selecteditem = item;
		if (!this.selecteditem.product_type) {
			this.selecteditem.product_type = {}
			this.selecteditem.product_type.requiresconfig = false;
		}

		this.modalService.open(this.configproductele, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {
			this.selecteditem = false;
		});
	}


	toggleFilters() {
		this.showfilters = (this.showfilters) ? false : true;
	}

	reloadOrder(event: any) {
		this.creditService.getTransactionById(event).subscribe( (order: any) => {
			this.debtorno = order.debtorno;
			this.branchcode = order.branchcode;
			this.credit_invoice = event
			this.editing_order = false;
			this.original_order = false;

			const payload = {
				orderno: order.order_,
				transno: order.transno
			}

			if(this.fetchingorder) {
				this.fetchingorder.unsubscribe();
			}
			this.fetchingorder = this.creditService.loadCreditFromSalesOrderWTransno(payload).subscribe((results: any) => {
				//this.customer_data.reference = results.header.customerref
				//11.7 taking out causes error for reloading po
				//this.customer_data.original_order = results;

				this.original_order = results;
				if(results.header.options) {
					this.customer_data.options = results.header.options;
				}

				this.order_details = results;

				if(results.warn) {
					this.reloadedOrder = order.order_;
					this.globalSearchService.showNotification('Items already credited not added.', 'danger','bottom','right');
				}
			});
		});

		this.selectedindex = 0;
	}


	toggleCustomerInfo() {
		this.customerinfo = (this.customerinfo) ? false : true;
	}

	toggleAddItems() {
		this.addingitems = (this.addingitems) ? false : true;
		this.customerinfo = (this.addingitems) ? false : true;
	}

	back(): void {
		this.location.back()
	}

	randomString(length: any, chars: any) {
		let result = '';
		for (let i = length; i > 0; --i) {result += chars[Math.floor(Math.random() * chars.length)];}
		return result;
	}

	openModal(content, item) {

		this.item_details = item;

		this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {

		}, (reason) => {
			this.item_details = false;
		});
	}

	invoice() {
		this.router.navigate(['/orders/invoice/' + this.orderResults.orderno]);
	}

	ngAfterViewInit() {


	}

	updateCustomer(event: any){
		this.cdr.detectChanges();
	}


	// CART FUNCTIONS
	stopProp(event: any): void {
		event.stopPropagation();
	}


	sendCartUpdate() {

		if (this.user) {

			const data = [{ customer: this.debtorno, user: this.user }]

			this.omsService.sendCartUpdate({ data, roomName: 'CartRoom' }, cb => {});
		}
	}

	customerChanged(customer: any) {
		this.customerinfo = true;
		this.selectedindex = 0;
		this.router.navigate(['/receivables/credit-note/' + customer.debtorno + '/' + customer.branchcode]);
	}

	toggleGuide() {
		this.showGuide = (this.showGuide) ? false : true;
	}

}
