<div class="row m-0 p-0"  @grow >
	<div class="col-md-3 mt-2">
		<form [formGroup]="barcodeForm" *ngIf="barcodeForm">
			<div class="card mt-2">
				<h4 class="card-title mt-3 ml-4 mb-0"> Add Barcode</h4>
				<div class="card-body">
					<div class="row">
						<div class="col-md-12">
							<mat-form-field appearance="outline">
								<mat-label>Barcode</mat-label>
								<input type="text" formControlName="barcode" matInput placeholder="Barcode" />
							</mat-form-field>
						</div>

						<div class="col-md-12">
							<mat-form-field appearance="outline">
								<mat-label>Case Size</mat-label>
								<input type="text" formControlName="casesize" matInput placeholder="Case Size" />
							</mat-form-field>
						</div>
						<div class="col-md-12 text-left">
							<button mat-stroked-button class="mt-3" (click)="addBarcode()">
								<mat-icon>save</mat-icon>
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
	<div class="col-md-9 mt-2">
		<ng-container *ngIf="barcodes">
			<h4 class="mt-3"> Current Barcodes</h4>
			<div class="table">
				<table class="table table-fluid">
					<tr>
						<th class="">Barcode</th>
						<th *ngIf="config.env.package !='tires'" >Case Size</th>
						<th  class=""></th>
					</tr>
					<tr *ngFor="let p of barcodes">
						<td class="text-left">
							<span class="mt-3" *ngIf="p.isprimary == '1'">
								Primary*
							</span>
							<input class="form-control border-light " #barcode value="{{ p.code }}" (input)="p.savevalue=$event.target.value;">
						</td>
						<td class="text-center"><input class="form-control border-light text-center" value="{{ p.casesize }}" (input)="p.savevalue=barcode.value;p.casesize=$event.target.value;" *ngIf="config.env.package !='tires'"></td>
						<td class="text-right">
							<button mat-button [disabled]="(!p.savevalue || p.savevalue == '') ? true : false" class="btn btn-primary btn-sm" (click)="updateBarcode(p)" [ngClass]="!p.savevalue || p.savevalue =='' ? 'disabled': 'green-btn'">
								<i class="fa fa-save "></i>
							</button>
							<button class="btn btn-sm btn-primary" (click)="removeBarcode(p)">
								<mat-icon>remove</mat-icon>
							</button>
						</td>
					</tr>
				</table>
			</div>
		</ng-container>
	</div>
</div>
