import { Component, OnDestroy, OnInit,ViewChild, ChangeDetectorRef } from '@angular/core';
import { ViewportScroller } from '@angular/common';

import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

import { trigger, state, style, transition, animate } from '@angular/animations';
import * as Chartist from 'chartist';

import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

import { FormBuilder, FormGroup,UntypedFormControl } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DataService } from '../../data.service';
import { OmsService } from '../../services/oms.service';
import { OrdersService } from '../../services/orders.service';
import { DailySales } from '../../classes/reports';
import { OrderTypes,Cards } from '../../classes/orders';
import { Message } from '../../classes/message';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import * as XLSX from 'xlsx';

declare let $: any;
declare const google: any;

interface Marker {
	lat: number;
	lng: number;
	label?: string;
	draggable?: boolean;
}
@Component({
	selector: 'app-route-builder',
	templateUrl: './route-builder.component.html',
	styleUrls: ['./route-builder.component.scss']
})
export class RouteBuilderComponent implements OnInit {

	newMessage: string;
	messageList:  string[] = [];
	sender: any;

	SENDER: any;
	messages = [];
	message: any;
	CHAT_ROOM = 'OrderBoard';
	orderfiltertype = new UntypedFormControl(1);
	messageForm: any;
	data: any;
	carddata: any;
	searchterm = '';
	active_run: any;

	todo = [];
	review = [];
	done = [];

	orders = [];
	status = [];
	sortcolumns = [];
	cardaction: any;
	selectedColumn: any;
	picking: any;
	pickable = [];
	columnlist = [];
	completed_orders: any = [];
	modalList = [];
	modalcolumn: any;
	innerWidth: any;
	mapdata: any;
	columnfocus: any;
	focusdata: any;
	totalonrun: any;

	alternate = false;
	toggle = true;
	color = false;
	size = 40;
	expandEnabled = true;
	contentAnimation = true;
	dotAnimation = true;

	returns = [];

	side = 'right';
	filteredItems: any;
	ordersFound: any;
	runlist = [];

	pickedOptions: any = [
		{ value : 1, name: 'All' },
		{ value : 2, name: 'Picked Only' },

	]

	constructor(public omsService: OmsService, public location: Location, public ordersService: OrdersService, private globalSearchService: GlobalSearchService) {


	}

	ngOnInit(): void {


		this.innerWidth = window.innerWidth;
		//let data =  [];
		//this.omsService.sendOrderUpdate({data, roomName: this.CHAT_ROOM}, cb => {});

		this.omsService.getOrderCards().subscribe((results:any) => {
			this.status = results.status
			this.pickable = results.orders;
			this.filteredItems = results.orders;
			this.carddata = results.current
			this.sortcolumns = results.columns;
		});

		this.omsService.getPicks().subscribe((results:any) => {

			this.completed_orders = [];
			results.picked.forEach((item: any) => {
				if(!this.completed_orders.includes(item.ORDER_NUMBER)) {
					this.completed_orders.push(item.ORDER_NUMBER);
				}
			});

		});

		const token = this.randomString(12, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
		if (token) {
			this.omsService.setupSocketConnection(token);
			this.omsService.subscribeToCards((err, data) => {
				if(data) {
					this.completed_orders = [];
					data.picked.forEach((item: any) => {
						if(!this.completed_orders.includes(item.ORDER_NUMBER)) {
							this.completed_orders.push(item.ORDER_NUMBER);
						}
					});

				}

				if(data.data) {
					this.carddata = data.data;
				}
			});

			this.omsService.subscribeToOpenOrders((err, data) => {
				this.showNotification('New Order Added ','info','top','right');

				this.omsService.getOrderCards().subscribe((results:any) => {
					this.status = results.status
					this.pickable = results.orders;
					this.filteredItems = results.orders;
					this.carddata = results.current
					this.sortcolumns = results.columns;
				});

			});
		}
	}
	filterOrderType() {

	}

	isComplete(item: any) {
		 const truefalse =true;
		 //this.completed_orders.includes(item)

		return truefalse;
	}

	onHeaderClick(event) {
		if (!this.expandEnabled) {
			event.stopPropagation();
		}
	}

	onDotClick(event) {
		if (!this.expandEnabled) {
			event.stopPropagation();
		}
	}



	onExpandEntry(expanded, index) {

	}

	toggleSide() {
		this.side = this.side === 'left' ? 'right' : 'left';
	}

	getColumnFocus() {
		if(this.columnfocus) {
			this.focusdata = this.carddata[this.columnfocus];
			return this.carddata[this.columnfocus];
		} else {
			this.focusdata = false;
			return false;
		}
	}

	setColumnFocus(column) {
		this.columnfocus = column;
	}

	runList(column: any, activerun: any) {
		this.active_run = activerun;
		this.omsService.getRunPick(column).subscribe((results: any) => {
			this.runlist = results.items;
			this.totalonrun = results.items.reduce(function (accumulator, item) {
			  return accumulator + parseFloat(item.ordered);
			}, 0);
		});
	}


	drawMap(column) {
		const coordinates = [];
		this.columnfocus = column;
		this.setColumnFocus(column);
		this.focusdata = this.carddata[column];
		//todo make this part of company settings
		const home = { lat: 42.928455044944165, lng: -78.82142723259965 }
		const lines = [home];
		const columndata= this.carddata[column];
		columndata.forEach((item: any) => {
			lines.push({ lat: parseFloat(item.lat), lng: parseFloat(item.lng) })
			const ltlng = new google.maps.LatLng(item.lat, item.lng);
			const itemsort = +item.sort;
			const m = new google.maps.Marker({
				position: ltlng,
				title: '('+itemsort+') '+item.orderno,
				optimized: true,
				animation: google.maps.Animation.DROP,
				label: {
					text: '('+itemsort+')',
					color: '#fff',
					fontSize: '14px',
					fontWeight: 'bold'
				}
			});

			coordinates.push(m);
		});

		lines.push(home);

		const myLatlng = new google.maps.LatLng(42.928455044944165, -78.82142723259965);
		const mapOptions = {
			zoom: 11,
			center: myLatlng,
			scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
			styles: [
				{
					'featureType': 'water',
					'stylers': [{
						'saturation': 43
					}, {
						'lightness': -11
					}, {
						'hue': '#0088ff'
					}]
				}, {
					'featureType': 'road',
					'elementType': 'geometry.fill',
					'stylers': [{
						'hue': '#ff0000'
					}, {
						'saturation': -100
					}, {
						'lightness': 99
					}]
				}, {
					'featureType': 'road',
					'elementType': 'geometry.stroke',
					'stylers': [{
						'color': '#808080'
					}, {
						'lightness': 54
					}]
				}, {
					'featureType': 'landscape.man_made',
					'elementType': 'geometry.fill',
					'stylers': [{
						'color': '#ece2d9'
					}]
				}, {
					'featureType': 'poi.park',
					'elementType': 'geometry.fill',
					'stylers': [{
						'color': '#ccdca1'
					}]
				}, {
					'featureType': 'road',
					'elementType': 'labels.text.fill',
					'stylers': [{
						'color': '#767676'
					}]
				}, {
					'featureType': 'road',
					'elementType': 'labels.text.stroke',
					'stylers': [{
						'color': '#ffffff'
					}]
				}, {
					'featureType': 'poi',
					'stylers': [{
						'visibility': 'off'
					}]
				}, {
					'featureType': 'landscape.natural',
					'elementType': 'geometry.fill',
					'stylers': [{
						'visibility': 'on'
					}, {
						'color': '#b8cb93'
					}]
				}, {
					'featureType': 'poi.park',
					'stylers': [{
						'visibility': 'on'
					}]
				}, {
					'featureType': 'poi.sports_complex',
					'stylers': [{
						'visibility': 'on'
					}]
				}, {
					'featureType': 'poi.medical',
					'stylers': [{
						'visibility': 'on'
					}]
				}, {
					'featureType': 'poi.business',
					'stylers': [{
						'visibility': 'simplified'
					}]
				}]

		};
		const map = new google.maps.Map(document.getElementById('map'), mapOptions);

		const marker = new google.maps.Marker({
			position: myLatlng,
			title: 'Home'
		});

		marker.setMap(map);

		coordinates.forEach((item: any) => {
			item.setMap(map);
		});

		 const lineSymbol = {
			 path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
		};

		const pathTo = new google.maps.Polyline({
			path: lines,
			geodesic: true,
			strokeColor: '#F1575A',
			strokeOpacity: 1.0,
			strokeWeight: 2,
			icons: [{
				icon: lineSymbol,
				offset: '100%'
			}],
		  });
		pathTo.setMap(map);
		// To add the marker to the map, call setMap();

	}

	getRecalc(column) {
		this.omsService.getRecalc(column).subscribe((results:any) => {
			this.carddata[column] = results.recalc;
		});
	}

	getDropType() {
		if(this.innerWidth > 600) {
			return 'horizontal';
		} else {
			return 'vertical';
		}
	}

	submitMessage(cardaction) {
		const data = this.carddata //cardaction;

		if (data) {
			this.omsService.sendCardUpdate({ data, roomName: this.CHAT_ROOM }, cb => {
			});

			this.messages = [...this.messages,{ data,...this.SENDER, },];
		}
	}

	submitDispatch(columnid) {
		const data = { column: columnid, data: this.carddata[columnid] } //cardaction;
		if (data) {
			this.omsService.sendDispatchUpdate({ data, roomName: this.CHAT_ROOM }, cb => {
			});

			this.carddata[columnid] = [];
		}
	}

	randomString(length, chars) {
		let result = '';
		for (let i = length; i > 0; --i) {result += chars[Math.floor(Math.random() * chars.length)];}
		return result;
	}

	drop(event: CdkDragDrop<string[]> , list: string) {

		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {

			transferArrayItem(event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex);
		}

		const cardaction = {
			pindex: event.previousIndex,
			cindex: event.currentIndex,
			list: list,
			message: this.carddata
		}

		this.submitMessage(cardaction);

	}

	clearRun(column) {

		const original = this.carddata[column]
		this.pickable = [...this.filteredItems, ...original];
		this.carddata[column] = [];

		const cardaction = {
			message: this.carddata
		}

		this.submitMessage(cardaction);
	}

	back(): void {
		this.location.back()
	}

	setFilteredLists(term) {


		const allresults = [];
		const ordersFound = [];
		if(this.pickable) {
			const openorders = this.filterItem(this.pickable, term, 'address,order');
			if(openorders) {
				openorders.forEach(function (value) {
					allresults.push(value)
				});
			}
		}

		this.setFiltered(allresults)

	}

	getFiltered() {
		return this.filteredItems;
	}

	setFiltered(results) {
		this.filteredItems = results;
	}

	assignCopy(input){
		this.filteredItems = Object.assign([], input);
	}

	filterItem(input, term , keys ){
		if(!term){
			this.assignCopy(input);
		}
		const result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) &&	new RegExp(term, 'gi').test(item[key])));
		return result
	}

	public onInput(event: any, keys = 'orderno'){
		this.searchterm = event.target.value;
		// this pushes the input value into the service's Observable.

		this.globalSearchService.searchTerm.next(event.target.value);
		const term = event.target.value;
		const allresults = [];

		if(this.pickable) {
			this.setFilteredLists(term);
		}
	}

	listData(column) {
		this.modalList = this.carddata[column];
		this.modalcolumn = column;
	}

	exportExcel(datain): void {
		/* table id is passed over here */
		const element = datain;
		const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(element);

		/* generate workbook and add the worksheet */
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
		/* save to file */
		XLSX.writeFile(wb, 'RunSheet.xlsx');
	}

	showNotification(message, type, from, align){
		//  const type = ['','info','success','warning','danger'];
		 // const color = Math.floor((Math.random() * 4) + 1);

		  $.notify({
			icon: 'notifications',
			message: message
		},{
			type: type,
			timer: 1000,
			placement: {
				from: from,
				align: align
			},
			template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
				'<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
				'<i class="material-icons" data-notify="icon">notifications</i> ' +
				'<span data-notify="title">{1}</span> ' +
				'<span data-notify="message">{2}</span>' +
				'<div class="progress" data-notify="progressbar">' +
				  '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
				'</div>' +
				'<a href="{3}" target="{4}" data-notify="url"></a>' +
			'</div>'
		});
	}

}
