<ng-template #popTemplate>
	<div class="form-group">
		<div class="row">
			<div class="col-12 text-center" *ngIf="snoozeForm">
				<form [formGroup]="snoozeForm" >
					<label for="numberofdays">Snoozing (Days): {{ snoozeorder }}</label>
					<div class="row">
						<div class="col-4">
						<mat-form-field class="example-full-width" appearance="fill" >
							<mat-label>Initials</mat-label>
							<input matInput type="text" placeholder="Initials" [value]=""  name="initials" id="initials"  formControlName="initials">
							<mat-error>
								<span *ngIf="!snoozeForm.get('initials').valid && snoozeForm.get('initials').touched">
								Enter your Initials!!</span>
							</mat-error>
						</mat-form-field>
						</div>
						<div class="col-8">
							<mat-form-field class="example-full-width" appearance="fill">
							<mat-label>Reason For Snooze</mat-label>
								<input matInput #message maxlength="128" placeholder="Reason for snooze ..." name="message" id="message" formControlName="message" [value]=""  >
								<mat-hint align="end">{{message.value.length}} / 128 </mat-hint>
								<mat-error>
									<span *ngIf="!snoozeForm.get('message').valid && snoozeForm.get('message').touched">
									Enter a reason!
									</span>
								</mat-error>
							</mat-form-field>
						</div>
					</div>
				</form>

				<div class="row">
					<mat-form-field color="accent" appearance="fill" class="col-5">
						<mat-label>Pick a Day</mat-label>
						<input matInput  [matDatepicker]="picker1" (dateInput)="dateChanged($event)" (dateChange)="dateChanged($event)"
						[(ngModel)]="initaldate"
						>
						<mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
						<mat-datepicker #picker1></mat-datepicker>
					</mat-form-field>

					<h6 class="col-2 ml-0 mr-0"><b>OR</b></h6>

					<mat-form-field class="example-full-width" appearance="fill" class="col-5">
						<mat-label>Number Of Days</mat-label>
						<input matInput type="number" placeholder="Number Of Days" [value]="numberofdays"  name="numberofdays" id="numberofdays" (input)="setSnooze($event)" restrict-to="[0-9]">
					</mat-form-field>
				</div>

					<button class="btn btn-sm btn-rose" (click)="snooze(snoozeorder)">
						<i class="material-icons">snooze</i>
					</button>

			</div>
		</div>
	</div>
</ng-template>

<div class="card-header card-header-icon" [ngClass]="finished() ? 'card-header-info' : 'card-header-rose'" *ngIf="order">
    <div class="card-icon" (click)="back()">
      <i class="material-icons">toc</i>
    </div>
	    <div class="card-title">
	    	<div class="text-left">
		    	 <h4 class="card-title" [ngClass]="issnoozed ? 'text-warning' : ''">
			    	 <span class="col-6">
					 	Order#: <b>{{ orderset }}</b>
			    	 </span>
			    	 <div class="ml-auto mr-0 col-6 text-right">
				    	 <span class="text-primary"><b>{{ order.status }}</b></span>
				    	 <span class="text-waring" *ngIf="issnoozed">&nbsp;<b>Snoozed</b></span>
			    	 </div>
	    		</h4>
	    	</div>

	    		<small>
					<div class="col-12 mr-0 pr-0 pl-0 mr-0 ml-0 text-right" *ngIf="!issnoozed" >
						<button id="popsnooze" type="button" [popover]="popTemplate" class="btn btn-sm btn-rose"
						placement="left" (click)="setSnoozeOrder(orderset)">
						<i class="material-icons">notifications_active</i></button>
					</div>
					<div class="col-12 mr-0 pr-0 pl-0 mr-0 ml-0 text-right" *ngIf="issnoozed" >
						<button id="popsnooze" type="button"  class="btn btn-sm btn-danger pr-0 pl-3"
						(click)="snooze(orderset)">
						<i class="material-icons">snoozed</i></button>
					</div>
				</small>

	    	<p class="card-category col-6">Status: </p>
	    	<div class="col-12 mt-0 text-left" *ngIf="order.po">
				<h6 class="mb-0">PO: <b>{{ order.po }}</b></h6>
			</div>
			<div class="text-left col-6" [innerHTML]="address" *ngIf="address != ''"></div>
	    </div>
  </div>
	<div class="card-body" *ngIf="order">
		<div id="typography">
			<div class="card-title">
				<div class="digital-add needs-validation" *ngIf="order">
					<form >
						<!-- order details -->
						<div class="row border-bottom mt-2">
							<div class="col-6 text-left mr-0 pr-0"><b>BIN/ITEM/DESC</b></div>
							<div class="col-2 text-center m-0 p-0 "><b></b></div>
							<div class="col-4 text-right ml-0 text-nowrap"><b>PICK</b></div>
						</div>
						<div class="mt-auto mb-auto" *ngFor="let item of orderdetails.details" [ngClass]="item.PRICE != 0 ? 'border-bottom':'' ">
							<div class="row mt-0 mb-0 pt-0 pb-0">
								<div class="col-auto text-left mr-0 pr-0">

									<b>{{ item.stockid }}</b>: {{ item.description }} </div>
									<!--<div class="col-3 text-right ml-0 text-nowrap" *ngIf="item.PRICE != 0"><b>{{ item.PRICE | currency }}</b></div>-->
							</div>
							<div class="row mt-0 mb-0 pt-0 pb-0">
								<div class="col-4 text-left mr-0 pr-0 mt-1 ">
									<mat-checkbox class="mat-checkbox mat-accent ml-auto mr-0 mt-3" data-item="{{ item.stockid }}" [checked]='isChecked(item.orderlineno)' (change)="toggle($event, item.orderlineno, item.ordered)" ></mat-checkbox>&nbsp;&nbsp;<b>{{ item.bin }}</b></div>
								<div class="col-5 text-right text-nowrap ml-auto mr-0 mt-auto mb-auto pr-0">
									<div class="form-group">
										<button class=" btn btn-just-icon btn-white btn-fab btn-round btn-sm" (click)="removePick(item.orderlineno)">
										<small><i class="material-icons">remove</i></small></button>
										<span class="mr-2 ml-2" [ngClass]="getCount(item.orderlineno) < item.ordered ? 'text-danger': 'text-success'" ><b>{{ getCount(item.orderlineno) }}</b> / <b>{{ item.ordered }}</b>
										</span>
									<button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-2" (click)="addPick(item.orderlineno)">
										<small><i class="material-icons">add</i></small>
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
				<div class="col-12 col-md-12 text-right mt-2 mb-2" *ngIf="itemScanForm">
					<form [formGroup]="itemScanForm" (ngSubmit)="scanItem()" class="needs-validation product-add" novalidate="">
						<input formControlName="stockidsearch" class="form-control text-right" id="stockidsearch" type="text" required="" placeholder="* Item Scan">
						<div class="text-right mt-2 mb-2">
							<button type="button" class="btn btn-sm btn-primary mr-1" (click)="scanItem()">Scan / Type Item</button>
						</div>
					</form>
				</div>
				<div class="row" *ngIf="order.snoozehist">
						<div class="col-12" *ngFor="let snooze of order.snoozehist">
							 {{ snooze.initials }} : Snoozed: {{ snooze.date_added }}  End: {{ snooze.date_end }} {{ snooze.comments }}
						</div>
					</div>
				<div class="alert pt-0 pb-0" [ngClass]="finished() ? 'alert-info' : 'alert-warning'" *ngIf="error != ''"><button mat-button="" type="button" data-dismiss="alert" aria-label="Close" class="close mat-button mat-button-base"><span class="mat-button-wrapper"><i class="material-icons">close</i></span><div matripple="" class="mat-ripple mat-button-ripple"></div><div class="mat-button-focus-overlay"></div></button><span>{{ error }}</span></div>
			</div>
		</div>
	</div>
