import { Component, OnDestroy, OnInit,ViewChild,ChangeDetectorRef,ElementRef } from '@angular/core';
import { CustomerService } from '../services/customer.service';
import { Location } from '@angular/common'

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { ActivatedRoute, Router } from '@angular/router';

import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../services/globalsearchservice.service';
import { merge, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';


@Component({
	selector: 'app-customers',
	templateUrl: './customers.component.html',
	styleUrls: ['./customers.component.scss']
})

export class CustomersComponent implements OnInit {
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('customersearch') customersearch: ElementRef;
	color  = 'blue';
	title = 'customers';
	ready: boolean;
	noresults: boolean;
	include_disabled = false;
	customers: any = [];
	all_customers: any = [];
	results: any = [];
	headercolumns: any = [
		'actions',
		'debtorno',
		'name',
		'billingaddress',
		'phoneno'
	];
	filteredItems: any = [];
	customersearching = false;
	customersearchsubscription: any;
	lastcustomer = '';

	constructor(private customerService: CustomerService, private location: Location,public cdr: ChangeDetectorRef,private globalSearchService: GlobalSearchService,public router: Router) { }

	ngOnInit(): void {
		this.ready = false;
		this.noresults = true;
		this.globalSearchService.mesagesallowed.next(true);
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.lastCustomer.subscribe( (result: any) => {
			if(result) {
				//this.viewCustomer(result);
			}
		});
	}

	 exportPdf() {
		 // import("jspdf").then(jsPDF => {
		///	import("jspdf-autotable").then(x => {
			 //	 const doc = new jsPDF.default(0,0);
			 //	 doc.autoTable(this.header, this.customers);
		//	doc.save('products.pdf');
		//})
	 //	 })
	}

	selectCustomer(event: any) {
		this.router.navigate(['./customers/view/'+event.debtorno]);

	}

	viewCustomer(debtorno: any) {
		this.router.navigate(['./customers/view/'+debtorno]);
	}

	exportExcel() {
		this.globalSearchService.exportJsonToExcel(this.customers, 'Customers');
	}

	back(): void {
		this.location.back()
	}



	announceSortChange(sortState: Sort) {

	}

}
