<ng-template #details let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">{{ displaydetails.title }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<!--
		<mat-chip-set role="list">
			<mat-chip role="listitem" *ngFor="let l of viewedtransactions">
				<button mat-menu-item (click)="viewPayments(l,details)">
					<mat-icon>credit_card</mat-icon>
					<span>Payments</span>
				</button>
			</mat-chip>
		</mat-chip-set>
		-->

		<div class="mb-3" *ngIf="displaydetails">
			<h4></h4>
			<ng-container *ngIf="displaydetails.type == 'html'">
				<div [innerHTML]="displaydetails.display">
				</div>
			</ng-container>
			<ng-container *ngIf="displaydetails.type == 'pdf'">
				<ngx-extended-pdf-viewer [base64Src]="displaydetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true"></ngx-extended-pdf-viewer>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<app-customer-aging [aged]="customerdata.aged"></app-customer-aging>

<div class="table table-sm">
	<div class="row m-0 p-0" *ngIf="transactions.length">
		<div class="col-md-4">
			<mat-form-field appearance="outline">
				<mat-label>Transaction Dates</mat-label>
				<mat-date-range-input [rangePicker]="picker">
					<input matStartDate placeholder="Start date" [formControl]="fromDateControl">
					<input matEndDate placeholder="End date" [formControl]="toDateControl">
				</mat-date-range-input>
				<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-date-range-picker #picker></mat-date-range-picker>
				<span matSuffix>
					<button mat-icon-button color="white" type="submit" (click)="loadTransactions()">
						<mat-icon class="p-0">search</mat-icon>
					</button>
				</span>
			</mat-form-field>
		</div>
		<div class="col-md-4 offset-md-4 text-right">
			<mat-form-field appearance="outline">
				<mat-label>Filter Transactions</mat-label>
				<input matInput placeholder="Search by reference" [formControl]="transactionFilterControl" (input)="filterTransactions()">
				<mat-icon matSuffix>search</mat-icon>
			</mat-form-field>
		</div>
	</div>

	<div class="table mt-3">
		<table class="table table-fixed table-sm table-hover" *ngIf="filteredTransactions.length">
			<thead class="thead-dark">
				<tr>
					<th scope="col" class="text-center"></th>
					<th scope="col" class="text-center"></th>
					<th scope="col">Invoice#</th>
					<th scope="col">Type</th>
					<th scope="col">Date</th>
					<th scope="col" class="text-center">Order#</th>
					<th scope="col" class="td-max">Reference</th>
					<th scope="col" class="td-max">Notes</th>
					<th scope="col" class="text-right">Total</th>
				</tr>
			</thead>
			<tbody>
				<ng-container *ngFor="let tran of filteredTransactions">
					<tr>
						<td class="text-center">
							<button mat-icon-button *ngIf="tran.type == '10' || tran.type == '11'" (click)="openDocument(tran,details,'html')">
								<mat-icon>visibility</mat-icon>
							</button>
						</td>
						<td class="text-center">
							<button mat-raised-button color="accent" class="btn-sm" (click)="loadCredit(tran.id)">
								Credit
							</button>
						</td>
						<td>{{ tran.transno }}</td>
						<td>{{ tran.typename }}</td>
						<td>{{ tran.trandate | date:'shortDate' }}</td>
						<td class="text-center">{{ tran.order_ }}</td>
						<td class="td-max">{{ tran.invtext }}</td>
						<td class="td-max">{{ tran.ordernotes }}</td>

						<td class="text-right">{{ tran.totalamount | currency }}</td>
					</tr>
				</ng-container>
			</tbody>
		</table>

		<div class="row">
			<div class="col-12 text-center" *ngIf="!filteredTransactions.length">
				<h4><b>No Transactions Found</b></h4>
			</div>
		</div>
	</div>

</div>