<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-3">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Customer Groups</h4>
						<p class="card-category"></p>
					</div>
					<div class="card-body">
						<mat-form-field appearance="outline">
							<mat-label>Customer Search Add Customers to Groups</mat-label>
							<input type="text" matInput [formControl]="search" (input)="customerSearch()">
						</mat-form-field>
						<ng-container *ngIf="searchResults">
							<div class="row" *ngFor="let c of searchResults">
								<div class="col-12" (click)="addCustomer(c)">
									{{ c.debtorno }}.{{c.branchcode}} {{ c.name }}
								</div>
								<mat-divider></mat-divider>
							</div>
						</ng-container>
						<ng-container *ngIf="grouped.length">
							<form [formGroup]="groupForm">
								<div class="card">
									<div class="card-body">
										<div class="row">
											<div class="col-8">
												<mat-form-field appearance="outline">
													<mat-label>Group Name</mat-label>
													<input type="text" class="text-left " matInput formControlName="group_name" />
												</mat-form-field>
											</div>
											<div class="col-4">
												<button mat-stroked-button color="success" aria-label="" (click)="addGroup()" class="mt-2 mb-2">
													<mat-icon>add</mat-icon> Add Group
												</button>
											</div>
										</div>

										<div class="row">
											<div class="col-6" *ngFor="let c of grouped">
												<button mat-stroked-button color="success" aria-label="" (click)="removeCustomer(c)" class="mt-2 mb-2">
													<mat-icon>remove</mat-icon>
												</button>
												{{ c.debtorno }}.{{c.branchcode}} {{ c.name }}
											</div>
											<mat-divider></mat-divider>
										</div>

									</div>
								</div>
							</form>
						</ng-container>
						<div class="row border-top" *ngFor="let c of customergroups">
							<div class="col-10">
								<h4>{{ c.group_name }}</h4>
							</div>
							<div class="col-2">
								<button mat-stroked-button color="success" aria-label="" (click)="removeGroup(c)" class="mt-2 mb-2">
									<mat-icon>remove</mat-icon>
								</button>
								<button mat-stroked-button color="success" aria-label="" (click)="editGroup(c)" class="mt-2 mb-2">
									<mat-icon>edit</mat-icon>
								</button>
							</div>
							<div class="col-12" *ngFor="let p of c.members">
								<button mat-stroked-button color="success" aria-label="" (click)="removeMember(p , c)" class="mt-2 mb-2">
									<mat-icon>remove</mat-icon>
								</button>
								{{ p.debtorno }}.{{ p.branchcode }} {{ p.name }}

							</div>
							<mat-divider></mat-divider>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
