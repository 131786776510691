<div class="container-fluid tp-box" [@flipState]="flip" >
	<div class="row tp-box__side tp-box__back" *ngIf="flip == 'active'">
		<div class="card" >
			<div class="card-header card-header-info card-header-icon">
				<div class="card-icon pointer" (click)="toggleFlip(false, '')">
					<i class="material-icons">wb_cloudy</i>
				</div>
				<h3 class="card-title">Over Due Customers {{ title }} </h3>
			</div>
			<div class="card-body"  *ngIf="viewing">
				<app-trial-balance [data]="viewing"></app-trial-balance>
			</div>
		</div>
	</div>
	<div class="row tp-box__side tp-box__front" id="frontside" [ngClass]="flip == 'inactive' ? '' : 'd-none'">
		<div class="card card-stats">
			<div class="card-header card-header-info card-header-icon">
				<div class="card-icon pointer" (click)="toggleFlip(original_trialbalance, 'All')">
					<i class="material-icons">wb_cloudy</i>
				</div>
				<p class="card-category">Over Due Customers</p>
				<h3 class="card-title">
					<span *ngIf="trialbalance">
						{{ total_overdue_details.total }}
					</span>
					<span *ngIf="!trialbalance">
						<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</span>
				</h3>
			</div>
			<small>
				<p class="card-category text-info text-justify mt-3 text-right">
					<span class=" ml-1 mr-1 pointer" (click)="toggleFlip(overduezero, '30 Days')"> ( {{ total_overdue_details.zero }} ) 30 Day </span>
					<span class=" ml-1 mr-1 pointer" (click)="toggleFlip(overdueone, '60 Days')"> ( {{ total_overdue_details.one }} ) 60 Day </span>
					<span class=" ml-1 mr-1 pointer" (click)="toggleFlip(overduetwo, '90 Days')"> ( {{ total_overdue_details.two }} ) 90 Day </span>
					<span class=" ml-1 mr-1 pointer" (click)="toggleFlip(overduethree, '120 Days')"> ( {{ total_overdue_details.three }} ) 120 Day </span>
				</p>
			</small>
			<div class="card-footer">
				<div class="stats pointer">
					<a (click)="toggleFlip(original_trialbalance, 'All')">
						<i class="material-icons">content_copy</i>
						View Now</a>
				</div>
				<br />
			</div>
		</div>
	</div>
</div>
