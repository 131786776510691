import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, UntypedFormGroup, UntypedFormControl, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { InventoryService } from '../../services/inventory.service';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { TableModule } from 'primeng/table';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { Directive, HostListener } from '@angular/core';
import { merge, fromEvent, interval, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { I } from '@angular/cdk/keycodes';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { BommodalComponent } from '../../shared/boms/bommodal/bommodal.component';
import { VendorService } from '../../services/vendor.service';

import * as $ from 'jquery';

@Component({
	selector: 'app-inventory-promos',
	templateUrl: './inventory-promos.component.html',
	styleUrls: ['./inventory-promos.component.scss'],
	animations: [
		trigger('grow', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: '0', width: '0' }),
				animate('150ms', style({ width: '*', height: '*' })),
				animate('150ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate('150ms', style({ width: '0', height: '0' })),
				animate('150ms', style({ opacity: 0 }))
			])
		]),
	],
})
export class InventoryPromosComponent implements OnInit {

	@ViewChild('vendorsearch') vendorsearchele: ElementRef;

	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	itemSearchControl = new UntypedFormControl('');
	categoryControl = new UntypedFormControl('');
	productLineControl = new UntypedFormControl('');
	searchpromo = new UntypedFormControl('');
	searchmix = new UntypedFormControl('');
	searching = false;
	inventorysearching: any;
	itemsearchsubscription: any;
	include_disabled = new UntypedFormControl(false);

	items: any = [];
	activemm: any;;
	allitems: any = [];
	newmm: any = false;
	exists: any = false;
	color: any = '';
	config: any = [];
	user: any = [];
	promoitems: any = false;
	promos: any = false;
	mms: any = false;
	mm: any = false;
	itemForm: UntypedFormGroup;
	itemMMForm: UntypedFormGroup;
	vendorsearchsubscription: any = false;
	editingpromo: any = false;
	newpromo: any = false;
	editingmm: any = false;
	vendorresults: any = [];
	properties: any = false;
	saving: any = false;
	mmitems: any = false;
	triggersearch: any = false;
	allpromos: any = [];
	brands: any = [];
	promo_types = [
	{
		value: 3,
		label: 'Mix And Match Child Price with additional Items'
	},
	{
		value: 2,
		label: 'Discounted Total, Mix And Match Items'
	},
	{
		value: 4,
		label: 'Child Priced Promo'
		//bottom up
	},
	]



	sortedPromos = [];

	sortColumn: string = '';
	sortDirection: string = 'asc';
	sortedMMs = [];
	mmSortColumn: string = '';
	mmSortDirection: string = 'asc';

	constructor(private vendorService: VendorService, private globalsService: GlobalsService, private route: ActivatedRoute, private inventoryService: InventoryService, private location: Location, public router: Router, private modalService: NgbModal, public cdr: ChangeDetectorRef, private fb: FormBuilder, private globalSearchService: GlobalSearchService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});

		this.color = this.globalSearchService.getColor();
		this.globalSearchService.user.subscribe((results: any) => {
			if (results) {
				this.user = results.user;
			}
		});
	}

	ngOnInit(): void {
		const filter = []
		this.inventoryService.getPromos(filter).subscribe(r => {
			this.promos = r.promos;
			this.sortedPromos = this.promos;
			this.allpromos = r.promos;
		});

		this.inventoryService.getMfgs().subscribe(r => {
			this.brands = r
		});

		this.inventoryService.getMixAndMatch(filter).subscribe(r => {
			this.mms = r;
			this.sortedMMs = r;
			this.setMMForm();
		});

		this.globalsService.getItemSetup().subscribe((results: any) => {
			this.properties = results
			this.setForm();
		});

	}

	removeExclusion(item: any) {

		const ob = this.mm.exlusions.filter((i: any) => {
			return i.stockid == item.stockid;
		})[0];

		const index = this.mm.exlusions.indexOf(ob);
		this.mm.exlusions.splice(index, 1);
		this.triggersearch = this.mm.exlusions;

	}

	exludeItem(item: any) {
		const index = this.mmitems.indexOf(item);
		if (index >= 0) {
			this.mmitems.splice(index, 1);
			this.mm.exlusions.push(item);
		}
	}

	showResults(event: any) {
		this.triggersearch = false;
		this.mmitems = event;
	}

	vendorSearch(value: any) {


		fromEvent(this.vendorsearchele.nativeElement, 'keyup')
			.pipe(
				debounceTime(150),
				distinctUntilChanged(),
			)
			.subscribe();


		if (this.vendorsearchsubscription) {
			this.vendorsearchsubscription.unsubscribe();
		}

		this.vendorsearchsubscription = this.vendorService.getVendorSearch(value).subscribe((results: any) => {
			this.vendorresults = results
		});

	}

	setForm() {

		const today = new Date();
		const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

		this.itemForm = this.fb.group({
			//image: [''],
			supplierno: [''],
			stockid: ['', [Validators.required, Validators.maxLength(20)]],
			name: ['', [Validators.required]],
			brand: ['', [Validators.required]],
			description: [''],
			longdescription: [''],
			categoryid: ['', [Validators.required]],
			lineid: ['', [Validators.required]],
			mbflag: ['C', [Validators.required]],
			taxcatid: ['2', [Validators.required]],
			units: ['each', [Validators.required]],
			supplier: [''],
			mfgpart: [''],
			promotype: [3],
			discount: [0, ],
			price: [0.00, ],
			discounttype: ['', ],
			cost: [0, [Validators.required]],
			options: [1, [Validators.required]],
			enddate: [lastDayOfMonth, [Validators.required]],
			startdate: [today, [Validators.required]],
		});

		this.itemForm.get('lineid').valueChanges.subscribe(newValue => {
			let suggested = newValue + this.itemForm.get('mfgpart').value

			//todo make this dynamic: break by % % and fill in variable names
			if (this.config.stockidformat && !this.editingpromo) {
				switch (this.config.stockidformat) {
					case '%categoryid%lineid%mfgpart%':
						suggested = this.itemForm.get('categoryid').value + this.itemForm.get('lineid').value + newValue;
						break;
					default:
						suggested = newValue + '-' + this.itemForm.get('mfgpart').value;
						break;
				}
				this.itemForm.get('stockid').setValue(suggested);
			}

			
		});

		this.itemForm.get('mfgpart').valueChanges.subscribe(newValue => {
			let suggested = this.itemForm.get('lineid').value + '-' + newValue

			//todo make this dynamic: break by % % and fill in variable names
			if (this.config.stockidformat && !this.editingpromo) {
				switch (this.config.stockidformat) {
					case '%categoryid%lineid%mfgpart%':
						suggested = this.itemForm.get('categoryid').value + this.itemForm.get('lineid').value + newValue;
						break;
					default:
						suggested = this.itemForm.get('lineid').value + '-' + newValue;
						break;
				}
				this.itemForm.get('stockid').setValue(suggested);
			}

			
		});

		this.itemForm.get('name').valueChanges.subscribe(newValue => {
			this.itemForm.get('description').setValue(newValue);
			this.itemForm.get('longdescription').setValue(newValue);
		});

		this.itemForm.get('brand').valueChanges.subscribe(r => {
			this.updateProductLines();
		});
	}

	clearForm() {
		this.itemSearchControl.setValue('');
		this.allitems = [];
	}

	updateProductLines() {

		const data = {
			brands: this.itemForm.get('brand').value
		}

		this.inventoryService.getBrandProductlines(data).subscribe((r: any) => {
			this.properties.productlines = r;

			if (r[0]) {
				this.inventoryService.getSupplierFromProductLine(r[0].line_field).subscribe(supp => {
					this.itemForm.get('supplierno').setValue(supp[0]);
				});
			}
		});
	}

	setMMForm() {

		const today = new Date();
		//var priorDate = new Date(new Date().setDate(today.getDate() - 365));
		const priorDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
		const nextDate = new Date(today.getFullYear(), today.getMonth() + 2, 0);


		this.itemMMForm = this.fb.group({
			//image: [''],
			stockid: ['', [Validators.required, Validators.maxLength(20)]],
			name: ['', [Validators.required]],
			ceiling: ['', [Validators.required]],
			floor: ['', [Validators.required]],
			subtotal: ['', [Validators.required]], //subtotal?
			startdate: [priorDate, [Validators.required]],
			enddate: [nextDate, [Validators.required]],

		});

		this.triggersearch = false;
	}

	removePromo(mm: any) {
		const filter = [];
		if(confirm('Delete Promo - '+mm.name+'?')){
		this.inventoryService.removePromo(mm).subscribe((results: any) => {
			this.inventoryService.getPromos(filter).subscribe(r => {
				this.promos = r.promos;
				this.sortedPromos = this.promos;
				this.allpromos = r.promos;
				this.sortedPromos = r.promos;
			});
		});
		}
	}

	removeMM(mm:any) {
		if(confirm('Delete Mix and Match ?')){
			const data = {
				selected: mm
			}

			this.inventoryService.removeMM(data).subscribe(r => {
				this.mms = r;
				this.sortedMMs = r;
			});
		}
	}

	loadMM(mm: any) {

		const filters = { mmid: mm.mmid }
		this.setMMForm();
		this.inventoryService.getMix(filters).subscribe(r => {
			this.exists = true;
			this.mm = r;
			this.editingmm = r;
			this.newmm = false
			this.itemMMForm.get('stockid').setValue(mm.stockid);
			this.itemMMForm.get('name').setValue(mm.name);
			this.itemMMForm.get('ceiling').setValue(mm.ceiling);
			this.itemMMForm.get('floor').setValue(mm.floor);
			this.itemMMForm.get('startdate').setValue(new Date(mm.start_date));
			this.itemMMForm.get('enddate').setValue(new Date(mm.stop_date));
			this.itemMMForm.get('subtotal').setValue(r.price)
			this.triggersearch = false;
		});


	}

	loadPromo(promo: any) {

		this.editingpromo = promo;


		//
		// this.itemForm = this.fb.group({
		// 	//image: [''],
		// 	supplierno: [''],
		// 	stockid: ['', [Validators.required,Validators.maxLength(20)]],
		// 	name: ['', [Validators.required]],
		// 	brand: ['', [Validators.required]],
		// 	description: [''],
		// 	longdescription: [''],
		// 	categoryid: ['', [Validators.required]],
		// 	lineid: ['', [Validators.required]],
		// 	mbflag: ['C', [Validators.required]],
		// 	taxcatid: ['2', [Validators.required]],
		// 	units: ['each', [Validators.required]],
		// 	supplier: [''],
		// 	mfgpart: [''],
		// 	promotype: [3],
		// 	discount: [0,],
		// 	price: [0.00,],
		// 	discounttype: ['',],
		// 	cost: [0,[Validators.required]],
		// 	options: [1, [Validators.required]],
		// 	enddate: [lastDayOfMonth,[Validators.required]],
		// 	startdate: [today,[Validators.required]],
		// });
		//
		this.itemForm.get('stockid').setValue(promo.stockid);
		this.itemForm.get('name').setValue(promo.name);
		this.itemForm.get('description').setValue(promo.description);
		this.itemForm.get('longdescription').setValue(promo.definition.longdescription);
		this.itemForm.get('price').setValue(promo.price);
		this.itemForm.get('categoryid').setValue(promo.definition.categoryid);
		this.itemForm.get('lineid').setValue(promo.definition.lineid);
		this.itemForm.get('mfgpart').setValue(promo.definition.mfgpart);
		this.itemForm.get('brand').setValue(promo.brand.manufacturer_id);
		this.itemForm.get('supplierno').setValue(promo.vendor);
		this.itemForm.get('promotype').setValue(promo.type);
		this.itemForm.get('startdate').setValue(new Date(promo.startdate));
		this.itemForm.get('enddate').setValue(new Date(promo.enddate));

		this.promoitems = promo.products;
	}

	stripSlashes(input) {
		return input.replace(/\\(.)/mg, '$1');
	}

	noNewLines(input: any) {
		return this.stripSlashes(input.replace(/[^\x20-\x7E]/gmi, ' ').trim().replace(/\\n/g, ' '));
	}

	financial(x) {

		if (Number.isNaN(x)) {
			x = 0
		}

		return parseFloat(Number.parseFloat(x).toFixed(2));
	}

	back(): void {
		this.location.back()
	}

	displayFn(vendorid: any): string {

		if (!vendorid) {
			return '';
		}

		return vendorid.suppname;

	}

	ngOnChanges(changes: any) {}

	searchItems() {
		this.searching = true;

		if (this.inventorysearching) {
			this.inventorysearching.unsubscribe()
		}

		const search = {
			stockid: this.itemSearchControl.value,
			category: this.categoryControl.value,
			productlines: this.productLineControl.value,
			discontinued: this.include_disabled.value,
			promotype: this.itemForm.get('promotype').value

		}

		this.inventorysearching = this.inventoryService.promolookup(search).subscribe((items: any) => {
			this.allitems = items
			this.searching = false;
			this.items = items;
		});
	}



	removePromoItem(item: any) {
		const payload = {
			parent: this.itemForm.get('stockid').value,
			child: item,
		}

		this.inventoryService.removePromoItem(payload).subscribe((results: any) => {
			this.promoitems = results.bomitems
		});
	}

	saveMm(data: any) {

		const payload = {
			mixandmatch: this.itemMMForm.value,
			rules: data,
			mmid: this.mm.mmid
		}

		this.saving = true;

		this.inventoryService.saveMM(payload).subscribe((results: any) => {
			this.saving = false;
			this.promoitems = results.bomitems
		});

	}

	createMM() {

		const payload = {
			mixandmatch: this.itemMMForm.value,
		}

		this.saving = true;
		this.inventoryService.createMM(payload).subscribe((results: any) => {
			this.saving = false;
			if (results.success) {
				this.loadMM(results.details)
			}
		});
	}

	addPromo() {

		//if(this.itemForm.valid) {
		const payload = {
			promo: this.itemForm.value,
			promoitems: this.promoitems,
		}


		const filter = []
		this.saving = true;


		this.inventoryService.savePromo(payload).subscribe((results: any) => {
			this.saving = false;
			this.inventoryService.getPromos(filter).subscribe(r => {
				this.promos = r.promos;
				this.sortedPromos = this.promos
				this.allpromos = r.promos;
				const reload = this.promos.filter(ra => {
					return ra.promoid = this.editingpromo.promoid
				})[0];
				if (reload) {
					this.editingpromo = reload;
				}
				if (this.newpromo) {
					this.newpromo = false;

					const newitem = this.promos.filter(ra => {
						return ra.stockid = this.itemForm.get('stockid').value
					})[0];
					if (newitem) {
						this.editingpromo = newitem;
					}

				}
			});

		});
		//}
	}

	addPrommoItem(data: any) {

		const payload = {
			parent: this.itemForm.get('stockid').value,
			child: data,
		}

		this.inventoryService.addToPromo(payload).subscribe((results: any) => {
			if (!results.success) {
				this.globalSearchService.showNotification(results.message, 'danger', 'bottom', 'right');
			} else {
				this.globalSearchService.showNotification('Added', 'success', 'bottom', 'right');
			}
			this.promoitems = results.bomitems
		});

	}

	viewItem(row: any) {
		this.allitems = [];
		this.items = [];
		this.itemSearchControl.setValue('');
		this.router.navigate(['/inventory/view/' + row.stockid])
	}

	toggleNewMM() {
		this.newmm = true;
		this.editingmm = false;
	}

	searchPromos(search) {
		if (search != '') {
			let results = this.globalSearchService.filterItem(this.allpromos, search, 'stockid,start_date,stop_date,name,products')
			this.promos = results;
			this.sortedPromos = results;

		} else {
			this.promos = this.allpromos;
			this.sortedPromos = this.allpromos;
		}
	}

	searchMix(search) {
		if (search != '') {
			let results = this.globalSearchService.filterItem(this.mms, search, 'stockid,start_date,stop_date,name,products')
			this.sortedMMs = results;
		} else {
			this.sortedMMs = this.mms;
		}
	}

	closeMM() {
		this.newmm = false;
		this.editingmm = false;
		this.mm = false;
		this.exists = false;
		this.itemMMForm.reset();

		this.inventoryService.getMixAndMatch([]).subscribe(r => {
			this.mms = r;
			this.sortedMMs = r;
		});

	}

	sortTable(column: string) {
		if (this.sortColumn === column) {
			this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
		} else {
			this.sortColumn = column;
			this.sortDirection = 'asc';
		}

		this.sortedPromos.sort((a, b) => {
			let comparison = 0;

			if (a[column] > b[column]) {
				comparison = 1;
			} else if (a[column] < b[column]) {
				comparison = -1;
			}

			return this.sortDirection === 'asc' ? comparison : -comparison;
		});
	}

	getSortIcon(column: string): string {
		if (this.sortColumn === column) {
			return this.sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward';
		}
		return 'sort';
	}
	
	newPromo(){
	
	this.setForm();
	this.promoitems = false;
	this.newpromo = true;
	
	}

	sortMMTable(column: string) {
		if (this.mmSortColumn === column) {
			this.mmSortDirection = this.mmSortDirection === 'asc' ? 'desc' : 'asc';
		} else {
			this.mmSortColumn = column;
			this.mmSortDirection = 'asc';
		}

		this.sortedMMs.sort((a, b) => {
			let comparison = 0;

			if (a[column] > b[column]) {
				comparison = 1;
			} else if (a[column] < b[column]) {
				comparison = -1;
			}

			return this.mmSortDirection === 'asc' ? comparison : -comparison;
		});
	}

	// Method to get the appropriate Material icon for MM sorting
	getMMSortIcon(column: string): string {
		if (this.mmSortColumn === column) {
			return this.mmSortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward';
		}
		return 'sort';
	}

}