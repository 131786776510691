<div class="w-100">
	<div class="col-12 text-right mt-2">
		<b>ITEMS:</b>{{ total_items }}<br>
		<span [ngClass]="total_order_volume > total_capacity ? 'text-danger': ''">
			<b>CAPACITY:</b> {{ total_order_volume | number : '1.2-2' }} / {{ total_capacity | number : '1.2-2' }}
		</span>
	</div>
	<div class="" id="printableRunSheet" #printableRunSheet>
		<div class="row small-font border-bottom item-list m-0 p-0" *ngFor="let da of dispatches;trackBy: identify">
			<ng-container *ngFor="let i of da.orderdetails">
				<li class="dd-item dd3-item item-box">
					<div class="dd-handle dd3-handle" [ngClass]="{credit: da.ordertype=='11',loaded: i.loaded, picked: da.pick_complete, started: (i.pickstatus == '23' && !da.pick_complete) }">&nbsp;</div>
					<mat-card class="dd3-content p-1 ">
						<mat-card-content>
							<div class="row m-0 p-0 no-overflow">
								<div class="col-12 m-0 p-0" [ngClass]="user.user.dispatchadmin == '1' ? 'col-md-12' : 'col-md-12'">
									<ul class="entry-list m-0 p-0" >
										<li>
											<div class="spec-label">ITEM#</div>
											<div class="spec-value">{{ i.stkcode }}</div>
										</li>
										<li>
											<div class="spec-label">DESC#</div>
											<div class="spec-value">{{ i.itemdesc }}</div>
										</li>
										<li>
											<div class="spec-label">ORDER#</div>
											<div class="spec-value">{{ da.header.orderno }}</div>
										</li>
										<li>
											<div class="spec-label">VOLUME</div>
											<div class="spec-value">{{ da.ordercapacity | number : '1.2-2' }}</div>
										</li>
										<li>
											<div class="spec-label">QTY</div>
											<div class="spec-value">{{ i.quantity }}</div>
										</li>
									</ul>
								</div>
							</div>
						</mat-card-content>
					</mat-card>
				</li>
			</ng-container>
		</div>
	</div>

</div>
