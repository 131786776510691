<div class="card mb-3">

	<div class="card-header">

		<div class="row">
			<div class="col-4">
				<mat-form-field appearance="fill">
					<mat-label>Price Level</mat-label>
					<mat-select [formControl]="priceLevelForm.get('pricelevels')" multiple>
						<mat-option *ngFor="let level of priceLevels" [value]="level.price_level_id">
							{{ level.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-4">
				<mat-form-field appearance="fill">
					<mat-label>Product Line</mat-label>
					<mat-select [formControl]="priceLevelForm.get('categories')" multiple>
						<mat-option *ngFor="let category of categories" [value]="category.line_field">
							{{ category.line_field }}-{{ category.line_description }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-4">
				<button mat-raised-button color="primary" (click)="toggleNewPriceLevel()">Create New Price Level</button>
			</div>
		</div>

		<!-- New Price Level Input Section -->
		<div *ngIf="showNewPriceLevelInput" class="row mt-3">
			<div class="col-4">
				<mat-form-field appearance="fill" class="full-width">
					<mat-label>New Price Level Name</mat-label>
					<input matInput [(ngModel)]="newPriceLevelName">
				</mat-form-field>
			</div>
			<div class="col-4">
				<mat-form-field appearance="fill" class="full-width">
					<mat-label>New Price Level ID</mat-label>
					<input matInput [(ngModel)]="newPriceLevelId">
				</mat-form-field>
			</div>
			<div class="col-4">
				<button mat-raised-button color="primary" (click)="createNewPriceLevel()">Save</button>
			</div>
		</div>

	</div>
	<div class="card-body" id="open-orders">
		<div id="order-parent">
			<table class="table table-striped table-sm">
				<thead>
					<tr>
						<th class="text-center">Level</th>
						<th class="text-center">Product Line</th>
						<th>Name</th>
						<th class="text-center">Discount %</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let result of results">
						<td class="text-center">{{ result.price_type }}</td>
						<td class="text-center">{{ result.category_id }}</td>
						<td>{{ result.price_name }}</td>
						<td>
							<mat-form-field appearance="outline" class="full-width">
								<input matInput type="text" [(ngModel)]="result.discountpercent" class="text-center" (blur)="onPriceBlur(result.price_type, result.category_id, result.discountpercent)">
								<span matSuffix>
									<button mat-icon-button color="primary" (click)="onPriceBlur(result.price_type, result.category_id, result.discountpercent)">
										<mat-icon>save</mat-icon>
									</button>
								</span>
								<span matSuffix>%</span>
							</mat-form-field>

						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>

<div id="floater-container"></div>