<div class="card-body p-0 m-0">
	<div class="row p-0 m-0">
		<!-- Filters container (left) -->
		<div class="col-2 border-right p-3 m-0">
			<div class="d-flex justify-content-between align-items-center mb-3 p-0 m-0">
				<h4 class="font-weight-lighter" >Filters</h4>
				<button mat-stroked-button color="accent" (click)="mergeMonths()">Refresh</button>
			</div>

			<!-- Source Filters -->
			<div class="filter-section mb-3 p-0 m-0">
				<h5 class="font-weight-lighter">Channels</h5>
				<form [formGroup]="sourceFilters">
					<div *ngFor="let control of controls.sources">
						<mat-checkbox class="example-margin p-0 m-0" formControlName="{{control}}_fc">{{control}}</mat-checkbox>
					</div>
				</form>
			</div>

			<hr class="p-0 m-0">

			<!-- Month Filters -->
			<div class="filter-section mb-3 p-0 m-0">
				<h5 class="font-weight-lighter">Months</h5>
				<form [formGroup]="monthFilters">
					<div *ngFor="let control of controls.monthes">
						<mat-checkbox class="example-margin p-0 m-0" formControlName="{{control}}_fc">{{control}}</mat-checkbox>
					</div>
				</form>
			</div>

			<hr class="p-0 m-0">

			<!-- State Filters -->
			<div class="filter-section p-0 m-0">
				<h5 class="font-weight-lighter">States</h5>
				<form [formGroup]="stateFilters">
					<div *ngFor="let control of controls.states">
						<mat-checkbox class="example-margin p-0 m-0" formControlName="{{control}}_fc">{{control}}</mat-checkbox>
					</div>
				</form>
			</div>
		</div>

		<!-- Main content container (right) -->
		<div class="col-10 p-0 m-0">
			<!-- Dropdown header container (top) -->
			<div class="row mb-3 p-0 m-0">
				<div class="col-4 ">
					<mat-form-field appearance="outline" class="w-100 p-0 m-0">
						<mat-label>Customer Number</mat-label>
						<input matInput placeholder="Customer Number" [formControl]="customerSearchRef" (keyup)="searchCustomers($event)">
					</mat-form-field>
				</div>
				<div class="col-4">
					<mat-form-field appearance="outline" class="w-100 p-0 m-0">
						<mat-label>Market Category</mat-label>
						<mat-select multiple>
							<mat-option *ngFor="let t of itemCategories" [value]="t.categoryid">
								{{t.categorydescription}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-4 ">
					<mat-form-field appearance="outline" class="w-100 p-0 m-0">
						<mat-label>Customer Type</mat-label>
						<mat-select multiple (selectionChange)="filterCustomers($event)">
							<mat-option *ngFor="let t of custTypes" [value]="t.typeid">
								{{t.typename}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>

			<!-- Tables container (middle) -->
			<div class="row p-0 m-0">
				<!-- Customer table (left) -->
				<div class="col-6 p-0 m-0">
					<mat-card class="table-card m-0">
						<mat-card-header class="p-2 m-0">
							<mat-card-title class="mat-card-header-text m-0">Customer</mat-card-title>
						</mat-card-header>
						<mat-card-content class="p-0 m-0">
							<div class="customertable p-0 m-0">
								<div [ngClass]="customersLoading ? 'd-none' : 'visible'">
									<table class="table table-striped table-bordered p-0 m-0" #custTable>
										<thead class="thead-dark">
											<tr>
												<th>Sold To</th>
												<th>Sold To Code</th>
												<th class="currency-cell">Net Sls Sd LY</th>
												<th class="currency-cell">Net Sls Sd</th>
												<th class="currency-cell">Delta PY</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let row of dataObs | async">
												<td>{{row.name}}</td>
												<td>{{row.debtorno}}</td>
												<td class="currency-cell">{{row.PREV_Y | currency}}</td>
												<td class="currency-cell">{{row.THIS_Y | currency}}</td>
												<td class="currency-cell">{{row.DELTA | currency}}</td>
												<td>
													<div [ngClass]="{
														'bg-success rounded h-50 my-auto': row.DELTA > 0,
														'bg-danger rounded h-50 my-auto': row.DELTA < 0
													}">
														<mat-icon *ngIf="row.DELTA < 0" class="text-white">keyboard_arrow_down</mat-icon>
														<mat-icon *ngIf="row.DELTA == 0" class="text-black">remove</mat-icon>
														<mat-icon *ngIf="row.DELTA > 0" class="text-white">keyboard_arrow_up</mat-icon>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div *ngIf="customersLoading" class="d-flex justify-content-center align-items-center" style="height: 500px;">
									<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</div>
							</div>
						</mat-card-content>
						<mat-card-actions class="p-2 m-0">
							<mat-paginator id="paginator" [pageSizeOptions]="items_per_page" [pageSize]="100"></mat-paginator>
						</mat-card-actions>
					</mat-card>
				</div>

				<!-- Brands tables (right) -->
				<div class="col-6 p-0 m-0">
					<div *ngFor="let brandGroup of gappData.brands | keyvalue" class="mb-3 p-0 m-0">
						<mat-card class="table-card m-0">
							<mat-card-header class="p-2 m-0">
								<mat-card-title class="mat-card-header-text m-0">{{brandGroup.key.substring(1)}}</mat-card-title>
								<button mat-icon-button (click)="collapseTable(brandGroup.key.substring(1))">
									<mat-icon>expand_more</mat-icon>
								</button>
							</mat-card-header>
							<mat-card-content id="{{brandGroup.key.substring(1)}}" class="overflow-hidden w-100 p-0 m-0">
								<table class="table table-striped table-bordered w-100 p-0 m-0">
									<thead class="thead-dark">
										<tr>
											<th>Brand</th>
											<th class="currency-cell">Net Sls Sd LY</th>
											<th class="currency-cell">Net Sls Sd</th>
											<th class="currency-cell">Delta PY</th>
											<th>Chng %</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let row of gappData.brands[brandGroup.key] | keyvalue">
											<td>{{row.value.name}}</td>
											<td class="currency-cell">{{row.value.PREV_Y | currency}}</td>
											<td class="currency-cell">{{row.value.THIS_Y | currency}}</td>
											<td class="currency-cell">{{row.value.DELTA | currency}}</td>
											<td>{{row.percentChange | percent}}</td>
										</tr>
									</tbody>
								</table>
							</mat-card-content>
						</mat-card>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
