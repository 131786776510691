<ng-template #detailsModal let-modal>

	<div class="modal-header">
		<div class="header-content">
			<mat-icon class="warning-icon" aria-hidden="true">warning</mat-icon>
			<h4 id="modal-title">Warning: Duplicate Address Found</h4>
		</div>
	</div>

	<div class="modal-body" role="document">
		<div class="row">
			<div class="matchblock col-md-6">
				<h5>Entered Address</h5>
				<hr class="mb-0">
				<p>{{debtorFromGroup.get('address1').value}}</p>
				<p *ngIf="!debtorFromGroup.get('address2').value">-</p>
				<p *ngIf="debtorFromGroup.get('address2').value">{{debtorFromGroup.get('address2').value}}</p>
				<p>{{debtorFromGroup.get('address3').value}}, {{debtorFromGroup.get('address4').value}}, {{debtorFromGroup.get('address5').value}}</p>
				<p>{{debtorFromGroup.get('address6').value}}</p>
			</div>
			<div class="col-md-6 matches">
				<h5>{{duplicatewarning.length > 1 ? 'Matches Found' : 'Match Found'}}</h5>
				<hr class="mb-0">
				<ng-container *ngFor="let match of duplicatewarning">
					<div class="matchblock">
						<p>
							(<span class="font-weight-bold">
								<a [routerLink]="['./customers/view/' + match.debtorno]">{{match.debtorno}}.{{match.branchcode}}</a></span>)
							<span class="font-weight-lighter">{{match.name}}</span>
						</p>
						<p [ngClass]="isMatch(debtorFromGroup.get('address1').value, match.address1) ? 'warn' : ''">{{match.address1}}</p>
						<p *ngIf="!match.address2">-</p>
						<p *ngIf="match.address2" [ngClass]="isMatch(debtorFromGroup.get('address2').value, match.address2) ? 'warn' : ''">{{match.address2}}</p>
						<p>
							<span [ngClass]="isMatch(debtorFromGroup.get('address3').value, match.address3) ? 'warn' : ''">{{match.address3}}</span>,
							<span [ngClass]="isMatch(debtorFromGroup.get('address4').value, match.address4) ? 'warn' : ''">{{match.address4}}</span>,
							<span [ngClass]="isMatch(debtorFromGroup.get('address5').value, match.address5) ? 'warn' : ''">{{match.address5}}</span>
						</p>
						<p [ngClass]="isMatch(debtorFromGroup.get('address6').value, match.address6) ? 'warn' : ''">{{match.address6}}</p>
					</div>
				</ng-container>
			</div>
		</div>
	</div>

	<div class="modal-footer justify-content-between">
		<button mat-button class="grey-b" (click)="userSelection(false)">Edit Form</button>
		<button mat-button class="green-b" (click)="userSelection(true)">Continue</button>
	</div>

</ng-template>



<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> New Customers</h4>
						<p class="card-category text-right">Customer Information</p>
					</div>
					<div class="card-body">

						<mat-stepper linear #stepper>
							<mat-step [stepControl]="debtorFromGroup" [editable]=isEditable>
								<form [formGroup]="debtorFromGroup">

									<ng-template matStepLabel color="purple">Billing Information</ng-template>

									<div class="row mt-2">
										<div class="col-md-6">
											<app-address-lookup (setAddress)="getAddress($event)" adressType="address">
											</app-address-lookup>
										</div>
										<div class="col-md-6">
											<app-customer-lookup [creating]="true" (customer_selected)="selectCustomer($event)"></app-customer-lookup>
										</div>
									</div>

									<div class="row mt-2">
										<div class="col-md-6 card-group">
											<div class="card m-0">
												<div class="card-body">
													<div class="row">
														<div class="col-md-12">
															<mat-form-field appearance="outline">
																<mat-label>Business Name</mat-label>
																<input matInput required value="" formControlName="name">
															</mat-form-field>
														</div>
													</div>
													<div class="row">
														<div class="col-md-12">
															<mat-form-field appearance="outline">
																<mat-label>Address 1</mat-label>
																<input matInput required value="" formControlName="address1">
															</mat-form-field>
														</div>
													</div>
													<div class="row">
														<div class="col-md-12">
															<mat-form-field appearance="outline">
																<mat-label>Address 2</mat-label>
																<input matInput value="" formControlName="address2">
															</mat-form-field>
														</div>
													</div>
													<div class="row">
														<div class="col-md-4">
															<mat-form-field appearance="outline">
																<mat-label>City</mat-label>
																<input matInput required value="" formControlName="address3">
															</mat-form-field>
														</div>
														<div class="col-md-4" *ngIf="zones">
															<mat-form-field appearance="outline">
																<mat-label>Region</mat-label>
																<mat-select formControlName="address4">
																	<mat-option *ngFor="let zone of zones.zones" [value]="zone.code">
																		{{zone.code }}
																	</mat-option>
																</mat-select>
															</mat-form-field>

														</div>
														<div class="col-md-4">
															<mat-form-field appearance="outline">
																<mat-label>Postal</mat-label>
																<input matInput required value="" formControlName="address5">
															</mat-form-field>
														</div>
														<div class="col-md-12 " *ngIf="zones">
															<mat-form-field appearance="outline" class="">
																<mat-label>Country</mat-label>
																<mat-select formControlName="address6" (selectionChange)="updateZones($event)">
																	<mat-option *ngFor="let zone of zones.countries" [value]="zone.name">
																		{{ zone.name }}
																	</mat-option>
																</mat-select>
															</mat-form-field>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-md-6 card-group">
											<div class="card m-0">
												<div class="card-body">
													<div class="row">
														<div class="col-md-3">
															<mat-form-field appearance="outline">
																<mat-label>Credit Limit</mat-label>
																<input matInput required value="" class="text-right" formControlName="creditlimit">
																<span matPrefix>$</span>
															</mat-form-field>
														</div>
														<div class="col-md-3">
															<mat-form-field appearance="outline">
																<mat-label>Sale Discount</mat-label>
																<input matInput value="0" class="text-right" formControlName="discount">
																<span matSuffix>%</span>
															</mat-form-field>
														</div>
														<div class="col-md-3">
															<mat-form-field appearance="outline">
																<mat-label>Payment Discount</mat-label>
																<input matInput value="0" class="text-right" formControlName="pymtdiscount">
																<span matSuffix>%</span>
															</mat-form-field>
														</div>
														<div class="col-md-3">
															<mat-form-field appearance="outline">
																<mat-label>Interest</mat-label>
																<input matInput value="0" class="text-right" formControlName="arintrate">
																<span matSuffix>%</span>
															</mat-form-field>
														</div>
														<ng-container *ngIf="config && config.env.package == 'beauty'">
															<div class="col-md-12 text-center d-none">
																<mat-slide-toggle formControlName="signed_mor">Signed Contract</mat-slide-toggle>
															</div>
															<div class="col-md-6">
																<mat-form-field appearance="outline">
																	<mat-label>License</mat-label>
																	<input matInput formControlName="cosm_license">
																</mat-form-field>
															</div>
															<div class="col-md-6">
																<mat-form-field appearance="outline">
																	<mat-label>Expiration</mat-label>
																	<input matInput formControlName="licExpDate" [matDatepicker]="licExpDatedatepicker">
																	<mat-datepicker-toggle matSuffix [for]="licExpDatedatepicker"></mat-datepicker-toggle>
																	<mat-datepicker #licExpDatedatepicker></mat-datepicker>
																</mat-form-field>
															</div>
														</ng-container>
													</div>
													<div class="row">
														<div class="col-md-6">
															<mat-form-field appearance="outline">
																<mat-label>Payment Terms</mat-label>
																<mat-select formControlName="paymentterms">
																	<mat-option *ngFor="let va of terms" [value]="va?.termsindicator">
																		{{va.terms}}
																	</mat-option>
																</mat-select>
															</mat-form-field>
														</div>
														<div class="col-md-6">
															<mat-form-field appearance="outline">
																<mat-label>Price List</mat-label>
																<mat-select formControlName="salestype">
																	<mat-option *ngFor="let tpe of salestypes" [value]="tpe?.typeabbrev">
																		{{tpe.sales_type}}
																	</mat-option>
																</mat-select>
															</mat-form-field>
														</div>

													</div>
													<div class="row">
														<div class="col-md-4">
															<mat-form-field appearance="outline">
																<mat-label>Customer Type</mat-label>
																<mat-select formControlName="typeid">
																	<mat-option *ngFor="let tpe of customertypes" [value]="tpe?.typeid">
																		{{tpe.typename}}
																	</mat-option>
																</mat-select>
															</mat-form-field>
														</div>
														<div class="col-md-4">
															<mat-form-field appearance="outline">
																<mat-label>Credit Status</mat-label>
																<mat-select formControlName="holdreason">
																	<mat-option *ngFor="let va of holdreasons" [value]="va?.reasoncode">
																		{{va.reasondescription}}
																	</mat-option>
																</mat-select>
															</mat-form-field>
														</div>
														<div class="col-md-4">
															<mat-form-field appearance="outline">
																<mat-label>Currency</mat-label>
																<mat-select formControlName="currcode">
																	<mat-option *ngFor="let currency of currencies" [value]="currency?.currabrev">
																		{{currency.currency}}
																	</mat-option>
																</mat-select>
															</mat-form-field>
														</div>
													</div>
													<div class="row mt-2">
														<div class="col-md-4">
															<mat-checkbox formControlName="emailstatement">Email Statement</mat-checkbox>
															<span [ngClass]="debtorFromGroup.get('emailstatement').value ? '': 'd-none'">
																<mat-form-field appearance="outline">
																	<mat-label>Statement Email Address</mat-label>
																	<input matInput value="" formControlName="statementemailaddress">
																</mat-form-field>
															</span>
														</div>
														<div class="col-md-4">
															<mat-checkbox formControlName="ediinvoices">Email Invoices</mat-checkbox>
															<span [ngClass]="debtorFromGroup.get('ediinvoices').value ? '': 'd-none'">
																<mat-form-field appearance="outline">
																	<mat-label>Invoice Email Address</mat-label>
																	<input matInput value="" formControlName="invoiceemailaddress">
																</mat-form-field>
															</span>
														</div>
														<div class="col-md-4">
															<mat-checkbox formControlName="customerpoline">Require PO#</mat-checkbox>
														</div>

													</div>
													<div class="row">
														<div class="col-12">
															<mat-form-field appearance="outline">
																<mat-label>Print Statement</mat-label>
																<mat-select formControlName="edireference">
																	<mat-option *ngFor="let va of ediYesNo" [value]="va?.value">
																		{{va.view}}
																	</mat-option>
																</mat-select>
															</mat-form-field>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-12 ml-auto mr-auto text-center mt-4">
											<button class="ml-auto mr-auto " (click)="compareAddress()" mat-button mat-raised-button [color]="debtorFromGroup.valid ? 'primary': 'warn' ">Next</button>
											<button mat-button matStepperNext style="display: block" id="stepperNext"></button>
										</div>
									</div>
								</form>
							</mat-step>
							<mat-step [stepControl]="branchFormGroup" [editable]="isEditable">
								<form [formGroup]="branchFormGroup">
									<ng-template matStepLabel>Shipping Information</ng-template>
									<div class="row mt-2">
										<div class="col-md-6">
											<div class="card m-0">
												<div class="card-body">
													<div class="row">
														<div class="col-md-12">
															<mat-form-field appearance="outline">
																<mat-label>Branch Code</mat-label>
																<input matInput required value="" formControlName="branchcode">
															</mat-form-field>
														</div>
													</div>
													<div class="row">
														<div class="col-md-6">
															<mat-form-field appearance="outline">
																<mat-label>Name</mat-label>
																<input matInput required value="" formControlName="brname">
															</mat-form-field>
														</div>
														<div class="col-md-6">
															<mat-form-field appearance="outline">
																<mat-label>Attention</mat-label>
																<input matInput required value="" formControlName="contactname">
															</mat-form-field>
														</div>
													</div>
													<div class="row">
														<div class="col-md-6">
															<mat-form-field appearance="outline">
																<mat-label>Address 1</mat-label>
																<input matInput required value="" formControlName="braddress1">
															</mat-form-field>
														</div>
														<div class="col-md-6">
															<mat-form-field appearance="outline">
																<mat-label>Address 2</mat-label>
																<input matInput value="" formControlName="braddress2">
															</mat-form-field>
														</div>
													</div>
													<div class="row">
														<div class="col-md-4">
															<mat-form-field appearance="outline">
																<mat-label>City</mat-label>
																<input matInput required value="" formControlName="braddress3">
															</mat-form-field>
														</div>
														<div class="col-md-4">
															<mat-form-field appearance="outline" *ngIf="zones">
																<mat-label>Region</mat-label>
																<mat-select formControlName="braddress4">
																	<mat-option *ngFor="let zone of zones.zones" [value]="zone.code">
																		{{zone.code }}
																	</mat-option>
																</mat-select>
															</mat-form-field>

														</div>
														<div class="col-md-4">
															<mat-form-field appearance="outline">
																<mat-label>Postal</mat-label>
																<input matInput required value="" formControlName="braddress5">
															</mat-form-field>
														</div>
														<div class="col-md-12">
															<mat-form-field appearance="outline" *ngIf="zones">
																<mat-label>Country</mat-label>
																<mat-select formControlName="braddress6" (selectionChange)="updateZones($event)">
																	<mat-option *ngFor="let zone of zones.countries" [value]="zone.name">
																		{{ zone.name }}
																	</mat-option>
																</mat-select>
															</mat-form-field>
														</div>
													</div>
													<div class="row">
														<div class="col-md-4">
															<mat-form-field appearance="outline">
																<mat-label>Phone</mat-label>
																<input matInput value="" formControlName="phoneno" mask="(000) 000-0000" [showMaskTyped]="true">
															</mat-form-field>
														</div>
														<div class="col-md-4">
															<mat-form-field appearance="outline">
																<mat-label>Email</mat-label>
																<input matInput value="" formControlName="email" placeholder="example@domain.com">
															</mat-form-field>
														</div>
														<div class="col-md-4">
															<mat-form-field appearance="outline">
																<mat-label>Fax</mat-label>
																<input matInput value="" formControlName="faxno" mask="(000) 000-0000" [showMaskTyped]="true">
															</mat-form-field>
														</div>
													</div>

													<div class="clearfix"></div>
												</div>
											</div>
										</div>
										<div class="col-md-6">
											<div class="card m-0 card-profile">
												<div class="card-body">
													<div class="row">
														<div class="col-md-12">
															<mat-slide-toggle formControlName="disabletrans">Disabled</mat-slide-toggle>
															&nbsp;&nbsp;&nbsp;
															<mat-slide-toggle formControlName="tirefee" [ngClass]="!config.fet ? 'd-none': ''">Tire Fee</mat-slide-toggle>

														</div>
													</div>
													<div class="row">
														<div class="col-md-6">
															<mat-form-field appearance="outline">
																<mat-label>Warehouse</mat-label>
																<mat-select formControlName="defaultlocation">
																	<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
																		{{loc.loccode}} {{loc.locationname}}
																	</mat-option>
																</mat-select>
															</mat-form-field>
														</div>
														<div class="col-md-6">
															<mat-form-field appearance="outline">
																<mat-label>Shipping Method</mat-label>
																<mat-select formControlName="defaultshipvia">
																	<mat-option *ngFor="let ship of shipvia" [value]="ship.shipper_id">
																		{{ship.shippername}}
																	</mat-option>
																</mat-select>
															</mat-form-field>
														</div>
														<div class="col-md-6">
															<mat-form-field appearance="outline">
																<mat-label>Default Payment Method</mat-label>
																<mat-select formControlName="default_paymentmehod">
																	<mat-option *ngFor="let p of paymentmethods" [value]="p.paymentid">
																		{{p.paymentname}}
																	</mat-option>
																</mat-select>
															</mat-form-field>

														</div>
														<div class="col-md-6">
															<mat-form-field appearance="outline">
																<mat-label>Route</mat-label>
																<mat-select formControlName="route">
																	<mat-option *ngFor="let k of routes" [value]="k.route_Id">
																		{{ k.route_Id }}: {{ k.route_description }}
																	</mat-option>
																</mat-select>
															</mat-form-field>
														</div>

													</div>
													<div class="row">
														<div class="col-md-6">
															<mat-form-field appearance="outline">
																<mat-label>Sales Area</mat-label>
																<mat-select formControlName="area">
																	<mat-option *ngFor="let area of areas" [value]="area.areacode">
																		{{area.areadescription}}
																	</mat-option>
																</mat-select>
															</mat-form-field>
														</div>
														<div class="col-md-6">
															<mat-form-field appearance="outline">
																<mat-label>Sales Person</mat-label>
																<mat-select formControlName="salesman">
																	<mat-option *ngFor="let person of salesman" [value]="person.salesmancode">
																		{{ person.salesmancode}} {{person.salesmanname }}
																	</mat-option>
																</mat-select>
															</mat-form-field>
														</div>
													</div>
													<div class="row">
														<div class="col-md-6">
															<mat-form-field appearance="outline">
																<mat-label>Tax Id</mat-label>
																<input matInput value="" formControlName="tax_ref">
															</mat-form-field>
														</div>
														<div class="col-md-6">
															<mat-form-field appearance="outline" *ngIf="taxes">
																<mat-label>Tax Authority</mat-label>
																<mat-select formControlName="taxgroupid">
																	<mat-option *ngFor="let tax of taxes" [value]="tax.taxgroupid">
																		{{tax.taxgroupdescription}}
																	</mat-option>
																</mat-select>
															</mat-form-field>
														</div>
													</div>
													<div class="row">
														<div class="col-md-6">
															<mat-form-field class="full-width" appearance="outline">
																<mat-label>UserName</mat-label>
																<input matInput formControlName="username" placeholder="Username">
															</mat-form-field>
														</div>
														<div class="col-md-6">
															<mat-form-field class="full-width" appearance="outline">
																<mat-label>Password</mat-label>
																<input matInput formControlName="password" placeholder="Password">
															</mat-form-field>
														</div>

														<div class="col-md-12">
															<mat-form-field class="full-width" appearance="outline">
																<mat-label>Special Instructions</mat-label>
																<input matInput formControlName="specialinstructions" placeholder="...">
															</mat-form-field>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-12 mt-3 text-center">
											<button mat-button mat-raised-button matStepperPrevious color='primary'>Back</button>
											&nbsp;&nbsp;

											<button mat-button mat-raised-button (click)="stepper.reset(); setFormDefaults();">Reset</button>
											&nbsp;&nbsp;

											<button mat-button  mat-raised-button matStepperNext (click)="prefillContact()">Next</button>

										</div>
									</div>
								</form>
							</mat-step>
							<mat-step [stepControl]="contactFormGroup" [editable]="isEditable">
								<ng-template matStepLabel>Contacts</ng-template>
								<div class="col-12 ml-auto mr-auto text-center mt-4">
									<form [formGroup]="contactFormGroup" *ngIf="contactFormGroup">
										<div class="row mt-2">
											<div class="col-md-3">
												<mat-form-field appearance="outline" >
													<mat-label>Name</mat-label>
													<input matInput formControlName="name" placeholder="Contact Name">
												</mat-form-field>
											</div>
											<div class="col-md-3">
												<mat-form-field class="full-width"  appearance="outline">
													<mat-label>Phone</mat-label>
													<!--mask="(000) 000-0000" value="{{contact.phone | phoneFormat}}"-->
													<input matInput formControlName="phone"  placeholder="Contact Phone"  mask="(000) 000-0000" [showMaskTyped]="true" >
												</mat-form-field>
											</div>
											<div class="col-md-3">
												<mat-form-field class="full-width" appearance="outline" >
													<mat-label>Email</mat-label>
													<input matInput formControlName="email" placeholder="Contact Email" >
													<mat-hint *ngIf="emailwarn"> Provide a Valid Email</mat-hint>
												</mat-form-field>
											</div>
											<div class="col-md-3">

												<mat-form-field appearance="outline">
													<mat-label>Roles</mat-label>
													<mat-select formControlName="role" multiple>
														<mat-option *ngFor="let loc of contacttypes" [value]="loc.contact_role">
															{{loc.contact_type}}
														</mat-option>
													</mat-select>
												</mat-form-field>

												<!-- <mat-form-field class="full-width" appearance="outline" >
													<mat-label>Role</mat-label>
													<input matInput required formControlName="role" placeholder="Contact Role" >
												</mat-form-field> -->
											</div>
										</div>

										<button mat-raised-button color="primary" class="mt-2 mb-2" (click)="addContact()">Add  Contact</button>


									</form>

									<div class="table mt-2 mb-4" *ngIf="contacts.length">
										<table class="table table-fixed table-sm">
												<thead>
													<tr>
														<th></th>
														<th>Name</th>
														<th>Email</th>
														<th>Phone</th>
														<th>Role</th>
													</tr>
												</thead>
												<tbody>
													<ng-container >
													<tr *ngFor="let contact of contacts">
														<td><mat-icon matSuffix (click)="removeContact(contact)">remove</mat-icon></td>
														<td>{{contact.name}}</td>
														<td>{{contact.email}}</td>
														<td>{{contact.phone | phoneFormat}}</td>
														<td>{{contact.role}}</td>
													</tr>
													</ng-container>
													<ng-container *ngIf="!contacts.length">
														<tr>
															<td colspan="5" class="text-center">
																<h4>No Contacts</h4>
															</td>
														</tr>
													</ng-container>
												</tbody>
										</table>
									</div>

									<mat-divider></mat-divider>
									<br />
									<button mat-button mat-raised-button matStepperPrevious color='primary'>Back</button>
									&nbsp;&nbsp;
									<button mat-button mat-raised-button (click)="stepper.reset()">Reset</button>
									&nbsp;&nbsp;
									<button mat-button matStepperNext mat-raised-button [color]="branchFormGroup.valid ? 'warn': 'success' " (click)="createCustomer()">Create Customer</button>
								</div>
							</mat-step>
						</mat-stepper>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
