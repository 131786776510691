<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Remote API Settings</h4>
						<p class="card-category">Remote API Settings</p>
					</div>
					<div class="card-body">
						<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent">
							<mat-tab label="Remote APIs">
								<table class="table table-sm">
									<tr>
										<th>API Name</th>
										<th>Vendor</th>
										<th>Username</th>
										<th>Password</th>
                                        <th>ApiKey</th>
										<th><button mat-mini-fab color="accent" type="button" aria-label="Add" (click)="add('rapi')"><i class="material-icons">add</i></button></th>
									</tr>
									<tr *ngFor="let type of remoteapi" (click)="editRapi(type.api_id)">
										<td >{{ type.apiname }}</td>
										<td>{{ type.supplierno }}</td>
										<td>{{ type.apilogin }}</td>
										<td>{{ type.apipassword }}</td>
                                        <td>{{ type.apikey }}</td>
										<td></td>
									</tr>
								</table>
							</mat-tab>
							<mat-tab label="Remote API Mappings">
								<table class="table table-sm">
									<tr>
										<th>API Name</th>
										<th>Local Whse Code</th>
                                        <th>Remote Feed Label</th>
                                        <th>Local Feed Label</th>
                                        <th>Sort Order</th>
                                        <th>Local Bin Label</th>
										<th><button mat-mini-fab color="accent" type="button" aria-label="Add" (click)="add('rmap')"><i class="material-icons">add</i></button></th>
									</tr>
									<tr *ngFor="let type of apimappings" (click)="editRmap(type.map_id)">
										<td>{{ type.apiname }}</td>
										<td>{{ type.warehouse }}</td>
                                        <td>{{ type.feedcolumn }}</td>
                                        <td>{{ type.feedlabel }}</td>
                                        <td>{{ type.sort_value }}</td>
                                        <td>{{ type.binlabel }}</td>
										<td></td>
									</tr>
								</table>
							</mat-tab>
							<mat-tab label="Remote API Exclusions">
								<table class="table table-sm">
									<tr>
									<th>API Name</th>
									<th>Manufacturer</th>
                                    <th>Item Category</th>
									<th><button mat-mini-fab color="accent" type="button" aria-label="Add" (click)="add('rex')"><i class="material-icons">add</i></button></th>
									</tr>
									<tr *ngFor="let type of exclusions" (click)="editRex(type.ex_id)">
										<td>{{ type.apiname }}</td>
										<td>{{ type.name }}</td>
                                        <td>{{ type.categorydescription }}</td>
										<td></td>
									</tr>
								</table>
							</mat-tab>
						</mat-tab-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #remotes let-modal (click)="stopProp($event)">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Remote API</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
    <button mat-mini-fab color="accent" type="button" aria-label="Save" (click)="save(rapi)"><i class="material-icons">save</i></button><button mat-mini-fab color="accent" type="button" aria-label="Cancel" (click)="modal.dismiss('Cross click')"><i class="material-icons">cancel</i></button>
	<button mat-mini-fab color="warn" type="button" aria-label="Delete" (click)="delete(rapi)"><i class="material-icons">delete</i></button>
	<table class="table">
        <tr><th> API Name </th><th>Vendor</th><th></th></tr>
        <tr>
        <td>
                    <input matInput placeholder="API Name" [(ngModel)]="rapi.apiname">
                    </td>
        <td><mat-select [(value)]="rapi.supplierno">
						<mat-option *ngFor="let supplier of suppliers"
						[value]="supplier.supplierid">
						{{supplier.supplierid}} {{supplier.suppname}}</mat-option>
					</mat-select></td><td></td></tr>
        <tr><th>Login</th><th>Password</th><th>API Key</th></tr>
        <tr>            <td>
                    <input matInput placeholder="API UserName" [(ngModel)]="rapi.apilogin">
                    </td><td>
                    <input matInput placeholder="API Password" [(ngModel)]="rapi.apipassword">
                    </td><td><input matInput placeholder="API Key" [(ngModel)]="rapi.apikey"></td>
        </tr>
        </table>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #rmappings let-modal (click)="stopProp($event)">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Remote Mapping</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
    <button mat-mini-fab color="accent" type="button" aria-label="Save" (click)="save(rmap);"><i class="material-icons">save</i></button><button mat-mini-fab color="accent" type="button" aria-label="Cancel" (click)="modal.dismiss('Cross click')"><i class="material-icons">cancel</i></button>
	<button mat-mini-fab color="warn" type="button" aria-label="Delete" (click)="delete(rmap)"><i class="material-icons">delete</i></button>
	<table class="table">
        <tr><th> API Name </th><th>Local Whse Code</th><th>Sort Order</th></tr>
        <tr>
        <td>
                    <mat-select [(value)]="rmap.api_id">
						<mat-option *ngFor="let api of remoteapi"
						[value]="api.api_id">
						{{api.apiname}}</mat-option>
					</mat-select>
                    </td>
        <td><input matInput placeholder="00" [(ngModel)]="rmap.warehouse"></td><td><input matInput placeholder="1" [(ngModel)]="rmap.sort_value"></td></tr>
        <tr><th>Remote Feed</th><th>Local Feed</th><th>Bin Label</th></tr>
        <tr>            <td>
                    <input matInput placeholder="local" [(ngModel)]="rmap.feedcolumn">
                    </td><td>
                    <input matInput placeholder="2-4 Day" [(ngModel)]="rmap.feedlabel">
                    </td><td><input matInput placeholder="Bin" [(ngModel)]="rmap.binlabel"></td>
        </tr>
        </table>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #rexclude let-modal (click)="stopProp($event)">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Remote Exclusion</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
    <button mat-mini-fab color="accent" type="button" aria-label="Save" (click)="save(rex)"><i class="material-icons">save</i></button><button mat-mini-fab color="accent" type="button" aria-label="Cancel" (click)="modal.dismiss('Cross click')"><i class="material-icons">cancel</i></button>
	<button mat-mini-fab color="warn" type="button" aria-label="Delete" (click)="delete(rex)"><i class="material-icons">delete</i></button>
	<table class="table">
        <tr><th> API Name </th><th>Manufacturer</th><th>Category</th></tr>
        <tr>
        <td>
                    <mat-select [(value)]="rex.api_id">
						<mat-option *ngFor="let api of remoteapi"
						[value]="api.api_id">
						{{api.apiname}}</mat-option>
					</mat-select>
                    </td>
        <td><mat-select [(value)]="rex.manufacturer_id">
						<mat-option *ngFor="let brand of brands"
						[value]="brand.manufacturer_id">
						{{brand.name}}</mat-option>
					</mat-select></td><td><mat-select [(value)]="rex.categoryid">
						<mat-option *ngFor="let cat of cats"
						[value]="cat.value">
						{{cat.name}}</mat-option>
					</mat-select></td></tr>
        </table>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
